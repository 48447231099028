import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AlertService, LoaderService, SearchFiltersService } from 'foo-framework';
import { keyBy } from 'lodash';
import { FooPaginatedListingDialogTabsConfigs } from './foo-paginated-listing-dialog-tabs-configs.interface';

@Component({
  selector: 'foo-paginated-listing-dialog',
  templateUrl: './foo-paginated-listing-dialog.component.html',
  styleUrls: ['./foo-paginated-listing-dialog.component.scss']
})
export class FooPaginatedListingDialogComponent {

  dataShimmer: { [key: string]: boolean } = {};
  dialogTitle;
  button;
  activeTab;
  tabsConfigs: FooPaginatedListingDialogTabsConfigs[];
  tabs: { [key: string]: any } = {};
  searchText: { [key: string]: string } = {};
  defaultItemsPerPage = 20;
  itemsPerPage: { [key: string]: number } = {};
  pageNumber: { [key: string]: number } = {};
  totalData: { [key: string]: number } = {};
  paginationInput: { [key: string]: any } = {};
  items: { [key: string]: any[] } = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    protected translate: TranslateService,
    protected alert: AlertService,
    public dialogRef: MatDialogRef<any>,
    protected loaderService: LoaderService,
    protected searchFiltersService: SearchFiltersService
  ) {
    this.dialogTitle = data?.dialogTitle ? data.dialogTitle : '';
    this.tabsConfigs = data?.tabsConfigs || [];
    this.tabs = keyBy(this.tabsConfigs, 'key')
    this.activeTab = (this.tabsConfigs?.length == 1 ? this.tabsConfigs[0].key : data?.activeTab) || this.tabsConfigs[0].key;
    this.initComponentTabs();
    this.button = data?.button || {
      label: this.translate.instant('general.done'),
      class: 'foo-btn-primary foo-btn-center'
    };
  }

  ngOnInit(): void {
    this.getData(1, this.activeTab);
  }

  initComponentTabs(): void {
    for (let x = 0; x < this.tabsConfigs.length; x++) {
      this.initializeTab(this.tabsConfigs[x].key);
    }
  }

  initializeTab(tab: string): void {
    this.pageNumber[tab] = this.pageNumber[tab] || 1;
    this.itemsPerPage[tab] = this.itemsPerPage[tab] || this.defaultItemsPerPage;
    this.searchText[tab] = this.searchText[tab] || '';
    this.items[tab] = [{}];
    this.tabsDefaultValues(tab);
  }

  tabsDefaultValues(tab: string) {
    const defaultData: any = {
      key: tab,
      label: this.tabs[tab].label,
      labels: [
        ...this.tabs[tab].labels
      ],
      search: this.tabs[tab].search || {
        placeholder: "general.search",
        class: ''
      },
      dataSource: this.tabs[tab]?.dataSource,
    };
    this.tabs[tab] = defaultData
  }

  setPaginator(tab: string): void {
    this.paginationInput[tab] = {
      pageNumber: this.pageNumber[tab],
      totalData: this.totalData[tab],
      dataPerpage: this.itemsPerPage[tab]
    };
  }

  clearSearchInput(data: any, tab: string) {
    this.searchText[tab] = '';
    this.getData(1, tab);
  }

  searchData(search: string, tab: string) {
    this.searchText[tab] = search;
    this.getData(1, tab);
  }

  getData(pageNumber: number, tab: string) {
    this.dataShimmer[tab] = true;
    if (!this.items[tab]?.length) {
      this.items[tab] = [{}];
    }
    this.pageNumber[tab] = pageNumber;
    this.tabs[tab].dataSource(this.searchText[tab], this.pageNumber[tab], this.itemsPerPage[tab]).subscribe(resp => {
      this.items[tab] = resp.data;
      this.totalData[tab] = resp.total;
      this.itemsPerPage[tab] = resp.dataPerpage || this.defaultItemsPerPage;
      this.dataShimmer[tab] = false;
      this.setPaginator(tab);
    });
  }

  submit() {
    this.dialogRef.close();
  }

  changeTab(tab: string): void {
    if (this.activeTab !== tab) {
      this.activeTab = tab;
    }
    this.getData(1, tab);
    this.searchFiltersService.setSearchInfo(this);
  }
}
