import { Injectable } from '@angular/core';
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { isDate, isObject } from 'lodash';
import moment from 'moment';

@Injectable()
export class NgbDateFooParserFormatterService extends NgbDateParserFormatter {
  parse(value: string, separator = '/'): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split(separator);
      if (dateParts.length === 1) {
        return { year: Number(dateParts[0]), month: null, day: null };
      } else if (
        dateParts.length === 2
      ) {
        return {
          year: Number(dateParts[1]),
          month: Number(dateParts[0]),
          day: null,
        };
      } else if (
        dateParts.length === 3
      ) {
        return {
          year: Number(dateParts[2]),
          month: Number(dateParts[1]),
          day: Number(dateParts[0]),
        };
      }
    }
    return null;
  }

  format(date: NgbDateStruct | Date): string {
    if (!date) {
      return '';
    }
    if (isObject(date) && !isDate(date)) {
      return moment()
          .year(date.year)
          .month(date.month - 1)
          .date(date.day)
          .format('DD/MM/YYYY');
    }
    return moment(date).format('DD/MM/YYYY');
  }
}
