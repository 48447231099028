import { Injectable } from '@angular/core';
import { RouteHandler } from './route-handler';
import { AlertService, MakeCallService } from 'foo-framework';
import { TranslateService } from '@ngx-translate/core';
import { isFunction } from 'lodash';

@Injectable({
 providedIn: 'root'
})
export class ExternalPortalRouteHandlerService implements RouteHandler {

  constructor(
    protected makeCall: MakeCallService,
    protected alert: AlertService,
    protected translate: TranslateService
  ) { }

  handle(menuItem: any): void {
    if (!this.isValidUrl(menuItem['menu_url'])) {
      this.alert.emitAlert({type: 'danger', text: this.translate.instant('general.invalidURL')});
      return;
    }
    this.makeCall.postRequest('auth/token', null, {showLoader: true}).subscribe(value => {
        if (value?.token) {
          const url = this.parametrizedURL(menuItem['menu_url'], {
              token: value.token
          });
          window.open(url, '_blank').focus();
        } else {
          this.alert.emitAlert({type: 'danger', text: this.translate.instant('general.somethingWentWrong')});
        }
    });
  }

  protected isValidUrl(url) {
    try { 
      if (isFunction(window['URL'])) {
        return Boolean(new window['URL'](url));
      } else {
        return true;
      }
    }
    catch(e){ 
      return false; 
    }
 }

  protected parametrizedURL(url: string, queryParams: any): string {
    let parametrizedURL = url;
    if (queryParams) {
      const queryString = Object.keys(queryParams).map(key => key + '=' + queryParams[key]).join('&');
      parametrizedURL += (url.indexOf('?') !== -1) ? '&' + queryString : '?' + queryString;
    }
    return parametrizedURL;
  }
}
