import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import {TranslateService} from "@ngx-translate/core";

import {AlertService, TokenStorage, AuthenticateService, LoaderService, RouteService} from "foo-framework";

@Component({
  selector: 'app-self-onboarding-webview',
  templateUrl: './self-onboarding-webview.component.html',
  styleUrls: ['./self-onboarding-webview.component.scss']
})
export class SelfOnboardingWebviewComponent implements OnInit {
  configuration;
  category_answer_id;
  public logo;


  @Output() LangChangedEvent = new EventEmitter<string>();
  isrtl = false;
  webformParams = null;
  public constructor(
    private router: Router,
    private authenticateService: AuthenticateService,
    private tokenStorage: TokenStorage,
    private alert: AlertService,
    private translate: TranslateService,
    private routerService: RouteService,
    private loaderService: LoaderService) {
    translate.setDefaultLang(this.tokenStorage.getCurrentLanguage());
  }

  ngOnInit(): void {
    this.logo = this.tokenStorage.getLoginLogo();
    const token = this.tokenStorage.getSelfOnboardingToken();
    const category_key = this.tokenStorage.getSelfOnboardingCategoryKey();
    const application_id =  this.tokenStorage.getSelfOnboardingApplicationId();
    this.category_answer_id = this.tokenStorage.getSelfOnboardingCategoryAnswerId();

    if(!category_key || !token){
      this.onLogout();
    }

    this.webformParams = {
      token,
      category_key,
      application_id,
      category_answer_id: this.category_answer_id,
      is_self_onboarding: true
    };
  }

  onAction(event): void {
    switch (event?.action) {
      case 'onload':
        if(event?.data?.value?.application_id){
          this.tokenStorage.setSelfOnboardingApplicationId(event?.data?.value?.application_id);
        }
        break;
      case 'FINISH':
        this.onLogout();
        break;
      case 'BACK':
        this.router.navigate(['/company']);
        break;
      case 'ERROR':
        this.onLogout();
        break;
    }
  }

  useLanguage() {
    let language = 'en';
    this.isrtl = false;
    if (this.tokenStorage.getCurrentLanguage() == 'en') {
      language = 'ar';
      this.isrtl = true;
    }
    this.translate.use(language);
    this.LangChangedEvent.emit(language);
    this.tokenStorage.setCurrentLanguage(language);
  }

  onLogout() {
    this.loaderService.visibility = true;
    this.authenticateService.flushsession().subscribe(
      (data) => {
        if (data["status"] === 'OK') {
          this.tokenStorage.clear();
          this.routerService.clearParams();
          window.location.href = (window['prefix'] ? `/${window['prefix']}` : '') +'/login';
          this.authenticateService.cancelPendingRequests();
        } else {
          this.loaderService.visibility = false;
          this.alert.emitAlert({type:"danger", text: data["message"]});
        }

      },
      error => {
        this.loaderService.visibility = false;
        this.alert.emitAlert({type:"danger", text: error["message"]});
      });
  }

}
