<div class="row" *ngIf="engineConfig && engineConfig.values && engineConfig.dropdowns">
  <div class="col-sm-12 col-md-4 col-lg-4 mb-10">
    <label class="form-label">{{'idverification.engineProviders.tamperingEngineProvider' | translate}}</label>
    <ng-select appNgSelectCustom
               [selectReadonly]="engineConfig?.readonly"
               [readonly]="engineConfig?.readonly"
               [clearable]="false"
               class="form-control"
               [searchable]="true"
               [hideSelected]="true"
               [(ngModel)]="engineConfig['values']['tampering']"
               (change)="engineChanged.emit({type: 'tampering', value:  $event})"
               placeholder="{{'idverification.engineProviders.selectProvider' | translate}}">
      <ng-container *ngFor="let type of engineConfig['dropdowns']['tampering']">
        <ng-option [value]="type.id"><span class="text-capitalize">{{type.name}}</span></ng-option>
      </ng-container>
    </ng-select>
  </div>
  <div class="w-100"></div>
  <div class="col-sm-12 col-md-4 col-lg-4 mb-10">
    <label class="form-label">{{'idverification.engineProviders.faceMatchingEngineProvider' | translate}}</label>
    <ng-select appNgSelectCustom
               [selectReadonly]="engineConfig?.readonly"
               [readonly]="engineConfig?.readonly"
               [clearable]="false"
               class="form-control"
               [searchable]="true"
               [hideSelected]="true"
               [(ngModel)]="engineConfig['values']['facematching']"
               (change)="engineChanged.emit({type: 'facematching', value:  $event})"
               placeholder="{{'idverification.engineProviders.selectProvider' | translate}}">
      <ng-container *ngFor="let type of engineConfig['dropdowns']['facematching']">
        <ng-option [value]="type.id"><span class="text-capitalize">{{type.name}}</span></ng-option>
      </ng-container>
    </ng-select>
  </div>
  <div class="w-100"></div>
  <div class="col-sm-12 col-md-4 col-lg-4 mb-10">
    <label class="form-label">{{'idverification.engineProviders.livenessEngineProvider' | translate}}</label>
    <ng-select appNgSelectCustom
               [selectReadonly]="engineConfig?.readonly"
               [readonly]="engineConfig?.readonly"
               [clearable]="false"
               class="form-control"
               [searchable]="true"
               [hideSelected]="true"
               [(ngModel)]="engineConfig['values']['liveness']"
               (change)="engineChanged.emit({type: 'liveness', value:  $event})"
               placeholder="{{'idverification.engineProviders.selectProvider' | translate}}">
      <ng-container *ngFor="let type of engineConfig['dropdowns']['liveness']">
        <ng-option [value]="type.id"><span class="text-capitalize">{{type.name}}</span></ng-option>
      </ng-container>
    </ng-select>
  </div>

</div>

<ng-container *ngIf="engineConfig?.values?.liveness == 1">
  <div class="general-settings-second-section mt-28">
    <div class="m-0 p-0">
      <div *ngFor="let challengeConfig of challengesConfig | keyvalue">
        <div class="row general-settings-line m-0">
          <div class="col-md-10 col-lg-10 col-8 pr-0 pl-24 pt-31" *ngIf="isBoolean(challengeConfig.value[1])">
            <span class="general-settings-subTitle">{{ getChallengeName(challengeConfig.value[0]) }}</span>
          </div>
          <div class="col-lg-2 col-md-2 col-4 pt-24" *ngIf="isBoolean(challengeConfig.value[1])">
            <input class="switch_1" type="checkbox" *ngIf="permissions.can_update"
                   [(ngModel)]="challengeConfig.value[1]" [checked]="challengeConfig.value[1]"
                   (change)="toggleChallengeConfig(challengeConfig.value[0], challengeConfig.value[1])">
          </div>
        </div>
        <div class="row line-border-bottom"></div>
      </div>
    </div>
  </div>


<div class="general-settings-challengesize-section mt-28" *ngIf="challengeSizeform">
  <form [formGroup]="challengeSizeform" autocomplete="off">
  <div class="m-0 p-0">
    <div class="row general-settings-challengesize-inner  m-0 align-items-center">
     <div class="col-12 col-sm pr-0 pb-2 pb-sm-0"> <b>{{ "shared-component.generalSettings.numberOfChallenges" | translate }}*</b></div>
      <div class="col-12 col-sm-5 pr-sm-0">
      <!--  <input appNumbersOnly="true" type="text" formControlName="retrieved_challenges_size" class="form-control">-->


        <div class="col-10 col-sm-10 p-0 d-flex justify-content-end align-items-center " >
          <label class="item-status count-status" >
            {{ (challengesConfigObj?.retrieved_challenges_size == challenges?.length) ? ('idvconfiguration.all' | translate ) : challengesSize }}
          </label>


          <button class="editCusProfButton circle-actions"
                 (click)="editRetrievedChallenges(challengesConfigObj?.retrieved_challenges_size)" >
            <span class="foo-icon foo-edit-primary"></span>
          </button>
        </div>

        <!--<small [class.invisible]="!((challengeSizeform.get('retrieved_challenges_size').touched || challengeSizeform.get('retrieved_challenges_size').dirty) && challengeSizeform.get('retrieved_challenges_size').hasError('required'))"
               class="text-danger position-absolute">
          {{"validations.required_field" | translate}}
        </small>
        <small [class.invisible]="!challengessizeinvalid"
               class="text-danger position-absolute">
          {{"idvconfiguration.challengesnumbererror" | translate}}
        </small>-->
      </div>
      <!--<div class="col-12 col-sm-auto text-end">
        <button (click)="saveOneFieldChallengeConfig()" [disabled]="challengeSizeform.pristine || challengeSizeform.invalid" class="foo-btn-primary foo-btn-center">
          {{ "team.save" | translate}}
        </button>
      </div>-->
    </div>
  </div>
  </form>
</div>


<div class="row width-690 mt-10">
    <div class="col-lg-9 col-md-9 col-xs-12 col-sm-12 col-12">
        <div class="general-settings-subTitle">
            {{ "shared-component.generalSettings.livenessChallenges" | translate }}
        </div>
    </div>

    <div class="col-lg-3 col-md-3 col-xs-12 col-sm-12 col-12 column-reverse">
        <button (click)="!orderChanged ? orderButtonClicked = true : saveOrder()"
            *ngIf="!challengesConfigObj['challenges_shuffled'] && permissions.can_update" [ngClass]="!orderChanged ? 'foo-btn-outline-secondary min-w170' : 'foo-btn-primary min-w170'">
            {{ !orderChanged ? ("shared-component.generalSettings.reOrder" | translate) : ("shared-component.generalSettings.saveOrder" | translate) }}</button>
    </div>
</div>

<div class="general-settings-first-section mt-9">
    <div class="m-0 p-0" cdkDropList (cdkDropListDropped)="drop($event)">
        <div *ngFor="let challenge of challenges" cdkDrag
            [cdkDragDisabled]="challengesConfigObj['challenges_shuffled'] && orderButtonClicked">
            <div class="row general-settings-line m-0">
                <div class="col-lg-10 col-md-10 col-8 pr-0 pl-24 pt-31">
                    <span class="general-settings-subTitle">{{ getChallengeName(challenge.challenge_name) }}</span>
                </div>
                <div class="col-lg-2 col-md-2 col-4 pt-24">
                    <div class="row">
                        <div class="col-6">
                            <input class="switch_1" *ngIf="permissions.can_update" type="checkbox"
                                [(ngModel)]="challenge.enabled" [checked]="challenge.enabled"
                                (change)="toggleChallenge(challenge)">
                        </div>
                        <div class="col-6">
                            <div *ngIf="!challengesConfigObj['challenges_shuffled'] && orderButtonClicked">
                                <button class="circle-actions cursor-move">
                                  <span class="foo-icon foo-menu-primary"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row line-border-bottom"></div>
        </div>
    </div>
</div>

<ng-container *ngIf="secret_key">
    <div class="general-settings-subTitle" class="d-none">
        {{ "shared-component.generalSettings.secretKey" | translate }}
    </div>

    <div class="general-settings-second-section mt-28" class="d-none">
        <div class="m-0 p-0">
            <div class="row general-settings-line m-0">
                <div class="col-md-10 col-lg-10 col-8 pr-0 pl-24 pt-31">
                    <span class="general-settings-subTitle">{{ secret_key }}</span>
                </div>
                <div class="col-lg-2 col-md-2 col-4 pt-24">
                    <button class="btn box-shadow-none"
                    [cdkCopyToClipboard]="secret_key">
                    <span class="foo-icon foo-copy-primary"></span>
                </button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
