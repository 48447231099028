<foo-control-label [templateOptions]="templateOptions" [control]="control"></foo-control-label>

<div class="input-number-container">
<input class="form-control"
       [appNumbersOnly]="true"
       [suppressFractions]="!templateOptions?.allowFractions"
       [fractionCount]="templateOptions?.fractionsCount"
       [maxlength]="templateOptions?.maxLength"
       [placeholder]='templateOptions?.placeholder | translate'
       (beforeinput)="templateOptions?.onBeforeInput && templateOptions?.onBeforeInput($event)"
       (input)="templateOptions?.onInput && templateOptions?.onInput($event)"
       [formControl]="control"
       [type]="'text'"
       inputmode="numeric"
/>
  <span class="number-amount" *ngIf="templateOptions?.currency">{{ templateOptions?.currency | translate }}</span>
</div>

<foo-control-errors *ngIf="!templateOptions?.readonly && !templateOptions?.hideErrors"  [control]="control" [errorMessages]="errors"></foo-control-errors>
