<button class="close-icon" mat-button mat-dialog-close><span class="foo-icon foo-close-dark"></span></button>
<div mat-dialog-title>
  <p>{{"idvconfiguration.displayChallenges" | translate}}</p>
</div>

<mat-dialog-content formValidationContainer class="mt-10">
  <form   [formGroup]="challengeSizeform" formValidation autocomplete="off"
        id="ngForm"    #formChallenges="ngForm">

    <div class="form-group">
    <label class="form-label">{{ "idvconfiguration.challengesToDisplay" | translate }}</label>
    <div class="form-group">
      <mat-radio-group formControlName="add_type">
        <mat-radio-button value="all"  (click)="checkType('all')">{{'idvconfiguration.all' | translate}}</mat-radio-button>
        <mat-radio-button value="choosenumber"  (click)="checkType('choosenumber')" class="ml-111" >{{'idvconfiguration.choosenumber' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
    </div>

    <div class="form-group">
      <label class="form-label">{{ "idvconfiguration.number_of_challenges" | translate }}</label>
      <input [readOnly]="disablechallengeSizeInput" [min]="minVal"
        [appNumbersOnly]="true" type="text" [placeholder]="'idvconfiguration.enter_number' | translate"
             formControlName="retrieved_challenges_size" class="form-control">


      <small [class.invisible]="!((challengeSizeform.get('retrieved_challenges_size').touched || challengeSizeform.get('retrieved_challenges_size').dirty) && challengeSizeform.get('retrieved_challenges_size').hasError('required'))"
              class="text-danger position-absolute">
         {{"validations.required_field" | translate}}
       </small>

      <small [class.invisible]="!challengesMinSizeInvalid || challengessizeinvalid || challengeSizeform.get('retrieved_challenges_size').hasError('required')"
             class="text-danger position-absolute">
        {{"idvconfiguration.challengesMinNumberError" | translate}}
      </small>

       <small [class.invisible]="!challengessizeinvalid || challengesMinSizeInvalid || challengeSizeform.get('retrieved_challenges_size').hasError('required')"
              class="text-danger position-absolute">
         {{"idvconfiguration.challengesnumbererror" | translate}}
       </small>
    </div>

    <div class="form-group mt-40">
    <label class="form-label">  {{"idvconfiguration.less_than_enabled_challenges" | translate}} </label>
    </div>

  </form>
</mat-dialog-content>

<div mat-dialog-actions class="pt-8">
  <button (click)="onSubmit()" [disabled]="(!(challengeSizeform.get('retrieved_challenges_size').touched || challengeSizeform.get('retrieved_challenges_size').dirty || challengeSizeform.get('add_type').touched || challengeSizeform.get('add_type').dirty)) || challengeSizeform.invalid"
          class="foo-btn-primary foo-btn-center">
    {{ "team.save" | translate}}
  </button>
</div>
