<button class="close-icon" mat-dialog-close><span class="foo-icon foo-close-dark"></span></button>
<div mat-dialog-title>
  <p>{{ dialogTitle | translate }}</p>
</div>
<mat-dialog-content class="mt-0 pt-0" [class.shimmer-animate]="dataShimmer[activeTab]">

  <div class="row foo-tabs-menu mb-20" *ngIf="tabsConfig.length > 1">
    <div class="col-12">
      <ul class="nav nav-pills auto-slider">
        <swiper [slidesPerView]="'auto'" [spaceBetween]="0" [navigation]="false">
          <ng-template swiperSlide *ngFor="let tab of tabsConfig; let i = index">
            <li (click)="changeTab(tab.key)">
              <a href="javascript:void(0)" [class.active]="activeTab == tab.key">
                {{ tab.title | translate}}
                ({{selectedCount[tab.key]}})
              </a>
            </li>
          </ng-template>
        </swiper>
      </ul>
    </div>
  </div>

  <ng-container *ngIf="activeTab">
    <search-bar *ngIf="tabs[activeTab]?.search" [shimmer]="dataShimmer[activeTab]" [hasFilter]="false"
      [hasButton]="false" [isModal]="true" [placeholder]="tabs[activeTab]?.search?.placeholder | translate"
      [class]="tabs[activeTab]?.search?.class" (onSearchEvent)="searchData($event, activeTab)" [navTab]="activeTab"
      [searchIconBtn]="false" [filterIconBtn]="false" (onClearEvent)="clearSearchInput($event, activeTab)"></search-bar>

    <ng-container *ngIf="items[activeTab]?.length">
      <div class="foo-checkbox-option shimmer-row all-checkbox-row pt-16">
        <ng-container *ngIf="!searchText[activeTab]">
          <mat-checkbox *ngIf="tabs[activeTab]?.canSelectAll" [class.shimmer-item]="dataShimmer[activeTab]"
            [disabled]="dataShimmer[activeTab]" class="input-checkbox fw-bold" [class.mr-10]="!dataShimmer[activeTab]"
            [class.mr-12]="dataShimmer[activeTab]"
            [ngModel]="allIsChecked[activeTab] && totalCount[activeTab] === selectedCount[activeTab]"
            (change)="selectUnselectAll($event,activeTab)">
          </mat-checkbox>
          <span class="fw-bold shimmer-item">
            <ng-container *ngIf="tabs[activeTab]?.totalCount">
              {{tabs[activeTab]?.totalCount?.label | translate }}
            </ng-container>
            <ng-container *ngIf="tabs[activeTab]?.totalCount">
              ({{ totalCount[activeTab] }})
            </ng-container>
          </span>
        </ng-container>
        <div class="row line-border-bottom" *ngIf="!selectedCount[activeTab] && !searchText[activeTab]"
          [hidden]="dataShimmer[activeTab]"></div>
        <div class="row items-count fw-bold ml-0 mt-16 mb-16"
          *ngIf="selectedCount[activeTab]" [hidden]="dataShimmer[activeTab]">
          {{selectedCount[activeTab]}} {{ tabs[activeTab]?.selectedCount?.label | translate }}
        </div>
      </div>
    </ng-container>
    <div class="row">
      <!-- Tab panes -->
      <div class="tab-content clearfix pl-0">
        <div class="tab-pane active">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12" *ngIf="items[activeTab]?.length">
              <dynamic-listing (onGetDataEvent)="getData($event, activeTab)"
                [paginationInput]="paginationInput[activeTab]" [dataInput]="items[activeTab]"
                [labelsInput]="tabs[activeTab]?.labels" [dataShimmer]="dataShimmer[activeTab]" class="codes-list">
              </dynamic-listing>
              <ng-template [frameworkListingTemplate]="'checkbox'" let-item="item">
                <mat-checkbox [class.shimmer-item]="dataShimmer[activeTab]" class="input-checkbox mr-2"
                  *ngIf="!loadedItemsById[activeTab]?.[getItemWithKeyProp(activeTab, item)]">
                </mat-checkbox>
                <mat-checkbox
                  [disabled]="tabs[activeTab]?.getIsDisabled ? tabs[activeTab]?.getIsDisabled(item) : false"
                  [class.shimmer-item]="dataShimmer[activeTab]" class="input-checkbox mr-2"
                  *ngIf="item && loadedItemsById[activeTab]?.[getItemWithKeyProp(activeTab, item)]"
                  [(ngModel)]="loadedItemsById[activeTab][getItemWithKeyProp(activeTab, item)]['checked']"
                  (click)="$event.stopPropagation();" (change)="selectChanged($event,activeTab, item)">
                </mat-checkbox>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>

  </ng-container>

</mat-dialog-content>
<div mat-dialog-actions class="pt-8">
  <button type="button" (click)="submit()" [class]="button?.class" [disabled]="dataShimmer[activeTab]">
    {{ button?.label | translate }}
  </button>
</div>
