import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import {DataStorageService, TokenStorage} from 'foo-framework';

@Component({
  selector: 'news-box',
  templateUrl: './news-box.component.html',
  styleUrls: ['./news-box.component.scss']
})
export class NewsBoxComponent implements OnInit {

    title:string="";
    text:string="";
    button:string="";
    data:any;
    curLang : string;
    @Input() item;
    @Input() hasShimmer = true;
    public constructor(private _router: Router,private DataStorage : DataStorageService, private tokenStorage: TokenStorage) {
        // if(this.DataStorage.storage){
        //     this.data = this.DataStorage.storage;
        //     this.title = this.data["title"];
        //     this.text = this.data["text"];
        //     this.button = this.data["button"];
        // }else{
        //     this._router.navigate(['/home']);
        // }
    }

  ngOnInit(): void {
    this.curLang = this.tokenStorage.getCurrentLanguage();
    // setTimeout(() => {
    //   this.hasShimmer = false;
    // }, 1000);
  }
}
