<div class="row mt-10">
  <div class="col-12">


    <ng-container *ngFor="let accordion of data">
      <mat-accordion class="w-100">
        <mat-expansion-panel class="mb-10">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="col-6 col-sm-6 p-0">
                <b>{{accordion.title | translate}}</b>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <add-introduction-form
            *ngIf=" accordion?.template == 'add-introduction-form' && configurations.length != 0"
            [data]="configurations" [api_url]="api_url" [api_url_upload_icon]="api_url_upload_icon"
            [permissions]="permissions" [app_id]="app_id" [app_name]="app_name">
          </add-introduction-form>

          <add-step1-done-form
            *ngIf=" accordion?.template == 'add-step1-done-form' && configurations.length != 0"
            [data]="configurations" [api_url]="api_url" [permissions]="permissions" [app_id]="app_id" [app_name]="app_name">
          </add-step1-done-form>

          <add-step2-done-form
            *ngIf=" accordion?.template == 'add-step2-done-form' && configurations.length != 0"
            [data]="configurations" [api_url]="api_url" [permissions]="permissions" [app_id]="app_id" [app_name]="app_name">
          </add-step2-done-form>

        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>


    <ng-container *ngIf="configurations.length != 0">
      <mat-accordion class="w-100">
        <mat-expansion-panel class="mb-10">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="col-6 col-sm-6 p-0">
                <b>{{ "shared-component.appInfo.documentTypes.title" | translate }}</b>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <add-document-types-form *ngIf="configurations.length != 0" [data]="configurations" [api_url]="api_url"
                                   [api_url_upload_main_icon_document_types]="api_url_upload_main_icon_document_types"
                                   [api_url_upload_document_icon]="api_url_upload_document_icon"
                                   [permissions]="permissions" [app_id]="app_id" [app_name]="app_name">
          </add-document-types-form>

        </mat-expansion-panel>
      </mat-accordion>

    </ng-container>

  </div>
</div>
