import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatDialogConfig} from '@angular/material/dialog';
import {MatDialogRef} from '@angular/material/dialog';
import {FooPopupComponent} from './foo-popup.component';
import {TranslateService} from '@ngx-translate/core';
import {get} from 'lodash';

@Injectable()
export class FooPopupService {

  constructor(
    private matDialog: MatDialog,
    private translate: TranslateService
  ) {
  }

  open<T, D = any, R = any>(config?: MatDialogConfig<D> & {
    componentInfo?: {
      module: any;
      componentName: string;
      component: any;
    }
  }): MatDialogRef<FooPopupComponent<T>, R> {
    const dialog = this.matDialog.open<FooPopupComponent<T>>(FooPopupComponent,
      {
        direction: this.translate.currentLang === 'ar' ? 'rtl' : 'ltr',
        width: '100%',
        height: '100%',
        panelClass: 'app-custom-dialog-container',
        backdropClass: 'app-customer-backdrop',
        ...config,
        data: {
          ...(get(config, 'data') || {}),
        }
      });
    return dialog;
  }
}
