<div class="container selfOnboardingContainer">
  <div class="row pt-35 pb-30 pl-25 pr-25">
    <div class="col-6">
      <img src="{{logo}}" width="100"/>
    </div>
    <div class="col-6 text-end">
      <div (click)="onLogout()" class="logoutButtonDiv">
        <span _ngcontent-tbj-c173="" class="foo-icon foo-logout-third icon-img mr-8 align-middle"></span>
        <span>{{"auth.onboarding.logout" | translate}}</span>
      </div>
    </div>
  </div>
  <div *ngIf="category_answer_id" class="row pb-15 pl-35 pr-35">
    <div class="col-12">
      <div class="d-inline-block">
       <span class="appStatusText">{{"auth.onboarding.applicationStatus" | translate}}</span>
      </div>
      <div class="d-inline-block pl-0">
        <span class="pl-2 foo-status-warning">
          <span class="font-weight-400 text-capitalize">{{"auth.onboarding.pending" | translate}}</span></span></div>
    </div>
  </div>

  <framework-webforms-viewer [class.hasCategoryId]="category_answer_id" (onAction)="onAction($event)" [params]="webformParams" [permissions]="{can_update: 'false'}"></framework-webforms-viewer>
</div>
