import {Directive, Input, OnInit, TemplateRef} from '@angular/core';
import {ListingTemplatesService} from './listing-templates.service';

@Directive({
  selector: '[frameworkListingTemplate]',
})
export class ListingTemplateDirective implements OnInit{
  @Input() frameworkListingTemplate: any;
  constructor(
    private listingTemplateService: ListingTemplatesService,
    private templateRef: TemplateRef<any>,
  ) {}

  ngOnInit(): void {
    this.listingTemplateService.addTemplate(this.frameworkListingTemplate, this.templateRef);
  }

}
