import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, LoaderService, MakeCallService } from 'foo-framework';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {

  constructor(private alert: AlertService,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<any>,
    private loaderService: LoaderService,
    private makeCall: MakeCallService,
    private translate: TranslateService,) { }
  ngOnInit(): void {

  }

  onSubmit() {
    if (this.data.apiUrl && this.data.apiParams) {
      if (!this.loaderService.visibility) {
        this.loaderService.visibility = true;
        this.makeCall.postCall(this.data.apiUrl, this.data.apiParams).subscribe({
          next: (resp: any) => {
            if (resp.status?.toLowerCase() === 'success' || resp.status?.toLowerCase() === 'ok') {
              this.alert.emitAlert({type: 'success', text: resp['message']});
              this.dialogRef.close('submit');
            } else {
              this.alert.emitAlert({type: 'danger', text: resp['message']});
            }
            this.loaderService.visibility = false;
          },
          error: err => {
            this.loaderService.visibility = false;
            this.alert.emitAlert({type: 'danger', text: this.translate.instant('general.somethingWentWrong')});
          }
        });
      }
    } else {
      this.dialogRef.close(true);
    }
  }

}
