<ng-container *ngIf="isString(tooltip)||tooltip?.hasOwnProperty('message'); else stackIcon">
  <div class="foo-icon foo-stack {{icon?.['containerClass']}} overflow-visible"
       #tooltipID="matTooltip"
       [matTooltipClass]="tooltip?.['className']"
       [matTooltipPosition]="tooltip?.['position']||'above'"
       [matTooltip]="(isString(tooltip)?tooltip:tooltip?.['message']) | translate"
  >
    <ng-container *ngTemplateOutlet="iconsTemplate;context:{icon:icon}">
    </ng-container>
  </div>
</ng-container>
<ng-template #stackIcon>
  <div class="foo-icon foo-stack {{icon?.['containerClass']}} overflow-visible">
    <ng-container *ngTemplateOutlet="iconsTemplate;context:{icon:icon}">
    </ng-container>
  </div>
</ng-template>

<ng-template #iconsTemplate let-icon="icon">
  <span class="foo-icon {{isString(icon)?icon: icon?.['iconClass']}}"></span>
  <span *ngIf="icon?.['stackIconClass']" class="foo-icon {{icon['stackIconClass']}}"></span>
</ng-template>
