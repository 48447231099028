<div class="row mb-30">
    <div class="col-12 col-sm-6 col-md-6 col-lg-6">
        <lottie-player src="/assets/calender.json"  background="transparent"  speed="1" class="lottie-loader" autoplay></lottie-player>
    </div>
</div>
<div class="row">
    <div class="col-12 col-sm-6 col-md-6 col-lg-6">
        <h2 class="big-title">{{ "general.pagenotfound" | translate }}</h2>
        <p class="description mb-30">{{ "general.pagenotfoundtext" | translate }}</p>
        <button (click)="goBack()" class="foo-btn-primary  nomargin">{{ "general.goback" | translate }}</button>
    </div>
</div>
