<div class="login_content" [attr.dir]="isrtl ? 'rtl' : 'ltr'">
  <div class="login_body_container">
    <div class="login_body" formValidationContainer>
      <div class="col-12 col-md-12 col-sm-12 col-lg-12 px-0" *ngIf="showLang">
        <div class="foo-btn-outline-secondary langSwitch" *ngIf="showLang" (click)='useLanguage();'>
          <span>{{ "menu.lang" | translate }} </span>
        </div>
      </div>
      <div class="col-12 col-md-12 col-sm-12 col-lg-12 centered mb-24">
        <img class="login-logo" alt="login-logo" src="{{logo}}" />
        <p class="form-title">{{projectName}}</p>
        <p class="title-desc">{{ "general.logintoyouraccount" | translate}}</p>
      </div>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="col-12 col-md-12 col-sm-12 col-lg-12 p-0" formValidation autocomplete="off">
        <div class="login-notices" *ngIf="successFieldShow">
          <div class="success-field">
            <span>{{ successMessage | translate}}</span>
          </div>
        </div>
        <div class="form-group mb-24">
          <label class="form-label login-label">{{ "auth.emailAddress" | translate}}</label>
          <input type="text" formControlName="username" class="form-control mb-5px" placeholder='{{ "auth.enterEmailAddress" | translate}}' />
          <small *ngIf="(loginForm.get('username').touched || loginForm.get('username').dirty) && loginForm.get('username').hasError('required')"
                 class="text-danger">
            {{"validations.required_field" | translate}}
          </small>
        </div>
        <div class="form-group mb-32">
          <label class="form-label login-label">{{ "general.password" | translate}}</label>
          <input class="form-control mb-5px" formControlName="pin" [class.input-pin]="!browserCredentialsSave" [type]="browserCredentialsSave ? 'password' : 'text'" placeholder='{{ "general.enterpassword" | translate}}'>
          <small *ngIf="(loginForm.get('pin').touched || loginForm.get('pin').dirty) && loginForm.get('pin').hasError('required')"
                 class="text-danger">
            {{"validations.required_field" | translate}}
          </small>
        </div>
        <div class="form-group" *ngIf="false">
          <mat-checkbox class="form-control" formControlName="rememberMe" >{{ "auth.remember_me" | translate }}</mat-checkbox>
        </div>
        <div class="form-group mb-28">
          <button type="submit" class="foo-btn-primary btn-block">{{ "general.login" | translate}}</button>
        </div>
        <div class="form-group no-mb">
          <div (click)="forgotPass()"  class="forgot-password"><span class="bold-hover foo-text-primary">{{ "general.forgotPasswordQues" | translate}}</span></div>
        </div>
      </form>

      <div *ngIf="selfOnboardingCategoryKey" class="selfOnboardingCategoryDiv mt-30 pt-25">
        <button (click)="goToSelfOnboardingSignup()"
                class="foo-btn-outline-secondary foo-btn-center w-100">{{"auth.onboarding.selfOnboarding" | translate}}</button>
      </div>
      
      <ng-container *ngIf="customButtons">
        <ng-container [ngTemplateOutlet]="customButtons"></ng-container>
      </ng-container>
    </div>
  </div>
  <div class="login-footer" *ngIf="showCopyright || showDesignedDevelopedByFoo">
    <div class="reserved" *ngIf="showCopyright">&copy; Copyright {{currentYear}} {{projectName}}</div>
    <div class="designed" *ngIf="showDesignedDevelopedByFoo">Designed and developed by FOO_</div>
  </div>
</div>

