import {Component, OnInit, Input, Output, EventEmitter, ViewChild, SimpleChanges} from '@angular/core';
import { SelectablePropertyViewerOption } from './interfaces/selectable-property-viewer-option.interface';
import { MatSelect } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { MakeCallService } from 'foo-framework';
import _, { isFunction } from 'lodash';

@Component({
  selector: 'framework-selectable-property-viewer',
  templateUrl: './selectable-property-viewer.component.html',
  styleUrls: ['./selectable-property-viewer.component.scss']
})
export class SelectablePropertyViewerComponent implements OnInit {

  @Input() options: Array<SelectablePropertyViewerOption>;
  @Input() value;
  @Input() disable: boolean;
  @Input() readonly: boolean = false;
  @Output() valueChange = new EventEmitter<any>();

  @ViewChild(MatSelect) select: MatSelect;

  protected predefinedOptions: {
    [key:string]: SelectablePropertyViewerOption
  } = {
    "ACTIVE": {
      value: "ACTIVE",
      className: "status-box-green",
      optionDisplay: this.translate.instant('general.optionActive'),
      display: this.translate.instant('team.active'),
    },
    "INACTIVE": {
      value: "INACTIVE",
      className: "status-box-failed",
      optionDisplay: this.translate.instant('general.optionInactive'),
      display: this.translate.instant('team.inactive'),
    },
    "PENDING": {
      value: "PENDING",
      className: 'status-box-pending',
      optionDisplay: this.translate.instant('general.optionPending'),
      display: this.translate.instant('team.pending'),
    },
    "ONGOING": {
      value: "ONGOING",
      className: 'status-box-light',
      optionDisplay: this.translate.instant('general.optionOngoing'),
      display: this.translate.instant('general.ongoing'),
    },
    "REJECTED": {
      value: "REJECTED",
      className: 'status-box-failed',
      optionDisplay: this.translate.instant('general.optionRejected'),
      display: this.translate.instant('general.rejected'),
    },
    "CANCELLED": {
      value: "CANCELLED",
      className: 'status-box-failed',
      optionDisplay: this.translate.instant('general.optionCancelled'),
      display: this.translate.instant('general.cancelled'),
    },
    "SUSPENDED": {
      value: "SUSPENDED",
      display: this.translate.instant('sva.statusSuspended'),
      optionDisplay: this.translate.instant('sva.optionSuspend'),
      className: 'status-box-failed'
    },
    "CLOSED": {
      value: 'CLOSED',
      display: this.translate.instant('sva.statusClosed'),
      optionDisplay: this.translate.instant('sva.optionClose'),
      className: 'status-box-gray'
    }
  }

  class;
  selectedItem: SelectablePropertyViewerOption;
  constructor(
    protected translate: TranslateService,
    public makeCall: MakeCallService
  ) { }

  ngOnInit(): void {
    this.initPreDefinedOptions();
    const selectedItem = this.options?.find(item => item.value === this.value);
    if(selectedItem && selectedItem.className){
      this.selectedItem = selectedItem;
      this.class = selectedItem.className;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      this.initPreDefinedOptions();
      const selectedItem = this.options?.find(item => item.value === this.value);
      if(selectedItem && selectedItem.className){
        this.selectedItem = selectedItem;
        this.class = selectedItem.className;
      }
    }
  }

  initPreDefinedOptions(){
    if(this.options?.length == 1){
      this.readonly = true;
    }
    //If no options are present, options is set equal to preDefinedOptions
    if(!(this.options?.length)){
      this.readonly = true;
      this.options = (Object.values(this.predefinedOptions))
    }
    else{
      this.options = this.options.map((item) => ({
          ...(this.predefinedOptions[item.predefinedValue || item.value] || {}),
          ...item
      }))
    }
  }

  selectionChange(event) {
    this.valueChange.emit(event.value);
  }

  isFunction(item){
    return isFunction(item);
  }

  shouldShowOption(item: SelectablePropertyViewerOption): boolean {
    if (item.hasOwnProperty('isShown') && item.value != this.value) {
      if (isFunction(item.isShown)) {
        return item.isShown();
      } else {
        return item.isShown;
      }
    } else {
      return true;
    }
  }
}