import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';

@Injectable()
export class UrlHelperService {
    constructor(private http: HttpClient) {
    }

    get(url: string): Observable<any> {
        const options= new HttpHeaders()
        .set('Content-Type', 'blob')
        .set('Access-Control-Allow-Origin', '*')
        .set('x-session', 'eyJhbGciOiJIUzI1NiJ9.eyJhdXRoZW50aWNhdGVkIjpmYWxzZSwic2Vzc2lvbl9pZCI6IjljOTcxOGEzLTRhMzQtNDc3ZC04NzAzLTk2MTY4ZTY1ZWMyMSIsImRldmljZV9pZCI6NTIsInVzZXJfaWQiOjc2NywiYXBwX2lkIjoxfQ.WBaVHmnhsaPYDGdEfQaA3QQ-aVn7zQx7LPZ_q-LaQrY')
        .set('x-agent', 'windows 8, postman, Google Chrome, 1.0.0, 3x')
        .set('x-lang', 'en')
        .set('x-device', 'windows 8, postman, Google Chrome, 1.0.0, 3x');
        return new Observable((observer: Subscriber<any>) => {
            let objectUrl: string = null;

            this.http
                .get(url, { headers: options, responseType: 'blob'})
                .subscribe(m => {
                    objectUrl = URL.createObjectURL(m);
                    observer.next(objectUrl);
                });

            return () => {
                if (objectUrl) {
                    URL.revokeObjectURL(objectUrl);
                    objectUrl = null;
                }
            };
        });
    }
}
