import { Injectable } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Observable } from 'rxjs';


interface BaseConfigs {
  googleRecaptchaEnabled?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {

  constructor(private reCaptchaV3Service: ReCaptchaV3Service) {}

  handleRecaptcha(action: string, configs: BaseConfigs): Observable<string> {
    if (window['google_recaptcha']) {
      let reCaptchaEnabledForAPI = !!window['google_recaptcha_enabled_for_all_api'];

      if (configs?.googleRecaptchaEnabled !== undefined) {
        reCaptchaEnabledForAPI = configs.googleRecaptchaEnabled;
      }

      if (reCaptchaEnabledForAPI) {
        return this.reCaptchaV3Service.execute(action);
      }
    }
    
   // If GOOGLE_reCAPTCHA is not true, return an observable with a null token
   return new Observable<string>((observer) => {
    observer.next(null);
    observer.complete();
  });
  }
}
