import {Component, forwardRef, Injector, Input, OnInit, TemplateRef, ViewChild, ViewChildren} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {get, unionBy, isArray, flatten, isFunction, isObject} from 'lodash';
import {AlertService} from 'foo-framework';
import {UntypedFormArray, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ControlValueAccessorConnectorComponent} from '../control-value-accessor-connector.component';
import {FooTemplatesService} from '../foo-templates.service';
import { NgSelectComponent } from '@ng-select/ng-select';


const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FooSearchableSelectComponent),
  multi: true
};


export interface FooSelectData {
  count: number;
  entries: any[];
  lastPage: number;
}

@Component({
  selector: 'foo-searchable-select',
  templateUrl: './foo-searchable-select.component.html',
  styleUrls: ['./foo-searchable-select.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class FooSearchableSelectComponent extends ControlValueAccessorConnectorComponent implements OnInit {
  @Input() selectedByKeys: ((keys: string[]) => Observable<any[]>) | any[];
  @Input() pageSize = 20;
  @Input() placeholder = 'general.select';
  @Input() closeOnSelect = true;
  @Input() multiple = false;
  @Input() readonly = false;
  @Input() clearable = false;
  @Input() keyProp: string = null;
  @Input() titleProp = 'name';
  @Input() optionTemplate = 'selectDefault';
  @Input() labelTemplate = 'selectDefault';

  @Input() headerTemplate = null;
  @Input() footerTemplate = null;
  @Input() multiLabelTemplate = null;

  @Input() hideSelected;

  @Input() searchable = true;
  @Input() clearSearchOnAdd = true;

  // special cases params
  @Input() uniqueWithinFormArray = false;

  @Input() debounceTime = 300;

  @Input('excludedValues')
  set excludedValues(value) {
    this._excludedValues = value;
    this.handleExcludedValues(this.excludedValues);
  }

  @Input('dataSource')
  set dataSource(value: (term: string, page: number, pageSize: number) => Observable<FooSelectData>) {
    if (value !== this._dataSource) {
      this._dataSource = value;
      this.pageNumber = 1;
      this.items = [];
      this.isLoading = true;
      this.refreshSelectedByKeys();
      this.getData();
    }
  }

  get excludedValues(): any[] {
    return this._excludedValues;
  }

  get dataSource(): any {
    return this._dataSource;
  }

  private _excludedValues = [];
  private _dataSource = null;

  items: any[] = [];
  isLoading = false;
  isLoadingAfterAdd = false;

  isPageAlreadyLoaded = false;

  searchTerm = '';

  pageNumber = 1;

  searchTerm$ = new Subject();

  selectedByKeysByFunc: any[] = [];

  @ViewChild('select', {static: false}) selectControl: NgSelectComponent;

  constructor(
    protected alert: AlertService,
    private fooTemplateService: FooTemplatesService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.checkUniqueValues(this.controlContainer.control.value);
    this.controlContainer.control.valueChanges.subscribe({
      next: values => {
        this.checkUniqueValues(values);
      }
    });
    this.searchTerm$.pipe(
      debounceTime(this.debounceTime),
    ).subscribe((value: string) => {
      this.pageNumber = 1;
      this.searchTerm = value;
      this.isLoading = true;
      this.getData();

    });
    if (!this.dataSource) {
      this.refreshSelectedByKeys();
    }
  }

  refreshSelectedByKeys() {
    if (isFunction(this.selectedByKeys)) {
      (this.selectedByKeys(this.multiple ? this.control.value : [this.control.value]) as Observable<any[]>).subscribe({
        next: (values) => {
          this.selectedByKeysByFunc = values || [];
          this.items = unionBy(this.items || [], this.selectedByKeysByFunc, this.keyProp || 'id');
          this.checkUniqueValues(this.controlContainer.control.value);
          this.adjustSelectedValuesAfterApiCalling();
        }
      });
    } else {
      this.items = unionBy(this.items || [], this.selectedByKeys || [], this.keyProp || 'id');
    }
  }

  adjustSelectedValuesAfterApiCalling(): void {
    if (this.multiple) {
      this.control.setValue((this.control.value || []).map(v => {
        const updatedValue = (this.selectedByKeysByFunc || []).find(i => this.compareWith(i, v));
        if (isObject(v) && updatedValue) {
          return updatedValue;
         } else {
          return v;
         }
      }));
    } else {
     if (isObject(this.control.value) && this.selectedByKeysByFunc?.length &&  this.compareWith(this.selectedByKeysByFunc[0], this.control.value)) {
      this.control.setValue(this.selectedByKeysByFunc[0]);
     }
    }
  }

  checkUniqueValues(values: any[]): void {
    if (this.uniqueWithinFormArray && this.controlContainer.control instanceof UntypedFormArray) {
      if (this.multiple) {
        values = flatten(values);
      }
      values = values.concat(this.excludedValues || []);
      this.handleExcludedValues(values);
    }
  }

  handleExcludedValues(values): void {
    this.items = this.items.map(item => (!this.keyProp && (values).map(v => v ? v.id : null).includes(item.id)) || (this.keyProp && (values).includes(item[this.keyProp])) ? {
      ...item,
      disabled: true
    } : {
      ...item,
      disabled: false
    });
  }


  onScrollToEnd($event) {
    if (!this.isPageAlreadyLoaded) {
      this.isLoading = true;
      this.getData();
    }
  }

  onSearch($event) {
    this.searchTerm$.next($event.term);
  }

  getData(): void {
    if (!this.dataSource) {
      return;
    }
    const searchTerm = this.searchTerm;
    this.dataSource(this.searchTerm, this.pageNumber, this.pageSize).subscribe({
        next: (data: FooSelectData) => {
          if (this.searchTerm !== searchTerm) {
            return;
          }
          if (this.pageNumber == 1) {
            if (isArray(this.selectedByKeys)) {
              const selectedValues = this.multiple ? (this.control.value || []) : [this.control.value];
              const selectedItems = (this.items || []).filter(i => selectedValues.includes(i[this.keyProp || 'id']));
              const items = unionBy(data.entries, this.selectedByKeys, this.keyProp || 'id');
              this.items = unionBy(items, selectedItems, this.keyProp || 'id');
            } else {
              const selectedValues = this.multiple ? (this.control.value || []) : [this.control.value];
              const selectedItems = (this.items || []).filter(i => selectedValues.includes(i[this.keyProp || 'id']));
              const items = unionBy(data.entries || [], this.selectedByKeysByFunc, this.keyProp || 'id');
              this.items = unionBy(items, selectedItems, this.keyProp || 'id');
            }
          } else {
            this.items = unionBy(this.items, data.entries, this.keyProp || 'id');
          }
          this.checkUniqueValues(this.controlContainer.control.value);
          this.handleExcludedValues(this.excludedValues);
          if (this.pageNumber < data.lastPage) {
            this.pageNumber++;
            this.isPageAlreadyLoaded = false;
          } else {
            this.isPageAlreadyLoaded = true;
          }
          this.isLoadingAfterAdd = false;
          this.isLoading = false;
        },
        error: error => {
          this.alert.emitAlert({type: 'danger', text: error.message});
        }
      }
    );
  }

  compareWith(a: any, b: any) {
    if (!this.keyProp) {
      return a.id == b.id;
    } else {
      return a[this.keyProp] == b;
    }
  }

  searchFn(term: string, item: any): boolean {
    return true;
  }


  onAdd($event) {
    if (this.searchTerm != '') {
      this.searchTerm = '';
      this.pageNumber = 1;
      this.isLoadingAfterAdd = true;
      this.getData();
    }
  }


  onOpen($event): void {
     if (this.isLoadingAfterAdd) {
      this.isLoading = true;
     }
  }

  getTemplate(id: string): TemplateRef<any> {
    return this.fooTemplateService.getTemplate(id);
  }

}
