import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {NgbDropdown} from "@ng-bootstrap/ng-bootstrap";
import {flatten, has, pick} from "lodash";

export enum SortingTypes {
  ALPHABETICALLY = 'ALPHABETICALLY',
  DATE = 'DATE',
  CUSTOM = 'CUSTOM'
}

export interface SortingConfig {
  type: SortingTypes;
  sort_field: string;
  desc_label?: string;
  asc_label?: string;
}

export declare type SortingConfigs = SortingConfig[];


@Component({
  selector: 'list-sorting',
  templateUrl: './list-sorting.component.html',
  styleUrls: ['./list-sorting.component.scss']
})
export class ListSortingComponent implements OnInit, OnChanges {

  @ViewChild(NgbDropdown) dropdown: NgbDropdown;

  @Input() config: SortingConfigs = [];


  @Output() onSortingChanged = new EventEmitter<{
    sort_field: string;
    sort_direction: string;
  }>();

  @Input() shimmer = false;

  @Input() initialSortingState = null;

  predefinedLabels = {
    [SortingTypes.DATE]: {
      desc: 'sorting.newToOld',
      asc: 'sorting.oldToNew'
    },
    [SortingTypes.ALPHABETICALLY]: {
      desc: 'sorting.alphabeticallyDesc',
      asc: 'sorting.alphabeticallyAsc'
    }
  };

  selectedOption = null;

  sortingOptions: any[] = [];

  constructor() { }

  ngOnInit(): void {
    this.initSortingTypes();
    this.setSortingState();
  }


  chooseOption(option): void {
    this.selectedOption = option;
    this.dropdown.close();
    this.onSortingChanged.emit(pick(option, ['sort_field', 'sort_direction']));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (has(changes,'config')) {
       this.initSortingTypes();
    }
  }

  isOptionSelected(option): boolean {
    return this.isEqual(this.selectedOption, option);
  }

  initSortingTypes(): void {
    this.sortingOptions = flatten((this.config || []).map(item => [
      {
        ...item,
        label: item.asc_label,
        sort_direction: 'asc'
      },
      {
        ...item,
        label: item.desc_label,
        sort_direction: 'desc'
      }
    ]));
  }

  setSortingState():void {
     if (this.initialSortingState) {
       this.selectedOption = (this.sortingOptions || []).find(option => this.isEqual(option, this.initialSortingState));
     } else {
       this.selectedOption = null;
     }
  }

  isEqual(option1, option2): boolean {
    return option1?.sort_field === option2?.sort_field && option1?.sort_direction === option2?.sort_direction;
  }
}
