<button class="close-icon" mat-dialog-close><span class="foo-icon foo-close-dark"></span></button>
<div mat-dialog-title>
  <p *ngIf="data.mode=='edit'">{{ data.settings_title }}  {{ "shared-component.countries.popup.settingsTitle" | translate}}</p>
</div>

<mat-dialog-content formValidationContainer class="mt-10">
  <form (ngSubmit)="onSubmit(selected_flow_id)" [formGroup]="form" formValidation autocomplete="off" id="ngForm" #editStepsForm="ngForm">

    <ng-container *ngIf="input_form.capture_screen">

      <div class="form-group">

        <div class="header-form-group">
          {{ "shared-component.countries.popup.firstNgContainerTitle" | translate }}
        </div>

        <label class="form-label">{{ "shared-component.countries.popup.firstField" | translate }}<span>*</span></label>

        <input [placeholder]="'shared-component.countries.popup.placeHolderFirstField' | translate" class="form-control"
          formControlName="capture_screen_title" type="text" [(ngModel)]="input_form.capture_screen.title">

        <small
          *ngIf="(form.get('capture_screen_title').touched || form.get('capture_screen_title').dirty) && form.get('capture_screen_title').hasError('required')"
          class="text-danger">
          {{"validations.required_field" | translate}}
        </small>

        <small
          *ngIf="(form.get('capture_screen_title').touched || form.get('capture_screen_title').dirty) && form.get('capture_screen_title').hasError('maxlength')"
          class="text-danger">
          {{"validations.maxLength" | translate: { maxnumber: 240 } }}
        </small>
      </div>

      <div class="form-group" *ngIf="data.input_form.input_type != 'FACE'">
        <label class="form-label">{{ "shared-component.countries.popup.hintField" | translate }}<span>*</span></label>

        <input [placeholder]="'shared-component.countries.popup.placeHolderHintField' | translate" class="form-control"
               formControlName="capture_screen_hint" type="text" [(ngModel)]="input_form.capture_screen.hint">

        <small
          *ngIf="(form.get('capture_screen_hint').touched || form.get('capture_screen_hint').dirty) && form.get('capture_screen_title').hasError('required')"
          class="text-danger">
          {{"validations.required_field" | translate}}
        </small>

        <small
          *ngIf="(form.get('capture_screen_hint').touched || form.get('capture_screen_hint').dirty) && form.get('capture_screen_title').hasError('maxlength')"
          class="text-danger">
          {{"validations.maxLength" | translate: { maxnumber: 240 } }}
        </small>
      </div>


      <!--Removed Button Text Field-->
      <!--<div class="form-group">

        <label class="form-label">{{ "shared-component.countries.popup.secondField" | translate }}<span>*</span></label>

        <input [placeholder]="'shared-component.countries.popup.placeHolderSecondField' | translate"
          class="form-control" formControlName="capture_screen_button_title" type="text"
          [(ngModel)]="input_form.capture_screen.button_title">

        <small
          *ngIf="(form.get('capture_screen_button_title').touched || form.get('capture_screen_button_title').dirty) && form.get('capture_screen_button_title').hasError('required')"
          class="text-danger">
          {{"validations.required_field" | translate}}
        </small>


        <small
          *ngIf="(form.get('capture_screen_button_title').touched || form.get('capture_screen_button_title').dirty) && form.get('capture_screen_button_title').hasError('maxlength')"
          class="text-danger">
          {{"validations.maxLength" | translate: { maxnumber: 240 } }}
        </small>
      </div>-->

    </ng-container>

    <ng-container *ngIf="input_form.preview_screen && input_form.preview_screen.visible">

      <div class="form-group mt-24">

        <div class="header-form-group">
          {{ "shared-component.countries.popup.secondNgContainerTitle" | translate }}
        </div>

        <framework-uploader [extensions]="'JPG, PNG'" [fileSize]="8" [fileType]="'image'"
          [formControlName]="'preview_screen_icon'"
          [labelInput]="'shared-component.countries.popup.thirdField'" [showProgress]="true">
        </framework-uploader>

      </div>

      <div class="form-group">

        <label class="form-label">{{ "shared-component.countries.popup.firstField" | translate }}<span>*</span></label>

        <input [placeholder]="'shared-component.countries.popup.placeHolderFirstField' | translate" class="form-control"
          formControlName="preview_screen_title" type="text" [(ngModel)]="input_form.preview_screen.title">

        <small
          *ngIf="(form.get('preview_screen_title').touched || form.get('preview_screen_title').dirty) && form.get('preview_screen_title').hasError('required')"
          class="text-danger">
          {{"validations.required_field" | translate}}
        </small>

        <small
          *ngIf="(form.get('preview_screen_title').touched || form.get('preview_screen_title').dirty) && form.get('preview_screen_title').hasError('maxlength')"
          class="text-danger">
          {{"validations.maxLength" | translate: { maxnumber: 240 } }}
        </small>
      </div>

      <div class="form-group" *ngIf="data.input_form.input_type != 'FACE'">
        <label class="form-label">{{ "shared-component.countries.popup.hintField" | translate }}<span>*</span></label>

        <input [placeholder]="'shared-component.countries.popup.placeHolderHintField' | translate" class="form-control"
               formControlName="preview_screen_text" type="text" [(ngModel)]="input_form.preview_screen.text">

        <small
          *ngIf="(form.get('preview_screen_text').touched || form.get('preview_screen_text').dirty) && form.get('preview_screen_text').hasError('required')"
          class="text-danger">
          {{"validations.required_field" | translate}}
        </small>

        <small
          *ngIf="(form.get('preview_screen_text').touched || form.get('preview_screen_text').dirty) && form.get('preview_screen_text').hasError('maxlength')"
          class="text-danger">
          {{"validations.maxLength" | translate: { maxnumber: 240 } }}
        </small>
      </div>

      <div class="form-group">

        <label class="form-label">{{ "shared-component.countries.popup.secondField" | translate }}<span>*</span></label>

        <input [placeholder]="'shared-component.countries.popup.placeHolderSecondField' | translate"
          class="form-control" formControlName="preview_screen_button_title" type="text"
          [(ngModel)]="input_form.preview_screen.button_title">

        <small
          *ngIf="(form.get('preview_screen_button_title').touched || form.get('preview_screen_button_title').dirty) && form.get('preview_screen_button_title').hasError('required')"
          class="text-danger">
          {{"validations.required_field" | translate}}
        </small>

        <small
          *ngIf="(form.get('preview_screen_button_title').touched || form.get('preview_screen_button_title').dirty) && form.get('preview_screen_button_title').hasError('maxlength')"
          class="text-danger">
          {{"validations.maxLength" | translate: { maxnumber: 240 } }}
        </small>
      </div>

    </ng-container>

    <ng-container *ngFor="let test of input_form.tests">

      <div class="form-group  mt-24">

        <div class="header-form-group">
          {{ test.name }}
        </div>

        <label class="form-label">{{test.name}} %<span>*</span></label>

        <div class="min-righticon-container">
          <input class="form-control" [appNumbersOnly]="true" [placeholder]="test.name + ' %'" [formControlName]="'test_' + test.id" type="text"/>
          <span class="min-righticon-form">
            <img src="/assets/img/percentage.svg" alt="percentage"/>
          </span>
        </div>

        <small
          *ngIf="(form.get('test_' + test.id).touched || form.get('test_' + test.id).dirty) && form.get('test_' + test.id).hasError('required')"
          class="text-danger">
          {{"validations.required_field" | translate}}
        </small>

        <small
          *ngIf="(form.get('test_' + test.id).touched || form.get('test_' + test.id).dirty) && form.get('test_' + test.id).hasError('min')"
          class="text-danger">
          {{"validations.minimumNumber" | translate: { number: 0 } }}
        </small>

        <small
          *ngIf="(form.get('test_' + test.id).touched || form.get('test_' + test.id).dirty) && form.get('test_' + test.id).hasError('max')"
          class="text-danger">
          {{"validations.maximumNumber" | translate: { number: 100 } }}
        </small>

      </div>

    </ng-container>

    <ng-container *ngIf="withNFC && input_form.nfc && input_form.nfc.supported">

      <div class="form-group">
        <label class="form-label bold-line">{{ ( selected_flow?.document?.name =='PASSPORT' ? "shared-component.countries.popup.biometricPassport" : "shared-component.countries.popup.biometricId" ) | translate }}</label>

        <input class="switch_1" type="checkbox" [disabled]="true" [checked]="input_form.nfc.supported"
          [(ngModel)]="input_form.nfc.supported">
      </div>

    </ng-container>

    <ng-container *ngIf="hasNFC && input_form.nfc && input_form.nfc.supported && input_form.nfc.active">

      <div class="form-group">
        <label class="form-label  bold-line">{{ ( selected_flow?.document?.name =='PASSPORT' ? "shared-component.countries.popup.biometricPassport" : "shared-component.countries.popup.biometricId" ) | translate }}</label>

        <input class="switch_1" type="checkbox" [checked]="input_form.nfc.enabled" formControlName="nfc">
      </div>

    </ng-container>

  </form>
</mat-dialog-content>

<div mat-dialog-actions class="pt-28">
  <button form="ngForm" [disabled]="editStepsForm.form.pristine" class="foo-btn-primary  foo-btn-center" type="submit">
    {{"team.save" | translate}}
  </button>
</div>
