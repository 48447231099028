<div [class.hide-arrow]="readonly">
    <mat-form-field class="mat-status-box {{class}}">
        <mat-select [disabled]="readonly || disable || (makeCall.isLoading | async)" panelClass="mat-custom-select-pannel" [(value)]="value" (selectionChange)="selectionChange($event)" disableOptionCentering>
        <mat-select-trigger>
            <ng-container *ngFor="let item of options">
                <span *ngIf="item.value === value">
                    {{ item.display | translate }}
                </span>
            </ng-container>
        </mat-select-trigger>
        <ng-container *ngFor="let item of options">
            <mat-option [value]="item.value" [class.d-none]="item.value == value" *ngIf="shouldShowOption(item)">
                {{ item.optionDisplay ? (item.optionDisplay | translate) : (item.display | translate) }}
            </mat-option>
        </ng-container>
        </mat-select>
    </mat-form-field>
</div>
