import {Component, OnInit, EventEmitter, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';
import {
  GeneralEventService,
  ManageBreadcrumbService,
  RouteService,
  TokenStorage
} from 'foo-framework';
import {Router} from '@angular/router';
import {get} from 'lodash';
import { Direction } from '@angular/cdk/bidi';

@Component({
  selector: 'app-home',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {
  isClosed = false;
  curLang: string;
  subscribegEvent: any;
  @Output() LangChangedEvent = new EventEmitter<string>();
  @Output() BackgroundImageDisplay = new EventEmitter<boolean>();
  showLang = false;
  showLeftMenu = true;
  showLogo = false;
  defaultState;
  ltr = this.translate.instant("general.dir") as Direction | "auto";

  constructor(
    private translate: TranslateService,
    private gEvent: GeneralEventService,
    private location: Location,
    private routeService: RouteService,
    public manageBreadcrumb: ManageBreadcrumbService,
    private router: Router,
    private tokenStorage: TokenStorage
  ) {

    if (!this.tokenStorage.getCurrentLanguage()) {
      this.tokenStorage.setCurrentLanguage('en')
    }
    this.curLang = this.tokenStorage.getCurrentLanguage();
    translate.setDefaultLang(this.curLang);
  }

  ngOnInit(): void {
    this.showLang = JSON.parse(window['showlang']);
    this.showLeftMenu = JSON.parse(window['showleftmenu']);
    this.showLogo = JSON.parse(window['showlogo']);
    this.defaultState = this.tokenStorage.getConfiguration();
    this.tokenStorage.getConfiguration().subscribe(res => {
      this.defaultState= get(res,'default_state') || '/home';
    });
  }

  toggleMenu($event) {
    // this.isClosed = !this.isClosed;
    // this.isClosed = this.routeService.menuClosed;
    //check if event false remove class menuclosed from page-content-wrapper class in pages.component.html
    if($event){
      this.isClosed = this.routeService.menuClosed;
    }else{
      this.isClosed = $event;
    }
  }

  useLanguage(language: any) {
    this.LangChangedEvent.emit(language);
    this.translate.use(language);
    this.tokenStorage.setCurrentLanguage(language)
    this.curLang = language;
  }

  goBack(event): void {
    event.preventDefault();
    event.stopPropagation();
    this.routeService.beforeBackCall().subscribe(value => {
      if (value) {
        if (this.routeService.routesHistory.length > 1) {
          this.router.navigate([`/${this.routeService.routesHistory.slice(0, this.routeService.routesHistory.length - 1).join('/')}`]
            , {
              queryParams: {
                '--keep': true
              }
            });
        } else {
          if (this.defaultState !== null || this.defaultState !== undefined) {
            this.router.navigate([this.defaultState]);
          } else {
            this.router.navigate(['/home']);
          }
        }
      }
    });
  }

}
