import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {from, Observable, Subject} from 'rxjs';
import {TokenStorage} from './token-storage.service';
import {AccessData} from '../interfaces/access-date';
import {Router} from '@angular/router';
import { AlertService } from './alert.service';
import { TranslateService } from '@ngx-translate/core';
import { RecaptchaService } from './recaptcha.service';

@Injectable()
export class AuthenticateService {
  API_URL = 'api/auth';
  API_ENDPOINT_LOGIN = '/login';
  API_ENDPOINT_LOGOUT = '/logout';
  API_ENDPOINT_ONBOARDING_SIGNUP = '/signup';
  API_ENDPOINT_FLUSH_SESSION = '/flushsession';
  API_ENDPOINT_REFRESH: '/refresh';
  public onCredentialUpdated$: Subject<AccessData>;
  public onCancelPendingRequests$: Subject<any>;
  constructor(
    private http: HttpClient,
    private tokenStorage: TokenStorage,
    private router: Router,
    private alert: AlertService,
    private translate: TranslateService,
    private recaptchaService: RecaptchaService
  ) {
    this.onCredentialUpdated$ = new Subject();
    this.onCancelPendingRequests$ = new Subject();
  }

  /**
   * Check, if user already authorized.
   * @description Should return Observable with true or false values
   * @returns {Observable<boolean>}
   * @memberOf AuthService
   */
  // public isAuthorized() {
  //   return this.tokenStorage.getUser().pipe(map(user => !!user));
  // }
  /**
   * Get access token
   * @description Should return access token in Observable from e.g. localStorage
   * @returns {Observable<string>}
   */
  public getAccessToken(): Observable<string> {
    return this.tokenStorage.getAccessToken();
  }

  /**
   * Function, that should perform refresh token verifyTokenRequest
   * @description Should be successfully completed so interceptor
   * can execute pending requests or retry original one
   * @returns {Observable<any>}
   */
  public refreshToken(): Observable<AccessData> {
    return null;
  }

  /**
   * Function, checks response of failed request to determine,
   * whether token be refreshed or not.
   * @description Essentialy checks status
   * @param {Response} response
   * @returns {boolean}
   */
  public refreshShouldHappen(response: HttpErrorResponse): boolean {
    return response.status === 401;
  }
  /**
   * Verify that outgoing request is refresh-token,
   * so interceptor won't intercept this request
   * @param {string} url
   * @returns {boolean}
   */
  public verifyTokenRequest(url: string): boolean {
    return url.endsWith(this.API_ENDPOINT_REFRESH);
  }

  /**
   * Get user
   * @description Should return access token in Observable from e.g. localStorage
   * @returns {Observable<string>}
   */
  public getUser() {
    return this.tokenStorage.getUser();
  }

  /**
   * Get user roles
   * @returns {Observable<any>}
   */
  public getUserRoles(): Observable<any> {
    return this.tokenStorage.getUserRoles();
  }


  /**
   * Get languages
   * @returns {any}
   */
  public getLanguages(): any {
    return this.tokenStorage.getLanguages();
  }

  /**
   * Get configuration
   * @returns {Observable<any>}
   */
  public getConfiguration(): Observable<any> {
    return this.tokenStorage.getConfiguration();
  }

  /**
   * Submit login request
   * @param {Credential} credential
   * @returns {Observable<any>}
   */

  login(username: string, password: string, remember: string, reCaptchaEnabledForAPI?: boolean, url: string = null, extraData: Object = {}): Observable<any> {
    const apiUrl = url && url !== '' ? url : this.API_URL + this.API_ENDPOINT_LOGIN;
    return this.recaptchaService.handleRecaptcha(apiUrl, { googleRecaptchaEnabled: reCaptchaEnabledForAPI }).pipe(
      switchMap((recaptchaToken: string | null) => {
        let dataToSend: any = { username, password, remember, ...extraData };
        if (recaptchaToken) {
          dataToSend = { ...dataToSend, reCaptchaToken: recaptchaToken };
        }
        return this.http.post(apiUrl, dataToSend)
          .pipe(map((response: any) => {
            if (response instanceof Array) {
              return response.pop();
            }
            return response;
          }),
            tap(this.saveAccessData.bind(this)),
            catchError(this.handleError('login', []))
          );
      })
    );
  }


  /**
   * Submit onboarding signup request
   * @param {Credential} credential
   * @returns {Observable<any>}
   */
  signup(username: string, password: string, app_id, remember:string, reCaptchaEnabledForAPI?: boolean): Observable<any> {
    return this.recaptchaService.handleRecaptcha(this.API_URL + this.API_ENDPOINT_ONBOARDING_SIGNUP, {googleRecaptchaEnabled: reCaptchaEnabledForAPI}).pipe(
      switchMap((recaptchaToken: string | null) => {
        let dataToSend: any = { username, password, app_id, remember}
        if (recaptchaToken) {
          dataToSend = { ...dataToSend, reCaptchaToken: recaptchaToken };
        }
        return this.http.post(this.API_URL + this.API_ENDPOINT_ONBOARDING_SIGNUP , dataToSend)
        .pipe(map((response: any) => {
            if (response instanceof Array) {
              return response.pop();
            }
            return response;
          }),
          tap(this.saveAccessData.bind(this)),
          catchError(this.handleError('signup', []))
        );
      })
    );   
  }
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: any) {
    return (error: any): Observable<any> => {
      // TODO: send the error to remote logging infrastructure
      console.log(error); // log to console instead
      let msg = error.message;
      if (!msg){
        msg = this.translate.instant('general.somethingWentWrong');
      }
      this.alert.emitAlert({type: 'danger', text: msg});

      // Let the app keep running by returning an empty result.
      return from(result);
    };
  }

  public cancelPendingRequests() {
    this.onCancelPendingRequests$.next(true);
  }

  public logout(): Observable<any> {
    return this.http.post(this.API_URL + this.API_ENDPOINT_LOGOUT, {})
      .pipe(map((response: any) => {
        if (response instanceof Array) {
          return response.pop();
        }
        return response;
      }));
  }

  public flushsession(): Observable<any> {
    return this.http.post(this.API_URL + this.API_ENDPOINT_FLUSH_SESSION, {})
      .pipe(map((response: any) => {
        if (response instanceof Array) {
          return response.pop();
        }
        return response;
      }));
  }

  /**
   * Save access data in the storage
   * @private
   * @param {AccessData} data
   */
  private saveAccessData(accessData: AccessData) {
    if (accessData['status'] === 'OK') {
      this.tokenStorage
        .setUser(JSON.stringify(accessData.user))
        .setUserRoles(JSON.stringify([accessData.role]))
        .setLanguages(JSON.stringify(accessData.languages))
        .setConfiguration(JSON.stringify(accessData.configuration));
      this.onCredentialUpdated$.next(accessData);
    }
  }
}
