import { Pipe, PipeTransform } from '@angular/core';

export let dataTranslation={
  menu: [],
  other: [],
  breadcrumb: []
};

@Pipe({
  name: 'TranslationFormat'
})

export class TranslationFormatPipe implements PipeTransform {



  transform(value: string, type:string,curLang:string): string {
    var ans='';
    dataTranslation[type].forEach(element => {
          if(element.title == value){
            element.translations.forEach(item => {
                if(item["lang"] == curLang){
                  ans = item["title"];
                }

              });
          }
      });
    return ans;
  }

}
