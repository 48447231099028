import { DOCUMENT } from '@angular/common';
import { Directive, HostBinding, HostListener, Inject} from '@angular/core';
@Directive({
  selector: '[alphabetsOnly]',
})
export class AlphabetsOnlyDirective {

  @HostBinding('autocomplete') public autocomplete
  constructor(@Inject(DOCUMENT) private document: Document) {
    this.autocomplete = 'off'
  }
  @HostListener('keypress', ['$event']) public disableKeys(e: any) {
    return (
      e.keyCode === 13 || e.keyCode === 8 || (e.keyCode === 32 && e.target.value != '') ||
      (e.keyCode >= 97 && e.keyCode <= 122) ||
      (e.keyCode >= 65 && e.keyCode <= 90)
    )
  }

  @HostListener('paste', ['$event']) onPaste(event) {
    event.preventDefault();
    const re = /[^a-zA-Z\s]/gi ;
    const respaces = /^\s*$/gi ;
    const pasteData = event.clipboardData.getData('text/plain').replace(re , '').replace(respaces, '');
    document.execCommand('insertHTML', false, pasteData);
  }



}
