import { Directive, Input, ElementRef, Renderer2, HostListener } from '@angular/core';
import { Sort } from '../utils/sort';
import { isString } from 'lodash';


@Directive({
  selector: '[appSort]'
})
export class SortDirective {

  @Input() appSort: Array<any>;

  @Input() groupSelector: any | string = null;
  
  private sortOrder = 1;

  constructor(private renderer: Renderer2, private targetElem: ElementRef) { }

  @HostListener("click", ['$event'])
  sortData(event: any) {
    event.preventDefault();
    event.stopPropagation();
    // Create Object of Sort Class
    const sort = new Sort();
    // Get Reference Of Current Clicked Element
    const elem = this.targetElem.nativeElement;
    // Get In WHich Order list should be sorted by default it should be set to desc on element attribute
    const order = elem.getAttribute("data-order")?elem.getAttribute("data-order"):"desc";
    // Get The Property Type specially set [data-type=date] if it is date field
    const type = elem.getAttribute("data-type");
    // Get The Property Name from Element Attribute
    const property = elem.getAttribute("data-label");
    if (order === "desc") {
      this.appSort.sort(sort.startSort(property, order, type));
      elem.setAttribute("data-order", "asc");
    }
    else {
      this.appSort.sort(sort.startSort(property, order, type));
      elem.setAttribute("data-order", "desc");
    }

     (isString(this.groupSelector)  ?  document.querySelector(this.groupSelector) : this.groupSelector)?.querySelectorAll('[data-order]').forEach(i => {
      if (i.getAttribute("data-label") !== property) {
        i.setAttribute("data-order", "");
      } else {
        if (order === "desc") {
          i.setAttribute("data-order", "asc");
        }
        else {
          i.setAttribute("data-order", "desc");
        }
      }
    })
  }

}