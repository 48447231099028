import {Component, forwardRef, Injector, Input, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {FooControlComponent} from "../foo-control/foo-control.component";

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FooInputComponent),
  multi: true
};

@Component({
  selector: 'foo-input',
  templateUrl: './foo-input.component.html',
  styleUrls: ['./foo-input.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class FooInputComponent extends FooControlComponent implements OnInit {
  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
  }

}
