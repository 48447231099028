import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { get, entries } from 'lodash';
import {UtilsService} from './utils.service';
import {MakeCallService} from './makecall.service';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class ApprovalService {
  approvalResult: {
    referenceId?: number;
    rejectionReason?: string;
    status: 'PENDING' | 'APPROVED' | 'REJECTED';
    mainMessage: string;
  } = null;

  selectedCategory: any = null;
  selectedStatus: string;
  selectedItem: any = null;
  refresh = new BehaviorSubject<boolean>(false);
  constructor(private makecall: MakeCallService, private utils: UtilsService) {}

  get myId(): number {
    return get(JSON.parse(localStorage.getItem('user')), 'id');
  }

  getCategories(): Observable<any> {
    return this.makecall.postCall('approval/all', {});
  }

  getTransactionsByCategory(
    categoryName: string,
    status: any,
    isMyRequests: boolean,
    paginationParams: {
      page: number;
    },
    sortingParams: {
      sort_field: string;
      sort_direction: string;
    },
    filter,
    searchTerm: string
  ): Observable<any> {
    const params = this.utils.urlParam({
      key: categoryName,
      my_requests: isMyRequests,
      status,
      ...((searchTerm || '').trim() ? {tag: searchTerm.trim()} : {}),
      ...paginationParams,
      ...sortingParams,
      ...entries(filter || {}).reduce((memo, [key, value]) => value ? ({...memo, [key]: value }) : memo, {})
    });
    return this.makecall.postCall(
      `approval/transactions?${params}`,
      {}
    );
  }

  getTransactionDetails(transactionId: number, isRole = 0): Observable<any> {
    return this.makecall.postCall(
      `approval/transactionsdetails?id=${transactionId}&is_role=${isRole}`,
      {}
    );
  }

  approve(transactionGroupId: number): Observable<any> {
    return this.makecall.postCall(
      `approval/approve?id=${transactionGroupId}`,
      {}
    );
  }

  reject(transactionGroupId: number, rejection_reason): Observable<any> {
    return this.makecall.postCall(`approval/reject?id=${transactionGroupId}`, {
      rejection_reason,
    });
  }
  refreshData() {
    this.refresh.next(true);
  }
}
