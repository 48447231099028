<foo-control-label [templateOptions]="templateOptions" [control]="control"></foo-control-label>

<ng-container *ngIf="(templateOptions?.showNoSelectionOption || templateOptions.disableOption || templateOptions.hideOption) && !templateOptions.dataSource">
  <ng-select appNgSelectCustom
             class="form-control"
             [multiple]="templateOptions?.multiple"
             [selectReadonly]="templateOptions?.readonly"
             (change)="templateOptions?.onChange && templateOptions?.onChange($event)"
             [placeholder]='placeholder | translate'
             [hideSelected]="true"
             [formControl]="control">
    <ng-container *ngIf="templateOptions?.showNoSelectionOption">
      <ng-option [value]="null">{{ noSelectionOptionText | translate }}</ng-option>
    </ng-container>
    <ng-container *ngFor="let item of templateOptions?.items; let index = index;">
      <ng-option
        *ngIf="templateOptions.hideOption ? templateOptions.hideOption(item[keyProp]) : true"
        [disabled]="templateOptions.disableOption ? templateOptions.disableOption(item[keyProp]) : false"
        [value]="item[keyProp]">
        <ng-container
          *ngTemplateOutlet="getTemplate(optionTemplate); context: { item: item, index: index}">
        </ng-container>
      </ng-option>
    </ng-container>
  </ng-select>
</ng-container>

<ng-container *ngIf="!templateOptions?.showNoSelectionOption && !templateOptions.disableOption && !templateOptions.hideOption &&  !templateOptions.dataSource">
  <ng-select appNgSelectCustom
             class="form-control"
             [selectReadonly]="templateOptions?.readonly"
             [items]="templateOptions?.items"
             [bindLabel]="titleProp"
             [bindValue]="keyProp"
             (change)="templateOptions?.onChange && templateOptions?.onChange($event)"
             [formControl]="control"
             [placeholder]="placeholder | translate"
             [hideSelected]="true"
  >
    <ng-template ng-label-tmp let-item="item" *ngIf="!templateOptions?.multiple">
      <ng-container
        *ngTemplateOutlet="getTemplate(labelTemplate); context: { item: item}">
      </ng-container>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-index="index">
      <ng-container
        *ngTemplateOutlet="getTemplate(optionTemplate); context: { item: item, index: index}">
      </ng-container>
    </ng-template>
  </ng-select>
</ng-container>

<ng-container *ngIf="templateOptions.dataSource">
  <foo-searchable-select
    [dataSource]="templateOptions.dataSource"
    [formControl]="control"
    [multiple]="templateOptions.multiple"
    [titleProp]="templateOptions.titleProp"
    [keyProp]="templateOptions.keyProp"
    [placeholder]="templateOptions.placeholder"
    [labelTemplate]="templateOptions.labelTemplate"
    [optionTemplate]="templateOptions.optionTemplate"
    [clearable]="true"
  >

  </foo-searchable-select>
</ng-container>



<foo-control-errors *ngIf="!templateOptions?.readonly && !templateOptions?.hideErrors"  [control]="control" [errorMessages]="errors"></foo-control-errors>

<ng-template [frameworkTemplate]="'selectDefault'" let-item="item">
  {{item[titleProp]| translate}}
</ng-template>
