<div class="row foo-tabs-menu mb-20">
  <div class="col-12">
    <ul class="nav nav-pills auto-slider">
      <swiper [slidesPerView]="'auto'" [spaceBetween]="0" [navigation]="false">
        <ng-container *ngFor="let tab of tabs">
          <ng-template swiperSlide *ngIf="checkIfTabHasPermission(tab?.key)" >
            <li (click)="tabChange(tab?.key)" [class.disabled]="checkFunctionVal(tab?.isDisabled,tab?.key)">
              <a href="javascript:void(0)" [class.active]="tab.key==activeTab" [class]="tab?.className">{{ checkFunctionVal(tab?.title,tab?.key)| translate }}</a>
            </li>
          </ng-template>
        </ng-container>

      </swiper>
    </ul>
  </div>
</div>
