import { Component, Input, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DynamicListingService } from '../../../services/dynamic-listing.service';
import _, { isArray, isEqual } from 'lodash';

@Component({
  selector: 'list-of-columns-as-key-value',
  templateUrl: './list-of-columns-as-key-value.component.html',
  styleUrls: ['./list-of-columns-as-key-value.component.scss']
})
export class ListOfColumnsAsKeyValueComponent implements OnInit {

  detailsObject: any;

  @Input() item = {};// dynamic listing row item
  @Input() index = 0;
  @Input() set detailsObj(value: any) {
    this.detailsObject = value;
  }
  @Input() boxView = false;
  @Input() expansionPanelData = {};// with dynamic listing to get expan data by api
  @Input() isShimmeringDetails: boolean[] | boolean = false;
  @Input() showSeparator = false;


  constructor(protected sanitizer: DomSanitizer,
    protected dynamicListingService: DynamicListingService) {
  }

  ngOnInit(): void {
    if (this.detailsObject?.data?.length) {
      this.dynamicListingService.drawColumns(this.detailsObject);
    }
  }

  columnHasValues(arr): boolean {
    for (const obj of arr) {
      // Check if the object is empty and doesn't have the 'value' property
      if (Object.keys(obj).length === 0 && obj.constructor === Object && !('value' in obj)) {
        continue;
      }
      return true;
    }
    return false;
  }

  getSecureStyle(style: string): any {
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

  getObjKeys(obj) {
    return (Object.keys(obj)).filter(key => key.startsWith('col_'));
  }

  calcFlexBasis(obj) {
    return 'calc(' + (100 / this.getObjKeys(obj).length) + '%' + ')';
  }

  calculateFlexBasisForGapsBetweenColumns(obj) {
    return 'calc(' + (100 / ((this.getObjKeys(obj).length * 2) - 1)) + '%' + ')';
  }

  objectIsEmpty() {
    let detailsObjectClone = _.cloneDeep(this.detailsObject)
    return this.getObjKeys(detailsObjectClone).every(function (key) {
      let val = detailsObjectClone[key];
      return Array.isArray(val) && !val.length
    });
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes && !isEqual(changes.detailsObj.currentValue, changes.detailsObj.previousValue)) {
      const detailsObj: SimpleChange = changes.detailsObj;
      if (detailsObj.currentValue?.data){
        this.dynamicListingService.drawColumns(detailsObj.currentValue);
      }
    }
  }

  isArray(object: any){
    return isArray(object);
  }
}
