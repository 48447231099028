import {
  Component,
  forwardRef,
  Injector,
  EventEmitter,
  Input,
  Output,
  ElementRef,
  ViewChild,
  OnInit
} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {TranslateService} from "@ngx-translate/core";
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {FooControlComponent} from '../foo-control/foo-control.component';
import {MatChipInputEvent} from "@angular/material/chips";
import {KeyCodesMapping} from "./key-codes-mapping.const";
import {omit} from "lodash";
import {ValidatorPatterns, ValidatorPatternsConfig} from "foo-framework";

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FooTagsInputComponent),
  multi: true
};

@Component({
  selector: 'foo-tags-input',
  templateUrl: './foo-tags-input.component.html',
  styleUrls: ['./foo-tags-input.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class FooTagsInputComponent extends FooControlComponent implements OnInit {
  constructor(
    protected translate: TranslateService,
    injector: Injector
  ) {
    super(injector);
  }

  @Input() addOnBlur: boolean = false;
  @Input() disabled: boolean;
  @Input() id: string;
  @Input() separatorKeyCodes: readonly number[] | ReadonlySet<number> = [ENTER, COMMA] as const;

  @Output() tokenEnd: EventEmitter<MatChipInputEvent> = new EventEmitter();

  @Input() tagPattern: string;
  @ViewChild('tagInput', {static: true}) tagInput: ElementRef<HTMLInputElement>;

  ngOnInit(): void {

  }

  add(event): void {
    const input = event.input;
    const tag = (event.value || '').trim();
    if (tag) {
      if (this.validateTag(tag)) {
        this.addTag(tag);
        if (input) {
          input.value = '';
        }
        const inputEvent: MatChipInputEvent = {
          input: event,
          value: tag,
          chipInput: input
        };
        this.tokenEnd.emit(inputEvent);
      }
    }else{
      const tags = this.control.value || [];
      this.control.setValue(tags);
    }
  }

  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const pastedText = event.clipboardData?.getData('text/plain') || '';
    let separatorCodesArray = Array.from(this.separatorKeyCodes);
    separatorCodesArray = separatorCodesArray.filter(code => code !== 13);
    const separatorCodes = separatorCodesArray.map(code => KeyCodesMapping[code]?.symbol);
    const separatorRegex = new RegExp(separatorCodes.join('|'), 'g');
    const values = pastedText.split(separatorRegex).map(value => value.trim());
    values.forEach(tag => {
      if (tag && this.validateTag(tag)) {
        this.addTag(tag);
      }
    });
    (event.target as HTMLInputElement).value = '';
  }

  addTag(tag: string) {
    const tags = this.control.value || [];
    tags.push(tag.trim());
    this.control.setValue(tags);
    this.control.markAsDirty();
  }

  remove(tag: string): void {
    const tags = this.control.value as string[];
    const index = tags.indexOf(tag);
    if (index >= 0) {
      tags.splice(index, 1);
      this.control.setValue(tags);
    }
  }

  get validation() {
    return ValidatorPatternsConfig?.[this.tagPattern];
  }

  validateTag(tag: any): boolean {
    const pattern = new RegExp(this.validation || this.tagPattern);
    const patternCheck = pattern.test(tag);
    const alreadyAdded = this.control.value ? this.control.value.includes(tag) : false;
    const alreadyAddedError = tag.length && alreadyAdded;
    let hasError: boolean = false;
    if (!patternCheck) {
      this.control.setErrors({...(this.control.errors || {}), 'pattern': this.tagPattern || true});
      hasError = true;
    } else if (alreadyAddedError) {
      this.control.setErrors({...(this.control.errors || {}), 'alreadyAdded': true});
      hasError = true;
    }else {
      this.control.setErrors(omit(this.control.errors || {}, ['pattern', 'alreadyAdded']));
      if (this.control.errors && Object.keys(this.control.errors).length === 0) {
        this.control.setErrors(null);
      }
    }

    return !hasError;
  }
}
