import {Component, forwardRef, Injector, Input, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {FooControlComponent} from "../foo-control/foo-control.component";

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FooTimepickerComponent),
  multi: true
};

@Component({
  selector: 'foo-timepicker',
  templateUrl: './foo-timepicker.component.html',
  styleUrls: ['./foo-timepicker.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class FooTimepickerComponent extends FooControlComponent implements OnInit {

  @Input() clearable: boolean = false;

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
  }

  get icon(): string {
    return this.templateOptions?.icon || 'foo-time-primary';
  }

  get timeFormat(): number {
    return parseInt(this.templateOptions?.format) || 12;
  }

  clearInput(){
    this.control.setValue(null);
  }
}
