import { Injectable } from '@angular/core';
import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class SendOldValuesService {

  old_values = {};

  constructor() { }

  sendOldValues(oldData: any, arrayOfcustomOldDataAsKeyValue: any = []) {
    let oldDataCloned = _.cloneDeep(oldData);
    this.old_values = {
      ...oldDataCloned
    }

    if (arrayOfcustomOldDataAsKeyValue.length) {
      this.updateOldValuesByArray(arrayOfcustomOldDataAsKeyValue);
    }
    return this.old_values;
  }

  updateOldValuesByArray(array) {
    array.forEach(item => {
      this.setValueForPropertyByPath(this.old_values, item.key, item.value);
    });
  }

  setValueForPropertyByPath(obj, path, value) {
    let schema = obj;
    let pList = path.split('.');
    let len = pList.length;
    for (let i = 0; i < len - 1; i++) {
      let elem = pList[i];
      if (!schema[elem]) schema[elem] = {}
      schema = schema[elem];
    }

    schema[pList[len - 1]] = value;
  }
}
