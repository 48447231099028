import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'display-as-html',
  templateUrl: './display-as-html.component.html',
  styleUrls: ['./display-as-html.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom 
})
export class DisplayAsHtmlComponent implements OnInit {

  @Input() data;

  constructor() { }

  ngOnInit(): void {
  }

}
