import {Component, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef, ViewChildren} from '@angular/core';
import {NgbDropdown} from "@ng-bootstrap/ng-bootstrap";
import {FooTemplatesService} from '../foo-templates.service';
import { TokenStorage } from 'foo-framework';
import {FooIconConfig} from '../foo-icon/foo-icon.component';

@Component({
  selector: 'foo-tooltip-menu',
  templateUrl: './tooltip-menu.component.html',
  styleUrls: ['./tooltip-menu.component.scss']
})
export class TooltipMenuComponent {

  @ViewChildren(NgbDropdown)
  dropdowns: QueryList<NgbDropdown>;
  dropdown: NgbDropdown;
  @Input() itemShimmer;
  @Input() items;
  @Input() placement;
  @Input() tooltipToggleIcon: string | FooIconConfig = 'foo-more-light';
  @Input() tooltipToggleClassName = '';
  @Input() tooltipToggleText = '';

  @Output() onFunctionCalledEvent = new EventEmitter<{ functionName: string, functionParams: object }>();

  constructor(
    protected templateService: FooTemplatesService,
    protected tokenStorage: TokenStorage,
  ) {
  }

  ngOnInit(): void {
    if (this.placement === undefined) {
      let curLang = this.tokenStorage.getCurrentLanguage();
      if (curLang === 'ar'){
        this.placement = 'bottom-left';
      } else {
        this.placement = 'bottom-right';
      }
    }
  }

  hoverOpen(dropdown: any): void {
    this.dropdown = this.dropdowns.find(x => (x as any)._nativeElement == dropdown);
    if (!this.dropdown.isOpen()) {
      this.dropdown.open();
    }

  }

  hoverClose(dropdown: any): void {
    this.dropdown = this.dropdowns.find(x => (x as any)._nativeElement == dropdown);
    if (this.dropdown.isOpen()) {
      this.dropdown.close();
    }
  }

  btnFunctionCalled(name, params) {
    this.onFunctionCalledEvent.emit({
      functionName: name,
      functionParams: params
    });
  }

  getTemplate(id: string): TemplateRef<any> {
    return this.templateService.getTemplate(id);
  }
}
