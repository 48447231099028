import {Component, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {AuthenticateService} from '../../services/authenticate.service';
import {TokenStorage} from '../../services/token-storage.service';
import {Router} from '@angular/router';
import {LoaderService} from '../../services/loader/loader.service';
import {AlertService} from '../../services/alert.service';
import {RouteService} from '../../services/route.service';
import {DOCUMENT} from '@angular/common';
import {get} from 'lodash';
import {DataStorageService} from '../../services/data-storage.service';
import { DomainService } from '../../services/domain.service';
import { GeneralConfigService } from '../../services/general-config.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() showLang: boolean;
  @Input() isFullMenu: boolean;
  @Input() showLogo: boolean;
  @Input() logoSrc: string;


  @Input() isFullWidth: boolean;
  @Output() LangChangedEvent = new EventEmitter<string>();
  @Input() LangChanged;
  personName: string;
  lastLogin;
  lastFailedLogin;
  menuItems: any;
  deviceMenuItems: any[];
  defaultState;
  logo: any;
  merchantLogo: string;
  userType: string;
  pinset: string = '';
  curLang: string;
  isrtl() {
    const dir = this.dom.querySelector('body').dir == 'rtl' ? 'rtl' : 'ltr';
    return dir == 'rtl';
  }

  constructor(@Inject(DOCUMENT) private dom,
              private authenticateService: AuthenticateService,
              private router: Router,
              private tokenStorage: TokenStorage,
              private loaderService: LoaderService,
              private alert: AlertService,
              private routerService: RouteService,
              private dataStorage: DataStorageService,
              private generalConfigService: GeneralConfigService,
              public domainService: DomainService
  ) {
  }

  ngOnInit(): void {
    const config = JSON.parse(this.tokenStorage.getWebConfig());
    this.logo = get(config, 'logo') || this.logoSrc;
    var user = JSON.parse(this.tokenStorage.getUser());
    this.merchantLogo = user?.related_info?.image;
    this.userType = user?.user_type;
    this.personName = get(user, 'profile.fname') || '';
    this.lastLogin = this.tokenStorage.getLastLogin() ? JSON.parse(this.tokenStorage.getLastLogin()):'';
    this.lastFailedLogin = this.tokenStorage.getLastFailedLogin() ? JSON.parse(this.tokenStorage.getLastFailedLogin()):'';
    this.menuItems = this.routerService.initMenu("my_account_menu");
    this.deviceMenuItems = this.routerService.initMenu("device_menu");
    /*if(JSON.parse(window['languages']).length>0)
    {
      console.log(JSON.parse(window['languages']));
      this.showLang = true;
    }*/
    this.defaultState = this.tokenStorage.getConfiguration();
    this.tokenStorage.getConfiguration().subscribe(res => {
      this.defaultState = get(res, 'default_state') || '/home';
    });
    this.curLang = this.tokenStorage.getCurrentLanguage();

    var namePath = 'profile.fname';
    if (this.generalConfigService.getConfig().headerConfig?.displayUserFullname) {
      namePath = 'profile.fullname';
    }
    this.personName = get(user, namePath) || '';
    this.tokenStorage.userDetailsSubject.subscribe(user => {
      if (user) {
        this.personName = get(user, namePath) || '';
      }
    });
  }

  getRoutePath(menuItem: any): string {
    if ((this.routerService as any).isNavigationPeventedOnRoute(menuItem)) {
      return null;
    } else {
      return this.domainService.getPrefixedURL(menuItem.path);
    }
  }

  onItemClicked(event: any, menuItem: any): void {
    if ((this.routerService as any).isNavigationPeventedOnRoute(menuItem)) {
      (this.routerService as any).handleCustomRoute(menuItem);
    }
  }

  useLanguage() {
    var language = "en";
    if (this.tokenStorage.getCurrentLanguage() == "en") {
      language = "ar";
    }
    this.LangChangedEvent.emit(language);
    this.tokenStorage.setCurrentLanguage(language);

  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.LangChanged) {
      this.curLang = this.LangChanged;
    }
  }

  onLogout() {
    this.pinset = get(this.dataStorage, 'storage.pinset'); //this.DataStorage.storage['pin_set']

    if(this.pinset == 'pin'){
      window.location.href = (window['prefix'] ? `/${window['prefix']}` : '') + '/login';
    }else{
      this.loaderService.visibility = true;
      this.authenticateService.logout().subscribe(
        (data) => {
          if (data["status"] === 'OK') {
            this.tokenStorage.clear();
            this.routerService.clearParams();
            window.location.href = (window['prefix'] ? `/${window['prefix']}` : '') + '/login';
            this.authenticateService.cancelPendingRequests();
          } else {
            this.loaderService.visibility = false;
            this.alert.emitAlert({type: "danger", text: data["message"]});

          }

        },
        error => {
          this.loaderService.visibility = false;
          this.alert.emitAlert({type: "danger", text: error["message"]});
        });}
  }

  checkList(list) {
    return list !== null && get(list, 'length') > 0;
  }

  goHome() {
    event.preventDefault();
    event.stopPropagation();
    if (this.defaultState !== null || this.defaultState !== undefined) {
      this.router.navigate([this.defaultState]);
    } else {
      this.router.navigate(['/home']);
    }

  }
}
