import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

  reg: RegExp;
  transform(value: any): string {
    if (value) {
      // Gets the value as a string
      if (typeof value !== 'string') {
        value = value.toString();
      }
      // Delete existing spaces
      value = (value as string).replace(' ', '');

      // Add space between each 4 digits
      value = value.replace(/(.{3})(.{2})(.{4})(.{4})/g, ' $1 $2 $3 $4 ');
    }
    return value;
  }

}
