import {Overlay, OverlayConfig} from '@angular/cdk/overlay';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {
  AlertService,
  GeneralEventService,
  LoaderService,
  MakeCallService,
  PermissionsService,
  TokenStorage
} from 'foo-framework';
import {isString} from 'lodash';
import {debounceTime, fromEvent, Subscription} from 'rxjs';
import {EventEmitter} from '@angular/core';
import {BreadcrumbService} from 'xng-breadcrumb';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
  selector: 'framework-webforms-viewer',
  templateUrl: './webforms-viewer.component.html',
  styleUrls: ['./webforms-viewer.component.scss']
})
export class WebformsViewerComponent implements OnInit, OnChanges {
  @Input() params: {
    session: string,
    application_id?: number;
    category_key: string;
    is_self_onboarding?: boolean;
  } | {
    session: string,
    application_id?: number;
    category_answer_id: string;
  } | {
    token: string,
    application_id?: number;
    category_key: string;
    is_self_onboarding?: boolean
  } | {
    token: string,
    application_id?: number;
    category_answer_id: string;
    is_self_onboarding?: boolean;
  } = null;

  @Output() onAction = new EventEmitter();

  @Output() onIframeShwonCheck = new EventEmitter();

  @Input() permissions: any = null;

  @ViewChild('iframe', {static: false}) iframe: ElementRef;

  @ViewChild('overlayTemplate', {static: true}) overlayTemplate: TemplateRef<any>;

  urlSafe: SafeResourceUrl;

  isSummary = false;

  onMessage: any;

  subscription = new Subscription();

  webviewHeight = 0;

  corporateId = null;

  overlayRef = null;

  isOverlayShown = false;

  webformsParams = '';

  popupHeight = 680;
  popupWidth = 553;

  bodyScrollTop = 0;


  constructor(
    private tokenStorage: TokenStorage,
    private alert: AlertService,
    private translate: TranslateService,
    private gEvent: GeneralEventService,
    public sanitizer: DomSanitizer,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private loader: LoaderService) {
      this.onMessage = (event) => {
        const response = this.getMessageData(event);
        if (response.action) {
          this.onAction.emit({
            action: response.action,
            data: response
          });
          switch (response.action) {
            case 'resize':
              if (this.isSummary) {
                this.webviewHeight = (response.scrollHeight || response.clientHeight) + 1;
                this.onIframeShwonCheck.emit(this.webviewHeight > 1);
              } else {
                this.webviewHeight = (response.scrollHeight || response.clientHeight) + 20;
                this.onIframeShwonCheck.emit(this.webviewHeight > 20);
              }
              break;
            case 'loader':
              this.loader.visibility = response.value;
              break;
            case 'alert':
              this.alert.emitAlert(response.value);
              break;
            case 'overlay':
              if (response.visibility) {
                this.showBackDrop();
                this.handleShowBackdrop();
                this.isOverlayShown = true;
              } else {
                this.isOverlayShown = false;
                this.handleHideBackdrop();
                this.overlayRef.dispose();
              }
              break;
          }
        } else {
          this.onAction.emit({
            action: response.navigation,
            data: response
          });
          switch (response.navigation) {
            case 'ERROR':
              this.alert.emitAlert({
                type: 'danger',
                text: response.message || this.translate.instant('general.somethingWentWrong')
              });
              break;
          }
        }
      };
    }

  ngOnInit(): void {
    fromEvent(window, 'resize').pipe(debounceTime(300))
      .subscribe((event: any) => {
         if (this.isOverlayShown) {
          const rect = this.iframe.nativeElement.getBoundingClientRect();
          this.iframe.nativeElement.style.left = parseInt(((Math.max((window.innerWidth - this.popupWidth), 0) / 2)) as any) + 'px';
          this.iframe.nativeElement.style.top = parseInt(((Math.max((window.innerHeight - this.popupHeight), 0) / 2)) as any) + 'px';
          this.iframe.nativeElement.style.maxHeight = Math.min(window.innerHeight, this.popupHeight) + 'px';
          this.iframe.nativeElement.style.minHeight = Math.min(window.innerHeight, this.popupHeight) + 'px';
          this.iframe.nativeElement.style.width = Math.min(window.innerWidth,this.popupWidth) + 'px';
        }
      });

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.params) {
      this.initwebView();
    }
  }

  initwebView(): void {
    if (!this.params) {
      return;
    }
    this.subscription.unsubscribe();
    this.subscription = new Subscription();
    this.loadIframe()
  }

  getMessageData(event): any {
    const domain = new URL(window['webforms_link']);
    if (event.origin === domain.origin && isString(event.data)) {
      try {
        const parsedData = JSON.parse(event.data);
        if (parsedData.action) {
          return parsedData;
        }
        return (parsedData.redirect || '').split('?')[1].split('&').map(param => ({
          [param.split('=')[0]]: param.split('=').length === 2 ? param.split('=')[1] : ''
        })).reduce((memo, curr) => ({...memo, ...curr}), {});
      } catch (e) {
        return {};
      }
    } else {
      return {};
    }
  }

  onload(event): void {
    this.loader.visibility = false;
  }


  parametrizedURL(url: string, queryParams: any): string {
    let parametrizedURL = url;
    if (queryParams) {
      const queryString = Object.keys(queryParams).map(key => key + '=' + queryParams[key]).join('&');
      parametrizedURL += (url.indexOf('?') !== -1) ? '&' + queryString : '?' + queryString;
    }
    return parametrizedURL;
  }

  loadIframe(): void {
    // for breadcrumb display and background image
    this.gEvent.emitEvent({"breadcrumb": true, "background": true});
    this.loader.visibility = true;
    let curLang = this.translate.currentLang || 'en';
    let webformsLink = window['webforms_link'];
    const canEdit = this.permissions?.can_update;

    window.removeEventListener('message', this.onMessage);


    const queryParams = {
      delegate_alert: true,
      delegate_loader: true,
      fullscreen_dialog: true,
      'X-LANG': curLang,
      can_edit: canEdit,
      ...(this.params['extra'] || {}),
    };
    if (this.params['token']) {
      queryParams['X-FOO-TOKEN'] = this.params['token'];
    } else {
      queryParams['X-SESSION'] = this.params['session'];
    }

    if (this.params['category_answer_id']) {
      queryParams['category_answer_id'] = this.params['category_answer_id'];
      webformsLink += '/group-summary';
      this.isSummary = true
    }else if (this.params['category_key'] && this.params['is_self_onboarding']) {
      queryParams['category_key'] = this.params['category_key'];
      webformsLink += '/portal-self-onboarding';
    }else if (this.params['category_key']) {
      queryParams['category_key'] = this.params['category_key'];
      webformsLink += '/group';
    }
    if (this.params['application_id']) {
      queryParams['application_id'] = this.params['application_id'];
    }

    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.parametrizedURL(webformsLink, queryParams));

    this.subscription.add(
      this.translate.onLangChange.subscribe(({lang}) => {
        queryParams['X-LANG'] = lang;
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.parametrizedURL(webformsLink, queryParams));
      })
    );

    window.addEventListener('message', this.onMessage, false);
  }

  handleShowBackdrop(): void {
    this.bodyScrollTop = document.querySelector('html').scrollTop;
    this.iframe.nativeElement.style.zIndex = '100001';
    this.iframe.nativeElement.style.position = 'fixed';
    const rect = this.iframe.nativeElement.getBoundingClientRect();
    this.iframe.nativeElement.style.top = parseInt(((Math.max((window.innerHeight - this.popupHeight), 0) / 2)) as any) + 'px';
    this.iframe.nativeElement.style.left = parseInt( ((Math.max((window.innerWidth - this.popupWidth), 0) / 2)) as any) + 'px';
    this.iframe.nativeElement.style.maxHeight = Math.min(window.innerHeight, this.popupHeight) + 'px';
    this.iframe.nativeElement.style.minHeight = Math.min(window.innerHeight, this.popupHeight) + 'px';
    this.iframe.nativeElement.style.width = Math.min(window.innerWidth,this.popupWidth) + 'px';
    this.iframe.nativeElement.style.borderRadius = '15px';
    this.iframe.nativeElement.style.visibility = 'hidden';
    document.querySelector('body').style.overflowY = 'hidden';
    setTimeout(() => {
      this.iframe.nativeElement.style.visibility = 'visible';
    }, 350);
  }

  handleHideBackdrop(): void {
    this.iframe.nativeElement.style.zIndex = 'initial';
    this.iframe.nativeElement.style.position = 'initial';
    this.iframe.nativeElement.style.top = 'initial';
    this.iframe.nativeElement.style.left = 'initial';
    this.iframe.nativeElement.style.maxHeight = 'initial';
    this.iframe.nativeElement.style.minHeight = 'initial';
    this.iframe.nativeElement.style.width = '100%';
    this.iframe.nativeElement.style.borderRadius = 'initial';
    document.querySelector('body').style.overflowY = 'initial';
    document.querySelector('html').scrollTop = this.bodyScrollTop;
  }

  showBackDrop(): void {
    let config = new OverlayConfig();

    config.positionStrategy = this.overlay.position()
      .global();
    config.backdropClass = 'app-customer-backdrop';

    config.hasBackdrop = true;

    this.overlayRef = this.overlay.create(config);
    const overlayPortal = new TemplatePortal(this.overlayTemplate, this.viewContainerRef);
    this.overlayRef.attach(overlayPortal);

  }

  ngOnDestroy(): void {
    window.removeEventListener('message', this.onMessage);
    this.subscription.unsubscribe();
  }

}
