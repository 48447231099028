import { Component, Input, OnInit } from '@angular/core';
import { DynamicListingService } from '../../../services/dynamic-listing.service';
import _ from 'lodash';

@Component({
  selector: 'list-of-entries',
  templateUrl: './list-of-entries.component.html',
  styleUrls: ['./list-of-entries.component.scss']
})
export class ListOfEntriesComponent implements OnInit {

  @Input() item = {};
  @Input() index = 0;
  @Input() detailsObj;
  @Input() boxView = false;
  @Input() expansionPanelData = {};
  @Input() isShimmeringDetails = [];

  constructor(protected dynamicListingService: DynamicListingService) { }

  ngOnInit(): void {
  }

  checkEmptyObj() {
    return this.dynamicListingService.checkEmptyObj(this.expansionPanelData, this.index);
  }

}
