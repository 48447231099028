<foo-control-label [templateOptions]="templateOptions" [control]="control"></foo-control-label>

<mat-form-field appearance="fill" class="datepicker timepicker">
  <input matInput
         [ngxMatTimepicker]="picker"
         [formControl]="control"
         [format]="timeFormat"
         [max]="$any(templateOptions?.max)"
         [min]="$any(templateOptions?.min)"
         [placeholder]="templateOptions?.placeholder| translate"
         class="input-calendar cursor-pointer"
         readonly>
  <mat-icon matSuffix (click)="picker.open()" class="cursor-pointer">
    <span class="foo-icon" [class]="icon"></span>
  </mat-icon>

  <ng-container *ngIf="control?.value && clearable">
    <div matDatepickerToggleIcon class="custom-clear-input" (click)="clearInput()"><span class="ng-clear"></span></div>
  </ng-container>

  <ngx-mat-timepicker color="primary" #picker class="foo-timepicker" ></ngx-mat-timepicker>

</mat-form-field>


<foo-control-errors *ngIf="!templateOptions?.readonly && !templateOptions?.hideErrors" [control]="control"
                    [errorMessages]="errors"></foo-control-errors>
