<div class="login_content">
  <div class="login_body_container">
    <div class="login_body" formValidationContainer>

      <div class="col-12 col-md-12 col-sm-12 col-lg-12 px-0" *ngIf="showLang">
        <div class="foo-btn-outline-secondary langSwitch" *ngIf="showLang" (click)='useLanguage();'>
          <span>{{ "menu.lang" | translate }} </span>
        </div>
      </div>

      <div class="col-12 col-md-12 col-sm-12 col-lg-12 mb-24 centered">
            <img class="login-logo" alt="login-logo" src="{{logo}}" />
            <p class="big-title">{{ "general.forgotPassword" | translate}}</p>
            <p class="title-desc">{{ "general.emailSentToAccount" | translate}}</p>
        </div>
      <form [formGroup]="emailForm" (ngSubmit)="onSubmit()" formValidation class="col-12 col-md-12 col-sm-12 col-lg-12 p-0">
            <div class="form-group mb-32">
                <label class="form-label">{{"auth.emailAddress" | translate}}</label>
                <input class="form-control" formControlName="email"  type="email" placeholder='{{"auth.enterEmailAddress" | translate}}' />
              <small *ngIf="(emailForm.get('email').touched || emailForm.get('email').dirty) && emailForm.get('email').hasError('email')"
                     class="text-danger">
                {{"validations.required_field" | translate}}
              </small>
              <small *ngIf="emailForm.get('email').touched && emailForm.get('email').hasError('email')"
                     class="text-danger">
                {{"general.invalidemail" | translate}}
              </small>
            </div>

            <div class="form-group mb-16">
                <button type="submit" class="foo-btn-primary btn-block">{{ "auth.submit" | translate}}</button>
            </div>
            <div class="form-group mb-0">
              <button class="back-btn foo-btn-outline-secondary" (click)="goBack()"><span class="bold-hover">{{ "auth.back" | translate}}</span></button>
            </div>
        </form>
    </div>
  </div>
  <div class="login-footer" *ngIf="showCopyright || showDesignedDevelopedByFoo">
    <div class="reserved" *ngIf="showCopyright">&copy; Copyright {{currentYear}} {{projectName}}</div>
    <div class="designed" *ngIf="showDesignedDevelopedByFoo">Designed and developed by FOO_</div>
  </div>
</div>

