import {ElementRef, HostListener} from '@angular/core';
import {ViewChild} from '@angular/core';
import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {fromEvent, Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, startWith} from 'rxjs/operators';
import {DataStorageService, SearchFiltersService, TokenStorage} from 'foo-framework';
import {MatDialog} from "@angular/material/dialog";
import { UntypedFormControl } from '@angular/forms';
import { isObject } from 'lodash';
import { SearchResultService } from '../services/search-result.service';

@Component({
  selector: 'search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {

  title: string = "";
  text: string = "";
  button: string = "";
  data: any;
  searchText = "";
  @ViewChild('searchInput', {static: false}) searchInput: ElementRef;
  @Input() fiterPanelState = 'closed';
  @Input() shimmer;
  @Input() placeholder = 'general.searchbykeyword';
  @Input() hasFilter = false;
  @Input() hasDialogFilter: ElementRef<any> | HTMLDivElement;
  @Input() hasButton = false;
  @Input() searchIconBtn = false;
  @Input() filterIconBtn = true;
  @Output() onSearchEvent = new EventEmitter<string>();
  @Output() onFilterToggle = new EventEmitter<string>();
  @Output() onClearEvent = new EventEmitter<boolean>();
  @Input('navTab')
  set navTab(value: string) {
    if (this._navTab !== value && value) {
      this._navTab = value || '';
      const searchText = this.searchFiltersService.getSearchText(this.searchText);
      if (isObject(searchText)) {
        this.searchText = searchText[this.navTab] || '';
      }
    }
  }
  @Input() isModal = false;
  @Input() isDropdown = false;

  get navTab(): string {
    return this._navTab;
 }

  _navTab = '';

  @Input() autoCompleteSearchBar = false;
  @Input() options;
  @Input() disableSearchInput: boolean = false;
  @Output() selectedOptionValue = new EventEmitter<any>();
  myControl: UntypedFormControl = new UntypedFormControl();
  filteredOptions: Observable<any[]>;
  autoCompleteData = null;

  public constructor(
    private _router: Router,
    private DataStorage: DataStorageService,
    private dialog: MatDialog,
    private searchFiltersService: SearchFiltersService,
    public route: ActivatedRoute,
    private SearchResultService: SearchResultService,
    private tokenStorage: TokenStorage
  ) {
    // if(this.DataStorage.storage){
    //     this.data = this.DataStorage.storage;
    //     this.title = this.data["title"];
    //     this.text = this.data["text"];
    //     this.button = this.data["button"];
    // }else{
    //     this._router.navigate(['/home']);
    // }
  }

  curLang: string;
  items;
  @HostListener('document:keydown.escape')
  onEscapeKey() {
    this.closeFilter();
  }
  ngOnInit(): void {
    if (!this.isDropdown) {
      const searchText = this.searchFiltersService.getSearchText(this.searchText);
      if ((this.route.snapshot.queryParams.active_tab && this.navTab == this.route.snapshot.queryParams.active_tab) || !this.route.snapshot.queryParams.active_tab) {
        if (isObject(searchText) && this.navTab) {
          this.searchText = searchText[this.navTab] || '';
        } else {
          this.searchText = searchText || '';
        }
      }
    }

    this.curLang = this.tokenStorage.getCurrentLanguage();
    if (this.hasDialogFilter) {
      this.hasDialogFilter['classList'].add('dialog-filter');
    }

    if(this.autoCompleteSearchBar) {
      this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(val => val.length >= 1 ? this.filter(val) : [])
      );
    }
  }

  ngAfterViewInit() {
    if (!this.hasButton && !this.autoCompleteSearchBar) {
      fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
        // get value
        map((event: any) => {
          this.searchText = event.target.value;
          return event.target.value;
        })

        // Time in milliseconds between key events
        , debounceTime(700)

        // If previous query is diffent from current
        , distinctUntilChanged()

        // subscription for response
      ).subscribe((text: string) => {
        this.onSearchEvent.emit(this.searchText);
      });
    }
  }

  clearSearch() {
    this.searchText = '';
    this.searchInput.nativeElement.value = '';
    this.autoCompleteData = '';
    this.onClearEvent.emit(true);
  }

  initSearch(data: any = null) {
    if(data) {
      this.onSearchEvent.emit(data);
      this.SearchResultService.setHasSearch(true);
    } else {
      this.onSearchEvent.emit(this.searchText);
      if (this.searchText) {
        this.SearchResultService.setHasSearch(true);
      } else {
        this.SearchResultService.setHasSearch(false);
      }
    }
  }

  toggleFilter(element): void {
    this.fiterPanelState = this.fiterPanelState === 'opened' ? 'closed' : 'opened';
    this.onFilterToggle.emit(this.fiterPanelState);
    let intervalId = setInterval(() => {
      const isButtonFocused = document.activeElement === element;
      if (isButtonFocused) {
        element.blur();
        clearInterval(intervalId);
      }
    }, 50);
  }

  closeFilter() {
    this.fiterPanelState = 'closed';
    this.onFilterToggle.emit(this.fiterPanelState);
  }

  resetFilter() {
    this.onClearEvent.emit();
    this.closeFilter();
  }

  filter(val: string): string[] {
    return this.options.filter(option =>
      option.value.toLowerCase().indexOf(val.toLowerCase()) === 0);
  }

  getOptionText(option) {
    return option?.value ? option.value : '';
  }

  selectedOption(event) {
    this.autoCompleteData = event.option.value;
    this.selectedOptionValue.emit(event.option.value);
  }

  ngOnDestroy(): void {
    this.SearchResultService.setHasSearch(false);
  }
}
