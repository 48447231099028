import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {TokenStorage} from '../services/token-storage.service';
import {RouteService} from '../services/route.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(
    private router: Router,
    private tokenStorage: TokenStorage,
    private routerService: RouteService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const comingFromSelfOnboarding = (this.tokenStorage.getSelfOnboardingCategoryKey() && this.tokenStorage.getSelfOnboardingToken()) || state.url == 'self-onboarding';
    if (this.tokenStorage.getUser() && this.tokenStorage.getUser() !== 'undefined' && this.tokenStorage.getUser() !== 'null' && !comingFromSelfOnboarding) {
      // logged in so return true
      if (!this.routerService.initiatedRoutes) {
        this.routerService.afterLogin$.next(false);
      }

      return true;
    }

    if (comingFromSelfOnboarding) {
      this.router.navigate(['/self-onboarding']);
    }

    // not logged in so redirect to login page with the return url
    if (state.url != (window['prefix'] ? `/${window['prefix']}` : '') + '/home' && state.url != "/" && !comingFromSelfOnboarding && state.url != 'self-onboarding-signup') {
      this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
    } else {
      this.router.navigate(['/login']);
    }
    return false;
  }
}
