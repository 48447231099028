import {Injectable} from '@angular/core';
import _ from 'lodash';
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class DynamicListingService {

  constructor(
    private translate: TranslateService
  ) {
  }

  findLongestDataArray(data: any = []): number {
    let maxLength = 0;

    for (const item of data) {
      const dataLength = item?.data?.length || 0;
      if (dataLength > maxLength) {
        maxLength = dataLength;
      }
    }

    return maxLength;
  }

  formatColumnsConfig(columns: any = {}): any[] {
    const maxLength = this.findLongestDataArray(columns);
    let emptyColumns = 0;

    const concatenatedData: any[] = [];


    for (const item of columns) {
      const diff = maxLength - (item?.data?.length || 0);
      if (item?.data?.length > 0) {
        item.data.unshift({
          key: '',
          name: '',
          value: this.translate.instant(item.title)
        });
        for (let i = 0; i < diff; i++) {
          item?.data.push({});
        }
        concatenatedData.push(...item?.data);
      }else{
        emptyColumns++;
      }
    }
    if(emptyColumns>0){
      let emptyObjectsToPush = (maxLength+1)*emptyColumns;
      for (let i = 0; i < emptyObjectsToPush; i++) {
        concatenatedData.push({});
      }
    }
    return concatenatedData;
  }

  drawColumns(expansionDetails) {
    let numberOfColumns = expansionDetails.numberOfColumns ? expansionDetails.numberOfColumns : 3;
    let direction = expansionDetails.direction ? expansionDetails.direction : 'vertical';
    let data = expansionDetails.data ? expansionDetails.data : [{}];
    let numberOfObjectsForEachCell = Math.ceil(data?.length / numberOfColumns);
    expansionDetails = this.initObjColumns(numberOfColumns, expansionDetails);
    switch (direction) {
      case 'vertical':
        for (let i = 0; i < numberOfColumns; i++) {
          this.pushAllObjInsideArray(expansionDetails['col_' + i], data.slice((i * numberOfObjectsForEachCell), (i * numberOfObjectsForEachCell) + numberOfObjectsForEachCell));
        }
        break;
      case 'horizontal':
        for (let i = 0; i < numberOfColumns; i++) {
          for (let j = i; j < data?.length; j = j + numberOfColumns) {
            expansionDetails['col_' + i].push(data[j]);
          }
        }
        break;
    }
    return expansionDetails;
  }

  initObjColumns(numberOfColumns, expansionDetails) {
    for (let i = 0; i < numberOfColumns; i++) {
      expansionDetails['col_' + i] = [];
    }
    return expansionDetails;
  }

  pushAllObjInsideArray(key, array) {
    array.forEach(item => {
      key.push(item);
    });
  }

  checkEmptyObj(data, index) {
    if (_.isEmpty(data)) {
      return true;
    } else {
      return (data[index])?.length === 0;
    }
  }
}
