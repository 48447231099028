import { TrustedUrl } from './trusted-url.pipe';
import { TrustedHtml } from './trusted-html.pipe';
import { PropertyByLangPipe } from './property-by-lang.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CurrencyCode } from './currency-code.pipe';
import { FormatCode } from './format-code.pipe';
import { IbanFormatPipe } from './iban-format.pipe';
import { LimitLength } from './limit-length.pipe';
import { StringobjectPipe } from './stringobject.pipe';
import { TranslationFormatPipe } from './translation-format.pipe';
import { PhoneNumberPipe } from './phone-number.pipe';
import { CardPanPipe } from './card-pan.pipe';
import { FormatPhoneNumberPipe } from './format-phone-number.pipe';
import { ReplaceSeparatorAndCapitalizePipe } from './replace-separator-and-capitalize.pipe';
import { LinkPipe } from './link.pipe';
import { OpacityPipe } from './opacity.pipe';
import { FormatNumberBasedOnCurrencyPipe } from './format-number-based-on-currency.pipe';
import { CustomDatePipe } from './custom-date.pipe';

@NgModule({
  declarations: [
    PropertyByLangPipe,
    CurrencyCode,
    FormatCode,
    IbanFormatPipe,
    LimitLength,
    StringobjectPipe,
    TranslationFormatPipe,
    TrustedHtml,
    TrustedUrl,
    PhoneNumberPipe,
    CardPanPipe,
    FormatPhoneNumberPipe,
    ReplaceSeparatorAndCapitalizePipe,
    LinkPipe,
    OpacityPipe,
    FormatNumberBasedOnCurrencyPipe,
    CustomDatePipe
  ],
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [
    PropertyByLangPipe,
    CurrencyCode,
    FormatCode,
    IbanFormatPipe,
    LimitLength,
    StringobjectPipe,
    TranslationFormatPipe,
    TrustedHtml,
    TrustedUrl,
    PhoneNumberPipe,
    CardPanPipe,
    FormatPhoneNumberPipe,
    ReplaceSeparatorAndCapitalizePipe,
    LinkPipe,
    OpacityPipe,
    FormatNumberBasedOnCurrencyPipe,
    CustomDatePipe
  ],
  providers: [
    CustomDatePipe
  ]
})
export class PipesModule { }
