import {Directive, Input, OnInit, TemplateRef} from '@angular/core';
import {FooTemplatesService} from './foo-templates.service';

@Directive({
  selector: '[frameworkTemplate]',
})
export class FooTemplateDirective implements OnInit{
  @Input() frameworkTemplate: any;
  constructor(
    private fooTemplateService: FooTemplatesService,
    private templateRef: TemplateRef<any>,
  ) {}

  ngOnInit(): void {
    this.fooTemplateService.addTemplate(this.frameworkTemplate, this.templateRef);
  }

}
