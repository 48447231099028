import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import moment from 'moment';
import {isEmpty, isNil} from "lodash";

@Injectable()
export class TokenStorage {
  configuration = window['configuration'] ? JSON.parse(window['configuration']) : null;

  public userDetailsSubject = new Subject<any>();

  constructor() { }

  get prefix(): string {
    if (window['prefix']) {
      return window['prefix'] + '_';
    } else {
      return '';
    }
  }

  /**
   * Get user in JSON string
   * @returns {Observable<any>}
   */
  public getUser() {
    const user = localStorage.getItem(this.prefix + 'user');
    return user;
  }

  /**
   * Set User
   * @returns {TokenStorage}
   */
  public setUser(user: string): TokenStorage {
    localStorage.setItem(this.prefix + 'user', user);

    return this;
  }

  public updateUserDetails(user) {
    let currentUser = JSON.parse(this.getUser());
    currentUser = {...currentUser, ...user};
    this.setUser(JSON.stringify(currentUser));
    this.userDetailsSubject.next(currentUser);
  }

  /**
   * Get user in JSON string
   * @returns {Observable<any>}
   */
  public getLastLogin() {
    const user = localStorage.getItem(this.prefix + 'lastLogin');
    return user === 'null' ? null : user;
  }

  /**
   * Set User
   * @returns {TokenStorage}
   */
  public setLastLogin(date: string): TokenStorage {
    if (isNil(date) || isEmpty(date)) {
      localStorage.setItem(this.prefix + 'lastLogin', 'null');
    } else {
      localStorage.setItem(this.prefix + 'lastLogin', (Number(date) * 1000).toString());
    }
    return this;
  }

  /**
   * Get last failed login
   * @returns {Observable<any>}
   */
  public getLastFailedLogin() {
    const loginnDate = localStorage.getItem(this.prefix + 'lastFailedLogin');
    return loginnDate;
  }

  /**
   * Set User
   * @returns {TokenStorage}
   */
  public setLastFailedLogin(date: string): TokenStorage {
    if (isNil(date) || isEmpty(date)) {
      localStorage.setItem(this.prefix + 'lastFailedLogin', null);
    } else {
      const failedLogin = moment(date).unix();
      localStorage.setItem(this.prefix + 'lastFailedLogin', (Number(failedLogin)*1000).toString());
    }
    return this;
  }

  /**
   * Get user roles in JSON string
   * @returns {Observable<any>}
   */
  public getUserRoles(): Observable<any> {
    const roles: any = localStorage.getItem(this.prefix + 'roles');
    try {
      return of(JSON.parse(roles));
    } catch (e) {}
  }

  /**
   * Set User Roles
   * @returns {TokenStorage}
   */
  public setUserRoles(roles: string): TokenStorage {
    localStorage.setItem(this.prefix + 'roles', roles);

    return this;
  }

  /**
   * Get appConfig in JSON string
   */
  public getAppConfig() {
    return window['appConfig'] ? JSON.parse(window['appConfig']) : {};
  }

  /**
   * Get getAppConfigByKey in JSON string
   */
  public getAppConfigByKey(key: string, returnAsBoolean: boolean = false) {
    const appConfig = this.getAppConfig();
    if (appConfig && !returnAsBoolean) {
      return appConfig[key]?.value;
    } else {
      return appConfig && (appConfig[key]?.value == 1 || appConfig[key]?.value == '1' || appConfig[key]?.value == true || appConfig[key]?.value == 'true');
    }
  }

  /**
   * Get configuration in JSON string
   * @returns {Observable<any>}
   */
  public getConfiguration(): Observable<any> {
    const configuration: any = localStorage.getItem(this.prefix + 'configuration');
    try {
      return of(JSON.parse(configuration));
    } catch (e) {}
  }

  /**
   * Set configuration
   * @returns {TokenStorage}
   */
  public setConfiguration(configuration: string): TokenStorage {
    localStorage.setItem(this.prefix + 'configuration', configuration);

    return this;
  }

  /**
   * Get languages in JSON string
   * @returns {any}
   */
  public getLanguages(): any {
    const languages: any = localStorage.getItem(this.prefix + 'languages');
    try {
      return JSON.parse(languages);
    } catch (e) {
      return [];
    }
  }

  /**
   * Get Access Token
   * @returns {Observable<any>}
   */
  public getAccessToken(): Observable<any> {
    const user: any = localStorage.getItem(this.prefix + 'user');
    try {
      return of(JSON.parse(user));
    } catch (e) {}
  }

  /**
   * Set languages
   * @returns {TokenStorage}
   */
  public setLanguages(languages: string): TokenStorage {
    localStorage.setItem(this.prefix + 'languages', languages);

    return this;
  }

  /**
   * Set current language
   * @returns {TokenStorage}
   */
  public setCurrentLanguage(currentLang: string): TokenStorage {
    localStorage.setItem(this.prefix + 'curLang', currentLang);
    return this;
  }

  /**
   * Get current language
   * @returns {any}
   */
  public getCurrentLanguage() {
    const currentLang: string = localStorage.getItem(this.prefix + 'curLang');
    return currentLang || 'en';
  }

  ///////////////////////////////////////////////////////
  //////////////// PHP ADDITIONS ////////////////////////
  ///////////////////////////////////////////////////////

  /**
   * Set WebConfig
   * @returns {TokenStorage}
   */
  public setWebConfig(currentLang: string): TokenStorage {
    localStorage.setItem(this.prefix + 'webconfig', currentLang);
    return this;
  }

  /**
   * Get WebConfig
   * @returns {Observable<any>}
   */
  public getWebConfig() {
    const currentLang: string = localStorage.getItem(this.prefix + 'webconfig');
    try {
      return currentLang;
    } catch (e) {}
  }




  /**
   * Get Login Logo
   * @returns {Observable<any>}
   */

  public getLoginLogo() {
    const loginLogo =  this.configuration && this.configuration['login_logo'] ? this.configuration['login_logo'] :
      this.configuration && this.configuration['logo'] ? this.configuration['logo'] : null;
    try {
      return loginLogo;
    } catch (e) {}
  }

  ///////////////////////////////////////////////////////
  //////////////// SELF ONBOARDING ADDITIONS ////////////////////////
  ///////////////////////////////////////////////////////

  getValueForKey = (key: string): string | undefined =>
    this.configuration?.attributes?.find(attr => attr.key === key)?.value;

  public getSelfOnboardingConfigAppId() {
    const applicationId: string = this.getValueForKey('self_onboarding_app_id');
    // Check if the value is not 'undefined' and is not null
    if (applicationId !== 'undefined' && applicationId !== null) {
      return applicationId;
    }
    return null;
  }


  public setSelfOnboardingCategoryKey() {
    const category_key = this.getValueForKey('self_onboarding_category_key');
    if(category_key){
      localStorage.setItem(this.prefix + 'self_onboarding_category_key','' + category_key);
    }
    return this;
  }

  public getSelfOnboardingCategoryKey() {
    const categoryKey: string = localStorage.getItem(this.prefix + 'self_onboarding_category_key');

    // Check if the value is not 'undefined' and is not null
    if (categoryKey !== 'undefined' && categoryKey !== null) {
      return categoryKey;
    }
    return null;
  }


  public setSelfOnboardingApplicationId(applicationId: string): TokenStorage {
    if(applicationId){
      localStorage.setItem(this.prefix + 'self_onboarding_application_id', '' + applicationId);
    }
    return this;
  }

  public getSelfOnboardingApplicationId() {
    const applicationId: string = localStorage.getItem(this.prefix + 'self_onboarding_application_id');

    // Check if the value is not 'undefined' and is not null
    if (applicationId !== 'undefined' && applicationId !== null) {
      return applicationId;
    }
    return null;
  }


  public setSelfOnboardingCategoryAnswerId(category_answer_id: string): TokenStorage {
    if(category_answer_id){
      localStorage.setItem(this.prefix + 'self_onboarding_category_answer_id','' + category_answer_id);
    }
    return this;
  }

  public getSelfOnboardingCategoryAnswerId() {
    const answer_id: string = localStorage.getItem(this.prefix + 'self_onboarding_category_answer_id');

    // Check if the value is not 'undefined' and is not null
    if (answer_id !== 'undefined' && answer_id !== null) {
      return answer_id;
    }
    return null;
  }


  public setSelfOnboardingToken(token: string): TokenStorage {
    if(token){
      localStorage.setItem(this.prefix + 'self_onboarding_token', '' + token);
    }
    return this;
  }

  public getSelfOnboardingToken(): string | null {
    const currentLang = localStorage.getItem(this.prefix + 'self_onboarding_token');

    // Check if the value is not 'undefined' and is not null
    if (currentLang !== 'undefined' && currentLang !== null) {
      return currentLang;
    }
    return null;
  }

  /**
   * Remove tokens
   */
  public clear(){
    localStorage.removeItem(this.prefix + 'user');
    localStorage.removeItem(this.prefix + 'roles');
    localStorage.removeItem(this.prefix + 'configuration');
    localStorage.removeItem(this.prefix + 'languages');
    localStorage.removeItem(this.prefix + 'webconfig');
    localStorage.removeItem(this.prefix + 'menu');
    localStorage.removeItem(this.prefix + 'r_token');
    localStorage.removeItem(this.prefix + 'public_token');
    localStorage.removeItem(this.prefix + 'self_onboarding_category_answer_id');
    localStorage.removeItem(this.prefix + 'self_onboarding_app_id');
    localStorage.removeItem(this.prefix + 'self_onboarding_token');
    localStorage.removeItem(this.prefix + 'self_onboarding_category_key');
    localStorage.removeItem(this.prefix + 'self_onboarding_application_id');
  }
}
