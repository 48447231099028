import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cardPan'
})
export class CardPanPipe implements PipeTransform {

  transform(value: string, ...args: any[]): string {
    return this.seperateCardNumber(String(value), args[0] ?? ' ');
  }

  seperateCardNumber(cardNumber: string, seperator= ' '): string {
    let trimmedCardNum = cardNumber.replace(/\s+/g, '');

    if (trimmedCardNum.length > 16) {
      trimmedCardNum = trimmedCardNum.substr(0, 16);
    }

    /* Handle American Express 4-6-5 spacing */
    const partitions = trimmedCardNum.startsWith('34') || trimmedCardNum.startsWith('37')
      ? [4, 6, 5]
      : [4, 4, 4, 4];

    const numbers = [];
    let position = 0;
    partitions.forEach(partition => {
      const part = trimmedCardNum.substr(position, partition);
      if (part) { numbers.push(part); }
      position += partition;
    });

    return numbers.join(seperator);
  }
}
