import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from "@angular/common";

@Injectable()
export class LoaderService {
  private visible: boolean;
  listeners = [];

  semaphore = 0;

  constructor(
    @Inject(DOCUMENT) private dom
  ) {
    this.visible = false;
  }

  onVisibilityChange(fn): void {
    this.listeners.push(fn);
  }

  get visibility(): boolean {
    return this.visible;
  }

  set visibility(value: boolean) {
    if (value) {
      this.semaphore += 1;
      this.visible = true;
    } else {
      if (!this.semaphore || this.semaphore === 1) {
        this.visible = false;
      }
      if (this.semaphore) {
        this.semaphore -= 1;
      }
    }
    if (value === this.visible) {
      if (value) {
        this.dom.querySelector('body').className += ' is-loading';
        window.addEventListener("keydown",  this.preventKeyCallBack, true);
        window.addEventListener("keyup",    this.preventKeyCallBack, true);
        window.addEventListener("keypress", this.preventKeyCallBack, true);
      } else {
        this.dom.querySelector('body').className = this.dom.querySelector('body').className.replaceAll(' is-loading', '');
        window.removeEventListener("keydown",  this.preventKeyCallBack, true);
        window.removeEventListener("keyup",    this.preventKeyCallBack, true);
        window.removeEventListener("keypress", this.preventKeyCallBack, true);
      }
      this.listeners.forEach((fn) => {
        fn(value);
      });
    }
  }

  preventKeyCallBack(e): void {
    e.stopPropagation();
    e.preventDefault();
  }
}
