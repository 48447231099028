<foo-control-label [templateOptions]="templateOptions" [control]="control"></foo-control-label>


<mat-form-field appearance="fill" class="datepicker">
    <input matInput [ngxMatColorPicker]="picker" (input)="onInputChange($event)" [placeholder]="(templateOptions?.placeholder || placeholder) | translate" [formControl]="control">
    <div matSuffix
          class="cursor-pointer color-picker-suffix"
          [ngClass]="{'not-chosen': !control.value, 'show-border': ['#ffffff', '#ffffffff'].includes(control.value?.toHexString())}"
          [style.background-color]="control.value?.toHexString()" (click)="picker.open()"></div>
    <ngx-mat-color-picker #picker [touchUi]="false"></ngx-mat-color-picker>
</mat-form-field>


<foo-control-errors *ngIf="!templateOptions?.readonly && !templateOptions?.hideErrors && !control.hasError('invalid_color')"  [control]="control" [errorMessages]="errors">

</foo-control-errors>


<small *ngIf="(control.touched || control.dirty) && control.hasError('invalid_color')" class="text-danger">
    {{"validations.invalid_color" | translate}}
</small>
