<div class="row flex-container {{ menuClosed ? 'pl-15' : '' }}">

  <!-- menu -->
  <ng-container *ngIf="showMenu">
  <nav class="navbar left-menu-details" [class.closed]="menuClosed">
    <div class="logo-section">
      <div class="head-icons">
        <div class="menu-btn" (click)="toggleMenu()">
          <span class="foo-icon foo-menu-sub-left-menu-secondary close-menu-icon"></span>
        </div>
      </div>
    </div>
    <div class="min-righticon-container">
      <span class="min-righticon-form" (click)="toggleMenu()">
         <span *ngIf="!menuClosed" class="foo-icon foo-close-sub-left-menu-secondary close-menu-icon"></span>
         <span *ngIf="menuClosed" class="foo-icon foo-menu-sub-left-menu-secondary close-menu-icon"></span>
      </span>
    </div>
    <ul class="navbar-nav nav nav-tabs">
      <ng-container *ngFor="let link of links">
        <li *ngIf="(link.state && isStateExisted(link.state)) || permissions[link.permission]?.can_read"
            class="nav-item" [id]="link.id">
          <a class="nav-link" [class.disabled]="itemShimmer" [ngClass]="{'active': checkIfActivatedRoute(link.route)}"
             [attr.queryParamsHandling]="'merge'" (click)="emptyRouteHistory(link)"
             [attr.data-target]="link.dataTarget"
             role="tab" data-bs-toggle="tab">
             <i *ngIf="!link.iconImage; else asImage" [class]="link.icon" title="{{link.label | translate}}"></i>
             <ng-template #asImage>
               <span [class]="'foo-icon foo-'+ link.iconImage +'-sub-left-menu-secondary'" [ngClass]="checkIfActivatedRoute(link.route) ? 'foo-'+ link.iconImage +'-sub-left-menu-active' : ''"></span>
            </ng-template>
            <span class="nav-label ml-15">{{link.label | translate}}</span>
          </a>
        </li>
      </ng-container>
    </ul>
  </nav>
  </ng-container>
  <!-- /menu  -->

  <div [class.hide-menu]="!showMenu" [class.menuclosed]="menuClosed || !showMenu" class="profile-content width-responsive-design">
    <div class="row tab-content m-0" [class.shimmer-animate]="itemShimmer">
      <div class="col-md-12 tab-pane active p-0" id="customer-info">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
