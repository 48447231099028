<form [formGroup]="form" autocomplete="off" (ngSubmit)="update()" formValidation>
  <div class="row">
    <div class="col-12 col-sm-6 col-md-5 col-lg-5 col-xl-4">
      <div class="app-info-img">
        <img class="w-100" src="/assets/img/app-info-introduction.png" alt="">
      </div>
    </div>

    <div class="col-12 col-sm-6 col-md-7 col-lg-6 col-xl-5 mt-1 pl-0">

      <div class="form-group">
        <label class="form-label">{{ "shared-component.appInfo.introduction.introTitle" | translate }}*</label>

        <input class="form-control"
          placeholder="{{'shared-component.appInfo.introduction.introTitle-placeholder' | translate}}"
          formControlName="introTitle" type="text" [(ngModel)]="data['progress_intro_titles']['title']" />

        <small
          *ngIf="(form.get('introTitle').touched || form.get('introTitle').dirty) && form.get('introTitle').hasError('required')"
          class="text-danger">
          {{"validations.required_field" | translate}}
        </small>

        <small
          *ngIf="(form.get('introTitle').touched || form.get('introTitle').dirty) && form.get('introTitle').hasError('maxwords')"
          class="text-danger">
          {{"validations.maxWords" | translate: { maxnumber: 6 } }}
        </small>

      </div>

      <div class="form-group">
        <label class="form-label">{{ "shared-component.appInfo.introduction.introText" | translate }}*</label>

        <textarea class="form-control mb-9" formControlName="introText" type="text"
          placeholder="{{'shared-component.appInfo.introduction.introText-placeholder' | translate}}" rows="7"
          [maxlength]="maxChars" [(ngModel)]="data['progress_intro_titles']['text']"></textarea>

        <span class="form-label">{{ form.controls['introText'].value.length }}/{{ maxChars }}
          {{'shared-component.appInfo.characters' |
          translate}}</span>

        <br>

        <small
          *ngIf="(form.get('introText').touched || form.get('introText').dirty) && form.get('introText').hasError('required')"
          class="text-danger">
          {{"validations.required_field" | translate}}
        </small>

        <small
          *ngIf="(form.get('introText').touched || form.get('introText').dirty) && form.get('introText').hasError('maxwords')"
          class="text-danger">
          {{"validations.maxWords" | translate: { maxnumber: 50 } }}
        </small>
      </div>

      <div class="form-group">
        <framework-uploader [formControlName]="'icon1'" [fileSize]="8"
          [extensions]="extensions" [uploadNote]="uploadNote" [showProgress]="true" [fileType]="'image'"
          [labelInput]="'shared-component.appInfo.introduction.icon1'" [hasEditPerminssion]="true"
          [hasDeletePerminssion]="false" [hasDownloadPerminssion]="false">
        </framework-uploader>
      </div>

      <div class="form-group">
        <label class="form-label">{{ "shared-component.appInfo.introduction.stepOneTitle" | translate }}*</label>

        <input class="form-control"
          placeholder="{{'shared-component.appInfo.introduction.title1-placeholder' | translate}}"
          formControlName="documentTitle" type="text" [(ngModel)]="data['document_verification']['title']" />

        <small
          *ngIf="(form.get('documentTitle').touched || form.get('documentTitle').dirty) && form.get('documentTitle').hasError('required')"
          class="text-danger">
          {{"validations.required_field" | translate}}
        </small>

        <small
          *ngIf="(form.get('documentTitle').touched || form.get('documentTitle').dirty) && form.get('documentTitle').hasError('maxwords')"
          class="text-danger">
          {{"validations.maxWords" | translate: { maxnumber: 6 } }}
        </small>
      </div>

      <div class="form-group">
        <label class="form-label">{{ "shared-component.appInfo.introduction.text1" | translate }}*</label>

        <textarea class="form-control mb-9" formControlName="documentText" type="text"
          placeholder="{{'shared-component.appInfo.introduction.text1-placeholder' | translate}}" rows="7"
          [maxlength]="maxChars" [(ngModel)]="data['document_verification']['text']"></textarea>

        <span class="form-label">{{ form.controls['documentText'].value.length }}/{{ maxChars }}
          {{'shared-component.appInfo.characters' |
          translate}}</span>

        <br>

        <small
          *ngIf="(form.get('documentText').touched || form.get('documentText').dirty) && form.get('documentText').hasError('required')"
          class="text-danger">
          {{"validations.required_field" | translate}}
        </small>

        <small
          *ngIf="(form.get('documentText').touched || form.get('documentText').dirty) && form.get('documentText').hasError('maxwords')"
          class="text-danger">
          {{"validations.maxWords" | translate: { maxnumber: 50 } }}
        </small>
      </div>

      <div class="form-group">
        <framework-uploader [formControlName]="'icon2'" [fileSize]="8"
          [extensions]="extensions" [uploadNote]="uploadNote" [showProgress]="true" [fileType]="'image'"
          [labelInput]="'shared-component.appInfo.introduction.icon2'" [hasEditPerminssion]="true"
          [hasDeletePerminssion]="false" [hasDownloadPerminssion]="false">
        </framework-uploader>
      </div>

      <div class="form-group">
        <label class="form-label">{{ "shared-component.appInfo.introduction.stepSecondTitle" | translate }}*</label>

        <input class="form-control"
          placeholder="{{'shared-component.appInfo.introduction.title2-placeholder' | translate}}"
          formControlName="personalTitle" type="text" [(ngModel)]="data['personal_verification']['title']" />

        <small
          *ngIf="(form.get('personalTitle').touched || form.get('personalTitle').dirty) && form.get('personalTitle').hasError('required')"
          class="text-danger">
          {{"validations.required_field" | translate}}
        </small>

        <small
          *ngIf="(form.get('personalTitle').touched || form.get('personalTitle').dirty) && form.get('personalTitle').hasError('maxwords')"
          class="text-danger">
          {{"validations.maxWords" | translate: { maxnumber: 6 } }}
        </small>

      </div>

      <div class="form-group">
        <label class="form-label">{{ "shared-component.appInfo.introduction.text2" | translate }}*</label>

        <textarea class="form-control mb-9" formControlName="personalText" type="text"
          placeholder="{{'shared-component.appInfo.introduction.text2-placeholder' | translate}}" rows="7"
          [maxlength]="maxChars" [(ngModel)]="data['personal_verification']['text']"></textarea>

        <span class="form-label">{{ form.controls['personalText'].value.length }}/{{ maxChars }}
          {{'shared-component.appInfo.characters' |
          translate}}</span>

        <br>

        <small
          *ngIf="(form.get('personalText').touched || form.get('personalText').dirty) && form.get('personalText').hasError('required')"
          class="text-danger">
          {{"validations.required_field" | translate}}
        </small>

        <small
          *ngIf="(form.get('personalText').touched || form.get('personalText').dirty) && form.get('personalText').hasError('maxwords')"
          class="text-danger">
          {{"validations.maxWords" | translate: { maxnumber: 50 } }}
        </small>
      </div>

      <div class="form-group mt-5">
        <button type="submit" [disabled]="form.pristine" *ngIf="permissions?.can_update" class="foo-btn-primary  btn-main-site min-w170 foo-btn-center">{{
          "shared-component.appInfo.introduction.submit" | translate}}</button>
      </div>

    </div>
  </div>
</form>
