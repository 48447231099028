import {Directive, ElementRef, HostListener, Input, OnInit, Optional, Renderer2} from '@angular/core';
import {NgSelectComponent} from '@ng-select/ng-select';

import { asapScheduler } from 'rxjs';
@Directive({
  selector: '[appNgSelectCustom]'
})
export class NgSelectCustomDirective implements OnInit {

  @Input() appNgSelectAutocomplete = false;
  @Input() appNgSelectSearchBarDesign = false;
  @Input()
  set selectReadonly(value) {
    this._selectReadonly = value;
    this.checkReadonly();
  }

  get selectReadonly(): boolean {
    return this._selectReadonly;
  }

  // tslint:disable-next-line:variable-name
  private _selectReadonly = false;

  private open: any;
  private handleClearClick: any;
  private handleKeyCode: any;
  private handleKeyDown: any;


  constructor(
    private el: ElementRef,
    @Optional() private select: NgSelectComponent,
    private renderer: Renderer2
  ) {
  }

  @HostListener('click')
  onClick() {
    if (this.appNgSelectAutocomplete) {
      this.select.close();
    }
  }
  ngOnInit(): void {
    if(this.appNgSelectAutocomplete){
      this.renderer.addClass(this.el.nativeElement, 'autocomplete');

    }
    if(this.appNgSelectSearchBarDesign) {
      this.renderer.addClass(this.el.nativeElement, 'search-bar-design');
    }

    this.select.handleArrowClick = () => {
    };
    this.select.handleMousedown = () => {
    };


    const selectDom = this.el.nativeElement;
    selectDom.addEventListener('mouseleave', (event) => {
      this.el.nativeElement.querySelectorAll('.ng-option-marked').forEach(dom => dom.classList.remove('ng-option-marked'));
    });

    selectDom.addEventListener('mouseover', (event) => {
      this.el.nativeElement.querySelectorAll('.ng-option-marked').forEach(dom =>  dom !== event.target ?  dom.classList.remove('ng-option-marked') : null);
      if (event.target.classList.contains('ng-option')) {
        event.target.classList.add('ng-option-marked');
      } else {
        const parentOption = this.getParentOption(event.target);
        if (parentOption) {
          parentOption.classList.add('ng-option-marked');
        }
      }
    }, true);

    this.select.openEvent.subscribe((event) => {

      asapScheduler.schedule(() => {
        this.el.nativeElement.querySelectorAll('.ng-option-marked').forEach(dom => dom.classList.remove('ng-option-marked'));
        const matDialogContent = this.getParentElement(this.el.nativeElement, 'mat-dialog-content') || null;
        const ngDropdownPanel = this.el.nativeElement.querySelector('ng-dropdown-panel') ?? null;
        const fromGroup = this.el.nativeElement.parentElement;
        if (matDialogContent && ngDropdownPanel) {
          if(ngDropdownPanel.getBoundingClientRect().bottom > matDialogContent.getBoundingClientRect().bottom){
            matDialogContent.scrollTop += fromGroup.getBoundingClientRect().top - matDialogContent.getBoundingClientRect().top;
          }
        }
      })
    });

    const element = this.el.nativeElement.querySelector('.ng-select-container');
    if (element.addEventListener) {  // all browsers except IE before version 9
      element.addEventListener('click', this.onInputClick.bind(this), true);
    } else {
      if (element.attachEvent) {   // IE before version 9
        element.attachEvent('click', this.onInputClick.bind(this));
      }
    }
  }

  getParentElement(target: HTMLElement, tagName: string): HTMLElement {
    let parentElement = target.parentElement;
    while (parentElement) {
      if (parentElement.tagName.toLowerCase() === tagName.toLowerCase()) {
        return parentElement;
      }
      parentElement = parentElement.parentElement;
    }
    return null;
  }

  getParentOption(target: any): any {
        while (target.tagName.toLowerCase() !== 'ng-select') {
           if (target.classList.contains('ng-option')) {
             return target;
           }
           target = target.parentNode;
        }
        return null;
  }

  onInputClick(event): void {
    if (event.target.classList.contains('ng-clear-wrapper') || event.target.classList.contains('ng-clear')) {
      event.stopPropagation();
      this.select.handleClearClick();
    } else {
      event.preventDefault();
      if (!this.select.disabled && !this.selectReadonly) {
        this.select.toggle();
      }
    }
  }

  checkReadonly(): void {
    if (this.selectReadonly) {
      if (this.select.isOpen) {
        this.select.close();
      }
      this.open = this.select.open;
      this.handleKeyCode = this.select.handleKeyCode;
      this.handleKeyDown = this.select.handleKeyDown;
      this.handleClearClick = this.select.handleClearClick;

      this.select.open = () => {
      };
      this.select.handleKeyCode = () => {
      };
      this.select.handleKeyDown = () => {
      };
      this.select.handleClearClick = () => {
      };
      this.el.nativeElement.querySelector('.ng-arrow').style.visibility = 'hidden';
    } else {
      if (this.open) {
        this.select.open = this.open;
        this.select.handleKeyCode = this.handleKeyCode;
        this.select.handleKeyDown = this.handleKeyDown;
        this.select.handleClearClick = this.handleClearClick;
        this.el.nativeElement.querySelector('.ng-arrow').style.visibility = 'visible';
      }
    }
  }

}
