<nav class="navbar navbar-expand-sm top-nav border-bottom"
     [ngClass]="isrtl() ? 'rtl' : 'ltr'"
     [class.menufull]="isFullMenu" [class.menuclosed]="isFullWidth">
  <div class="col-12 col-sm-12 col-md-12 col-lg-12 pl-0 login-info">
    <div class="logo" *ngIf="isFullMenu" (click)="goHome()">
      <img src="{{logo}}" alt="logo"/>
      <img *ngIf="merchantLogo && userType === 'merchant'" src="{{merchantLogo}}" alt="merchant-logo"/>
    </div>

    <div class="login-dates">
      <span>{{"general.lastsuccessfullogin" | translate }}
        <div class=" inline-div fixedNumberAr"> {{ lastLogin ? (lastLogin | date:'d MMM y | h:mm z') : '-'}}</div>
      </span>
      <span class="login-dates-separator">--</span>
      <span class="half-opacity white-space-nowrap">{{"general.lastfailedlogin" | translate }}
        <div class=" inline-div fixedNumberAr">  {{ lastFailedLogin ? (lastFailedLogin | date:'d MMM y | h:mm z') : '-'}}</div>
      </span>
    </div>

    <div class="logout-menu" [class.direction-rtl]="isrtl()" [class.direction-ltr]="!isrtl()">

      <div class="foo-btn-outline-secondary langSwitch mb-2" *ngIf="showLang" (click)='useLanguage();'>
        <span>{{ "menu.lang" | translate }} </span>
      </div>
      <ul class="nav navbar-nav">
        <li class="dropdown" *ngIf="checkList(deviceMenuItems)">
          <a href="#" class="dropdown-toggle dropdown-toggle-device" dropdown-menu-hover data-bs-toggle="dropdown" data-close-others="true">
            <span class="foo-icon foo-settings-secondary"></span>
          </a>
          <ul class="dropdown-menu" [class.direction-rtl]="isrtl()" [class.direction-ltr]="!isrtl()">
            <li class="nav-item" *ngFor="let item of deviceMenuItems">
              <a class="nav-link" [routerLink]="getRoutePath(item)" (click)="onItemClicked($event, item)">
               <!-- <i class="{{item.icon}} logout-icons"></i>-->

                <i *ngIf="item.icon && (!item.icon_path || item.icon_path =='')" class="{{item.icon}} logout-icons"></i>
                <img class="margin-icon-img" *ngIf="item.icon_path && item.icon_path !==''" src="{{item.icon_path}}" [alt]="item.icon_path">

                <!-- <span>{{item.title}}</span>-->
                <span>{{item.title | TranslationFormat:'menu': curLang}}</span>
              </a>
            </li>
          </ul>
        </li>


        <li *ngIf="checkList(deviceMenuItems)" class="separator"></li>
        <li class="dropdown">
          <a href="#" class="dropdown-toggle user-div" data-bs-toggle="dropdown" data-hover="dropdown" data-close-others="true">
            <span class="username username-hide-on-mobile">
              {{personName}}
            </span>
            <i class="foo-icon menu-arrow foo-arrow-down-secondary" ></i>
          </a>
          <ul class="dropdown-menu" [class.direction-rtl]="isrtl()" [class.direction-ltr]="!isrtl()">
            <li class="nav-item" *ngFor="let item of menuItems">
              <a class="nav-link" [routerLink]="getRoutePath(item)" (click)="onItemClicked($event, item)">
                <!--<i class="{{item.icon}} logout-icons"></i>-->
                <i *ngIf="item.icon && !item.icon_path && item.icon_path ==''  " class="{{item.icon}} logout-icons"></i>
                <img class="icon-img" *ngIf="item.icon_path && item.icon_path !==''" src="{{item.icon_path}}" [alt]="item.icon_path">


                <!--  <span>{{item.title}}</span>-->
                <span>{{item.title | TranslationFormat:'menu': curLang}}</span>

              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="onLogout()">
                <span class="foo-icon foo-logout-third icon-img mr-8 align-middle"></span>
                <span>{{"general.logout" | translate}}</span></a>
            </li>
          </ul>
        </li>
      </ul>

    </div>
  </div>
</nav>
