<dynamic-listing (arrowClicked)="viewDetails($event)"
                      [boxView]="true"
                      (onFunctionCalledEvent)="functionCalled($event)"
                      [btnList]="btnList"
                      [clickableRow]="true"
                      [dataInput]="data"
                      [dataShimmer]="dataShimmer"
                      [labelsInput]="labels"
                      [canViewDetails]="true"
                      [paginationInput]="paginationInput"
                      [permissions]="permissions"
                      ></dynamic-listing>
