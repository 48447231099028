<div class="mt-4" *ngIf="isGroupRows">
  <div class="{{issearchInput ? 'row' : ''}}">
    <search-bar
      [shimmer]="dataShimmer"
      *ngIf="issearchInput"
      (onSearchEvent)="searchData($event)"
      class="{{issearchInput ? 'col-12 col-sm-12 col-md-4 col-lg-4' : ''}}"

    ></search-bar>
  </div>

  <div class="row d-none d-md-flex d-lg-flex transaction-box text-center"
       *ngIf="dataLength > 0 && !staticOrdering && !apiOrdering">
    <ng-container *ngFor="let label of labels">
      <div scope="col" *ngIf="!label.hide && !label.group_row" [attr.data-label]="label.value"
           class="col {{label.className}}">
        <span class="table-label" [class.shimmer-even]="dataShimmer">{{ label.title | translate }}</span>
      </div>
    </ng-container>
    <div class="col table-label" *ngIf="btnList?.length>0 || openView"></div>
  </div>

  <!-- sort by typescript -->
  <div class="row d-none d-md-flex d-lg-flex transaction-box text-center"
       *ngIf="dataLength > 0 && staticOrdering">
    <ng-container *ngFor="let label of labels">
      <div scope="col" *ngIf="!label.type || label.type != 'date'" [attr.data-label]="label.value"
           class="col {{label.className}} {{label.hide_label ? 'd-none' : '' }}">
        <span [class.shimmer-even]="dataShimmer" [attr.data-order]="(!label.disableSorting) ? '' : null" [appSort]="data"
              class="table-label">{{label.title | translate}}</span>
      </div>
      <div scope="col" *ngIf="label.type == 'date'" [attr.data-label]="label.value"
           class="col table-label {{label.className}} {{label.hide_label ? 'd-none' : '' }}">
        <span [class.shimmer-even]="dataShimmer" [attr.data-order]="(!label.disableSorting) ? '' : null" data-type="date" [appSort]="data"
              class="table-label">{{ label.title | translate }}</span>
      </div>
    </ng-container>
    <div class="col table-label" *ngIf="btnList?.length>0 || openView"></div>
  </div>


  <!-- sort by api -->
  <div class="row d-none d-md-flex d-lg-flex transaction-box text-center" *ngIf="dataLength > 0 && apiOrdering">
    <ng-container *ngFor="let label of labels">
      <div scope="col" (click)="!dataShimmer && Reorder(label)"
           class="col table-label {{label.className}} {{label.hide_label  ? 'd-none' : '' }}">
        <span [class.shimmer-even]="dataShimmer" [attr.data-order]="(!label.disableSorting) ? (label.sorting ? label.sorting : '') : null"
              class="table-label">{{ label.title | translate }}</span>
      </div>
    </ng-container>
    <div class="col table-label" *ngIf="btnList?.length>0 || openView"></div>
  </div>

  <div class="data-container group-row-table">
    <ng-container *ngFor="let items of data | keyvalue">
    <div class="row group-row-tr"
         [class.container-box-list]="!boxView"
         [class.container-box-seperatedlist]="boxView"
         [class.shimmer-animate]="dataShimmer" *ngIf="items?.value?.length">
         <div class="group-row-header">
          <ng-container *ngFor="let label of labels">
            <div (click)="!dataShimmer && log(label)"
              class="col {{label.className}} transaction-info reportMobile my-md-0 my-1 " *ngIf="label.type == 'date'">
              <span class="shimmer-item" [class.date]="label.type === 'date'"
                [title]="items.key ? (items.key | date: label.format || 'd MMM yyyy') : ''">
                {{ items.key ? (items.key | date: label.format || "d MMM yyyy") : "-" }}
              </span>
            </div>
          </ng-container>
        </div>
      <div class="w-100 group-row">
        <div class="row"
             [class.shimmer-row-equal-mobile]="dataShimmer"
             [class.container-box-list]="!boxView"
             [class.container-box-seperatedlist]="boxView"
             [class.clickableRow]="clickableRow"
             [class.pointer-cursor]="clickableRow"
             (click)="!dataShimmer && clickableRow && toggleArrow(item)"
             [class.pendingTransactionRed]="item.pending_transaction"
             [style]="customRowStyle ? customRowStyle(item) : ''"
             *ngFor=" let item of items.value | paginate : {
                itemsPerPage: dataPerpage,
                currentPage: pageNumber,
                totalItems: totalData
              }" [class.shimmer-animate]="dataShimmer">

          <div
            class="col {{label.className}} transaction-info reportMobile my-md-0 my-1 {{label.hide || label.group_row ? 'd-none' : '' }} {{label.classOptions ? ( label.classOptions.hasOwnProperty(item[label.value]) ? label.classOptions[item[label.value]] : '' ) : '' }}"
            *ngFor="let label of labels">

            <ng-container *ngIf="!staticOrdering && !apiOrdering && !label.hide && !label.group_row">
              <div *ngIf="label.title" [attr.data-label]="label.value"
                   class="table-label d-block d-md-none resp-label">
                {{ label.title | translate }}:
              </div>
            </ng-container>

            <ng-container *ngIf="staticOrdering || apiOrdering && !label.hide">
              <div *ngIf=" (!label.type ||
                label.type != 'date' ||
                label.type != 'datetime') && label.title"
                   [attr.data-order]="(!label.disableSorting) ? '' : null"
                   [attr.data-label]="label.value"
                   [appSort]="data"
                   class="table-label d-block d-md-none resp-label">
                {{ label.title | translate }}:
              </div>
              <div *ngIf="label.type == 'date' && label.title "
                   [appSort]="data"
                   [attr.data-order]="(!label.disableSorting) ? '' : null"
                   [attr.data-label]="label.value"
                   data-type="date"
                   class="table-label d-block d-md-none resp-label">
                {{ label.title | translate }}:
              </div>
            </ng-container>

            <div class="d-block values-container" [class.shimmer-item]="dataShimmer" *ngIf="!label.hide">
                  <span *ngIf="label.type === 'boolean' && !label.group_row">
                    {{ (item[label.value] == 1 ? "Yes" : "No") ? item[label.value] == 1 ? ("general.yes" | translate) : ("general.no" | translate) : "-" }}
                  </span>
              <span *ngIf="label.type === 'translate' && !label.group_row">
                      <span [title]="item[label.value] ? (item[label.value] | translate) : ''">
                        {{ item[label.value] ? (item[label.value] | translate) : "-" }}
                      </span>
                  </span>
              <span *ngIf="label.type === 'async' && !label.group_row">
                    <span [title]="item[label.value] ? (item[label.value] | async) : ''">
                    {{ item[label.value] ? (item[label.value] | async) : "-" }}
                    </span>
                  </span>
              <span class="reportMobileValue overflow-visible"
                    *ngIf="label.type === 'value-template' && getTemplate(label.templateRef) && !label.group_row">
                    <ng-container
                      *ngTemplateOutlet="getTemplate(label.templateRef); context: { item: item, label: label}">
                    </ng-container>
                  </span>
              <span *ngIf="label.type == 'time' && !label.group_row " [class.date]="label.type === 'time'"
                    [title]="item[label.value] ? (item[label.value] | date: label.format || 'hh:mm:ss') : '' ">
                      {{ item[label.value] ? (item[label.value] | date: label.format || "hh:mm:ss") : "-" }}
                  </span>
              <span *ngIf="label.type == 'date' && !label.group_row" [class.date]="label.type === 'date'"
                    [title]="item[label.value] ? (item[label.value] | date: label.format || 'd MMM yyyy') : ''">
                      {{ item[label.value] ? (item[label.value] | date: label.format || "d MMM yyyy") : "-" }}
                  </span>
              <span *ngIf="label.type == 'datetime' && !label.group_row" [class.date]="label.type === 'datetime'"
                    [title]=" item[label.value] ? (item[label.value] | date: label.format || 'd MMM yyyy | hh:mm:ss') : ''">
                      {{ item[label.value] ? (item[label.value] | date: label.format || "d MMM yyyy | hh:mm:ss") : "-" }}
                  </span>

              <!--number type handling in listing col-->
              <span [ngClass]="label?.cssClass ? label.cssClass: ''"
                    *ngIf="label.type == 'number' && !label.group_row"
                    [class.date]="label.type === 'number'"
                    [title]="(item[label.value] || 0) | number:'1.2-2' ">
                      {{ (item[label.value] || 0) | number:'1.2-2' }}
                  </span>
              <!-- /end number -->

              <!--with-css type handling in listing col-->
              <span *ngIf="label.type == 'with-css' && !label.group_row" [ngClass]="label.cssClass"
                    [title]="(item | stringobject: label.value) ? (item | stringobject: label.value) :'-'">
                    {{(item | stringobject: label.value) ? (item | stringobject: label.value) : "-"}}
                </span>
              <!-- /end with-css  -->

              <!--number with currency-->
              <span *ngIf="label.type == 'number-currency' && !label.group_row "
                    [class.date]="label.type === 'number-currency'"
                    [title]="((item[label.value] || 0) | number:'1.2-2') + ' ' + (item[label.currency] || '')">
                    {{ ((item[label.value] || 0) | number:'1.2-2') + ' ' + ((item[label.currency] || ''))}}
                </span>
              <!--/end-->

              <span *ngIf="label.type == 'status' && !label.group_row"
                    [class.red]="!item[label.value]"
                    [class.green]="item[label.value]">
                    <span *ngIf="item[label.value]" class="custom-status">
                      {{ "team.active" | translate }}
                    </span>
                    <span *ngIf="!item[label.value]" class="custom-status">
                      {{ "team.inactive" | translate }}
                    </span>
                </span>

              <span *ngIf="label.type == 'image' && !label.group_row">
                    <div class="{{ label.imageClassName ? label.imageClassName : 'imageContainer' }}"
                         [ngStyle]="{'background-image':
                          'url(' + (item | stringobject: label.value) + ')'}"></div>
                </span>

              <span *ngIf="!label.type && !label.group_row"
                    [title]="(item | stringobject: label.value) ? (item | stringobject: label.value) : '-' ">
                    {{(item | stringobject: label.value) ? (item | stringobject: label.value) : "-" }}
                </span>

            </div>
            <div class="col account-action-box account-action-box-desktop"
                 *ngIf="label.type === 'action-template'">
              <div class="rightBtnDesign" *ngIf="getTemplate(label.templateRef)">
                <ng-container
                  *ngTemplateOutlet="getTemplate(label.templateRef); context: { item: item, label: label}">
                </ng-container>
              </div>
            </div>

          </div>

          <div class="col account-action-box account-action-box-desktop"
               *ngIf="btnList?.length>0 || openView">
            <div *ngFor="let btnItem of btnList" class="rightBtnDesign">
              <button [disabled]="dataShimmer"
                      (click)="$event.stopPropagation();btnFunctionCalled(btnItem.fctName, item)"
                      class="{{btnItem.className}}">
                <img *ngIf="btnItem.imagePath" src="{{btnItem.imagePath}}" class="svg-color-filter" [alt]="btnItem.imagePath"/>
                <span *ngIf="btnItem.title">{{btnItem.title | translate}}</span>
              </button>
            </div>
            <i *ngIf="openView" (click)="!dataShimmer && toggleArrow(item)" class="foo-icon foo-arrow-right-light"></i>
          </div>

        </div>
      </div>
    </div>
    </ng-container>
  </div>

  <div *ngIf="dataLength === 0 && !dataShimmer " class="data-not-found">
      <framework-no-data></framework-no-data>
  </div>
</div>

<div class="" *ngIf="!isGroupRows">

  <framework-listing
    (onGetDataEvent)="onGetDataEvent"
    (onReorderEvent)="onReorderEvent"
    [issearchInput]="issearchInput"
    [dataInput]="dataInput"
    [permissions]="permissions"
    [labelsInput]="labels"
    [dataShimmer]="dataShimmer"
    [openView]="openView"
    (arrowClicked)="arrowClicked"
    [clickableRow]="clickableRow"
    [btnList]="btnList"
    [staticOrdering]="staticOrdering"
    [apiOrdering]="apiOrdering">
  </framework-listing>

</div>


<div class="row mt-20" *ngIf="pagination != null">
  <pagination-controls
    (pageChange)="getData($event)"
    [maxSize]="9"
    [directionLinks]="true"
    [autoHide]="true"
    [responsive]="true"
    previousLabel=""
    nextLabel=""
  >
  </pagination-controls>
</div>
