<div class="" [class.shimmer-animate]="dataShimmer" #dynamicListContainer>
  <div class="{{issearchInput ? 'row' : ''}}">
    <search-bar
      [shimmer]="dataShimmer"
      *ngIf="issearchInput"
      (onSearchEvent)="searchData($event)"
      class="{{issearchInput ? 'col-12 col-sm-12 col-md-4 col-lg-4' : ''}}"

    ></search-bar>
  </div>

  <div class="list-header transaction-box text-center mt-4"
       [class.d-none]="boxView" [class.d-md-flex]="!boxView"
       *ngIf="dataInput?.length > 0 && !staticOrdering && !apiOrdering" [class.shimmer-row-equal-mobile]="dataShimmer">
    <div class="info-cols">
    <ng-container *ngFor="let label of labels">
      <div scope="col" [attr.data-label]="label.value" class="col {{label.className}}" [style.flex-basis]="getSecureStyle('calc(' + (label.flexBasis) + '%' + ')')">
        <span class="table-label" [class.shimmer-even]="dataShimmer && label.title">{{ label.title | translate }}</span>
      </div>
    </ng-container>
    </div>
    <div>
      <div class="col actions-header table-label" *ngIf="btnList?.length>0 || openView">
         <div [style.width]="actionsWidth + 'px'"></div>
      </div>
    </div>
  </div>
  <!-- sort by typescript -->
  <div class="list-header transaction-box text-center mt-4" [class.shimmer-row-equal-mobile]="dataShimmer"
       [class.d-none]="boxView" [class.d-md-flex]="!boxView"
       *ngIf="dataInput?.length > 0 && staticOrdering">
    <div class="info-cols">
    <ng-container *ngFor="let label of labels">
      <div scope="col" [style.flex-basis]="getSecureStyle('calc(' + (label.flexBasis) + '%' + ')')" *ngIf=" (!label.type || ( label.type != 'date' && label.type != 'datetime')) && label.title" [attr.data-label]="label.value" class="col {{label.className}}">
        <span [attr.data-order]="!firstLoadCriteria && (!label.disableSorting) ? '' : null" [appSort]="data" class="table-label" [class.shimmer-even]="dataShimmer" [ngClass]="{'sorting-disabled': firstLoadCriteria === true}">{{label.title | translate}}</span>
      </div>
      <div scope="col" [style.flex-basis]="getSecureStyle('calc(' + (label.flexBasis) + '%' + ')')" *ngIf="(label.type == 'date' || label.type == 'datetime') && label.title" [attr.data-label]="label.value" class="col  table-label {{label.className}}">
        <span [attr.data-order]="!firstLoadCriteria && (!label.disableSorting) ? '' : null" data-type="date" [appSort]="data" class="table-label" [class.shimmer-even]="dataShimmer && label.title" [ngClass]="{'sorting-disabled': firstLoadCriteria === true}">{{ label.title | translate }}</span>
      </div>
    </ng-container>
    </div>
    <div>
    <div class="col  table-label" *ngIf="btnList?.length>0 || openView">
      <div [style.width]="actionsWidth + 'px'"></div>
    </div>
    </div>
  </div>


  <!-- sort by api -->
  <div class="list-header transaction-box text-center mt-4" [class.shimmer-row-equal-mobile]="dataShimmer"
       [class.d-none]="boxView" [class.d-lg-flex]="!boxView"
       *ngIf="dataInput?.length > 0 && apiOrdering">
    <div class="info-cols">
    <ng-container *ngFor="let label of labels">
      <div scope="col"  [style.flex-basis]="getSecureStyle('calc(' + (label.flexBasis) + '%' + ')')" (click)="!dataShimmer && !firstLoadCriteria && !label?.disableSorting ? Reorder(label) : ''" class="col  table-label {{label.className}}">
        <span [attr.data-order]="!firstLoadCriteria && (!label.disableSorting) ? (label.sorting ? label.sorting : '') : null" class="table-label" [class.shimmer-even]="dataShimmer && label.title">{{ label.title | translate }}</span>
      </div>
    </ng-container>
    </div>
      <div>
        <div class="col actions-header table-label" *ngIf="btnList?.length>0 || openView">
          <div [style.width]="actionsWidth + 'px'"></div>
        </div>
      </div>
  </div>

  <div class="data-container">
    <div class="listing-row"
         [class.d-none]="firstLoadCriteria"
         [class.container-box-list]="!boxView"
         [class.container-box-seperatedlist]="boxView"
         [class.pointer-cursor]="clickableRow"
         [class.clickableRow]="clickableRow"
         [class.shimmer-row-equal-mobile]="dataShimmer"
         (click)="!dataShimmer && clickableRow && toggleArrow(item)"
         [class.pendingTransactionRed]="!dataShimmer && item.pending_transaction"
         [style]="customRowStyle ? customRowStyle(item) : ''"
         *ngFor=" let item of (pagination ? (data | paginate : {
                itemsPerPage: dataPerpage,
                currentPage: pageNumber,
                totalItems: totalData
              }) : data)"
    >
      <div class="info-cols">
        <div [style.flex-basis]="label.flexBasis + '%'"
            class="
              {{
                isFunction(label.classOptions) ? label.classOptions(item) :
                (isObject(label.classOptions) ? (label.classOptions.hasOwnProperty(item[label.value]) ? label.classOptions[item[label.value]] : ''):
                (isString(label.classOptions) ? label.classOptions : ''))
              }} col {{label.className}} transaction-info reportMobile my-md-0 my-1 listing-row-100"
            [ngClass]="{'flex-md-column align-items-start': label.new_row && label.new_row_value}"
            *ngFor="let label of labels">
        <ng-container *ngIf="!staticOrdering && !apiOrdering">
          <div *ngIf="label.title" [attr.data-label]="label.value" class="table-label d-block d-md-none resp-label">
            {{ label.title | translate }}
          </div>
        </ng-container>
        <ng-container *ngIf="staticOrdering || apiOrdering">
          <div *ngIf=" (!label.type || ( label.type != 'date' && label.type != 'datetime')) && label.title" [attr.data-order]="(!label.disableSorting) ? '' : null"
               [attr.data-label]="label.value" (click)="!firstLoadCriteria && apiOrdering && !label?.disableSorting ? Reorder(label) : ''" [appSort]="data" class="table-label d-block d-md-none resp-label">
            {{ label.title | translate }}
          </div>
          <div *ngIf="(label.type == 'date' || label.type == 'datetime') && label.title" [appSort]="data"
               [attr.data-order]="(!label.disableSorting) ? '' : null" (click)="!firstLoadCriteria && apiOrdering && !label?.disableSorting ? Reorder(label) : ''" [attr.data-label]="label.value" data-type="date"
               class="table-label d-block d-md-none resp-label">
            {{ label.title | translate }}
          </div>
        </ng-container>

          <div *ngIf="label.type
              !== 'action-template'"
               [class.shimmer-item]="dataShimmer && !label?.className?.includes('remove-auto-shimmer')" class="d-block values-container">
            <span *ngIf="label.type === 'boolean'">
              {{ (item[label.value] == 1 ? "Yes" : "No") ? item[label.value] == 1 ? ("general.yes" | translate) : ("general.no" | translate) : "-" }}
          </span>
          <span *ngIf="label.type === 'translate'">
            <span [title]="item[label.value] ? (item[label.value] | translate) : ''">
              {{ item[label.value] ? (item[label.value] | translate) : "-" }}
            </span>
          </span>
          <span *ngIf="label.type === 'async'">
              <span [title]="item[label.value] ? (item[label.value] | async) : ''" >
              {{ item[label.value] ? (item[label.value] | async) : "-" }}
            </span>
          </span>
          <span class="reportMobileValue overflow-visible" *ngIf="label.type === 'value-template' && getTemplate(label.templateRef)">
            <ng-container
              *ngTemplateOutlet="getTemplate(label.templateRef); context: { item: item, label: label}">
            </ng-container>
          </span>
          <span class='d-block' *ngIf="label.type == 'time'" [class.date]="label.type === 'time'"
                [title]="item[label.value] ? (item[label.value] | date: label.format || 'HH:mm:ss') : '' ">
            {{ item[label.value] ? (item[label.value] | date: label.format || "HH:mm:ss") : "-" }}
          </span>
          <span class='d-block' *ngIf="label.type == 'date'" [class.date]="label.type === 'date'"
                [title]="item[label.value] ? (item[label.value] | date: label.format || 'dd MMM yyyy') : ''">
            {{ item[label.value] ? (item[label.value] | date: label.format || "dd MMM yyyy") : "-" }}
          </span>
          <span class='d-block' *ngIf="label.type == 'datetime'" [class.date]="label.type === 'datetime'"
                [title]=" item[label.value] ? (item[label.value] | date: label.format || 'dd MMM yyyy | HH:mm:ss') : ''">
            {{ item[label.value] ? (item[label.value] | date: label.format || "dd MMM yyyy | HH:mm:ss") : "-" }}
          </span>


          <!--number type handling in listing col-->
          <span [ngClass]="label?.cssClass ? label.cssClass: ''"
                *ngIf="label.type == 'number'"

                [title]="(item[label.value] || 0) | number:'1.2-2' " >
            {{ (item[label.value] || 0) | number:'1.2-2' }}
          </span>
          <!-- /end number -->

          <!--with-css type handling in listing col-->
          <span *ngIf="label.type == 'with-css'" [ngClass]="label.cssClass"
                [title]="(item | stringobject: label.value) ? (item | stringobject: label.value) :'-'" >
            {{(item | stringobject: label.value) ? (item | stringobject: label.value) : "-"}}
          </span>
          <!-- /end with-css  -->

          <!--number with currency-->
          <span *ngIf="label.type == 'number-currency'"
                [class.amount]="label.type === 'number-currency'"
                [title]="((item[label.value] || 0) | number:'1.2-2') + '  ' + (label.currency || '')" >
            {{ ((item[label.value] || 0) | number:'1.2-2' ) + '  ' + ((item[label.currency] || ''))}}
          </span>
          <!--/end-->

          <!--phone numberstring formatted-->
          <span [ngClass]="label?.cssClass ? label.cssClass: ''"
                *ngIf="label.type == 'phone-number'"
                [class.date]="label.type === 'phone-number'"
                [title]="(item[label.value] || '-') | phoneNumber " >
            {{ (item[label.value] || '-') | phoneNumber }}
          </span>
          <!-- /end number -->



          <span *ngIf="label.type == 'status'"
                [class.red]="!item[label.value]"
                [class.green]="item[label.value]">
              <span *ngIf="item[label.value]"  class="custom-status">
              {{ "team.active" | translate }}
            </span>
              <span *ngIf="!item[label.value]"  class="custom-status">
              {{ "team.inactive" | translate }}
            </span>
          </span>

          <span *ngIf="label.type == 'image'">
            <div class="{{ label.imageClassName ? label.imageClassName : 'imageContainer' }}"
                 [ngStyle]="{'background-image':
                  'url(' + (item | stringobject: label.value) + ')'
              }" ></div>
          </span>

          <span class="d-block" [class.fs-14]="label.new_row && label.new_row_value"
                *ngIf="!label.type" [title]="(item | stringobject: label.value) ? (item | stringobject: label.value) : '-' ">
            {{(item | stringobject: label.value) ? (item | stringobject: label.value) : "-" }}
          </span>

          <span *ngIf="label.new_row && label.new_row_value" class="label-row2 d-block {{label.new_row_class ? label.new_row_class : ''}}">
            {{label.new_row_class && label.new_row_class=='add-hashtag' ? '#' :''}}{{(item | stringobject: label.new_row_value) ? (item | stringobject: label.new_row_value) : "-"}}
          </span>
        </div>
        <div class="col account-action-box account-action-box-desktop"
             *ngIf="label.type === 'action-template'">
          <div class="rightBtnDesign" *ngIf="getTemplate(label.templateRef)">
            <ng-container
              *ngTemplateOutlet="getTemplate(label.templateRef); context: { item: item, label: label}">
            </ng-container>
          </div>
        </div>
      </div>
      </div>
      <div class="actions-cols">
        <div #actions class="col account-action-box account-action-box-desktop listing-row-100"
           *ngIf="btnList?.length>0 || openView">
        <div *ngFor="let btnItem of btnList" class="rightBtnDesign">
          <ng-container *ngIf="btnItem.type === 'checkbox'; else anotherType">
            <input class="{{btnItem.className}}" type="checkbox" [checked]="item.active"
              [(ngModel)]="item.active" (change)="$event.stopPropagation();btnFunctionCalled(btnItem.fctName, item)"
              (click)="$event.stopPropagation();">
          </ng-container>
          <ng-template #anotherType>
            <button [disabled]="dataShimmer"
              (click)="$event.stopPropagation();btnFunctionCalled(btnItem.fctName, item)"
              class="{{btnItem.className}}">
              <img *ngIf="btnItem.imagePath" src="{{btnItem.imagePath}}" class="svg-color-filter" [alt]="btnItem.imagePath"/>
              <foo-icon *ngIf="btnItem.icon" [icon]="isString(btnItem.icon)?'foo-'+btnItem.icon:btnItem.icon" [tooltip]="btnItem?.tooltip"></foo-icon>

              <span *ngIf="btnItem.title">{{btnItem.title | translate}}</span>
            </button>
          </ng-template>
        </div>
        <i *ngIf="openView && !dataShimmer"  (click)="toggleArrow(item)">
          <span class="foo-icon foo-arrow-right-light pointer-cursor"></span>
        </i>
        </div>
      </div>
    </div>
    <div *ngIf="dataInput?.length === 0 && !dataShimmer">
      <framework-no-data [params]="noDataFoundParams"></framework-no-data>
    </div>
  </div>
</div>
<div class="row mt-20" *ngIf="pagination != null">
  <pagination-controls
    (pageChange)="getData($event)"
    [maxSize]="9"
    [directionLinks]="true"
    [autoHide]="true"
    [responsive]="true"
    previousLabel=""
    nextLabel=""
  >
  </pagination-controls>
</div>
