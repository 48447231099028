import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {AlertService, TokenStorage} from 'foo-framework';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  languages;
  translationsFGContent: any = {};
  translations: any;

  constructor(private fb: UntypedFormBuilder,
    private alert: AlertService,
    private translate: TranslateService,
    private tokenStorage: TokenStorage,
  ) {
    this.languages = this.tokenStorage.getLanguages();
  }

  generateValidationRules(data, rules) {
    let arrayOfData = this.fb.group({});
    let keys = Object.keys(rules);
    if (keys.length) {
      keys.forEach(element => {

        if (element === 'translations') {

          this.translations = this.fb.group({});


          this.languages.forEach(language => {
            this.translationsFGContent[language['code']] = this.fb.group({});

            let translation_keys = Object.keys(rules[element]);

            if (translation_keys.length) {
              translation_keys.forEach(key => {

                if (!rules['translations'][key].length) {
                  this.translationsFGContent[language['code']].addControl(key, new UntypedFormControl(data && data[element] && data[element][language['code']] && data[element][language['code']][key] ? data[element][language['code']][key] : null));
                } else {
                  let translation_validation_rules = [];
                  rules['translations'][key].forEach(subElement => {
                    translation_validation_rules.push(subElement);
                  });
                  this.translationsFGContent[language['code']].addControl(key, new UntypedFormControl(data && data[element] && data[element][language['code']] && data[element][language['code']][key] ? data[element][language['code']][key] : null, translation_validation_rules));
                }
              });
            }
          });

          this.translations = this.translationsFGContent;
          arrayOfData.addControl('translations', new UntypedFormGroup(this.translations));

        } else if (!rules[element].length) {

          arrayOfData.addControl(element, new UntypedFormControl(data && data[element] ? data[element] : null));
        } else {

          let validation_rules = [];
          rules[element].forEach(subElement => {
            validation_rules.push(subElement);
          });
          arrayOfData.addControl(element, new UntypedFormControl(data && (data[element] || data[element] == 0)? data[element] : null, validation_rules));
        }
      });
    }
    return arrayOfData;
  }

  getFormValidationErrors(controls: FormGroupControls): AllValidationErrors[] {
    let errors: AllValidationErrors[] = [];
    Object.keys(controls).forEach(key => {
      const control = controls[key];
      if (control instanceof UntypedFormGroup) {
        errors = errors.concat(this.getFormValidationErrors(control.controls));
      }
      const controlErrors: ValidationErrors = controls[key].errors;
      if (controlErrors !== null) {
        Object.keys(controlErrors).forEach(keyError => {
          errors.push({
            control_name: key,
            error_name: keyError,
            error_value: controlErrors[keyError]
          });
        });
      }
    });
    return errors;
  }

  displayFormValidationErrors(errors: AllValidationErrors[], pointerToTranslationFile: string = 'general') {
    let text;
    errors.forEach(error => {
      switch (error.error_name) {
        case 'required': text = this.translate.instant(`${pointerToTranslationFile}.checkrequired`);
          break;
        case 'validatePhoneNumber':
        case 'pattern': text = this.translate.instant(`${pointerToTranslationFile}.invalid${error.control_name}`);
          break;
        case 'email': text = this.translate.instant(`${pointerToTranslationFile}.invalidemail`);
          break;
        default: text = `${error.control_name}: ${error.error_name}: ${error.error_value}`;
      }
    });
    this.alert.emitAlert({ type: 'danger', text: text });
  }

}


export interface AllValidationErrors {
  control_name: string;
  error_name: string;
  error_value: any;
}

export interface FormGroupControls {
  [key: string]: AbstractControl;
}
