import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {LoaderService} from '../../services/loader/loader.service';
import {ColorService} from '../../services/color.service';
import {GeneralConfigService} from "../../services/general-config.service";

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  showLoader: boolean;
  newShowLoader: boolean;
  showLoaderBlurBackground: boolean;
  @ViewChild('fooPageLoader', {static: false}) fooPageLoader: ElementRef;
  private _colorMap: Map<string, string>;
  loaderColor;
  loaderColorChanged = false;

  constructor(
    private colorService: ColorService,
    private generalConfigService: GeneralConfigService,
    private loaderService: LoaderService
  ) {
  }

  ngOnInit() {
    const showLoaderBlurBackgroundVal = this.generalConfigService.getConfigProperty('commonConfig')?.showLoaderBlurBackground;
    this.showLoaderBlurBackground = showLoaderBlurBackgroundVal !== undefined ? showLoaderBlurBackgroundVal : false;
    this.loaderService.onVisibilityChange((value) => {
      this.newShowLoader = value;
      if (value && !this.loaderColorChanged) {
        this.changeLoaderColor();
      }else{
        this.showLoader = value;
      }
    });
  }

  changeLoaderColor() {
    this._colorMap = new Map();
    this.colorService.loadColors(['--foo-loader-color'], this._colorMap);
    this.loaderColor = this._colorMap.get('--foo-loader-color');

    const styleTag = document.createElement('style');
    if (this.loaderColor) {
      styleTag.innerHTML = `
        .foo-page-loader-path {
          stroke: ` + this.loaderColor + ` !important;
        }
      `;

      let intervalId = setInterval(() => {
        const loaderLoaded = this.fooPageLoader?.nativeElement?._lottie.wrapper?.querySelector('path');
        if (loaderLoaded) {
          loaderLoaded?.classList?.add('foo-page-loader-path');
          this.fooPageLoader.nativeElement?._lottie?.wrapper?.appendChild(styleTag);

          this.showLoader = this.newShowLoader;

          if(!this.loaderColorChanged){
            this.loaderColorChanged = true
          }
          clearInterval(intervalId);
        }
      }, 50);
    }
  }
}
