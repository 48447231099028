import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChildren
} from '@angular/core';
import {ListingTemplatesService} from '../listing/listing-templates.service';
import {DomSanitizer} from "@angular/platform-browser";
import { ListingService } from '../services/listing.service';

@Component({
  selector: 'framework-boxes-listing',
  templateUrl: './boxes-listing.component.html',
  styleUrls: ['./boxes-listing.component.css']
})
export class BoxesListingComponent implements OnInit {

  labels: any[] = [];
  @Input() customRowStyle: (data) => string;

  @Input() dataShimmer;
  @Input() listingdata;
  @Input() paginationInput;
  @Input() dataInput;

  @Input()
  set labelsInput(value) {
    const totalSpans = (value || []).reduce((memo, label) => memo + (label.span || 1), 0);
    this.labels = value.map(label => ({
      ...label,
      flexBasis: ((label.span || 1) / totalSpans) * 100
    }));
  }

  @Input() issearchInput;
  @Input() showEditDelete;
  @Input() openView;
  @Input() staticOrdering;
  @Input() apiOrdering;
  @Input() permissions;
  @Input() btnList;
  @Output() onFunctionCalledEvent = new EventEmitter<{ functionName: string, item: object }>();
  @Output() onGetDataEvent = new EventEmitter<number>();
  @Output() onSearchEvent = new EventEmitter<string>();
  @Output() arrowClicked = new EventEmitter<any>();
  @Output() onReorderEvent = new EventEmitter<any>();
  @Input() clickableRow = false;
  @Input() boxView = false; // adds a class to the list to show each row in boxes: used in framework-boxes-listing
  @Input() firstLoadCriteria = false;
  @Input() noDataFoundParams;

  @ViewChildren('actions') actionsBoxes: QueryList<ElementRef>;

  public constructor(
    protected listingTemplateService: ListingTemplatesService,
    protected sanitizer: DomSanitizer,
    protected ListingService: ListingService
  ) {
    
  }

  ngOnInit(): void {
    
  }
}
