import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { SearchResultService } from '../services/search-result.service';
import { ListingService } from '../services/listing.service';

@Component({
  selector: 'framework-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.scss']
})
export class NoDataComponent implements OnInit {

  @Input() params;
  centerInPage: boolean = true;
  marginTop: number = 0;
  hasMultipleListingComponents: boolean = false;
  hasSearch: boolean = false;
  hasFilter: boolean = false;
  isEmptySearch: boolean = false;
  static nbOfNoDataComponents: number = 0;

  constructor(private SearchResultService: SearchResultService, private ListingService: ListingService) { }
  isAnimated=true;
  ngOnInit(): void {
    if(this.params?.imageUrl && !this.params?.imageUrl?.endsWith('.json')){
      this.isAnimated = false;
    }
    if(!this.params){
      this.params = {};
    }

    NoDataComponent.nbOfNoDataComponents++;

    this.ListingService.hasMultipleListingComponents.asObservable().subscribe(value => {
      this.hasMultipleListingComponents = value;
    });

    this.SearchResultService.getHasSearch().subscribe(value => {
      this.hasSearch = value;
    });

    this.SearchResultService.getHasFilter().subscribe(value => {
      this.hasFilter = value;
    });

    this.isEmptySearch = this.hasSearch || this.hasFilter;
  }

  ngAfterViewInit(){
    setTimeout(() => {
      let hasVerticalScroll = document.body.scrollHeight > document.body.clientHeight;
      let hasMultipleNoDataComponents = NoDataComponent.nbOfNoDataComponents > 1;
      this.centerInPage = !hasVerticalScroll && !hasMultipleNoDataComponents && !this.hasMultipleListingComponents;
      let noDataContainerBottom = document.getElementsByClassName("no-data-container")[0]?.getBoundingClientRect()?.bottom || 0;
      let pageHeight = document.getElementsByClassName("page-body")[0]?.getBoundingClientRect()?.height || 0;
      this.marginTop = Math.floor(((pageHeight - noDataContainerBottom) / 2));
    }, 0);
  }

  ngOnDestroy(): void {
    NoDataComponent.nbOfNoDataComponents--;
  }
}
