import { routes as pagesRoutes } from './services/router.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UnauthPagesRoutingModule } from './unauth-pages/unauth-pages-routing.module';
import { LoginComponent, ForgotPasswordComponent, ResetPasswordComponent } from 'foo-framework';
import {SelfOnboardingSignupComponent, SelfOnboardingWebviewComponent} from "foo-framework/modules/self-onboarding";
const routes: Routes = [
  {
    path: window['prefix'] || '',
    data: { breadcrumb: { skip: true } },
    children: pagesRoutes,
  },
  {
    path: window['prefix'] || '',
    data: { breadcrumb: { skip: true } },
    children: [
      {
        path: '',
        loadChildren: () => UnauthPagesRoutingModule
      },
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'self-onboarding-signup',
        component: SelfOnboardingSignupComponent,
      },
      {
        path: 'self-onboarding',
        component: SelfOnboardingWebviewComponent,
        data: {
          title: 'Self Onboarding',
          canonical: '/self-onboarding',
        }
      },
      {
        path: 'forgot-password', component: ForgotPasswordComponent,
        data: {
          title: 'Forgot Password',
          canonical: '/forgot-password',
        }
      },
      {
        path: 'password/resetpasswordview/:email/:code', component: ResetPasswordComponent,
        data: {
          title: 'Reset Password',
          canonical: '/password/resetpasswordview',
        }
      },
      {
        path: 'password/setpasswordview/:email/:code', component: ResetPasswordComponent,
        data: {
          title: 'Set Password',
          canonical: '/password/setpasswordview',
        }
      },
      {
        path: '**',
        redirectTo: '404',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: '**',
    redirectTo: '404',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
