import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertService, CustomValidatorsService, LoaderService, MakeCallService} from 'foo-framework';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
@Component({
  selector: 'add-step1-done-form',
  templateUrl: './add-step1-done-form.component.html',
  styleUrls: ['./add-step1-done-form.component.scss']
})
export class AddStep1DoneFormComponent implements OnInit {


  form: UntypedFormGroup;
  extensions = 'Supports: JPG, PNG, PDF';
  uploadNote = 'Image Dimension should be 45x45 px';
  @Input() data: any;
  fromGroups = {}
  maxChars: number = 250;
  @Input() api_url: string = '';
  @Input() permissions: any;
  @Input() app_id: any;
  @Input() app_name: any;

  oldDataForApprovalTransactionDetails: any = {};

  constructor(
    private loaderService: LoaderService,
    private fb: UntypedFormBuilder,
    private alert: AlertService,
    private makecall: MakeCallService,
    private translate: TranslateService,
    private customValidators: CustomValidatorsService
  ) {
  }

  ngOnInit(): void {
    this.oldDataForApprovalTransactionDetails = _.cloneDeep(this.data);
    this.fromGroups = {
      stepOneDoneTitle: ["progress_one_section_finished_titles" in this.data ? this.data['progress_one_section_finished_titles']['title'] : '', [Validators.required, this.customValidators.ValidateMaxWords(6)]],
      stepOneDoneText: ["progress_one_section_finished_titles" in this.data ? this.data['progress_one_section_finished_titles']['text'] : '', [Validators.required, this.customValidators.ValidateMaxWords(50)]],
    }
    this.form = this.fb.group(this.fromGroups);
  }

  update() {
    if (this.form.valid) {
      this.loaderService.visibility = true;
      this.data = {
        ...this.data,
        old_values: {
          ...this.oldDataForApprovalTransactionDetails
        }
      };
      if(this.app_id) {
        this.data = {
          ...this.data,
          'app_id' : this.app_id,
          'app_name' : this.app_name
        };
      }
      this.makecall.postCall(this.api_url, this.data).subscribe((data) => {
        if (data && ((data['status'])?.toLowerCase() === 'success' || 'ok')) {
          this.alert.emitAlert({
            type: 'success',
            text: data['message'] && data['message'] != '' ? data['message'] : this.translate.instant('general.SuccessfullySaved')
          });
        } else {
          this.alert.emitAlert({ type: 'danger', text: data['message'] });
        }
        this.loaderService.visibility = false;
      },
        error => {
          this.alert.emitAlert({ type: 'danger', text: this.translate.instant('general.somethingWentWrong') });
          this.loaderService.visibility = false;
        });
    }
  }
}
