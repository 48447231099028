// related issue https://github.com/angular/angular/issues/44999


import {LazyLoadedComponent} from "foo-framework";

export class RoutesMapper {

  /* exmaple
    ExmapleModule: {
      load: () => import('module path').then(m => ({
        module: m.ExmapleModule,
        components: m
      })),
      name: 'ExmapleModule'
    }
   */
  static modules = {
    MerchantsModule: {
      load: () => import('dmp-framework/modules/merchants').then(m => ({
        module: m.MerchantsModule,
        components: m
      })),
      name: 'MerchantsModule'
    },
    MerchantConfigurationModule: {
      load: () => import('dmp-framework/modules/merchant-configuration').then(m => ({
        module: m.MerchantConfigurationModule,
        components: m
      })),
      name: 'MerchantConfigurationModule'
    },
    MerchantGroupsModule: {
      load: () => import('dmp-framework/modules/merchant-groups').then(m => ({
        module: m.MerchantGroupsModule,
        components: m
      })),
      name: 'MerchantGroupsComponent'
    },
    PricingRulesModule: {
      load: () => import('dmp-framework/modules/pricing-rules').then(m => ({
        module: m.PricingRulesModule,
        components: m
      })),
      name: 'PricingRulesComponent'
    },
    FeesModule: {
      load: () => import('dmp-framework/modules/fees').then(m => ({
        module: m.FeesModule,
        components: m
      })),
      name: 'FeesModule'
    },
    OrderHistoryModule: {
      load: () => import('dmp-framework/modules/order-history').then(m => ({
        module: m.OrderHistoryModule,
        components: m
      })),
      name: 'OrderHistoryModule'
    },
    SecurityModule: {
      load: () => import('dmp-framework/modules/security').then(m => ({
        module: m.SecurityModule,
        components: m
      })),
      name: 'SecurityModule'
    },
    BranchesModule: {
      load: () => import('dmp-framework/modules/branches').then(m => ({
        module: m.BranchesModule,
        components: m
      })),
      name: 'BranchesModule'
    },
    DigitalInvoicesModule: {
      load: () => import('dmp-framework/modules/digital-invoice').then(m => ({
        module: m.DigitalInvoiceModule,
        components: m
      })),
      name: 'DigitalInvoiceModule'
    },
    MarketPlaceModule: {
      load: () => import('dmp-framework/modules/market-place').then(m => ({
        module: m.MarketPlaceModule,
        components: m
      })),
      name: 'MarketPlaceModule'
    },
    MyTerminalsModule: {
      load: () => import('dmp-framework/modules/my-terminals').then(m => ({
        module: m.MyTerminalsModule,
        components: m
      })),
      name: 'MyTerminalsModule'
    },
    AggregatorManagementModule: {
      load: () => import('dmp-framework/modules/aggregator-management').then(m => ({
        module: m.AggregatorManagementModule,
        components: m
      })),
      name: 'AggregatorManagementModule'
    },
    ApplicationsModule: {
      load: () => import('dmp-framework/modules/applications').then(m => ({
        module: m.ApplicationsModule,
        components: m
      })),
      name: 'ApplicationsModule'
    },
    CheckoutSdkModule: {
      load: () => import('dmp-framework/modules/checkout-sdk').then(m => ({
        module: m.CheckoutSdkModule,
        components: m
      })),
      name: 'CheckoutSdkModule'
    },
    DashboardModule: {
      load: () => import('dmp-framework/modules/dashboard').then(m => ({
        module: m.DashboardModule,
        components: m
      })),
      name: 'DashboardModule'
    },
  };

  static preDefinedRouteConfigs = [
    {
      path: 'digital-invoice-configuration',
      component: LazyLoadedComponent,
      parentComponents: [{
        module: RoutesMapper.modules.MerchantsModule,
        componentName: 'MerchantDetailsComponent'
      }],
      data: {
        title: 'Digital Invoice',
        canonical: '/merchantdetails',
        breadcrumb: {
          label: 'merchants.digitalInvoice',
          alias: 'merchantdetails'
        },
        routeInfo: {
          module: RoutesMapper.modules.MerchantsModule,
          componentName: 'DigitalInvoiceConfigurationComponent',
        }
      },
    },
    {
      path: 'inapp-configuration',
      component: LazyLoadedComponent,
      parentComponents: [{
        module: RoutesMapper.modules.MerchantsModule,
        componentName: 'MerchantDetailsComponent'
      }],
      data: {
        title: 'In-App & Web Checkout',
        canonical: '/merchantdetails',
        breadcrumb: {
          label: 'merchants.inapp',
          alias: 'merchantdetails'
        },
        routeInfo: {
          module: RoutesMapper.modules.MerchantsModule,
          componentName: 'InappConfigurationComponent',
        }
      },
    },
    {
      path: 'qr-code-configuration',
      component: LazyLoadedComponent,
      parentComponents: [{
        module: RoutesMapper.modules.MerchantsModule,
        componentName: 'MerchantDetailsComponent'
      }],
      data: {
        title: 'QR Code',
        canonical: '/merchantdetails',
        breadcrumb: {
          label: 'merchants.qr',
          alias: 'merchantdetails'
        },
        routeInfo: {
          module: RoutesMapper.modules.MerchantsModule,
          componentName: 'QrCodeConfigurationComponent',
        }
      },
    },
    {
      path: 'soft-pos-configuration',
      component: LazyLoadedComponent,
      parentComponents: [{
        module: RoutesMapper.modules.MerchantsModule,
        componentName: 'MerchantDetailsComponent'
      }],
      data: {
        title: 'Soft POS',
        canonical: '/merchantdetails',
        breadcrumb: {
          label: 'merchants.softpos',
          alias: 'merchantdetails'
        },
        routeInfo: {
          module: RoutesMapper.modules.MerchantsModule,
          componentName: 'SoftPosConfigurationComponent',
        }
      },
    },
    {
      path: 'marketplace-configuration',
      component: LazyLoadedComponent,
      parentComponents: [{
        module: RoutesMapper.modules.MerchantsModule,
        componentName: 'MerchantDetailsComponent'
      }],
      data: {
        title: 'Marketplace',
        canonical: '/merchantdetails',
        breadcrumb: {
          label: 'merchants.marketplace',
          alias: 'merchantdetails'
        },
        routeInfo: {
          module: RoutesMapper.modules.MerchantsModule,
          componentName: 'MarketplaceConfigurationComponent',
        }
      },
    },
    {
      path: 'merchantdetails',
      component: LazyLoadedComponent,
      parentComponents: [
        {
          module: RoutesMapper.modules.MerchantsModule,
          componentName: 'MerchantDetailsComponent',
        }
      ],
      data: {
        title: 'Details',
        canonical: '/merchantdetails',
        breadcrumb: {
          label: 'menu.merchantdetails',
          alias: 'merchantdetails'
        },
        routeInfo: {
          module: RoutesMapper.modules.BranchesModule,
          componentName: 'BranchesComponent',
        }
      },
    },
    {
      path: 'merchant-payment-controls',
      component: LazyLoadedComponent,
      parentComponents: [{
        module: RoutesMapper.modules.MerchantsModule,
        componentName: 'MerchantDetailsComponent'
      }],
      data: {
        title: 'Payment Controls',
        canonical: '/merchantdetails',
        breadcrumb: {
          label: 'menu.paymentcontrols',
          alias: 'merchantdetails'
        },
        routeInfo: {
          module: RoutesMapper.modules.MerchantsModule,
          componentName: 'MerchantPaymentControlsComponent',
        }
      },
    }
  ];

  static availableComponents = [
    {
      name: 'MerchantsComponent',
      module: RoutesMapper.modules.MerchantsModule,
      componentName: 'MerchantsComponent'
    },
    {
      name: "BranchesApprovalComponent",
      module: RoutesMapper.modules.MerchantsModule,
      componentName: "BranchesApprovalComponent"
    },
    {
      name: "MerchantDetailsComponent",
      module: RoutesMapper.modules.MerchantsModule,
      componentName: "MerchantDetailsComponent"
    },
    {
      name: "MerchantInfoComponent",
      module: RoutesMapper.modules.MerchantsModule,
      parentComponents: [{
        module: RoutesMapper.modules.MerchantsModule,
        componentName: "MerchantDetailsComponent"
      }],
      componentName: "MerchantInfoComponent"
    },
    {
      name: 'MerchantGroupComponent',
      module: RoutesMapper.modules.MerchantsModule,
      parentComponents: [{
        module: RoutesMapper.modules.MerchantsModule,
        componentName: 'MerchantDetailsComponent'
      }],
      componentName: 'MerchantGroupComponent'
    },
    {
      name: "MerchantFeesComponent",
      module: RoutesMapper.modules.MerchantsModule,
      parentComponents: [{
        module: RoutesMapper.modules.MerchantsModule,
        componentName: 'MerchantDetailsComponent'
      }],
      componentName: "MerchantFeesComponent"
    },
    {
      name: "MerchantConfigurationComponent",
      module: RoutesMapper.modules.MerchantConfigurationModule,
      componentName: "MerchantConfigurationComponent"
    },
    {
      name: 'PricingRulesComponent',
      module: RoutesMapper.modules.PricingRulesModule,
      componentName: 'PricingRulesComponent'
    },
    {
      name: 'AddPricingRuleComponent',
      module: RoutesMapper.modules.PricingRulesModule,
      componentName: 'AddPricingRuleComponent'
    },
    {
      name: 'FeesComponent',
      module: RoutesMapper.modules.FeesModule,
      componentName: 'FeesComponent'
    },
    {
      name: 'AddFeeComponent',
      module: RoutesMapper.modules.FeesModule,
      componentName: 'AddFeeComponent'
    },
    {
      name: 'MerchantGroupsComponent',
      module: RoutesMapper.modules.MerchantGroupsModule,
      componentName: 'MerchantGroupsComponent'
    },
    {
      name: 'AddMerchantGroupComponent',
      module: RoutesMapper.modules.MerchantGroupsModule,
      componentName: 'AddMerchantGroupComponent'
    },
    {
      name: 'OrderHistoryComponent',
      module: RoutesMapper.modules.OrderHistoryModule,
      componentName: 'OrderHistoryComponent'
    },
    {
      name: 'OrderDetailsComponent',
      module: RoutesMapper.modules.OrderHistoryModule,
      componentName: 'OrderDetailsComponent'
    },
    {
      name:'TransactionDetailsComponent',
      module: RoutesMapper.modules.OrderHistoryModule,
      componentName: 'TransactionDetailsComponent'
    },
    {
      name: 'SecurityComponent',
      module: RoutesMapper.modules.SecurityModule,
      componentName: 'SecurityComponent'
    },
    {
      name: "BranchesComponent",
      module: RoutesMapper.modules.BranchesModule,
      componentName: "BranchesComponent"
    },
    {
      name: "BranchDetailsComponent",
      module: RoutesMapper.modules.BranchesModule,
      componentName: "BranchDetailsComponent"
    },
    {
      name: 'MyTerminalsComponent',
      module: RoutesMapper.modules.MyTerminalsModule,
      componentName: 'MyTerminalsComponent'
    },
    {
      name: "BranchDetailsComponent",
      module: RoutesMapper.modules.BranchesModule,
      componentName: "BranchDetailsComponent"
    },
    {
      name: "DigitalInvoiceComponent",
      module: RoutesMapper.modules.DigitalInvoicesModule,
      componentName: "DigitalInvoiceComponent"
    },
    {
      name: "InstallmentDetailsComponent",
      module: RoutesMapper.modules.DigitalInvoicesModule,
      componentName: "InstallmentDetailsComponent"
    },
    {
      name: "InvoiceDetailsComponent",
      module: RoutesMapper.modules.DigitalInvoicesModule,
      componentName: "InvoiceDetailsComponent"
    },
    {
      name: 'MarketPlaceComponent',
      module: RoutesMapper.modules.MarketPlaceModule,
      componentName: 'MarketPlaceComponent'
    },
    {
      name: 'AggregatorManagementComponent',
      module: RoutesMapper.modules.AggregatorManagementModule,
      componentName: 'AggregatorManagementComponent'
    },
    {
      name: 'AggregatorDetailsComponent',
      module: RoutesMapper.modules.AggregatorManagementModule,
      componentName: 'AggregatorDetailsComponent'
    },
    {
      name: 'GroupsListComponent',
      module: RoutesMapper.modules.ApplicationsModule,
      componentName: 'ApplicationsListComponent'
    },
    {
      name: 'ApplicationDetailsComponent',
      module: RoutesMapper.modules.ApplicationsModule,
      componentName: 'ApplicationDetailsComponent'
    },
    {
      name: 'CheckoutSdkComponent',
      module: RoutesMapper.modules.CheckoutSdkModule,
      componentName: 'CheckoutSdkComponent'
    },
    {
      name: "DashboardComponent",
      module: RoutesMapper.modules.DashboardModule,
      componentName: "DashboardComponent"
    },
  ];

}
