import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { MatDialog } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { TranslateService } from '@ngx-translate/core';
import { FileUploadFormComponent } from '../file-upload-form/file-upload-form.component';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { FooPaginatedListingDialogComponent } from '../foo-paginated-listing-dialog/foo-paginated-listing-dialog.component';
import { FooPaginatedListingDialogTabsConfigs } from '../foo-paginated-listing-dialog/foo-paginated-listing-dialog-tabs-configs.interface';

interface DialogConfigs {
  data?: any,
  width?: string,
  height?: string,
  panelClass?: string | string[],
  backdropClass?: string | string[]
}

export interface FileUploadFormConfigs {
  uploadPath?: string,
  uploadParams?: any,
  dialogTitle?: string,
  downloadTemplateTitle?: string,
  downloadTemplatePlaceholder?: string,
  uploaderExtensions?: string,
  uploaderFileType?: string,
  uploaderFormControlName?: string,
  uploaderLabelInput?: string,
  uploaderShowProgress?: boolean,
  returnAsFile?: boolean,
  submitButtonLabel?: string,
}

interface FooPaginatedListingDialogConfigs {
  dialogTitle?: string;
  activeTab?: string;
  button?: {
    label?: string;
    class?: string;
  };
  tabsConfigs: FooPaginatedListingDialogTabsConfigs[];
}

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private dialog: MatDialog, private translate: TranslateService,) { }

  defaultDialogConfigs: DialogConfigs = {
    data: {},
    width: '550px',
    height: '',
    panelClass: ['app-custom-dialog-container', 'display-scrollbar'],
    backdropClass: 'app-customer-backdrop'
  }

  openDialog(
    component: ComponentType<any>,
    configurations?: DialogConfigs
  ): Observable<any> {
    const configs = {...this.defaultDialogConfigs, ...configurations};
    return new Observable<any>(observer => {
      const dialogRef = this.dialog.open(component, {
        width: configs.width,
        height: configs.height,
        panelClass: configs.panelClass,
        backdropClass: configs.backdropClass,
        data: configs.data
      });
      dialogRef.afterClosed().subscribe(confirmed => {
        observer.next(confirmed || false);
        observer.complete();
      });
    });
  };

  defaultFileUploadFormConfigs: FileUploadFormConfigs = {
    uploadPath: 'upload/csv',
    uploadParams: null,
    dialogTitle: this.translate.instant("fileUploadForm.bulkUpload"),
    downloadTemplateTitle: this.translate.instant("fileUploadForm.downloadTemplate"),
    downloadTemplatePlaceholder: this.translate.instant("fileUploadForm.downloadTemplatePlaceholder"),
    uploaderExtensions: 'CSV, XLS, XLSX',
    uploaderFileType: 'csv',
    uploaderFormControlName: 'file',
    uploaderLabelInput: this.translate.instant("fileUploadForm.documentsNeeded"),
    returnAsFile: false,
    uploaderShowProgress: true,
    submitButtonLabel: this.translate.instant("fileUploadForm.submit"),
  }

  openFileUploadForm(
    apiUrl: string,
    templateHref?: string,
    configurations?: FileUploadFormConfigs
  ): Observable<boolean> {
    const data =  { apiUrl: apiUrl, templateHref: templateHref, ...this.defaultFileUploadFormConfigs, ...configurations };
    return this.openDialog(FileUploadFormComponent, {data: data});
  }

  openConfirm(
    title: string,
    text: string,
    button?: string,
    buttonClass?: string,
    apiUrl?: string,
    apiParams?: any,
    dialogConfigs?: DialogConfigs,
  ): Observable<boolean> {
    const data = {
      apiUrl: apiUrl,
      apiParams: apiParams,
      title: title,
      text: text,
      button: button ? button : this.translate.instant('general.confirm'),
      buttonClass: buttonClass ? buttonClass : 'foo-btn-primary'
    };
    const configs = {data: data, width: '500px', panelClass: 'app-custom-dialog-container', ...dialogConfigs};
    return this.openDialog(DeleteDialogComponent, configs);
  }

  openDelete(
    apiUrl: string,
    apiParams: any,
    title: string,
    text: string,
    button?: string,
    buttonClass?: string,
    dialogConfigs?: DialogConfigs
  ): Observable<boolean> {
    const data = {
      apiUrl: apiUrl,
      apiParams: apiParams,
      title: title,
      text: text,
      button: button,
      buttonClass: buttonClass
    };
    const configs = {data: data, width: '500px', panelClass: 'app-custom-dialog-container', ...dialogConfigs};
    return this.openDialog(DeleteDialogComponent, configs);
  }

  openFooPaginatedListing(data: FooPaginatedListingDialogConfigs, dialogConfigs?: DialogConfigs): Observable<boolean> {
    const configs = {data: data, width: '500px', ...dialogConfigs};
    return this.openDialog(FooPaginatedListingDialogComponent, configs);
  }
}
