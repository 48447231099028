import {isString, get, isFunction, isObject} from 'lodash';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {SimpleChanges, SimpleChange} from "@angular/core";
import {ListingTemplatesService} from "./listing-templates.service";
import {DomSanitizer} from "@angular/platform-browser";
import { ListingService } from '../services/listing.service';
import {MakeCallService, TokenStorage} from "foo-framework";

@Component({
  selector: 'framework-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss'],
})
export class ListingComponent implements OnInit, AfterViewInit {


  pagination: [];
  data = [];
  issearch: 0;
  searchText = '';
  dataPerpage = 20;
  pageNumber = 1;
  totalData = 0;
  labels: any[] = [];
  static nbOfListingComponents: number = 0;


  @Input() customRowStyle: (data) => string;

  @Input() dataShimmer;
  @Input() listingdata;
  @Input() paginationInput;
  @Input() dataInput;

  @Input()
  set labelsInput(value) {
    const totalSpans = (value || []).reduce((memo, label) => memo + (label.span || 1), 0);
    this.labels = value.map(label => ({
      ...label,
      flexBasis: ((label.span || 1) / totalSpans) * 100
    }));
  }

  @Input() issearchInput;
  @Input() showEditDelete;
  @Input() openView;
  @Input() staticOrdering;
  @Input() apiOrdering;
  @Input() permissions;
  @Input() btnList;
  @Output() onFunctionCalledEvent = new EventEmitter<{ functionName: string, item: object }>();
  @Output() onGetDataEvent = new EventEmitter<number>();
  @Output() onSearchEvent = new EventEmitter<string>();
  @Output() arrowClicked = new EventEmitter<any>();
  @Output() onReorderEvent = new EventEmitter<any>();
  @Input() clickableRow = false;
  @Input() boxView = false; // adds a class to the list to show each row in boxes: used in framework-boxes-listing
  @Input() firstLoadCriteria = false;
  @Input() noDataFoundParams;
  actionsWidth = 30;

  @ViewChildren('actions') actionsBoxes: QueryList<ElementRef>;
  @ViewChild('dynamicListContainer', { static: true}) dynamicListContainer : ElementRef;

  public constructor(
    protected cdr: ChangeDetectorRef,
    protected listingTemplateService: ListingTemplatesService,
    protected sanitizer: DomSanitizer,
    protected ListingService: ListingService,
    protected tokenStorage: TokenStorage,
    protected makecall: MakeCallService
  ) {
    // it is put here since there are cases where ngOnInit() may not get called before ngOnDestroy()
    this.ListingService.incrementNbOfListingComponents();
  }

  curLang: string;

  getTemplate(id: string): TemplateRef<any> {
    return this.listingTemplateService.getTemplate(id);
  }

  getSecureStyle(style: string): any {
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

  ngOnInit(): void {
    if (this.dataShimmer === undefined) {
      this.makecall.isLoading.subscribe((isLoading) => {
        this.dataShimmer = isLoading;
      });
    }
    this.curLang = this.tokenStorage.getCurrentLanguage();
  }

  btnFunctionCalled(name, data) {
    this.onFunctionCalledEvent.emit({
      functionName: name,
      item: data
    });
  }

  getData($event) {
    this.pageNumber = $event;
    this.onGetDataEvent.emit($event);
  }

  searchData($event) {
    this.onSearchEvent.emit($event);
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentPagination: SimpleChange = changes.paginationInput;
    const currentData: SimpleChange = changes.dataInput;
    const currentIssearch: SimpleChange = changes.issearchInput;
    if (currentPagination && currentPagination.currentValue) {
      this.pagination = currentPagination.currentValue;
      this.totalData = this.pagination["totalData"];
      this.dataPerpage = this.pagination["dataPerpage"] ? this.pagination["dataPerpage"] : (this.data.length ? this.data.length : 1);
      this.pageNumber = this.pagination["pageNumber"];
    }
    if (currentData) {
      this.data = currentData.currentValue;
      if(this.data.length == 0 && this.pageNumber > 1){
        this.pageNumber--;
        this.getData(this.pageNumber);
      }
    }
    if (currentIssearch) {
      this.issearch = currentIssearch.currentValue;
    }

  }
  Reorder(label) {
    for (let x = 0; x < this.labels.length; x++) {
      if (this.labels[x] !== label) {
        this.labels[x].sorting = null;
      }
    }
    if (!label.sorting) {
      label.sorting = "desc";
    } else if (label.sorting == "desc") {
      label.sorting = "asc";
    } else {
      label.sorting = "desc";
    }
    this.onReorderEvent.emit(label);
  }

  toggleArrow(item) {

    this.arrowClicked.emit(item);
  }

  ngOnDestroy(): void {
    this.ListingService.nbOfListingComponents--;
  }

  ngAfterViewInit(): void {
    const observer = new ResizeObserver(() => {
      this.calculateActionsWidth();
    });
    observer.observe(this.dynamicListContainer.nativeElement);
    this.calculateActionsWidth();
  }

  calculateActionsWidth(): void {
    const item = this.actionsBoxes?.first;
    this.actionsWidth = item && item.nativeElement.clientWidth
      ? item.nativeElement.clientWidth
      : 30;
      this.cdr.detectChanges()
  }

  isFunction(obj: any): boolean {
    return isFunction(obj);
  }

  isString(obj: any): boolean {
    return isString(obj);
  }

  isObject(obj: any): boolean {
    return isObject(obj);
  }
}
