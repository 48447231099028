import {Injectable} from '@angular/core';
import {MakeCallService} from "foo-framework";
import {BehaviorSubject} from "rxjs";
import {TranslationService} from "foo-framework/shared-modules";

@Injectable({
  providedIn: 'root'
})
export class DmpDataServicesService {
  regions = new BehaviorSubject<any>(null);
  branches = new BehaviorSubject<any>(null);
  currencies = new BehaviorSubject<any>(null);
  merchantApprovalData = new BehaviorSubject<any>(null);
  merchantGroups = new BehaviorSubject<any>(null);

  constructor(
    private makeCall: MakeCallService,
    private translationService: TranslationService,
  ) {
  }
  getMerchantGroups(showLoader = true) {
    this.makeCall.getRequest('pricingrules/merchantgroup', {}, {showLoader: showLoader}).subscribe({
      next: (data: any) => {
        if (data['items']) {
          this.merchantGroups.next(this.translationService.getTranslationData(data['items']));
        }
      }
    });
    return this.regions;
  }
  getRegions(showLoader = true) {
    this.makeCall.getRequest('merchants/regions', {}, {showLoader: showLoader}).subscribe({
      next: (data: any) => {
        if (data['regions']) {

          this.regions.next(this.translationService.getTranslationData(data['regions']));
        }
      }
    });
    return this.regions;
  }

  getBranches(showLoader=true) {
    this.makeCall.getRequest('myterminals/allbranches', {type: 'branches'},{showLoader:showLoader}).subscribe({
      next: (resp: any) => {
        if (resp['branches']) {
          this.branches.next(this.translationService.getTranslationData(resp['branches']));

        }
      }
    });
    return this.branches;
  }

  getAllCurrencies(showLoader = true) {
    this.makeCall.getRequest('config/allcurrencies', {}, {showLoader: showLoader}).subscribe({
      next: (resp: any) => {
        let currencies = this.translationService.getTranslationData(resp['currencies']);
        this.currencies.next(currencies);
      }
    });
    return this.currencies;
  }

  getMerchantApprovalData(){
    this.makeCall.getRequest('merchants/merchantapprovaldata', {}).subscribe({
      next: (resp: any) => {
        let paymentMethods = resp['payment_methods'];
        let paymentGateways = this.translationService.getTranslationData(resp['payment_gateway']);
        let merchants = this.translationService.getTranslationData(resp['merchants']);
        let cardTypes = resp['card_types'];
        const merchantApprovalData = {
          paymentMethods: paymentMethods,
          paymentGateways: paymentGateways,
          merchants: merchants,
          cardTypes: cardTypes
        }
        this.merchantApprovalData.next(merchantApprovalData);
      }, error: error => {
        this.merchantApprovalData.error(error['message']);
        this.merchantApprovalData = new BehaviorSubject<any>(null);
      }
    })
    return this.merchantApprovalData;
  }

}
