<button class="close-icon" mat-button mat-dialog-close><span class="foo-icon foo-close-dark"></span></button>
<div mat-dialog-title>
  <p>{{ "orderHistory.refund" | translate }}</p>
</div>
<mat-dialog-content class="mt-10">
  <p class="font-weight-bold">{{data?.item?.date| date:'d MMM yyyy HH:mm' || "-"}}</p>
  <div class="col-12 property-info">
    <span class="property-label"> {{'orderHistory.correlation' | translate}}# </span> &nbsp;
    <span class="property-value"> {{data?.item?.reference_number || "-"}} </span>
  </div>
  <div class="col-12 property-info">
    <span class="property-label"> {{'orderHistory.orderID' | translate}} </span> &nbsp;
    <span class="property-value"> {{data?.item?.order_id || "-"}} </span>
  </div>
  <div class="px-1 pt-20">
    <form (ngSubmit)="onSubmit()" [formGroup]="form" formValidation autocomplete="off" id="ngForm" #fromDom="ngForm">
      <div class="form-group hasrightinfo">
        <label class="form-label mb-1">{{ "orderHistory.refundAmount" | translate}}*</label>
        <input class="form-control" [InputUnit]="currency" placeholder='{{ "orderHistory.enterAmount" | translate }}'
               formControlName="amount" type="text" [appNumbersOnly]="true"/>
        <small
          *ngIf="(form.get('amount').touched || form.get('amount').dirty) && form.get('amount').hasError('required')"
          class="text-danger pl-14">
          {{"validations.required_field" | translate}}
        </small>
        <small *ngIf="form.get('amount').touched && form.get('amount').hasError('pattern')"
               class="text-danger mt-1">{{"validations.pattern" | translate}}</small>
      </div>
    </form>
    <p class="fs-14 mb-0 mt-2">{{'orderHistory.refundText'|translate}}</p>

  </div>
</mat-dialog-content>
<div mat-dialog-actions class="pt-25">
  <button form="ngForm" type="submit" [disabled]="form.pristine||!form.valid"
          class="foo-btn-primary foo-btn-center">{{ "orderHistory.refund" | translate }}</button>
</div>
