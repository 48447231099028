import { Injectable, Injector } from '@angular/core';
import { Routes, Router } from '@angular/router';
import {
  AuthGuard,
  LoginComponent,
  PageNotFoundComponent,
  RouteService,
  OtpComponent
} from 'foo-framework';
import {RoutesMapper as FrameworkRoutesMapper} from 'foo-framework/routes-mapper';
import { RoutesMapper as DmpRoutesMapper} from 'dmp-framework/routes-mapper';
import { PagesComponent } from '../pages/pages.component';

export let routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    canActivate: [AuthGuard],
    children: [],
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login',
    },
  },
  {
    path: 'login-otp',
    component: OtpComponent,
    data: {
      title: 'Login',
    },
  },
];

export const modules = {};

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  initiatedRoutes = false;

  finalRoutes = [];
  routerRoutes: Routes = this.router['config'];

  /* exmaple
    ExmapleModule: {
      load: () => import('module path').then(m => ({
        module: m.ExmapleModule,
        components: m
      })),
      name: 'ExmapleModule'
    }
   */
  appAvailableComponents = [];
  appPredefinedRoutesConfig = [];

  frameworkAvailableComponents = [];
  walletAvailableComponents = [];
  corporateAvailableComponents = [];
  dmpAvailableComponents = [];

  frameworkPredefinedRoutesConfig = [];
  walletPredefinedRoutesConfig = [];
  corporatePredefinedRoutesConfig = [];
  dmpPredefinedRoutesConfig = [];

  availableComponents = [];
  preDefinedRouteConfigs = [];
  unhandledRoutes = [
    'login-otp',
    'check-number',
    'check-success',
    'forgot-password',
    'self-onboarding-signup',
    'self-onboarding',
    'check-username',
    'check-email',
    'password/resetpasswordview',
    'password/setpasswordview',
    '404',
  ];

  constructor(private injector: Injector, private router: Router) {
    try {
      this.frameworkAvailableComponents = FrameworkRoutesMapper.availableComponents || [];
      this.frameworkPredefinedRoutesConfig = FrameworkRoutesMapper.preDefinedRouteConfigs || [];
    } catch (e) {
      console.log('foo-framework is not included');
      this.frameworkAvailableComponents = [];
      this.frameworkPredefinedRoutesConfig = [];
    }

    try {
      this.dmpAvailableComponents = DmpRoutesMapper.availableComponents || [];
      this.dmpPredefinedRoutesConfig = DmpRoutesMapper.preDefinedRouteConfigs || [];
    } catch (e) {
      console.log('foo-dmp is not included');
      this.dmpAvailableComponents = [];
      this.dmpPredefinedRoutesConfig = [];
    }


    this.availableComponents = this.appAvailableComponents
      .concat(this.dmpAvailableComponents)
      .concat(this.frameworkAvailableComponents);

    this.preDefinedRouteConfigs = this.appPredefinedRoutesConfig
      .concat(this.dmpPredefinedRoutesConfig)
      .concat(this.frameworkPredefinedRoutesConfig);
  }

  init(): void {
    this.injector.get(RouteService).initialize(this.availableComponents, this.preDefinedRouteConfigs, this.unhandledRoutes);
    this.initiatedRoutes = true;
  }
}
