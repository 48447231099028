import { NgModule } from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatRadioModule} from '@angular/material/radio';
import {MatDialogModule} from '@angular/material/dialog';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import {MatSelectModule} from '@angular/material/select';
import {MatOptionModule} from '@angular/material/core';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {NgxMatTimepickerModule} from 'ngx-mat-timepicker';
import {MatChipsModule} from '@angular/material/chips';
import {MatTooltipModule} from '@angular/material/tooltip';


@NgModule({
  declarations: [],
  imports: [
    NgbModule,
    NgSelectModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatRadioModule,
    NgxPaginationModule,
    MatDialogModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatOptionModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    NgxMatTimepickerModule,
    MatChipsModule,
    MatTooltipModule
  ],
  exports: [
    NgbModule,
    NgSelectModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatRadioModule,
    NgxPaginationModule,
    MatDialogModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatOptionModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    NgxMatTimepickerModule,
    MatChipsModule,
    MatTooltipModule
  ]
})
export class MaterialModule { }
