import { DOCUMENT } from '@angular/common';
import {Directive, ElementRef, HostBinding, HostListener, Inject} from '@angular/core';
@Directive({
  selector: '[versionNumber]',
})
export class VersionNumberDirective {

  @HostBinding('autocomplete') public autocomplete
  constructor(@Inject(DOCUMENT) private document: Document, private element: ElementRef) {
    this.autocomplete = 'off'
  }

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.charCode);
    const versionRegex = /^[0-9.]$/; // Allow numeric characters and dots

    const inputText = (event.target as HTMLInputElement).value;
    const hasPreviousDot = inputText.includes('.');
    const isCurrentCharacterDot = (inputChar === '.');

    if (isCurrentCharacterDot && hasPreviousDot && inputText.endsWith('.')) {
      event.preventDefault(); // Prevent consecutive dots
    }

    if (isCurrentCharacterDot && inputText.length === 0) {
      event.preventDefault(); // Prevent starting with a dot
    }

    if (!versionRegex.test(inputChar)) {
      event.preventDefault(); // Prevent invalid characters
    }
  }

  @HostListener('paste', ['$event']) onPaste(event) {
    event.preventDefault();
    let pasteData = event.clipboardData.getData('text/plain') .replace(/[^\d.]/g, '')
      .replace('\.+','.')
      .replace(/\.(?: \.)+/g, '.');
    pasteData = pasteData.split('.');
    pasteData = this.cleanArray(pasteData, 'paste');
    pasteData = pasteData.join(".")
    document.execCommand('insertHTML', false, pasteData);
  }

  cleanArray(text, type = null) {
    var newArray = new Array();
    for (var i = 0; i < text.length; i++) {
      if(type == 'paste' && text[i] != ''){
        newArray.push(text[i]);
      }
      else if (!type && (text[i] != '' || i == text.length-1)) {
        newArray.push(text[i]);
      }
    }
    return newArray;
  }

}
