import {Component, forwardRef, HostBinding, Injector, Input, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR} from "@angular/forms";

import {FooControlComponent} from "../foo-control/foo-control.component";

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FooTextareaComponent),
  multi: true
};

@Component({
  selector: 'foo-textarea',
  templateUrl: './foo-textarea.component.html',
  styleUrls: ['./foo-textarea.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class FooTextareaComponent extends FooControlComponent implements OnInit {

  @HostBinding('style.position') public position = 'relative';

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
  }

}
