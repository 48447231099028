import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { AlertService, CustomValidatorsService, LoaderService, MakeCallService } from "foo-framework";
import { TranslateService } from "@ngx-translate/core";
import _ from 'lodash';
@Component({
  selector: 'add-document-types-form',
  templateUrl: '../add-document-types-form/add-document-types-form.component.html',
  styleUrls: ['../add-document-types-form/add-document-types-form.component.scss']
})
export class AddDocumentTypesFormComponent implements OnInit {


  form: UntypedFormGroup;
  extensions = 'Supports: JPG, PNG, PDF';
  uploadNote = 'Image Dimension should be 120x120 px';
  @Input() data: any;
  fromGroups = {}
  maxChars: number = 250;
  @Input() api_url: string = '';
  @Input() api_url_upload_main_icon_document_types: string = '';
  @Input() api_url_upload_document_icon: string = '';
  @Input() permissions: any;
  @Input() app_id: any;
  @Input() app_name: any;

  oldDataForApprovalTransactionDetails: any = {};

  constructor(
    private loaderService: LoaderService,
    private fb: UntypedFormBuilder,
    private alert: AlertService,
    private makecall: MakeCallService,
    private translate: TranslateService,
    private customValidators: CustomValidatorsService
  ) {
  }

  ngOnInit(): void {
    this.oldDataForApprovalTransactionDetails = _.cloneDeep(this.data);
    this.fromGroups = {
      mainIcon: ["document_screen" in this.data && this.data['document_screen']['intro'].icon ? this.data['document_screen']['intro'].icon : this.data['document_screen']['intro'].icon_link],
      introTitle: ["document_screen" in this.data ? this.data['document_screen']['intro']['title'] : '', [Validators.required, this.customValidators.ValidateMaxWords(6)]],
      introText: ["document_screen" in this.data ? this.data['document_screen']['intro']['text'] : '', [Validators.required, this.customValidators.ValidateMaxWords(50)]]
    }

    this.oldDataForApprovalTransactionDetails['document_intro_icon'] = (("document_screen" in this.data) && this.data['document_screen']['intro'].icon) ? this.data['document_screen']['intro'].icon : this.data['document_screen']['intro'].icon_link;

    this.form = this.fb.group(this.fromGroups);

    if(this.data['document_screen'].documents.length) {
      this.data['document_screen'].documents.forEach((element, index) => {
      this.form.addControl('icon_' + element.name, new UntypedFormControl(element && element.icon ? element.icon : element.icon_link));
      this.form.addControl('title_' + index, new UntypedFormControl(element && element.title ? element.title : '', [Validators.required, this.customValidators.ValidateMaxWords(6)]));
      this.form.addControl('text_' + index, new UntypedFormControl(element && element.text ? element.text : '', [Validators.required, this.customValidators.ValidateMaxWords(50)]));

      this.oldDataForApprovalTransactionDetails[(element.name)?.toLowerCase() + '_icon'] = element && element.icon ? element.icon : element.icon_link;
      });
    } else {
      this.alert.emitAlert({ type: 'danger', text: this.translate.instant('general.somethingWentWrong') });
    }
  }

  update() {
    if (this.form.valid) {
      this.loaderService.visibility = true;
      this.data = {
        ...this.data,
        icons: [],
        old_values: {
          ...this.oldDataForApprovalTransactionDetails
        }
      };
      if(this.app_id) {
        this.data = {
          ...this.data,
          'app_id' : this.app_id,
          'app_name' : this.app_name
        };
      }
      // for upload main icon
      if (this.form.controls['mainIcon'].value) {
        this.data.icons.push({
          icon_type: 'document_intro_icon',
          url: this.form.controls['mainIcon'].value,
          icon_key: 'icon'
        });
      }

      // for upload icon 1 and icon 2
      let iconArray = [];
      Object.keys(this.form.controls).forEach(key => {
        if (key.startsWith('icon_')) {
          let docTypeAfterSubstring = key.substring(key.indexOf('_') + 1);
          if(!this.app_id) {
            iconArray.push({
              doc_type: docTypeAfterSubstring,
              image: this.form.controls[key].value
            });
          } else {
            iconArray.push({
              doc_type: docTypeAfterSubstring,
              image: this.form.controls[key].value,
              app_id: this.app_id
            });
          }
        }
      });

      iconArray.forEach(element => {
        if (element) {
          this.data.icons.push({
            doc_type: element.doc_type,
            url: element.image,
            icon_key: 'document_icon'
          });
        }
      });

      this.makecall.postCall(this.api_url, this.data).subscribe((data) => {
        if (data && ((data['status'])?.toLowerCase() === 'success' || 'ok')) {
          this.alert.emitAlert({
            type: 'success',
            text: data['message'] && data['message'] != '' ? data['message'] : this.translate.instant('general.SuccessfullySaved')
          });
        } else {
          this.alert.emitAlert({ type: 'danger', text: data['message'] });
        }
        this.loaderService.visibility = false;
      },
      error => {
        this.alert.emitAlert({ type: 'danger', text: this.translate.instant('general.somethingWentWrong') });
        this.loaderService.visibility = false;
      });
    }
  }
}
