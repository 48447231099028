import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumber } from 'libphonenumber-js';

@Pipe({
  name: 'formatPhoneNumber'
})
export class FormatPhoneNumberPipe implements PipeTransform {
  transform(phoneValue: number | string): string {
    let stringPhone = phoneValue + "";
    if (!stringPhone.startsWith("+")) {
      if (stringPhone.startsWith("00")) {
        stringPhone = stringPhone.substring(2);
      }
      stringPhone = "+" + stringPhone;
    }
    const phoneNumber = parsePhoneNumber(stringPhone);
    const formatted = phoneNumber.formatInternational();
    return formatted;
  }
}