<foo-control-label [templateOptions]="templateOptions" [control]="control"></foo-control-label>

<ngx-intl-tel-input [cssClass]="'custom'"
                    [enableAutoCountrySelect]="true"
                    [enablePlaceholder]="true"
                    [maxLength]="15"
                    [numberFormat]="PhoneNumberFormat.National"
                    [phoneValidation]="true"
                    [preferredCountries]="preferredCountries"
                    [searchCountryField]="[
                      SearchCountryField.Iso2,
                      SearchCountryField.Name
                    ]"
                    [searchCountryFlag]="true"
                    [selectFirstCountry]="false"
                    [selectedCountryISO]="selectedCountry"
                    [separateDialCode]="separateDialCode"
                    [formControl]="control"
                    [onlyCountries]="filteredCountries"
                    name="phone">
</ngx-intl-tel-input>

<foo-control-errors *ngIf="!templateOptions?.readonly && !templateOptions?.hideErrors"  [control]="control" [errorMessages]="errors">

</foo-control-errors>
