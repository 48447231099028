import { Component, Input, OnInit } from '@angular/core';

import { RouteService } from '../../../services/route.service';
import { DomainService } from '../../../services/domain.service';
import { TokenStorage } from '../../../services/token-storage.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import {Router} from "@angular/router";

@Component({
  selector: 'left-menu-search-bar',
  templateUrl: './left-menu-search-bar.component.html',
  styleUrls: ['./left-menu-search-bar.component.scss']
})
export class LeftMenuSearchBarComponent implements OnInit {

  @Input() menuItems: any[] = [];
  @Input() menuClosed: boolean = false;

  curLang: string;
  showSearchIcon: boolean = false;
  hovered: boolean = false;

  constructor(protected routerService: RouteService,
              protected domainService: DomainService,
              protected tokenStorage: TokenStorage,
              protected router: Router,
              protected breakpointObserver: BreakpointObserver) {
    breakpointObserver
      .observe('(min-width: 1025px)')
      .subscribe(({ matches }) => {
        if (matches) {
          this.showSearchIcon = true;
        } else {
          this.showSearchIcon = false;
        }
      });
  }

  ngOnInit() {
    this.curLang = this.tokenStorage.getCurrentLanguage();
  }

  selectionChanged(event) {
    if(event){
      if ((this.routerService as any).isNavigationPeventedOnRoute(event)) {
        (this.routerService as any).handleCustomRoute(event);
      }
      const routePath = this.getRoutePath(event);
      this.router.navigate([routePath]);
    }
  }

  getRoutePath(menuItem: any): string {
    if ((this.routerService as any).isNavigationPeventedOnRoute(menuItem)) {
      return null;
    } else {
      return this.domainService.getPrefixedURL(menuItem.path);
    }
  }
  //
  // onItemClicked(event: any, menuItem: any): void {
  //   if ((this.routerService as any).isNavigationPeventedOnRoute(menuItem)) {
  //     (this.routerService as any).handleCustomRoute(menuItem);
  //   }
  // }

  customFilter(term: string, item: any) {
    return (item?.translatedTitle?.toLowerCase())?.includes(term?.toLowerCase()) ||
      (item?.translatedParentItemTitle?.toLowerCase())?.includes(term?.toLowerCase());
  }
}
