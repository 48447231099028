import {Route, Router, Routes} from '@angular/router';
import {LazyLoadedComponent} from './../components/lazy-loaded/lazy-loaded.component';
import {BreadcrumbService} from 'xng-breadcrumb';
import {PageNotFoundComponent} from './../components/page-not-found/page-not-found.component';
import {Injectable, Injector, Type, createNgModuleRef} from '@angular/core';
import {get, isEmpty, omit, uniq, orderBy, isFunction, find, without, uniqBy} from 'lodash';
import {isObservable, Observable, of, Subject} from 'rxjs';
import {dataTranslation} from '../pipes/translation-format.pipe';
import { Meta } from '@angular/platform-browser';
import { LazyLoadingCacheService } from './lazy-loading-cache.service';
import { LocationStrategy } from '@angular/common';
import {TokenStorage} from './token-storage.service';
import {chain} from 'lodash';
import {takeUntil} from "rxjs/operators";
import { SearchFiltersService } from './search-filters.service';
import { MakeCallService } from './makecall.service';

@Injectable({
  providedIn: 'root'
})
export class RouteService {
  menuClosed = false;

  constructor(
    protected breadcrumbService: BreadcrumbService,
    protected router: Router,
    protected metaService: Meta,
    protected injector: Injector,
    protected lazyLoadingCacheService: LazyLoadingCacheService,
    protected location: LocationStrategy,
    protected tokenStorage: TokenStorage,
    protected searchFiltersService: SearchFiltersService,
    protected makeCall: MakeCallService
  ) {

    this.location.onPopState(() => {
      this.isBrowserBackBtnClicked = true;
    });
  }

  routesHistory = [];
  routeChildren = null;
  paramsByRoute = {};

  allPreDefinedRouteConfigs = [];

  breadcrumbsStorage = [];

  persistParams = [];

  public afterLogin$ = new Subject<any>();

  initiatedRoutes = false;

  finalRoutes: any[] = [];

  allAvailableRoutes: any[] = [];

  allUnhandledRoutes: any[] = [];

  routesByParentId: any = {};

  protected beforeBack: (() => Observable<boolean> | boolean)[] = [];
  protected isBrowserBackBtnClicked = false;

  initialize(
    availableComponents: any[],
    preDefinedRouteConfigs: any[] = [],
    unhandledRoutes: string[] = []
  ): void {
    this.allAvailableRoutes = availableComponents;
    this.allPreDefinedRouteConfigs = preDefinedRouteConfigs;
    this.allUnhandledRoutes = unhandledRoutes;
  }


  getCurrentMenu() {
    let currentMenu;
    let lastPartOfUrl = (this.router.url).split('/').pop();
    if (lastPartOfUrl.includes('?')) {
      lastPartOfUrl = lastPartOfUrl.substring(0, lastPartOfUrl.indexOf('?'));
    }
    this.menu.find(item => {
      if(item?.menu_url === lastPartOfUrl) {
        currentMenu = item;
      }
    });
    return currentMenu;
  }

  getUrl(route): string {
    const path = route.menu_url.replace('#/', '').replace('.html', '');
    if (this.getComponentInfo(route)) {
      return path == 'javascript;;' ? route.state_name : path;
    } else {
      return path == 'javascript;;'
        ? route.title.replace(' ', '-').toLowerCase()
        : path;
    }
  }

  getChildren(item): any[] {
    return (this.menu || []).filter(subItem => subItem.menu_parent_id === item.id);
  }

  initMenu(type, parent = null): any[] {
    let menu = [];
    if (!parent) {
      menu = (this.menu || []).filter(item => ((item.section === type && !item.menu_parent_id) && (((item?.main_controller)?.trim() !== '') || (((item?.main_controller)?.trim() === '') && this.getChildren(item)?.length))));
    } else {
      menu = this.getChildren(parent).filter(item => item.section === type);
    }
    if (!menu.length) {
      return [];
    }
    menu.forEach(item => {
      dataTranslation.menu.push({
        title: item.title,
        translations: item.translations,
      });
    });
    return orderBy(menu.map(item => {
      const children = this.initMenu(type, item);
      return {
        title: item.title,
        translatedTitle: this.getDataFromTranslations(item, 'title'),
        icon: item.class_name,
        icon_path: item.icon_path.trim(),
        path: '/' + this.getUrl(item),
        isChild: !!parent,
        order: item.menu_order,
        main_controller: item.main_controller,
        menu_url: item.menu_url,
        type: item.type,
        submenu: children.length ? children : null,
        ...(
          (item?.main_controller === 'open_in_new_tab') ?
            {open_in_new_tab: item?.menu_extension_details?.open_in_new_tab, new_tab_url: item?.menu_extension_details?.url} :
            null
        )
      };
    }), ['order'], ['asc']);
  }

  getSavedBreadCrumbs(url: string): any {
    return this.breadcrumbsStorage.find((b) => b.alias === url);
  }

  recursiveMerge(oldRoute, newRoute): Route {
    if (newRoute.component === oldRoute.component && newRoute.path === oldRoute.path) {
      if (newRoute.children) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < newRoute.children.length; i++) {
          const idx = (oldRoute.children || []).findIndex((route: Route) => route.path === newRoute.children[i].path);
          if (idx === -1) {
            oldRoute.children.push(newRoute.children[i]);
          } else {
            oldRoute.children[idx] = this.recursiveMerge(oldRoute.children[idx], newRoute.children[i]);
          }
        }
      }
      return oldRoute;
    } else {
      return newRoute;
    }
  }

  mergeChildren(currentChildren: Routes): Routes {
    const newRoute: Route = this.calculateRouteFromHistory();
    const oldRouteIdx: number = currentChildren.findIndex((route: Route) => route.path === newRoute.path);

    if (oldRouteIdx === -1) {
      return this.routeChildren.concat([newRoute]);
    } else {
      currentChildren[oldRouteIdx] = this.recursiveMerge(currentChildren[oldRouteIdx], newRoute);
      return currentChildren;
    }
  }

  handleOnReload(): void {
    this.handleOnReloadWithRoute(location.pathname);
  }

  handleOnReloadWithRoute(url: string): void {
    const fullLocationPath = url.split('?');
    const locationPath = fullLocationPath[0].split('/');
    this.routesHistory = url
      ? locationPath.filter(
        (s, idx) => s && s !== locationPath[locationPath.length - 1]
      )
      : [];
    if (window['prefix']) {
      this.routesHistory.shift();
    }
    this.breadcrumbService.breadcrumbs$.subscribe((value) => {
      this.breadcrumbsStorage = value;
      localStorage.setItem((window['prefix'] || '') + 'breadcrumbsStorage',  JSON.stringify( this.breadcrumbsStorage));
    });
    this.persistParams = localStorage.getItem((window['prefix'] || '') + 'persistParams') ? JSON.parse(localStorage.getItem((window['prefix'] || '') + 'persistParams')) : [];
    this.paramsByRoute = localStorage.getItem((window['prefix'] || '') + 'paramsByRoute') ? JSON.parse(localStorage.getItem((window['prefix'] || '') + 'paramsByRoute')) : {};
    this.breadcrumbsStorage = localStorage.getItem((window['prefix'] || '') + 'breadcrumbsStorage') ? JSON.parse(localStorage.getItem((window['prefix'] || '') + 'breadcrumbsStorage')) : {};
  }

  handleMetaTags(path: string): void {
    if (path === 'login') {
      const timeout = this.metaService.getTag("name='timeout'")?.content;
      if (timeout && !this.metaService.getTag("http-equiv='refresh'")) {
        this.metaService.addTag({
          httpEquiv: 'refresh',
          content: timeout
        });
      }
    } else {
      this.metaService.removeTag("http-equiv='refresh'");
    }
  }

  getFirstMenu(){
    let menuOrdered = chain(this.menu).filter( i => ((i.section == 'left_menu' || i.section == 'top_left_menu') && !i.menu_parent_id)).orderBy('menu_order').map( i => {
      const children =  chain(this.menu).filter(j => j.menu_parent_id == i.id).orderBy('menu_order').value();
      return children.length ? children : [i];
    }).flatten().value();
    return menuOrdered.length ? menuOrdered[0].menu_url : 'home';
  }

  handleRoute(url: string) {
    if (this.isBrowserBackBtnClicked) {
      this.isBrowserBackBtnClicked =false;
      this.handleOnReloadWithRoute(url);
      this.router.navigateByUrl(url);
    } else {
      this.handleMenuItemRoute(url);
    }
  }

  handleMenuItemRoute(url: string): void {
    const fullLocationPath = url.split('?');
    let params = (get(fullLocationPath, '1') || '')
      .split('&')
      .filter((p) => p.indexOf('per--') === -1)
      .filter((p) => !!p);

    const keep = params.find(p => p.indexOf('--keep=true') !== -1);
    params = params.filter(p => p.indexOf('--keep=true') === -1);


    const persistParams = (get(fullLocationPath, '1') || '')
      .split('&')
      .filter((p) => p.indexOf('per--') !== -1)
      .map((p) => p.substr(5));
    let routesParts = fullLocationPath[0].split('/');
    if (window['prefix']) {
      routesParts = (routesParts || []).filter(r => window['prefix'] !== r);
    }

    this.handleMetaTags(routesParts[routesParts.length - 1]);

    if (routesParts[routesParts.length - 1] === 'login') {
      if (window['prefix'] && fullLocationPath[0].indexOf(window['prefix']) === -1) {
        this.router.navigateByUrl('/' + `${window['prefix']}` + fullLocationPath.join('?'));
      }
      return;
    }

    if (
      this.allUnhandledRoutes.find(
        (unhandledRoute) => fullLocationPath[0].indexOf(unhandledRoute) !== -1
      )
    ) {
      if (window['prefix'] && !(fullLocationPath[0].startsWith(window['prefix']) || fullLocationPath[0].startsWith('/' + window['prefix']))) {
        this.router.navigateByUrl('/' + `${window['prefix']}` + fullLocationPath.join('?'));
      }
      return;
    }


    if (routesParts[routesParts.length - 1] === '' || routesParts[routesParts.length - 1] === 'home') {
      let defaultState = null;
      let finalise = new Subject();

      this.tokenStorage.getConfiguration().pipe(takeUntil(finalise)).subscribe(res => {
        if(res){  defaultState= get(res,'default_state');
          finalise.next(null);
          finalise.complete();
        }
      });

      let defaultStateWithoutSlashes = defaultState?.replace(/\//g, '');
      if (this.menu?.find(r => r.menu_url === 'home') || this.allPreDefinedRouteConfigs?.find(r => r.path === 'home')) {
        routesParts[routesParts.length - 1] = 'home';
      } else if (this.menu.find(r => r.menu_url === defaultStateWithoutSlashes) || this.allPreDefinedRouteConfigs.find(r => r.path === defaultStateWithoutSlashes))
        routesParts[routesParts.length - 1] = defaultStateWithoutSlashes;
      else {
        routesParts[routesParts.length - 1] = this.getFirstMenu();
      }
    }

    if (
      this.routesHistory.length &&
      `&${this.routesHistory.join('&')}` === routesParts.join('&')
      // this.routesHistory[this.routesHistory.length - 1] ===
      // routesParts[routesParts.length - 1]
    ) {
      return;
    }

    const route = this.findMenuByUrl(routesParts[routesParts.length - 1]);
    // clear all cached APIs when navigate to a new component
    this.makeCall.emptyCacheOnNavigation();
    if (!keep && route && get(route, 'section') !== 'hidden') {
      this.routesHistory = [];
      this.paramsByRoute = {};
      this.persistParams = [];
      // clear all saved filters and seach terms when navigate to a new section
      this.searchFiltersService.clearAll();
    }

    const historyIdx = this.routesHistory.findIndex(
      (r) => r === routesParts[routesParts.length - 1]
    );
    if (historyIdx === -1) {
      this.routesHistory.push(routesParts[routesParts.length - 1]);
      this.persistParams = uniqBy([
        ...(this.persistParams || []),
        ...(persistParams || []),
      ], (param => (param || '').split('=')[0]));
      this.paramsByRoute[routesParts[routesParts.length - 1]] = uniqBy([
        ...(this.persistParams || []),
        ...(params || []),
      ], (param => (param || '').split('=')[0]));
    } else {
      this.paramsByRoute = omit(
        this.paramsByRoute,
        this.routesHistory.slice(historyIdx + 1, this.routesHistory.length)
      );
      this.routesHistory = this.routesHistory.slice(0, historyIdx + 1);
      this.persistParams = uniqBy([
        ...(this.persistParams || []),
        ...(persistParams || []),
      ], (param => (param || '').split('=')[0]));
      this.paramsByRoute[routesParts[routesParts.length - 1]] =    this.paramsByRoute[routesParts[routesParts.length - 1]] = uniqBy([
        ...(this.persistParams || []),
        ...(isEmpty(params)
          ? this.paramsByRoute[routesParts[routesParts.length - 1]] || []
          : params || []),
      ], (param => (param || '').split('=')[0]));
    }

    let isRouteAdded = false;
    const routerRoutesFinal = this.router.config;
    routerRoutesFinal.forEach((element) => {
      if (isRouteAdded) {
        return;
      }
      if (element.path === (window['prefix'] || '') && element.children) {
        element.children.forEach((r) => {
          if (isRouteAdded) {
            return;
          }
          if (r.path == '') {
            if (!this.routeChildren) {
              this.routeChildren = r.children;
            }
            dataTranslation.breadcrumb
              = [];
            r.children = this.mergeChildren(r.children || []);
            isRouteAdded = true;
          }
        });
      }
    });
    /***
     * Closing as a "won't fix". resetConfig is a config reset and should be assumed to be treated as a completely new config. As such, the previously stored routes cannot be re-used. That said, you can directly access and modify the router.config so that the object equality still works. https://angular.io/api/router/Router#config
     * https://github.com/angular/angular/issues/38326
     */
    // this.router.resetConfig(routerRoutesFinal);

    localStorage.setItem((window['prefix'] || '') + 'persistParams',  JSON.stringify(this.persistParams));
    localStorage.setItem((window['prefix'] || '') + 'paramsByRoute',  JSON.stringify( this.paramsByRoute));

    this.router.navigateByUrl(
      '/' + (window['prefix'] ? `${window['prefix']}/` : '') +
      this.routesHistory.join('/') +
      '?' +
      [
        ...uniq(
          (keep ? ['--keep=true'] : []).concat(this.paramsByRoute[routesParts[routesParts.length - 1]] || [])
        ),
      ].join('&')
    );
  }

  get menu(): any[] {
    return JSON.parse(window['menu']) || [];
  }

  findMenuByUrl(url: string): any {
    const menuItem = (this.menu || []).find((menuItem) => {
      const routesParts = menuItem.menu_url.split('/');
      return routesParts[routesParts.length - 1] === url;
    });
    if( menuItem?.translations){
      menuItem.title = menuItem.translations.find((item)=> { return item?.lang === this.tokenStorage.getCurrentLanguage() })?.title
    }
    return menuItem;
  }

  getComponentInfo(route): any {
    if (!route) {
      return false;
    }
    return this.allAvailableRoutes.find(
      (c) => c.name === route.main_controller
    );
  }


  calculateRouteFromMenuItem(idx, componentInfo, historyRoute, savedBreadcrumb, isLastRoute, parentIdx = 0): any {
    const parents = (isFunction(get(componentInfo, 'parentComponents')) ? componentInfo.parentComponents(this.routesHistory) : get(componentInfo, 'parentComponents')) || [];
    if (parents.length === parentIdx) {
      if (isLastRoute) {
        return {
          path: !parents.length ? this.routesHistory[idx] : '',
          component: componentInfo
            ? (componentInfo.module ? LazyLoadedComponent : componentInfo.component)
            : PageNotFoundComponent,
          data: {
            title:
            /*get(historyRoute, 'translations.0.title') ||*/
            historyRoute.title,
            id: historyRoute.id,
            canonical: '/' + this.routesHistory[idx],
            icon: historyRoute.class_name,
            section: historyRoute.section,
            routeInfo: componentInfo,
            skylineBackground: true,
            breadcrumb: {
              ...(get(savedBreadcrumb, 'label') !==
              this.routesHistory[idx]
                ? {
                  label:
                    get(savedBreadcrumb, 'label') ||
                    historyRoute.title ||
                    '',
                }
                : {}),
              alias: this.routesHistory[idx],
            },
          }
        };
      } else {
        return {
          path: !parents.length ? this.routesHistory[idx] : '',
          children: [
            {
              path: '',
              component: componentInfo
                ? (componentInfo.module ? LazyLoadedComponent : componentInfo.component)
                : PageNotFoundComponent,
              data: {
                title:
                /*get(historyRoute, 'translations.0.title') ||*/
                historyRoute.title,
                id: historyRoute.id,
                canonical: '/' + this.routesHistory[idx],
                icon: historyRoute.class_name,
                section: historyRoute.section,
                routeInfo: componentInfo,
                skylineBackground: true,
                breadcrumb: {
                  ...(get(savedBreadcrumb, 'label') !==
                  this.routesHistory[idx]
                    ? {
                      label:
                        get(savedBreadcrumb, 'label') ||
                        historyRoute.title ||
                        '',
                    }
                    : {}),
                  alias: this.routesHistory[idx],
                },
              },
            },
            ...(!parents.length ? [this.calculateRouteFromHistory(idx + 1)] : []),
          ],
        };
      }
    } else if (parentIdx === 0) {
      return {
        path: this.routesHistory[idx],
        children: [
          {
            component: parents[parentIdx].componentName ? LazyLoadedComponent : parents[parentIdx], // parent component | module + component name
            path: '',
            data: {
              title:
              /*get(historyRoute, 'translations.0.title') ||*/
              historyRoute.title,
              id: historyRoute.id,
              canonical: '/' + this.routesHistory[idx],
              icon: historyRoute.class_name,
              section: historyRoute.section,
              routeInfo: parents[parentIdx].componentName ? parents[parentIdx] : null,
              skylineBackground: true,
              breadcrumb: {
                ...(get(savedBreadcrumb, 'label') !==
                this.routesHistory[idx]
                  ? {
                    label:
                      get(savedBreadcrumb, 'label') ||
                      historyRoute.title ||
                      '',
                  }
                  : {}),
                alias: this.routesHistory[idx],
              },
            },
            children: [
              ...[this.calculateRouteFromMenuItem(idx, componentInfo, historyRoute, savedBreadcrumb, false, parentIdx + 1)]
            ]
          },
          ...(!isLastRoute ? [this.calculateRouteFromHistory(idx + 1)] : []),
        ]
      };
    } else {
      return {
        path: '',
        component: parents[parentIdx].componentName ? LazyLoadedComponent : parents[parentIdx],
        data: {
          routeInfo: parents[parentIdx].componentName ? parents[parentIdx] : null,
        },
        children: [
          ...[this.calculateRouteFromMenuItem(idx, componentInfo, historyRoute, savedBreadcrumb, false, parentIdx + 1)],
        ]
      };
    }
  }

  calculateRouteFromPredefinedConfig(idx, predefinedConfig, isLastRoute, parentIdx = 0): any {
    const parents = (isFunction(predefinedConfig.parentComponents) ? predefinedConfig.parentComponents(this.routesHistory) : predefinedConfig.parentComponents) || [];
    if (parents.length === parentIdx) {
      if (isLastRoute) {
        return {
          path: !parents.length ? predefinedConfig.path : '',
          ...omit(predefinedConfig, ['path', 'parentComponents']),
        };
      } else {
        return {
          path: !parents.length ? predefinedConfig.path : '',
          children: [
            {
              path: '',
              ...omit(predefinedConfig, ['path', 'parentComponents']),
            },
            ...((!parents.length) ? [this.calculateRouteFromHistory(idx + 1)] : []),
          ]
        };
      }
    } else if (parentIdx === 0) {
      return {
        path: predefinedConfig.path,
        children: [
          {
            path: '',
            component: parents[parentIdx].componentName ? LazyLoadedComponent : parents[parentIdx],
            ...omit(predefinedConfig, ['path', 'parentComponents', 'component']),
            data: {
              ...(predefinedConfig.data || {}),
              routeInfo: parents[parentIdx].componentName ? parents[parentIdx] : null,
            },
            children: [
              ...[this.calculateRouteFromPredefinedConfig(idx, predefinedConfig, false, parentIdx + 1)],
            ]
          },
          ...(!isLastRoute ? [this.calculateRouteFromHistory(idx + 1)] : []),
        ]
      };
    } else {
      return {
        path: '',
        component: parents[parentIdx].componentName ? LazyLoadedComponent : parents[parentIdx],
        data: {
          routeInfo: parents[parentIdx].componentName ? parents[parentIdx] : null,
        },
        children: [
          ...[this.calculateRouteFromPredefinedConfig(idx, predefinedConfig, false, parentIdx + 1)],
        ]
      };
    }
  }


  calculateRouteFromHistory(idx = 0): any {
    if (idx < this.routesHistory.length - 1) {
      const historyRoute = this.findMenuByUrl(this.routesHistory[idx]);
      const componentInfo = this.getComponentInfo(historyRoute);
      const savedBreadcrumb = this.getSavedBreadCrumbs(this.routesHistory[idx]);
      const predefinedConfig = this.allPreDefinedRouteConfigs.find(
        (config) => config.path === this.routesHistory[idx]
      );

      if (historyRoute) {
        dataTranslation.breadcrumb.push({
          title: historyRoute.title,
          translations: historyRoute.translations,
        });
      }
      return predefinedConfig
        ? this.calculateRouteFromPredefinedConfig(idx, predefinedConfig, false)
        : (historyRoute
          ? this.calculateRouteFromMenuItem(idx, componentInfo, historyRoute, savedBreadcrumb, false)
          : {
            path: this.routesHistory[idx],
            children: [
              {
                path: '',
                component: PageNotFoundComponent,
              },
              ...[this.calculateRouteFromHistory(idx + 1)],
            ],
          });
    } else {
      const route = this.findMenuByUrl(this.routesHistory[idx]);
      const componentInfo = this.getComponentInfo(route);
      const savedBreadcrumb = this.getSavedBreadCrumbs(this.routesHistory[idx]);
      const predefinedConfig = this.allPreDefinedRouteConfigs.find(
        (config) => config.path === this.routesHistory[idx]
      );
      if (route) {
        dataTranslation.breadcrumb.push({
          title: route.title,
          translations: route.translations,
        });
      }
      return predefinedConfig
        ? this.calculateRouteFromPredefinedConfig(idx, predefinedConfig, true)
        : (route
          ? this.calculateRouteFromMenuItem(idx, componentInfo, route, savedBreadcrumb, true)
          : {
            path: this.routesHistory[idx],
            component: PageNotFoundComponent,
          });
    }
  }

  registerBeforeBackEvent(cb: () => Observable<boolean> | boolean): void {
    this.beforeBack.push(cb);
  }

  removeBeforeBackEvent(cb: () => Observable<boolean> | boolean): void {
    this.beforeBack = (this.beforeBack || []).filter(c => c !== cb);
  }

  beforeBackCall(): Observable<boolean> {
    return new Observable(observer => {
      this._beforeBackCall(0, observer);
    });
  }

  protected _beforeBackCall(idx, observer) {
    if (this.beforeBack?.length && idx < this.beforeBack.length) {
      let result = this.beforeBack[idx]();
      if (!isObservable(result)) {
        result = of(result) as Observable<boolean>;
      }
      result.subscribe(value => {
        if (value) {
          if (idx === (this.beforeBack.length -1)) {
            observer.next(true);
            observer.complete();
          } else {
            this._beforeBackCall(idx + 1, observer);
          }
        } else {
          observer.next(false);
          observer.complete();
        }
      });
    } else if (!this.beforeBack?.length) {
      observer.next(true);
      observer.complete();
    }
  }

  getSectionTitleFromMenu() {
    let sectionTitle = '';
    let parentSectionTitle = '';
    let lastPartOfUrl = (this.router.url).split('/').pop();
    if (lastPartOfUrl.includes('?')) {
      lastPartOfUrl = lastPartOfUrl.substring(0, lastPartOfUrl.indexOf('?'));
    }
    this.menu.find(item => {
      if(item?.menu_url === lastPartOfUrl) {
        if (item.menu_parent_id) {
          this.menu.find(parentItem => {
            if (parentItem.id === item.menu_parent_id) {
              parentSectionTitle = this.getDataFromTranslations(parentItem, 'title');
            }
          });
        }
        sectionTitle = this.getDataFromTranslations(item, 'title');
      }
    });
    return parentSectionTitle.length ? parentSectionTitle + ' - ' + sectionTitle : sectionTitle;
  }

  getSectionTranslationDataFromMenu(menuItem: string) {
    let sectionMenuItem = '';
    let lastPartOfUrl = (this.router.url).split('/').pop();
    if (lastPartOfUrl.includes('?')) {
      lastPartOfUrl = lastPartOfUrl.substring(0, lastPartOfUrl.indexOf('?'));
    }
    this.menu.find(item => {
      if(item?.menu_url === lastPartOfUrl) {
        if (item.menu_parent_id) {
          sectionMenuItem = this.getDataFromTranslations(item, menuItem);
        }
      }
    });
    return sectionMenuItem;
  }

  getDataFromTranslations(item, key) {
    return item?.translations?.find(translation => {
      return translation?.lang === (this.tokenStorage.getCurrentLanguage() ? this.tokenStorage.getCurrentLanguage() : '');
    })[key];
  }

  isNavigationPeventedOnRoute(menuItem: any): boolean {
    return menuItem.type === 'service';
  }

  handleCustomRoute(menuItem: any): void {
    switch (menuItem.type) {
      case 'service': {
        const handlerConfig = this.allAvailableRoutes.find(route => route.name === menuItem.main_controller);
        if (!handlerConfig) {
          return;
        }
        this.loadService(handlerConfig).then(service => {
          service.handle(menuItem);
        });
        break;
      }
      default:
        break;
    }
  }

  async loadService(config: any): Promise<any> {
    if (config.module) {
      const cachedModule = this.lazyLoadingCacheService.getCachedModule(config.module.name);
      let result: any = null;
      let moduleRef: any = null;
      if (!cachedModule) {
        result = await config.module.load();
        moduleRef = createNgModuleRef(result.module, this.injector);
        this.lazyLoadingCacheService.cacheModule(config.module.name, result, moduleRef);
      } else {
        result = cachedModule.module;
        moduleRef = cachedModule.moduleRef;
      }
      return moduleRef.injector.get(result.components[config.serviceName || config.name]  as Type<any>);
    } else {
      return this.injector.get(config.service  as Type<any>);
    }
  }

  clearParams(){
    localStorage.removeItem((window['prefix'] || '') + 'persistParams');
    localStorage.removeItem((window['prefix'] || '') + 'paramsByRoute');
    localStorage.removeItem((window['prefix'] || '') + 'breadcrumbsStorage');
    this.routesHistory = [];
    this.paramsByRoute = {};
    this.breadcrumbsStorage = [];
    this.persistParams = [];
  }

}
