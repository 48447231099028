import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { DataStorageService } from '../../services/data-storage.service';
import { MakeCallService } from '../../services/makecall.service';
import { AlertService } from '../../services/alert.service';
import { TokenStorage } from '../../services/token-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '../../services/loader/loader.service';
import { RouteService } from '../../services/route.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import moment from 'moment';
import {CustomValidatorsService} from '../../services/custom-validators.service';
import {GeneralConfigService} from '../../services/general-config.service';

import { DatePipe } from '@angular/common';
import { interval } from 'rxjs';
import _ from 'lodash';


@Component({
  selector: 'app-login-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit {
  selfOnboardingCategoryKey = this.tokenStorage.getSelfOnboardingCategoryKey();

  returnUrl = '';
  number = '';
  data: any;
  curLang: string;
  counter: number;
  token: any;
  email: any;
  register_validation: any;
  otpForm: UntypedFormGroup;
  @Output() LangChangedEvent = new EventEmitter<string>();
  configuration = JSON.parse(window['configuration']);
  logo = this.configuration && this.configuration['login_logo'] ? this.configuration['login_logo'] : this.configuration['logo'];
  projectName = this.configuration ? this.configuration['title'] :'';
  currentYear = moment().year();
  otpLength: number;
  intervalId: any;
  dateOncePageLoaded: Date;
  cloneCounter;
  recaptchaEnabled: boolean = !!window['google_recaptcha_guest'];
  showCopyright;
  showDesignedDevelopedByFoo;

  public constructor(
    private activatedRoute: ActivatedRoute,
    private _router: Router,
    private DataStorage: DataStorageService,
    private makecall: MakeCallService,
    private loaderService: LoaderService,
    private tokenStorage: TokenStorage,
    private alert: AlertService,
    private translate: TranslateService,
    private routesService: RouteService,
    public datepipe: DatePipe,
    private generalConfigService: GeneralConfigService,
    private customValidators: CustomValidatorsService) {
    translate.setDefaultLang(this.tokenStorage.getCurrentLanguage());
    if(this.DataStorage.storage){
      this.data = this.DataStorage.storage;
      this.counter = this.data["resend_counter"]*1000;
      this.cloneCounter = _.cloneDeep(this.counter);
      this.dateOncePageLoaded = new Date();
      if(this.data['token']){
        this.token = this.data['token'];
        this.email = this.data['email'];
        this.register_validation = this.data['register_validation'];
        this.checkOtpRequest();
      }
    } else {
      if (this.returnUrl) {
        this._router.navigateByUrl(this.returnUrl);
      } else {
        this._router.navigate(['/home']);
      }
    }
    this.initTimer();
    document.addEventListener("visibilitychange", this.initTimerAfterTabVisibilitychange.bind(this));
  }

  ngOnInit(): void {
    const showCopyrightVal = this.generalConfigService.getConfigProperty('commonConfig')?.showCopyright;
    this.showCopyright = showCopyrightVal !== undefined ? showCopyrightVal : true;
    const showDesignedDevelopedByFooVal = this.generalConfigService.getConfigProperty('commonConfig')?.showDesignedDevelopedByFoo;
    this.showDesignedDevelopedByFoo = showDesignedDevelopedByFooVal !== undefined ? showDesignedDevelopedByFooVal : true;

    this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'];
    this.curLang = this.tokenStorage.getCurrentLanguage();
    this.otpForm = new UntypedFormGroup({
      otp: new UntypedFormControl('', [Validators.required, Validators.minLength(this.otpLength), Validators.maxLength(this.otpLength), this.customValidators.onlyNumbers])
    });
  }

  get f() { return this.otpForm.controls; }

  checkOtpRequest(){

    this.makecall.postCall('auth/checkpasscode', {
      email: this.email,
      code: this.token
    }, null, { googleRecaptchaEnabled: this.recaptchaEnabled }).subscribe(resp=> {
        if(resp['status'] !== 'OK'){
          this.alert.emitAlert({type:"danger", text: resp["message"]});

          if(!this.register_validation){
            this._router.navigate(['/self-onboarding-signup']);
          }
          else if(this.returnUrl){
            this._router.navigate(['/login'], { queryParams: { returnUrl: this.returnUrl } });
          }else{
            this._router.navigate(['/login']);
          }
        }else{
          if(resp['cms_otp_length']){
            this.otpLength = resp['cms_otp_length'];
            this.otpForm.controls.otp.setValidators([Validators.required, Validators.minLength(this.otpLength), Validators.maxLength(this.otpLength), this.customValidators.onlyNumbers]);
          }
        }
      },
      error=>{
        this.alert.emitAlert({type:"danger", text: "Something went wrong"});

        if(!this.register_validation){
          this._router.navigate(['/self-onboarding-signup']);
        }
        else if(this.returnUrl){
          this._router.navigate(['/login'], { queryParams: { returnUrl: this.returnUrl } });
        }else{
          this._router.navigate(['/login']);
        }
      });
  }

  resendCode() {
    if (this.counter == 0) {
      this.loaderService.visibility = true;
      this.makecall.postCall('auth/resendotp', {
        username: this.email,
        register_validation : this.register_validation
      }, null , { googleRecaptchaEnabled: this.recaptchaEnabled }).subscribe(resp => {
          if (resp['status'] === 'success' || resp['status'] === 'OK') {
            this.counter = resp["expireAfter"] * 1000;
            this.cloneCounter = _.cloneDeep(this.counter);
            this.dateOncePageLoaded = new Date();
            this.token = resp['token'];
            this.alert.emitAlert({type: 'success', text: resp['message']});

            this.initTimer();
          } else {
            this.alert.emitAlert({type: "danger", text: resp["message"]});
          }
          this.loaderService.visibility = false;
        },
        error => {
          this.loaderService.visibility = false;
          this.alert.emitAlert({type: "danger", text: "Something went wrong"});
        });
    }
  }

  onSubmit() {
    if (this.otpForm.valid) {
      this.loaderService.visibility = true;
      this.makecall.postCall('auth/submitotp', {
        otp: this.f.otp.value,
        code: this.token,
        email: this.email,
        register_validation : this.register_validation
      }, null, { googleRecaptchaEnabled: this.recaptchaEnabled }).subscribe((resp: any) => {
          if (resp['status'] === 'success' || resp['status'] === 'OK') {

            let appStatus = resp['data'] && resp['data'].status ? resp['data'].status : null;
            let isSelfOnboarding = (this.selfOnboardingCategoryKey && (appStatus == 'DRAFT' || appStatus == 'PENDING')) || !this.register_validation;
            this.tokenStorage.setWebConfig(JSON.stringify(resp["configuration"]));

            if (resp["user"] && !isSelfOnboarding) {
              this.tokenStorage.setUser(JSON.stringify(resp["user"]));
              this.tokenStorage.setUserRoles(JSON.stringify(resp["role"]));
              this.tokenStorage.setLanguages(JSON.stringify(resp["languages"]));
              this.tokenStorage.setLastLogin(JSON.stringify(resp["user"].lastlogin));
              this.tokenStorage.setConfiguration(JSON.stringify(resp["configuration"]));
              window['user'] = JSON.stringify(resp["user"]);
              window['menu'] = JSON.stringify(resp["menu"]);
              window['configuration'] = JSON.stringify(resp["configuration"]);
              window['role'] = JSON.stringify(resp["role"]);
              window['languages'] = JSON.stringify(resp["languages"]);
              this.routesService.afterLogin$.next(true);
            }

            if (isSelfOnboarding) {
              if (appStatus == 'DRAFT') {
                if (resp['data'] && resp['data'].application_id) {
                  this.tokenStorage.setSelfOnboardingApplicationId(resp['data'].application_id);
                }
              }

              if (appStatus == 'PENDING') {
                if (resp['data'] && resp['data'].category_answer_id) {
                  this.tokenStorage.setSelfOnboardingCategoryAnswerId(resp['data'].category_answer_id);
                }
              }

              if (resp['data'] && resp['data'].token) {
                this.tokenStorage.setSelfOnboardingToken(resp?.data?.token);
              }
              this._router.navigate(['/self-onboarding']);
            }

            if (!isSelfOnboarding) {
              if (this.data["route"]) {
                if (this.returnUrl) {
                  this._router.navigateByUrl(this.returnUrl);

                } else {
                  this._router.navigate([this.data["route"]]);
                }
              } else {
                if (this.returnUrl) {
                  this._router.navigateByUrl(this.returnUrl);
                } else {
                  this._router.navigate(['/home']);
                }
              }
            }
          } else {
            this.alert.emitAlert({type: "danger", text: resp["message"]});
          }
        },
        error => {
          this.loaderService.visibility = false;
          this.alert.emitAlert({type: "danger", text: error["message"]});
        });
    }
  }

  initTimer() {
    this.intervalId = interval(1000).subscribe(() => {
      this.counter = this.counter - 1000;
      if (this.counter === 0) {
        this.intervalId.unsubscribe();
      }
    });
  }

  initTimerAfterTabVisibilitychange() {
    if (!document.hidden) {
      let dateOncePageActive = new Date();
      let dateBetween: any = moment(dateOncePageActive).diff(moment(this.dateOncePageLoaded), 'seconds');
      dateBetween = dateBetween * 1000;
      if (this.cloneCounter >= dateBetween) {
        this.counter = this.cloneCounter - dateBetween;
      }
    } else {
      if (this.counter > 0) {
        this.intervalId.unsubscribe();
        this.initTimer();
      }
    }
  }
}
