import {Directive, Inject} from '@angular/core';
import {values} from 'lodash';
import {DOCUMENT} from '@angular/common';

@Directive({
  selector: '[appPopupsDropdownsAlignment]'
})
export class PopupsDropdownsAlignmentDirective {

  bookedObservers = {};
  counter = 0;

  constructor(
    @Inject(DOCUMENT) private dom
  ) {
    const targetNode = this.dom.querySelector('body');
    const config = {childList: true, subtree: true, };
    const callback = (mutationList, observe) => {
      if (!targetNode.querySelector('mat-dialog-container')) {
        values(this.bookedObservers).forEach((bookedObserver: any) => {
          bookedObserver.disconnect();
        });
        this.bookedObservers = {};
        this.counter = 0;
      } else {
        const modalOpen = mutationList.find(item => Array.from(item.target.classList).find(i => i === 'modal-open'));
        if (modalOpen?.addedNodes?.length && modalOpen.target.querySelectorAll('mat-dialog-container .modal')?.length) {
          modalOpen.target.querySelectorAll('mat-dialog-container .modal').forEach(modalDom => {
            if (!this.bookedObservers[modalDom.id]) {
              this.scrollToView(modalDom.querySelector('.modal-content'));
              modalOpen.target.querySelector('mat-dialog-content').style.minHeight = `${modalDom.querySelector('.modal-content').offsetHeight}px`;
              const modalObserver = new MutationObserver(() => {
                if (!Array.from(modalDom.classList).find(i => i === 'show')) {
                  modalOpen.target.querySelector('mat-dialog-content').style.minHeight = null;
                } else {
                  this.scrollToView(modalDom.querySelector('.modal-content'));
                  modalOpen.target.querySelector('mat-dialog-content').style.minHeight = `${modalDom.querySelector('.modal-content').offsetHeight}px`;
                }
              });
              this.bookedObservers[modalDom.id] = modalObserver;
              modalObserver.observe(modalDom, {
                ...config,
                attributes: true
              });
            }
          });
        }

        const countryDropDown = mutationList.find(item => Array.from(item.target.classList).find(i => i === 'country-dropdown'));
        const countryDropDownParentDialog = this.getParentDialog(countryDropDown?.target);
        if (countryDropDown && !countryDropDown.target.id) {
          countryDropDown.target.id = `dropdown-${this.counter++}`;
        }
        if (countryDropDown?.addedNodes?.length && !this.bookedObservers[countryDropDown.target.id] && countryDropDownParentDialog) {
          this.scrollToView(countryDropDown.target?.parentElement);
          countryDropDownParentDialog.style.minHeight = `${countryDropDown.target.offsetHeight}px`;
          const countryDropDownObserver = new MutationObserver((list) => {
            if (Array.from(countryDropDown.target.classList).find(i => i === 'show')) {
              this.scrollToView(countryDropDown.target?.parentElement);
              countryDropDownParentDialog.style.minHeight = `${countryDropDown.target.offsetHeight}px`;
            } else {
              countryDropDownParentDialog.style.minHeight = null;
            }
          });
          this.bookedObservers[countryDropDown.target.id] = countryDropDownObserver;
          countryDropDownObserver.observe(countryDropDown.target, {...config, attributes: true});
        }


        const ngSelect = mutationList.find(item => item.target.tagName === 'NG-SELECT');
        const ngSelectParentDialog = this.getParentDialog(ngSelect?.target);
        if (ngSelect?.addedNodes?.length && ngSelectParentDialog && this.checkHeight(ngSelect.target)) {
          this.scrollToView(ngSelect.target.querySelector('ng-dropdown-panel'));
          ngSelectParentDialog.style.minHeight = `${ngSelect.target.querySelector('ng-dropdown-panel')?.offsetHeight}px`;
        } else if (ngSelect?.removedNodes?.length && ngSelectParentDialog) {
          ngSelectParentDialog.style.minHeight = null;
        }

        const ngSelectContainer = mutationList.find(item => Array.from(item.target.classList).find(i => i === 'ng-select-container'));
        const ngSelectContainerParentDialog = this.getParentDialog(ngSelectContainer?.target);
        if (ngSelectContainer?.removedNodes?.length && ngSelectContainerParentDialog && this.checkHeight(ngSelectContainer.target.parentElement)) {
          this.scrollToView(ngSelectContainer.target.parentElement?.querySelector('ng-dropdown-panel'));
        }
      }
    };

    const observer = new MutationObserver(callback);

    observer.observe(targetNode, config);
  }

  checkHeight(t){
    if(t) {
      let checkScrollDropdown = t.querySelector('ng-dropdown-panel')?.getBoundingClientRect();
      checkScrollDropdown = checkScrollDropdown?.y + t.querySelector('ng-dropdown-panel')?.clientHeight as any;
      let checkScrollDialog = document.querySelector('mat-dialog-content')?.getBoundingClientRect();
      checkScrollDialog = checkScrollDialog?.y + checkScrollDialog?.height as any;
      return checkScrollDialog <= checkScrollDropdown;
    }else{
      return false;
    }
  }

  getParentDialog(target: any): any {
    if (!target) {
      return;
    }
    while (target.tagName.toLowerCase() !== 'body') {
      if (target.tagName.toLowerCase() === 'mat-dialog-content') {
        return target;
      }
      target = target.parentNode;
    }
    return null;
  }

  scrollToView(node: any): void {
    if (node && node.scrollIntoView) {
      node.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }

}
