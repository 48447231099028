import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl, Validators} from "@angular/forms";
import {FooControlOptions} from "../foo-control/foo-control-options";
import { isString } from 'lodash';

@Component({
  selector: 'foo-control-label',
  templateUrl: './foo-control-label.component.html',
  styleUrls: ['./foo-control-label.component.scss']
})
export class FooControlLabelComponent implements OnInit {

  @Input() templateOptions: FooControlOptions;

  @Input() control: UntypedFormControl;


  constructor() { }

  ngOnInit(): void {
  }

  deleteRepeatItem(): void{
    if (this.templateOptions.onItemRemoval) {
      this.templateOptions.onItemRemoval(this.templateOptions);
    }
  }

  get requiredValidator() {
    return Validators.required;
  }
  isString(obj:any):boolean{
    return isString(obj);
  }
}
