import { Injectable } from '@angular/core';

@Injectable()
export class ColorService {
  public loadColors(colors: string[], colorMap: Map<string, string>) {
    // Read the custom property of body section with given name:
    const appElement = document.getElementsByClassName('page-body');
    if (appElement && appElement.length > 0) {
      const appStyles = window.getComputedStyle(document.body);
      colors.forEach((el) => {
        colorMap.set(
          el,
          appStyles.getPropertyValue(el).replace(' ', '')
        );
      });
    }
  }
}
