import {Component, forwardRef, Injector, Input, OnInit, TemplateRef} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {FooTemplatesService} from '../../foo-templates.service';
import {FooControlComponent} from '../foo-control/foo-control.component';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FooCheckboxComponent),
  multi: true
};

@Component({
  selector: 'foo-checkbox',
  templateUrl: './foo-checkbox.component.html',
  styleUrls: ['./foo-checkbox.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class FooCheckboxComponent extends FooControlComponent implements OnInit {

  disableOthers = false;

  constructor(
    private fooTemplateService: FooTemplatesService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.control.setValue(this.templateOptions.items.reduce((memo: any, current: any) => {
      if (current.selected) {
        return memo.concat([current.value]);
      }
      return memo;
    }, [] as any));

     this.templateOptions.items.forEach(element => {
       if (element.value == 'none' && element.selected == true) {
         this.disableOthers = true;
       }
     });
  }

  getTemplate(id: string): TemplateRef<any> {
    return this.fooTemplateService.getTemplate(id);
  }

  get labelTemplate(): string {
    return this.templateOptions?.labelTemplate || 'checkboxDefault';
  }

  get label(): string {
    return this.templateOptions?.label || 'No Label Specified';
  }

  setValues(val: any): void {
    if (val.source.name == "none") {
      this.templateOptions.items.forEach(element => {
        if (element.value != val.source.name) {
          element.selected = false;
        }
      });
      if (val.checked == true) {
        this.disableOthers = true;
      } else {
        this.disableOthers = false;
      }
    }
    /////////////
    if (this.templateOptions.checkOnlyOne && val.checked) {
      this.templateOptions.items.forEach(element => {
        if (element.value != val.source.name) {
          element.selected = !val.checked;
        }
      });
    }

    this.control.setValue(this.templateOptions.items.reduce((memo: any, current: any) => {
      if (current.selected) {
        return memo.concat([current.value]);
      }
      return memo;
    }, [] as any));

  }

}
