import {Injectable, Injector} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
  AlertService,
  CurrencyService,
  MakeCallService,
  ApprovalService,
  ParserService,
  ReplaceSeparatorAndCapitalizePipe,
  TokenStorage
} from 'foo-framework';
import {TransactionDetailsService, TranslationService} from 'foo-framework/shared-modules';
import {Subscription} from "rxjs";
import _ from 'lodash';
import {DmpDataServicesService} from "../services/dmp-data-services.service";
import {DecimalPipe} from '@angular/common';

@Injectable({
  providedIn: 'root'
})

export class DmpTransactionDetailsService extends TransactionDetailsService {
  regions = [];
  branches = [];
  currencies = [];
  paymentMethods: any = [];
  paymentGateways: any = [];
  merchants: any = [];
  cardTypes: any = [];
  merchantGroups: any = [];
  userType: string = window['userType'] ? JSON.parse(window['userType']) : 'cms';
  subscriptions = new Subscription();

  constructor(
    protected injector: Injector,
    protected translate: TranslateService,
    protected makecall: MakeCallService,
    protected alert: AlertService,
    protected parser: ParserService,
    protected translationService: TranslationService,
    protected decimalPipe: DecimalPipe,
    protected replaceSeparatorAndCapitalizePipe: ReplaceSeparatorAndCapitalizePipe,
    protected dataServices: DmpDataServicesService,
    protected tokenStorage: TokenStorage,
    protected approvalService: ApprovalService,
    protected currencyService: CurrencyService
  ) {
    super(injector, translate, makecall, alert, parser, translationService, decimalPipe, tokenStorage, replaceSeparatorAndCapitalizePipe, currencyService);
    this.subscriptions.add(this.approvalService.refresh.asObservable().subscribe((data) => {
      if (data) {
        this.subscriptions.add(this.dataServices.getBranches(false).subscribe());
        this.subscriptions.add(this.dataServices.getAllCurrencies(false).subscribe());
        this.subscriptions.add(this.dataServices.getRegions(false).subscribe());
        this.subscriptions.add(this.dataServices.getMerchantApprovalData().subscribe());
        this.subscriptions.add(this.dataServices.getMerchantGroups(false).subscribe());

        this.setRegions();
        this.setBranches();
        this.setAllCurrencies();
        this.setMerchantsApprovalData();
        this.setMerchantGroups();
      }
    }));
  }

  setMerchantGroups = function () {
    this.subscriptions.add(this.dataServices.merchantGroups.asObservable().subscribe((data) => {
      if (data) {
        this.merchantGroups = data;
      }
    }));
  }
  setAllCurrencies = function () {
    this.subscriptions.add(this.dataServices.currencies.asObservable().subscribe((data) => {
      if (data) {
        this.currencies = data;
      }
    }));
  };

  setRegions = function () {
    this.subscriptions.add(this.dataServices.regions.asObservable().subscribe((data) => {
      if (data) {
        this.regions = data;
      }
    }));
  }

  setBranches = function () {
    this.subscriptions.add(this.dataServices.branches.asObservable().subscribe((data) => {
      if (data) {
        this.branches = data;
      }
    }));
  }

  setMerchantsApprovalData = function () {
    this.subscriptions.add(this.dataServices.merchantApprovalData.asObservable().subscribe((data) => {
      if (data) {
        this.paymentMethods = data['paymentMethods'];
        this.paymentGateways = data['paymentGateways'];
        this.merchants = data['merchants'];
        this.cardTypes = data['cardTypes'];
      }
    }));
  };

  transformData(data, category, roles) {
    if (data.length > 0) {
      // this.getCustomValuesData(data);
      if (data[0].action === 'delete') {
        return super.getDeleteInfo(data);
      }
      if (data[0].action === 'edit' || data[0].action === 'add') {
        data[0].description_transaction = this.getDescriptionItem(data[0], category);
      }

      if (category === 'branches') {
        return this.getPendingBranchData(data);
      } else if (category === 'terminals') {
        return this.getPendingTerminalData(data);
      } else if (category === 'merchant') {
        return this.getMerchantData(data);
      } else if (category === 'merchant_configuration') {
        return this.getMerchantPaymentControlsData(data);
      } else if (category === 'aggregator') {
        return this.getPendingAggregatorData(data);
      } else if (category === 'pricing_management') {
        return this.getPendingPricingManagement(data);
      } else {
        return {pending_transaction: data[0]};
      }
    } else {
      return '';
    }
  }

  getPendingPricingManagement(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };
    if (transactions && transactions.length) {
      for (const element of transactions) {
        if (element.description_transaction) {
          dataToSend.pending_transaction.description_transaction = transactions.description_transaction;
        }
        for (let j = 0; j < element.transaction_details.length; j++) {
          if (element.transaction_details[j].column_name !== 'id' && element.transaction_details[j].column_name !== 'pricing_type') {
            if (element.transaction_details[j].column_name == 'merchant_group_id') {
               dataToSend.pending_transaction.transaction_details.push({
                column_name: this.translate.instant('pricingRules.merchantGroup'),
                old_value:element.transaction_details[j].old_relation?element.transaction_details[j].old_relation?.name: this.merchantGroups?.find(x => x.id == element.transaction_details[j].old_value)?.name,
                new_value:element.transaction_details[j].new_relation?element.transaction_details[j].new_relation?.name: this.merchantGroups?.find(x => x.id == element.transaction_details[j].new_value)?.name,
              });
            } else if (element.transaction_details[j].column_name === 'equation') {

              let newEquationData = JSON.parse(element.transaction_details[j].new_value);
              let oldEquationData = JSON.parse(element.transaction_details[j].old_value);
              let equationData = [];
              for (const property in newEquationData) {
                if (property.toLowerCase() !== 'recipient') {
                  equationData.push({
                    column_name: property,
                    old_value: oldEquationData ? oldEquationData[property] : null,
                    new_value: newEquationData ? newEquationData[property] : null
                  });
                }
              }
              equationData.forEach((item: any) => {
                dataToSend.pending_transaction.transaction_details.push({
                  column_name: item.column_name,
                  old_value: item.old_value,
                  new_value: item.new_value
                });
              });
            } else if (element.transaction_details[j].column_name === 'acquirer_id') {
              dataToSend.pending_transaction.transaction_details.push({
                column_name: this.translate.instant('approval.acquirerName'),
                old_value: element.transaction_details[j]?.old_relation?.email,
                new_value: element.transaction_details[j]?.new_relation?.email,
              });
            } else {
              dataToSend.pending_transaction.transaction_details.push({
                column_name: element.transaction_details[j].column_name,
                old_value: element.transaction_details[j].old_value,
                new_value: element.transaction_details[j].new_value,
                lang: element.transaction_details[j].lang
              });
            }
          }
        }
      }
    }
    return dataToSend;
  }

  getPendingAggregatorData(transactionsData) {
    let transactions = transactionsData.find(x => x.key == 'aggregator') || transactionsData[0];
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };
    if (transactions.description_transaction) {
      dataToSend.pending_transaction.description_transaction = transactions.description_transaction;
    }
    if (transactions.transaction_details) {
      for (const element of transactions.transaction_details) {
        if (element.column_name == "pfi") {
          dataToSend.pending_transaction.transaction_details.push({
            column_name: this.translate.instant('aggregator.paymentFacilitatorID'),
            old_value: element.old_value,
            new_value: element.new_value,
            lang: element.lang
          });
        }
        if (element.column_name == "iso") {
          dataToSend.pending_transaction.transaction_details.push({
            column_name: this.translate.instant('aggregator.independentSalesOrganizationID'),
            old_value: element.old_value,
            new_value: element.new_value,
            lang: element.lang
          });
        }
        if (element.column_name == "name" || element.column_name == "email") {
          dataToSend.pending_transaction.transaction_details.push({
            column_name: element.column_name,
            old_value: element.old_value,
            new_value: element.new_value,
            lang: element.lang
          });
        }


      }
    }
    if (transactions?.extra?.merchants_list && transactions?.extra?.merchants_list.length) {
      let new_merchants_value = '';
      let old_merchants_value = '';
      transactions?.extra?.merchants_list.forEach(merchantId => {
        const merch = this.merchants.find(m => m?.id === merchantId);
        if (merch) {
          new_merchants_value += merch?.translations?.[this.curLang]?.name + ',';
        }
      });
      new_merchants_value = new_merchants_value.slice(0, -1);
      if (transactions?.extra?.old_merchants_list && transactions?.extra?.old_merchants_list.length) {
        transactions?.extra?.old_merchants_list.forEach(merchantId => {
          const merch = this.merchants.find(m => m?.id === merchantId);
          if (merch) {
            old_merchants_value += merch?.translations?.[this.curLang]?.name + ',';
          }
        });
        old_merchants_value = old_merchants_value.slice(0, -1);
      }
      dataToSend.pending_transaction.transaction_details.push({
        column_name: this.translate.instant('merchants.merchants'),
        old_value: old_merchants_value,
        new_value: new_merchants_value,
        lang: null
      });
    }
    dataToSend.pending_transaction.transaction_details = _.orderBy(dataToSend.pending_transaction.transaction_details, ['lang'], ['desc']);
    return dataToSend;
  }

  getPendingTerminalData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };
    if (transactions[0].description_transaction) {
      dataToSend.pending_transaction.description_transaction = transactions[0].description_transaction;
    }
    if (transactions[0].transaction_details) {
      for (const element of transactions[0].transaction_details) {
        if (element.column_name != "merchant_id") {
          if (element.column_name == "branch_id") {
            element.column_name == this.translate.instant('approval.branch');
            element.new_value = this.branches?.find(x => x.id == element.new_value)?.translations?.[this.currentLanguage]?.name;
          }
          if (element.column_name == "active") {
            element.old_value = element.old_value == '1' ? this.translate.instant('approval.yes') : this.translate.instant('approval.no');
            element.new_value = element.new_value == '1' ? this.translate.instant('approval.yes') : this.translate.instant('approval.no');
          }
          if (element.column_name == "currency_id") {
            element.column_name = this.translate.instant('approval.currency');
            element.old_value = this.currencies?.find(x => x.id == element.old_value)?.translations?.[this.currentLanguage]?.name;
            element.new_value = this.currencies?.find(x => x.id == element.new_value)?.translations?.[this.currentLanguage]?.name;
          }
          dataToSend.pending_transaction.transaction_details.push({
            column_name: element.column_name,
            old_value: element.old_value,
            new_value: element.new_value,
            lang: element.lang
          });
        }
      }
    }
    dataToSend.pending_transaction.transaction_details = _.orderBy(dataToSend.pending_transaction.transaction_details, ['lang'], ['desc']);
    return dataToSend;
  }

  getPendingBranchData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };

    for (let i = 0; i < transactions.length; i++) {
      if (i !== 0 && transactions[i].key == 'branches') {
        var last = dataToSend.pending_transaction.transaction_details.pop();
        last.new_value_class = '';
        last.old_value_class = '';
        last.colmn_name_class = 'border-bottom';
        dataToSend.pending_transaction.transaction_details.push(last);
      }

      if (transactions[i].description_transaction) {
        dataToSend.pending_transaction.description_transaction = transactions[i].description_transaction;
      }
      if (transactions[i].transaction_details) {
        if ((transactions[i].transaction_details).some(e => e.column_name === 'cash_in') || (transactions[i].transaction_details).some(e => e.column_name === 'cash_out')) {
          let cash_in_old_value = _.some(transactions[i].transaction_details, {column_name: 'cash_in'}) ? _.find(transactions[i].transaction_details, {column_name: 'cash_in'})?.old_value : transactions[i].feature_item.cash_in;
          let cash_in_new_value = _.some(transactions[i].transaction_details, {column_name: 'cash_in'}) ? _.find(transactions[i].transaction_details, {column_name: 'cash_in'})?.new_value : transactions[i].feature_item.cash_in;
          let cash_out_old_value = _.some(transactions[i].transaction_details, {column_name: 'cash_out'}) ? _.find(transactions[i].transaction_details, {column_name: 'cash_out'})?.old_value : transactions[i].feature_item.cash_out;
          let cash_out_new_value = _.some(transactions[i].transaction_details, {column_name: 'cash_out'}) ? _.find(transactions[i].transaction_details, {column_name: 'cash_out'})?.new_value : transactions[i].feature_item.cash_out;
          let dataToPush = {
            column_name: this.translate.instant('approval.atmServices'),
            old_value: (parseInt(cash_in_old_value) ? this.translate.instant('approval.cash_in') : '') + (parseInt(cash_in_old_value) && parseInt(cash_out_old_value) ? ', ' : '') + (parseInt(cash_out_old_value) ? this.translate.instant('approval.cash_out') : ''),
            new_value: (parseInt(cash_in_new_value) ? this.translate.instant('approval.cash_in') : '') + (parseInt(cash_in_new_value) && parseInt(cash_out_new_value) ? ', ' : '') + (parseInt(cash_out_new_value) ? this.translate.instant('approval.cash_out') : '')
          };
          transactions[i].transaction_details = [...transactions[i].transaction_details, dataToPush];
        }
      }

      if (transactions[i].key == 'branches') {
        for (const element of transactions[i].transaction_details) {
          if (element.column_name !== 'merchant_id' && element.column_name !== 'cash_in' && element.column_name !== 'cash_out') {
            if ((element.column_name === 'country_id') || (element.column_name === 'region_id') || (element.column_name === 'subregion_id')) {
              if (element?.old_relation) element.old_relation = this.translationService.getTranslationData(element.old_relation);
              if (element?.new_relation) element.new_relation = this.translationService.getTranslationData(element.new_relation);
              let old_value = element.old_value;
              let new_value = element.new_value;
              let column_name = element.column_name;
              element.column_name = element.column_name === 'country_id' ? this.translate.instant('approval.country') : element.column_name === 'region_id' ? this.translate.instant('approval.region') : this.translate.instant('approval.subregion');
              element.old_value = element?.old_relation?.translations?.[this.currentLanguage]?.name ? element.old_relation.translations[this.currentLanguage].name : '';
              element.new_value = element?.new_relation?.translations?.[this.currentLanguage]?.name ? element.new_relation.translations[this.currentLanguage].name : '';

              if (column_name === 'region_id') {
                if (!element.old_value || element.old_value == '') {
                  element.old_value = this.regions?.find(x => x.id == old_value)?.translations?.[this.currentLanguage]?.name;
                }
                if (!element.new_value || element.new_value == '') {
                  element.new_value = this.regions?.find(x => x.id == new_value)?.translations?.[this.currentLanguage]?.name;
                }
              } else if (column_name === 'country_id') {
                if (!element.old_value || element.old_value == '') {
                  element.old_value = this.regions?.find(x => x.country_id == old_value)?.country?.name;
                }
                if (!element.new_value || element.new_value == '') {
                  element.new_value = this.regions?.find(x => x.country_id == new_value)?.country?.name;
                }
              }
            }

            if (element.column_name === 'address') {
              element.column_name = this.translate.instant('approval.addressDetails');
            }
            if (element.column_name === 'description') {
              element.column_name = this.translate.instant('branches.landmark');
            }
            if (element.column_name === 'type_id') {
              element.new_value = this.branchTypesById && this.branchTypesById[element.new_value] && this.branchTypesById[element.new_value].translations && this.branchTypesById[element.new_value].translations[this.currentLanguage] ? this.branchTypesById[element.new_value].translations[this.currentLanguage].name : element.new_value;
              element.old_value = this.branchTypesById && this.branchTypesById[element.old_value] && this.branchTypesById[element.old_value].translations && this.branchTypesById[element.old_value].translations[this.currentLanguage] ? this.branchTypesById[element.old_value].translations[this.currentLanguage].name : element.old_value;
              element.column_name = this.translate.instant('approval.mainBranchType');
            }
            if (element.column_name === 'lat') {
              element.column_name = this.translate.instant('approval.latitude');
            }
            if (element.column_name === 'lng') {
              element.column_name = this.translate.instant('approval.longtitude');
            }
            if (element.column_name === 'active') {
              element.column_name = this.translate.instant('approval.status');
              element.old_value = element.old_value == 1 ? this.translate.instant('approval.active') : this.translate.instant('approval.inactive');
              element.new_value = element.new_value == 1 ? this.translate.instant('approval.active') : this.translate.instant('approval.inactive');
            }
            if (element.column_name === 'branch_type') {
              if ((element.old_value)?.includes("smartatm")) element.old_value = (element.old_value)?.replace('smartatm', this.translate.instant('approval.smartAtm'));
              if ((element.new_value)?.includes("smartatm")) element.new_value = (element.new_value)?.replace('smartatm', this.translate.instant('approval.smartAtm'));
              if ((element.old_value)?.includes("atm")) element.old_value = (element.old_value)?.replace('atm', this.translate.instant('approval.atm'));
              if ((element.new_value)?.includes("atm")) element.new_value = (element.new_value)?.replace('atm', this.translate.instant('approval.atm'));
            }

            dataToSend.pending_transaction.transaction_details.push({
              column_name: element.column_name,
              old_value: element.old_value,
              new_value: element.new_value,
              lang: element.lang
            });
          }
        }
      } else if (transactions[i].key == 'opening_hours') {
        const dataToadd = {
          column_name: this.translate.instant('approval.openingHours') + ' : ' + (transactions[i].feature_item ? transactions[i].feature_item.day : ''),
          old_value: '',
          new_value: ''
        };
        for (const element of transactions[i].transaction_details) {
          if (!transactions[i].feature_item && element.column_name === 'day') {
            dataToadd.column_name += element.new_value;
          }
          if (element.column_name === 'is_closed' && element.old_value === 1) {
            dataToadd.old_value += this.translate.instant('approval.closed');
          }
          if (element.column_name === 'is_closed' && element.new_value === 1) {
            dataToadd.new_value += this.translate.instant('approval.closed');
          }
          if (element.column_name === 'from_time') {
            dataToadd.old_value += element.old_value ? (this.translate.instant('approval.open') + ' : ' + element.old_value) : '';
            dataToadd.new_value += element.new_value ? (this.translate.instant('approval.open') + ' : ' + element.new_value) : '';
          }
          if (element.column_name === 'to_time') {
            dataToadd.old_value += element.old_value ? (' ' + this.translate.instant('approval.close') + ' : ' + element.old_value) : '';
            dataToadd.new_value += element.new_value ? (' ' + this.translate.instant('approval.close') + ' : ' + element.new_value) : '';
          }
        }
        dataToSend.pending_transaction.transaction_details.push(dataToadd);
      }
    }

    let branchUpload = this.getTransactionByName(transactions, 'branch.upload');
    if (branchUpload) {
      dataToSend.pending_transaction.transaction_details.push({
        column_name: branchUpload.transaction_details[0].column_name,
        old_value: branchUpload.transaction_details[0].old_value,
        new_value: branchUpload.transaction_details[0].new_value
      });
    }

    return dataToSend;
  }

  getDeleteInfo(data) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };

    const item = data[0].feature_item;
    if (data[0].key === 'merchant_app_config') {
      if (item?.app.name) {
        let arrayOfObject = [
          {
            key: this.translate.instant('approval.name'),
            value: item?.app.name
          }
        ];

        dataToSend.pending_transaction.description_transaction = this.transformJsonToString(arrayOfObject);
      }

      if (item?.payment_method_id) {
        let paymentMethod = this.paymentMethods.filter(function (method) {
          return method.id == item?.payment_method_id;
        });
        dataToSend.pending_transaction.transaction_details.push({
          "column_name": this.translate.instant('merchantConfiguration.paymentMethod'),
          "old_value": paymentMethod.length ? this.translate.instant('merchants.' + paymentMethod[0].key) : "",
          "new_value": paymentMethod.length ? this.translate.instant('merchants.' + paymentMethod[0].key) : ""
        })
      }

      if (item?.app?.merchant_id) {
        let merchant_id = item.app.merchant_id;
        if (merchant_id) {
          let merchantDetails = this.merchants.filter(function (merchant) {
            return merchant.id == item.app.merchant_id;
          });
          dataToSend.pending_transaction.transaction_details.push({
            "column_name": this.translate.instant('merchants.merchant'),
            "old_value": merchantDetails.length ? merchantDetails[0].name + " " : "",
            "new_value": merchantDetails.length ? merchantDetails[0].name + " " : ""
          })
        }
      }
    }
    return dataToSend;
  }

  getTransactionByName(transactionsList, transactionName) {
    return transactionsList.filter(function (transaction) {
      return transaction.key == transactionName;
    })[0];
  };

  getAllTransactionsByName(transactionsList, transactionName) {
    return transactionsList.filter(function (transaction) {
      return transaction.key == transactionName;
    });
  };

  getColumn(columnsList, columnName) {
    let column = columnsList.filter(function (column) {
      return column.column_name == columnName;
    });
    if (column)
      return column[0];
    return false;
  };

  checkIfSdkAdded(transactions, key) {
    if (transactions && transactions != null && transactions != '' && key && key != null && key != '') {
      let transactions_to_search = _.flatten(_.map(transactions, 'transaction_details'));
      var is_added = _.find(transactions_to_search, function (item) {
        return item.new_value == key;
      });
      if (is_added)
        return true;
      else
        return false;
    }
    return false;
  };

  checkIfSdkDeleted(transactions, key) {
    if (transactions && transactions != null && transactions != '' && key && key != null && key != '') {
      let transactions_to_search = _.flatten(transactions);
      var is_deleted = _.find(transactions_to_search, function (item) {
        // @ts-ignore
        return item.feature_item && item.feature_item.key == key;
      });
      if (is_deleted)
        return true;
      else
        return false;
    }
    return false;
  };

  getPaymentControlStringTranslate(key) {
    return this.translate.instant('paymentControls.' + key);
  }

  getMerchantConfigDeleteInfo(data) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };

    const item = data[0].feature_item;
    if (data[0].key === 'acquirer.fraud') {
      const columnName = this.getPaymentControlInfo(data[0]) + ' ' + this.translate.instant('approval.status');
      dataToSend.pending_transaction.transaction_details.push({
        column_name: columnName,
        old_value: this.translate.instant('approval.active'),
        new_value: this.translate.instant('approval.deleted')
      });

      let arrayOfObject = [
        {
          key: this.translate.instant('approval.title'),
          value: this.replaceSeparatorAndCapitalizePipe.transform(item['key'])
        }];

      dataToSend.pending_transaction.description_transaction = this.transformJsonToString(arrayOfObject);

    }
    return dataToSend;
  }

  getPaymentControlInfo(transactions) {
    const feature_item = transactions?.feature_item;
    const extraInfo = transactions?.extra;
    let rule = feature_item, categoryKey = feature_item?.key, default_currency = extraInfo?.default_currency || '';

    const counters_list = [
      {'id': '1.00', 'name': this.translate.instant('paymentControls.counters.firstTime')},
      {'id': '2.00', 'name': this.translate.instant('paymentControls.counters.secondTime')},
      {'id': '3.00', 'name': this.translate.instant('paymentControls.counters.thirdTime')},
      {'id': '4.00', 'name': this.translate.instant('paymentControls.counters.fourthTime')},
      {'id': '5.00', 'name': this.translate.instant('paymentControls.counters.fifthTime')}
    ];

    const counter = counters_list.find(counter => counter.id === rule.value);
    const counterName = counter?.name + ' ' + rule.rule;

    let title = rule?.rule ? this.replaceSeparatorAndCapitalizePipe.transform(rule?.rule) + ' ' : '';
    switch (categoryKey) {
      case 'card':
        title = counterName;
        break;
      case 'transaction_amount':
        if (rule.rule == 'GREATER_THAN' || rule.rule == 'BETWEEN') {
          title += rule.min_amount + ' ' + default_currency
        }
        if (rule.min_amount && rule.max_amount && rule.rule == 'BETWEEN') {
          title += ' ' + this.translate.instant('paymentControls.and') + ' ';
        }
        if (rule.rule == 'LESS_THAN' || rule.rule == 'BETWEEN') {
          title += rule.max_amount + ' ' + default_currency;
        }
        break;
      case 'transaction_volume':
        title += rule.value + ' ' + default_currency;
        break;
      case 'transaction_frequency':
        title += rule.value;
        break;
      case 'locations':
        title += rule.csv ? rule.csv === 'KW' ? this.getPaymentControlStringTranslate(rule.csv) : rule.csv : this.translate.instant('paymentControls.all');
        break;
      case 'bin_ranges':
        title += rule.csv;
        break;
      default:
        title = rule.rule;
        break;
    }
    return title;
  }

  getMerchantPaymentControlsData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };
    let merchant_id = transactions[0]?.extra?.merchant_id;
    let arrayOfObject = [];
    if (merchant_id) {
      let merchantDetails = this.merchants.filter(function (item) {
        return item.id == merchant_id;
      });
      arrayOfObject = [
        {
          key: this.translate.instant('merchants.merchant'),
          value: merchantDetails.length ? merchantDetails[0].name + " " : ""
        }];
    }

    if (transactions[0].feature_item) {
      let categories = transactions[0]?.extra?.categories;
      let title = categories && categories.find(c => c.key == transactions[0].feature_item['key'])?.name || transactions[0].feature_item['key'];
      title = title === 'bin_ranges' ? this.getPaymentControlStringTranslate(title) : title;

      arrayOfObject.push({
        key: this.getPaymentControlStringTranslate('paymentControl'),
        value: this.replaceSeparatorAndCapitalizePipe.transform(title) + ' - ' + this.getPaymentControlInfo(transactions[0])
      })
    }


    let i = 0;
    for (const t of transactions) {
      for (const element of t.transaction_details) {
        if (t['action'] == 'edit') {
          if (element.column_name === 'order') {
            let name = this.getPaymentControlInfo(t);
            const columnName = this.getPaymentControlStringTranslate('order') + ': ' + name;
            dataToSend.pending_transaction.transaction_details.push({
              column_name: columnName,
              old_value: element.old_value,
              new_value: element.new_value
            });
          } else {
            if (element.column_name === 'action') {
              dataToSend.pending_transaction.transaction_details.push({
                column_name: element.column_name,
                old_value: element.old_value ? this.translate.instant('paymentControls.actions.' + element.old_value?.replace('_', '').toLowerCase()) : "-",
                new_value: element.new_value ? this.translate.instant('paymentControls.actions.' + element.new_value?.replace('_', '').toLowerCase()) : "-"
              });
            } else {
              dataToSend.pending_transaction.transaction_details.push({
                column_name: element.column_name,
                old_value: this.replaceSeparatorAndCapitalizePipe.transform(element.old_value),
                new_value: this.replaceSeparatorAndCapitalizePipe.transform(element.new_value)
              });
            }
          }
        } else if (t['action'] == 'add') {
          if (element.column_name === 'action') {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: element.column_name,
              old_value: element.old_value ? this.translate.instant('paymentControls.actions.' + element.old_value?.replace('_', '').toLowerCase()) : "-",
              new_value: element.new_value ? this.translate.instant('paymentControls.actions.' + element.new_value?.replace('_', '').toLowerCase()) : "-"
            });
          } else {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: element.column_name,
              old_value: this.replaceSeparatorAndCapitalizePipe.transform(element.old_value),
              new_value: this.replaceSeparatorAndCapitalizePipe.transform(element.new_value)
            });
          }
        }
      }
      i++;
    }
    if (arrayOfObject?.length) {
      dataToSend.pending_transaction.description_transaction = this.transformJsonToString(arrayOfObject);
    }
    return dataToSend;
  }

  getMerchantData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };
    if (transactions[0].description_transaction) {
      dataToSend.pending_transaction.description_transaction = transactions[0].description_transaction;
    }

    let merchantExist = false;
    //orders table same as the prder of fields in the add popup
    let orders = ["reference_merchant_id", "name", "mcc_id", "cr_number", "email", "Card Types", "Currency", "default_currency", "m4m_enabled", "has_marketplace", "Credit Card", "enable_advanced_report",];
    if ((this.userType === "acquirer" || this.userType == "aggregator") && (this.getTransactionByName(transactions, 'payment_config') || this.getTransactionByName(transactions, 'merchant_app'))) {
      let paymentconfig = this.getTransactionByName(transactions, 'payment_config');
      if (paymentconfig) {
        merchantExist = true;
        let oldGateways = [];
        let newGateways = [];
        for (let i = 0; i < transactions.length; i++) {
          if (transactions[i].key === "payment_config") {
            let gatewayColumn = this.getColumn(transactions[i].transaction_details, 'gateway_id');
            if (gatewayColumn) {
              let oldValue = this.paymentGateways.find(function (item) {
                return item.id == gatewayColumn.old_value;
              });
              let newValue = this.paymentGateways.find(function (item) {
                return item.id == gatewayColumn.new_value;
              });
              if (oldValue?.name) oldGateways.push(oldValue?.name);
              if (newValue?.name) newGateways.push(newValue?.name);
            } else {
              let gatewayFeatureColumn = transactions[i].feature_item?.gateway_id;
              if (gatewayFeatureColumn) {
                if (transactions[i].action === 'delete') {
                  let oldValue = this.paymentGateways.find(function (item) {
                    return item.id == gatewayFeatureColumn;
                  });
                  if (oldValue?.name) oldGateways.push(oldValue?.name);
                } else {
                  let oldValue = this.paymentGateways.find(function (item) {
                    return item.id == gatewayFeatureColumn;
                  });
                  let newValue = this.paymentGateways.find(function (item) {
                    return item.id == gatewayFeatureColumn;
                  });
                  if (oldValue?.name) oldGateways.push(oldValue?.name);
                  if (newValue?.name) newGateways.push(newValue?.name);
                }
              }
            }
          }
        }

        dataToSend.pending_transaction.transaction_details.push({
          "column_name": this.translate.instant('merchants.configurations.paymentGateway'),
          "old_value": oldGateways.join(" - "),
          "new_value": newGateways.join(" - ")
        })

        //merchant column
        let merchantColumn = this.getColumn(transactions[0].transaction_details, 'merchant_id');
        if (merchantColumn) {
          let oldValue = this.merchants.filter(function (item) {
            return item.id == merchantColumn.old_value;
          });
          let newValue = this.merchants.filter(function (item) {
            return item.id == merchantColumn.new_value;
          });
          dataToSend.pending_transaction.transaction_details.push({
            "column_name": this.translate.instant('merchants.merchant'),
            "old_value": oldValue.length ? oldValue[0].name : "",
            "new_value": newValue.length ? newValue[0].name + " " : ""
          })
        } else {
          let newValue = this.merchants.filter(function (item) {
            return item.id == transactions[0].feature_item.merchant_id;
          });
          dataToSend.pending_transaction.transaction_details.push({
            "column_name": this.translate.instant('merchants.merchant'),
            "old_value": newValue.length ? newValue[0].name : "",
            "new_value": newValue.length ? newValue[0].name + " " : ""
          })
        }

        //payment method
        let merchantAppConfigTransactions = transactions.find(item => item.key === "merchant_app_config");
        let paymentConfigTransactions = transactions.find(item => item.key === "payment_config");
        let paymentMethodColumn = merchantAppConfigTransactions ? this.getColumn(merchantAppConfigTransactions.transaction_details, 'payment_method_id') : (paymentConfigTransactions ? this.getColumn(paymentConfigTransactions.transaction_details, 'payment_method_id') : '');

        if (paymentMethodColumn) {
          let oldValue = this.paymentMethods.filter(function (item) {
            return item.id == paymentMethodColumn.old_value;
          });
          let newValue = this.paymentMethods.filter(function (item) {
            return item.id == paymentMethodColumn.new_value;
          });
          dataToSend.pending_transaction.transaction_details.push({
            "column_name": this.translate.instant('merchantConfiguration.paymentMethod'),
            "old_value": oldValue.length ? this.translate.instant('merchants.' + oldValue[0].key) : "",
            "new_value": newValue.length ? this.translate.instant('merchants.' + newValue[0].key) + " " : ""
          })
        } else if (paymentConfigTransactions) {
          let newValue = this.paymentMethods.filter(function (item) {
            return item.id == paymentConfigTransactions?.feature_item?.payment_method_id;
          });
          dataToSend.pending_transaction.transaction_details.push({
            "column_name": this.translate.instant('merchantConfiguration.paymentMethod'),
            "old_value": newValue.length ? this.translate.instant('merchants.' + newValue[0].key) : "",
            "new_value": newValue.length ? this.translate.instant('merchants.' + newValue[0].key) + " " : ""
          })
        }
      }

      //merchant app column
      let merchantAppConfig = this.getTransactionByName(transactions, 'merchant_app');
      if (merchantAppConfig) {
        let appconfigid = this.getColumn(merchantAppConfig.transaction_details, 'app_id');
        let appconfigsecret = this.getColumn(merchantAppConfig.transaction_details, 'secret_token');
        let appconfigname = this.getColumn(merchantAppConfig.transaction_details, 'name');

        dataToSend.pending_transaction.transaction_details.push({
          "column_name": this.translate.instant('merchants.configurations.name'),
          "old_value": appconfigname.old_value,
          "new_value": appconfigname.new_value,
          "lang": appconfigname.lang
        });

        dataToSend.pending_transaction.transaction_details.push({
          "column_name": this.translate.instant('merchants.configurations.appId'),
          "old_value": appconfigid.old_value,
          "new_value": appconfigid.new_value
        });

        dataToSend.pending_transaction.transaction_details.push({
          "column_name": this.translate.instant('merchants.configurations.SecretToken'),
          "old_value": appconfigsecret.old_value,
          "new_value": appconfigsecret.new_value
        });

        if (!merchantExist) {
          let merchantColumn = this.getColumn(merchantAppConfig.transaction_details, 'merchant_id');
          if (merchantColumn) {
            let oldValue = this.merchants.filter(function (item) {
              return item.id == merchantColumn.old_value;
            });
            let newValue = this.merchants.filter(function (item) {
              return item.id == merchantColumn.new_value;
            });
            dataToSend.pending_transaction.transaction_details.push({
              "column_name": this.translate.instant('merchants.merchant'),
              "old_value": oldValue.length ? oldValue[0].translations[this.curLang].name : "",
              "new_value": newValue.length ? newValue[0].translations[this.curLang].name + " " : ""
            })
          } else {
            let newValue = this.merchants.filter(function (item) {
              return item.id == merchantAppConfig.feature_item.merchant_id;
            });
            dataToSend.pending_transaction.transaction_details.push({
              "column_name": this.translate.instant('merchants.merchant'),
              "old_value": newValue.length ? newValue[0].translations[this.curLang].name : "",
              "new_value": newValue.length ? newValue[0].translations[this.curLang].name + " " : ""
            })
          }
        }
      }

      let masterpassConfig = this.getTransactionByName(transactions, 'masterpass_config');
      if (masterpassConfig) {
        let merchant_checkout_id = this.getColumn(masterpassConfig.transaction_details, 'merchant_checkout_id');
        let consumer_key = this.getColumn(masterpassConfig.transaction_details, 'consumer_key');
        let private_key = this.getColumn(masterpassConfig.transaction_details, 'private_key');
        let preferred_wallet = this.getColumn(masterpassConfig.transaction_details, 'preferred_wallet');

        dataToSend.pending_transaction.transaction_details.push({
          "column_name": this.translate.instant('merchants.configurations.MerchantCheckoutID'),
          "old_value": merchant_checkout_id.old_value,
          "new_value": merchant_checkout_id.new_value
        });

        dataToSend.pending_transaction.transaction_details.push({
          "column_name": this.translate.instant('merchants.configurations.ConsumerKey'),
          "old_value": consumer_key.old_value,
          "new_value": consumer_key.new_value
        });

        dataToSend.pending_transaction.transaction_details.push({
          "column_name": this.translate.instant('merchants.configurations.PrivateKey'),
          "old_value": private_key.old_value,
          "new_value": private_key.new_value
        });

        dataToSend.pending_transaction.transaction_details.push({
          "column_name": this.translate.instant('merchants.configurations.PreferredWallet'),
          "old_value": preferred_wallet.old_value,
          "new_value": preferred_wallet.new_value
        });

      }

      let merchantConfig = this.getTransactionByName(transactions, 'merchant');
      if (merchantConfig) {
        let has_sva = this.getColumn(merchantConfig.transaction_details, 'has_sva');

        if (has_sva) {
          dataToSend.pending_transaction.transaction_details.push({
            "column_name": this.translate.instant('merchants.configurations.FOOSVA'),
            "old_value": has_sva.old_value == "1" ? this.translate.instant('approval.yes') : this.translate.instant('approval.no'),
            "new_value": has_sva.new_value == "1" ? this.translate.instant('approval.yes') : this.translate.instant('approval.no')
          });
        }
      }

      //active
      let activeColumn = this.getColumn(transactions[0].transaction_details, 'active');
      if (activeColumn && transactions[0].action !== 'add') {
        dataToSend.pending_transaction.transaction_details.push({
          "column_name": this.translate.instant('approval.active'),
          "old_value": (activeColumn.old_value == "0" || activeColumn.old_value == 0) ? this.translate.instant('approval.no') : this.translate.instant('approval.yes'),
          "new_value": (activeColumn.new_value == "0" || activeColumn.new_value == 0) ? this.translate.instant('approval.no') : this.translate.instant('approval.yes')
        })
      }
    } else {
      let merchants = this.getAllTransactionsByName(transactions, 'merchant');
      if (merchants.length) {
        for (let i = 0; i < merchants.length; i++) {
          let merchant = merchants[i];
          if (i !== 0) {
            var last = dataToSend.pending_transaction.transaction_details.pop();
            last.new_value_class = '';
            last.old_value_class = '';
            last.colmn_name_class = 'border-bottom';
            dataToSend.pending_transaction.transaction_details.push(last);
          }

          for (let j = 0; j < merchant.transaction_details.length; j++) {
            if (merchant.transaction_details[j].column_name != "acquirer_id" && merchant.transaction_details[j].column_name != "image"
              && merchant.transaction_details[j].column_name != "max_branches" && merchant.transaction_details[j].column_name != "max_terminals"
              && merchant.transaction_details[j].column_name != "sender_name" && merchant.transaction_details[j].column_name != "merchant_sender_email"
              && merchant.transaction_details[j].column_name != "marketplace_sender_email" && merchant.transaction_details[j].column_name != "merchant_gateway_id") {
              if (merchant.transaction_details[j].column_name == "active") {
                if (merchant.transaction_details[j].old_value == "-1" || merchant.transaction_details[j].old_value == "0") {
                  merchant.transaction_details[j].old_value = this.translate.instant('approval.no');
                } else {
                  if (merchant.transaction_details[j].old_value == "1") {
                    merchant.transaction_details[j].old_value = this.translate.instant('approval.yes');
                  }
                }
  
                if (merchant.transaction_details[j].new_value == "-1" || merchant.transaction_details[j].new_value == "0") {
                  merchant.transaction_details[j].new_value = this.translate.instant('approval.no');
                } else {
                  if (merchant.transaction_details[j].new_value == "1") {
                    merchant.transaction_details[j].new_value = this.translate.instant('approval.yes');
                  }
                }
              }
  
              if (merchant.transaction_details[j].column_name == "deleted_at") {
                merchant.transaction_details[j].column_name = this.translate.instant('approval.active');
                merchant.transaction_details[j].old_value = this.translate.instant('approval.no');
                merchant.transaction_details[j].new_value = this.translate.instant('approval.yes');
  
                dataToSend.pending_transaction.description_transaction = this.getDescriptionTransactionActiveInactiveCmsUsersActions(transactions);
              }
  
              if (merchant.transaction_details[j].column_name == "mcc_id") {
                merchant.transaction_details[j].old_value = (merchant.transaction_details[j].old_relation) ? merchant.transaction_details[j].old_relation.code : merchant.transaction_details[j].old_value;
                merchant.transaction_details[j].new_value = merchant.transaction_details[j].new_relation?.code;
              }
  
              if (merchant.transaction_details[j].column_name == "default_currency") {
                let oldCurr = this.currencies.filter(function (item) {
                  return parseInt(item.id) == parseInt(merchant.transaction_details[j].old_value);
                });
                let newCurr = this.currencies.filter(function (item) {
                  return parseInt(item.id) == parseInt(merchant.transaction_details[j].new_value);
                });
                merchant.transaction_details[j].old_value = oldCurr[0]?.translations[this.curLang]?.name;
                merchant.transaction_details[j].new_value = newCurr[0]?.translations[this.curLang]?.name;
              }
  
              if (merchant.transaction_details[j].column_name == "m4m_enabled" || merchant.transaction_details[j].column_name == "has_marketplace") {
                merchant.transaction_details[j].old_value = (!merchant.transaction_details[j].old_value || merchant.transaction_details[j].old_value == "0" || merchant.transaction_details[j].old_value == 0) ? this.translate.instant('approval.no') : this.translate.instant('approval.yes');
                merchant.transaction_details[j].new_value = (!merchant.transaction_details[j].new_value || merchant.transaction_details[j].new_value == "0" || merchant.transaction_details[j].new_value == 0) ? this.translate.instant('approval.no') : this.translate.instant('approval.yes');
              }
  
              if ((merchant.transaction_details[j].column_name != "active") || merchant.transaction_details[j].column_name == "active" && merchant.action != "add") {
                dataToSend.pending_transaction.transaction_details.push({
                  column_name: merchant.transaction_details[j].column_name,
                  old_value: merchant.transaction_details[j].old_value,
                  new_value: merchant.transaction_details[j].new_value,
                  lang: merchant.transaction_details[j].lang
                });
              }
  
            }
          }
        }
        let merchantpaymentinst = transactions.filter(function (transaction) {
          return transaction.key == 'merchant.payment_instrument';
        });
        if (merchantpaymentinst) {
          for (let i = 0; i < merchantpaymentinst.length; i++) {
            if (merchantpaymentinst[i].action == "add") {
              let payminst = this.paymentMethods.filter(function (item) {
                return item.id == parseInt(merchantpaymentinst[i].transaction_details[0].new_value);
              });
              if (payminst[0]?.key == "cd") {
                let order = (orders.indexOf("Credit Card") != -1 ? orders.indexOf("Credit Card") : orders.length);
                dataToSend.pending_transaction.transaction_details.push({
                  column_name: this.translate.instant('merchants.configurations.CreditCard'),
                  old_value: this.translate.instant('approval.no'),
                  new_value: this.translate.instant('approval.yes'),
                  order: order
                });
              }
            } else {
              if (merchantpaymentinst[i].action == "delete") {
                dataToSend.pending_transaction.transaction_details.push({
                  column_name: this.translate.instant('merchants.configurations.CreditCard'),
                  old_value: this.translate.instant('approval.yes'),
                  new_value: this.translate.instant('approval.no')
                });
              }
            }
          }
        }


        if (this.userType === "acquirer" || this.userType === "aggregator") {
          if (this.getTransactionByName(transactions, 'merchant_config')) {
            let merchant_config = this.getTransactionByName(transactions, 'merchant_config');
            for (let i = 0; i < merchant_config.transaction_details.length; i++) {
              dataToSend.pending_transaction.transaction_details.push({
                "column_name": merchant_config.transaction_details[i].column_name,
                "old_value": (!merchant_config.transaction_details[i].old_value || merchant_config.transaction_details[i].old_value == "0" || merchant_config.transaction_details[i].old_value == 0) ? this.translate.instant('approval.no') : this.translate.instant('approval.yes'),
                "new_value": (!merchant_config.transaction_details[i].new_value || merchant_config.transaction_details[i].new_value == "0" || merchant_config.transaction_details[i].new_value == 0) ? this.translate.instant('approval.no') : this.translate.instant('approval.yes')
              });
            }
          }

          let sdk_menu_config = transactions.filter(function (transaction) {
            return transaction.key == 'merchant_sdk_menus';
          });
          if (sdk_menu_config && sdk_menu_config != null && sdk_menu_config.length) {
            let added_sdk_menu_config = sdk_menu_config.filter(function (transaction) {
              return transaction.action == 'add';
            });
            let deleted_sdk_menu_config = sdk_menu_config.filter(function (transaction) {
              return transaction.action == 'delete';
            });

            if (added_sdk_menu_config && added_sdk_menu_config != null && added_sdk_menu_config != '') {
              for (let x = 0; x < added_sdk_menu_config.length; x++) {
                for (let i = 0; i < added_sdk_menu_config[x].transaction_details.length; i++) {
                  if (added_sdk_menu_config[x].transaction_details[i].column_name == 'key'
                    && ((!deleted_sdk_menu_config || deleted_sdk_menu_config == null || deleted_sdk_menu_config == '') || (deleted_sdk_menu_config && !this.checkIfSdkDeleted(deleted_sdk_menu_config, added_sdk_menu_config[x].transaction_details[i].new_value)))) {
                    dataToSend.pending_transaction.transaction_details.push({
                      "column_name": this.translate.instant('merchants.configurations.SDKMenu'),
                      "old_value": this.replaceSeparatorAndCapitalizePipe.transform(added_sdk_menu_config[x].transaction_details[i].old_value),
                      "new_value": this.replaceSeparatorAndCapitalizePipe.transform(added_sdk_menu_config[x].transaction_details[i].new_value)
                    });
                  }

                }
              }
            }

            if (deleted_sdk_menu_config && deleted_sdk_menu_config != null && deleted_sdk_menu_config != '') {
              for (let x = 0; x < deleted_sdk_menu_config.length; x++) {
                for (let i = 0; i < deleted_sdk_menu_config[x].transaction_details.length; i++) {
                  if (deleted_sdk_menu_config[x].feature_item && deleted_sdk_menu_config[x].feature_item != null && deleted_sdk_menu_config[x].feature_item != ''
                    && ((!added_sdk_menu_config || added_sdk_menu_config == null || added_sdk_menu_config == '') || (added_sdk_menu_config && !this.checkIfSdkAdded(added_sdk_menu_config, deleted_sdk_menu_config[x].feature_item.key)))) {
                    dataToSend.pending_transaction.transaction_details.push({
                      "column_name": this.translate.instant('merchants.configurations.SDKMenu'),
                      "old_value": this.replaceSeparatorAndCapitalizePipe.transform(deleted_sdk_menu_config[x].feature_item.key),
                      "new_value": 'N/A'
                    });
                  }

                }
              }
            }
          }

          let merchant_card_type = transactions.filter(function (transaction) {
            return transaction.key == 'merchant_card_type';
          });
          let strnewdisplay = "";
          let strolddisplay = "N/A";
          if (merchant_card_type && merchant_card_type.length > 0) {
            for (let i = 0; i < merchant_card_type.length; i++) {
              if (merchant_card_type[i].action == "add") {
                let cardtype = this.cardTypes.filter(function (item) {
                  return item.id == parseInt(merchant_card_type[i].transaction_details[0].new_value);
                })[0];
                strnewdisplay += cardtype.name + ",";
              }
              if (merchant_card_type[i].action == "delete" && merchant_card_type[i].feature_item) {
                let cardtype = this.cardTypes.filter(function (item) {
                  return item.id == parseInt(merchant_card_type[i].feature_item.card_type_id);
                })[0];
                strolddisplay += cardtype.name + ",";
                strolddisplay = strolddisplay.replace("N/A", "");
              }
            }
            //add order to field
            dataToSend.pending_transaction.transaction_details.push({
              column_name: this.translate.instant('merchants.configurations.CardTypes'),
              old_value: (strolddisplay != "N/A") ? strolddisplay.substring(0, strolddisplay.length - 1) : strolddisplay,
              new_value: strnewdisplay.substring(0, strnewdisplay.length - 1),
              order: orders.indexOf('Card Types')
            });
          }
        }
      } else {
        //jis
        let merchpaymethods = this.getTransactionByName(transactions, 'merchant_payment_methods');
        if (merchpaymethods) {
          let arrayOfObject = [];
          if (merchpaymethods.feature_item?.merchant_id) {
            let value = this.merchants.filter(function (item) {
              return item.id == merchpaymethods.feature_item?.merchant_id;
            });

            arrayOfObject.push({
              key: this.translate.instant('merchants.merchant'),
              value: value.length ? value[0].name + " " : ""
            })
          }
          if (merchpaymethods.feature_item?.payment_method_id) {
            let value = this.paymentMethods.filter(function (item) {
              return item.id == merchpaymethods.feature_item?.payment_method_id;
            });

            arrayOfObject.push({
              key: this.translate.instant('merchantConfiguration.paymentMethod'),
              value: value.length ? this.translate.instant('merchants.' + value[0].key) + " " : ""
            })
          }
          
          dataToSend.pending_transaction.description_transaction = this.transformJsonToString(arrayOfObject);

          for (let i = 0; i < merchpaymethods.transaction_details.length; i++) {
            if (merchpaymethods.transaction_details[i].column_name == "merchant_id") {
              let oldValue = this.merchants.filter(function (item) {
                return item.id == merchpaymethods.transaction_details[i].old_value;
              });
              let newValue = this.merchants.filter(function (item) {
                return item.id == merchpaymethods.transaction_details[i].new_value;
              });
              dataToSend.pending_transaction.transaction_details.push({
                "column_name": this.translate.instant('merchants.merchant'),
                "old_value": oldValue.length ? oldValue[0].name : "",
                "new_value": newValue.length ? newValue[0].name + " " : ""
              })
            }
            if (merchpaymethods.transaction_details[i].column_name == "payment_method_id") {
              let oldValue = this.paymentMethods.filter(function (item) {
                return item.id == merchpaymethods.transaction_details[i].old_value;
              });
              let newValue = this.paymentMethods.filter(function (item) {
                return item.id == merchpaymethods.transaction_details[i].new_value;
              });
              dataToSend.pending_transaction.transaction_details.push({
                "column_name": this.translate.instant('merchantConfiguration.paymentMethod'),
                "old_value": oldValue.length ? this.translate.instant('merchants.' + oldValue[0].key) : "",
                "new_value": newValue.length ? this.translate.instant('merchants.' + newValue[0].key) + " " : ""
              })
            }
            if (merchpaymethods.transaction_details[i].column_name == "active") {
              if (merchpaymethods.transaction_details[i].old_value == "-1" || merchpaymethods.transaction_details[i].old_value == "0") {
                merchpaymethods.transaction_details[i].old_value = this.translate.instant('approval.no');
              } else {
                if (merchpaymethods.transaction_details[i].old_value == "1") {
                  merchpaymethods.transaction_details[i].old_value = this.translate.instant('approval.yes');
                }
              }

              if (merchpaymethods.transaction_details[i].new_value == "-1" || merchpaymethods.transaction_details[i].new_value == "0") {
                merchpaymethods.transaction_details[i].new_value = this.translate.instant('approval.no');
              } else {
                if (merchpaymethods.transaction_details[i].new_value == "1") {
                  merchpaymethods.transaction_details[i].new_value = this.translate.instant('approval.yes');
                }
              }
              dataToSend.pending_transaction.transaction_details.push({
                column_name: merchpaymethods.transaction_details[i].column_name,
                old_value: merchpaymethods.transaction_details[i].old_value,
                new_value: merchpaymethods.transaction_details[i].new_value
              });
            }
          }
        }
      }
    }
    let currenciessaved = transactions.filter(function (item) {
      return item.key == "merchant"
    });
    currenciessaved = (currenciessaved[0] && currenciessaved[0].feature_item && currenciessaved[0].feature_item.currencies) ? (currenciessaved[0].feature_item).currencies : [];
    let currenciesdeleted = transactions.filter(function (item) {
      return item.key == "merchant.currencies" && item.action == "delete"
    });
    let currenciesadded = transactions.filter(function (item) {
      return item.key == "merchant.currencies" && item.action == "add"
    });
    let notChangedCurrencies = currenciesdeleted.filter(function (item) {
      return currenciesadded.filter(function (item2) {
        if (typeof item2.transaction_details.currency_id != "undefined")
          item2.transaction_details = _.groupBy(item2.transaction_details, "column_name");
        return item2.transaction_details[0].new_value == item.feature_item.currency_id;
      }).length > 0;
    });

    if (notChangedCurrencies.length > 0) {
      currenciesadded = currenciesadded.filter(function (item) {
        return notChangedCurrencies.filter(function (item2) {
          return item2.feature_item.currency_id != item.transaction_details[0].new_value
        }).length > 0
      });

      currenciesdeleted = currenciesdeleted.filter(function (item) {
        return notChangedCurrencies.filter(function (item2) {
          return item2.feature_item.currency_id != item.feature_item.currency_id;
        }).length > 0
      });
    }
    let allAddedCurrencies = [];
    for (let i = 0; i < currenciesadded.length; i++) {
      let curr = this.currencies.filter(function (item) {
        if (typeof currenciesadded[i].transaction_details.currency_id == "undefined")
          currenciesadded[i].transaction_details = _.groupBy(currenciesadded[i].transaction_details, "column_name");

        return parseInt(item.id) == currenciesadded[i].transaction_details.currency_id[0].new_value;
      });

      if (curr[0]) allAddedCurrencies.push(curr[0].translations[this.curLang]?.name);
    }

    let allOldCurrencies = [];
    for (let i = 0; i < currenciesdeleted.length; i++) {
      let curr = this.currencies.filter(function (item) {
        return parseInt(item.id) == parseInt(currenciesdeleted[i].feature_item.currency_id);
      });
      if (curr[0]) allOldCurrencies.push(curr[0].translations[this.curLang]?.name);
    }

    if (allAddedCurrencies.length || allOldCurrencies.length) {
      let allBeforeCurrencies = [];
      let allAfterCurrencies = [];
      for (let i = 0; i < currenciessaved.length; i++) {
        allBeforeCurrencies.push(currenciessaved[i].key);

        if (allOldCurrencies.indexOf(currenciessaved[i].key) == -1) {
          allAfterCurrencies.push(currenciessaved[i].key);
        }
      }
      allAfterCurrencies = allAfterCurrencies.concat(allAddedCurrencies);

      if (allBeforeCurrencies.length != 0 && allAfterCurrencies.length != 0) {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: this.translate.instant('merchants.configurations.Currency'),
          old_value: allBeforeCurrencies.join(", "),
          new_value: allAfterCurrencies.join(", "),
          order: orders.indexOf('Currency')
        });
      }

      if (allAfterCurrencies.length != 0 && allBeforeCurrencies.length == 0) {
        //add order to field ,add currencies comma separated
        dataToSend.pending_transaction.transaction_details.push({
          column_name: this.translate.instant('merchants.configurations.Currency'),
          old_value: 'N/A',
          new_value: allAddedCurrencies.join(", "),
          order: orders.indexOf('Currency')
        });
      }

      if (allBeforeCurrencies.length != 0 && allAfterCurrencies.length == 0) {
        //add order to field ,add currencies comma separated

        dataToSend.pending_transaction.transaction_details.push({
          column_name: this.translate.instant('merchants.configurations.Currency'),
          old_value: allOldCurrencies.join(", "),
          new_value: 'N/A'
        });
      }
    }

    let fraudConfig = this.getTransactionByName(transactions, "fraud.config");
    if (fraudConfig) {
      let active = this.getColumn(fraudConfig.transaction_details, 'active');

      dataToSend.pending_transaction.transaction_details.push({
        "column_name": this.translate.instant('merchants.advancedPaymentControls'),
        "old_value": active.old_value == "1" ? this.translate.instant('approval.yes') : this.translate.instant('approval.no'),
        "new_value": active.new_value == "1" ? this.translate.instant('approval.yes') : this.translate.instant('approval.no')
      });
    }

    let merchantUpload = this.getTransactionByName(transactions, 'merchant.upload');
    if (merchantUpload) {
      dataToSend.pending_transaction.transaction_details.push({
        column_name: merchantUpload.transaction_details[0].column_name,
        old_value: merchantUpload.transaction_details[0].old_value,
        new_value: merchantUpload.transaction_details[0].new_value
      });
    }
        
    //reorder values base on order letiable
    dataToSend.pending_transaction.transaction_details = _.sortBy(dataToSend.pending_transaction.transaction_details, "order");
    return dataToSend;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
