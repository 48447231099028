<div class="login_content">
  <div class="login_body_container">
    <div class="login_body" formValidationContainer>
      <div class="col-12 col-md-12 col-sm-12 col-lg-12" *ngIf="showLang">
        <div class="foo-btn-outline-secondary langSwitch" *ngIf="showLang" (click)='useLanguage();'>
          <span>{{ "menu.lang" | translate }} </span>
        </div>
      </div>
      <div class="col-12 col-md-12 col-sm-12 col-lg-12 mb-24 centered">
        <img class="login-logo" alt="login-logo" src="{{logo}}"/>
        <p class="big-title">{{ pagetitle | translate}}</p>
        <p class="title-desc">{{ "auth.onboarding.startBySigningUp" | translate}}</p>
      </div>
      <form autocomplete="off" [formGroup]="signupForm" (ngSubmit)="onSubmit()" class="col-12 col-md-12 col-sm-12 col-lg-12 p-0" formValidation>
        <div class="form-group">
          <label class="form-label login-label">{{ "auth.emailAddress" | translate}}</label>
          <input type="text" formControlName="username" class="form-control mb-5px" placeholder='{{ "auth.enterEmailAddress" | translate}}' autocomplete="new-username"/>
          <small *ngIf="(signupForm.get('username').touched || signupForm.get('username').dirty) && signupForm.get('username').hasError('required')"
                 class="text-danger">
            {{"validations.required_field" | translate}}
          </small>
        </div>
        <div class="form-group mb-10">
          <label class="form-label">{{"auth.password" | translate}}</label>
          <input class="form-control" formControlName="new_pin" type="password" (input)="passModify();"
                 placeholder='{{ "general.enterpassword" | translate }}' autocomplete="new-password">
          <small *ngIf="(signupForm.get('new_pin').touched || signupForm.get('new_pin').dirty) && signupForm.get('new_pin').hasError('required')"
                 class="text-danger">
            {{"general.requiredPassword" | translate}}
          </small>
        </div>

        <div class="row mb-10" *ngIf="((signupForm.get('new_pin').touched || signupForm.get('new_pin').dirty) && !signupForm.get('new_pin').hasError('required')) || !(signupForm.get('new_pin').touched || signupForm.get('new_pin').dirty)">
          <div class="col-12 pass-check">
            <p class="check-condition" [class.verified]="hasLength" *ngIf='passConfig && passConfig["password_length"] && passConfig["password_length"].value > 0'>
              <span class="foo-icon foo-success-pass-check succes-img mr-1"></span>
              <span class="foo-icon foo-failed-pass-check failed-img mr-1"></span>
              {{ "general.passcheck1" | translate }} {{ charNumber }} {{ "general.passcheck6" | translate }}</p>
            <p class="check-condition" [class.verified]="hasUppercase" *ngIf='passConfig && passConfig["password_uppercase"] && passConfig["password_uppercase"].value == 1'>
              <span class="foo-icon foo-success-pass-check succes-img mr-1"></span>
              <span class="foo-icon foo-failed-pass-check failed-img mr-1"></span>
              {{ "general.passcheck2" | translate }}</p>
            <p class="check-condition" [class.verified]="hasLowercase" *ngIf='passConfig && passConfig["password_lowercase"] && passConfig["password_lowercase"].value == 1'>
              <span class="foo-icon foo-success-pass-check succes-img mr-1"></span>
              <span class="foo-icon foo-failed-pass-check failed-img mr-1"></span>
              {{ "general.passcheck3" | translate }}</p>
            <p class="check-condition" [class.verified]="hasNumbers" *ngIf='passConfig && passConfig["password_numbers"] && passConfig["password_numbers"].value == 1'>
              <span class="foo-icon foo-success-pass-check succes-img mr-1"></span>
              <span class="foo-icon foo-failed-pass-check failed-img mr-1"></span>
              {{ "general.passcheck4" | translate }}</p>
            <p class="check-condition" [class.verified]="hasSymbols" *ngIf='passConfig && passConfig["password_symbols"] && passConfig["password_symbols"].value == 1'>
              <span class="foo-icon foo-success-pass-check succes-img mr-1"></span>
              <span class="foo-icon foo-failed-pass-check failed-img mr-1"></span>
              {{ "general.passcheck5" | translate }}</p>
          </div>
        </div>
        <div class="form-group mb-32">
          <label class="form-label">{{"auth.confirmPassword" | translate}}</label>
          <input formControlName="repeat_new_pin" class="form-control" type="password"
                 placeholder='{{ "auth.confirmPassword" | translate }}'>
          <small *ngIf="(signupForm.get('repeat_new_pin').touched || signupForm.get('repeat_new_pin').dirty) && signupForm.get('repeat_new_pin').hasError('required')"
                 class="text-danger">
            {{"general.requiredPassword" | translate}}
          </small>
        </div>

        <div class="form-group mb-28">
          <button type="submit" class="foo-btn-primary btn-block">
            {{ btntitle | translate}}
          </button>
        </div>
        <div class="form-group no-mb">
          <div class="already-registered">
            <span class="foo-text-secondary">{{ "auth.onboarding.alreadyRegistered" | translate}} </span>
            <span (click)="goToLogin()" class="bold-hover foo-text-primary loginButton">{{ "auth.onboarding.login" | translate}}</span>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="login-footer" *ngIf="showCopyright || showDesignedDevelopedByFoo">
    <div class="reserved" *ngIf="showCopyright">&copy; Copyright {{currentYear}} {{projectName}}</div>
    <div class="designed" *ngIf="showDesignedDevelopedByFoo">Designed and developed by FOO_</div>
  </div>
</div>
