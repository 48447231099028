<button class="close-icon" mat-dialog-close><span class="foo-icon foo-close-dark"></span></button>
<div mat-dialog-title>
  <p>{{ "general.resetPass" | translate}}</p>
</div>

<mat-dialog-content formValidationContainer class="mt-10">
  <form (ngSubmit)="submit()" [formGroup]="form" formValidation autocomplete="off" id="ngForm">
    <div class="form-group">
      <label class="form-label">{{ "general.newPass" | translate }}*</label>
      <input [placeholder]="'general.newPassPlaceholder' | translate" class="form-control"
             formControlName="password"
             type="password"
             (input)="passModify();">
      <small
        *ngIf="(form.get('password').touched || form.get('password').dirty) && form.get('password').hasError('required')"
        class="text-danger">
        {{"validations.required_field" | translate}}
      </small>
    </div>
    <div class="row">
      <div class="col-12 pass-check">
        <p class="check-condition" [class.verified]="hasLength" *ngIf='passConfig && passConfig["password_length"] && passConfig["password_length"].value > 0'>
          <span class="foo-icon foo-success-pass-check succes-img mr-1"></span>
          <span class="foo-icon foo-failed-pass-check failed-img mr-1"></span>
          {{ "general.passcheck1" | translate }} {{ charNumber }} {{ "general.passcheck6" | translate }}</p>
        <p class="check-condition" [class.verified]="hasUppercase" *ngIf='passConfig && passConfig["password_uppercase"] && passConfig["password_uppercase"].value == 1'>
          <span class="foo-icon foo-success-pass-check succes-img mr-1"></span>
          <span class="foo-icon foo-failed-pass-check failed-img mr-1"></span>
           {{ "general.passcheck2" | translate }}</p>
        <p class="check-condition" [class.verified]="hasLowercase" *ngIf='passConfig && passConfig["password_lowercase"] && passConfig["password_lowercase"].value == 1'>
          <span class="foo-icon foo-success-pass-check succes-img mr-1"></span>
          <span class="foo-icon foo-failed-pass-check failed-img mr-1"></span>
           {{ "general.passcheck3" | translate }}</p>
        <p class="check-condition" [class.verified]="hasNumbers" *ngIf='passConfig && passConfig["password_numbers"] && passConfig["password_numbers"].value == 1'>
          <span class="foo-icon foo-success-pass-check succes-img mr-1"></span>
          <span class="foo-icon foo-failed-pass-check failed-img mr-1"></span>
           {{ "general.passcheck4" | translate }}</p>
        <p class="check-condition" [class.verified]="hasSymbols" *ngIf='passConfig && passConfig["password_symbols"] && passConfig["password_symbols"].value == 1'>
          <span class="foo-icon foo-success-pass-check succes-img mr-1"></span>
          <span class="foo-icon foo-failed-pass-check failed-img mr-1"></span>
           {{ "general.passcheck5" | translate }}</p>
      </div>
    </div>
  </form>
</mat-dialog-content>

<div mat-dialog-actions class="pt-28">
  <button form="ngForm" type="submit" [disabled]="form.pristine || !hasSymbols || !hasNumbers || !hasLowercase || !hasUppercase || !hasLength"
          class="foo-btn-primary foo-btn-center">{{ "general.submit" | translate}}</button>
</div>
