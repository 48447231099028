<nav class="navbar left-menu" [class.closed]="menuClosed">
  <div class="logo-section">
    <img *ngIf="logo" class="logo" alt="logo" src="{{logo}}">
    <img *ngIf="userLogo" class="logo" src="{{userLogo}}" alt="user-logo"/>
    <ng-container *ngIf="environment && !menuClosed">
      <div class="d-inline-flex environment" [style.background]="environmentBackgroundColor">
          <span>{{ environment }}</span>
      </div>
    </ng-container>
    <div class="foo-btn-outline-secondary langSwitch" (click)='useLanguage();'  *ngIf="showLang" >
      <span>{{ "menu.lang" | translate }}</span></div>
    <div class="head-icons">
      <div class="menu-btn" (click)="toggleMenu($event)">
        <span class="foo-icon foo-menu-white"></span>
      </div>
    </div>
  </div>
  <div class="mobile-info">
    <p>{{personName}}</p>
    <p>{{ lastLogin ? (lastLogin | date:'EEEE, MMMM d, y, h:mm a') : '-'}}</p>
  </div>

  <left-menu-search-bar [menuItems]="leftMenuSearchBarItems" [menuClosed]="menuClosed"></left-menu-search-bar>

  <ul class="navbar-nav">
    <li class="nav-item" *ngFor="let item of topLeftMenuItems">
      <a class="nav-link" *ngIf="item.submenu" (click)="itemParentMenu(item)" [ngClass]="{'activated': checkIfActivatedChildRoute(currentUrl, item)}">
        <i  *ngIf="item.icon &&  (!item.icon_path || item.icon_path =='') "  class="{{item.icon}}"></i>
        <img class="icon-img" *ngIf="item.icon_path && item.icon_path !==''" src="{{item.icon_path}}" [alt]="item.icon_path">
        <span>{{item.title | TranslationFormat:'menu':curLang}}</span>
        <span [class.open]="!item.isOpen" class="menu-arrow foo-icon foo-arrow-down-left-menu-secondary"></span>
      </a>
      <a class="nav-link" *ngIf="!item.submenu" (click)="onItemClicked($event, item)" [routerLink]="getRoutePath(item)" [ngClass]="{'activated': mainUrl === item.path}">
        <i *ngIf="item.icon &&  (!item.icon_path || item.icon_path =='') " class="{{item.icon}}"></i>
        <img class="icon-img" *ngIf="item.icon_path && item.icon_path !==''" src="{{item.icon_path}}" [alt]="item.icon_path">
        <span>{{item.title | TranslationFormat:'menu':curLang}} </span>
      </a>
      <!-- {{item.translations[curLang].title}} -->
      <div class="sub-menu" *ngIf="item.submenu" [class.hide]="!item.isOpen">
        <a class="nav-link" *ngFor="let subitem of item.submenu" [routerLink]="getRoutePath(subitem)" (click)="onItemClicked($event, subitem)" [ngClass]="{'activated': mainUrl === subitem.path}">
          <i class="{{subitem.icon !=='' && subitem.icon !==null && subitem.icon !==undefined  ? subitem.icon : 'dot-item'}}"></i>
          <span>{{subitem.title | TranslationFormat:'menu':curLang}}</span>
        </a>
      </div>
    </li>
    <span *ngIf="topLeftMenuItems.length" class="border-bottom-top-left-menu d-block"></span>
    <li class="nav-item" *ngFor="let item of menuItems">
      <a class="nav-link" *ngIf="item.submenu" (click)="itemParentMenu(item)" [ngClass]="{'activated': checkIfActivatedChildRoute(currentUrl, item)}">
        <i  *ngIf="item.icon &&  (!item.icon_path || item.icon_path =='') "  class="{{item.icon}}"></i>
        <img class="icon-img" *ngIf="item.icon_path && item.icon_path !==''" src="{{item.icon_path}}" [alt]="item.icon_path">
        <span>{{item.title | TranslationFormat:'menu':curLang}}</span>
        <span [class.open]="!item.isOpen" class="menu-arrow foo-icon foo-arrow-down-left-menu-secondary"></span>
      </a>
      <a class="nav-link" *ngIf="!item.submenu" (click)="onItemClicked($event, item)" [routerLink]="getRoutePath(item)" [ngClass]="{'activated': mainUrl === item.path}">
        <i *ngIf="item.icon &&  (!item.icon_path || item.icon_path =='') " class="{{item.icon}}"></i>
        <img class="icon-img" *ngIf="item.icon_path && item.icon_path !==''" src="{{item.icon_path}}" [alt]="item.icon_path">
        <span>{{item.title | TranslationFormat:'menu':curLang}} </span>
      </a>
      <!-- {{item.translations[curLang].title}} -->
      <!-- Submenu Level 1 -->
      <div class="sub-menu" *ngIf="item.submenu" [class.hide]="!item.isOpen">
        <ng-container *ngFor="let subitem of item.submenu">
          <a class="nav-link" *ngIf="subitem.submenu" (click)="itemParentMenu(subitem)" [ngClass]="{'activated': checkIfActivatedChildRoute(currentUrl, subitem)}">
            <i *ngIf="subitem.icon && (!subitem.icon_path || subitem.icon_path == '')" class="{{subitem.icon}}"></i>
            <img class="icon-img" *ngIf="subitem.icon_path && subitem.icon_path !== ''" src="{{subitem.icon_path}}" [alt]="subitem.icon_path">
            <span>{{subitem.title | TranslationFormat:'menu':curLang}}</span>
            <span [class.open]="!subitem.isOpen" class="menu-arrow foo-icon foo-arrow-down-left-menu-secondary"></span>
          </a>
          <a class="nav-link" *ngIf="!subitem.submenu" (click)="onItemClicked($event, subitem)" [routerLink]="getRoutePath(subitem)" [ngClass]="{'activated': mainUrl === subitem.path}">
            <i *ngIf="subitem.icon && (!subitem.icon_path || subitem.icon_path == '')" class="{{subitem.icon}}"></i>
            <img class="icon-img" *ngIf="subitem.icon_path && subitem.icon_path !== ''" src="{{subitem.icon_path}}" [alt]="subitem.icon_path">
            <span>{{subitem.title | TranslationFormat:'menu':curLang}}</span>
          </a>
          <!-- Submenu Level 2 -->
          <div class="sub-menu pl-24" *ngIf="subitem.submenu" [class.hide]="!subitem.isOpen">
            <ng-container *ngFor="let subSubItem of subitem.submenu">
              <a class="nav-link" *ngIf="subSubItem.submenu" (click)="itemParentMenu(subSubItem)" [ngClass]="{'activated': checkIfActivatedChildRoute(currentUrl, subSubItem)}">
                <i *ngIf="subSubItem.icon && (!subSubItem.icon_path || subSubItem.icon_path == '')" class="{{subSubItem.icon}}"></i>
                <img class="icon-img" *ngIf="subSubItem.icon_path && subSubItem.icon_path !== ''" src="{{subSubItem.icon_path}}" [alt]="subSubItem.icon_path">
                <span>{{subSubItem.title | TranslationFormat:'menu':curLang}}</span>
                <span [class.open]="!subSubItem.isOpen" class="menu-arrow foo-icon foo-arrow-down-left-menu-secondary"></span>
              </a>
              <a class="nav-link" *ngIf="!subSubItem.submenu" (click)="onItemClicked($event, subSubItem)" [routerLink]="getRoutePath(subSubItem)" [ngClass]="{'activated': mainUrl === subSubItem.path}">
                <i *ngIf="subSubItem.icon && (!subSubItem.icon_path || subSubItem.icon_path == '')" class="{{subSubItem.icon}}"></i>
                <img class="icon-img" *ngIf="subSubItem.icon_path && subSubItem.icon_path !== ''" src="{{subSubItem.icon_path}}" [alt]="subSubItem.icon_path">
                <span>{{subSubItem.title | TranslationFormat:'menu':curLang}}</span>
              </a>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </li>
    <li class="nav-item mobile-info" *ngFor="let item of adminItems">
      <a class="nav-link" *ngIf="item.submenu" (click)="itemParentMenu(item)" [ngClass]="{'activated': checkIfActivatedChildRoute(currentUrl, item)}">
        <i *ngIf="item.icon &&  (!item.icon_path || item.icon_path =='') " class="{{item.icon}}"></i>
        <img class="icon-img" *ngIf="item.icon_path && item.icon_path !==''" src="{{item.icon_path}}" [alt]="item.icon_path">

        <span>{{item.title | TranslationFormat:'menu':curLang}}</span>
        <span [class.open]="!item.isOpen" class="menu-arrow foo-icon foo-arrow-down-left-menu-secondary"></span>
      </a>
      <a class="nav-link" *ngIf="!item.submenu" (click)="onItemClicked($event, item)" [routerLink]="getRoutePath(item)" [ngClass]="{'activated': mainUrl === item.path}">
        <i *ngIf="item.icon &&  (!item.icon_path || item.icon_path =='') " class="{{item.icon}}"></i>
        <img  class="icon-img" *ngIf="item.icon_path && item.icon_path !==''" src="{{item.icon_path}}" [alt]="item.icon_path">
        <span>{{item.title | TranslationFormat:'menu':curLang}} </span>
      </a>
      <!-- {{item.translations[curLang].title}} -->
      <div class="sub-menu" *ngIf="item.submenu" [class.hide]="!item.isOpen">
        <a class="nav-link" *ngFor="let subitem of item.submenu" [routerLink]="getRoutePath(subitem)" (click)="onItemClicked($event, subitem)" [ngClass]="{'activated': mainUrl === subitem.path}">
          <i class="{{subitem.icon !=='' && subitem.icon !==null && subitem.icon !==undefined  ? subitem.icon : 'dot-item'}}"></i>
          <span>{{subitem.title | TranslationFormat:'menu':curLang}}</span>
        </a>
      </div>
    </li>
    <li class="nav-item mobile-info" *ngFor="let item of deviceItems">
      <a class="nav-link" *ngIf="item.submenu" (click)="itemParentMenu(item)" [ngClass]="{'activated': checkIfActivatedChildRoute(currentUrl, item)}">
        <i *ngIf="item.icon &&  (!item.icon_path || item.icon_path =='') " class="{{item.icon}}"></i>
        <img class="icon-img" *ngIf="item.icon_path && item.icon_path !==''" src="{{item.icon_path}}" [alt]="item.icon_path">
        <span>{{item.title | TranslationFormat:'menu':curLang}}</span>
        <span [class.open]="!item.isOpen" class="menu-arrow foo-icon foo-arrow-down-left-menu-secondary"></span>
      </a>
      <a class="nav-link" *ngIf="!item.submenu" (click)="onItemClicked($event, item)" [routerLink]="getRoutePath(item)" [ngClass]="{'activated': mainUrl === item.path}">
        <i *ngIf="item.icon &&  (!item.icon_path || item.icon_path =='') " class="{{item.icon}}"></i>
        <img class="icon-img" *ngIf="item.icon_path && item.icon_path !==''" src="{{item.icon_path}}" [alt]="item.icon_path">

        <span>{{item.title | TranslationFormat:'menu':curLang}} </span>
      </a>
      <!-- {{item.translations[curLang].title}} -->
      <div class="sub-menu" *ngIf="item.submenu" [class.hide]="!item.isOpen">
        <a class="nav-link" *ngFor="let subitem of item.submenu" [routerLink]="getRoutePath(subitem)" (click)="onItemClicked($event, subitem)" [ngClass]="{'activated': mainUrl === subitem.path}">
          <i *ngIf="item.icon" class="{{subitem.icon !=='' && subitem.icon !==null && subitem.icon !==undefined  ? subitem.icon : 'dot-item'}}"></i>
          <span>{{subitem.title | TranslationFormat:'menu':curLang}}</span>
        </a>
      </div>
    </li>
    <li class="nav-item mobile-info" *ngFor="let item of myAccountMenuItems">
      <a class="nav-link" *ngIf="item.submenu" (click)="itemParentMenu(item)" [ngClass]="{'activated': checkIfActivatedChildRoute(currentUrl, item)}">
        <i *ngIf="item.icon &&  (!item.icon_path || item.icon_path =='') " class="{{item.icon}}"></i>
        <img class="icon-img" *ngIf="item.icon_path && item.icon_path !==''" src="{{item.icon_path}}" [alt]="item.icon_path">
        <span>{{item.title | TranslationFormat:'menu':curLang}}</span>
        <span [class.open]="!item.isOpen" class="menu-arrow foo-icon foo-arrow-down-left-menu-secondary"></span>
      </a>
      <a class="nav-link" *ngIf="!item.submenu" (click)="onItemClicked($event, item)" [routerLink]="getRoutePath(item)" [ngClass]="{'activated': mainUrl === item.path}">
        <i *ngIf="item.icon &&  (!item.icon_path || item.icon_path =='') " class="{{item.icon}}"></i>
        <img class="icon-img" *ngIf="item.icon_path && item.icon_path !==''" src="{{item.icon_path}}" [alt]="item.icon_path">
        <span>{{item.title | TranslationFormat:'menu':curLang}} </span>
      </a>
      <!-- {{item.translations[curLang].title}} -->
      <div class="sub-menu" *ngIf="item.submenu" [class.hide]="!item.isOpen">
        <a class="nav-link" *ngFor="let subitem of item.submenu" [routerLink]="getRoutePath(subitem)" (click)="onItemClicked($event, subitem)" [ngClass]="{'activated': mainUrl === subitem.path}">
          <i   class="{{subitem.icon !=='' && subitem.icon !==null && subitem.icon !==undefined  ? subitem.icon : 'dot-item'}}"></i>
          <span>{{subitem.title | TranslationFormat:'menu':curLang}}</span>
        </a>
      </div>
    </li>
    <li class="nav-item logout-btn">
      <a class="nav-link" (click)="onLogout()" href="javascript:void(0)">
        <i class="foo-icon foo-logout-light align-middle"></i>
        <span>{{"general.logout" | translate}} </span>
      </a>
    </li>
  </ul>
</nav>
