import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class GeneralEventService {
    initEvent: EventEmitter<any> = new EventEmitter();
  constructor() { }

  emitEvent(info) {
    this.initEvent.emit(info);
  }
  getEvent() {
    return this.initEvent;
  }
  
}