import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ListingService {
  nbOfListingComponents = 0;
  hasMultipleListingComponents = new BehaviorSubject<boolean>(false);
  constructor() { }

  incrementNbOfListingComponents(): void {
    this.nbOfListingComponents++;
    this.hasMultipleListingComponents.next(this.nbOfListingComponents > 1);
  }
}
