import {Pipe, PipeTransform} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {get, isArray, values} from 'lodash';

@Pipe({
  name: 'propertyByLang'
})
export class PropertyByLangPipe implements PipeTransform {

  constructor(
    private translate: TranslateService
  ) {
  }

  transform(value: any, property: string): Observable<any> {
    return this.translate.onLangChange.pipe(
      map((langChangeEvent: LangChangeEvent) => langChangeEvent.lang)
    ).pipe(
      startWith(this.translate.currentLang),
      map(lang => {
        const defaultValue = get(
          (isArray(get(value, 'translations')) ? get(value, 'translations') : values(get(value, 'translations')))
            .find((t) => t.lang === 'en'),
          property
        ) || '';
        return (
          get(
            (isArray(get(value, 'translations')) ? get(value, 'translations') : values(get(value, 'translations'))).find((t) => t.lang === lang),
            property
          ) || defaultValue
        );
      })
    );
  }

}
