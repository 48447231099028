import {Component, Input, OnInit} from '@angular/core';
import {isString} from 'lodash';

export interface FooIconConfig {
  containerClass?: string;
  stackIconClass?: string;
  iconClass: string;
}

export interface FooTooltipConfig {
  message: string;
  position?: string;
  className?: string;
}

@Component({
  selector: 'foo-icon',
  templateUrl: './foo-icon.component.html',
  styleUrl: './foo-icon.component.scss'
})
export class FooIconComponent implements OnInit {
  @Input() icon: FooIconConfig | string;
  @Input() tooltip: FooTooltipConfig | string  ;

  ngOnInit(): void {
  }

  isString(item: FooIconConfig| FooTooltipConfig | string): boolean {
    return isString(item);
  }
}
