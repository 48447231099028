import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-countries-list',
  templateUrl: './countries-list.component.html',
  styleUrls: ['./countries-list.component.scss']
})
export class CountriesListComponent {

  @Input() data: any;
  @Input() permissions: any;
  @Input() labels: any;
  @Input() dataShimmer: any;
  @Input() btnList: any = [];
  @Input() paginationInput: any;

  @Output() viewDetailsClicked = new EventEmitter<any>();
  @Output() deleteButtonClicked = new EventEmitter<any>();
  @Output() updateAppCountryStatusChanged = new EventEmitter<any>();
  

  viewDetails(item) {
    this.viewDetailsClicked.emit(item);
  }

  delete(item) {
    this.deleteButtonClicked.emit(item);
  }

  updateAppCountryStatus(item) {
    this.updateAppCountryStatusChanged.emit(item);
  }

  functionCalled($event): void{
    this[$event.functionName]($event.item);
  }

}
