import { DomSanitizer } from '@angular/platform-browser';
import {ChangeDetectorRef, Component, OnInit, TemplateRef} from '@angular/core';
import {ListingTemplatesService} from "../listing/listing-templates.service";
import {ListingComponent} from "../listing/listing.component";
import { ListingService } from '../services/listing.service';
import {MakeCallService, TokenStorage} from "foo-framework";

@Component({
  selector: 'group-listing',
  templateUrl: './group-listing.component.html',
  styleUrls: ['./group-listing.component.scss'],
})

export class GroupListingComponent extends ListingComponent implements OnInit {

  public constructor(
    protected listingTemplateService: ListingTemplatesService,
    protected sanitizer: DomSanitizer,
    protected ListingService: ListingService,
    protected tokenStorage: TokenStorage,
    protected makecall: MakeCallService,
    protected cdr: ChangeDetectorRef,
  ) {
    super(cdr, listingTemplateService, sanitizer, ListingService, tokenStorage, makecall);
  }

  dataLength;
  isGroupRows = false;
  groupDataInput = [];

  getTemplate(id: string): TemplateRef<any> {
    return this.listingTemplateService.getTemplate(id);
  }

  log(data) {
    console.log(data);
  }

  ngOnInit(): void {
    if (this.GroupRows()) {
      this.ListingService.incrementNbOfListingComponents();
    }
  }

  ngOnChanges(): void {
    if (this.GroupRows()) {
      this.curLang = this.tokenStorage.getCurrentLanguage();
      this.pagination = this.paginationInput;

      if (this.pagination) {
        this.totalData = this.pagination["totalData"];
        this.dataPerpage = this.pagination["dataPerpage"] ? this.pagination["dataPerpage"] : (this.data.length ? this.data.length : 1);
        this.pageNumber = this.pagination["pageNumber"];
      }

      this.data = Object.assign([], this.groupDataInput);
      this.issearch = this.issearchInput;
      this.dataLength = typeof (this.dataInput) === 'object' ? Object.keys(this.dataInput).length : this.dataInput.length;
    }
  }

  groupBy(xs, f) {
    return xs.reduce((memo, value) => {
      memo[f(value)] = memo[f(value)] || [];
      memo[f(value)].push(value);
      return memo;
    }, {});
  }

  GroupRows() {
    for (let x = 0; x < this.labels.length; x++) {
      this.isGroupRows = this.labels[x]['group_row'];
      if (this.isGroupRows) {
        this.groupDataInput = this.groupBy(this.dataInput || [], (c) => c.date);
        break;
      }
    }
    return true;
  }

  ngOnDestroy(): void {
    if (this.GroupRows()) {
      this.ListingService.nbOfListingComponents--;
    }
  }
}
