import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

import {filter} from 'lodash';

interface SearchInfoInterface {
  searchText?: string;
  form?: any;
  pageNumber?: number;
  sortingState?: { sort_direction: string, sort_field: string };
}

@Injectable({
  providedIn: 'root'
})

export class SearchFiltersService {

  static searchInfo: { [route: string]: SearchInfoInterface } = {};

  filterKeys = [
    'form',
    'searchText',
    'pageNumber',
    'sortingState',
  ];

  constructor(
    private router: Router
  ) {
  }

  public defineRouteSearchInfo(): void {
    if (!SearchFiltersService.searchInfo[this.router.url.split('?')[0]]) {
      SearchFiltersService.searchInfo[this.router.url.split('?')[0]] = {};
    }
  }

  public routeExists(): SearchInfoInterface {
    return SearchFiltersService.searchInfo[this.router.url.split('?')[0]];
  }

  public setForm(formvalue: any): void{
    this.defineRouteSearchInfo();
    SearchFiltersService.searchInfo[this.router.url.split('?')[0]].form = formvalue;
  }

  public setSearchText(searchText: string): void {
    this.defineRouteSearchInfo();
    SearchFiltersService.searchInfo[this.router.url.split('?')[0]].searchText = searchText;
  }

  public setPageNumber(pageNumber: number): void {
    this.defineRouteSearchInfo();
    SearchFiltersService.searchInfo[this.router.url.split('?')[0]].pageNumber = pageNumber;
  }

  public setSortingState(sortingState: { sort_direction: string, sort_field: string }): void {
    this.defineRouteSearchInfo();
    SearchFiltersService.searchInfo[this.router.url.split('?')[0]].sortingState = sortingState;
  }

  // Getters
  public getSearchText(searchText): any {
    return SearchFiltersService.searchInfo[this.router.url.split('?')[0]] && SearchFiltersService.searchInfo[this.router.url.split('?')[0]].searchText
      ? SearchFiltersService.searchInfo[this.router.url.split('?')[0]].searchText : searchText;
  }

  public getForm(form): any {
    return SearchFiltersService.searchInfo[this.router.url.split('?')[0]] && SearchFiltersService.searchInfo[this.router.url.split('?')[0]].form
      ? SearchFiltersService.searchInfo[this.router.url.split('?')[0]].form : form;
  }

  public getPageNumber(pageNumber): any {
    return SearchFiltersService.searchInfo[this.router.url.split('?')[0]] && SearchFiltersService.searchInfo[this.router.url.split('?')[0]].pageNumber
      ? SearchFiltersService.searchInfo[this.router.url.split('?')[0]].pageNumber : pageNumber;
  }

  public fillSort(component, savedValue, labelsVarName: string = 'labels'): void {
    const sortLabel = filter(component[labelsVarName], ['sortingValue', savedValue?.sort_field]);
    if (sortLabel && sortLabel[0]) {
      sortLabel[0].sorting = savedValue.sort_direction;
    }
  }


  /**
   * Get return sorting state and used add the sorting field to the corresponding object in the labels.
   * @param component the component instance
   * @param sortingState used to set the value to default state in case there was no value for sorting saved in the service
   * @param labelsVarName it is only passed when labels had a different naming in the component(e.g. labelspending in idv users) so that it is filled or else defaullt value is labels
   */
  public getSortingState(component, sortingState, labelsVarName: string = 'labels'): any {

    const savedSortingField = SearchFiltersService.searchInfo[this.router.url.split('?')[0]]?.sortingState;
    if (savedSortingField) {
      this.fillSort(component, savedSortingField, labelsVarName);
    }
    return savedSortingField ? savedSortingField : sortingState;
  }

  public setByName(component, property: string): void {
    this.defineRouteSearchInfo();
    SearchFiltersService.searchInfo[this.router.url.split('?')[0]][property] = component[property];
  }

  public fillByName(component, property: string): any {
    component[property] = SearchFiltersService.searchInfo[this.router.url.split('?')[0]] && SearchFiltersService.searchInfo[this.router.url.split('?')[0]][property]
      ? SearchFiltersService.searchInfo[this.router.url.split('?')[0]][property] : component[property];
  }


  /**
   * clears current route info
   */
  public clear(): void {
    delete SearchFiltersService.searchInfo[this.router.url.split('?')[0]];
  }

  /**
   * clears all routes info
   */
  public clearAll(): void {
    SearchFiltersService.searchInfo = {};
  }

  public fillSearchInfo(component): void {
    const that = this;
    this.filterKeys.forEach(value => {
      if (value in component) {
        const savedValue = SearchFiltersService.searchInfo[that.router.url.split('?')[0]] && SearchFiltersService.searchInfo[that.router.url.split('?')[0]][value] ? SearchFiltersService.searchInfo[that.router.url.split('?')[0]][value] : 'none';
        if (savedValue !== 'none') {
          component[value] = savedValue;

          if (value === 'sortingState') {
            that.fillSort(component, savedValue);
          }
        }
      }
    });
  }

  public setSearchInfo(component): void{
    const that = this;
    this.defineRouteSearchInfo();
    this.filterKeys.forEach(value => {
      if (value in component) {
        that['set' + (value.charAt(0).toUpperCase() + value.slice(1))](component[value]);
      }
    });
  }

  public resetLabels(component, labelsVarName: string = 'labels', sortingState?): void {
    component[labelsVarName].forEach(object => {
      if (object?.sorting){
        delete object.sorting;
      }
    });
    const labelsCopy = JSON.parse(JSON.stringify(component[labelsVarName]));
    component[labelsVarName] = [];
    component[labelsVarName] = labelsCopy;
    const sortingStateVar = sortingState ? sortingState : 'sortingState';
    component[sortingStateVar] = null ;
    if (SearchFiltersService.searchInfo && SearchFiltersService.searchInfo[this.router.url.split('?')[0]]
      && SearchFiltersService.searchInfo[this.router.url.split('?')[0]].sortingState){
      SearchFiltersService.searchInfo[this.router.url.split('?')[0]].sortingState = null;
    }
  }

}
