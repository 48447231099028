import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'IbanFormat'
})
export class IbanFormatPipe implements PipeTransform {
   reg: RegExp;

  transform(value: any): string {
    if (value) {
      // Gets the value as a string
      if (typeof value !== 'string') {
        value = value.toString();
      }
      // Delete existing spaces
      value = (value as string).replace(' ', '');

      // Add space between each 4 digits
      value = value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
    }
    return value;
  }

}
