<div class="form-group pr-0 pl-0" [class.col-12]="labelInput">
  <h2 class="container-label mb-10" *ngIf="labelInput">{{ labelInput | translate }}<span *ngIf="isRequired">*</span></h2>
  <ng-container *ngIf="!multiple">
    <div class="uploader-container">
      <input #fileInput type="file" accept="{{acceptString}}" (change)="onFileInput($event)" style="display:none;"/>
      <div class="uploader-image d-flex ">
        <div class="upload-placeholder align-self-center" *ngIf="!(currentFile && isObject(fileInfoDetails))">
          <img class="icon-picture mb-15" src="/assets/img/iconpicture.svg" alt="icon-picture"><br>
          <div class="mb-10 drag-text">{{ 'general.drag&dropHere' | translate }}</div>
          <button type="button" (click)="fileInput.click()" class="mb-10 browse-btn">{{ 'general.browseFiles' | translate }}</button>
          <br>
          <div class="note-text" *ngIf="displayedExtensions" [ngClass]="{'mb-10': uploadNote}">{{ 'general.supports' | translate }}{{displayedExtensions }}</div>
          <div class="note-text">{{uploadNote}}</div>
          <input hidden type="text" [formControl]="control">
        </div>
        <ng-container *ngIf="currentFile && isObject(fileInfoDetails)">
          <div *ngIf="(fileType == 'csv' || fileType == 'excel' || fileType == 'excelxls' || fileType == 'word' || fileType == 'zip' || fileType == 'txt')" class="file-info single">
            <img *ngIf="getFileIconName(returnAsFile ? blobUrl : currentFile)" alt="file-icon"
                 src="./assets/img/file-icons/{{ getFileIconName(returnAsFile ? blobUrl : currentFile)}}.svg"
            />
            <p class="file-name">{{ getFileName(returnAsFile ? blobUrl : currentFile) }}.{{getFileIconName(currentFile)}}<br>
              <span class="file-size">{{ getFileSize(returnAsFile ? blobUrl : currentFile) }}</span>
            </p>

          </div>
          <img width="170" height="170" *ngIf="returnFileWithInfo && !multiple; else other_content" [src]="sanitize(returnAsFile ? blobUrl : currentFile?.path)" [alt]="fileInfoDetails.name_without_ext ? fileInfoDetails.name_without_ext : 'image'"/>
          <ng-template #other_content>
            <img width="170" height="170" *ngIf="checkType(returnAsFile ? blobUrl : currentFile, 'image')" [src]="sanitize(returnAsFile ? blobUrl : currentFile)" [alt]="fileInfoDetails.name_without_ext ? fileInfoDetails.name_without_ext : 'image'"/>
          </ng-template>

          <video controls *ngIf="checkType(returnAsFile ? blobUrl : currentFile, 'video')" (click)="toggleVideo()" #videoPlayer>
            <source [src]="sanitize(returnAsFile ? blobUrl : currentFile)" type="video/mp4" />
            <track label="English" kind="captions" srclang="en" src="" default>
          </video>
        </ng-container>
      </div>

      <ng-container *ngIf="!isBase64">
        <div class="uploader-progress progress">
          <div class="progress-bar progress-bar-info" *ngIf="filesCount != 0 && showProgress && progress<100"
               role="progressbar"
               attr.aria-valuenow="{{ progress }}"
               aria-valuemin="0"
               aria-valuemax="100"
               [ngStyle]="{ width: progress + '%' }">
            {{ progress }}%
          </div>
        </div>
      </ng-container>
      <div class="uploader-top-bar" [class.fileIcons]="!checkType(currentFile, 'image')" *ngIf="currentFile && isObject(fileInfoDetails)">
        <button *ngIf="hasEditPerminssion" class="circle-actions" type="button" (click)="fileInput.click()">
          <span class="foo-icon foo-edit-secondary"></span>
        </button>
        <button *ngIf="hasDeletePerminssion" class="circle-actions" type="button" (click)="delete()">
          <span class="foo-icon foo-delete-secondary"></span>
        </button>
        <button *ngIf="hasDownloadPerminssion" class="circle-actions" type="button" (click)="download(currentFile)">
          <span class="foo-icon foo-download-secondary"></span>
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="multiple">
    <div class="uploader-container multiple">
      <ng-container *ngIf="!control?.value?.length">
        <ng-container *ngTemplateOutlet="Uploader">
        </ng-container>
      </ng-container>
      <ng-container *ngIf="control?.value?.length">
        <div class="files-list">
          <ng-container *ngFor="let file of control?.value; let idx = index">
            <div class="uploader-file">
              <div class="uploader-top-bar">
                <button *ngIf="hasDeletePerminssion && file" type="button" class="circle-actions" (click)="deleteFile(idx)">
                  <span class="foo-icon foo-delete-secondary"></span>
                </button>

                <button *ngIf="hasDownloadPerminssion && file && checkType(file, 'image') "
                         type="button" class="circle-actions" (click)="download(file)">
                  <span class="foo-icon foo-download-secondary"></span>
                </button>

                <button *ngIf="hasDownloadPerminssion && file && (!checkType(file, 'image')) && downloadMultiple"
                      type="button" class="circle-actions" (click)="downloadviaPath(file)">
                  <span class="foo-icon foo-download-secondary"></span>
                </button>


              </div>
              <div class="file-info" style="height: 100%;" *ngIf="!checkType(file, 'image')">
                <img *ngIf="getFileIconName(file)"
                     src="./assets/img/file-icons/{{ getFileIconName(file)}}.svg"
                     alt="file-icon"
                />
                <p class="file-name only-name" [title]="getFileName(file)">{{ getFileName(file) }}</p>
              </div>

              <img *ngIf="checkType(file, 'image') && isObject(fileInfoDetails)" [src]="sanitize(file)" [alt]="fileInfoDetails.name_without_ext ? fileInfoDetails.name_without_ext : 'image'"/>

              <video controls *ngIf="checkType(file, 'video')" (click)="toggleVideo()" #videoPlayer>
                <source [src]="sanitize(file)" type="video/mp4" />
                <track label="English" kind="captions" srclang="en" src="" default>
                Browser not supported
              </video>
            </div>
          </ng-container>
          <ng-container *ngTemplateOutlet="Uploader">
          </ng-container>
        </div>
      </ng-container>
    </div>

    <ng-template #Uploader>
      <div class="uploader-file files-selector">
        <div class="upload-placeholder">
          <img src="/assets/img/iconpicture.svg" class="icon-picture" alt="icon-picture"><br>
          <span class="drag-text">{{ 'general.drag&dropHere' | translate }}</span><br>
          <button type="button" (click)="fileInput.click()" class="browse-btn">{{ 'general.browseFiles' | translate }}
          </button>
          <br>
          <span class="note-text" *ngIf="extensions">{{ 'general.supports' | translate }}{{extensions }}</span><br>
          <span class="note-text">{{uploadNote}}</span>
          <input #fileInput type="file" accept="{{acceptString}}" (change)="onFileInput($event)" multiple style="display:none;"/>
          <input hidden type="text" [formControl]="control">
        </div>
      </div>
    </ng-template>
  </ng-container>
  <small *ngIf="control.hasError('required') && (control.touched || control.dirty)"
         class="text-danger">
    {{"validations.required_field" | translate}}
  </small>
</div>
