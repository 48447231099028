import { Component, Input, OnInit, ElementRef, ViewChild } from '@angular/core';


@Component({
  selector: 'foo-count-up',
  templateUrl: './count-up.component.html',
  styleUrls: ['./count-up.component.scss'],
})
export class CountUpComponent implements OnInit {
  @Input() value: number = 0;
  @Input() duration: number = 1000;
  @Input() format: string = '1.0';
  @Input() class: string = '';
  @Input() disable: boolean = false;
  countUpValue: number = 0;
  animationState: string = 'start';

  constructor() { }

  ngOnInit(): void {
    if(this.value && !this.disable){
    this.startCountUpAnimation();
    }else if(this.value){
      this.countUpValue = this.value;
    }
  }


  startCountUpAnimation(): void {
    const targetValue = this.value;
    const duration = this.duration; 
    const interval = this.duration / targetValue;
    const steps = Math.ceil(duration / interval);
    const increment = (targetValue - this.countUpValue) / steps;
    
    const countUpInterval = setInterval(() => {
      this.countUpValue = Math.floor(this.countUpValue + increment);
      
      if (this.countUpValue >= targetValue) {
        this.countUpValue = targetValue;
        clearInterval(countUpInterval);
        this.animationState = 'end';
      }
    }, interval);
  }
}
