import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {get, isBoolean} from 'lodash';
import {TokenStorage} from "foo-framework";
import { DialogService } from '../services/dialog.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-transaction-details',
  templateUrl: './transaction-details.component.html',
  styleUrls: ['./transaction-details.component.scss']
})
export class TransactionDetailsComponent implements OnInit, OnDestroy {

  @Input() transactionDetails: any;
  @Input() item: any;
  @Input() detailsShimmer: any;
  @Input() dataShimmer: any;
  @Input() selectedStatus: any;
  @Input() roleTransactionDetails: any;
  @Input() getColumnName: () => string;
  @Input() getPermissionTitle: () => string;
  @Input() activeTab: any;
  @Input() permissions: any;
  @Input() arrayKey: any;

  @Output() reject = new EventEmitter<any>();
  @Output() approve = new EventEmitter<any>();

  curLang: string;
  responsiveDesignMobileBreakPoint = false;
  responsiveDesignTabletBreakPoint = false;
  subscriptions = new Subscription();

  constructor(private breakpointObserver: BreakpointObserver,
              private tokenStorage: TokenStorage,
              private translate: TranslateService,
              private dialogService: DialogService) { }

  ngOnInit(): void {
    this.curLang = this.tokenStorage.getCurrentLanguage();
    this.breakpointObserver
    .observe('(max-width: 540px)')
    .subscribe(({matches}) => {
      this.responsiveDesignMobileBreakPoint = matches;
    });

    this.breakpointObserver
    .observe('(max-width: 1425px)')
    .subscribe(({matches}) => {
      this.responsiveDesignTabletBreakPoint = matches;
    });
  }

  textTransformInitialCheck(column_name){
    return ((column_name)?.toLowerCase() === 'email' ||
      (column_name)?.toLowerCase() === 'name' ||
      (column_name)?.toLowerCase() === 'message' ||
      (column_name)?.toLowerCase() === 'message_en' ||
      (column_name)?.toLowerCase() === 'message_ar' ||
      (column_name)?.toLowerCase() === 'url' ||
      (column_name)?.toLowerCase() === 'link' ||
      (column_name)?.toLowerCase() === 'smtp_email' ||
      (column_name)?.toLowerCase() === 'smtp_password' ||
      (column_name)?.toLowerCase() === 'smtp_host' ||
      (column_name)?.toLowerCase() === 'host' ||
      (column_name)?.toLowerCase() === 'emails_to_send' ||
      (column_name)?.toLowerCase() === 'checkbook_email' ||
      (column_name)?.toLowerCase() === 'feedback_emails' ||
      (column_name)?.toLowerCase() === 'transfer_confirmation_message' ||
      (column_name)?.toLowerCase() === 'payment_confirmation_message' ||
      (column_name)?.toLowerCase() === 'session_inactivity_message' ||
      (column_name)?.toLowerCase() === 'account_blocked_message' ||
      (column_name)?.toLowerCase() === 'stop_card_message' ||
      (column_name)?.toLowerCase() === 'transfer_pending_message' ||
      (column_name)?.toLowerCase() === 'transfer_online_message' ||
      (column_name)?.toLowerCase() === 'user_email' ||
      (column_name)?.toLowerCase() === 'password');
  }

  getDisplayValue(val, column_name){
    let textToReturn='';
    if (val != null && val != '' && !isBoolean(val)){
      textToReturn = val;
    }
    else if (isBoolean(val)){
      textToReturn = val ? this.translate.instant('approval.yes')  : this.translate.instant('approval.no');
    }
    else{
      textToReturn = '-';
    }

    if(column_name == 'expiry'){
      textToReturn += " ";
      if((val == 1 || val == "1" )){
        textToReturn += this.translate.instant('approval.month')
      }
      else{
        textToReturn += this.translate.instant('approval.months')
      }
    }
    return textToReturn;
  }

  getColumnNameForTransaction(item) {
    return this.getColumnName.call(this, item);
  }

  getPermissionTitleForTransaction(item) {
    return this.getPermissionTitle.call(this, item);
  }

  comparison(newValue, oldValue, item = null) {
    if (this.checkAction(item, 'add')) {
      return false;
    } else {
      return newValue != oldValue;
    }
  }

  getTypeOf(item){
    return typeof item;
  }

  rejectButton(item) {
    this.reject.emit(item);
  }

  approveButton(item) {
    this.approve.emit(item);
  }

  checkAction(item, actionName) {
    return (item?.action === actionName ||
      item?.extra?.display_action === actionName ||
      item?.type === actionName ||
      item?.transactions_group?.transactions[0]?.action === actionName);
  }

  columnIsImage(column_name) {
    return (column_name)?.toLowerCase().includes('image') || (column_name)?.toLowerCase().includes('icon') || (column_name)?.toLowerCase().includes('front_overlay') || (column_name)?.toLowerCase().includes('back_overlay');
  }

  openDialog(component, configurations) {
    let configs = {
      data: {
        ...configurations
      },
      width: '500px'
    };
    this.subscriptions.add(this.dialogService.openDialog(component, configs).subscribe());
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
