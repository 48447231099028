import { unionBy } from 'lodash';
import { Injectable, InjectionToken } from '@angular/core';

export enum ComponentTypes {

}

export interface AppComponentsConfig {
  configs?: { name: ComponentTypes, component: any }[],
}

export const APP_COMPONENTS_CONFIG = new InjectionToken<AppComponentsConfig>('APP_COMPONENTS_CONFIG');

@Injectable({
  providedIn: 'root'
})
export class FooSharedModulesComponentsConfigService {

  componentsConfig: { name: ComponentTypes, component: any, module?: any, componentName?: string }[] = [

  ];

  constructor(
  ) {
  }

  setComponentsConfig(config: { name: ComponentTypes, component: any, module?: any, componentName?: string }[]): void {
    this.componentsConfig = unionBy((config || []), this.componentsConfig, 'name');
  }
}
