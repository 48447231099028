<foo-control-label [templateOptions]="templateOptions" [control]="control"></foo-control-label>

<input class="form-control"
       [readonly]="templateOptions?.readonly"
       [maxlength]="templateOptions?.maxLength"
       [placeholder]='templateOptions?.placeholder | translate'
       (beforeinput)="templateOptions?.onBeforeInput && templateOptions?.onBeforeInput($event)"
       (input)="templateOptions?.onInput && templateOptions?.onInput($event)"
       [formControl]="control"
       [type]="templateOptions?.type"
       />


<foo-control-errors *ngIf="!templateOptions?.readonly && !templateOptions?.hideErrors"  [control]="control" [errorMessages]="errors"></foo-control-errors>
