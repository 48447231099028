import {
  Component,
  createNgModuleRef,
  Inject,
  Injector,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {isFunction, get} from 'lodash';
import {isObservable, Observable} from 'rxjs';
import {LazyLoadingCacheService} from 'foo-framework';

export interface DialogButton<T> {
  key: string;
  title: string;
  classNames: string;
  callback: (dialogRef: MatDialogRef<T>, component?: FooPopupComponent<T>) => void;
}

@Component({
  selector: 'foo-popup',
  templateUrl: './foo-popup.component.html',
  styleUrls: ['./foo-popup.component.scss']
})
export class FooPopupComponent<T> implements OnInit {
  btnList: DialogButton<T>[];
  componentInfo: {
    module: any,
    componentName: string,
    component: any;
  } = null;

  buttonsDirection: 'vertical' | 'horizontal' = 'horizontal';

  @ViewChild("innerComponent", {read: ViewContainerRef, static: true})
  innerComponent!: ViewContainerRef;


  constructor(
    public dialogRef: MatDialogRef<T>,
    @Inject(MAT_DIALOG_DATA) public data,
    private injector: Injector,
    private lazyLoadingCacheService: LazyLoadingCacheService
  ) {
    this.btnList = get(this.data, 'btnList') || [];
    this.componentInfo = get(this.data, 'componentInfo') || null;
    this.buttonsDirection = get(this.data, 'buttonsDirection') || 'horizontal';
  }

  ngOnInit(): void {
    if (this.componentInfo) {
      this.loadComponent().then();
    }
  }

  async loadComponent(): Promise<any> {
    if (this.componentInfo.module && this.componentInfo.module.name) {
      const cachedModule = this.lazyLoadingCacheService.getCachedModule(this.componentInfo.module.name);
      let result: any = null;
      let moduleRef: any = null;
      if (!cachedModule) {
        result = await this.componentInfo.module.load();
        moduleRef = createNgModuleRef(result.module, this.injector);
        this.lazyLoadingCacheService.cacheModule(this.componentInfo.module.name, result, moduleRef);
      } else {
        result = cachedModule.module;
        moduleRef = cachedModule.moduleRef;
      }
      this.innerComponent.clear();
      this.innerComponent.createComponent(result.components[this.componentInfo.componentName], {ngModuleRef: moduleRef});
    } else if (this.componentInfo.module) {
      let moduleRef: any = null;
      moduleRef = createNgModuleRef(this.componentInfo.module, this.injector);
      this.innerComponent.clear();
      this.innerComponent.createComponent(this.componentInfo.component, {ngModuleRef: moduleRef});
    } else {
      this.innerComponent.clear();
      this.innerComponent.createComponent(this.componentInfo.component);
    }
  }

  get title(): string | Observable<string> | any {
    if (isFunction(get(this.data, 'title'))) {
      return this.data.title();
    }
    return get(this.data, 'title');
  }

  get text(): string | Observable<string> | any {
    if (isFunction(get(this.data, 'text'))) {
      return this.data.text();
    }
    return get(this.data, 'text');
  }

  isObservable(object: any): boolean {
    return isObservable(object);
  }

  onClick(btn: DialogButton<T>): void {
    if (isFunction(btn.callback)) {
      btn.callback(this.dialogRef, this);
    } else {
      this.dialogRef.close(btn.key);
    }
  }
}
