import {Component, forwardRef, Injector, Input, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {FooControlComponent} from "../foo-control/foo-control.component";

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FooRadiolistComponent),
  multi: true
};

@Component({
  selector: 'foo-radiolist',
  templateUrl: './foo-radiolist.component.html',
  styleUrls: ['./foo-radiolist.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class FooRadiolistComponent extends FooControlComponent  implements OnInit {

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
  }

  get keyProp(): string {
    return this.templateOptions?.keyProp || 'value';
  }

  get titleProp(): string {
    return this.templateOptions?.titleProp || 'label';
  }


}
