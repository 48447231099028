import { Injectable } from '@angular/core';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { AlertService } from './alert.service';
import {TokenStorage} from './token-storage.service';


@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(private alert: AlertService, private tokenStorage: TokenStorage ) { }

  curLang = this.tokenStorage.getCurrentLanguage();

  copyTranslation(allData, languages, fillEmpty) {
    let toCopy = allData[this.curLang];
    if (!toCopy) {
      for (let j = 1; j < languages.length; j++) {
        if (allData[languages[j].code]) {
          toCopy = allData[languages[j].code];
          break;
        }
      }
    }

    for (let i = 0; i < languages.length; i++) {
      const data = allData[languages[i].code];
      if (!data) {
        allData[languages[i].code] = {
          lang: languages[i].code
        };
      }
      for (const key in toCopy) {
        if (key !== 'translation_id' && key !== 'lang') {
          if (!data[key] || data[key] === '' || data[key] == null || data[key] === '<p>&nbsp;<br></p>' || data[key] === '<p><br data-mce-bogus="1"></p>')
            if (!fillEmpty) {
              data[key] = toCopy[key];
            }
            else {
              data[key] = '';
            }
        }
      }
    }
  }

  getNumberWithoutCountryCode(phoneNumber) {
    let phoneWithoutCountryCode;
    let selectedCountry = null;
    if (phoneNumber) {
      const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();

      try {
        let numberProto = phoneUtil.parse(phoneNumber, "");
        let countryCode = numberProto.getCountryCode();
        selectedCountry = phoneUtil.getRegionCodeForNumber(numberProto);

        if (phoneNumber.startsWith(countryCode)) {
          let phoneNoCountryCode = phoneNumber.replace(countryCode, "");
          phoneWithoutCountryCode = phoneNoCountryCode;
        } else if (phoneNumber.startsWith("+" + countryCode)) {
          let phoneNoCountryCode = phoneNumber.replace("+" + countryCode, "");
          phoneWithoutCountryCode = phoneNoCountryCode;
        }
      } catch (NumberParseException) {
        this.alert.emitAlert({ type: 'danger', text: NumberParseException });
        return NumberParseException;
      }
    }
    return { phoneWithoutCountryCode, selectedCountry };
  }
}
