import { CryptoService} from "foo-framework";
import {NgbDateFooParserFormatterService, PermissionsService, RouteService} from 'foo-framework';
import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {BreadcrumbModule} from 'xng-breadcrumb';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule} from '@angular/material/form-field';
import { SwiperModule } from 'swiper/angular';
import { AppComponent } from './app.component';
import { HttpBackend, provideHttpClient, withInterceptors, withJsonpSupport, withXsrfConfiguration } from '@angular/common/http';
import { PagesComponent } from './pages/pages.component';
import { UnauthPagesComponent } from './unauth-pages/unauth-pages.component';
import { AppRoutingModule } from './app-routing.module';
import {AuthInterceptor} from './interceptors/auth-interceptor';
// import ngx-translate and the http loader
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {MatRadioModule} from '@angular/material/radio';
import {NgxPaginationModule} from 'ngx-pagination';
import {DecimalPipe, SlicePipe} from '@angular/common';
import { MatDialogModule} from '@angular/material/dialog';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { FooFrameworkModule} from 'foo-framework';
import { FooDmpModule} from 'dmp-framework';

import {MatSelectModule} from '@angular/material/select';
import {MatOptionModule} from '@angular/material/core';
import { EditorModule } from '@tinymce/tinymce-angular';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { DatePipe } from '@angular/common';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import {environment} from "../environments/environment";
import { SharedModulesModule } from "foo-framework/shared-modules";
@NgModule({ declarations: [
        AppComponent,
        PagesComponent,
        UnauthPagesComponent,
    ],
    exports: [],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatMomentDateModule,
        NgbModule,
        BrowserModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpBackend]
            }
        }),
        NgSelectModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatInputModule,
        MatSelectModule,
        MatOptionModule,
        MatAutocompleteModule,
        MatRadioModule,
        ReactiveFormsModule,
        FormsModule,
        SwiperModule,
        BreadcrumbModule,
        DragDropModule,
        NgxPaginationModule,
        AppRoutingModule,
        MatDialogModule,
        EditorModule,
        SharedModulesModule.forRoot(),
        FooFrameworkModule.forRoot(environment),
        FooDmpModule.forRoot()], providers: [
        { provide: MAT_DATE_FORMATS, useValue: {
                parse: {
                    dateInput: 'DD-MM-YYYY',
                },
                display: {
                    dateInput: 'DD-MM-YYYY',
                    monthYearLabel: 'MM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MM YYYY'
                },
            } },
        SlicePipe,
        NgbDateFooParserFormatterService,
        DatePipe,
        CryptoService,
        DecimalPipe,
        provideHttpClient(withInterceptors([AuthInterceptor]), withXsrfConfiguration({cookieName: 'XSRF-TOKEN', headerName: 'X-XSRF-TOKEN'}))
    ] })
export class AppModule {
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpBackend): MultiTranslateHttpLoader {
  // return new TranslateHttpLoader(http);
  return new MultiTranslateHttpLoader(http, [
    {prefix: 'assets/i18n/', suffix: '.common.json?v=' + Date.now()},
    {prefix: 'dist/angular/assets/i18n/', suffix: '.foo.json?v=' + Date.now()},
    {prefix: 'dist/angular/assets/i18n/', suffix: '.dmp.json?v=' + Date.now()},
    {prefix: 'dist/angular/assets/i18n/', suffix: '.json?v=' + Date.now()}
   ]);
}
