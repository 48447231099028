import { ColorAdapter } from '@angular-material-components/color-picker';
import { Component, Injector, Input, OnInit, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FooControlComponent } from '../foo-control/foo-control.component';
import { isEmpty, isString, omit } from 'lodash';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FooColorpickerComponent),
  multi: true
};

@Component({
  selector: 'foo-colorpicker',
  templateUrl: './foo-colorpicker.component.html',
  styleUrls: ['./foo-colorpicker.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class FooColorpickerComponent extends FooControlComponent implements OnInit {
  
  @Input() placeholder: string;

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    if (this.control.value && isString(this.control.value)) {
      const color = new ColorAdapter().parse(this.control.value);
      this.control.setValue(color);
    }
    this.control.markAsUntouched();
  }

  onInputChange(event: any): void {
    if (!this.control.value && event.target?.value) {
     this.control.setErrors({
       ...(this.control.errors || {}),
      invalid_color: true
      });
    } else {
     const errorsExludedMine = omit(this.control.errors, ['invalid_color']);
     this.control.setErrors(
         isEmpty(errorsExludedMine) ? null : errorsExludedMine
    );
    }
 }
}
 