import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyService } from '../services/currencies/currency.service';

@Pipe({
  name: 'formatNumberBasedOnCurrency'
})
export class FormatNumberBasedOnCurrencyPipe implements PipeTransform {

  constructor(
    private currencyService: CurrencyService
  ) {
  }

  transform(value: any, currency: any): unknown {
    return this.currencyService.getFormattedNumberBasedOnCurrency(value, currency);
  }

}
