import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { get, isNil, isNumber } from 'lodash';
import { DateTime } from 'luxon';
import { isObservable, of } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ParserService {
  constructor(private translate: TranslateService) {}

  booleanParser(value) {
    return +value
      ? 'transactionDetails.general.yes'
      : 'transactionDetails.general.no';
  }

  fromListPropertyParser$(
    list: any[] | Observable<any[]>,
    displayExpr: string,
    keyExpr = 'id'
  ) {
    return (value) =>
      (isObservable(list) ? list : of(list)).pipe(
        map((_list) =>
          get(
            (_list || []).find(
              (item) =>
                item[keyExpr] === (isNumber(item[keyExpr]) ? +value : value)
            ),
            displayExpr
          )
        )
      );
  }

  fromListParser$(list: any[] | Observable<any[]>, keyExpr = 'id') {
    return (value) =>
      (isObservable(list) ? list : of(list)).pipe(
        map((_list) =>
          (_list || []).find(
            (item) =>
            item[keyExpr] ==  value
          )
        )
      );
  }

  fromListWithTranslationParser$(
    list: any[] | Observable<any[]>,
    displayExpr: string,
    keyExpr = 'id'
  ) {
    return (value) =>
      this.fromListPropertyParser$(
        list,
        'translations',
        keyExpr
      )(value).pipe(
        switchMap((translations) =>
          this.onTranslationChange$().pipe(
            map((lang) => this.getTranslation(translations, lang, displayExpr))
          )
        )
      );
  }

  noValueProvidedParser(value) {
    return !isNumber(value) && (isNil(value) || value === 'null' || value === '')
      ? 'N/A'
      : value;
  }

  dateParser(format: string = 'dd/MM/YYYY - hh:mm') {
    return (value) => {
      DateTime.fromSeconds(value).toFormat(format);
    };
  }

  translationsParser$(property: string) {
    return (value) =>
      this.onTranslationChange$().pipe(
        map((lang) => {
          return this.getTranslation(
            get(value, 'translations') || [],
            lang,
            property
          );
        })
      );
  }

  translationsParser(property: string) {
    return (value) =>
      this.getTranslation(
        get(value, 'translations') || [],
        this.translate.currentLang,
        property
      );
  }

  ibanParser(value) {
    if (value) {
      var country = value.substring(0, 2);
      if (country.toUpperCase() != 'AE') {
        return 'AE' + value;
      }
      return value;
    } else {
      return value;
    }
  }

  translateParser$(path = '') {
    return (value) => {
      if (value) {
        return this.translate.stream(path ? `${path}.${value}`: value);
      } else {
        return of(null);
      }
    };
  }

  private onTranslationChange$() {
    return this.translate.onLangChange.pipe(
      startWith({ lang: this.translate.currentLang }),
      map((event) => {
        return get(event, 'lang');
      })
    );
  }

  private getTranslation(translations, lang, property): string {
    const defaultValue = get(
      (translations || []).find((t) => t.lang === 'en'),
      property
    );
    return (
      get(
        (translations || []).find((t) => t.lang === lang),
        property
      ) || defaultValue
    );
  }
}
