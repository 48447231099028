import {Component, EventEmitter, forwardRef, HostListener, Injector, Input, OnInit, Output} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {FooControlComponent} from '../foo-control/foo-control.component';
import {AlertService} from 'foo-framework';
import {TranslateService} from '@ngx-translate/core';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FooFileComponent),
  multi: true
};

@Component({
  selector: 'foo-file',
  templateUrl: './foo-file.component.html',
  styleUrls: ['./foo-file.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class FooFileComponent extends FooControlComponent implements OnInit {

  accept: Array<string> = ['png', 'jpg', 'pdf'];
  extensions = 'JPG, PNG, PDF';
  fileSize = 5;

  selectedFiles?: FileList;

  mimeTypes = {
    'audio/aac': 'aac',
    // 'application/x-abiword': 'abw',
    // 'application/x-freearc': 'arc',
    'video/x-msvideo': 'avi',
    // 'application/vnd.amazon.ebook': 'azw',
    'application/octet-stream': 'bin',
    'image/bmp': 'bmp',
    // 'application/x-bzip': 'bz',
    // 'application/x-bzip2': 'bz2',
    // 'application/x-csh': 'csh',
    'text/css': 'css',
    'text/csv': 'xls',
    'application/msword': 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'doc',
    // 'application/vnd.ms-fontobject': 'eot',
    // 'application/epub+zip': 'epub',
    // 'application/gzip': 'gz',
    'image/gif': 'gif',
    'text/html': 'html',
    // 'image/vnd.microsoft.icon': 'ico',
    // 'text/calendar': 'ics',
    // 'application/java-archive': 'jar',
    'image/jpeg': 'jpg',
    // 'text/javascript': 'mjs',
    // 'application/json': 'json',
    // 'application/ld+json': 'jsonld',
    'audio/midi': 'midi',
    'audio/mpeg': 'mp3',
    'video/mpeg': 'mpg',
    // 'application/vnd.apple.installer+xml': 'mpkg',
    // 'application/vnd.oasis.opendocument.presentation': 'odp',
    // 'application/vnd.oasis.opendocument.spreadsheet': 'ods',
    // 'application/vnd.oasis.opendocument.text': 'odt',
    // 'audio/ogg': 'oga',
    // 'video/ogg': 'ogv',
    // 'application/ogg': 'ogx',
    // 'audio/opus': 'opus',
    // 'font/otf': 'otf',
    'image/png': 'png',
    'application/pdf': 'pdf',
    'application/x-httpd-php': 'php',
    'application/vnd.ms-powerpoint': 'ppt',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'ppt',
    'application/vnd.rar': 'zip',
    // 'application/rtf': 'rtf',
    // 'application/x-sh': 'sh',
    'image/svg+xml': 'svg',
    // 'application/x-shockwave-flash': 'swf',
    'application/x-tar': 'zip',
    'image/tiff': 'tif',
    // 'video/mp2t': 'ts',
    // 'font/ttf': 'ttf',
    'text/plain': 'txt',
    // 'application/vnd.visio': 'vsd',
    // 'audio/wav': 'wav',
    // 'audio/webm': 'weba',
    // 'video/webm': 'webm',
    // 'image/webp': 'webp',
    // 'font/woff': 'woff',
    // 'font/woff2': 'woff2',
    'application/xhtml+xml': 'html',
    'application/vnd.ms-excel': 'xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xls',
    'application/xml ': 'xml',
    'application/vnd.mozilla.xul+xml': 'xml',
    'application/zip': 'zip',
    // 'video/3gpp': '3gp',
    // 'video/3gpp2': '3g2',
    'application/x-7z-compressed': 'zip'
  };

  fileTypesMap = {
    image: 'image/*',
    video: 'video/*',
    text: 'text/plain',
    csv: 'text/csv',
    pdf: 'application/pdf',
    png: 'image/png',
    jpg: 'image/jpeg',
    excel: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    word: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  };

  fileSizeErrorMessage = this.translate.instant('uploader.fileSizeErrorMessage', {fileSize: this.fileSize});


  constructor(
    private alert: AlertService,
    private translate: TranslateService,
    injetor: Injector
  ) {
    super(injetor);
  }

  ngOnInit(): void {
  }
  get multiple(): boolean {
    return this.templateOptions?.multiple;
  }
}
