import {Component, OnInit, Inject} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AlertService, LoaderService, UploadService} from 'foo-framework';
import {HttpResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-file-upload-form',
  templateUrl: './file-upload-form.component.html',
  styleUrls: ['./file-upload-form.component.scss']
})
export class FileUploadFormComponent implements OnInit {

  form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder,
              private alert: AlertService,
              @Inject(MAT_DIALOG_DATA) public data,
              public dialogRef: MatDialogRef<any>,
              private loaderService: LoaderService,
              private uploadService: UploadService,
              private translate: TranslateService,) {
  }

  ngOnInit() {
    let controlsConfig = {};
    let formControlName = this.data.uploaderFormControlName;
    controlsConfig[formControlName] = ['', [Validators.required]];
    this.form = this.fb.group(controlsConfig);
  }

  onSubmit() {
    if (this.form.valid && !this.loaderService.visibility) {
      this.loaderService.visibility = true;
      if (this.data.returnAsFile) {
        this.dialogRef.close(this.form.value[this.data.uploaderFormControlName]);
        this.loaderService.visibility = false;
      } else {
        let params = {};
        if (this.data.uploadParams) {
          let keys = Object.keys(this.data.uploadParams);
          if (keys.length) {
            keys.forEach(param => {
              params['params[' + param + ']'] = this.data.uploadParams[param];
            });
          }
        }

        this.uploadService.upload(this.form.value[this.data.uploaderFormControlName], this.data.uploadPath, this.data.apiUrl, params).subscribe({
          next: (event: any) => {
            if (event instanceof HttpResponse) {
              let response = event.body;
              if (response && response.response && (response.response.status === 'OK' || response.response.status === 'success')) {
                this.alert.emitAlert({type: 'success', text: response.response['message']});
                this.dialogRef.close('submit');
                this.loaderService.visibility = false;
              } else {
                this.alert.emitAlert({type: 'danger', text: response.response['message']});
                this.dialogRef.close('submit');
              }
            }
          },
          error: err => {
            this.loaderService.visibility = false;
            if (err.error && err.error.message) {
              this.alert.emitAlert({type: "danger", text: err.error.message});
            } else {
              this.alert.emitAlert({type: "danger", text: this.translate.instant('general.somethingWentWrong')});
            }
          }
        });
      }
    }
  }
}
