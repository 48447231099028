import {ApprovalService} from './services/approval.service';
import {LazyLoadingCacheService} from './services/lazy-loading-cache.service';
import { ManageBreadcrumbService } from './services/manage-breadcrumb.service';
import {RouteReuseStrategy, RouterModule} from '@angular/router';
import { PipesModule } from './pipes/pipes.module';
import { UtilsService } from './services/utils.service';
import { UrlHelperService } from './services/url-helper.service';
import { TokenStorage } from './services/token-storage.service';
import { SortDirective } from './services/sortable.directive';
import { ParserService } from './services/parser.service';
import { NumbersOnlyDirective } from './services/numbers-only.directive';
import { AlphabetsOnlyDirective } from './directives/alphabets-only/alphabets-only.directive';
import { VersionNumberDirective} from './directives/version-number/version-number.directive';
import { MakeCallService } from './services/makecall.service';
import { GeneralEventService } from './services/generalEvents.service';
import { DataStorageService } from './services/data-storage.service';
import { CustomValidatorsService } from './services/custom-validators.service';
import { CardValidator } from './services/card-validator.service';
import { AuthenticateService } from './services/authenticate.service';
import { AlertService } from './services/alert.service';
import { LoaderService } from './services/loader/loader.service';
import { LoaderComponent } from './components/loader/loader.component';
import { LeftMenuComponent } from './components/left-menu/left-menu.component';
import { HeaderComponent } from './components/header/header.component';
import { MaterialModule } from './material/material.module';
import { LoginComponent } from './auth/login/login.component';
import { ForgotPasswordComponent } from './auth/forgot/forgot-password.component';
import { PermissionsService } from './services/permissions.service';
import { RouteService } from './services/route.service';
import {
  CUSTOM_ELEMENTS_SCHEMA, Inject,
  ModuleWithProviders,
  NgModule, Optional,
  APP_INITIALIZER, Injector
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {provideHttpClient, withJsonpSupport} from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { SwiperModule } from 'swiper/angular';
import { CanonicalService } from './services/canonical/canonical.service';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { NgbDateFooParserFormatterService } from './services/ngb-date-foo-parser-formatter.service';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import {ResetPasswordComponent} from './auth/reset/reset-password.component';
import {OtpComponent} from './auth/otp/otp.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {NoteLimitService} from './services/note-limit.service';
import { LazyLoadedComponent } from './components/lazy-loaded/lazy-loaded.component';
import {FooRouteReuseStrategy} from './core/foo-route-reuse-strategy';
import {FormValidationDirective} from  './directives/form-validation/form-validation.directive';
import {FormValidationContainerDirective} from './directives/form-validation/form-validation-container.directive';
import {OnClickedOutsideDirective} from './directives/on-clicked-outside/on-clicked-outside.directive';
import {NgSelectCustomDirective} from './directives/ng-select-custom/ng-select-custom.directive';
import {APP_GENERAL_CONFIG, AppGeneralConfig, GeneralConfigService} from './services/general-config.service';
import {PopupTinymceEditorDirective} from './directives/popup-tinymce-editor/popup-tinymce-editor.directive';

import {SearchFiltersService} from './services/search-filters.service';
import {MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';
import {
  PopupsDropdownsAlignmentDirective
} from './directives/popups-dropdowns-alignment/popups-dropdowns-alignment.directive';
import {InputUnitDirective} from './directives/input-unit/input-unit.directive';
import { ReplaceSeparatorAndCapitalizePipe } from '../lib/pipes/replace-separator-and-capitalize.pipe';
import {ColorService} from './services/color.service';
import { DomainService } from './services/domain.service';
import { TinymceConfigService } from './services/tinymce-config.service';
import { StringobjectPipe } from '../lib/pipes/stringobject.pipe';
import { ConfirmDialogComponent } from './auth/reset/confirm-dialog/confirm-dialog.component';
import {
  AllowedCharactersDirective
} from './directives/allowed-characters/allowed-characters.directive';
import { appInitializerFactory } from './appInitializerFactory';
import {WhitespacesDirective} from './directives/whitespaces-characters/whitespaces.directive';

import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { RecaptchaService } from "./services/recaptcha.service"
import { CurrencyService } from './services/currencies/currency.service';
import { MapService } from "./services/map.service";
import { FormatNumberBasedOnCurrencyPipe } from '../lib/pipes/format-number-based-on-currency.pipe';
import { LeftMenuSearchBarComponent } from './components/left-menu/left-menu-search-bar/left-menu-search-bar.component';

const components = [
  LoginComponent,
  ForgotPasswordComponent,
  ResetPasswordComponent,
  OtpComponent,
  HeaderComponent,
  LeftMenuComponent,
  LoaderComponent,
  PageNotFoundComponent,
  NumbersOnlyDirective,
  AlphabetsOnlyDirective,
  SortDirective,
  NgSelectCustomDirective,
  OnClickedOutsideDirective,
  NoteLimitService,
  LazyLoadedComponent,
  FormValidationDirective,
  FormValidationContainerDirective,
  PopupTinymceEditorDirective,
  PopupsDropdownsAlignmentDirective,
  InputUnitDirective,
  VersionNumberDirective,
  ConfirmDialogComponent,
  AllowedCharactersDirective,
  WhitespacesDirective,
  LeftMenuSearchBarComponent
];

@NgModule({ declarations: [...components],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [
        ...components,
        PipesModule,
        RecaptchaV3Module
    ], imports: [TranslateModule,
        CommonModule,
        MaterialModule,
        PipesModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        SwiperModule,
        BreadcrumbModule,
        EditorModule,
        DragDropModule,
        RecaptchaV3Module] })
export class FooFrameworkModule {
  static forRoot(
    config: AppGeneralConfig = {}
  ): ModuleWithProviders<FooFrameworkModule> {
    return {
      ngModule: FooFrameworkModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: appInitializerFactory,
          deps: [TranslateService, Injector, TokenStorage],
          multi: true
        },
        LazyLoadingCacheService,
        ApprovalService,
        RouteService,
        PermissionsService,
        SearchFiltersService,
        {
          provide: 'FrameworkRoutes',
          useExisting: RouteService
        },
        {
          provide: 'Permissions',
          useExisting: PermissionsService,
        },
        { provide: APP_GENERAL_CONFIG, useValue: config, multi: true },
        CanonicalService,
        LoaderService,
        AlertService,
        AuthenticateService,
        CardValidator,
        CustomValidatorsService,
        DataStorageService,
        GeneralEventService,
        MakeCallService,
        ParserService,
        TokenStorage,
        UrlHelperService,
        UtilsService,
        ManageBreadcrumbService,
        GeneralConfigService,
        ColorService,
        DomainService,
        RecaptchaService,
        MapService,
        {
          provide: RouteReuseStrategy,
          useClass: FooRouteReuseStrategy,
        },
        {
          provide: NgbDateParserFormatter,
          useClass: NgbDateFooParserFormatterService,
        },
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { disableClose: true, hasBackdrop: true, autoFocus: false } },
        ReplaceSeparatorAndCapitalizePipe,
        TinymceConfigService,
        StringobjectPipe,
        {
          provide: RECAPTCHA_V3_SITE_KEY,
          useFactory: () => {
            if (window['google_recaptcha']) {
              return window['google_recaptcha_site_key'];
            } else {
              return null;
            }
          }
        },
        CurrencyService,
        FormatNumberBasedOnCurrencyPipe,
        provideHttpClient(withJsonpSupport())
      ],
    };
  }

  constructor(generalConfig: GeneralConfigService, @Optional() @Inject(APP_GENERAL_CONFIG) configs: AppGeneralConfig[]) {
    if (!configs) {
      return;
    }

    configs.forEach((config: AppGeneralConfig) => {
      generalConfig.setConfig(config);
    });

  }
}
