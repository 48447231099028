
<div class="unauth-body">
    <!-- BEGIN SIDEBAR -->
    <div class="row top-menu">
        <div class="col-6 col-md-6 col-sm-6 col-lg-6">
                <img class="login-logo" routerLink="/" src="/assets/img/logo.svg" />
        </div>
        <div class="col-6 col-md-6 col-sm-6 col-lg-6 lang-cont">
                <div class="langSwitch" (click)='changeLanguage();'>
                  <span>{{ "menu.lang" | translate }} </span>
                </div>
        </div>
    </div>
    <!-- END SIDEBAR -->
    <div class="page-content-wrapper" [dir]='ltr'>
        <div class="page-content">
            <xng-breadcrumb>
                    <ng-container>
                        <ng-container *xngBreadcrumbItem="let breadcrumb;let first = first">
                            <ng-container *ngIf="first"><img class="hover-scale svg-color-filter" (click)="goBack()" src="/assets/img/back.svg"/></ng-container>
                        </ng-container>
                    </ng-container>
            </xng-breadcrumb>
            <router-outlet (LangChangedEvent)="useLanguage($event)"></router-outlet>
        </div>
    </div>
</div>

