import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {AlertService, CustomValidatorsService, LoaderService, MakeCallService, TokenStorage} from "foo-framework";
import {TranslateService} from "@ngx-translate/core";
import { keyBy } from 'lodash';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  form: UntypedFormGroup;
  allowDmpFeature = false;
  // used for form generation
  currenciesSet = new Set([]);
  touchedCurrenciesForm: boolean = false;
  currencies = [];

  paymentMethodsSet = new Set([]);
  touchedPaymentMethodForm: boolean = false;
  payment_methods = [];
  languages: Array<object>;
  languagesNoCurrent: Array<object>;
  curLang: string;
  user: any;
  hasSymbols = false;
  hasNumbers = false;
  hasLowercase = false;
  hasUppercase = false;
  hasLength = false;
  passConfig: any;
  charNumber =  0;

  constructor(private makecall: MakeCallService,
              private customValidators: CustomValidatorsService,
              private loaderService: LoaderService,
              private alert: AlertService,
              public dialogRef: MatDialogRef<ResetPasswordComponent>,
              private translate: TranslateService,
              private fb: UntypedFormBuilder,
              private tokenStorage: TokenStorage,
              @Inject(MAT_DIALOG_DATA) public data,) {
    this.data = data;
  }

  ngOnInit(): void {
    this.getPassConfig();
    this.form = this.fb.group({
      id: [this.data && this.data.id ? this.data.id : ''],
      password: ['', Validators.required],
    });
    this.user = JSON.parse(this.tokenStorage.getUser());
  }

  getPassConfig(){
    this.makecall.postRequest('auth/passconfig', {
      "email": this.user?.email
    }, { showLoader: true }).subscribe({
      next: (resp: any) => {
        this.passConfig = keyBy(resp['configuration'], 'key');
        this.charNumber = this.passConfig['password_length'] && this.passConfig['password_length'].value ? this.passConfig['password_length'].value : 0;
      }
    });
  }

  
  passModify() {
    if (this.passConfig && this.passConfig["password_length"] && this.form.value?.password.length < Number(this.passConfig["password_length"].value)) {
      this.hasLength = false;
    } else {
      this.hasLength = true;
    }
    let re = new RegExp(/[-!@$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/);
    if (this.passConfig && this.passConfig["password_symbols"] && this.passConfig["password_symbols"].value == 1 && !re.test(this.form.value?.password)) {
      this.hasSymbols = false;
    } else {
      this.hasSymbols = true;
    }
    re = new RegExp(/[0-9]/);
    if (this.passConfig && this.passConfig["password_numbers"] && this.passConfig["password_numbers"].value == 1 && !re.test(this.form.value?.password)) {
      this.hasNumbers = false;
    } else {
      this.hasNumbers = true;
    }
    re = new RegExp(/[a-z]/);
    if (this.passConfig && this.passConfig["password_lowercase"] && this.passConfig["password_lowercase"].value == 1 && !re.test(this.form.value?.password)) {
      this.hasLowercase = false;
    } else {
      this.hasLowercase = true;
    }
    re = new RegExp(/[A-Z]/);
    if (this.passConfig && this.passConfig["password_uppercase"] && this.passConfig["password_uppercase"].value == 1 && !re.test(this.form.value?.password)) {
      this.hasUppercase = false;
    } else {
      this.hasUppercase = true;
    }

  }

  submit(): void {
    if (this.form.valid) {
      this.makecall.postRequest(this.data?.apiUrl, this.form.value, {showLoader: true, showSuccessMessage: true}).subscribe({
        next: (resp: any) => {
          this.dialogRef.close(resp);
        }
      });
    }
  }
}
