import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LoaderService} from './loader/loader.service';
import {UploadService} from './upload.service';

interface Configs {
    branding?: boolean,
    height?: number,
    menubar?: boolean,
    toolbar_mode?: string,
    plugins?: string[],
    convert_urls?: boolean;
    toolbar?: string,
    insertdatetime_dateformat?: string,
    images_upload_handler?: (blobInfo, progress) => void,
    link_assume_external_targets?: boolean
}

const defaultConfigs: Configs = {
    branding: false,
    height: 400,
    menubar: false,
    toolbar_mode: 'sliding',
    convert_urls: false,
    plugins: [
        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 'charmap', 'code', 'directionality', 'emoticons', 'fullscreen', 'insertdatetime',
    ],
    toolbar: `undo redo | blocks | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat |
    charmap emoticons | code link | ltr rtl | fullscreen | image |
    table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol |
    insertdatetime | help`,
    insertdatetime_dateformat: '%d-%m-%Y',
    link_assume_external_targets: true
}

declare const tinymce: any;

@Injectable()
export class TinymceConfigService {

    private config: Configs;

    constructor(
        @Optional() @Inject('customOptions') private customOptions: Configs,
        private http: HttpClient,
        private translate: TranslateService,
        private uploadService: UploadService,
        private loaderService: LoaderService
    ) {
        this.config = {...defaultConfigs, ...customOptions};
    }

  getKey() {
    return window['tinymce_api_key']
  }
    getTinymceEditorConfig() {
        const self = this;
        let editorConfigs = {
            branding: this.config.branding,
            height: this.config.height,
            menubar: this.config.menubar,
            toolbar_mode: this.config.toolbar_mode,
            plugins: this.config.plugins,
            toolbar: this.config.toolbar,
            insertdatetime_dateformat: this.config.insertdatetime_dateformat,
            link_assume_external_targets: this.config.link_assume_external_targets,
            file_picker_callback: function (cb, value, meta) {
                const input = document.createElement("input");
                input.setAttribute("type", "file");
                input.setAttribute("accept", "image/*");
                input.onchange = function () {
                    const file = input.files[0];
                    const reader = new FileReader();
                    reader.onload = function () {
                        const id = "blobid" + new Date().getTime();
                        const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                        const base64 = (<string>reader.result).split(",")[1];
                        const blobInfo = blobCache.create(id, file, base64);
                        blobCache.add(blobInfo);
                        cb(blobInfo.blobUri());
                    };
                    reader.readAsDataURL(file);
                };

                input.click();
            },
            images_upload_handler(blobInfo, progress): Promise<string> {
                return new Promise((resolve, reject) => {
                    self.loaderService.visibility = true;
                    self.uploadService.upload(blobInfo.blob(), 'upload/image', 'media/image-upload', {}).subscribe(
                        (uploadEvent: any) => {
                            if (uploadEvent.type === HttpEventType.UploadProgress) {
                                progress(Math.round(100 * uploadEvent.loaded / uploadEvent.total));
                            } else if (uploadEvent instanceof HttpResponse) {
                                if (uploadEvent.body && uploadEvent.body.response && uploadEvent.body.response.path) {
                                    resolve(uploadEvent.body.response.path);
                                    self.loaderService.visibility = false;
                                } else {
                                    reject({
                                        message: self.translate.instant('uploader.invalidServerResponse'),
                                        remove: true
                                    });
                                    self.loaderService.visibility = false;
                                }
                            }
                        },
                        (err: any) => {
                            if (err.error && err.error.message) {
                                reject({message: err.error.message, remove: true});
                            } else {
                                reject({message: self.translate.instant('general.somethingWentWrong'), remove: true});
                            }
                            self.loaderService.visibility = false;
                        });
                });
            }
        };
        return editorConfigs;
    }
}
