<div class="country-details" *ngIf="filtersLoaded | async">
    <div class="row mb-20 top-title-with-buttons">
        <div class="col-12 col-sm-10 col-md-10 col-lg-10">
            <h1 class="section-title">{{countryName}} {{'shared-component.countries.configuration' | translate}}</h1>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-12 mb-10" *ngFor="let flow of countryFlows; let i = index;">
            <mat-accordion class="w-100 accordion-steps">
                <mat-expansion-panel
                    [disabled]="(!(flow.enabled && flow.inputs.length > 0) || (asSuperAdmin && !(flow.active && flow.inputs.length > 0))) && !(disableUpdateFlowStatus && disableUpdateInputStatus)"
                    #MatExpansionPanel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <div class="col-10 col-sm-10 p-0">
                                <b>{{ flow.document.title }}</b>
                            </div>
                            <div class="col-2 col-sm-2 p-0 flex-end">
                                <input class="switch_1" type="checkbox" *ngIf="permissions.can_update"
                                    [checked]="asSuperAdmin ? flow.active : flow.enabled"
                                    [hidden]="disableUpdateFlowStatus" [disabled]="disableUpdateFlowStatus"
                                    (change)="asSuperAdmin ? updateFlowStatus(flow.id, flow.active = !flow.active, flow.document.title) : updateFlowStatus(flow.id, flow.enabled = !flow.enabled, flow.document.title)">
                            </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="w-100">
                        <ul
                            class="row {{ 'sortable' + flow.id }} {{ 'simple_with_animation' + flow.id }} list-unstyled">
                            <li class="col-12 step-container" *ngFor="let input of flow.inputs ;index as index">
                                <div class="row align-items-center" *ngIf="!input.hide_in_cms">

                                  <div class="col-12 col-sm-2 col-md-1 step-title mb-2 mb-md-0 pl-0 pr-0 pl-md-15 pr-md-15">
                                        Step {{ index + 1 }}
                                    </div>

                                  <div class="col-12 col-sm-10 col-md-11 step-actions-wrapper container-box-roles">
                                        <div class="step-action-info">
                                            <div class="step-actions-title">
                                                {{ getInputName(input.input_alias) }}
                                            </div>
                                        </div>

                                        <div class="step-actions flex-end">
                                            <div class="step-edit-tests">
                                                <input class="switch_1" type="checkbox" *ngIf="permissions.can_update"
                                                    [checked]="asSuperAdmin ? input.active : input.enabled"
                                                    [hidden]="disableUpdateInputStatus"
                                                    [disabled]="disableUpdateInputStatus"
                                                    (change)="asSuperAdmin ? updateInputStatus(flow.id, input.id, input.active = !input.active, flow.document.title, input.input_alias) : updateInputStatus(flow.id, input.id, input.enabled = !input.enabled, flow.document.title, input.input_alias)">
                                            </div>
                                            <div class="step-edit-tests">
                                                <button class="btn" *ngIf="permissions.can_update"
                                                    (click)="editStep(input, flow)">
                                                  <span class="foo-icon foo-settings-primary"></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>

                        <hr *ngIf="flow.overlays && updateOverlaysPermission">

                        <form class="overlay-form" [formGroup]="form" (ngSubmit)="updateOverlays(flow)"
                            *ngIf="flow.overlays && updateOverlaysPermission">
                            <div class="col-12 col-md-6 p-0">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <label class="id-overlay-title">{{ "shared-component.countries.idOverlay" |
                                            translate }}</label>
                                    </div>
                                    <div class="col-sm-6" *ngIf="flow.overlays.front">
                                        <div class="form-group">
                                            <framework-uploader
                                                [formControlName]="'flow_' + flow.id + '_frontIcon_' + i"
                                                [fileSize]="8"
                                                [extensions]="extensions" [showProgress]="true" [fileType]="'image'"
                                                [labelInput]="'shared-component.countries.idFrontOverlay'">
                                            </framework-uploader>
                                        </div>
                                    </div>
                                    <div class="col-sm-6" *ngIf="flow.overlays.back">
                                        <div class="form-group">
                                            <framework-uploader [formControlName]="'flow_' + flow.id + '_backIcon_' + i"
                                                [fileSize]="8"
                                                [extensions]="extensions" [showProgress]="true" [fileType]="'image'"
                                                [labelInput]="'shared-component.countries.idBackOverlay'">
                                            </framework-uploader>
                                        </div>

                                    </div>
                                    <div class="form-group col-12">
                                        <button class="login-btn foo-btn-primary foo-btn-center" type="submit"
                                            *ngIf="permissions.can_update" [disabled]="form.pristine">
                                            {{ "shared-component.countries.save" | translate }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</div>
