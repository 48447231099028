<div (mouseenter)="hovered = true" (mouseleave)="hovered = false">
  <span *ngIf="menuClosed && showSearchIcon"
        class="foo-icon {{hovered ? 'foo-search-primary' : 'foo-search-white'}} d-flex m-auto"></span>

  <div
    class="{{ (menuClosed && showSearchIcon && hovered) ? 'showDialogContainer showDialog': (menuClosed && showSearchIcon && !hovered) ? 'hideDialog' : '' }}">
    <ng-select (change)="selectionChanged($event)"
               [appNgSelectAutocomplete]=true
               [appNgSelectSearchBarDesign]=true
               [hideSelected]="true"
               [items]="menuItems"
               [placeholder]='"general.searchMenu" | translate'
               [searchFn]="customFilter"
               [searchable]="true"
               appNgSelectCustom
               bindLabel="translatedTitle"
               class="form-control">
      <ng-template ng-option-tmp let-item="item">
        <a class="nav-link left-menu-search-dropdown">
          <i *ngIf="item.icon && (!item.icon_path || item.icon_path?.trim() == '')" class="{{item.icon}}"></i>
          <img class="icon-img" *ngIf="item.icon_path && item.icon_path?.trim() !== ''" src="{{item.icon_path}}"
               [alt]="item.icon_path">
          <div class="{{ item?.translatedParentItemTitle ? 'd-flex flex-column' : 'display-table' }}">
            <span class="firstRow mb-1 {{ item?.translatedParentItemTitle ? '' : 'display-table-child' }}">{{item.translatedTitle}}</span>
            <span *ngIf="item?.translatedParentItemTitle" class="secondRow">{{item.translatedParentItemTitle}} </span>
          </div>
        </a>
      </ng-template>
    </ng-select>
  </div>

  <span class="border-bottom d-block mt-8"></span>
</div>
