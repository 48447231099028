<div class="data-container-has-mat-expansion-indicator" [class.shimmer-animate]="dataShimmer" #dynamicListContainer>
  <div class="row mb-20 top-title-with-buttons" [class.shimmer-animate]="dataShimmer" *ngIf="enableOrdering && orderChanged && data.length > 1">
    <div class="col-12 buttons-container">
      <button (click)="reOrder()" [disabled]="dataShimmer"
              class="foo-btn-primary mr-2">{{"faq.saveorder" | translate}}</button>
      <button (click)="cancelReorder()" [disabled]="dataShimmer"
              class="foo-btn-primary">{{"faq.cancelorder" | translate}}</button>
    </div>
  </div>


    <div class="list-header transaction-box text-center mt-4 list-header-info-cols"
        [class.d-md-flex]="!boxView" *ngIf="data?.length > 0 && !staticOrdering && !apiOrdering && !boxView && hasHeader"
        [class.shimmer-row-equal-mobile]="dataShimmer">
        <div class="info-cols">
            <ng-container *ngFor="let label of labels">
                <div scope="col" [attr.data-label]="label.value" class="col {{label.className}}"
                    [style.flex-basis]="getSecureStyle('calc(' + (label.flexBasis) + '%' + ')')">
                    <span class="table-label" [class.shimmer-even]="dataShimmer && label.title">{{ label.title | translate }}</span>
                </div>
            </ng-container>
        </div>
        <div>
            <div class="col actions-header table-label" *ngIf="btnList?.length>0 || !allAreDisabledViewDetails">
                <div [style.width]="actionsWidth + 'px'"></div>
            </div>
        </div>
    </div>
    <!-- sort by typescript -->
    <div class="list-header transaction-box text-center mt-4 list-header-info-cols" #headerGroupSelector
        [class.shimmer-row-equal-mobile]="dataShimmer" [class.d-md-flex]="!boxView"
        *ngIf="data?.length > 0 && staticOrdering && !boxView && hasHeader">
        <div class="info-cols">
            <ng-container *ngFor="let label of labels">
                <div scope="col" [style.flex-basis]="getSecureStyle('calc(' + (label.flexBasis) + '%' + ')')"
                    *ngIf=" (!label.type || ( label.type != 'date' && label.type != 'datetime')) && label.title"
                    class="col {{label.className}}">
                    <span  [attr.data-label]="label.value" [attr.data-order]="!firstLoadCriteria && (!label.disableSorting) ? '' : null"
                        [appSort]="data" [groupSelector]="headerGroupSelector"
                        class="table-label" [class.shimmer-even]="dataShimmer && label.title"
                        [ngClass]="{'sorting-disabled': firstLoadCriteria === true}">{{label.title | translate}}</span>
                </div>
                <div scope="col" [style.flex-basis]="getSecureStyle('calc(' + (label.flexBasis) + '%' + ')')"
                    *ngIf="(label.type == 'date' || label.type == 'datetime') && label.title"
                     class="col  table-label {{label.className}}">
                    <span [attr.data-label]="label.value" [attr.data-order]="!firstLoadCriteria && (!label.disableSorting) ? '' : null" data-type="date"
                        [appSort]="data" [groupSelector]="headerGroupSelector" class="table-label" [class.shimmer-even]="dataShimmer && label.title"
                        [ngClass]="{'sorting-disabled': firstLoadCriteria === true}">{{ label.title | translate
                        }}</span>
                </div>
            </ng-container>
        </div>
        <div>
            <div class="col actions-header table-label" *ngIf="btnList?.length>0 || !allAreDisabledViewDetails">
                <div [style.width]="actionsWidth + 'px'"></div>
            </div>
        </div>
    </div>

    <!-- sort by api -->
    <div class="list-header transaction-box text-center mt-4 list-header-info-cols"
        [class.shimmer-row-equal-mobile]="dataShimmer" [class.d-lg-flex]="!boxView"
        *ngIf="data?.length > 0 && apiOrdering && !boxView && hasHeader">
        <div class="info-cols">
            <ng-container *ngFor="let label of labels">
                <div scope="col" [style.flex-basis]="getSecureStyle('calc(' + (label.flexBasis) + '%' + ')')"
                    class="col  table-label {{label.className}}">
                    <span  (click)="!firstLoadCriteria && !label?.disableSorting ? sort(label) : ''"
                        [attr.data-order]="!firstLoadCriteria && (!label.disableSorting) ? (label.sorting ? label.sorting : '') : null"
                        class="table-label" [class.shimmer-even]="dataShimmer && label.title">{{ label.title | translate }}</span>
                </div>
            </ng-container>
        </div>
        <div>
            <div class="col actions-header table-label" *ngIf="btnList?.length>0 || !allAreDisabledViewDetails">
                <div [style.width]="actionsWidth + 'px'"></div>
            </div>
        </div>
    </div>

    <div class="data-container" cdkDropList (cdkDropListDropped)="drop($event)" cdkDropListOrientation="vertical" #dataGroupSelector>
        <div class="listing-row" [class.d-none]="firstLoadCriteria" [class.container-box-list]="!boxView" [class.border-bottom]="isDisabledExpansion(item)"
            [class.container-box-seperatedlist]="boxView" [class.pointer-cursor]="isEnabledViewDetails(item) && clickableRow"
            [class.clickableRow]="clickableRow" [class.shimmer-row-equal-mobile]="dataShimmer"
            (click)="!dataShimmer && clickableRow && isEnabledViewDetails(item) && viewDetails(item)"
            [class.pendingTransactionRed]="!dataShimmer && item.pending_transaction"
            [style]="customRowStyle ? customRowStyle(item) : ''" *ngFor=" let item of (pagination ? (data | paginate : {
                  id: pagination_id,
                  itemsPerPage: dataPerpage,
                  currentPage: pageNumber,
                  totalItems: totalData
                }) : data); let index = index" cdkDrag cdkDragBoundary=".data-container" (cdkDragStarted)="dragStarted($event)" [cdkDragDisabled]="dataShimmer || !enableOrdering">

          <!-- Accordion Section -->
          <mat-accordion *ngIf="!isDisabledExpansion(item)" [ngClass]="customListingClassName ? customListingClassName : 'w-100'">
            <mat-expansion-panel [disabled]="(isDisabledExpansion(item) || dataShimmer)"
                                 (opened)="getDetails(item, index)"
                                 (closed)="onToggleAccordion(item)"
                                 [class.rounded-0]="!boxView"
                                 [class.disable-mat-expansion]="!allAreDisabled && isDisabledExpansion(item)">
              <mat-expansion-panel-header [class.min-height]="boxView"
                                          [class.cursor-default]="dataShimmer || (isDisabledExpansion(item) && !clickableRow)"
                                          [class.rounded-0]="!boxView"
                                          [ngClass]="{'border-bottom height-mat-expansion-panel-header': !boxView}">
                <mat-panel-title>
                  <ng-container [ngTemplateOutlet]="sharedContentTemplate"
                                [ngTemplateOutletContext]="{ item: item, index: index}"></ng-container>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-container [ngSwitch]="getTemplateFromDetailsObj(expansionDetails, item) ? getTemplateFromDetailsObj(expansionDetails, item) : expansionTemplate">
                <ng-container *ngSwitchCase="'listOfEntries'">
                  <list-of-entries [item]="item"
                                   [index]="index"
                                   [detailsObj]="getDetailsObj(expansionDetails, item)"
                                   [boxView]="boxView"
                                   [expansionPanelData]="expansionPanelData"
                                   [isShimmeringDetails]="expansionDetailsDataShimmer"></list-of-entries>
                </ng-container>
                <ng-container *ngSwitchCase="'listOfColumnsAsKeyValue'">
                  <list-of-columns-as-key-value [item]="item"
                                                [index]="index"
                                                [detailsObj]="getDetailsObj(expansionDetails, item)"
                                                [boxView]="boxView"
                                                [expansionPanelData]="expansionPanelData"
                                                [isShimmeringDetails]="expansionDetailsDataShimmer"
                                                [showSeparator]="getDetailsObj(expansionDetails, item, 'showSeparator') ?? showSeparator"></list-of-columns-as-key-value>
                </ng-container>
                <ng-container *ngSwitchCase="'listOfDynamicColumns'">
                  <list-of-columns-as-key-value [item]="item"
                                                [detailsObj]="{data: getDataFromPath(item, getDetailsObj(expansionDetails, item, 'data.source'), index),
                                numberOfColumns: getDetailsObj(expansionDetails, item, 'numberOfColumns'),
                                lineBreakKeyValue: getDetailsObj(expansionDetails, item, 'lineBreakKeyValue'),
                                direction: getDetailsObj(expansionDetails, item, 'direction'),
                                key: getDetailsObj(expansionDetails, item, 'data.key'),
                                value: getDetailsObj(expansionDetails, item, 'data.value'),
                                keyClassName: getDetailsObj(expansionDetails, item, 'keyClassName'),
                                valueClassName: getDetailsObj(expansionDetails, item, 'valueClassName'),
                                containerWidth: getDetailsObj(expansionDetails, item, 'containerWidth')}"
                                                [boxView]="boxView"
                                                [showSeparator]="getDetailsObj(expansionDetails, item, 'showSeparator') ?? showSeparator"></list-of-columns-as-key-value>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <ng-container [ngTemplateOutlet]="getTemplateFromDetailsObj(expansionDetails, item) ? getTemplateFromDetailsObj(expansionDetails, item) : expansionTemplate"
                                [ngTemplateOutletContext]="{ item: item, index: index }"></ng-container>
                </ng-container>
              </ng-container>
            </mat-expansion-panel>
          </mat-accordion>

          <!-- Non-Accordion Section -->
          <div *ngIf="isDisabledExpansion(item)" [ngClass]="customListingClassName ? customListingClassName : 'basicListing w-100 d-flex'" [class.disable-mat-expansion]="!allAreDisabled && isDisabledExpansion(item)">
            <ng-container [ngTemplateOutlet]="sharedContentTemplate"
                          [ngTemplateOutletContext]="{ item: item, index: index}"></ng-container>
          </div>

          <div [ngStyle]="{'min-height.px':placeholderMinHeight}" class="example-custom-placeholder d-flex flex-column justify-content-center align-items-center" *cdkDragPlaceholder>
        <span class="foo-icon foo-customize-primary mt-3"></span>
        <span>{{'general.dragDropHere' | translate}}</span>
      </div>
    </div>

    <!-- Shared Content: Appears in both Accordion and Non-Accordion Sections -->
      <ng-template #sharedContentTemplate let-item='item' let-index='index'>

        <div class="info-cols">

          <div [style.flex-basis]="label.flexBasis + '%'"
               class="{{
                isFunction(label.classOptions) ? label.classOptions(item) :
                (isObject(label.classOptions) ? (label.classOptions.hasOwnProperty(item[label.value]) ? label.classOptions[item[label.value]] : ''):
                (isString(label.classOptions) ? label.classOptions : ''))
              }} col {{label.className}} w-100 transaction-info reportMobile my-md-0 my-1 listing-row-100"
               [ngClass]="{'flex-md-column align-items-start': label.secondRowValue}"
               *ngFor="let label of labels">
            <ng-container *ngIf="!staticOrdering && !apiOrdering">
              <div *ngIf="label.title" [attr.data-label]="label.value" class="table-label d-block d-md-none resp-label">
                {{ label.title | translate }}
              </div>
            </ng-container>
            <ng-container *ngIf="staticOrdering || apiOrdering">
              <div *ngIf=" (!label.type || ( label.type != 'date' && label.type != 'datetime')) && label.title"
                   [attr.data-order]="(!label.disableSorting) ? '' : null"
                   [attr.data-label]="label.value"
                   (click)="!firstLoadCriteria && apiOrdering && !label?.disableSorting ? sort(label) : ''"
                   [appSort]="data"
                   [groupSelector]="dataGroupSelector"
                   class="table-label d-block d-md-none resp-label">
                {{ label.title | translate }}
              </div>
              <div *ngIf="(label.type == 'date' || label.type == 'datetime') && label.title"
                   [appSort]="data"
                   [groupSelector]="dataGroupSelector"
                   [attr.data-order]="(!label.disableSorting) ? '' : null"
                   (click)="!firstLoadCriteria && apiOrdering && !label?.disableSorting ? sort(label) : ''"
                   [attr.data-label]="label.value"
                   data-type="date"
                   class="table-label d-block d-md-none resp-label">
                {{ label.title | translate }}
              </div>
            </ng-container>

              <div *ngIf="label.type !== 'actions'"
                   [class.shimmer-item]="dataShimmer && !label?.className?.includes('remove-auto-shimmer')"
                   class="d-block values-container">
                  <span *ngIf="label.type === 'boolean'" class="d-block">
                    {{ (item[label.value] == 1 ? "Yes" : "No") ? item[label.value] == 1 ?
                      ("general.yes" |
                          translate) : ("general.no" | translate) : "-" }}
                  </span>

                  <span *ngIf="label.type === 'translate'">
                      <span [title]="item[label.value] ? (item[label.value] | translate) : ''">
                        {{ item[label.value] ? (item[label.value] | translate) : "-" }}
                      </span>
                  </span>

                  <span *ngIf="label.type === 'async'">
                    <span [title]="item[label.value] ? (item[label.value] | async) : ''">
                        {{ item[label.value] ? (item[label.value] | async) : "-" }}
                    </span>
                  </span>

                  <span *ngIf="label.templateRef && getTemplate(label.templateRef)"
                        class="reportMobileValue overflow-visible d-block">
                      <ng-container *ngTemplateOutlet="getTemplate(label.templateRef); context: { item: item, label: label}">
                      </ng-container>
                  </span>

                  <span *ngIf="label.type == 'time'"
                        [class.date]="label.type === 'time'"
                        [title]="item[label.value] ? (item[label.value] | date: label.format || 'HH:mm:ss') : '' "
                        class="d-block">
                      {{ item[label.value] ? (item[label.value] | date: label.format || "HH:mm:ss") : "-" }}
                  </span>

                  <span *ngIf="label.type == 'date'"
                        [class.date]="label.type === 'date'"
                        [title]="item[label.value] ? (item[label.value] | date: label.format || 'dd MMM yyyy') : ''"
                        class="d-block">
                      {{ item[label.value] ? (item[label.value] | date: label.format || "dd MMM yyyy") : "-" }}
                  </span>

                  <span *ngIf="label.type == 'datetime'"
                        [class.date]="label.type === 'datetime'"
                        [title]=" item[label.value] ? (item[label.value] | date: label.format || 'dd MMM yyyy | HH:mm:ss') : ''"
                        class="d-block">
                      {{ item[label.value] ? (item[label.value] | date: label.format || "dd MMM yyyy | HH:mm:ss") : "-" }}
                  </span>

                  <!--number type handling in listing col-->
                  <span *ngIf="label.type == 'number'"
                        [title]="item[label.value] ? (label.format ? (item[label.value] | number: label.format) : (label.suffix ? (currencyService.getFormattedNumberBasedOnCurrency(item[label.value], item[label.suffix]) + ' ' + item[label.suffix]) : (item[label.value] | number:'1.2-2'))) : 0">
                      {{ item[label.value] ? (label.format ? (item[label.value] | number: label.format) : (label.suffix ? (currencyService.getFormattedNumberBasedOnCurrency(item[label.value], item[label.suffix]) + ' ' + item[label.suffix]) : (item[label.value] | number:'1.2-2'))) : 0 }}
                  </span>
                  <!-- /end number -->

                  <!--phone numberstring formatted-->
                  <span *ngIf="label.type == 'phone-number'"
                        [class.date]="label.type === 'phone-number'"
                        [title]="(item[label.value] || '-') | phoneNumber ">
                      {{ (item[label.value] || '-') | phoneNumber }}
                  </span>
                  <!-- /end number -->

                  <span *ngIf="label.type == 'status'"
                        [class.green]="item[label.value]"
                        [class.red]="!item[label.value]">
                      <span *ngIf="item[label.value]" class="custom-status">
                          {{ "team.active" | translate }}
                      </span>
                      <span *ngIf="!item[label.value]" class="custom-status">
                          {{ "team.inactive" | translate }}
                      </span>
                  </span>

                  <span *ngIf="label.type == 'image'" class="d-block">
                      <div [ngStyle]="{'background-image': 'url(' + (item | stringobject: label.value) + ')'}"
                           class="{{ label.imageClassName ? label.imageClassName : 'imageContainer' }}">
                      </div>
                  </span>

                  <span *ngIf="!label.type && !label.templateRef"
                        [class.fs-14]="label.secondRowValue"
                        [title]="label.formatter ? label.formatter((item | stringobject: label.value)) : ((item | stringobject: label.value) ? (item | stringobject: label.value) : '-')"
                        class="d-block">
                           {{label.formatter ? label.formatter((item | stringobject: label.value | translate)) : ((item | stringobject: label.value) ? (item | stringobject: label.value | translate) : "-") }}
                  </span>

                  <span *ngIf="label.secondRowValue"
                        class="label-row2 d-block {{label.secondRowClass ? label.secondRowClass : ''}}">
                      {{label.secondRowClass && label.secondRowClass == 'add-hashtag' ? '#' : ''}}{{(item | stringobject: label.secondRowValue) ? (item | stringobject: label.secondRowValue) : "-"}}
                  </span>
              </div>
              <div class="col account-action-box account-action-box-desktop" *ngIf="label.type === 'actions'">
              <div class="rightBtnDesign" *ngIf="getTemplate(label.templateRef)">
                <ng-container *ngTemplateOutlet="getTemplate(label.templateRef); context: { item: item, label: label}"></ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="actions-cols">
          <div #actions
               class="col listing-row-100 d-flex justify-content-end align-items-center {{ (!isEnabledViewDetails(item) && !allAreDisabledViewDetails) ? 'pr-40' : ''}}"
               *ngIf="btnList?.length>0 || !allAreDisabledViewDetails">
            <div *ngFor="let btnItem of btnList" class="rightBtnDesign">
              <ng-container *ngIf="btnItem?.templateRef; else anotherType">
                <ng-container *ngIf="getTemplate(btnItem.templateRef)">
                  <ng-container *ngTemplateOutlet="getTemplate(btnItem.templateRef); context: { item: item }"></ng-container>
                </ng-container>
              </ng-container>
              <ng-template #anotherType>
                <button [disabled]="dataShimmer || (has(btnItem, 'isDisabled') && (isFunction(btnItem?.isDisabled) ? btnItem.isDisabled(item, permissions) : btnItem?.isDisabled))"
                        (click)="$event.stopPropagation();btnFunctionCalled(btnItem.fctName, item)"
                        *ngIf="!has(btnItem, 'isShown') ||  (isFunction(btnItem?.isShown) ? btnItem.isShown(item, permissions) :  btnItem?.isShown)"
                        class="{{btnItem.className}}">
                  <img *ngIf="btnItem.imagePath"
                       src="{{btnItem.imagePath}}"
                       class="svg-color-filter"
                       [alt]="btnItem.imagePath"/>
                  <foo-icon *ngIf="btnItem.icon" [icon]="isString(btnItem.icon)?'foo-'+btnItem.icon:btnItem.icon" [tooltip]="btnItem?.tooltip"></foo-icon>

                  <span *ngIf="btnItem.title">{{btnItem.title | translate}}</span>
                </button>
              </ng-template>
            </div>
            <i *ngIf="!dataShimmer && isEnabledViewDetails(item)" (click)="viewDetails(item)">
              <span class="foo-icon foo-arrow-right-light pointer-cursor align-middle"></span> </i>
          </div>
        </div>
      </ng-template>

      <div *ngIf="data?.length === 0 && !dataShimmer">
        <framework-no-data [params]="noDataFoundParams"></framework-no-data>
      </div>
  </div>
</div>
<div class="row mt-20" *ngIf="pagination != null">
    <pagination-controls (pageChange)="getData($event)" [maxSize]="9" [directionLinks]="true" [autoHide]="true"
        [responsive]="true" previousLabel="" nextLabel="" [id]="pagination_id">
    </pagination-controls>
</div>
