<form [formGroup]="form" autocomplete="off" (ngSubmit)="update()" formValidation>

  <div class="row">
    <div class="col-12 col-sm-6 col-md-5 col-lg-5 col-xl-4">
      <div class="app-info-img">
        <img class="w-100"  src="/assets/img/app-info-document-types.svg" alt="">
      </div>
    </div>

    <div class="col-12 col-sm-6 col-md-7 col-lg-6 col-xl-5 mt-1 pl-0">

      <div class="form-group">
        <framework-uploader [formControlName]="'mainIcon'" [fileSize]="8"
          [extensions]="extensions" [uploadNote]="uploadNote" [showProgress]="true" [fileType]="'image'"
          [labelInput]="'shared-component.appInfo.documentTypes.mainicon'" [hasEditPerminssion]="true"
          [hasDeletePerminssion]="false" [hasDownloadPerminssion]="false">
        </framework-uploader>
      </div>

      <div class="form-group">
        <label class="form-label">{{ "shared-component.appInfo.documentTypes.introTitle" | translate }}*</label>

        <input class="form-control"
          placeholder="{{'shared-component.appInfo.documentTypes.introTitle-placeholder' | translate}}"
          formControlName="introTitle" type="text" [(ngModel)]="data['document_screen']['intro']['title']" />

        <small
          *ngIf="(form.get('introTitle').touched || form.get('introTitle').dirty) && form.get('introTitle').hasError('required')"
          class="text-danger">
          {{"validations.required_field" | translate}}
        </small>

        <small
          *ngIf="(form.get('introTitle').touched || form.get('introTitle').dirty) && form.get('introTitle').hasError('maxwords')"
          class="text-danger">
          {{"validations.maxWords" | translate: { maxnumber: 6 } }}
        </small>

      </div>

      <div class="form-group">
        <label class="form-label">{{ "shared-component.appInfo.documentTypes.introText" | translate }}*</label>

        <textarea class="form-control mb-9" formControlName="introText" type="text"
          placeholder="{{'shared-component.appInfo.documentTypes.introText-placeholder' | translate}}" rows="7"
          [maxlength]="maxChars" [(ngModel)]="data['document_screen']['intro']['text']"></textarea>

        <span class="form-label">{{ form.controls['introText'].value ? form.controls['introText'].value.length : '-' }}/{{ maxChars }}
          {{'shared-component.appInfo.characters'
          |
          translate}}</span>

        <br>
        <small
          *ngIf="(form.get('introText').touched || form.get('introText').dirty) && form.get('introText').hasError('required')"
          class="text-danger">
          {{"validations.required_field" | translate}}
        </small>

        <small
          *ngIf="(form.get('introText').touched || form.get('introText').dirty) && form.get('introText').hasError('maxwords')"
          class="text-danger">
          {{"validations.maxWords" | translate: { maxnumber: 50 } }}
        </small>
      </div>

      <ng-container *ngIf="data['document_screen'].documents">
        <ng-container *ngFor="let document of data['document_screen'].documents; let indexOfelement=index;">

          <div class="form-group">
            <framework-uploader [formControlName]="'icon_' + document.name" [fileSize]="8"
              [extensions]="extensions" [uploadNote]="uploadNote" [showProgress]="true"
              [fileType]="'image'" [labelInput]="'shared-component.appInfo.documentTypes.icon_' + indexOfelement"
              [hasEditPerminssion]="true" [hasDeletePerminssion]="false" [hasDownloadPerminssion]="false">
            </framework-uploader>
          </div>

          <div class="form-group">
            <label class="form-label">{{ "shared-component.appInfo.documentTypes.title_" + indexOfelement | translate
              }}*</label>

            <input class="form-control"
              placeholder="{{'shared-component.appInfo.documentTypes.title-placeholder-' + indexOfelement | translate}}"
              [formControlName]="'title_' + indexOfelement" type="text" [(ngModel)]="document.title" />

            <small
              *ngIf="(form.get('title_' + indexOfelement).touched || form.get('title_' + indexOfelement).dirty) && form.get('title_' + indexOfelement).hasError('required')"
              class="text-danger">
              {{"validations.required_field" | translate}}
            </small>

            <small
              *ngIf="(form.get('title_' + indexOfelement).touched || form.get('title_' + indexOfelement).dirty) && form.get('title_' + indexOfelement).hasError('maxwords')"
              class="text-danger">
              {{"validations.maxWords" | translate: { maxnumber: 6 } }}
            </small>

          </div>

          <div class="form-group">
            <label class="form-label">{{ "shared-component.appInfo.documentTypes.text_" + indexOfelement | translate
              }}</label>

            <textarea class="form-control mb-9" [formControlName]="'text_' + indexOfelement" type="text"
              placeholder="{{'shared-component.appInfo.documentTypes.text-placeholder-' + indexOfelement | translate}}"
              rows="7" [maxlength]="maxChars" [(ngModel)]="document.text"></textarea>

            <span class="form-label">{{ form.controls['text_' + indexOfelement].value.length }}/{{ maxChars }}
              {{'shared-component.appInfo.characters' |  translate}}*</span>

            <br>
            <small
              *ngIf="(form.get('text_' + indexOfelement).touched || form.get('text_' + indexOfelement).dirty) && form.get('text_' + indexOfelement).hasError('required')"
              class="text-danger">
              {{"validations.required_field" | translate}}
            </small>

            <small
              *ngIf="(form.get('text_' + indexOfelement).touched || form.get('text_' + indexOfelement).dirty) && form.get('text_' + indexOfelement).hasError('maxwords')"
              class="text-danger">
              {{"validations.maxWords" | translate: { maxnumber: 50 } }}
            </small>
          </div>

        </ng-container>
      </ng-container>

      <div class="form-group mt-5">
        <button type="submit" [disabled]="form.pristine" *ngIf="permissions?.can_update" class="foo-btn-primary btn-main-site min-w170 foo-btn-center">{{
          "shared-component.appInfo.documentTypes.submit" | translate}}</button>
      </div>

    </div>
  </div>
</form>
