import {Pipe, PipeTransform} from '@angular/core';
import _ from 'lodash';
import {TokenStorage} from '../services/token-storage.service';

@Pipe({
    name: 'stringobject'
})
export class StringobjectPipe implements PipeTransform {

    curLang = this.tokenStorage.getCurrentLanguage();
    valueToReturn: any;
    constructor(private tokenStorage: TokenStorage) {
    }
    transform(value: any, args?: any): any {
        if (!_.isEmpty(value)) {
            this.valueToReturn = value;
            let argsSplitted = args.split('.');
            if(argsSplitted.length == 1){
                this.valueToReturn = this.valueToReturn[argsSplitted[0]];
            }
            else {
                for (let i = 0; i < argsSplitted.length; i++) {
                    if (argsSplitted[i].startsWith("translations")) {
                        this.valueToReturn = this.valueToReturn && this.valueToReturn?.translations && this.valueToReturn?.translations[this.curLang] ? this.valueToReturn.translations[this.curLang] : '';
                    } else {
                        if (this.valueToReturn) this.valueToReturn = this.valueToReturn[argsSplitted[i]];
                    }
                }
            }
            return this.valueToReturn;
        }
    }
}
