import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {TokenStorage} from "foo-framework";

@Component({
  selector: 'app-message-popup',
  templateUrl: './message-popup.component.html',
  styleUrls: ['./message-popup.component.scss']
})
export class MessagePopupComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private tokenStorage: TokenStorage
  ) { }
  curLang : string;
  ngOnInit(): void {
    this.curLang = this.tokenStorage.getCurrentLanguage();
  }

}
