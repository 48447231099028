<button mat-button mat-dialog-close class="close-icon">
    <span class="foo-icon foo-close-dark"></span>
</button>
<div mat-dialog-title>
    <p class="confirm-text">{{data.title | translate }}</p>
</div>
<mat-dialog-content [innerHTML]="data.text" class="mt-10">
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close class="foo-btn-outline-secondary mr-0">{{'general.cancel' | translate}}</button>
    <button mat-button [class]="data.buttonClass ? data.buttonClass : 'foo-btn-primary'" class="ml-4 mr-3"
        (click)="onSubmit()">{{data.button ? data.button : ('general.confirm' | translate)}}</button>
</mat-dialog-actions>
