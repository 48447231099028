<button class="close-icon p-0" mat-dialog-close><span class="foo-icon foo-close-dark"></span></button>
<div mat-dialog-title>
  <p>{{ data.dialogTitle | translate }}</p>
</div>


<mat-dialog-content formValidationContainer class="mt-10">
  <form (ngSubmit)="onSubmit()" [formGroup]="form" formValidation autocomplete="off">

    <div class="form-group" *ngIf="data.templateHref">
      <ng-container>
        <label class="container-label">{{ data.downloadTemplateTitle | translate }}</label>
        <a class="btn downloadBtn"
           [href]="data.templateHref"
           target="_self"
           title="{{data.downloadTemplateTitle | translate }}">
          {{ data.downloadTemplatePlaceholder | translate }}
          <span class="foo-icon foo-download-primary"></span>
        </a>
      </ng-container>
    </div>

    <ng-container>
      <framework-uploader
        [extensions]="data.uploaderExtensions"
        [fileType]="data.uploaderFileType"
        [formControlName]="data.uploaderFormControlName"
        [labelInput]="data.uploaderLabelInput | translate"
        [returnAsFile]="true"
        [showProgress]="data.uploaderShowProgress"
      ></framework-uploader>

      <div class="pt-8">
        <button [disabled]="form.pristine"
                class="foo-btn-primary foo-btn-center"
                type="submit">
          {{ data.submitButtonLabel | translate }}
        </button>
      </div>
    </ng-container>
  </form>
</mat-dialog-content>
