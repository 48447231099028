import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class AlertService {
    initAlert: EventEmitter<any> = new EventEmitter();
  constructor() { }

  emitAlert(info) {
    this.initAlert.emit(info);
  }
  getAlert() {
    return this.initAlert;
  }
  
}