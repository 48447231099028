import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDateParserFormatter, NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { get } from 'lodash';
import moment from 'moment';

@Component({
  selector: 'framework-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DateRangePickerComponent,
    },
  ],
})
export class DateRangePickerComponent implements ControlValueAccessor {
  @Input() max = null;

  @Input() min = null;

  @Input() format = 'DD/MM/YYYY';

  @ViewChild('d') input: NgbInputDatepicker;
  @ViewChild('myRangeInput') myRangeInput: ElementRef;

  disabled: boolean = false;
  hoveredDate: any;
  fromDate: any;
  toDate: any;

  onChange = (value) => {};

  onTouched = () => {};

  touched = false;

  constructor(
     private renderer: Renderer2,
     private dateParserFormatter: NgbDateParserFormatter

    ) {}

  dateStructToDate(dateStruct): Date {
    return moment()
      .year(dateStruct.year)
      .month(dateStruct.month - 1)
      .date(dateStruct.day)
      .toDate();
  }


  isHovered(dateStruct): boolean {
    const date = this.dateStructToDate(dateStruct);
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      moment(date).isAfter(this.fromDate) &&
      moment(date).isBefore(this.hoveredDate)
    );
  }

  isInside(dateStruct): boolean {
    const date = this.dateStructToDate(dateStruct);
    return (
      moment(date).isAfter(this.fromDate) && moment(date).isBefore(this.toDate)
    );
  }

  isFrom(dateStruct): boolean {
    const date = this.dateStructToDate(dateStruct);
    return moment(date).isSame(this.fromDate, 'day');
  }

  isTo(dateStruct): boolean {
    const date = this.dateStructToDate(dateStruct);
    return moment(date).isSame(this.toDate, 'day');
  }

  writeValue(obj: any): void {
    this.fromDate = get(obj, 'from') || null;
    this.toDate = get(obj, 'to') || null;

    if (this.myRangeInput) {
      let parsed = '';
      if (this.fromDate) {
        parsed += this.dateParserFormatter.format(this.fromDate);
      }
      if (this.toDate) {
        parsed += ' - ' + this.dateParserFormatter.format(this.toDate);
      }
      this.renderer.setProperty(this.myRangeInput.nativeElement, 'value', parsed);
    }
    this.onChange({
      from: this.fromDate,
      to: this.toDate
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onDateSelection(dateStruct: NgbDateStruct) {
    let parsed = '';
    const date = this.dateStructToDate(dateStruct);
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (
      this.fromDate &&
      !this.toDate &&
      moment(date).isAfter(this.fromDate)
    ) {
      this.toDate = date;
      this.input.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    if (this.fromDate) {
      parsed += this.dateParserFormatter.format(this.fromDate);
    }
    if (this.toDate) {
      parsed += ' - ' + this.dateParserFormatter.format(this.toDate);
    }

    this.renderer.setProperty(this.myRangeInput.nativeElement, 'value', parsed);
    this.markAsTouched();
    this.onChange({
      from: this.fromDate,
      to: this.toDate
    });
  }
}
