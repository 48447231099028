import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import currenciesAsJsonFile from './Currencies.json';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  currencies = [];

  constructor(private decimalPipe: DecimalPipe) {
    this.currencies = currenciesAsJsonFile;
  }

  getNumberOfDecimalPoints(currency: string) {
    if (currency) return this.currencies?.find((item: any) => (item.currencyCode)?.toLowerCase() === currency?.toLowerCase())?.numberOfDecimalPoints;
  }

  getFormattedNumberBasedOnCurrency(balance, currency) {
    let balanceNumber = balance || 0;
    let numberOfDecimalPoints = 0;
    if (currency) numberOfDecimalPoints = this.getNumberOfDecimalPoints(currency);
    if (!numberOfDecimalPoints) numberOfDecimalPoints = 2;
    return this.decimalPipe.transform(balanceNumber, ('.' + numberOfDecimalPoints + '-' + numberOfDecimalPoints));
  }

}
