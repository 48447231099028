import {Injectable, TemplateRef} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ListingTemplatesService {

  templates: {
    [key: string]: TemplateRef<any>
  };

  constructor() {
    this.templates = {};
  }

  addTemplate(id: string, ref: TemplateRef<any>) {
    this.templates[id] = ref;
  }

  getTemplate(id: string): TemplateRef<any> {
    return this.templates[id] || null;
  }
}
