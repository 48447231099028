import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FooFrameworkModule, MaterialModule, PipesModule} from "foo-framework";
import { SharedModulesModule as FooSharedModulesModule, TransactionDetailsService } from 'foo-framework/shared-modules';

import { DmpTransactionDetailsService } from './services/dmp-transaction-details.service';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import {DmpDataServicesService} from "./services/dmp-data-services.service";
import { RefundDialogComponent } from './refund-dialog/refund-dialog.component';

const components = [
  ResetPasswordComponent,
  RefundDialogComponent
];

@NgModule({
  declarations: [
    ...components,
  ],
  imports: [
    CommonModule,
    FooSharedModulesModule,
    FooSharedModulesModule.forChild(),
    MaterialModule,
    FooFrameworkModule,
    PipesModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [
    FooSharedModulesModule,
    ...components
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: TransactionDetailsService, useClass: DmpTransactionDetailsService},
    ]
})
export class SharedModulesModule {
  // static forRoot(): ModuleWithProviders<SharedModulesModule> {
  //   return {
  //     ngModule: SharedModulesModule,
  //     providers: [
  //       DmpDataServicesService
  //     ]
  //   };
  // }
  //
  // static forChild(): ModuleWithProviders<SharedModulesModule> {
  //   return {
  //     ngModule: SharedModulesModule,
  //     providers: [
  //       DmpDataServicesService
  //     ]
  //   };
  // }
}
