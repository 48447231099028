
<div class="row">
    <!-- Tab panes -->
    <div class="tab-content clearfix">
      <div class="tab-pane active">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="urlSafe">
            <iframe #iframe [src]="urlSafe" (load)="onload($event)" [height]="webviewHeight" scrolling="no" width="100%"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>


  <ng-template #overlayTemplate>

  </ng-template>
