import {Component, EventEmitter, forwardRef, Injector, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {FooTemplatesService} from '../../foo-templates.service';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {FooControlComponent} from '../foo-control/foo-control.component';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FooSelectBoxComponent),
  multi: true
};

@Component({
  selector: 'foo-select-box',
  templateUrl: './foo-select-box.component.html',
  styleUrls: ['./foo-select-box.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class FooSelectBoxComponent extends FooControlComponent implements OnInit {

  constructor(
    private fooTemplateService: FooTemplatesService,
    injector: Injector
  ) {
    super(injector);
  }

  setDisabledState(isDisabled: boolean): void {
    this.templateOptions.readonly = isDisabled;
    this.formControlDirective?.valueAccessor?.setDisabledState(isDisabled);
  }

  ngOnInit(): void {
  }

  getTemplate(id: string): TemplateRef<any> {
    return this.fooTemplateService.getTemplate(id);
  }

  get keyProp(): string {
    return this.templateOptions?.keyProp || 'key';
  }

  get titleProp(): string {
    return this.templateOptions?.titleProp || 'name';
  }

  get placeholder(): string {
    return this.templateOptions?.placeholder || 'general.select';
  }

  get noSelectionOptionText(): string {
    return this.templateOptions?.noSelectionOptionText || 'general.nothingSelected';
  }


  get optionTemplate(): string {
    return this.templateOptions?.optionTemplate || 'selectDefault';
  }

  get labelTemplate(): string {
    return this.templateOptions?.labelTemplate || 'selectDefault';
  }


}
