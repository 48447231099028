import {Component, EventEmitter, Input, OnInit, Output, inject} from '@angular/core';
import {ITabs} from '../base-listings/base-listings.component';
import {PermissionsService} from 'foo-framework';

@Component({
  selector: 'foo-tabs',
  templateUrl: './foo-tabs.component.html',
  styleUrl: './foo-tabs.component.scss'
})
export class FooTabsComponent implements OnInit {

  @Input() activeTab: string;
  @Input() tabs: ITabs[];
  @Input() permissions;

  @Output() activeTabChange = new EventEmitter<any>();
  protected permissionService = inject(PermissionsService);

  constructor() {
    if (!this.permissions) {
      this.permissions = this.permissionService.permissions;
    }
  }

  ngOnInit(): void {
  }

  tabChange(key) {
    this.activeTabChange.emit(key);
  }

  getTab(name: string) {
    return this.tabs.find(t => t.key === name);
  }

  checkIfTabHasPermission(tab: string, action = 'can_read'): boolean {
    let tabObj: any = this.getTab(tab);
    if (!tabObj) {
      return false;
    }
    if(typeof tabObj?.permissions === 'function'){
      return tabObj.permissions(tab);
    }else {
      return tabObj?.permissions ? this.permissions?.[tabObj.permissions]?.[action] : this.permissions?.[action];
    }
  }
  checkFunctionVal(val: any, key: string): any {
    if (typeof val === 'function')
      return val(key);
    else
      return val;
  }
}
