import {Directive, ElementRef, Input, OnDestroy, SimpleChanges} from '@angular/core';
import { Subscription, debounceTime, fromEvent, tap } from 'rxjs';



@Directive({
  selector: '[popupTinymceEditor]'
})
export class PopupTinymceEditorDirective implements OnDestroy {
  matDialogContent = document.querySelector('.mat-dialog-content') as HTMLElement;
  matDialogContainer = document.querySelector('.mat-dialog-container') as HTMLElement;

  subscribtion: Subscription = new Subscription();


  openedMenus: any = [];

  constructor(
    private el: ElementRef
  ) {
    if (this.matDialogContent && this.matDialogContainer) {
    this.subscribtion.add(fromEvent(this.matDialogContent, 'scroll')
      .pipe(
        tap(() => {
          this.closeOpenedMenus();
        }),
        debounceTime(300)
      )
      .subscribe((e: Event) => {
        this.reopenClosedMenus()
       }));
      this.subscribtion.add(fromEvent(this.matDialogContainer, 'scroll')
      .pipe(
        tap(() => {
          this.closeOpenedMenus();
        }),
        debounceTime(300)
      )
      .subscribe((e: Event) =>
        this.reopenClosedMenus()
      ));
    }
  }

  ngOnDestroy(): void {
    this.subscribtion.unsubscribe();
  }

  reopenClosedMenus(){
    this.openedMenus.forEach(item => {
      if (this.isChildInsideView(this.matDialogContent, item)) {
        item.click();
      }
    });
    this.openedMenus = [];
  }

  closeOpenedMenus() {
    if (!this.openedMenus?.length) {
    this.openedMenus = this.el.nativeElement.querySelectorAll('editor [aria-owns]');
    this.openedMenus.forEach(item => {
         item.click();
    });
   }
  }

  isChildInsideView(parent, child) {
    var parentBox = parent.getBoundingClientRect();
    var childBox = child.getBoundingClientRect();

    if (
      childBox.top > parentBox.top &&
      childBox.bottom < parentBox.bottom) {
      return true;
    }
    return false;
  }

}
