import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ManageBreadcrumbService } from '../../services/manage-breadcrumb.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit, OnDestroy {
  constructor(
    private _location: Location,
    private manageBreadcrumb: ManageBreadcrumbService
  ) {
    this.manageBreadcrumb.isBreadcrumbShown = false;
  }

  ngOnDestroy(): void {
    this.manageBreadcrumb.isBreadcrumbShown = true;
  }

  ngOnInit(): void {}

  goBack() {
    this._location.back();
  }
}
