import { Directive, ElementRef, Inject, Input, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
@Directive({
  selector: '[InputUnit]'
})
export class InputUnitDirective implements OnInit {

  @Input('InputUnit')
  set InputUnit(value) {
    this._unit = value;
    if (this.unitDom) {
      if (this.unit) {
        this.unitDom.innerHTML = this.unit;
        const unitWith = this.unitDom.offsetWidth;
        if(this.isrtl()){
          this.elementRef.nativeElement.style.paddingLeft = `${unitWith + 20}px`;
        }else{
          this.elementRef.nativeElement.style.paddingRight = `${unitWith + 20}px`;
        }
      } else {
        if(this.isrtl()){
          this.elementRef.nativeElement.style.paddingLeft = this.initialPaddingLeft;
        }else{
          this.elementRef.nativeElement.style.paddingRight = this.initialPaddingRight;
        }
      }
    }
  }

  @Input('unitClasses')
  set unitClasses(value: string) {
    this._unitClasses = value || '';
    if (this.unitDom) {
      this.unitDom.className = 'min-righticon-form ' + this.unitClasses;
    }
  }


  get unit(): string {
    return this._unit;
  }

  get unitClasses(): string {
    return this._unitClasses;
  }

  _unit: string;
  _unitClasses: string = '';

  unitDom: any;

  initialPaddingRight: any;
  initialPaddingLeft: any;

  constructor(
    @Inject(DOCUMENT) private dom,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
  }

  isrtl() {
    const dir = this.dom.querySelector('body').dir == 'rtl' ? 'rtl' : 'ltr';
    return dir == 'rtl';
  }

  ngOnInit(): void {
    // this creates the wrapping div
    const div = this.renderer.createElement('div');
    div.classList.add('min-righticon-container');

    // this creates the span for unit
    const unit = this.renderer.createElement('span');
    unit.className = 'min-righticon-form ' + this.unitClasses;
    unit.innerHTML = this.unit;

    // this is the element to wrap
    const el = this.elementRef.nativeElement;
    const parent = el.parentNode;
    this.renderer.insertBefore(parent, div, el);

    this.renderer.appendChild(div, el);
    this.renderer.appendChild(div, unit);

    this.unitDom = unit;
    if (this.isrtl()) {
      this.initialPaddingLeft = el.style.paddingLeft;
      if (this.unit) {
        const unitWith = unit.offsetWidth;
        el.style.paddingLeft = `${unitWith + 20}px`;
      }
    } else {
      this.initialPaddingRight = el.style.paddingRight;
      if (this.unit) {
        const unitWith = unit.offsetWidth;
        el.style.paddingRight = `${unitWith + 20}px`;
      }
    }
  }

}
