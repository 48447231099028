import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {FooFrameworkModule, MaterialModule, PipesModule} from 'foo-framework';
import {SharedModulesModule} from 'foo-framework/shared-modules';
import { SelfOnboardingWebviewComponent } from './self-onboarding-webview/self-onboarding-webview.component'
import { SelfOnboardingSignupComponent} from './self-onboarding-signup/self-onboarding-signup.component';
import { WebformsSharedModule } from "foo-framework/modules/webforms-shared";

const components = [
  SelfOnboardingWebviewComponent,
  SelfOnboardingSignupComponent,
];

@NgModule({ declarations: [
        ...components,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [
        ...components
    ], imports: [TranslateModule,
        CommonModule,
        MaterialModule,
        SharedModulesModule,
        PipesModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        FooFrameworkModule,
        WebformsSharedModule], providers: [
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class SelfOnboardingModule {

}
