import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {CUSTOM_ELEMENTS_SCHEMA, Inject, ModuleWithProviders, NgModule, Optional} from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {FooFrameworkModule, PipesModule} from 'foo-framework';
import {NewsBoxComponent} from './news-box/news-box.component';
import {DateRangePickerComponent} from './date-range-picker/date-range-picker.component';
import {DeleteDialogComponent} from './delete-dialog/delete-dialog.component';
import {ListingComponent} from './listing/listing.component';
import {ListingBootstrapComponent} from './listing-bootstrap/listing-bootstrap.component';
import {SearchBarComponent} from './search-bar/search-bar.component';
import {StarRatingComponent} from './star-rating/star-rating.component';
import {TranslationInputComponent} from './translation-input/translation-input.component';
import {UploaderComponent} from './uploader/uploader.component';
import {InfoDialogComponent} from './info-dialog/info-dialog.component';
import {MaterialModule} from 'foo-framework';
import {SwiperModule} from 'swiper/angular';
import {BreadcrumbModule} from 'xng-breadcrumb';
import {EditorModule} from '@tinymce/tinymce-angular';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ListingTemplateDirective} from './listing/listing-template.directive';
import {ListingTemplatesService} from './listing/listing-templates.service';
import {NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { BoxesListingComponent } from './boxes-listing/boxes-listing.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgxPaginationModule } from 'ngx-pagination';
import { ValidationService } from './services/validation.service';
import { TranslationService } from './services/translation.service';
import { CountryService } from './services/country.service';
import {FooPopupComponent} from './foo-popup/foo-popup.component';

import {FooTemplateDirective} from './foo-template.directive';
import {FooTemplatesService} from './foo-templates.service';
import {FooPopupService} from './foo-popup/foo-popup.service';
import {
  ControlValueAccessorConnectorComponent
} from './control-value-accessor-connector.component';
import {MessagePopupComponent} from './message-popup/message-popup.component';
import {GroupListingComponent} from './group-listing/group-listing.component';
import {NoDataComponent} from './no-data/no-data.component';
import {
  FooSearchableSelectComponent
} from './foo-searchable-select/foo-searchable-select.component';
import { FirstLoadComponent } from './first-load/first-load.component';

import {CountriesListComponent} from './shared/countries/countries-list/countries-list.component';
import {GeneralSettingsComponent} from './shared/general-settings/general-settings.component';
import {AddCountryComponent} from './shared/countries/add-country/add-country.component';
import {AddStep1DoneFormComponent} from './shared/app-info-settings/add-step1-done-form/add-step1-done-form.component';
import {AddDocumentTypesFormComponent} from './shared/app-info-settings/add-document-types-form/add-document-types-form.component';
import {AppInfoSettingsComponent} from './shared/app-info-settings/app-info-settings.component';
import {EditStepsFormComponent} from './shared/countries/country-details/edit-steps-form/edit-steps-form.component';
// import {EditOneFieldChallengeConfigComponent} from
// '../modules/idv-configuration/edit-one-field-challenge-config/edit-one-field-challenge-config.component';
import {AddStep2DoneFormComponent} from './shared/app-info-settings/add-step2-done-form/add-step2-done-form.component';
import {CountryDetailsComponent} from './shared/countries/country-details/country-details.component';
import {AddIntroductionFormComponent} from './shared/app-info-settings/add-introduction-form/add-introduction-form.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {
  LeftMenuNavigatorComponent
} from './left-menu-navigator/left-menu-navigator.component';
import {FileIconComponent} from './file-icon/file-icon.component';
import { BoxesFilterComponent } from './boxes-filter/boxes-filter.component';
import {ListSortingComponent} from './list-sorting/list-sorting.component';
// import {CustomerTopBarComponent} from './customer-top-bar/customer-top-bar.component';
// import {UpdateCustomerStatusComponent} from './customer-top-bar/update-customer-status/update-customer-status.component';
// import {EditProfilePictureComponent} from './customer-top-bar/edit-profile-picture/edit-profile-picture.component';
import { TransactionDetailsComponent } from './transaction-details/transaction-details.component';
import { TransactionDetailsService } from './services/transaction-details.service';
import { DisplayChallengesDialogComponent } from './shared/display-challenges-dialog/display-challenges-dialog.component';
import {FooCheckboxComponent} from './controls/foo-checkbox/foo-checkbox.component';
import {FooConsentComponent} from './controls/foo-consent/foo-consent.component';
import {FooControlComponent} from './controls/foo-control/foo-control.component';
import {FooControlErrorsComponent} from './controls/foo-control-errors/foo-control-errors.component';
import {FooControlLabelComponent} from './controls/foo-control-label/foo-control-label.component';
import {FooDatepickerComponent} from './controls/foo-datepicker/foo-datepicker.component';
import {FooFileComponent} from './controls/foo-file/foo-file.component';
import {FooInputComponent} from './controls/foo-input/foo-input.component';
import {FooMobileNumberComponent} from './controls/foo-mobile-number/foo-mobile-number.component';
import {FooNumberComponent} from './controls/foo-number/foo-number.component';
import {FooRadiolistComponent} from './controls/foo-radiolist/foo-radiolist.component';
import {FooSelectBoxComponent} from './controls/foo-select-box/foo-select-box.component';
import {FooTextareaComponent} from './controls/foo-textarea/foo-textarea.component';
import { DisplayAsHtmlComponent } from './display-as-html/display-as-html.component';
import {FooTimepickerComponent} from './controls/foo-timepicker/foo-timepicker.component';
import { FileUploadFormComponent } from './file-upload-form/file-upload-form.component';
import { DialogService } from './services/dialog.service';
import { SendOldValuesService } from './services/send-old-values.service';
import {FooDatetimepickerComponent} from './controls/foo-datetimepicker/foo-datetimepicker.component';
import {NgxMatDatetimePickerModule, NgxMatNativeDateModule} from '@angular-material-components/datetime-picker';
import { DynamicListingComponent } from './dynamic-listing/dynamic-listing-component/dynamic-listing.component';
import { ListOfEntriesComponent } from './dynamic-listing/templates/list-of-entries/list-of-entries.component';
import { ListOfColumnsAsKeyValueComponent } from './dynamic-listing/templates/list-of-columns-as-key-value/list-of-columns-as-key-value.component';
import { DynamicListingService } from './services/dynamic-listing.service';
import { FooColorpickerComponent } from './controls/foo-colorpicker/foo-colorpicker.component';
import { MAT_COLOR_FORMATS, NgxMatColorPickerModule, NGX_MAT_COLOR_FORMATS } from '@angular-material-components/color-picker';
import { TooltipMenuComponent } from './tooltip-menu/tooltip-menu.component';
import { PageButtonsComponent } from './page-buttons/page-buttons.component';
import { PageTopComponent } from './page-top/page-top.component';

import {
  ShowHidePasswordComponent,
  ShowHideInputDirective,
  ShowHideStatusDirective,
  ShowHideTriggerDirective,
} from 'ngx-show-hide-password';
import { FooPaginatedListingDialogComponent } from './foo-paginated-listing-dialog/foo-paginated-listing-dialog.component';
import { APP_COMPONENTS_CONFIG, AppComponentsConfig, FooSharedModulesComponentsConfigService } from './services/foo-shared-modules-components-config.service';
import { FooPaginatedSelectComponent } from './controls/foo-paginated-select/foo-paginated-select.component';
import { FooPaginatedSelectDialogComponent } from './foo-paginated-select-dialog/foo-paginated-select-dialog.component';
import { FooTagsInputComponent } from './controls/foo-tags-input/foo-tags-input.component';
import { SelectablePropertyViewerComponent } from './selectable-property-viewer/selectable-property-viewer.component';
import {FooIconComponent} from './foo-icon/foo-icon.component';
import { CountUpComponent } from './count-up/count-up.component';
import {FooTabsComponent} from './foo-tabs/foo-tabs.component';


const components = [
  NewsBoxComponent,
  DateRangePickerComponent,
  DeleteDialogComponent,
  CountUpComponent,
  InfoDialogComponent,
  ListingComponent,
  ListingTemplateDirective,
  ListingBootstrapComponent,
  SearchBarComponent,
  StarRatingComponent,
  TranslationInputComponent,
  UploaderComponent,
  BoxesListingComponent,
  FooPopupComponent,
  FooTemplateDirective,
  ControlValueAccessorConnectorComponent,
  MessagePopupComponent,
  GroupListingComponent,
  NoDataComponent,
  FooSearchableSelectComponent,
  FirstLoadComponent,
  AddCountryComponent,
  CountriesListComponent,
  CountryDetailsComponent,
  EditStepsFormComponent,
  AppInfoSettingsComponent,
  AddDocumentTypesFormComponent,
  AddIntroductionFormComponent,
  AddStep1DoneFormComponent,
  AddStep2DoneFormComponent,
  GeneralSettingsComponent,
  LeftMenuNavigatorComponent,
  FileIconComponent,
  BoxesFilterComponent,
  ListSortingComponent,
  TransactionDetailsComponent,
  FooCheckboxComponent,
  FooConsentComponent,
  FooControlComponent,
  FooControlErrorsComponent,
  FooControlLabelComponent,
  FooDatepickerComponent,
  FooFileComponent,
  FooInputComponent,
  FooMobileNumberComponent,
  FooNumberComponent,
  FooRadiolistComponent,
  FooSelectBoxComponent,
  FooTextareaComponent,
  FooColorpickerComponent,
  DisplayAsHtmlComponent,
  FooTimepickerComponent,
  FileUploadFormComponent,
  FooDatetimepickerComponent,
  DynamicListingComponent,
  ListOfEntriesComponent,
  ListOfColumnsAsKeyValueComponent,
  TooltipMenuComponent,
  PageButtonsComponent,
  PageTopComponent,
  FooPaginatedListingDialogComponent,
  FooPaginatedSelectComponent,
  FooPaginatedSelectDialogComponent,
  FooTagsInputComponent,
  SelectablePropertyViewerComponent,
  FooIconComponent,
  FooTabsComponent
];

@NgModule({ declarations: [
        ...components,
        DisplayChallengesDialogComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [
        ...components,
        TranslateModule,
        CommonModule,
        MaterialModule,
        PipesModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        SwiperModule,
        BreadcrumbModule,
        EditorModule,
        DragDropModule,
        NgbDatepickerModule,
        NgbModule,
        NgxIntlTelInputModule,
        NgxPaginationModule,
        ClipboardModule,
        NgxMatNativeDateModule,
        NgxMatDatetimePickerModule,
        NgxMatColorPickerModule,
        ShowHidePasswordComponent,
        ShowHideInputDirective,
        ShowHideStatusDirective,
        ShowHideTriggerDirective,
    ], imports: [TranslateModule,
        CommonModule,
        MaterialModule,
        PipesModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        SwiperModule,
        BreadcrumbModule,
        EditorModule,
        DragDropModule,
        NgbDatepickerModule,
        NgbDatepickerModule,
        NgbModule,
        NgxIntlTelInputModule,
        NgxPaginationModule,
        FooFrameworkModule,
        ClipboardModule,
        NgxMatNativeDateModule,
        NgxMatDatetimePickerModule,
        NgxMatColorPickerModule,
        ShowHidePasswordComponent,
        ShowHideInputDirective,
        ShowHideStatusDirective,
        ShowHideTriggerDirective], providers: [
        { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class SharedModulesModule {
  static forRoot(componentConfig: AppComponentsConfig = {}): ModuleWithProviders<SharedModulesModule> {
    return {
      ngModule: SharedModulesModule,
      providers: [
        ListingTemplatesService,
        ValidationService,
        TranslationService,
        CountryService,
        FooTemplatesService,
        FooPopupService,
        TransactionDetailsService,
        DialogService,
        SendOldValuesService,
        DynamicListingService,
        DecimalPipe,
        FooSharedModulesComponentsConfigService,
        { provide: APP_COMPONENTS_CONFIG, useValue: componentConfig, multi: true },
      ]
    };
  }

  static forChild(): ModuleWithProviders<SharedModulesModule> {
    return {
      ngModule: SharedModulesModule,
      providers: [
        ListingTemplatesService,
        ValidationService,
        FooTemplatesService,
        FooPopupService,
        DialogService,
        SendOldValuesService,
        DynamicListingService,
        DecimalPipe,
        FooSharedModulesComponentsConfigService
      ]
    };
  }

  constructor(
    componentsConfig: FooSharedModulesComponentsConfigService,
    @Optional() @Inject(APP_COMPONENTS_CONFIG) componentsConfigs: AppComponentsConfig[],
    ) {

    if (componentsConfigs) {
      componentsConfigs.forEach((config: AppComponentsConfig) => {
        componentsConfig.setComponentsConfig(config?.configs);
      });
    }
  }

}
