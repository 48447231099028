import {Component, createNgModuleRef, Injector, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {LazyLoadingCacheService} from "../../services/lazy-loading-cache.service";

@Component({
  selector: 'framework-lazy-loaded',
  templateUrl: './lazy-loaded.component.html',
  styleUrls: ['./lazy-loaded.component.css']
})
export class LazyLoadedComponent implements OnInit {
  @ViewChild("lazyLoadedComponent", {read: ViewContainerRef, static: true})
  lazyLoadedComponent!: ViewContainerRef;

  routeInfo: any;

  constructor(
    private route: ActivatedRoute,
    private injector: Injector,
    private lazyLoadingCacheService: LazyLoadingCacheService
  ) {
    this.routeInfo = this.route.snapshot.data.routeInfo;
  }

  ngOnInit(): void {
     this.loadComponent().then();
  }

  async loadComponent(): Promise<any> {
    const cachedModule = this.lazyLoadingCacheService.getCachedModule(this.routeInfo.module.load);
    let result: any = null;
    let moduleRef: any = null;
    if (!cachedModule) {
       result = await this.routeInfo.module.load();
       moduleRef = createNgModuleRef(result.module, this.injector);
       this.lazyLoadingCacheService.cacheModule(this.routeInfo.module.load, result, moduleRef);
      //  this.lazyLoadingCacheService.cacheModule(this.routeInfo.module.load, this.routeInfo.module, moduleRef);
    } else {
      result = cachedModule.module;
      moduleRef = cachedModule.moduleRef;
    }
    this.lazyLoadedComponent.clear();
    this.lazyLoadedComponent.createComponent(result.components[this.routeInfo.componentName || this.routeInfo.name], {ngModuleRef: moduleRef});
  }

}
