interface KeyCodesInterface {
  [keyCode: number]: {
    symbol: string;
    unicode: number;
  };
}

export const KeyCodesMapping: KeyCodesInterface = {
  188: {
    symbol: ",",
    unicode: 44
  },
  190: {
    symbol: ".",
    unicode: 46
  },
  186: {
    symbol: ";",
    unicode: 59
  },
  57: {
    symbol: "(",
    unicode: 40
  },
  48: {
    symbol: ")",
    unicode: 41
  },
  219: {
    symbol: "[",
    unicode: 91
  },
  221: {
    symbol: "]",
    unicode: 93
  },
  191: {
    symbol: "/",
    unicode: 47
  },
  220: {
    symbol: "\\",
    unicode: 92
  },
  192: {
    symbol: "`",
    unicode: 96
  },
  49: {
    symbol: "!",
    unicode: 33
  },
  50: {
    symbol: "@",
    unicode: 64
  },
  51: {
    symbol: "#",
    unicode: 35
  },
  52: {
    symbol: "$",
    unicode: 36
  },
  53: {
    symbol: "%",
    unicode: 37
  },
  54: {
    symbol: "^",
    unicode: 94
  },
  55: {
    symbol: "&",
    unicode: 38
  },
  56: {
    symbol: "*",
    unicode: 42
  },
  189: {
    symbol: "-",
    unicode: 45
  },
  187: {
    symbol: "+",
    unicode: 43
  }
};
