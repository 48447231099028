import {Component, forwardRef, Injector, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {FooControlComponent} from '../foo-control/foo-control.component';
import {FooTemplatesService} from '../../foo-templates.service';
import {FooControlOptions} from '../foo-control/foo-control-options';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FooConsentComponent),
  multi: true
};

@Component({
  selector: 'foo-foo-consent',
  templateUrl: './foo-consent.component.html',
  styleUrls: ['./foo-consent.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class FooConsentComponent extends FooControlComponent implements OnInit {

  constructor(
    private fooTemplateService: FooTemplatesService,
    injector: Injector
  ) {
    super(injector);
  }
  ngOnInit(): void {
  }

  openConsent(item: FooControlOptions, url: string ): void{
    if (this.templateOptions.onConsentOpened) {
      this.templateOptions.onConsentOpened(item, url);
    }
  }


}
