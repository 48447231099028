import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AlertService, CustomValidatorsService, LoaderService, MakeCallService} from 'foo-framework';

import {GeneralSettingsComponent} from "../general-settings/general-settings.component";
import {TranslateService} from "@ngx-translate/core";
import {get} from 'lodash';

@Component({
  selector: 'app-display-challenges-dialog',
  templateUrl: './display-challenges-dialog.component.html',
  styleUrls: ['./display-challenges-dialog.component.scss']
})
export class DisplayChallengesDialogComponent implements OnInit {

  challengeSizeform: UntypedFormGroup;
  challengessizeinvalid= false;
  disablechallengeSizeInput= false;
  challengesMinSizeInvalid= false;
  minVal =1 ;
  constructor(private makecall: MakeCallService,
              private customValidators: CustomValidatorsService,
              private loaderService: LoaderService,
              private alert: AlertService,
              private fb: UntypedFormBuilder,
              private translate: TranslateService,
              public dialogRef: MatDialogRef<GeneralSettingsComponent>,
              @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    let data = {
      challenges_size: this.data?.challenges_size,
      challenges_list: this.data?.challenges_list
    };
    this.challengeSizeform = this.fb.group({
      retrieved_challenges_size: [ this.data?.challenges_size, [Validators.required]],
      add_type: [this.data?.challenges_size === this.data?.challenges_list?.length ? 'all' : 'choosenumber', [Validators.required]]
    });
    if (this.data?.challenges_size === this.data?.challenges_list?.length){
      this.disablechallengeSizeInput = true ;
    }
  }

  onSubmit() {
    let enabledCount = 0;
    this.data.challenges_list.forEach((element, index) => {
      if(element.enabled == true) {
        enabledCount +=1;
      }
    });
    if (this.challengeSizeform.valid) {
      let challengessize = 0;
      if (this.f['add_type'].value == 'all') {
        challengessize = +get(this.data, 'challenges_list.length');
      } else {
        challengessize = +this.challengeSizeform.controls['retrieved_challenges_size'].value;

      }
      if ( challengessize < this.minVal){
        //Number of challenges should be 1 or larger
        this.alert.emitAlert({ type: 'danger', text: this.translate.instant('idvconfiguration.challengesMinNumberError') });
        this.challengesMinSizeInvalid=true;
        this.challengessizeinvalid=false;
        return;
      } else if ( challengessize > (enabledCount) ){
        //Number of challenges should be larger than the number of enabled ones
        this.alert.emitAlert({ type: 'danger', text: this.translate.instant('idvconfiguration.challengesnumbererror') });
        this.challengessizeinvalid=true;
        this.challengesMinSizeInvalid=false;
        return;

      }else{
        this.challengessizeinvalid=false;
        this.challengesMinSizeInvalid=false;
      }

      if(!this.challengessizeinvalid && !this.challengesMinSizeInvalid ){
        if(this.f['add_type'].value == 'all'){
          this.challengeSizeform.get("retrieved_challenges_size").setValue(get(this.data,'challenges_list.length'));
        }
        this.dialogRef.close((this.challengeSizeform.value) );
      }

      this.challengeSizeform.markAsPristine();
    }
  }

  get f() { return this.challengeSizeform.controls; }

  checkType(value){
    this.challengessizeinvalid=false;
    if (value == 'all') {
      this.disablechallengeSizeInput=true;
      this.challengeSizeform.get("add_type").setValue('all');
      this.challengeSizeform.get("add_type").markAsTouched();

      const challengessize = +get(this.data, 'challenges_list.length');
      this.challengeSizeform.get("retrieved_challenges_size").setValue(challengessize);
      this.challengeSizeform.get('retrieved_challenges_size').clearValidators();
      this.challengeSizeform.get('retrieved_challenges_size').updateValueAndValidity();


    }else {


      this.disablechallengeSizeInput=false;
      this.challengeSizeform.get("add_type").setValue('choosenumber');
      this.challengeSizeform.get("add_type").markAsTouched();
      this.challengeSizeform.get('retrieved_challenges_size').addValidators([Validators.required]);
      this.challengeSizeform.get('retrieved_challenges_size').updateValueAndValidity();
    }
  }
}
