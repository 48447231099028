import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList, SimpleChange, SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ListingTemplatesService } from '../../listing/listing-templates.service';
import { DynamicListingService } from '../../services/dynamic-listing.service';
import { ListingService } from '../../services/listing.service';
import {CurrencyService, MakeCallService, StringobjectPipe, TokenStorage} from 'foo-framework';
import { ColumnsAsKeyValueConfigs, DynamicColumnsConfigs, EntriesConfigs, TemplateConfigs } from '../interfaces/expansion-details-configs.interface';
import { DynamicListingLabel } from '../interfaces/dynamic-listing-label.interface';
import _, { has, isFunction, isObject, isString, map } from 'lodash';


@Component({
  selector: 'dynamic-listing',
  templateUrl: './dynamic-listing.component.html',
  styleUrls: ['./dynamic-listing.component.scss']
})

export class DynamicListingComponent implements OnInit, AfterViewInit {

  curLang: string;
  pagination: [];
  data = [];
  dataPerpage = 20;
  pageNumber = 1;
  totalData = 0;
  labels: any[] = [];
  static nbOfListingComponents: number = 0;
  actionsWidth = 30;
  apiOrdering = false;
  staticOrdering = false;
  _sortable = false;
  hasHeader = true;

  @Input() customRowStyle: (data) => string;

  @Input() dataShimmer;
  @Input() paginationInput = {};
  @Input() set dataInput(value: any) {
    if(value?.length == 0 && this.pageNumber > 1){
      this.pageNumber--;
      this.getData(this.pageNumber);
    } else{
      this.data = value;
      this.oldDataOrder = _.cloneDeep(value);
    }
  }

  @Input()
  set labelsInput(value: DynamicListingLabel[]) {
    const totalSpans = (value || []).reduce((memo, label) => memo + (label.span || 1), 0);
    this.hasHeader = value.some(label => label?.title);
    this.labels = value.map(label => ({
      ...label,
      flexBasis: ((label.span || 1) / totalSpans) * 100
    }));
  }
  @Input() set sortable(value: boolean) {
    if (value) {
      this._sortable = true;
      if(Object.keys(this.paginationInput)?.length) {
        this.apiOrdering = true
      } else {
        this.staticOrdering = true
      }
    }
  }
  @Input() permissions;
  @Input() btnList;
  @Output() onFunctionCalledEvent = new EventEmitter<{ functionName: string, item: object }>();
  @Output() onGetDataEvent = new EventEmitter<number>();
  @Output() arrowClicked = new EventEmitter<any>();
  @Output() onSortingEvent = new EventEmitter<any>();
  @Input() clickableRow = false;
  @Input() boxView = false; // adds a class to the list to show each row in boxes: used in framework-boxes-listing
  @Input() firstLoadCriteria = false;
  @Input() noDataFoundParams;
  @ViewChildren('actions') actionsBoxes: QueryList<ElementRef>;
  @ViewChild('dynamicListContainer', { static: true}) dynamicListContainer : ElementRef;

  expansionPanelData: any = {};
  expansionDetailsDataShimmer = [];
  oldDataOrder = [];

  @Input() expansionTemplate: any;
  @Input() enableOrdering: boolean = false;
  @Input() expansionDetails: ColumnsAsKeyValueConfigs | DynamicColumnsConfigs | EntriesConfigs | TemplateConfigs[];
  @Input() disableExpansion: any;
  @Input() canViewDetails: any;
  @Input() customListingClassName: string;

  @Output() onOrderEvent = new EventEmitter<any>();

  orderChanged = false;
  allAreDisabled = true;
  placeholderMinHeight = 0;
  pagination_id: string;
  allAreDisabledViewDetails = true;
  showSeparator: boolean = true;

  constructor(
    protected cdr: ChangeDetectorRef,
    protected listingTemplateService: ListingTemplatesService,
    protected sanitizer: DomSanitizer,
    protected tokenStorage: TokenStorage,
    protected ListingService: ListingService,
    protected makecall: MakeCallService,
    protected dynamicListingService: DynamicListingService,
    protected stringobjectPipe: StringobjectPipe,
    protected currencyService: CurrencyService) {
    this.pagination_id = `pagination-${Math.floor(Math.random() * 1000000000)}`;
    // it is put here since there are cases where ngOnInit() may not get called before ngOnDestroy()
    this.ListingService.incrementNbOfListingComponents();
  }

  ngOnInit(): void {
    if (this.dataShimmer === undefined) {
      this.makecall.isLoading.subscribe((isLoading) => {
        this.dataShimmer = isLoading;
      });
    }
    this.curLang = this.tokenStorage.getCurrentLanguage();
    if (this.enableOrdering) {
      this.btnList.push({
        fctName: '',
        className: 'circle-actions cursor-move',
        icon: 'menu-primary'
      });
    }
    if (this.disableExpansion === undefined) {
      if (this.expansionDetails || this.expansionTemplate) {
        this.disableExpansion = false;
      } else {
        this.disableExpansion = true;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentPagination: SimpleChange = changes.paginationInput;
    const currentData: SimpleChange = changes.dataInput;
    if (currentPagination && currentPagination.currentValue) {
      this.pagination = currentPagination.currentValue;
      this.totalData = this.pagination["totalData"];
      this.dataPerpage = this.pagination["dataPerpage"] ? this.pagination["dataPerpage"] : (this.data.length ? this.data.length : 1);
      this.pageNumber = this.pagination["pageNumber"];
      if (this._sortable) {
        this.apiOrdering = true;
        this.staticOrdering = false;
      }
    }
    if (currentData) {
      this.data = currentData.currentValue;
      if(this.data?.length == 0 && this.pageNumber > 1){
        this.pageNumber--;
        this.getData(this.pageNumber);
      }
    }
  }

  getTemplate(id: string): TemplateRef<any> {
    return this.listingTemplateService.getTemplate(id);
  }

  getSecureStyle(style: string): any {
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

  btnFunctionCalled(name, data) {
    this.onFunctionCalledEvent.emit({
      functionName: name,
      item: data
    });
  }

  getData($event) {
    this.pageNumber = $event;
    this.onGetDataEvent.emit($event);
  }

  sort(label) {
    for (let x = 0; x < this.labels.length; x++) {
      if (this.labels[x] !== label) {
        this.labels[x].sorting = null;
      }
    }
    if (!label.sorting) {
      label.sorting = "desc";
    } else if (label.sorting == "desc") {
      label.sorting = "asc";
    } else {
      label.sorting = "desc";
    }
    if(!label?.disableSorting){
      label.sortingValue = label?.sortingValue || label?.value;
    }
    if(label.sortingValue){
      this.onSortingEvent.emit(label);
    }
  }

  viewDetails(item) {

    this.arrowClicked.emit(item);
  }

  ngOnDestroy(): void {
    this.ListingService.nbOfListingComponents--;
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.data, event.previousIndex, event.currentIndex);
    this.orderChanged = true;
  }

  dragStarted(event: any) {
    this.placeholderMinHeight = event.source.element.nativeElement.offsetHeight;
  }

  reOrder() {
    const dataToSend = map(this.data, (value, key) => {
      return {
        id: value.id,
        order_value: (key + 1)
      };
    });
    this.onOrderEvent.emit(dataToSend);
    this.orderChanged = false;
  }

  cancelReorder() {
    this.data = JSON.parse(JSON.stringify(this.oldDataOrder));
    this.orderChanged = false;
  }

  isDisabledExpansion(item) {
    let isDisabledExpansion;
    if (this.listOfEntriesHasEmptyDetails(item) || this.listOfColumnsAsKeyValueHasEmptyDetails(item)) {
      isDisabledExpansion = true;
    } else {
      isDisabledExpansion = this.convertToBoolean(this.disableExpansion, item);
    }
    if (!isDisabledExpansion && !(_.isEmpty(item))) {
      this.allAreDisabled = false;
    }
    return isDisabledExpansion;
  }

  isEnabledViewDetails(item) {
    let isEnabledViewDetails = this.convertToBoolean(this.canViewDetails, item);
    if (isEnabledViewDetails && !(_.isEmpty(item))) {
      this.allAreDisabledViewDetails = false;
    }
    return isEnabledViewDetails;
  }

  convertToBoolean(callBack, item) {
    let result;
    if (callBack && (typeof callBack === 'string')) {
      result = item[callBack];
    } else if (callBack && typeof callBack === 'boolean') {
      result = callBack;
    } else if (callBack && typeof callBack === 'function') {
      result = callBack(item);
    } else {
      result = false;
    }
    return result;
  }

  allItemsAsNull(item, arrayOfValues): boolean {
    let result = true;
    if (item && arrayOfValues && arrayOfValues.length) {
      result = !(arrayOfValues.some(val => this.stringobjectPipe.transform(item, val)));
    }
    return result;
  }

  onToggleAccordion(item, status: boolean = false): void {
    item.accordionExpanded = status;
  }

  getDetails(item, index) {
    this.onToggleAccordion(item, true);
    if ((this.expansionDetails as (EntriesConfigs | ColumnsAsKeyValueConfigs))?.getExpansionPanelData && !(Array.isArray(this.expansionDetails))) {
      this.expansionDetailsDataShimmer[index] = true;
      if (this.expansionTemplate === 'listOfColumnsAsKeyValue') {
        this.expansionDetails = this.dynamicListingService.drawColumns(this.expansionDetails);
      } else {
        this.expansionPanelData[index] = [{}];
      }
      (this.expansionDetails as (EntriesConfigs | ColumnsAsKeyValueConfigs))?.getExpansionPanelData(item).subscribe((items: any) => {
        if (this.expansionTemplate === 'listOfColumnsAsKeyValue') {
          this.expansionDetails = this.dynamicListingService.drawColumns({...this.expansionDetails, data: items});
        } else {
          this.expansionPanelData[index] = items;
        }
        this.expansionDetailsDataShimmer[index] = false;
      });
    }
  }

  listOfEntriesHasEmptyDetails(item) {
    return !this.disableExpansion && (this.expansionTemplate === 'listOfEntries' &&
      (
        (item[this.getDetailsObj(this.expansionDetails, item)?.source]?.length === 0) ||
        (!this.getDetailsObj(this.expansionDetails, item)?.source && !this.getDetailsObj(this.expansionDetails, item)?.getExpansionPanelData && this.allItemsAsNull(item, this.getDetailsObj(this.expansionDetails, item)?.values))
      )
    );
  }

  listOfColumnsAsKeyValueHasEmptyDetails(item) {
    return !this.disableExpansion && (this.expansionTemplate === 'listOfColumnsAsKeyValue' && this.getDetailsObj(this.expansionDetails, item)?.data?.length) === 0;
  }

  getDataFromPath(item, path, index) {
    if (item && path && !(this.dynamicListingService.checkEmptyObj(item, index))) {
      return this.stringobjectPipe.transform(item, path)
    } else {
      return [];
    }
  }

  isFunction(obj: any): boolean {
    return isFunction(obj);
  }

  has(obj: any, property: string) {
    return has(obj, property);
  }

  getDetailsObj(expansionDetails, item, property = null) {
    let result;
    if (Object.keys(item).length && Array.isArray(expansionDetails)) {
      result = expansionDetails?.find(element => element?.templateIndicatorKey === item?.templateIndicatorKey) || {};
    } else {
      result = expansionDetails;
    }
    return property ? this.stringobjectPipe.transform(result, property) : result;
  }

  getTemplateFromDetailsObj(expansionDetails, item) {
    let predefinedTemplates = ['listOfEntries', 'listOfColumnsAsKeyValue', 'listOfDynamicColumns'];
    if (Object.keys(item).length && Array.isArray(expansionDetails)) {
      let templateRef = expansionDetails?.find(element => element?.templateIndicatorKey === item?.templateIndicatorKey)?.templateRef;
      return (!predefinedTemplates?.includes(templateRef) ? this.getTemplate(templateRef) : templateRef);
    }
  }

  ngAfterViewInit(): void {
    const observer = new ResizeObserver(() => {
      this.calculateActionsWidth();
    });
    observer.observe(this.dynamicListContainer.nativeElement);
    this.calculateActionsWidth();
  }

  calculateActionsWidth(): void {
    const item = this.actionsBoxes?.first;
    this.actionsWidth = item && item.nativeElement.clientWidth
      ? item.nativeElement.clientWidth
      : 30;
     this.cdr.detectChanges();
  }

  isString(obj: any): boolean {
    return isString(obj);
  }

  isObject(obj: any): boolean {
    return isObject(obj);
  }
}
