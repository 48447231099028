import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertService, LoaderService, MakeCallService } from 'foo-framework';
import { TranslateService } from '@ngx-translate/core';
import {ValidationService} from '../../../../services/validation.service';
import { CountryDetailsComponent } from '../country-details.component';
import _, { parseInt, get } from 'lodash';

@Component({
  selector: 'app-edit-steps-form',
  templateUrl: './edit-steps-form.component.html',
  styleUrls: ['./edit-steps-form.component.scss']
})

export class EditStepsFormComponent implements OnInit {

  form: UntypedFormGroup;
  input_form;
  withNFC = false;
  hasNFC = true;
  fileIconScreen;
  extensions = 'JPG, PNG';
  selected_flow_id;
  formDataToSend: any;
  api_url_uploadResultIcon = '';
  api_url_updateinput= '';
  app_id = null;
  app_name = null;

  selected_flow = null;
  get s() { return this.form.controls; }

  // used for form generation
  /*rules = {
    capture_screen_title: [Validators.required, Validators.maxLength(240)],
    capture_screen_button_title: [Validators.required, Validators.maxLength(240)],
    preview_screen_icon: [Validators.required],
    preview_screen_title: [Validators.required, Validators.maxLength(240)],
    preview_screen_button_title: [Validators.required, Validators.maxLength(240)]
  };*/
  rules = {};
  oldDataForApprovalTransactionDetails: any = {};
  preview_screen_icon_changed = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private loaderService: LoaderService,
    private alert: AlertService,
    private makecall: MakeCallService,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<CountryDetailsComponent>,
    private validationService: ValidationService
  ) {
    this.form = new UntypedFormGroup({});


  }


  ngOnInit(): void {
    this.input_form = this.data.input_form;
    this.oldDataForApprovalTransactionDetails = _.cloneDeep(this.input_form);
    this.selected_flow= this.data.selected_flow;
    // Remove Liveness Detection
    this.input_form.tests = this.input_form.tests.filter(function( obj ) {
      return (obj.type !== 'LIVENESS' && obj.type !== 'EXTRACT_FACE');
    });

    this.buildRulesBasedOnForm(this.input_form);

    this.selected_flow_id = this.data.selected_flow_id;
    this.api_url_uploadResultIcon = this.data.api_url_uploadResultIcon;
    this.api_url_updateinput = this.data.api_url_updateinput;
    this.app_id = this.data.app_id ? this.data.app_id : null;
    this.app_name = this.data.app_name ? this.data.app_name : null;
    let dataForValidation = {
      capture_screen_title: this.data.input_form.capture_screen.title,
     // capture_screen_button_title: this.data.input_form.capture_screen.button_title,
      preview_screen_title: this.data.input_form.preview_screen.title,
      preview_screen_button_title: this.data.input_form.preview_screen.button_title,
      preview_screen_icon: this.data.input_form.preview_screen.icon ? this.data.input_form.preview_screen.icon : this.data.input_form.preview_screen.icon_link
    };

    if(this.data.input_form.input_type != 'FACE'){
      dataForValidation['capture_screen_hint'] = this.data.input_form.capture_screen.hint;
      dataForValidation['preview_screen_text'] =  this.data.input_form.preview_screen.text;
    }

    this.form = this.validationService.generateValidationRules(dataForValidation, this.rules);
    this.input_form.tests.forEach(test => {
      this.form.addControl('test_' + test.id, new UntypedFormControl(test && test.threshold ? test.threshold : '', [Validators.required, Validators.min(0), Validators.max(100)]));
    });

    if(this.hasNFC && this.input_form.nfc && this.input_form.nfc.supported && this.input_form.nfc.active){
      this.form.addControl('nfc' , new UntypedFormControl(this.input_form.nfc.enabled ? this.input_form.nfc.enabled : false, []));

    }

    this.form.get("preview_screen_icon")?.valueChanges.subscribe(value => {
      this.preview_screen_icon_changed = true;
   })
  }

  onSubmit(selected_flow_id) {
    if (this.form.valid) {

      this.formDataToSend = {
        flow_id: selected_flow_id,
        input_id: this.input_form.id,
        document_name: this.data.selected_flow.document.title,
        input_name: this.input_form.input_alias,
        country_name: this.data.country_name,
        capture_screen: this.input_form.capture_screen,
        preview_screen: this.input_form.preview_screen,
        tests: [],
        old_values: {
          capture_screen: this.oldDataForApprovalTransactionDetails.capture_screen,
          preview_screen: this.oldDataForApprovalTransactionDetails.preview_screen,
          tests: this.oldDataForApprovalTransactionDetails.tests.filter(function( obj ) {
            return (obj.type !== 'LIVENESS' && obj.type !== 'EXTRACT_FACE');
          }),
          [(this.data.selected_flow.document.name)?.toLowerCase() + '_' + (this.input_form.front ? "front" : "back") + '_icon']: this.data.input_form.preview_screen.icon ? this.data.input_form.preview_screen.icon : this.data.input_form.preview_screen.icon_link
        }
      };

      if (this.preview_screen_icon_changed) {
        this.formDataToSend.icons = [
          {
            doc_type: this.data.selected_flow.document.name,
            country: this.data.selected_country.iso_code,
            sub_folder_name: this.input_form.front ? "front" : "back",
            input_id: this.input_form.id,
            url: this.form.controls['preview_screen_icon']?.value,
            icon_key: 'result_icon'
          }
        ];
      }

      if (this.oldDataForApprovalTransactionDetails.nfc && this.oldDataForApprovalTransactionDetails.nfc.supported && this.oldDataForApprovalTransactionDetails.nfc.active) {
        let nfc_enabled = this.oldDataForApprovalTransactionDetails.nfc.enabled;
        this.formDataToSend.old_values.nfc_enabled = nfc_enabled;
      }


      Object.keys(this.form.controls).forEach(key => {
        if (key.startsWith('test_')) {
          let arrayAfterSplit = key.split('_');
          let test_id = parseInt(arrayAfterSplit[1]);
          this.formDataToSend.tests.push({
            test_id: test_id,
            threshold: this.form.controls[key].value
          });
        }
      });

      if (this.input_form.nfc && this.input_form.nfc.supported && this.input_form.nfc.active) {
        this.formDataToSend.nfc_enabled = this.form.controls['nfc'].value;
      }

      if(this.app_id) {
        this.formDataToSend.app_id = parseInt(this.app_id);
        this.formDataToSend.app_name = this.app_name;
      }
      this.loaderService.visibility = true;
      this.makecall.postCall(this.api_url_updateinput, this.formDataToSend).subscribe((data) => {
        if (data['status'] === 'OK' || data['status'] === 'success') {
          this.alert.emitAlert({
            type: 'success',
            text: data['message'] && data['message'] != '' ? data['message'] : this.translate.instant('general.SuccessfullySaved')
          });
          this.dialogRef.close('submit');
        } else {
          this.alert.emitAlert({ type: 'danger', text: data['message'] });
        }
        this.loaderService.visibility = false;
      },
        error => {
          this.alert.emitAlert({ type: 'danger', text: this.translate.instant('general.somethingWentWrong') });
          this.loaderService.visibility = false;
        });
    }
  }

  /*Build Validation Rules*/
  buildRulesBasedOnForm(form){
    this.rules = {};

    if (form.capture_screen){
      this.rules = { ...this.rules,
        capture_screen_title: [Validators.required, Validators.maxLength(240)],
       // capture_screen_button_title: [Validators.required, Validators.maxLength(240)]
      };
      if (this.data.input_form.input_type != 'FACE') {
        this.rules['capture_screen_hint'] = [Validators.required, Validators.maxLength(240)];
      }
    }
    if (form.preview_screen && form.preview_screen.visible){
      this.rules = { ...this.rules,
        preview_screen_icon: [Validators.required],
        preview_screen_title: [Validators.required, Validators.maxLength(240)],
        preview_screen_button_title: [Validators.required, Validators.maxLength(240)]};

      if (this.data.input_form.input_type != 'FACE') {
        this.rules['preview_screen_text'] = [Validators.required, Validators.maxLength(240)];
      }
    }


  }
}
