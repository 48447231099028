import { Injectable } from '@angular/core';

@Injectable()
export class DataStorageService {

    public storage: any;

    public constructor() {

    }

}