import {Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ControlValueAccessorConnectorComponent} from "../../control-value-accessor-connector.component";
import {FooControlOptions} from "./foo-control-options";

@Component({
  template: ``
})
export class FooControlComponent extends ControlValueAccessorConnectorComponent implements OnInit {
  errors = {};

  @Input()
  set templateOptions(value: FooControlOptions) {
    this.options = value;
    this.errors = {...(value?.errorMessages || {})};
  }

  get templateOptions(): FooControlOptions {
    return this.options;
  }

  options: FooControlOptions = {};


  constructor(
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit(): void {

  }
}

