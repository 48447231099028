import {Directive, ElementRef, HostListener, Input, Optional} from '@angular/core';
import {get} from "lodash";
import {NgControl} from "@angular/forms";
import { CurrencyService } from './currencies/currency.service';

@Directive({
  selector: '[appNumbersOnly]'
})

export class NumbersOnlyDirective {

  regexStr = '^[0-9]*$';

  ctrClicked = false;

  constructor(
    private el: ElementRef,
    @Optional() private ctrl: NgControl,
    private currencyService: CurrencyService
  ) {
  }

  @Input() appNumbersOnly: boolean;

  private _fractionCount: number;
  private _suppressFractions: boolean;

  @Input('fractionCount')
  set fractionCount(value: number) {
    this._fractionCount = value;
    // Set suppressFractions to true if fractionCount is 0
    this._suppressFractions = value === 0;
  }

  get fractionCount(): number {
    return this._fractionCount;
  }

  @Input('suppressFractions')
  set suppressFractions(value: boolean) {
    this._suppressFractions = value;
  }

  get suppressFractions(): boolean {
    return this._suppressFractions;
  }

  @Input() format: number[];

  @Input() lengthLimit: number;

  @Input('fractionCountBasedOnCurrency')
  set fractionCountBasedOnCurrency(value: string) {
    this.fractionCount = this.fractionCount || this.currencyService.getNumberOfDecimalPoints(value);
  }

  @HostListener('paste', ['$event']) onPaste(event) {
    event.preventDefault();
    event.stopPropagation();
    let text = (event.clipboardData || get(window, 'clipboardData')).getData(
      'text'
    ) || '';
    if (this.appNumbersOnly) {
      const regex = new RegExp("^[0-9\.]");
      text = text.split('')
        .filter(c => regex.test(c))
        // .filter(c => !this.suppressFractions || (this.suppressFractions && c !== '.'))
        .join('');

      const numberParts = text.split('.');
      let fractions = get(numberParts, '1') || '';
      if (this.fractionCount) {
        fractions = fractions.substr(0, this.fractionCount);
      }
      text = `${numberParts[0]}${(fractions && !this.suppressFractions) ? '.' + fractions : ''}`;
    }
    if (get(this.format, 'length')) {
      let mobileNumber = (text || '').replace(/\s/g, '');
      let value = '';
      let counter = 0;
      this.format.forEach((item, index) => {
        const nextCounter = counter + item;
        if (mobileNumber.substr(counter, item)) {
          value += mobileNumber.substr(counter, item) + (mobileNumber.substr(nextCounter, 1).length ? ' ' : '');
          counter = nextCounter;
        }
      });

      text = value + mobileNumber.substr(counter, mobileNumber.length);
    }
    if (this.lengthLimit) {
      text = text.substr(0, this.lengthLimit);
    }
    document.execCommand('insertHTML', false, text);
    return;
  }

  @HostListener('input', ['$event']) onInput(event) {
    if (get(this.format, 'length')) {
      let mobileNumber = (event.target.value || '').replace(/\s/g, '');
      let value = '';
      let counter = 0;
      this.format.forEach((item, index) => {
        const nextCounter = counter + item;
        if (mobileNumber.substr(counter, item)) {
          value += mobileNumber.substr(counter, item) + (mobileNumber.substr(nextCounter, 1).length ? ' ' : '');
          counter = nextCounter;
        }
      });
      event.target.value = value + mobileNumber.substr(counter, mobileNumber.length);
    }
  }

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = event as KeyboardEvent;
    if (this.ctrClicked && ['v', 'c', 'x'].includes(event.key)) {
      return;
    }
    if (['Control'].includes(event.key)) {
      this.ctrClicked = true;
      return;
    } else {
      this.ctrClicked = false;
    }
    if (['ArrowLeft', 'ArrowRight', 'Tab', 'Backspace', 'Delete'].includes(event.key)) {
      return;
    }
    let selectionCount = 0;
    if (document.getSelection().anchorNode.contains(event.currentTarget)) {
      selectionCount = document.getSelection().toString().length;
    }
    if (this.lengthLimit) {
      if (!(this.lengthLimit && (event.currentTarget.value.length - selectionCount) < Number(this.lengthLimit))) {
        event.preventDefault();
        return false;
      }
    }
    if (this.appNumbersOnly) {
      const regex = new RegExp("^[0-9\.]");
      const key = event.key;
      let element;
      if (key == "Unidentified") {
        //checking for android numpad
        if (!regex.test(event.currentTarget.value)) {
          event.preventDefault();
          event.currentTarget.value = "";
          return false;
        } else {
          // let it happen, don't do anything
          return;
        }
      } else if (key == "Backspace") {
        // let it happen, don't do anything
        return;
      } else {
        if (!regex.test(key)) {
          e.preventDefault();
        } else if (this.suppressFractions && key === '.') {
          e.preventDefault();
        } else {
          const selectionStart = event.currentTarget.selectionStart;
          const selectionEnd = event.currentTarget.selectionEnd;
          const trailDigits = (event.target.value || '').substr(selectionEnd);
          const indexOfPoint = (event.target.value || '').indexOf('.');
          if (indexOfPoint !== -1 && (indexOfPoint < selectionStart || indexOfPoint > selectionEnd) && key === '.') {
            e.preventDefault();
          } else if (this.fractionCount && this.fractionCount < (get(trailDigits, 'length') || 0) && key === '.') {
            e.preventDefault();
          } else if (key !== '.') {
            const numberParts = (event.target.value || '').split('.');
            if (this.fractionCount && this.fractionCount === (get(numberParts, '1.length') || 0) && selectionEnd >= indexOfPoint) {
              e.preventDefault();
            }
          } else {

            // let it happen, don't do anything
            return;
          }
        }
      }
    }
  }
}
