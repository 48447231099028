<foo-control-label [templateOptions]="templateOptions" [control]="control"></foo-control-label>
  <mat-radio-group
    class="row"
    name="account_type"
    [formControl]="control"
    [disabled]="templateOptions.readonly"
    ngDefaultControl
  >
    <ng-container *ngFor="let item of templateOptions?.items">
      <mat-radio-button [disabled]="templateOptions.readonly" class="form-control" [value]="item[keyProp]">
        {{ item[titleProp] | translate }}
      </mat-radio-button>
    </ng-container>
  </mat-radio-group>

<foo-control-errors *ngIf="!templateOptions?.readonly" [control]="control"
                    [errorMessages]="errors"></foo-control-errors>
