import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {TranslateService} from "@ngx-translate/core";
import {AlertService} from "foo-framework";
import {get} from 'lodash';
import {DisplayChallengesDialogComponent} from "../display-challenges-dialog/display-challenges-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss']
})
export class GeneralSettingsComponent implements OnInit {
  challengeSizeform: UntypedFormGroup;

  @Input() challenges: any;
  @Input() challengesConfig: any;
  @Input() challengesConfigObj: any;
  @Input() permissions: any;
  @Input() secret_key: '';
  @Input() engineConfig: any = {};

  @Output() reOrderClicked = new EventEmitter<any>();
  @Output() toggleChallengerChanged = new EventEmitter<any>();
  @Output() toggleChallengeConfigChanged = new EventEmitter<any>();
  @Output() changeChallengeConfigChanged = new EventEmitter<any>();
  @Output() saveOneFieldChallengeConfigChanged = new EventEmitter<any>();
  @Output() engineChanged = new EventEmitter<any>();

  orderChanged = false;
  orderButtonClicked = false;
  challengesSize = 0;
  private eventsSubscription: Subscription;
  @Input() orderChanged_subject: Observable<any>;
  challengessizeinvalid= false;
  constructor(
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private alert: AlertService,
    private dialog: MatDialog,){

  }
  ngOnInit(): void {
    this.eventsSubscription = this.orderChanged_subject.subscribe(data => {
      this.orderChanged = data;
      this.orderButtonClicked = data;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentchallengesConfigObj: SimpleChange = changes.challengesConfigObj;
    if (currentchallengesConfigObj && currentchallengesConfigObj.currentValue) {

      this.challengesSize = currentchallengesConfigObj.currentValue?.retrieved_challenges_size ;
      this.challengeSizeform = this.fb.group({
        retrieved_challenges_size: [currentchallengesConfigObj.currentValue?.retrieved_challenges_size, [Validators.required]]
      });
    }
  }

  // open dialog to edit
  editRetrievedChallenges(challengesSize = 0) {
    let data = {};

    data = {
      challenges_size: challengesSize,
      challenges_list: this.challenges
    };

    const dialogRef = this.dialog.open(DisplayChallengesDialogComponent, {
      width: '550px',
      panelClass: ['app-custom-dialog-container', 'display-scrollbar'],
      backdropClass: 'app-customer-backdrop',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result &&  get(result, 'retrieved_challenges_size' ) ) {
        this.challengeSizeform.get("retrieved_challenges_size").setValue(get(result,'retrieved_challenges_size'));

        this.saveOneFieldChallengeConfigChanged.emit( result)  ;
      }
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.challenges, event.previousIndex, event.currentIndex);
    this.orderChanged = true;
  }

  getChallengeName = function (name) {
    return name.split('_')
      .map((word) => {
        var loweredCase = word.toLowerCase();
        return word[0].toUpperCase() + loweredCase.slice(1);
      }
      )
      .join(' ');
  }

  isBoolean(value) {
    return typeof value === 'boolean';
  }

  saveOrder() {
    this.reOrderClicked.emit(this.challenges);
  }


  toggleChallenge(challenge) {

    let enabledCount = 0;
    this.challenges.forEach((element, index) => {
      if(challenge !== element && element.enabled == true) {
        enabledCount +=1;
      }
    });
    const challengessize = +this.challengeSizeform.controls['retrieved_challenges_size'].value  ;

     if(challenge.enabled == true){
       if (challengessize > (enabledCount+1) ){
        //Number of challenges shouldn’t be larger than the number of enabled ones
        this.alert.emitAlert({ type: 'danger', text: this.translate.instant('idvconfiguration.challengesnumbererror') });
        this.resetOldValues(challenge);
        return;
      }
    }else{
      if (enabledCount==0){
        // check if it is not the only one enabled we can't disable all
        this.alert.emitAlert({ type: 'danger', text: this.translate.instant('idvconfiguration.cantdisableall') });
        this.resetOldValues(challenge);
        return;
      } else if ((enabledCount) < challengessize) {
        //we can't disable less than challenges number
        this.alert.emitAlert({ type: 'danger', text: this.translate.instant('idvconfiguration.disable_challenges_error', { challengesnumber: challengessize }) });
        this.resetOldValues(challenge);
        return;
      }
    }

    this.toggleChallengerChanged.emit(challenge);
  }

  toggleChallengeConfig(key, value) {
    let dataToSend = {
      key: key,
      value: value
    }
    this.toggleChallengeConfigChanged.emit(dataToSend);
  }

  changeChallengeConfig(key, value) {
    let dataToSend = {
      key: key,
      value: value
    }
    this.changeChallengeConfigChanged.emit(dataToSend);
  }

  saveOneFieldChallengeConfig(){
    if(this.challengeSizeform.valid){
      this.saveOneFieldChallengeConfigChanged.emit(this.challengeSizeform.value);
      this.challengeSizeform.markAsPristine();
    }
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

  resetOldValues(challenge) {
    setTimeout(() => {
      this.challenges.forEach(item => {
        if(item === challenge) {
          item.enabled = !item.enabled;
        }
      });
    }, 500);
  }
}
