import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import {TokenStorage} from "foo-framework";
import { Direction } from '@angular/cdk/bidi';

@Component({
  selector: 'app-unauth-home',
  templateUrl: './unauth-pages.component.html',
  styleUrls: ['./unauth-pages.component.scss']
})
export class UnauthPagesComponent implements OnInit {

  isClosed = false;
  curLang : string;
  @Output() LangChangedEvent = new EventEmitter<string>();
  ltr = this.translate.instant("general.dir") as Direction | "auto";

  constructor(private translate: TranslateService,private location: Location ,private tokenStorage: TokenStorage) {

    if(!this.tokenStorage.getCurrentLanguage()){
      this.tokenStorage.setCurrentLanguage('en')
    }
    this.curLang = this.tokenStorage.getCurrentLanguage();
    translate.setDefaultLang(this.curLang);
}

  ngOnInit(): void {
  }

  changeLanguage(){
    var language = "en";
    if(this.tokenStorage.getCurrentLanguage() == "en"){
      language = "ar";
    }
    this.LangChangedEvent.emit(language);
    this.translate.use(language);
    this.tokenStorage.setCurrentLanguage(language);
    this.curLang = language;
  }
  useLanguage(language: any){
    this.LangChangedEvent.emit(language);
    this.translate.use(language);
    this.tokenStorage.setCurrentLanguage(language);
    this.curLang = language;
  }
  goBack(){
    this.location.back();
  }
}
