<div class="borderShadow">
    <search-bar [shimmer]="dataShimmer" *ngIf="issearchInput" (onSearchEvent)="searchData($event)"></search-bar>

    <table class="table table-striped border mt-1">
        <thead>
          <tr>
              <ng-container *ngFor="let label of labels">
                <th scope="col" *ngIf="!label.type || label.type!='date'" data-order="desc" [attr.data-label]="label.value" [appSort]="data" >{{ label.title | translate }}</th>
                <th scope="col" *ngIf="label.type=='date'" [appSort]="data" data-order="desc" [attr.data-label]="label.value" data-type="date">{{ label.title | translate }}</th>
              </ng-container>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of data | paginate : {
            itemsPerPage: dataPerpage,
            currentPage: pageNumber,
            totalItems: totalData
            };" [class.shimmer-animate]="dataShimmer">
                <td *ngFor="let label of labels">
                    <span class="d-block d-sm-none fw-bold" *ngIf="label.title"> {{ label.title | translate }}: </span>
                    <span class="reportMobileValue mt-5 mb-5" *ngIf="label.type === 'boolean'">
                        {{item[label.value] == 1 ? "Yes" : "No"}}
                    </span>
                    <span class="reportMobileValue mt-5 mb-5" *ngIf="label.type == 'date'" [class.date]="label.type === 'date'">
                        {{item[label.value]  | date: 'd MMM yyyy'}}
                    </span>
                    <span class="reportMobileValue mt-5 mb-5" *ngIf="label.type == 'datetime'" [class.date]="label.type === 'date'">
                        {{item[label.value]  | date: 'd MMM yyyy | hh:mm:ss'}}
                    </span>
                    <span class="reportMobileValue mt-5 mb-5" *ngIf="label.type == 'image'">

                        <div class="imageContainer" [ngStyle]="{'background-image': 'url(' + (item | stringobject : label.value) + ')'}" > </div>
                    </span>
                    <span class="reportMobileValue mt-5 mb-5" *ngIf="!label.type">
                        {{item | stringobject : label.value}}
                    </span>
                </td>
          </tr>
        </tbody>
      </table>

    <div class="row noresult-found-box mb-40 mt-40" *ngIf="dataInput.length === 0">
        <div class="noresult_found">
            <framework-no-data></framework-no-data>
        </div>
    </div>
</div>
<div class="row mt-20" *ngIf="pagination != null">
    <pagination-controls (pageChange)="pageNumber =$event; getData()"
                         [maxSize]="9"
                         [directionLinks]="true"
                         [autoHide]="true"
                         [responsive]="true"
                         previousLabel=""
                         nextLabel=""
    >
    </pagination-controls>
</div>
