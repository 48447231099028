import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { AlertService, CustomValidatorsService, LoaderService, MakeCallService } from "foo-framework";
import { TranslateService } from "@ngx-translate/core";
import _ from 'lodash';
@Component({
  selector: 'add-introduction-form',
  templateUrl: './add-introduction-form.component.html',
  styleUrls: ['./add-introduction-form.component.scss']
})
export class AddIntroductionFormComponent implements OnInit {


  form: UntypedFormGroup;
  extensions = 'JPG, PNG';
  uploadNote = 'Image Dimension should be 120x120 px';
  @Input() data: any;
  fromGroups = {}
  maxChars: number = 250;
  @Input() api_url: string = '';
  @Input() api_url_upload_icon: string = '';
  @Input() permissions: any;
  @Input() app_id: any;
  @Input() app_name: any;

  oldDataForApprovalTransactionDetails: any = {};

  constructor(
    private loaderService: LoaderService,
    private fb: UntypedFormBuilder,
    private alert: AlertService,
    private makecall: MakeCallService,
    private translate: TranslateService,
    private customValidators: CustomValidatorsService
  ) {
  }

  ngOnInit(): void {
    this.oldDataForApprovalTransactionDetails = _.cloneDeep(this.data);
    this.fromGroups = {
      introTitle: ["progress_intro_titles" in this.data ? this.data['progress_intro_titles']['title'] : '', [Validators.required, this.customValidators.ValidateMaxWords(6)]],
      introText: ["progress_intro_titles" in this.data ? this.data['progress_intro_titles']['text'] : '', [Validators.required, this.customValidators.ValidateMaxWords(50)]],
      icon1: ["document_verification" in this.data && this.data['document_verification'].icon ? this.data['document_verification'].icon : this.data['document_verification'].icon_link],
      documentTitle: ["document_verification" in this.data ? this.data['document_verification']['title'] : '', [Validators.required, this.customValidators.ValidateMaxWords(6)]],
      documentText: ["document_verification" in this.data ? this.data['document_verification']['text'] : '', [Validators.required, this.customValidators.ValidateMaxWords(50)]],
      icon2: ["personal_verification" in this.data && this.data['personal_verification'].icon ? this.data['personal_verification'].icon : this.data['personal_verification'].icon_link],
      personalTitle: ["personal_verification" in this.data ? this.data['personal_verification']['title'] : '', [Validators.required, this.customValidators.ValidateMaxWords(6)]],
      personalText: ["personal_verification" in this.data ? this.data['personal_verification']['text'] : '', [Validators.required, this.customValidators.ValidateMaxWords(50)]],
    }
    this.form = this.fb.group(this.fromGroups);
  }

  update() {
    if (this.form.valid) {
      this.loaderService.visibility = true;
      this.data = {
        ...this.data,
        icons: [],
        old_values: {
          ...this.oldDataForApprovalTransactionDetails
        }
      };
      if(this.app_id) {
        this.data = {
          ...this.data,
          'app_id' : this.app_id,
          'app_name' : this.app_name
        };
      }

      let iconArray = [];
      if (this.form.controls['icon1'].value) {
        if(!this.app_id) {
          iconArray.push({
            icon_type: 'document_verification_icon',
            image: this.form.controls['icon1'].value
          });
        } else {
          iconArray.push({
            icon_type: 'document_verification_icon',
            image: this.form.controls['icon1'].value,
            app_id: this.app_id
          });
        }
      }
      if (this.form.controls['icon2'].value) {
        if(!this.app_id) {
          iconArray.push({
            icon_type: 'personal_verification_icon',
            image: this.form.controls['icon2'].value
          });
        } else {
          iconArray.push({
            icon_type: 'personal_verification_icon',
            image: this.form.controls['icon2'].value,
            app_id: this.app_id
          });
        }
      }

      iconArray.forEach(element => {
        if (element) {
          this.data.icons.push({
            icon_type: element.icon_type,
            url: element.image,
            icon_key: 'icon'
          });
          let last_index = (element.icon_type).lastIndexOf("_");
          let icon_type_without_icon = (element.icon_type).substring(0, last_index);
          this.data.old_values[element.icon_type] = icon_type_without_icon in this.data && this.data[icon_type_without_icon].icon ? this.data[icon_type_without_icon].icon : this.data[icon_type_without_icon].icon_link
        }
      });

      this.makecall.postCall(this.api_url, this.data).subscribe((data) => {
        if (data && ((data['status'])?.toLowerCase() === 'success' || 'ok')) {
          this.alert.emitAlert({
            type: 'success',
            text: data['message'] && data['message'] != '' ? data['message'] : this.translate.instant('general.SuccessfullySaved')
          });
        } else {
          this.alert.emitAlert({ type: 'danger', text: data['message'] });
        }
        this.loaderService.visibility = false;
      },
        error => {
          this.alert.emitAlert({ type: 'danger', text: this.translate.instant('general.somethingWentWrong') });
          this.loaderService.visibility = false;
        });
    }
  }
}
