import {
  ControlContainer,
  ControlValueAccessor,
  UntypedFormControl,
  FormControlDirective,
} from '@angular/forms';
import {Component, Injector, Input, ViewChild} from '@angular/core';
@Component({
  template: '',
})
export class ControlValueAccessorConnectorComponent implements ControlValueAccessor {
  @ViewChild(FormControlDirective, {static: false})
  formControlDirective: FormControlDirective;

  @Input()
  formControl: UntypedFormControl;

  @Input()
  formControlName: string;

  get control(): any {
    return this.formControl || this.controlContainer.control.get(this.formControlName);
  }

  constructor(protected injector: Injector) {
  }

  get controlContainer() {
    return this.injector.get(ControlContainer);
  }

  registerOnTouched(fn: any): void {
    this.formControlDirective?.valueAccessor?.registerOnTouched(fn);
  }

  registerOnChange(fn: any): void {
    this.formControlDirective?.valueAccessor?.registerOnChange(fn);
  }

  writeValue(obj: any): void {
    this.formControlDirective?.valueAccessor?.writeValue(obj);
  }

  setDisabledState(isDisabled: boolean): void {
    this.formControlDirective?.valueAccessor?.setDisabledState(isDisabled);
  }
}
