import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[allowedCharacters]'
})
export class AllowedCharactersDirective {

  @Input() allowedCharacters: string = '';
  constructor(private el: ElementRef) {}
  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    const regex = new RegExp(`[^${this.allowedCharacters}]`, 'g');
    input.value = input.value.replace(regex, '');
  }
  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text/plain');
    const sanitizedText = pastedText.replace(new RegExp(`[^${this.allowedCharacters}]`, 'g'), '');
    document.execCommand('insertText', false, sanitizedText);
  }
}
