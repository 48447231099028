import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { TokenStorage } from './token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  private mapLoaded$: Observable<boolean>;
  curLang: string;
  constructor(private httpClient: HttpClient, private tokenStorage: TokenStorage) {
    this.curLang = this.tokenStorage.getCurrentLanguage();
    this.initMapLoading();
  }

  private initMapLoading(): void {
    this.mapLoaded$ = this.httpClient.jsonp(`https://maps.googleapis.com/maps/api/js?key=${window['map_api_key']}&libraries=places&language=${this.curLang}`, 'callback')
      .pipe(
        map(() => true),
        catchError(() => of(false)),
        shareReplay(1)
      );
  }

  getMapLoadedStatus(): Observable<boolean> {
    return this.mapLoaded$;
  }
}
