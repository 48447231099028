import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl} from "@angular/forms";
import {isBoolean, isObject, keys} from 'lodash';
import {isObservable} from 'rxjs';
import {deepmerge} from "deepmerge-ts";
import {errorMessages as defaultErrorMessages} from "foo-framework";

@Component({
  selector: 'foo-control-errors',
  templateUrl: './foo-control-errors.component.html',
  styleUrls: ['./foo-control-errors.component.scss']
})
export class FooControlErrorsComponent implements OnInit {
  @Input() control: UntypedFormControl;

  @Input()
  set errorMessages(value) {
    if (value) {
      value = deepmerge(defaultErrorMessages, value);
      this.errors = keys(value).map(key => ({
        type: key,
        message: value[key]
      }));
    } else {
      this.errors = [];
    }
  }

  errors: {
    type: string,
    message: any
  }[] = [];

  constructor() {
  }

  ngOnInit(): void {
    if (!this.errors.length) {
      this.errors = keys(defaultErrorMessages).map(key => ({
        type: key,
        message: defaultErrorMessages[key]
      }));
    }
  }

  showError(error): boolean {
    if (error.type === 'required') {
      return this.control?.hasError(error.type) && (this.control?.touched || this.control?.dirty);
    } else {
      return this.control?.hasError(error.type) && this.control?.touched;
    }
  }

  getMessage(error) : string {
    if (isObject(error?.message)) {
      return error?.message[isBoolean(this.control?.getError(error?.type)) ? 'default' : this.control?.getError(error?.type)];
    }
    return error?.message;
  }
}
