import {Injectable} from '@angular/core';
import {set, get} from 'lodash';

@Injectable()
export class LazyLoadingCacheService {

  modulesMap: {
    [name: string]: {
      module: any,
      moduleRef: any
    }
  } = {};

  constructor() {
  }

  cacheModule(name: string, module: any, moduleRef: any): void {
    set(this.modulesMap, [name, 'module'], module);
    set(this.modulesMap, [name, 'moduleRef'], moduleRef);
  }

  getCachedModule(name: string): any {
    return get(this.modulesMap, name);
  }

}
