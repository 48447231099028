import {Injectable, InjectionToken} from '@angular/core';
import {get} from 'lodash';

export interface AppGeneralConfig {
  mobile_number?: {
    country_code: string;
    regex: string;
  };
  listingComponentConfig?: {
    ellipsisEffect: boolean;
  };
  headerConfig?: {
    displayUserFullname: boolean;
  };
  addUserConfig?: {
    phoneOptional: boolean;
  };
  addRoleConfig?: {
    myAccountCheckDisabled: boolean;
  };
  
  commonConfig?: {
    hasMobileApp?: boolean;
    showCopyright?: boolean;
    showDesignedDevelopedByFoo?: boolean;
    showLoaderBlurBackground?: boolean;
    cardCountUpAnimation?: boolean | {duration: number};
  };
  applicationDetailsConfig?: {
    showAdditionalInformationPanel: boolean;
  };
  usersConfig?: {
    canUpdateAllUsers: boolean;
    hideSearchForRoles: boolean;
  };
  loginConfig?: {
    browserCredentialsSave: boolean;
  };
  customerCardListConfig?: {
    hideIssueVirtualCard: boolean;
  };
  cardTransactionsConfig?: {
    hideAdvancedSearch: boolean;
  };
  [key: string]: any;
}

export const APP_GENERAL_CONFIG = new InjectionToken<AppGeneralConfig>('APP_GENERAL_CONFIG');

@Injectable()
export class GeneralConfigService {

  private config: AppGeneralConfig  =  {};

  constructor() { }

  setConfig(config: AppGeneralConfig): void {
    this.config = {...this.config, ...(config || {})};
  }

  getConfig(): AppGeneralConfig {
    return this.config;
  }

  getConfigProperty(propertyPath: string): AppGeneralConfig {
    return get(this.config, propertyPath);
  }
}
