<div class="login_content">
  <div class="login_body_container">
    <div class="login_body" formValidationContainer>
      <div class="col-12 col-md-12 col-sm-12 col-lg-12 px-0" *ngIf="showLang">
        <div class="foo-btn-outline-secondary langSwitch" *ngIf="showLang" (click)='useLanguage();'>
          <span>{{ "menu.lang" | translate }} </span>
        </div>
      </div>
      <div class="col-12 col-md-12 col-sm-12 col-lg-12 mb-24 centered">
        <img class="login-logo" alt="login-logo" src="{{logo}}"/>
        <p class="big-title">{{ pagetitle | translate}}</p>
        <p class="title-desc" *ngIf="pageSubTitle != ''">{{ pageSubTitle | translate}}</p>
      </div>
      <form [formGroup]="resetForm" (ngSubmit)="onSubmit()" class="col-12 col-md-12 col-sm-12 col-lg-12 p-0" formValidation autocomplete="off">
        <div class="form-group mb-10">
          <label class="form-label">{{"auth.password" | translate}}</label>
          <input class="form-control" formControlName="new_pin" type="password" (input)="passModify();"
                 placeholder='{{ "general.enterpassword" | translate }}'>
          <small *ngIf="(resetForm.get('new_pin').touched || resetForm.get('new_pin').dirty) && resetForm.get('new_pin').hasError('required')"
                 class="text-danger">
            {{"general.requiredPassword" | translate}}
          </small>
        </div>
        <div class="row mb-10">
          <div class="col-12 pass-check">
            <p class="check-condition" [class.verified]="hasLength" *ngIf='passConfig && passConfig["password_length"] && passConfig["password_length"].value > 0'>
              <span class="foo-icon foo-success-pass-check succes-img mr-1"></span>
              <span class="foo-icon foo-failed-pass-check failed-img mr-1"></span>
              {{ "general.passcheck1" | translate }} {{ charNumber }} {{ "general.passcheck6" | translate }}</p>
            <p class="check-condition" [class.verified]="hasUppercase" *ngIf='passConfig && passConfig["password_uppercase"] && passConfig["password_uppercase"].value == 1'>
              <span class="foo-icon foo-success-pass-check succes-img mr-1"></span>
              <span class="foo-icon foo-failed-pass-check failed-img mr-1"></span>
               {{ "general.passcheck2" | translate }}</p>
            <p class="check-condition" [class.verified]="hasLowercase" *ngIf='passConfig && passConfig["password_lowercase"] && passConfig["password_lowercase"].value == 1'>
              <span class="foo-icon foo-success-pass-check succes-img mr-1"></span>
              <span class="foo-icon foo-failed-pass-check failed-img mr-1"></span>
               {{ "general.passcheck3" | translate }}</p>
            <p class="check-condition" [class.verified]="hasNumbers" *ngIf='passConfig && passConfig["password_numbers"] && passConfig["password_numbers"].value == 1'>
              <span class="foo-icon foo-success-pass-check succes-img mr-1"></span>
              <span class="foo-icon foo-failed-pass-check failed-img mr-1"></span>
               {{ "general.passcheck4" | translate }}</p>
            <p class="check-condition" [class.verified]="hasSymbols" *ngIf='passConfig && passConfig["password_symbols"] && passConfig["password_symbols"].value == 1'>
              <span class="foo-icon foo-success-pass-check succes-img mr-1"></span>
              <span class="foo-icon foo-failed-pass-check failed-img mr-1"></span>
               {{ "general.passcheck5" | translate }}</p>
          </div>
        </div>
        <div class="form-group mb-32">
          <label class="form-label">{{"auth.confirmPassword" | translate}}</label>
          <input formControlName="repeat_new_pin" class="form-control" type="password"
                 placeholder='{{ "auth.confirmPassword" | translate }}'>
          <small *ngIf="(resetForm.get('repeat_new_pin').touched || resetForm.get('repeat_new_pin').dirty) && resetForm.get('repeat_new_pin').hasError('required')"
                 class="text-danger">
            {{"general.requiredPassword" | translate}}
          </small>
        </div>

        <div class="form-group  mb-0">
          <button type="submit" class="foo-btn-primary btn-block">
            {{ btntitle | translate}}
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="login-footer" *ngIf="showCopyright || showDesignedDevelopedByFoo">
    <div class="reserved" *ngIf="showCopyright">&copy; Copyright {{currentYear}} {{projectName}}</div>
    <div class="designed" *ngIf="showDesignedDevelopedByFoo">Designed and developed by FOO_</div>
  </div>
</div>
