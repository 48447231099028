import {Directive, HostListener, Input} from '@angular/core';

@Directive({
  selector: 'textarea,input[type="text"]'
})
export class WhitespacesDirective {
  @Input() allowLeadingSpaces: boolean = false;

  constructor() {
  }

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    if (!this.allowLeadingSpaces) {
      const input = event.target as HTMLInputElement;
      if (input.value.startsWith(" ")) {
        event.preventDefault();
        input.value = input.value.trim();
      }
    }
  }

}
