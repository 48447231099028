import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebformsViewerComponent } from './components/webforms-viewer/webforms-viewer.component';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { FooFrameworkModule, MaterialModule, PipesModule } from 'foo-framework';
import { SharedModulesModule } from 'foo-framework/shared-modules';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';


const components = [
  WebformsViewerComponent
];


@NgModule({ declarations: [
        ...components
    ],
    exports: [
        ...components
    ], imports: [CommonModule,
        TranslateModule,
        PipesModule,
        FooFrameworkModule,
        SharedModulesModule.forChild(),
        MaterialModule,
        ReactiveFormsModule,
        MatDialogModule], providers: [{ provide: OverlayContainer, useClass: FullscreenOverlayContainer }, provideHttpClient(withInterceptorsFromDi())] })
export class WebformsSharedModule { }
