import {Component, HostListener, Input, OnInit} from '@angular/core';
import {BreakpointObserver} from "@angular/cdk/layout";
import {PermissionsService, RouteService} from "foo-framework";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'foo-left-menu-navigator',
  templateUrl: './left-menu-navigator.component.html',
  styleUrls: ['./left-menu-navigator.component.scss']
})
export class LeftMenuNavigatorComponent implements OnInit {

  @Input() links: {
    route: string;
    id: string;
    permission?: string;
    label: string;
    state?: string;
    dataTarget: string;
    clearNavigationHistory?: boolean,
    icon: string;
    iconImage: string;
  }[] = [];

  @Input() permissions: any = {};

  @Input() itemShimmer: boolean = false;

  @Input() params: any = {};

  menuClosed: boolean;


  @Input() showMenu = true;


  constructor(
    private breakpointObserver: BreakpointObserver,
    private routeService: RouteService,
    private router: Router,
    private permissionsService: PermissionsService,
    public route: ActivatedRoute
  ) {
    this.menuClosed = this.route.snapshot.queryParams.menuClosed ? JSON.parse(this.route.snapshot.queryParams.menuClosed) : false;
    breakpointObserver
      .observe('(max-width: 769px)')
      .subscribe(({matches}) => {
        if(!this.menuClosed) {
          this.menuClosed = !!matches;
        }
      });
  }

  ngOnInit(): void {
  }

  toggleMenu(): void {
    this.menuClosed = !this.menuClosed;
    const params = {...this.route.snapshot.queryParams, 'menuClosed': this.menuClosed};
    this.router.navigate([], {queryParams: params});
  }


  checkIfActivatedRoute(route: string): boolean {
    let currentIndex = -1;
    let maxIndex = -1;
    this.links.forEach((item: any, idx: number) => {
      if (item.route === route) {
        currentIndex = this.routeService.routesHistory.findIndex(part => part === item.route);
      } else {
        maxIndex = Math.max(maxIndex, this.routeService.routesHistory.findIndex(part => part === item.route));
      }
    });
    return currentIndex !== -1 && currentIndex > maxIndex;
  }

  emptyRouteHistory(routeLink): void {
    if (routeLink.clearNavigationHistory) {
      this.routeService.routesHistory = [];
      this.router.navigate([`/${routeLink.route}`], {
        queryParams: this.params
      });
    } else if (!this.checkIfActivatedRoute(routeLink.route)) {
      this.params = {...this.params, 'menuClosed': this.menuClosed};
      const routes = this.links.map(link => link.route);
      const existingRouteIdx = this.routeService.routesHistory.findIndex(route => routes.includes(route));
      if (existingRouteIdx !== -1) {
        this.routeService.routesHistory = this.routeService.routesHistory.slice(0, existingRouteIdx);
      }
      this.router.navigate([`/${routeLink.route}`], {
        queryParams: this.params
      });
    }
  }

  isStateExisted(state: string): boolean {
    // for testing purposes
    // return  true;
    return this.permissionsService.checkIfStateExist(state);
  }

}
