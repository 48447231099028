import { Component, forwardRef, Injector, Input, OnInit, SimpleChange, SimpleChanges , ChangeDetectorRef  } from '@angular/core';
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { FooControlComponent } from "../foo-control/foo-control.component";
import moment from 'moment';
import { asapScheduler, interval, map, Observable, pairwise, startWith } from 'rxjs';
import { TranslateService } from "@ngx-translate/core";

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FooDatetimepickerComponent),
  multi: true
};

@Component({
  selector: 'foo-datetimepicker',
  templateUrl: './foo-datetimepicker.component.html',
  styleUrls: ['./foo-datetimepicker.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class FooDatetimepickerComponent extends FooControlComponent implements OnInit {

  @Input() clearable: boolean = false;

  minDate: Date = null;
  minDate$: Observable<Date> = null;
  minDateSubscription;

  maxDate: Date = null;

  constructor(
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    injector: Injector
  ) {
    super(injector);
  }

  get icon(): string {
    return this.templateOptions?.icon || 'foo-calendar-primary';
  }

  ngOnInit(): void {
    this.setMin();
    if (!this.templateOptions.errorMessages) {
      this.templateOptions.errorMessages =
      {
        matDatetimePickerMin: this.translate.instant('validations.invalidDate'),
        matDatetimePickerMax: this.translate.instant('validations.invalidDate')
      };
    }

    this.control.valueChanges
      .pipe(
        startWith(this.control.value),
        pairwise()
      )
      .subscribe(([previousValue, currentValue]) => {
        this.validateControlValue();
        if (!previousValue && currentValue) {
          asapScheduler.schedule(() => {
            const now = new Date();
            const dateObj = new Date(this.control.value);
            if (!isNaN(dateObj.getTime())) {
              dateObj.setHours(now.getHours());
              dateObj.setMinutes(now.getMinutes());
              dateObj.setSeconds(now.getSeconds());
              this.control.setValue(dateObj.toISOString(), { emitEvent: false });
            }
          })
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    const templateOptions: SimpleChange = changes?.templateOptions;
    const currentMin = templateOptions?.currentValue?.min;
    const currentMax = templateOptions?.currentValue?.max;
    if (currentMin != 'now') {
      if (this.minDateSubscription) {
        this.minDateSubscription.unsubscribe();
      }
      this.minDate = moment(currentMin).add(1, 'm').toDate();
    }
    if (currentMax) {
      this.maxDate = moment(currentMax).subtract(1, 'm').toDate();
    }
    this.validateControlValue();
  }

  setMin() {
    if (this.templateOptions?.min) {
      if (this.templateOptions?.min == 'now') {
        this.minDate = moment(new Date()).add(1, 'm').toDate();
        this.minDate$ = interval(1000).pipe(
          map(() => moment(new Date()).add(1, 'm').toDate())
        );
        this.minDateSubscription = this.minDate$.subscribe(date => {
          this.minDate = date;
          this.validateControlValue();
        });
      } else {
        this.minDate = <Date>this.templateOptions?.min;
      }
      this.validateControlValue();
    }
  }

  clearInput() {
    this.control.setValue(null);
  }

  ngOnDestroy(): void {
    if (this.minDateSubscription) {
      this.minDateSubscription.unsubscribe();
    }
  }

  validateControlValue() {
    if (this.control.value) {
      const min = moment(this.minDate);
      const max = moment(this.maxDate);
      const value = moment(this.control.value);

      if (min.isValid() && !value.isAfter(min)) {
        this.control.setErrors({...(this.control.errors || {}), matDatetimePickerMin: true});
      }
      if (max.isValid() && !value.isBefore(max)) {
        this.control.setErrors({...(this.control.errors || {}), matDatetimePickerMax: true});
      }
      this.cdr.detectChanges();
    }
  }
}
