import { Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import { FooControlComponent } from '../foo-control/foo-control.component';
import { DialogService } from '../../services/dialog.service';
import { FooPaginatedSelectDialogComponent } from "../../foo-paginated-select-dialog/foo-paginated-select-dialog.component";
import { TranslateService } from "@ngx-translate/core";
import { NG_VALUE_ACCESSOR } from '@angular/forms';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FooPaginatedSelectComponent),
  multi: true
};

@Component({
  selector: 'foo-paginated-select',
  templateUrl: './foo-paginated-select.component.html',
  styleUrls: ['./foo-paginated-select.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})

export class FooPaginatedSelectComponent extends FooControlComponent implements OnInit {

  constructor(
    protected translate: TranslateService,
    private dialogService: DialogService,
    injector: Injector
  ) {
    super(injector);
  }

  controlValue: any;

  selectedItemsCount: number = 0;

  @Input() button = {};
  @Input() dataShimmer;
  @Input() activeTab;
  @Input() tabsConfig;
  @Input() dialogTitle;

  ngOnInit(): void {
    this.setCount(this.control.value);
    this.control.valueChanges.subscribe((value) => {
      this.setCount(value);
    });
  }

  setCount(value) {
    this.controlValue = value;
    this.selectedItemsCount = this.controlValue && this.controlValue.hasOwnProperty('totalCount') && this.controlValue?.totalCount ? this.controlValue?.['totalCount'] : 0;
  }

  selectItems() {
    this.dialogService.openDialog(FooPaginatedSelectDialogComponent, {
      data: {
        controlValue: this.controlValue,
        dialogTitle: this.dialogTitle,
        activeTab: this.activeTab,
        tabsConfig: this.tabsConfig,
        button: this.button
      }
    }).subscribe((data: any) => {
      if (data) {
        this.control.setValue(data);
      }
      this.control.markAsDirty();
      this.control.markAsTouched();
    });
  }
}
