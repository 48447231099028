import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {RouteService, GeneralConfigService} from 'foo-framework';

@Component({
  selector: 'page-top',
  templateUrl: './page-top.component.html',
  styleUrls: ['./page-top.component.scss']
})
export class PageTopComponent implements OnInit {
  @Input() sectionTitle = '';
  @Input() marginBottom = 20;
  @Input() className = '';

  @Input() hideBtnListOnDesktop = false;
  @Input() hideBtnListOnMobile = false;
  @Input() isBtnListDisabled = false;
  @Input() btnList;
  @Input() dataShimmer;
  @Input() menuType = 'main';

  @Output() onFunctionCalledEvent = new EventEmitter<{ functionName: string, item: object }>();

  @Input() sectionDescription = '';
  showSectionDescription: boolean = false;

  constructor(private routeService: RouteService, private generalConfigService: GeneralConfigService) {
    this.sectionTitle = this.sectionTitle ? this.sectionTitle : this.routeService.getSectionTitleFromMenu();
    this.sectionDescription = this.sectionDescription ? this.sectionDescription : this.routeService.getSectionTranslationDataFromMenu('description');
    const showSectionDescriptionVal = this.generalConfigService.getConfigProperty('pageTopConfig')?.showSectionDescription;
    this.showSectionDescription = showSectionDescriptionVal !== undefined ? showSectionDescriptionVal : false;
  }

  ngOnInit() {
  }

  get hasSectionDescription(): boolean {
    return this.sectionDescription?.length && this.showSectionDescription;
  }
}
