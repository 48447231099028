import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AlertService, LoaderService, SearchFiltersService } from 'foo-framework';
import { ValidationService } from '../services/validation.service';
import { isNumber, keyBy, values } from 'lodash';
import { FooPaginatedSelectDialogTabs } from './foo-paginated-select-dialog-tabs.interface';


@Component({
  selector: 'foo-paginated-select-dialog',
  templateUrl: './foo-paginated-select-dialog.component.html',
  styleUrls: ['./foo-paginated-select-dialog.component.scss']
})

export class FooPaginatedSelectDialogComponent implements OnInit {

  dataShimmer: { [key: string]: boolean } = {};
  dialogTitle;
  button;

  activeTab;
  tabsConfig;
  tabs: { [key: string]: FooPaginatedSelectDialogTabs } = {};

  searchText: { [key: string]: string } = {};
  defaultItemsPerPage = 20;
  itemsPerPage: { [key: string]: number } = {};
  pageNumber: { [key: string]: number } = {};
  totalData: { [key: string]: number } = {};
  paginationInput: { [key: string]: any } = {};

  items: { [key: string]: any[] } = {};
  controlValue: { [key: string]: any } = {};
  loadedItemsById: { [key: string]: any } = {};

  allIsChecked: { [key: string]: boolean } = {};
  excludedItems: { [key: string]: any[] } = {};
  selectedItems: { [key: string]: any[] } = {};

  totalCount: { [key: string]: number } = {};
  selectedCount: { [key: string]: number } = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    protected translate: TranslateService,
    protected alert: AlertService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<FooPaginatedSelectDialogComponent>,
    protected loaderService: LoaderService,
    private validationService: ValidationService,
    protected searchFiltersService: SearchFiltersService
  ) {
    this.dialogTitle = this.translate.instant(data.dialogTitle || 'paginatedSelect.selectItems');
    this.tabsConfig = data.tabsConfig;
    this.tabs = keyBy(this.tabsConfig, 'key')
    this.activeTab = (this.tabsConfig.length == 1 ? this.tabsConfig[0].key : data.activeTab) || this.tabsConfig[0].key;
    this.initComponentTabs();
    this.button = data.button.length || {
      label: this.translate.instant('paginatedSelect.done'),
      class: 'foo-btn-primary foo-btn-center'
    };
  }

  ngOnInit(): void {
    this.getData(1, this.activeTab);
  }

  getItemWithKeyProp(tab: string, item: any) {
    return item?.[this.tabs[tab]?.keyProp];
  }

  initComponentTabs(): void {
    const defaultItems = { selected: [], excluded: [], selectedCount: 0, all: false };
    for (let x = 0; x < this.tabsConfig.length; x++) {
      if (this.data.controlValue == null) {
        const defaultValueWithKey = [];
        defaultValueWithKey[this.tabsConfig[x].key] = defaultItems;
        this.controlValue = defaultValueWithKey;
      } else {
        this.controlValue[this.tabsConfig[x].key] = !values(this.data.controlValue[this.tabsConfig[x].key]).length ? defaultItems : this.data.controlValue[this.tabsConfig[x].key];
      }
      this.initializeTab(this.tabsConfig[x].key);
    }
  }

  initializeTab(tab: string): void {
    this.pageNumber[tab] = this.pageNumber[tab] || 1;
    this.itemsPerPage[tab] = this.itemsPerPage[tab] || this.defaultItemsPerPage;
    this.totalCount[tab] = 0;
    this.searchText[tab] = this.searchText[tab] || '';
    this.selectedCount[tab] = this.controlValue[tab]['selectedCount'] || 0;
    this.loadedItemsById[tab] = {};
    this.selectedItems[tab] = [];
    this.excludedItems[tab] = [];
    this.items[tab] = [{}];
    this.tabsDefaultValues(tab);
  }

  tabsDefaultValues(tab: string) {
    const defaultData: FooPaginatedSelectDialogTabs = {
      key: tab,
      title: this.tabs[tab].title,
      labels: [
        {
          value: 'checked',
          templateRef: 'checkbox',
          className: 'remove-auto-shimmer mr-10',
          span: 0.05
        },
        ...this.tabs[tab].labels
      ],
      search: this.tabs[tab].search || {
        placeholder: "paginatedSelect.search",
        class: ''
      },
      totalCount: this.tabs[tab].totalCount || {
        label: 'paginatedSelect.all'
      },
      selectedCount: this.tabs[tab].selectedCount || {
        label: 'paginatedSelect.selectedItems'
      },
      dataSource: this.tabs[tab]?.dataSource,
      getIsDisabled: (this.tabs[tab]?.getIsDisabled) || (() => {
        return false;
      }),
      keyProp: this.tabs[tab]?.keyProp || 'id',
      canSelectAll: 'canSelectAll' in this.tabs[tab] ? this.tabs[tab].canSelectAll : true
    };
    this.tabs[tab] = defaultData
  }

  setPaginator(tab: string): void {
    this.paginationInput[tab] = {
      pageNumber: this.pageNumber[tab],
      totalData: this.totalData[tab],
      dataPerpage: this.itemsPerPage[tab]
    };
  }

  clearSearchInput(data: any, tab: string) {
    this.searchText[tab] = '';
    this.getData(1, tab);
  }

  searchData(search: string, tab: string) {
    this.searchText[tab] = search;
    this.getData(1, tab);
  }

  getData(pageNumber: number, tab: string) {
    this.dataShimmer[tab] = true;
    this.pageNumber[tab] = pageNumber;
    this.tabs[tab].dataSource(this.searchText[tab], this.pageNumber[tab], this.itemsPerPage[tab]).subscribe(resp => {
      this.items[tab] = resp.data;
      this.totalData[tab] = resp.total;
      this.itemsPerPage[tab] = resp.dataPerpage || this.defaultItemsPerPage;
      if (!this.searchText[tab]) this.totalCount[tab] = 'selectableTotal' in resp ? resp?.selectableTotal || 0 : resp?.total;
      this.dataShimmer[tab] = false;
      this.setControlValue(tab);
      this.setPaginator(tab);
    });
  }

  updateLoadedItems(tab: string) {
    if (this.items[tab].length) {
      this.items[tab].forEach(item => {
        this.updateLoadedItem(tab, item, this.allIsChecked[tab]);
      });
    }
  }

  updateLoadedItem(tab: string, item: any, isChecked: boolean) {
    const item_id = this.getItemWithKeyProp(tab, item);
    const getIsDisabled = this.tabs[tab]?.getIsDisabled(item);
    isChecked = isChecked && !getIsDisabled;
    if (this.loadedItemsById[tab].hasOwnProperty(item_id)) {
      this.loadedItemsById[tab][item_id].checked = isChecked;
      this.loadedItemsById[tab][item_id].disabled = getIsDisabled;
      this.loadedItemsById[tab][item_id].item = item;
    } else {
      this.loadedItemsById[tab][item_id] = {
        item: item,
        checked: isChecked,
        disabled: getIsDisabled
      };
    }
  }

  setControlValue(tab: string) {
    this.allIsChecked[tab] = this.controlValue[tab]?.['all'] || false;
    this.selectedItems[tab] = this.controlValue[tab]?.['selected'] || [];
    this.excludedItems[tab] = this.controlValue[tab]?.['excluded'] || [];

    this.updateLoadedItems(tab);

    if (this.selectedItems[tab].length) {
      this.selectedItems[tab].forEach(item => {
        this.updateLoadedItem(tab, item, true);
      });
    }
    if (this.excludedItems[tab].length) {
      this.excludedItems[tab].forEach(item => {
        this.updateLoadedItem(tab, item, false);
      });
    }
    this.getControlValue(tab);
  }

  getTotalCount() {
    const results = values(this.controlValue);
    let totalCount = 0;
    if (results.length) {
      results.forEach(item => {
        if (item['selectedCount']) {
          if (isNumber(totalCount)) {
            totalCount += item['selectedCount'];
          } else {
            totalCount = item['selectedCount'];
          }
        }
      });
    }
    return totalCount;
  }

  getControlValue(tab: string, all = false): void {
    this.excludedItems[tab] = [];
    this.selectedItems[tab] = [];
    if (!this.allIsChecked[tab] && !all) {
      this.selectedItems[tab] = values(this.loadedItemsById[tab])
        .filter(item => item['checked'])
        .map(item => item['item']);
    } else {
      this.excludedItems[tab] = values(this.loadedItemsById[tab])
        .filter(item => !item['checked'] && !item['disabled'])
        .map(item => item['item']);
    }

    this.selectedCount[tab] = this.allIsChecked[tab] ? this.totalCount[tab] - this.excludedItems[tab].length : this.selectedItems[tab].length;
    if (this.totalCount[tab] === this.selectedCount[tab]) this.allIsChecked[tab] = true;

    this.controlValue[tab] = {
      selected: this.selectedItems[tab],
      excluded: this.excludedItems[tab],
      selectedCount: this.selectedCount[tab],
      all: this.allIsChecked[tab]
    };
  }

  selectChanged(event, tab: string, item: any) {
    const isChecked = event.checked;
    this.updateLoadedItem(tab, item, isChecked);
    this.getControlValue(tab);
  }

  selectUnselectAll(event, tab: string) {
    this.allIsChecked[tab] = event.checked;
    for (let item_id in this.loadedItemsById[tab]) {
      this.updateLoadedItem(tab, this.loadedItemsById[tab][item_id]['item'], this.allIsChecked[tab]);
    }
    this.getControlValue(tab, true);
  }

  submit() {
    let returnData = { totalCount: 0 };
    this.tabsConfig.forEach(item => {
      returnData[item.key] = this.controlValue[item.key];
    });
    const totalCount = this.getTotalCount();
    returnData['totalCount'] = totalCount;
    this.dialogRef.close(returnData);
  }

  changeTab(tab: string): void {
    if (this.activeTab !== tab) {
      this.activeTab = tab;
    }
    this.getData(1, tab);
    this.searchFiltersService.setSearchInfo(this);
  }
}
