<button class="close-icon" mat-dialog-close><span class="foo-icon foo-close-dark"></span></button>
<div mat-dialog-title>
    <p>{{ "shared-component.countries.addCountries" | translate }}</p>
</div>
<mat-dialog-content formValidationContainer class="form-wrapper mt-10">
    <form [formGroup]="form" formValidation autocomplete="off" (ngSubmit)="save()" id="ngForm">
        <div class="form-group" *ngFor="let country of supportedCountries">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
              <mat-checkbox class="example-margin" formControlName="{{country.id}}">
                {{country.name }}
              </mat-checkbox>
            </div>
        </div>
    </form>
</mat-dialog-content>

<div mat-dialog-actions class="pt-28">
    <button form="ngForm" [disabled]="disableButton" class="foo-btn-primary  foo-btn-center" type="submit">{{
        "team.save" |
        translate }}</button>
</div>
