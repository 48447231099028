import { Injectable } from '@angular/core';
import * as jose from 'jose';

@Injectable({
  providedIn: 'root',
})
export class CryptoService {
  private publicKey;
  private privateKey;
  constructor() {}

  public async generateKeyPairForRequest() {
    const { publicKey, privateKey } = await jose.generateKeyPair('RSA-OAEP-256', {modulusLength: 4096});
    this.publicKey = publicKey;
    this.privateKey = privateKey;
  }

  public async getPubKey() {
    return await jose.exportSPKI(this.publicKey);
  }

  /**
   * This function returns the decrypted data as plain text
   * @param data Object containing compact_encrypted_data
   */
  public async compactDecrypt(data) {
    const { plaintext, protectedHeader } = await jose.compactDecrypt(data["compact_encrypted_data"], this.privateKey);
    let decryptedData = new TextDecoder().decode(plaintext);
    return decryptedData;
  }
}
