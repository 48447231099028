export interface ValidatorPatternsInterface {
  [validation: string]: string | RegExp;
}

export enum ValidatorPatterns {
  default = 'default',
  email = 'email',
  number = 'number',
  url = 'url',
  version = 'version',
  coordinates = 'coordinates',
  // Add more patterns as needed
}
export const ValidatorPatternsConfig: ValidatorPatternsInterface = {
  [ValidatorPatterns.email]: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
  [ValidatorPatterns.number]: /^[0-9]+$/,
  [ValidatorPatterns.url]: /^(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?$/,
  [ValidatorPatterns.version]: /^(?!\.)(?!.*\.$)(?!.*\.\.)[0-9_.]+$/,
  [ValidatorPatterns.coordinates]: /^(\-?\d+(\.\d+)?),\s*(\-?\d+(\.\d+)?)$/,
  // Add more patterns as needed
};

export const errorMessages = {
  pattern: {
    [ValidatorPatterns.default]: 'validations.pattern',
    [ValidatorPatterns.email]: 'validations.email',
    [ValidatorPatterns.number]: 'validations.appNumbersOnly',
    [ValidatorPatterns.url]: 'validations.urlpattern'
  },
  required: 'validations.required_field',
  email: 'validations.email',
  onlyNumbers: 'validations.appNumbersOnly',
  matDatepickerParse: 'validations.entervaliddate',
  matDatepickerMin: 'validations.togreaterthanfromdate',
  matDatepickerMax: 'validations.togreaterthanfromdate',
  lessthan: 'validations.minLessThanMax',
  alphabetsOnly: 'validations.alphabetsonly',
  validatePhoneNumber: 'validations.mobilepattern',
  maxwords: 'validations.defaultMaxWords',
  maxlength: 'validations.maxLength',
  minlength: 'validations.minLength',
  noSpecialCharacters: 'validations.noSpecialCharacters',
  min: 'validations.defaultMinimumNumber',
  max: 'validations.defaultMaximumNumber',
  arabicCharactersOnly: 'validations.arabicCharactersOnly',
  alreadyAdded: 'validations.alreadyAdded',
}
