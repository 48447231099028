import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-app-info-settings',
  templateUrl: './app-info-settings.component.html',
  styleUrls: ['./app-info-settings.component.scss']
})
export class AppInfoSettingsComponent {

  @Input() data: any;
  @Input() configurations: any;
  @Input() api_url: string = '';
  @Input() api_url_upload_icon: string = '';
  @Input() api_url_upload_document_icon: string = '';
  @Input() api_url_upload_main_icon_document_types: string = '';
  @Input() permissions: any;
  @Input() app_id: any;
  @Input() app_name: any;
}
