
<ng-select
  class="form-control"
  #select
  appNgSelectCustom
  [items]="items"
  [loading]="isLoading"
  [formControl]="control"
  [searchable]="searchable"
  [clearable]="clearable"
  [hideSelected]="hideSelected == null || hideSelected == 'undefined' ? items.length : hideSelected"
  [placeholder]="placeholder"
  [selectReadonly]="readonly"
  [closeOnSelect]="closeOnSelect"
  [bindLabel]="titleProp"
  [clearSearchOnAdd]="clearSearchOnAdd"
  [compareWith]="compareWith.bind(this)"
  [searchFn]="searchFn.bind(this)"
  [bindValue]="keyProp"
  (close)="onAdd($event)"
  (open)="onOpen($event)"
  (search)="onSearch($event)"
  [multiple]="multiple"
  (scrollToEnd)="onScrollToEnd($event)">
  <ng-template ng-header-tmp *ngIf="headerTemplate">
    <ng-container
    *ngTemplateOutlet="getTemplate(headerTemplate); context: {selectControl: selectControl}">
   </ng-container>
  </ng-template>
  <ng-template ng-footer-tmp *ngIf="footerTemplate">
    <ng-container
    *ngTemplateOutlet="getTemplate(footerTemplate); context: {selectControl: selectControl}">
   </ng-container>
  </ng-template>
  <ng-template ng-multi-label-tmp let-items="items"  *ngIf="multiLabelTemplate">
    <ng-container
    *ngTemplateOutlet="getTemplate(multiLabelTemplate); context: { items: items}">
   </ng-container>
  </ng-template>
  <ng-template ng-label-tmp let-item="item">
    <ng-container
      *ngTemplateOutlet="getTemplate(labelTemplate); context: { item: item}">
    </ng-container>
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-index="index">
    <ng-container
      *ngTemplateOutlet="getTemplate(optionTemplate); context: { item: item, index: index}">
    </ng-container>
  </ng-template>
</ng-select>


<ng-template [frameworkTemplate]="'selectDefault'" let-item="item">
  {{item[titleProp]}}
</ng-template>
