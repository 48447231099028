<div *ngIf="hasFilter" class="row justify-content-between align-items-center mt-12 ">
  <div class="col-9 col-lg-10">
    <div class="row ml-0 mr-0">
      <ng-container *ngFor="let obj of configArr">

        <ng-container *ngIf="obj?.type==='range'">
          <div class="selected-filter flex align-items-center"
               *ngIf="obj?.formProp1 && filter?.[obj.formProp1] || obj?.formProp2 && filter?.[obj.formProp2]">
         <span *ngIf="obj?.formatDate">
           {{filter?.[obj.formProp1] ? (filter[obj.formProp1] | date:obj.formatDate) : ''}}
           {{filter?.[obj.formProp2] ? ' > ' + (filter[obj.formProp2] | date:obj.formatDate) : ''}}
         </span>
            <span *ngIf="!obj?.formatDate">
            {{filter?.[obj.formProp1] ? (filter[obj.formProp1]) + ' ' + (obj?.currency) : ''}}
              {{filter?.[obj.formProp2] ? ' > ' + (filter[obj.formProp2]) + ' ' + (obj?.currency) : ''}}
         </span>
            <button class="btn ml-10 d-flex"
                    (click)="clearFilterFields(obj?.formProp1, obj?.formProp2)">
              <span class="foo-icon foo-close-primary"></span>
            </button>
          </div>
        </ng-container>

        <ng-container *ngIf="obj?.type === 'value' || obj?.type === 'object'">
          <div class="selected-filter flex align-items-center"
               *ngIf="obj?.isNumber && isNumber(filter?.[obj.formProp]) || !obj?.isNumber && filter?.[obj.formProp]">
            <span>{{translationPathFun(obj) | translate}}</span>
            <button class="btn ml-10 d-flex" (click)="clearFilterFields(obj?.formProp)" *ngIf="(obj.hasOwnProperty('hideClearFilter') ? (isFunction(obj.hideClearFilter) ? !obj.hideClearFilter() : !obj.hideClearFilter) : true)">
              <span class="foo-icon foo-close-primary"></span>
            </button>
          </div>
        </ng-container>

      </ng-container>
    </div>
  </div>
  <div class="col-3 col-lg-2 text-end" *ngIf="!hideClearAllFilter">
    <button class="btn ml-8 filter-clear-all mr-4" (click)="clearAllFilterFields()">
      {{'general.ClearAll'|translate}}
    </button>
  </div>
</div>
