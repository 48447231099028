import {Injectable, Injector} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
  AlertService,
  CurrencyService,
  MakeCallService,
  ParserService,
  ReplaceSeparatorAndCapitalizePipe,
  TokenStorage
} from 'foo-framework';
import _, {
  differenceBy,
  filter,
  find,
  get,
  indexOf,
  isArray,
  isEmpty,
  isObject,
  keys,
  map,
  orderBy,
  toInteger,
  toPairs,
  unionBy,
  flatten,
  isString
} from 'lodash';
import {TranslationService} from './translation.service';
import moment from 'moment';
import { DecimalPipe } from '@angular/common';
import { FooPaginatedListingDialogComponent } from '../foo-paginated-listing-dialog/foo-paginated-listing-dialog.component';
import { map as rxjsMap } from "rxjs/operators";
import { FooPaginatedListingDialogTabsConfigs } from '../foo-paginated-listing-dialog/foo-paginated-listing-dialog-tabs-configs.interface';
import { ComponentType } from '@angular/cdk/portal';

interface TransactionDetailsConfigs {
  column_name?: string,
  colmn_name_class?: string,
  lang?: string,
  old_value?: any,
  old_value_class?: string,
  new_value?: any,
  new_value_class?: string,
  displayAs?: DisplayType,
  dialogConfigs?: DialogConfigs,
}

interface DialogConfigs {
  component: ComponentType<any>,
  oldValueDialogConfigs?: ValueDialogConfigs,
  newValueDialogConfigs?: ValueDialogConfigs,
}

interface ValueDialogConfigs {
  tabsConfigs?: any[],
  dialogTitle?: string,
}

// Define a type for the possible display types
type DisplayType = 'HTML' | 'InheritStyleHTML' | 'JSON' | 'dialog';

@Injectable({
  providedIn: 'root'
})
export class TransactionDetailsService {

  templatetypes = [];
  branchTypesById = [];
  curLang: string;
  transactionDetailsServices: any = {};
  cardProducts: any[] = [];

  constructor(
    protected injector: Injector,
    protected translate: TranslateService,
    protected makecall: MakeCallService,
    protected alert: AlertService,
    protected parser: ParserService,
    protected translationService: TranslationService,
    protected decimalPipe: DecimalPipe,
    protected tokenStorage: TokenStorage,
    protected replaceSeparatorAndCapitalizePipe: ReplaceSeparatorAndCapitalizePipe,
    protected currencyService: CurrencyService) {
    this.curLang = this.tokenStorage.getCurrentLanguage();
    let user = JSON.parse(this.tokenStorage.getUser());
    if (this.isFaas && user?.is_mother_company) this.getCardProducts();
    const services = this.approvalServices();
    if (services.length) {
      services.forEach((serviceName) => {
        this.transactionDetailsServices[serviceName] = this.getService(serviceName);
      });
    }
  }

  get currentLanguage(): string {
    return this.translate.currentLang || 'en';
  }

  get isFaas(): boolean {
    const conf = window['configuration'] ? JSON.parse(window['configuration']) : null
    if (conf && conf?.attributes) {
      let module = (conf?.attributes).find(item => item?.key === "module")?.value;
      return module === 'faas/';
    }
  }

  getCardProducts() {
    this.makecall.postRequest('card/cardProducts', []).subscribe({
      next: (data: any) => {
        this.cardProducts = data['app_card_products'];
      }
    });
  }

  groupByFeatures(arr) {
    let result = [];
    if (arr && arr.length > 0) {
      arr.forEach((item: any) => {
        if (this.getGroup(item) && result.length > 0 && result.find(e => e.key === this.getGroup(item))) {
          let dataToPush = {
            'key': this.getGroup(item, 'features'),
            'value': item.transaction_details
          };
          let objIndex = result.findIndex((obj => obj.key == this.getGroup(item)));
          result[objIndex].value.push(dataToPush);
        } else {
          let dataToPush = {
            'key': this.getGroup(item),
            'value': [{
              'key': this.getGroup(item, 'features'),
              'value': item.transaction_details
            }]
          };
          result.push(dataToPush);
        }
      });
      return result;
    }
  }

  getGroup(permissionItem, type: string = null) {
    const column_name = this.getColumn(permissionItem.transaction_details, 'menu_feature_id');
    if (column_name && column_name.feature) {
      return (type && type === 'features') ? column_name.feature.title : column_name.feature.group?.translations?.find(translation => translation.lang === this.curLang).title;
    }
  }

  groupByTransactionDetails(permissionTransactionDetails) {
    let permissionItemsAsGroup: any = {};
    if (permissionTransactionDetails) {
      permissionTransactionDetails?.forEach((transactionDetail: any) => {
        if (transactionDetail.column_name !== 'id' && transactionDetail.column_name !== 'role_id' && transactionDetail.column_name !== 'menu_feature_id') {
          permissionItemsAsGroup[transactionDetail.column_name] = {
            old_value: transactionDetail.old_value,
            new_value: transactionDetail.new_value,
          };
        }
      });
    }
    return permissionItemsAsGroup;
  }

  approvalServices(){
    let appConfig = window['appConfig'] ? JSON.parse(window['appConfig']) : 0;
    const approvalServicesConfig = appConfig['approval.services'];
    let approvalServices = [];
    if(appConfig && typeof appConfig !== 'undefined' &&  approvalServicesConfig?.value.length) {
      const stringifiedArray = approvalServicesConfig?.value;
      approvalServices = stringifiedArray.split(",");
    }
    return approvalServices;
  }

  getService(serviceName : string){
    try {
      return this.injector.get(serviceName);
    }catch (e) {
      return null;
    }
  }

  checkService(fn: string, args: any) {
    const services = this.approvalServices();
    let result = null;
    if (services.length) {
      services.forEach((serviceName) => {
        const service = this.getService(serviceName);
        if (service) {
          result = service[fn].apply(service, args);
          if (result) {
            return result;
          }
        }
      });
    }
    return result;
  }

  transformData(data, category, roles) {
    if (data.length > 0) {
      const serviceTransformData = this.checkService('transformData', [data,category,roles]);
      if (serviceTransformData) {
        return serviceTransformData;
      }
      this.getCustomValuesData(data);
      if (data[0].action === 'delete') {
        return this.getDeleteInfo(data);
      }
      if (data[0].action === 'edit' || data[0].action === 'add') {
        data[0].description_transaction = this.getDescriptionItem(data[0], category);
      }
      if (category === 'generalConf' || category === 'settings') {
        return this.getGeneralConfigurationData(data);
      }
      if (category === 'crypto_config') {
        return this.getCryptoConfigurationData(data);
      } else if (category === 'customer') {
        return this.getCustomerData(data);
      } else if (category === 'cmsUsers') {
        return this.getPendingCMSUserData(data, roles);
      } else if (category === 'branches' || category === 'ATMs' || category === 'locations' || category === 'subregions') {
        return this.getPendingBranchData(data);
      } else if (category === 'app_mode') {
        return this.getPendingAppModeData(data);
      } else if (category === 'pages' || category === 'content_management') {
        return this.getPendingPagesModeData(data);
      } else if (category === 'templates') {
        return this.getPendingTemplates(data);
      } else if (category === 'update_rules') {
        return this.getPendingRules(data);
      } else if (category === 'notification') {
        return this.getPendingNotifications(data);
      } else if (category === 'system_configuration') {
        return this.getSystemConfigurationData(data);
      } else if (category === 'sva_accounts') {
        return this.getSvaAccountsData(data);
      } else if (category === 'departments') {
        return this.getPendingDepartmentData(data);
      } else if (category === 'employees') {
        return this.getPendingEmployeeData(data);
      } else if (data[0]?.key === 'cardgroups') {
        return this.getPendingCardGroupstData(data);
      } else if (data[0]?.key === 'cardtypes') {
        return this.getPendingCardTypesData(data);
      } else if (category === 'cards') {
        return this.getPendingCardsData(data);
      } else if (category === 'companies') {
        return this.getPendingCompaniesData(data);
      }
      else if (category === 'transfers') {
        return this.getPendingTransfersData(data);
      }
        // else if (category === 'admin_idv_sendtojava') {
        //   return this.getPendingIDVData(data);
      // }
      else if (category === 'pricing_management') {
        return this.getPricingManagementData(data);
      } else if (category === 'merchants_management') {
        return this.getMerchantManagementData(data);
      } else if (category === 'location') {
        return this.getPendingLocationData(data);
      } else if (category === 'feedback') {
        return this.getPendingFeedbackData(data);
      } else if (category === 'workflow') {
        return this.getWorkflowData(data);
      } else if (category === 'owner.app' || category === 'admin_idv_sendtojava') {
        return this.getPendingOwnerAppData(data);
      } else if (category === 'dashboard') {
        return this.getPendingDashboardData(data);
      } else if (category === 'makerchecker') {
        return this.getPendingMakercheckerdData(data);
      } else if (category === 'idv_operation_results' || category === 'idv_statuses' || category === 'idv') {
        return this.getPendingIdvOperationResultsdData(data);
      } else if (category === 'dynamic_forms_ekyc' || category === 'dynamic_forms_ekyb') {
        return this.getPendingDynamicFormsEkycData(data);
      }
      else if (category === 'dynamic_forms') {
        return this.getPendingDynamicFormsData(data);
      }
      else if (category === 'loans') {
        return this.getPendingLoanData(data);
      } else if (category === 'accounts') {
        return this.getPendingAccountData(data);
      } else if (category === 'web_views') {
        return this.getWebViewsData(data);
      } else if (category === 'api_token') {
        return this.getApiTokenData(data);
      } else if (category === 'menu_extensions') {
        return this.getMenuExtensionsData(data);
      } else {
        return { pending_transaction: data[0] };
      }
    } else {
      return '';
    }
  }

  getCustomValuesData(data) {
    /*
    Backend has to send in extra {custom_values: {column_name: "Value"}}
    or {custom_values: {column_name: {old_value: "Value", new_value: "Value"}}}
    This is used when we want to show value in approval that the backend doesn't have access to
     */
    data.forEach((item) => {
      if (item.extra && item.extra.custom_values) {
        Object.keys(item.extra.custom_values).forEach((col) => {
          const filteredColumn = item.transaction_details.filter(trxn => trxn.column_name === col);
          if (filteredColumn.length) {
            if (typeof item.extra.custom_values[col] === "string")
              filteredColumn[0].new_value = item.extra.custom_values[col];
            else if (typeof item.extra.custom_values[col] === "object") {
              filteredColumn[0].old_value = item.extra.custom_values[col].old_value ?? "";
              filteredColumn[0].new_value = item.extra.custom_values[col].new_value ?? "";
            }
          }
        });
      }
    });
  }

  hasJsonStructure(str) {
    if (typeof str !== 'string') return false;
    try {
      const result = JSON.parse(str);
      const type = Object.prototype.toString.call(result);
      return type === '[object Object]'
        || type === '[object Array]';
    } catch (err) {
      return false;
    }
  }

  convertToString(item) {
    let str = '';

    if (Object.prototype.toString.call(item) === '[object Array]') {
      for (var val of item) {
        if (typeof val === "object") {
          let count = 0;
          for (const [k, v] of Object.entries(val)) {
            count += 1;
            str += isArray(val) ? '' : (k.toString() +  ' : ');  // Type is string
            str += (isObject(v) ?  this.convertToString(v) : ((this.hasJsonStructure(v) ? JSON.parse(v.toString()).toString() : v.toString()) || ' "" ')) + ' \t ';  // Type is any

            if (Object.entries(val).length !== count) {
              str += ' , ';
            }

          }
          str += ' \t \n  ';
        } else {
          str += val.toString();
        }
      }
      str += ' ';
    } else {
      for (const [k, v] of Object.entries(item)) {
        str += k.toString() + ' : ';  // Type is string
        str += (isObject(v) ? JSON.stringify(v)  : (v.toString() || ' "" ')) + ' \t\n  ';  // Type is any
      }
    }
    return str;
  }

  getPendingTransfersData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };

    if (_.find(transactions[0].transaction_details, {column_name: 'online_transfer_limit'}) || _.find(transactions[0].transaction_details, {column_name: 'offline_transfer_limit'})) {
      if(transactions && transactions.length && transactions[0]?.feature_item?.transfer_config) {
        transactions[0].feature_item.transfer_config = this.translationService.getTranslationData(transactions[0]?.feature_item?.transfer_config);
        let arrayOfObject = [
          {
            key: this.translate.instant('approval.description'),
            value: this.replaceSeparatorAndCapitalizePipe.transform(transactions[0]?.feature_item?.transfer_config?.translations?.[this.curLang]?.description)
          },
          {
            key: this.translate.instant('approval.currency'),
            value: transactions[0]?.feature_item?.currency
          }
        ];
        dataToSend.pending_transaction.description_transaction = this.transformJsonToString(arrayOfObject);
      }
    } else if (transactions?.length && transactions[0]?.feature_item && _.find(transactions[0].transaction_details, {column_name: 'otp'})) {
      transactions[0].feature_item = this.translationService.getTranslationData(transactions[0].feature_item);
      let arrayOfObject = [
        {
          key: this.translate.instant('approval.description'),
          value: this.replaceSeparatorAndCapitalizePipe.transform(transactions[0]?.feature_item?.translations?.[this.curLang]?.description)
        }
      ];
      dataToSend.pending_transaction.description_transaction = this.transformJsonToString(arrayOfObject);
    }
    if (transactions && transactions.length) {
      for (const element of transactions[0].transaction_details) {
        if (element.column_name === 'otp') {
          element.column_name = (element.column_name)?.toUpperCase();
          element.old_value = element.old_value == 1 ? this.translate.instant('approval.active') : this.translate.instant('approval.inactive');
          element.new_value = element.new_value == 1 ? this.translate.instant('approval.active') : this.translate.instant('approval.inactive');
        }
        if (element.column_name === 'online_transfer_limit' || element.column_name === 'offline_transfer_limit') {
          dataToSend.pending_transaction.transaction_details.push({
            column_name: element.column_name,
            old_value: this.decimalPipe.transform(element.old_value, '.2'),
            new_value: this.decimalPipe.transform(element.new_value, '.2'),
          });
        } else {
          dataToSend.pending_transaction.transaction_details.push({
            column_name: element.column_name,
            old_value: element.old_value,
            new_value: element.new_value,
          });
        }
      }
    }
    return dataToSend;
  }
  getDeleteInfo(data) {
    const serviceDeleteInfo = this.checkService('getDeleteInfo', [data]);
    if (serviceDeleteInfo?.pending_transaction?.transaction_details.length) {
      return serviceDeleteInfo;
    }
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };

    const item = data[0].feature_item;
    if (data[0].key === 'fee') {
      if (item.title) {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: this.translate.instant('approval.status'),
          old_value: this.translate.instant('approval.active'),
          new_value: this.translate.instant('approval.deleted')
        });

        let arrayOfObject = [
          {
            key: this.translate.instant('fees.fee'),
            value: item?.title
          },
          {
            key: this.translate.instant('fees.amount'),
            value: item?.amount
          },
        ];

        dataToSend.pending_transaction.description_transaction = this.transformJsonToString(arrayOfObject);
      }
    }
    if (data[0].key === 'pricing_rule') {
      if (item.title) {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: this.translate.instant('approval.status'),
          old_value: this.translate.instant('approval.active'),
          new_value: this.translate.instant('approval.deleted')
        });

        dataToSend.pending_transaction.description_transaction = this.getDescriptionTransactionDeletePricingRule(data);
      }
    }
    if (data[0].key === "acquirer_configuration") {
      if (item.title) {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: this.translate.instant('approval.status'),
          old_value: this.translate.instant('approval.active'),
          new_value: this.translate.instant('approval.deleted')
        });

        let arrayOfObject = [
          {
            key: this.translate.instant('approval.acquirerConfigurationTitle'),
            value: item?.title
          },
          {
            key: this.translate.instant('approval.accountNumber'),
            value: item?.account_number
          },
        ];

        dataToSend.pending_transaction.description_transaction = this.transformJsonToString(arrayOfObject);
      }
    }
    if (data[0].key === 'mailsettings') {
      if (item.smtp_name) {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: this.translate.instant('approval.smtp'),
          old_value: item.smtp_name,
          new_value: this.translate.instant('approval.notAvailable')
        });
      }
    } else if (data[0].key === 'cms_users') {
      if (item.email) {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: this.translate.instant('approval.status'),
          old_value: this.translate.instant('approval.active'),
          new_value: this.translate.instant('approval.inactive')
        });

        dataToSend.pending_transaction.description_transaction = this.getDescriptionTransactionActiveInactiveCmsUsersActions(data);
      }
    } else if (data[0].key === 'update_rules') {
      dataToSend.pending_transaction.transaction_details.push({
        column_name: this.translate.instant('approval.status'),
        old_value: this.translate.instant('approval.active'),
        new_value: this.translate.instant('approval.deleted')
      });

      dataToSend.pending_transaction.description_transaction = this.getDescriptionTransactionUpdateRulesActions(data);

    } else if (data[0].key === 'customer') {
      if (item.username) {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: this.translate.instant('approval.username'),
          old_value: item.username,
          new_value: this.translate.instant('approval.notAvailable')
        });
      }
    } else if (data[0].key === 'sms_settings') {
      if (item.type) {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: this.translate.instant('approval.type'),
          old_value: item.type,
          new_value: this.translate.instant('approval.notAvailable')
        });
      }
    } else if (data[0].key === 'customer.card') {
      if (item.masked_pan) {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: this.translate.instant('approval.card'),
          old_value: item.masked_pan,
          new_value: this.translate.instant('approval.notAvailable')
        });
        dataToSend.pending_transaction.description_transaction = this.translate.instant('approval.customer') + ' : ' + item.user.username;
      }
    } else if (data[0].key === 'notification.history') {
      if (item.message) {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: this.translate.instant('approval.message'),
          old_value: item.message,
          new_value: this.translate.instant('approval.notAvailable')
        });
      }
    } else if (data[0].key === 'blacklist_bin_range') {
      dataToSend.pending_transaction.transaction_details.push({
        column_name: this.translate.instant('approval.binRange'),
        old_value: data[0].feature_item.bin_range,
        new_value: this.translate.instant('approval.notAvailable')
      });
    } else if (data[0].key === 'bank.bin_range') {
      dataToSend.pending_transaction.transaction_details.push({
        column_name: this.translate.instant('approval.bin'),
        old_value: data[0].feature_item.bin,
        new_value: this.translate.instant('approval.notAvailable')
      });
    } else if (data[0].key === 'faqs') {
      dataToSend.pending_transaction.transaction_details.push({
        column_name: this.translate.instant('approval.status'),
        old_value: this.translate.instant('approval.active'),
        new_value: this.translate.instant('approval.deleted')
      });
      let arrayOfObject = [
        {
          key: this.translate.instant('approval.question'),
          value: item.question
        }
      ];
      dataToSend.pending_transaction.description_transaction = this.transformJsonToString(arrayOfObject);
    } else if (data[0].key === 'departments') {
      dataToSend.pending_transaction.transaction_details.push({
        column_name: this.translate.instant('approval.email'),
        old_value: data[0].feature_item.email,
        new_value: this.translate.instant('approval.notAvailable')
      });
      dataToSend.pending_transaction.transaction_details.push({
        column_name: this.translate.instant('approval.head_of_department'),
        old_value: data[0].feature_item.head_of_department,
        new_value: this.translate.instant('approval.notAvailable')
      });
    } else if ((data[0].key === 'branches') || (data[0].key === 'faq_categories') || (data[0].key === 'templates') ||
      (data[0].key === 'loantypes') || (data[0].key === 'loangroups') || (data[0].key === 'accounttypes') ||
      (data[0].key === 'accountgroup') || (data[0].key === 'regions') || (data[0].key === 'subregions') || (data[0].key === 'cardgroups')) {
      dataToSend.pending_transaction.transaction_details.push({
        column_name: this.translate.instant('approval.status'),
        old_value: this.translate.instant('approval.active'),
        new_value: this.translate.instant('approval.deleted')
      });
      let arrayOfObject = [
        {
          key: this.translate.instant('approval.name'),
          value: item.name
        }
      ];
      if (data[0].key === 'templates') {
        let types = [
          {
            type : 0,
            name: "Push"
          },
          {
            type : 2,
            name: "SMS"
          },
          {
            type : 3,
            name: "Email"
          }
        ];
        arrayOfObject.push(
          {
            key: this.translate.instant('approval.message'),
            value: item.message
          },
          {
            key: this.translate.instant('approval.type'),
            value: _.find(types, {type: item.type})?.name
          }
        );
      }
      if (data[0].key === 'loantypes') {
        arrayOfObject.push(
          {
            key: this.translate.instant('approval.type'),
            value: item?.type
          },
          {
            key: this.translate.instant('approval.core_banking_id'),
            value: item?.core_banking_id
          }
        );
      }
      dataToSend.pending_transaction.description_transaction = this.transformJsonToString(arrayOfObject);
    } else if (data[0].key === 'roles') {
      dataToSend.pending_transaction.transaction_details.push({
        column_name: this.translate.instant('approval.status'),
        old_value: this.translate.instant('approval.active'),
        new_value: this.translate.instant('approval.deleted')
      });

      dataToSend.pending_transaction.description_transaction = this.getDescriptionTransactionDeleteRoleActions(data);
    } else if(data[0].key === 'faq_categories' || data[0].key === 'api_token') {
      dataToSend.pending_transaction.transaction_details.push({
        column_name: this.translate.instant('approval.status'),
        old_value: this.translate.instant('approval.active'),
        new_value: this.translate.instant('approval.deleted')
      });
      let arrayOfObject = [
        {
          key: this.translate.instant('approval.name'),
          value: item.name
        }
      ];
      dataToSend.pending_transaction.description_transaction = this.transformJsonToString(arrayOfObject);
    } else if (data[0].key === 'location') {
      dataToSend.pending_transaction.transaction_details.push({
        column_name: this.translate.instant('approval.status'),
        old_value: this.translate.instant('approval.active'),
        new_value: this.translate.instant('approval.deleted')
      });
      let arrayOfObject = [
        {
          key: this.translate.instant('approval.name'),
          value: item.name
        },
        {
          key: this.translate.instant('approval.description'),
          value: item.description
        },
        {
          key: this.translate.instant('approval.active'),
          value: item.active
        },
        {
          key: this.translate.instant('approval.lat'),
          value: item.lat
        },
        {
          key: this.translate.instant('approval.lng'),
          value: item.lng
        }
      ];
      dataToSend.pending_transaction.description_transaction = this.transformJsonToString(arrayOfObject);
    } else if (data[0].key === 'owner.app') {
      dataToSend.pending_transaction.transaction_details.push({
        column_name: this.translate.instant('approval.status'),
        old_value: this.translate.instant('approval.active'),
        new_value: this.translate.instant('approval.deleted')
      });
      let arrayOfObject = [
        {
          key: this.translate.instant('approval.countryName'),
          value: data[0]?.extra?.custom_item?.country
        },
      ];
      dataToSend.pending_transaction.description_transaction = this.transformJsonToString(arrayOfObject);
    } else if((data[0].key === 'web_views') || (data[0].key === 'menu_extensions')) {
      dataToSend.pending_transaction.transaction_details.push({
        column_name: this.translate.instant('approval.status'),
        old_value: this.translate.instant('approval.active'),
        new_value: this.translate.instant('approval.deleted')
      });
      let arrayOfObject = [
        {
          key: this.translate.instant('approval.title'),
          value: item.title
        },
      ];
      dataToSend.pending_transaction.description_transaction = this.transformJsonToString(arrayOfObject);
    }
    else if (data[0].key === 'dynamic_forms_ekyc' || data[0].key === 'dynamic_forms_ekyb' || data[0].key == 'dynamic_form_groups' || data[0].key == 'dynamic_group_sub_groups') {
      dataToSend.pending_transaction.transaction_details.push({
        column_name: this.translate.instant('approval.status'),
        old_value: this.translate.instant('approval.active'),
        new_value: this.translate.instant('approval.deleted')
      });
      let arrayOfObject = [
        {
          key: this.translate.instant('approval.name'),
          value: (item && item?.name) ? item.name :  '-'
        }
      ];
      dataToSend.pending_transaction.description_transaction = this.transformJsonToString(arrayOfObject);
    } else if (data[0].key === 'cardtypes') {
      dataToSend.pending_transaction.transaction_details.push({
        column_name: this.translate.instant('approval.status'),
        old_value: this.translate.instant('approval.active'),
        new_value: this.translate.instant('approval.deleted')
      });
      let arrayOfObject = [
        {
          key: this.translate.instant('approval.coreID'),
          value: item?.core_banking_id || '-'
        },
        {
          key: this.translate.instant('approval.cardType'),
          value: item?.name || '-'
        },
        {
          key: this.translate.instant('approval.serviceNumber'),
          value: item?.phone || '-'
        },
        {
          key: this.translate.instant('approval.coreBankingType'),
          value: item?.core_banking_type || '-'
        },
      ];
      dataToSend.pending_transaction.description_transaction = this.transformJsonToString(arrayOfObject);
    } else {
      if (item?.name) {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: this.translate.instant('approval.name'),
          old_value: item.name,
          new_value: this.translate.instant('approval.notAvailable')
        });
      } else if (data[0]?.extra?.custom_feature_item) {
        dataToSend.pending_transaction.description_transaction = this.getPredefinedDescriptorItem(data[0], data[0].key);
      }
    }
    return dataToSend;
  }

  getDescriptionTransactionActiveInactiveCmsUsersActions(transactions) {
    if (transactions[0].feature_item && transactions[0].feature_item.email) {
      let arrayOfObject = [
        {
          key: this.translate.instant('approval.fullname'),
          value: transactions[0]?.feature_item?.profile?.fullname
        },
        {
          key: this.translate.instant('approval.email'),
          value: transactions[0]?.feature_item?.email
        },
        {
          key: this.translate.instant('approval.phone'),
          value: transactions[0]?.feature_item?.profile?.phone
        }
      ];
      return this.transformJsonToString(arrayOfObject);
    }
  }

  getDescriptionTransactionDeleteRoleActions(transactions) {
    if (transactions[0].feature_item && transactions[0].feature_item.name) {
      let arrayOfObject = [
        {
          key: this.translate.instant('approval.name'),
          value: transactions[0].feature_item.name
        }
      ];
      return this.transformJsonToString(arrayOfObject);
    }
  }

  getDescriptionTransactionDeletePricingRule(transactions) {
    if (transactions[0].feature_item) {
      let arrayOfObject = [
        {
          key: this.translate.instant('approval.pricingRuleTitle'),
          value: transactions[0]?.feature_item?.title
        },
        {
          key: this.translate.instant('approval.paymentType'),
          value: transactions[0]?.feature_item?.payment_type
        },
        {
          key: this.translate.instant('approval.validityFrom'),
          value: transactions[0]?.feature_item?.validity_from
        },
        {
          key: this.translate.instant('approval.validityTo'),
          value: transactions[0]?.feature_item?.validity_to
        }
      ];
      return this.transformJsonToString(arrayOfObject);
    }
  }

  getDescriptionTransactionUpdateRulesActions(transactions) {
    if (transactions[0].feature_item) {
      let arrayOfObject = [
        {
          key: this.translate.instant('approval.title'),
          value: transactions[0].feature_item?.title
        },
        {
          key: this.translate.instant('approval.message'),
          value: transactions[0].feature_item?.message
        },
        {
          key: this.translate.instant('approval.os'),
          value: transactions[0].feature_item?.os
        },
        {
          key: this.translate.instant('approval.os_version_max'),
          value: transactions[0].feature_item?.os_version_max
        },
        {
          key: this.translate.instant('approval.os_version_min'),
          value: transactions[0].feature_item?.os_version_min
        },
      ];
      return this.transformJsonToString(arrayOfObject);
    }
  }

  transformJsonToString(arrayOfObject) {
    let resultAsString = '';
    arrayOfObject.forEach((item: any) => {
      const valueClass = item?.valueClass;

      resultAsString = resultAsString + '<span class="leftSide"> ' + item.key + '</span><span class="rightSide pl-8 ' + valueClass + '"> ' + item.value + '<span><br>';
    });
    return resultAsString;
  }

  getDescriptionItem(data, category) {
    let arrayOfObject = [];
    switch (category) {
      case 'cmsUsers':
        if (data?.feature_item && data?.feature_item?.name) {
          arrayOfObject.push({
            key: data?.feature_item?.name,
            value: ''
          });
        } else {
          if (data?.feature_item && data?.feature_item?.username) {
            arrayOfObject.push({
              key: data?.feature_item?.username,
              value: ''
            });
          }
        }
        break;
      case 'mailsettings':
        if (data?.feature_item && data?.feature_item?.smtp_name) {
          arrayOfObject.push({
            key: this.translate.instant('approval.smtp'),
            value: data?.feature_item?.smtp_name
          });
        }
        break;
      case 'pages' || 'content_management':
        if (data?.feature_item && data?.feature_item?.title) {
          arrayOfObject.push({
            key: this.translate.instant('approval.page'),
            value: data?.feature_item?.title
          });
        }
        break;
      case 'generalConf':
        arrayOfObject.push({
          key: this.translate.instant('approval.generalConfigurations'),
          value: ''
        });
        break;
      case 'update_rules':
        if (data?.feature_item && data?.feature_item?.os) {
          arrayOfObject.push({
            key: this.translate.instant('approval.os'),
            value: data?.feature_item?.os
          });
        }
        break;
      case 'sms_settings':
        arrayOfObject.push({
          key: this.translate.instant('approval.smsConfiguration'),
          value: ''
        });
        break;
      case 'mail_settings':
        arrayOfObject.push({
          key: this.translate.instant('approval.emailConfiguration'),
          value: ''
        });
        break;
      case 'customer':
        if (data?.key === 'device' || data?.key === 'devices.active') {
          if (data?.feature_item) {
            arrayOfObject.push({
              key: this.translate.instant('approval.os'),
              value: data?.feature_item?.os
            })
            arrayOfObject.push({
              key: this.translate.instant('approval.type'),
              value: data?.feature_item?.type
            })
            arrayOfObject.push({
              key: this.translate.instant('approval.model'),
              value: data?.feature_item?.model
            });

            if (data?.transaction_details && _.find(data?.transaction_details, { column_name: 'user_id' })) {
              let fullName = _.find(data?.transaction_details, { column_name: 'user_id' })?.old_relation?.profile?.fullname;
              let email = _.find(data?.transaction_details, { column_name: 'user_id' })?.old_relation?.email;
              if (fullName) {
                arrayOfObject.push({
                  key: this.translate.instant('approval.fullname'),
                  value: fullName
                });
              }
              if (email) {
                arrayOfObject.push({
                  key: this.translate.instant('approval.email'),
                  value: email,
                  valueClass: 'text-transform-none'
                });
              }
            }
          }
        } else if (data?.key === 'customer.block') {
          if (data?.feature_item && data?.feature_item?.profile && data?.feature_item?.profile?.fullname) {
            arrayOfObject.push({
              key: this.translate.instant('approval.id'),
              value: data?.feature_item?.profile?.user_id
            });
            arrayOfObject.push({
              key: this.translate.instant('approval.name'),
              value: data?.feature_item?.profile?.fullname
            });
            arrayOfObject.push({
              key: this.translate.instant('approval.username'),
              value: data?.feature_item?.username
            });
          }
        } else {
          if (data?.feature_item && data?.feature_item?.user && data?.feature_item?.user?.username) {
            arrayOfObject.push({
              key: this.translate.instant('approval.customer'),
              value: data?.feature_item?.user?.username
            });
          }
        }
        break;
      case 'companies':
        if (data?.key === 'companies.profile') {
          if (data?.feature_item && data?.feature_item !== '' && data?.feature_item !== null) {
            arrayOfObject.push({
              key: this.translate.instant('approval.companyName'),
              value: data?.feature_item?.fullname
            });
          }
        }else{
          if (data?.feature_item) {
            arrayOfObject.push({
              key: this.translate.instant('approval.name'),
              value: data?.feature_item?.name ? data?.feature_item?.name : data?.feature_item?.feature?.name ? data?.feature_item?.feature?.name : ''
            });
          }
        }
        break;
      case 'card_product':
        if (data?.feature_item && data?.feature_item !== '' && data?.feature_item !== null) {
          arrayOfObject.push({
            key: this.translate.instant('approval.name'),
            value: data?.feature_item?.product_name
          });
        }
        break;
      case 'system_configuration':
        if (data?.feature_item && data?.feature_item !== '' && data?.feature_item !== null) {
          const col = this.getColumnName(data?.feature_item?.key);
          const colArr = col.split(' ');
          for (let i = 0; i < colArr.length; i++) {
            colArr[i] = colArr[i].charAt(0).toUpperCase() + colArr[i].substring(1);

            if (colArr[i].toLowerCase() === 'gl') {
              colArr[i] = 'GL';
            }
            if (colArr[i].toLowerCase() === 'csc') {
              colArr[i] = 'CSC';
            }
            if (colArr[i].toLowerCase() === 'bhd') {
              colArr[i] = '(BHD)';
            }
            if (colArr[i].toLowerCase() === 'usd') {
              colArr[i] = '(USD)';
            }
          }
          arrayOfObject.push({
            key: this.translate.instant('approval.name'),
            value: colArr.join(' ')
          });
        }
        break;
      case 'loans':
        if (data?.feature_item?.name) {
          arrayOfObject.push({
            key: this.translate.instant('approval.title'),
            value: data?.feature_item?.name
          });
        }
        if (data?.feature_item?.description) {
          arrayOfObject.push({
            key: this.translate.instant('approval.description'),
            value: data?.feature_item?.description
          });
        }
        if (data?.feature_item?.core_banking_id) {
          arrayOfObject.push({
            key: this.translate.instant('approval.core_banking_id'),
            value: data?.feature_item?.core_banking_id
          });
        }
        if (data?.feature_item?.type) {
          arrayOfObject.push({
            key: this.translate.instant('approval.type'),
            value: data?.feature_item?.type
          });
        }
        break;
      case 'menu_extensions':
        arrayOfObject.push({
          key: this.translate.instant('approval.title'),
          value: data?.feature_item?.title || '-'
        });
        break;
      default:
        if (!_.isEmpty(data?.extra?.custom_feature_item)) {
          return this.getPredefinedDescriptorItem(data, category);
        } else if (data?.feature_item && data?.feature_item?.name) {
          arrayOfObject.push({
            key: this.translate.instant('approval.name'),
            value: data?.feature_item?.name
          });
        }
        break;
    }

    if (data?.transaction_rejection_reason) {
      arrayOfObject.push({
        key: this.translate.instant('approval.transaction_rejection_reason'),
        value: data?.transaction_rejection_reason
      });
    }
    if (arrayOfObject?.length) {
      return this.transformJsonToString(arrayOfObject);
    }
    return '';
  }

  getPredefinedDescriptorItem(data, category) {
    if (!isEmpty(data?.extra?.custom_feature_item)) {
      if(isString(data.extra.custom_feature_item)) {
        data.extra.custom_feature_item = JSON.parse(data.extra.custom_feature_item);
      }
      return this.transformJsonToString(toPairs(data.extra.custom_feature_item || {}).filter(([key, value]) => value !== null).map(([key, value]) => ({
        key: this.removeUnderscoreAndCapitalize(key),
        value: value
      })));
    } else {
      return '';
    }
  }

  getColumnName(name) {
    let spacedName = name;
    if (name && (typeof name === 'string' || name instanceof String)) {
      spacedName = name.split('_').join(' ');
    }
    return spacedName;
  }

  getGeneralConfigurationData(transactions) {
    if (transactions[0].key === 'config.messages') {
      return this.getMessagesData(transactions);
    } else if (transactions[0].key === 'config.customer_service_number') {
      return this.getCustomerServiceNumberData(transactions);
    } else if (transactions[0].key === 'currencies') {
      return this.getAllowedCurrenciesData(transactions);
    } else if (transactions[0].key === 'config.base_currency_config') {
      return this.getBaseCurrencyData(transactions);
    } else if (transactions[0].key === 'config.security_config' || transactions[0].key === 'config') {
      return this.getSecurityData(transactions);
    } else if (transactions[0].key === 'config.default_transfer_limit') {
      return this.getDefaultTransferLimit(transactions);
    } else if (transactions[0].key === 'sms_settings') {
      return this.getSMSData(transactions);
    } else if (transactions[0].key === 'mailsettings') {
      return this.getEmailData(transactions);
    } else if (transactions[0].key === 'update_rules') {
      return this.getUpdateRulesData(transactions);
    } else if (transactions[0].key === 'config.account_config') {
      return this.getAccountConfigData(transactions);
    } else if (transactions[0].key === 'currencies.forex') {
      return this.getForexCurrenciesData(transactions);
    } else if (transactions[0].key === 'config.emails_to_send') {
      return this.getEmailsToSendData(transactions);
    } else {
      return { pending_transaction: transactions[0] };
    }
  }

  getMessagesData(transactions) {
    const dataToSend = {
      pending_transaction: {
        transaction_details: []
      }
    };
    for (const element of transactions) {
      for (let j = 0; j < element.transaction_details.length; j++) {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: element.feature_item.key,
          old_value: element.transaction_details[j].old_value,
          new_value: element.transaction_details[j].new_value,
          lang: element.transaction_details[j].lang
        });
      }
    }

    return dataToSend;
  }

  getEmailsToSendData(transactions) {
    const dataToSend = {
      pending_transaction: {
        transaction_details: [{
          column_name: transactions[0].feature_item.key,
          old_value: this.getColumn(transactions[0].transaction_details, 'value').old_value ? ((this.getColumn(transactions[0].transaction_details, 'value').old_value)?.split(','))?.join(', ') : '',
          new_value: this.getColumn(transactions[0].transaction_details, 'value').new_value ? ((this.getColumn(transactions[0].transaction_details, 'value').new_value)?.split(','))?.join(', ') : ''
        }]
      }
    };
    return dataToSend;
  }

  getCustomerServiceNumberData(transactions) {
    const dataToSend = {
      pending_transaction: {
        transaction_details: [{
          column_name: this.translate.instant('approval.customerServiceNumber'),
          old_value: this.getColumn(transactions[0].transaction_details, 'value').old_value,
          new_value: this.getColumn(transactions[0].transaction_details, 'value').new_value
        }]
      }
    };
    return dataToSend;
  }

  getAllowedCurrenciesData(transactions) {
    const dataToSend = {
      pending_transaction: {
        transaction_details: []
      }
    };
    if (transactions.length > 0) {
      if (transactions[0].action === 'add') {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: this.translate.instant('approval.currency'),
          old_value: this.getColumn(transactions[0].transaction_details, 'key').old_value,
          new_value: this.getColumn(transactions[0].transaction_details, 'key').new_value
        });
      } else {
        for (const element of transactions) {
          dataToSend.pending_transaction.transaction_details.push({
            column_name: element.feature_item.key,
            old_value: this.getColumn(element?.transaction_details, 'active')?.old_value == 1 ? this.translate.instant('approval.active') : this.translate.instant('approval.inactive'),
            new_value: this.getColumn(element?.transaction_details, 'active')?.new_value == 1 ? this.translate.instant('approval.active') : this.translate.instant('approval.inactive')
          });
        }
      }
    }

    return dataToSend;
  }

  getBaseCurrencyData(transactions) {
    const dataToSend = {
      pending_transaction: {
        transaction_details: [{
          column_name: transactions[0].feature_item.key,
          old_value: this.getColumn(transactions[0].transaction_details, 'value').old_value,
          new_value: this.getColumn(transactions[0].transaction_details, 'value').new_value
        }]
      }
    };
    return dataToSend;
  }

  getSecurityData(transactions) {
    const dataToSend = {
      pending_transaction: {
        transaction_details: []
      }
    };
    for (const element of transactions) {
      if (element.feature_item.key === 'otp_channel') {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: element.feature_item.key,
          old_value: this.getOtpChannel(this.getColumn(element.transaction_details, 'value').old_value),
          new_value: this.getOtpChannel(this.getColumn(element.transaction_details, 'value').new_value)
        });
      } else if (element.feature_item.key === 'previous_passwords') {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: this.translate.instant('approval.numberOfPasswordHistoryReminder'),
          old_value: this.getColumn(element.transaction_details, 'value').old_value,
          new_value: this.getColumn(element.transaction_details, 'value').new_value
        });
      } else if (element.feature_item.key === 'password_uppercase' || element.feature_item.key === 'password_lowercase' ||
        element.feature_item.key === 'password_numbers' || element.feature_item.key === 'password_symbols') {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: element.feature_item.key,
          old_value: this.getColumn(element.transaction_details, 'value').old_value == '1' ? this.translate.instant('approval.yes') : this.translate.instant('approval.no'),
          new_value: this.getColumn(element.transaction_details, 'value').new_value == '1' ? this.translate.instant('approval.yes') : this.translate.instant('approval.no')
        });
      } else if (element.feature_item.key === 'cms_2fa_expiry' || element.feature_item.key === 'cms_2fa_otp_resend_timeout' ||
        element.feature_item.key === 'cms_2fa_resend_otp_attempts' || element.feature_item.key === 'cms_2fa_resend_otp_unlock_after') {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: this.getTitleColumnName2fa(element.feature_item.key),
          old_value: this.getColumn(element.transaction_details, 'value').old_value,
          new_value: this.getColumn(element.transaction_details, 'value').new_value
        });
      } else if (element.feature_item.key === 'cms_2fa_sms' || element.feature_item.key === 'cms_2fa_email') {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: this.getTitleColumnName2fa(element.feature_item.key),
          old_value: this.getColumn(element?.transaction_details, 'value')?.old_value == '1' ? this.translate.instant('approval.active') : this.translate.instant('approval.inactive'),
          new_value: this.getColumn(element?.transaction_details, 'value')?.new_value == '1' ? this.translate.instant('approval.active') : this.translate.instant('approval.inactive')
        });
      } else {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: element.feature_item.key,
          old_value: this.getColumn(element.transaction_details, 'value').old_value,
          new_value: this.getColumn(element.transaction_details, 'value').new_value
        });
      }
    }
    return dataToSend;
  }

  getTitleColumnName2fa(title: string) {
    let result = '';
    switch (title) {
      case 'cms_2fa_sms':
        result = this.translate.instant('approval.cms_2fa_sms');
        break;
      case 'cms_2fa_email':
        result = this.translate.instant('approval.cms_2fa_email');
        break;
      case 'cms_2fa_expiry':
        result = this.translate.instant('approval.cms_2fa_expiry');
        break;
      case 'cms_2fa_otp_resend_timeout':
        result = this.translate.instant('approval.cms_2fa_otp_resend_timeout');
        break;
      case 'cms_2fa_resend_otp_attempts':
        result = this.translate.instant('approval.cms_2fa_resend_otp_attempts') ;
        break;
      case 'cms_2fa_resend_otp_unlock_after':
        result = this.translate.instant('approval.cms_2fa_resend_otp_unlock_after') ;
        break;
      default:
        result = '';
        break;
    }

    return result;
  }

  getAccountConfigData(transactions) {
    const dataToSend = {
      pending_transaction: {
        transaction_details: []
      }
    };
    for (const element of transactions) {
      if (element.feature_item.key === 'transactions_minimum_date') {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: this.translate.instant('generalConfigurations.historyLimit'),
          old_value: this.getColumn(element.transaction_details, 'value').old_value,
          new_value: this.getColumn(element.transaction_details, 'value').new_value
        });
      } else if (element.feature_item.key === 'transactions_maximum_range') {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: element.feature_item.key,
          old_value: this.getColumn(element.transaction_details, 'value').old_value,
          new_value: this.getColumn(element.transaction_details, 'value').new_value
        });
      } else if (element.feature_item.key === 'transactions_default_range') {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: element.feature_item.key,
          old_value: this.getColumn(element.transaction_details, 'value').old_value,
          new_value: this.getColumn(element.transaction_details, 'value').new_value
        });
      } else {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: element.feature_item.key,
          old_value: this.getColumn(element.transaction_details, 'value').old_value,
          new_value: this.getColumn(element.transaction_details, 'value').new_value
        });
      }
    }
    return dataToSend;
  }

  getForexCurrenciesData(transactions){
    const dataToSend = {
      pending_transaction: {
        transaction_details: []
      }
    };
    if (transactions.length > 0) {
      for (const element of transactions) {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: element.feature_item.currency_from + ' - ' + element.feature_item.currency_to,
          old_value: this.getColumn(element?.transaction_details, 'active')?.old_value == '1' ? this.translate.instant('approval.active') : this.translate.instant('approval.inactive'),
          new_value: this.getColumn(element?.transaction_details, 'active')?.new_value == '1' ? this.translate.instant('approval.active') : this.translate.instant('approval.inactive')
        });
      }
    }

    return dataToSend;
  }

  getDefaultTransferLimit(transactions) {
    const dataToSend = {
      pending_transaction: {
        transaction_details: []
      }
    };
    for (const element of transactions) {
      dataToSend.pending_transaction.transaction_details.push({
        column_name: element.feature_item.key,
        old_value: this.getColumn(element.transaction_details, 'value').old_value,
        new_value: this.getColumn(element.transaction_details, 'value').new_value
      });
    }
    return dataToSend;
  }

  getColumn(columnsList, columnName) {
    const columnFound = filter(columnsList, (column) => {
      return column.column_name === columnName;
    });
    return columnFound && columnFound[0] ? columnFound[0] : '';
  }

  getOtpChannel(otpChannnel) {
    let retVal = '';
    switch (Number(otpChannnel)) {
      case 0:
        retVal = this.translate.instant('approval.none');
        break;
      case 1:
        retVal = this.translate.instant('approval.SMSOnly');
        break;
      case 2:
        retVal = this.translate.instant('approval.eitherEmailOrSMS');
        break;
      case 3:
        retVal = this.translate.instant('approval.emailOnly');
        break;
      default:
        retVal = '';
        break;
    }

    return retVal;
  }

  getSMSData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };
    if (transactions[0].description_transaction) {
      dataToSend.pending_transaction.description_transaction = transactions[0].description_transaction;
    }
    for (const element of transactions[0].transaction_details) {
      if (element.column_name !== 'app_id' && element.column_name != 'acquirer_id') {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: element.column_name,
          old_value: element.old_value,
          new_value: element.new_value
        });
      }
    }
    return dataToSend;
  }

  getEmailData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };
    if (transactions[0].description_transaction) {
      dataToSend.pending_transaction.description_transaction = transactions[0].description_transaction;
    }
    for (const element of transactions[0].transaction_details) {
      if (element.column_name !== 'app_id' && element.column_name != 'acquirer_id' && element.column_name != 'smtp_password') {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: element.column_name,
          old_value: element.old_value,
          new_value: element.new_value
        });
      }
    }
    return dataToSend;
  }

  getUpdateRulesData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };

    dataToSend.pending_transaction.description_transaction = this.getDescriptionTransactionUpdateRulesActions(transactions);

    for (const element of transactions[0].transaction_details) {
      if(element.column_name === 'active' || element.column_name === 'force') {
        if (element.column_name === 'force') element.column_name = this.translate.instant('approval.forceUpdate')
        dataToSend.pending_transaction.transaction_details.push({
          column_name: element.column_name,
          old_value: element.old_value == 1 ? this.translate.instant('approval.active') : this.translate.instant('approval.inactive'),
          new_value: element.new_value == 1 ? this.translate.instant('approval.active') : this.translate.instant('approval.inactive'),
          lang: element.lang
        });
      } else {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: element.column_name,
          old_value: element.old_value == 0 ? '-' : element.old_value,
          new_value: element.new_value == 0 ? '-' : element.new_value,
          lang: element.lang
        });
      }
    }

    return dataToSend;
  }

  getCustomerData(transactions) {
    if (transactions[0].key === 'customer.block') {
      return this.getPendingCustomerStatusData(transactions);
    } else if (transactions[0].key === 'customer.reset_initial_password') {
      return { pending_transaction: transactions[0] };
    } else if (transactions[0].key === 'customer.profile') {
      return this.getPendingCustomerData(transactions);
    } else if (transactions[0].key === 'customer') {
      return this.getPendingCustomerData(transactions);
    } else if (transactions[0].key === 'customer.card') {
      return this.getPendingCardData(transactions);
    } else if (transactions[0].key === 'customer.cards') {
      // Related to Customer -> Cards section
      return this.getPendingCustomerCardData(transactions);
    } else if (transactions[0].key === 'device') {
      return this.getPendingCustomerDeviceData(transactions);
    } else if (transactions[0].key === 'customer.send') {
      return this.getPendingCustomerNotification(transactions);
    } else if (transactions[0].key === 'devices.active') {
      return this.getPendingDevices(transactions);
    } else if ((transactions[0].key === 'cardcontrol.ecommerce') || (transactions[0].key === 'cardcontrol.pos') || transactions[0].key === 'cardcontrol.atm') {
      return this.getPendingCardControl(transactions);
    } else {
      return { pending_transaction: transactions[0] };
    }
  }

  getPendingCustomerStatusData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };

    if (transactions[0].description_transaction) {
      dataToSend.pending_transaction.description_transaction = transactions[0].description_transaction;
    }

    if (transactions && transactions.length) {
      for (const element of transactions[0].transaction_details) {
        if (element.column_name !== "status_message" && element.column_name !== "active" && element.column_name !== 'failed_login_attempts') {
          if (element.column_name === 'account_status') {
            if (element.old_relation) element.old_relation = this.translationService.getTranslationData(element.old_relation);
            if (element.new_relation) element.new_relation = this.translationService.getTranslationData(element.new_relation);
            dataToSend.pending_transaction.transaction_details.push({
              column_name: this.translate.instant('approval.accountStatus'),
              old_value: element.old_value ? (element.old_relation?.translations?.[this.currentLanguage]?.title ? element.old_relation.translations[this.currentLanguage].title : element.old_value) : '-',
              new_value: element.new_relation?.translations?.[this.currentLanguage]?.title ? element.new_relation.translations[this.currentLanguage].title : element.new_value
            });
          } else {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: element.column_name,
              old_value: element.old_value,
              new_value: element.new_value
            });
          }
        }
      }
    }
    return dataToSend;
  }

  getPendingCustomerData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };
    for (const element of transactions) {
      for (let j = 0; j < element.transaction_details.length; j++) {
        if (element.transaction_details[j].column_name === 'failed_login_attempts') {
          dataToSend.pending_transaction.transaction_details.push({
            column_name: this.translate.instant('approval.resetLogin'),
            old_value: '0',
            new_value: '1'
          });
        }
        if (element.transaction_details[j].column_name !== 'user_type'
          && element.transaction_details[j].column_name !== 'app_id'
          && element.transaction_details[j].column_name !== 'validated'
          && element.transaction_details[j].column_name !== 'role_id'
          && element.transaction_details[j].column_name !== 'username'
          && element.transaction_details[j].column_name !== 'account_status'
          && element.transaction_details[j].column_name !== 'id'
          && element.transaction_details[j].column_name !== 'user_id'
          && !this.checkIfRecordExist(dataToSend.pending_transaction.transaction_details, element.transaction_details[j].column_name)) {
          if (element.transaction_details[j].column_name === 'country_id') {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: this.translate.instant('approval.countryName'),
              old_value: element.transaction_details[j]?.old_relation?.name,
              new_value: element.transaction_details[j]?.new_relation?.name,
              lang: element.transaction_details[j].lang
            });
          } else if (element.transaction_details[j].column_name === 'fname') {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: this.translate.instant('approval.firstName'),
              old_value: element.transaction_details[j].old_value,
              new_value: element.transaction_details[j].new_value,
              lang: element.transaction_details[j].lang
            });
          } else if (element.transaction_details[j].column_name === 'lname') {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: this.translate.instant('approval.lastName'),
              old_value: element.transaction_details[j].old_value,
              new_value: element.transaction_details[j].new_value,
              lang: element.transaction_details[j].lang
            });
          } else if (element.transaction_details[j].column_name === 'dob') {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: this.translate.instant('approval.dob'),
              old_value: element.transaction_details[j].old_value,
              new_value: element.transaction_details[j].new_value,
              lang: element.transaction_details[j].lang
            });
          } else if (element.transaction_details[j].column_name === 'local_image') {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: this.translate.instant('approval.image'),
              old_value: element.transaction_details[j].old_value,
              new_value: element.transaction_details[j].new_value
            });
          } else if (element.transaction_details[j].column_name === 'active') {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: this.translate.instant('approval.status'),
              old_value: element.transaction_details[j].old_value,
              new_value: this.translate.instant('approval.whitelisted')
            });
          } else {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: element.transaction_details[j].column_name,
              old_value: element.transaction_details[j].old_value,
              new_value: element.transaction_details[j].new_value,
              lang: element.transaction_details[j].lang
            });
          }
        }

      }
    }
    return dataToSend;
  }

  checkIfRecordExist(dataToSend, columnName) {
    return find(dataToSend, (item) => {
      return item.column_name === columnName;
    });
  }

  getPendingCardData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };

    if (transactions[0].description_transaction) {
      dataToSend.pending_transaction.description_transaction = transactions[0].description_transaction;
    }
    for (const element of transactions[0].transaction_details) {
      if (element.column_name === 'status') {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: this.translate.instant('approval.card'),
          old_value: transactions[0].feature_item.masked_pan,
          new_value: this.translate.instant('approval.notAvailable')
        });
      }

    }

    return dataToSend;
  }

  getPendingCustomerCardData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };

    if (transactions[0].description_transaction) {
      dataToSend.pending_transaction.description_transaction = transactions[0].description_transaction;
    }
    for (const element of transactions[0].transaction_details) {
      if (element.column_name !== 'product_id') {
        if (element.column_name === 'user_id') {
          dataToSend.pending_transaction.transaction_details.push({
            column_name: this.translate.instant('approval.customerName'),
            old_value: '-',
            new_value: element.new_relation?.profile?.fullname
          });
        } else {
          dataToSend.pending_transaction.transaction_details.push({
            column_name: element.column_name,
            old_value: element.old_value,
            new_value: element.new_value
          });
        }
      }
    }
    if (transactions[0]?.extra?.custom_feature_item) {
      dataToSend.pending_transaction.description_transaction = this.getCardInfoFromTransactionsExtra(transactions[0]?.extra?.custom_feature_item);
    }
    return dataToSend;
  }

  pushDetailsHandling(key, value){
    if(key == 'location_ids'){
      return value.length > 0  ? value.join(',') : '-';
    }
    else if(key == 'push_type'){
      return value  ? this.removeUnderscoreAndCapitalize(value) : '-';
    }else{
      return value;
    }

  }

  getPendingCustomerNotification(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };

    for (const element of transactions[0].transaction_details) {
      if (element.column_name === 'ids' || element.column_name == 'push_to_all_except') {

        let newValueTabsConfigs: FooPaginatedListingDialogTabsConfigs[] = [
          {
            key: 'users',
            labels: [
              {
                title: "",
                value: "profile.fullname",
                disableSorting: true
              }
            ],
            search: {
              placeholder: "approval.searchUsers",
              class: ''
            },
            dataSource: (searchText: string, page: number, pageSize: number) => {
              const dataToSend = {
                tag: searchText,
                page: page || 1,
                per_page: pageSize,
                ids: JSON.parse(element?.new_value)?.join(',')
              };
              return this.makecall.postRequest('sendmessage/customersbyids', dataToSend).pipe(rxjsMap((data: any) => {
                data['items'] = this.formatterFullName(data['items']);
                const response = {
                  data: data['items'],
                  total: data['total']
                }
                const items = JSON.parse(JSON.stringify(response));
                return items;
              }));
            }
          }
        ];

        let transactionDetails: TransactionDetailsConfigs = {
          column_name: element.column_name == 'ids' ? this.translate.instant('sendNotifications.sendTo') : element.column_name,
          new_value: (JSON.parse(element?.new_value)?.length ? JSON.parse(element.new_value).length : 0) + ' ' + this.translate.instant('approval.users'),
          displayAs: "dialog",
          dialogConfigs: {
            component: FooPaginatedListingDialogComponent,
            newValueDialogConfigs: {
              tabsConfigs: newValueTabsConfigs,
              dialogTitle: (JSON.parse(element?.new_value)?.length ? JSON.parse(element.new_value).length : 0) + ' ' + (element.column_name == 'push_to_all_except' ? this.translate.instant('approval.usersException') :  this.translate.instant('approval.users'))
            }
          },
        };

        dataToSend.pending_transaction.transaction_details.push(transactionDetails);
      }
      else if(element.column_name === 'push_data'){
        for (let key in JSON.parse(element.new_value)) {
          if (key == 'extra') {
            for (let extraKey in JSON.parse(element.new_value)?.extra) {
              dataToSend.pending_transaction.transaction_details.push({
                column_name: this.removeUnderscoreAndCapitalize(extraKey),
                old_value: this.pushDetailsHandling(extraKey, JSON.parse(element.old_value)?.extra[extraKey]),
                new_value: this.pushDetailsHandling(extraKey, JSON.parse(element.new_value)?.extra[extraKey])
              });
            }
          }else{
            dataToSend.pending_transaction.transaction_details.push({
              column_name: this.removeUnderscoreAndCapitalize(key),
              old_value: JSON.parse(element.old_value)[key] ? JSON.parse(element.old_value)[key] : '-',
              new_value: JSON.parse(element.new_value)[key] ? JSON.parse(element.new_value)[key] : '-'
            });
          }
        }
      } else if(element.column != "push_to_all_except" && element.column_name != "push_to_latest_active_device" && element.column_name != 'message'){
        if (element.column_name === 'subject' || element.column_name === 'prefix' || element.column_name === 'suffix') {
          if (_.find(transactions[0].transaction_details, {column_name: 'type'})?.new_value === 'email') {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: element.column_name,
              old_value: element.old_value,
              new_value: element.new_value
            });
          }
        } else if(element.column_name === 'deep_link_id') {
          if (element.old_relation) element.old_relation = this.translationService.getTranslationData(element.old_relation);
          if (element.new_relation) element.new_relation = this.translationService.getTranslationData(element.new_relation);
          dataToSend.pending_transaction.transaction_details.push({
            column_name: this.translate.instant('approval.deepLinkingSection'),
            old_value: element.old_relation?.translations?.[this.currentLanguage]?.title ? element.old_relation.translations[this.currentLanguage].title : '-',
            new_value: element.new_relation?.translations?.[this.currentLanguage]?.title ? element.new_relation.translations[this.currentLanguage].title : '-'
          });
        } else if (element.column_name === 'push_type') {
          dataToSend.pending_transaction.transaction_details.push({
            column_name: this.removeUnderscoreAndCapitalize(element.column_name),
            old_value: this.getSpecificTranslation(element.old_value),
            new_value: this.getSpecificTranslation(element.new_value)
          });
        } else if (element.column_name === 'type' && element.new_value == 'sms') {
          dataToSend.pending_transaction.transaction_details.push({
            column_name: this.translate.instant('approval.type'),
            old_value: this.translate.instant('approval.sms'),
            new_value: this.translate.instant('approval.sms'),
          });
        } else if ((element.column_name).startsWith('message')) {
          dataToSend.pending_transaction.transaction_details.push({
            column_name: element.column_name,
            old_value: element.old_value,
            new_value: element.new_value,
            displayAs: "HTML"
          });
        } else if (element.column_name === 'locations') {
          dataToSend.pending_transaction.transaction_details.push({
            column_name: element.column_name,
            old_value: element.old_value && JSON.parse(element.old_value)?.length ? JSON.parse(element.old_value).join(', ') : '',
            new_value: element.new_value && JSON.parse(element.new_value)?.length ? JSON.parse(element.new_value).join(', ') : ''
          });
        }else if (element.column_name === 'date'
          || element.column_name === 'expiry_date'
          || element.column_name === 'continuous_start_date'
          || element.column_name === 'continuous_end_date'
          || element.column_name === 'frequency_start_date'
          || element.column_name === 'frequency_end_date'
        ) {
          dataToSend.pending_transaction.transaction_details.push({
            column_name: element.column_name,
            old_value: element.old_value && element.old_value != '' ? moment(element.old_value,'X').format("YYYY-MM-DD HH:mm:ss") : '',
            new_value: element.new_value && element.new_value != '' ? moment(element.new_value,'X').format("YYYY-MM-DD HH:mm:ss") : '',
          });
        } else if (element.column_name === 'force' || element.column_name === 'enable_push_tracking' ||  element.column_name === 'is_silent') {
          dataToSend.pending_transaction.transaction_details.push({
            column_name: element.column_name,
            old_value: element.old_value == '0' || element.old_value == 0 ||  element.old_value == false ||  element.old_value == 'false' ? this.translate.instant('general.no') : this.translate.instant('general.yes'),
            new_value: element.new_value == '0' || element.new_value == 0 ||  element.new_value == false ||  element.new_value == 'false' ? this.translate.instant('general.no') : this.translate.instant('general.yes')
          });
        } else if (element.column_name === 'frequency_time') {
          dataToSend.pending_transaction.transaction_details.push({
            column_name: element.column_name,
            old_value: element.old_value ? moment(element.old_value, 'HH:mm:ss').format('h:mm A') : '-',
            new_value: element.new_value ? moment(element.new_value, 'HH:mm:ss').format('h:mm A') : '-'
          });
        } else {
          dataToSend.pending_transaction.transaction_details.push({
            column_name: element.column_name,
            old_value: element.old_value,
            new_value: element.new_value
          });
        }
      }
    }

    return dataToSend;
  }

  formatterFullName(data) {
    if (data?.length) {
      data.forEach((item: any) => {
        if (item?.profile?.fullname) item.profile.fullname = this.replaceSeparatorAndCapitalizePipe.transform(item.profile.fullname);
      });
    }
    return data;
  }

  getPendingDevices(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };

    if (transactions && transactions.length && transactions[0].description_transaction) {
      dataToSend.pending_transaction.description_transaction = transactions[0].description_transaction;
    }

    if (transactions && transactions.length) {
      for (const element of transactions[0].transaction_details) {
        if (element.column_name !== 'id' && element.column_name !== 'session' && element.column_name !== 'user_id') {
          if(element.column_name === 'active') {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: this.translate.instant('approval.status'),
              old_value: element.old_value == '0' ? this.translate.instant('approval.blocked') : element.old_value == '1' ? this.translate.instant('approval.active') : '-',
              new_value: element.new_value == '0' ? this.translate.instant('approval.blocked') : element.new_value == '1' ? this.translate.instant('approval.active') : '-'

            });
          } else {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: element.column_name,
              old_value: element.old_value,
              new_value: element.new_value
            });
          }
        }
      }
    }
    return dataToSend;
  }

  getSpecificTranslation(key) {
    let value = '';
    switch (key) {
      case 'one_shot':
        value = this.translate.instant('sendNotifications.oneshot');
        break;
      case 'one_shot_and_continuous':
        value = this.translate.instant('sendNotifications.oneShot&Continuous');
        break;
      default:
        value = key;
    }
    return value;
  }

  removeUnderscoreAndCapitalize(type){
    if (type) {
      return type.replace(/\_/gi, " ")
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }
    return type;
  };

  getPendingCustomerDeviceData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };


    if (transactions && transactions.length && transactions[0].description_transaction) {
      dataToSend.pending_transaction.description_transaction = transactions[0].description_transaction;
    }

    if (transactions && transactions.length) {
      for (const element of transactions[0].transaction_details) {
        if (element.column_name !== 'id' && element.column_name !== 'session') {
          if (element.column_name === 'active') {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: this.translate.instant('approval.status'),
              old_value: element.old_value === '0' ? this.translate.instant('approval.blocked') : element.old_value === '1' ? this.translate.instant('approval.active') : '-',
              new_value: element.new_value === '0' ? this.translate.instant('approval.blocked') : element.new_value === '1' ? this.translate.instant('approval.active') : '-'
            });
          } else if (element.column_name === 'user_id') {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: this.translate.instant('approval.fullname'),
              old_value: element.old_relation?.profile?.fullname,
              new_value: element.new_relation?.profile?.fullname
            });
            dataToSend.pending_transaction.transaction_details.push({
              column_name: this.translate.instant('approval.number'),
              old_value: element.old_relation?.profile?.phone,
              new_value: element.new_relation?.profile?.phone
            });
          } else {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: element.column_name,
              old_value: element.old_value,
              new_value: element.new_value
            });
          }
        }
      }
    }
    return dataToSend;
  }

  getCryptoConfigurationData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };
    if (transactions[0]) {

      for (const element of transactions) {
        for (let i = 0; i < element.transaction_details.length; i++) {
          if (element.transaction_details[i].column_name == 'alert_target') {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: element.transaction_details[i].column_name,
              old_value: element.transaction_details[i].old_value,
              new_value: element.transaction_details[i].new_value,
              old_value_class: 'text-transform-none',
              new_value_class: 'text-transform-none'
            });
          }
          else{
            dataToSend.pending_transaction.transaction_details.push({
              column_name: element.transaction_details[i].column_name,
              old_value: element.transaction_details[i].old_value,
              new_value: element.transaction_details[i].new_value
            });
          }
        }
      }
    }
    return dataToSend;
  }

  getPendingCMSUserData(transactions, roles) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };
    if (transactions[0]) {
      if (transactions[0].description_transaction) {
        dataToSend.pending_transaction.description_transaction = transactions[0].description_transaction;
      }

      if (transactions[0].transaction_details[0].column_name === 'failed_login_attempts') {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: this.translate.instant('approval.login'),
          old_value: this.translate.instant('approval.inactive'),
          new_value: this.translate.instant('approval.active')
        });
      } else {
        if (this.getColumn(transactions[0].transaction_details, 'deleted_at')) {
          dataToSend.pending_transaction.transaction_details.push({
            column_name: this.translate.instant('approval.status'),
            old_value: this.translate.instant('approval.inactive'),
            new_value: this.translate.instant('approval.active')
          });

          dataToSend.pending_transaction.description_transaction = this.getDescriptionTransactionActiveInactiveCmsUsersActions(transactions);

        } else {
          const profileObject = this.getTransactionByName(transactions, 'cms_users.profile');
          dataToSend.pending_transaction.transaction_details = profileObject && profileObject.transaction_details && profileObject.transaction_details.length > 0 ? this.getTransactionDetailsEditAddCmsUserActions(profileObject.transaction_details) : [];
          const roleTransaction = this.getTransactionByName(transactions, 'cms_users.role');
          const roleIdColumn = this.getColumn(roleTransaction.transaction_details, 'role_id');
          const emailuser = this.getTransactionByName(transactions, 'cms_users');
          if (emailuser) {
            const emailColumn = this.getColumn(emailuser.transaction_details, 'email');
            if (emailColumn) {
              dataToSend.pending_transaction.transaction_details.push({
                column_name: this.translate.instant('approval.email'),
                old_value: emailColumn.old_value,
                new_value: emailColumn.new_value
              });
            }
          }
          if (roleIdColumn) {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: this.translate.instant('approval.role'),
              old_value: this.resolveRoleNameByRoleId(roleIdColumn.old_value, roles),
              new_value: this.resolveRoleNameByRoleId(roleIdColumn.new_value, roles)
            });
          }
          for (const element of dataToSend.pending_transaction.transaction_details) {
            if (element.column_name === 'fname') {
              element.column_name = this.translate.instant('approval.firstName');
            } else if (element.column_name === 'lname') {
              element.column_name = this.translate.instant('approval.lastName');
            } else if (element.column_name === 'dob') {
              element.column_name = this.translate.instant('approval.dob');
            } else if (element.column_name === 'country_id') {
              element.column_name = this.translate.instant('approval.country_id');
            }
          }
          // remove userId Column
          const index = indexOf(map(dataToSend.pending_transaction.transaction_details, 'column_name'), 'user_id');
          if (index !== -1) {
            dataToSend.pending_transaction.transaction_details.splice(index, 1);
          }

          if(transactions[0]?.feature_item?.user?.email) {
            let arrayOfObject = [
              {
                key: this.translate.instant('approval.username'),
                value: transactions[0].feature_item.user.email,
                valueClass: 'text-transform-none'
              }
            ];
            dataToSend.pending_transaction.description_transaction = this.transformJsonToString(arrayOfObject);
          }
        }
      }
    }
    return dataToSend;
  }

  getTransactionDetailsEditAddCmsUserActions(transaction_details) {
    let result = _.filter(transaction_details, function (item) {
      return (item.column_name != 'dob' && item.column_name != 'about' && item.column_name != 'image' && item.column_name != 'country_id');
    });
    return result;
  }

  getTransactionByName(transactionsList, transactionName) {
    const transactionFound = filter(transactionsList, (transaction) => {
      return transaction.key === transactionName;
    });
    return transactionFound && transactionFound.length > 0 ? transactionFound[0] : [];
  }

  resolveRoleNameByRoleId(roleId, roles) {
    if (!roleId || roles.length === 0) {
      return '';
    }
    const roleFound = filter(roles, (item) => {
      return item.id === Number(roleId);
    });
    return roleFound && roleFound[0] ? roleFound[0].name : '';
  }

  getPendingBranchData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };
    if (transactions[0].description_transaction) {
      dataToSend.pending_transaction.description_transaction = transactions[0].description_transaction;
    }
    if(transactions[0].transaction_details) {
      if((transactions[0].transaction_details).some(e => e.column_name === 'cash_in') || (transactions[0].transaction_details).some(e => e.column_name === 'cash_out')) {
        let cash_in_old_value = _.some(transactions[0].transaction_details, {column_name: 'cash_in'}) ? _.find(transactions[0].transaction_details, {column_name: 'cash_in'})?.old_value : transactions[0].feature_item.cash_in;
        let cash_in_new_value = _.some(transactions[0].transaction_details, {column_name: 'cash_in'}) ? _.find(transactions[0].transaction_details, {column_name: 'cash_in'})?.new_value : transactions[0].feature_item.cash_in;
        let cash_out_old_value = _.some(transactions[0].transaction_details, {column_name: 'cash_out'}) ? _.find(transactions[0].transaction_details, {column_name: 'cash_out'})?.old_value : transactions[0].feature_item.cash_out;
        let cash_out_new_value = _.some(transactions[0].transaction_details, {column_name: 'cash_out'}) ? _.find(transactions[0].transaction_details, {column_name: 'cash_out'})?.new_value : transactions[0].feature_item.cash_out;
        let dataToPush = {
          column_name: this.translate.instant('approval.atmServices'),
          old_value: (parseInt(cash_in_old_value) ? this.translate.instant('approval.cash_in') : '') + (parseInt(cash_in_old_value) && parseInt(cash_out_old_value) ? ', ' : '') + (parseInt(cash_out_old_value) ? this.translate.instant('approval.cash_out') : ''),
          new_value: (parseInt(cash_in_new_value) ? this.translate.instant('approval.cash_in') : '') + (parseInt(cash_in_new_value) && parseInt(cash_out_new_value) ? ', ' : '') + (parseInt(cash_out_new_value) ? this.translate.instant('approval.cash_out') : '')
        };
        transactions[0].transaction_details = [...transactions[0].transaction_details, dataToPush];
      }
    }
    for (const element of transactions[0].transaction_details) {
      if (element.column_name !== 'merchant_id' && element.column_name !== 'cash_in' && element.column_name !== 'cash_out') {
        if ((element.column_name === 'country_id') || (element.column_name === 'region_id') || (element.column_name === 'subregion_id')) {
          if (element?.old_relation) element.old_relation = this.translationService.getTranslationData(element.old_relation);
          if (element?.new_relation) element.new_relation = this.translationService.getTranslationData(element.new_relation);
          element.column_name = element.column_name === 'country_id' ? this.translate.instant('approval.country') : element.column_name === 'region_id' ? this.translate.instant('approval.region') : this.translate.instant('approval.subregion');
          element.old_value = element?.old_relation?.translations?.[this.currentLanguage]?.name ? element.old_relation.translations[this.currentLanguage].name : '';
          element.new_value = element?.new_relation?.translations?.[this.currentLanguage]?.name ? element.new_relation.translations[this.currentLanguage].name : '';
        }

        if (element.column_name === 'address') {
          element.column_name = this.translate.instant('approval.addressDetails');
        }
        if (element.column_name === 'description') {
          element.column_name = this.translate.instant('approval.manager');
        }
        if (element.column_name === 'type_id') {
          element.new_value = this.branchTypesById && this.branchTypesById[element.new_value] && this.branchTypesById[element.new_value].translations && this.branchTypesById[element.new_value].translations[this.currentLanguage] ? this.branchTypesById[element.new_value].translations[this.currentLanguage].name : element.new_value;
          element.old_value = this.branchTypesById && this.branchTypesById[element.old_value] && this.branchTypesById[element.old_value].translations && this.branchTypesById[element.old_value].translations[this.currentLanguage] ? this.branchTypesById[element.old_value].translations[this.currentLanguage].name : element.old_value;
          element.column_name = this.translate.instant('approval.mainBranchType');
        }
        if (element.column_name === 'lat') {
          element.column_name = this.translate.instant('approval.latitude');
        }
        if (element.column_name === 'lng') {
          element.column_name = this.translate.instant('approval.longtitude');
        }
        if (element.column_name === 'active') {
          element.column_name = this.translate.instant('approval.status');
          element.old_value = element.old_value == 1 ? this.translate.instant('approval.active') : this.translate.instant('approval.inactive');
          element.new_value = element.new_value == 1 ? this.translate.instant('approval.active') : this.translate.instant('approval.inactive');
        }
        if (element.column_name === 'branch_type') {
          if ((element.old_value)?.includes("smartatm")) element.old_value = (element.old_value)?.replace('smartatm', this.translate.instant('approval.smartAtm'));
          if ((element.new_value)?.includes("smartatm")) element.new_value = (element.new_value)?.replace('smartatm', this.translate.instant('approval.smartAtm'));
          if ((element.old_value)?.includes("atm")) element.old_value = (element.old_value)?.replace('atm', this.translate.instant('approval.atm'));
          if ((element.new_value)?.includes("atm")) element.new_value = (element.new_value)?.replace('atm', this.translate.instant('approval.atm'));
          if (element.old_value) element.old_value = ((element.old_value)?.split(','))?.join(', ');
          if (element.new_value) element.new_value = ((element.new_value)?.split(','))?.join(', ');
        }

        dataToSend.pending_transaction.transaction_details.push({
          column_name: element.column_name,
          old_value: element.old_value,
          new_value: element.new_value,
          lang: element.lang
        });
      }
    }
    if (transactions.length > 1) {
      for (let x = 1; x < transactions.length; x++) {
        if (transactions[x].key == 'opening_hours') {
          const dataToadd = {
            column_name: this.translate.instant('approval.openingHours') + ' : ' + (transactions[x].feature_item ? transactions[x].feature_item.day : ''),
            old_value: '',
            new_value: ''
          };
          for (const element of transactions[x].transaction_details) {
            if (!transactions[x].feature_item && element.column_name === 'day') {
              dataToadd.column_name += element.new_value;
            }
            if (element.column_name === 'is_closed' && element.old_value === 1) {
              dataToadd.old_value += this.translate.instant('approval.closed');
            }
            if (element.column_name === 'is_closed' && element.new_value === 1) {
              dataToadd.new_value += this.translate.instant('approval.closed');
            }
            if (element.column_name === 'from_time') {
              dataToadd.old_value += element.old_value ? (this.translate.instant('approval.open') + ' : ' + element.old_value) : '';
              dataToadd.new_value += element.new_value ? (this.translate.instant('approval.open') + ' : ' + element.new_value) : '';
            }
            if (element.column_name === 'to_time') {
              dataToadd.old_value += element.old_value ? (' ' + this.translate.instant('approval.close') + ' : ' + element.old_value) : '';
              dataToadd.new_value += element.new_value ? (' ' + this.translate.instant('approval.close') + ' : ' + element.new_value) : '';
            }
          }
          dataToSend.pending_transaction.transaction_details.push(dataToadd);
        }
      }
    }
    dataToSend.pending_transaction.transaction_details = _.orderBy(dataToSend.pending_transaction.transaction_details, ['lang'],['desc']);
    return dataToSend;
  }

  getPendingAppModeData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };
    if (transactions[0].description_transaction) {
      dataToSend.pending_transaction.description_transaction = transactions[0].description_transaction;
    }
    for (const element of transactions[0].transaction_details) {
      dataToSend.pending_transaction.transaction_details.push({
        column_name: element.column_name,
        old_value: element.old_value,
        new_value: element.new_value
      });
    }
    return dataToSend;
  }

  getPendingPagesModeData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };
    if (transactions[0].description_transaction) {
      dataToSend.pending_transaction.description_transaction = transactions[0].description_transaction;
    }
    for (const element of transactions[0].transaction_details) {
      if (element.column_name === 'content') {
        const spanold = document.createElement('span');
        const spannew = document.createElement('span');
        spanold.innerHTML = element.old_value;
        spannew.innerHTML = element.new_value;

        element.old_value = spanold.innerText;
        element.new_value = spannew.innerText;
      }
      if (element.column_name === 'category_id') {
        element.column_name = this.translate.instant('approval.category_name');
        element.old_value = element.old_relation?.name ? element.old_relation?.name : '-';
        element.new_value = element.new_relation?.name ? element.new_relation?.name : '-';
      }
      if (element.column_name === 'name') {
        element.lang = element.lang ? element.lang : '-';
      }
      dataToSend.pending_transaction.transaction_details.push({
        column_name: element.column_name,
        old_value: element.old_value,
        new_value: element.new_value,
        lang: element.lang
      });
    }
    dataToSend.pending_transaction.transaction_details = _.orderBy(dataToSend.pending_transaction.transaction_details, ['lang'],['desc']);
    return dataToSend;
  }

  getPendingTemplates(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };
    if (transactions[0].description_transaction) {
      dataToSend.pending_transaction.description_transaction = transactions[0].description_transaction;
    }

    const typeColumn = this.getColumn(transactions[0].transaction_details, 'type');
    let oldtype = null;
    let newtype = null;
    if (typeColumn) {
      newtype = typeColumn.new_value;
      oldtype = typeColumn.old_value;
    } else {
      newtype = oldtype = transactions[0].feature_item.type;
    }

    for (const element of transactions[0].transaction_details) {
      if (element.column_name == 'message') {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: element.column_name,
          old_value: element.old_value ? (oldtype == 1 ? (element.old_value).text() : element.old_value) : null,
          new_value: element.new_value ? (newtype == 1 ? (element.new_value).text() : element.new_value) : null
        });
      } else {
        if (element.column_name == 'type') {
          dataToSend.pending_transaction.transaction_details.push({
            column_name: element.column_name,
            old_value: element.old_value ? this.templatetypes[element.old_value] : null,
            new_value: element.new_value ? this.templatetypes[element.new_value] : null
          });
        } else {
          dataToSend.pending_transaction.transaction_details.push({
            column_name: element.column_name,
            old_value: element.old_value,
            new_value: element.new_value
          });
        }
      }
    }


    return dataToSend;
  }

  getPendingRules(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };
    if (transactions[0].description_transaction) {
      dataToSend.pending_transaction.description_transaction = transactions[0].description_transaction;
    }
    for (const element of transactions[0].transaction_details) {
      if (element.column_name != 'app_id') {
        if (element.column_name == 'link') {
          element.column_name = this.translate.instant('approval.url');
        }
        if (element.column_name == 'message') {
          element.column_name = this.translate.instant('approval.message') + ' ' + this.capitalizeFirstLetter(element.lang);
        }
        dataToSend.pending_transaction.transaction_details.push({
          column_name: element.column_name,
          old_value: element.old_value,
          new_value: element.new_value
        });
      }

    }
    return dataToSend;
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  getPendingNotifications(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };

    if (transactions[0]?.description_transaction) {
      dataToSend.pending_transaction.description_transaction = transactions[0].description_transaction;
    }

    for (const element of transactions[0].transaction_details) {
      if (element.column_name !== 'app_id') {
        if (element.column_name === 'type') {
          dataToSend.pending_transaction.transaction_details.push({
            column_name: this.translate.instant('approval.type'),
            old_value: element.old_value === '0' ? this.translate.instant('approval.push') : element.old_value === '2' ? this.translate.instant('approval.sms') : element.old_value === '3' ? this.translate.instant('approval.email') : '-',
            new_value: element.new_value === '0' ? this.translate.instant('approval.push') : element.new_value === '2' ? this.translate.instant('approval.sms') : element.new_value === '3' ? this.translate.instant('approval.email') : '-',
          });
        } else {
          dataToSend.pending_transaction.transaction_details.push({
            column_name: element.column_name,
            old_value: element.old_value,
            new_value: element.new_value,
            lang: element.lang
          });
        }
      }
    }
    dataToSend.pending_transaction.transaction_details = _.orderBy(dataToSend.pending_transaction.transaction_details, ['lang'],['desc']);
    return dataToSend;
  }

  getSystemConfigurationData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };

    for (const element of transactions) {
      if (element.transaction_details.length > 0) {
        if (element.feature_item.key === 'bbk_usd_bhd_rate' || element.feature_item.key === 'remittance_transactions_number'
          || element.feature_item.key === 'sanction_screening_score_threshold' || element.feature_item.key === 'tax_charges_percentage') {
          dataToSend.pending_transaction.transaction_details.push({
            column_name: element.feature_item.key,
            old_value: element.transaction_details[0].old_value,
            new_value: element.transaction_details[0].new_value
          });
        } else if (element.feature_item.key === 'mastercard_send_income_gl_bhd_account_type' || element.feature_item.key === 'mastercard_send_expense_gl_bhd_account_type'
          || element.feature_item.key === 'try_p_l_income_gl_bhd_account_type' || element.feature_item.key === 'mastercard_send_csc_bhd_account_type'
          || element.feature_item.key === 'citi_mirror_account_usd_account_type') {
          dataToSend.pending_transaction.transaction_details.push({
            column_name: this.filterByName(element.feature_item.key),
            old_value: this.filterByTag(element.transaction_details[0].old_value),
            new_value: this.filterByTag(element.transaction_details[0].new_value)
          });
        } else {
          dataToSend.pending_transaction.transaction_details.push({
            column_name: this.filterByName(element.feature_item.key),
            old_value: element.transaction_details[0].old_value,
            new_value: element.transaction_details[0].new_value
          });
        }
      }
    }
    return dataToSend;
  }

  filterByTag(tag) {
    switch (tag) {
      case 'CSC':
        return this.translate.instant('approval.cscHoldoverAccount');
      case 'GL':
        return this.translate.instant('approval.glGeneralLedger');
      case 'CNS':
        return this.translate.instant('approval.cnsNostroAccount');
      default:
        return this.translate.instant('approval.invalidAccountType');
    }
  }

  filterByName(name) {
    const colArr = name.split('_');
    for (let i = 0; i < colArr.length; i++) {
      colArr[i] = colArr[i].charAt(0).toUpperCase() + colArr[i].substring(1);

      if (colArr[i].toLowerCase() === 'gl') {
        colArr[i] = 'GL';
      }
      if (colArr[i].toLowerCase() === 'csc') {
        colArr[i] = 'CSC';
      }
      if (colArr[i].toLowerCase() === 'bhd') {
        colArr[i] = '(BHD)';
      }
      if (colArr[i].toLowerCase() === 'usd') {
        colArr[i] = '(USD)';
      }
    }
    return colArr.join(' ');
  }

  getSvaAccountsData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };
    for (const element of transactions) {
      for (let j = 0; j < element.transaction_details.length; j++) {
        if (element.transaction_details[j].column_name !== 'account_type_label') {
          if (element.transaction_details[j].column_name === 'user_id') {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: this.translate.instant('approval.fullname'),
              old_value: element.transaction_details[j]?.old_relation?.profile?.fullname,
              new_value: element.transaction_details[j]?.new_relation?.profile?.fullname,
              lang: element.transaction_details[j].lang
            });
          }
          else if (element.transaction_details[j].column_name === 'status') {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: this.translate.instant('approval.status'),
              old_value: this.getSvaAccountStatusAsAdjective(element.transaction_details[j].old_value),
              new_value: this.getSvaAccountStatusAsAdjective(element.transaction_details[j].new_value),
            });
          } else if (element.transaction_details[j].column_name === 'alias' && (element.transaction_details[j].old_value || element.transaction_details[j].new_value)) {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: this.translate.instant('approval.accountAliasName'),
              old_value: element.transaction_details[j].old_value,
              new_value: element.transaction_details[j].new_value,
            });
          } else if (element.transaction_details[j].column_name === 'amount') {
            let oldValue = element.transaction_details[j].old_value;
            let oldValueNumber = oldValue ? (oldValue?.includes(" ") ? (oldValue)?.substring(0, (oldValue)?.indexOf(" ")) : oldValue) : 0;
            let oldValueCurrency = (oldValue && oldValue?.includes(" ")) ? (oldValue)?.split(" ")?.pop() : '';
            let newValue = element.transaction_details[j].new_value;
            let newValueNumber = newValue ? (newValue?.includes(" ") ? (newValue)?.substring(0, (newValue)?.indexOf(" ")) : newValue) : 0;
            let newValueCurrency = (newValue && newValue?.includes(" ")) ? (newValue)?.split(" ")?.pop() : '';
            dataToSend.pending_transaction.transaction_details.push({
              column_name: element.transaction_details[j].column_name,
              old_value: this.currencyService.getFormattedNumberBasedOnCurrency(oldValueNumber, oldValueCurrency) + ' ' + oldValueCurrency,
              new_value: this.currencyService.getFormattedNumberBasedOnCurrency(newValueNumber, newValueCurrency) + ' ' + newValueCurrency,
            });
          } else {
            if (!(element.transaction_details[j].column_name === 'alias')) {
              dataToSend.pending_transaction.transaction_details.push({
                column_name: element.transaction_details[j].column_name,
                old_value: element.transaction_details[j].old_value,
                new_value: element.transaction_details[j].new_value,
                lang: element.transaction_details[j].lang
              });
            }
          }
        }

      }
    }
    return dataToSend;
  }

  getSvaAccountStatusAsAdjective(data) {
    switch (data) {
      case ('Activate'):
      case ('activate'):
      case ('Active'):
        return this.translate.instant('approval.active');
      case ('Close'):
      case ('close'):
      case ('Closed'):
        return this.translate.instant('approval.close');
      case ('Suspend'):
      case ('suspend'):
      case ('Suspended'):
        return this.translate.instant('approval.suspend');
    }
  }

  getPendingDepartmentData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };
    if (transactions[0].description_transaction) {
      dataToSend.pending_transaction.description_transaction = transactions[0].description_transaction;
    }
    for (const element of transactions[0].transaction_details) {
      if (element.column_name != 'id'
        && !(
          element.column_name == 'name' &&
          element.lang == 'ar'
        )
        && element.column_name != 'type'
        && element.column_name !== 'new_department'
        && !(element.column_name == 'employees_action' && !element.old_value && !element.new_value)
      ) {
        if (element.column_name == 'name') {
          element.column_name = this.translate.instant('approval.departmentName');
        }
        if (element.column_name == 'external_reference_id') {
          element.column_name = this.translate.instant('departments.processorDepartmentId');
        }
        if (element.column_name == 'corporate_id') {
          element.column_name = this.translate.instant('approval.companyName');
          element.old_value = this.parser.translationsParser('name')(element.old_relation);
          element.new_value = this.parser.translationsParser('name')(element.new_relation);
        }
        if(element.column_name === 'status') {
          element.column_name = element.column_name;
          element.old_value = this.translate.instant(`departments.${element.old_value?.toLowerCase()}`);
          element.new_value = this.translate.instant(`departments.${element.new_value?.toLowerCase()}`);
        }
        dataToSend.pending_transaction.transaction_details.push({
          column_name: element.column_name,
          old_value: element.old_value,
          new_value: element.new_value
        });
      }

    }
    return dataToSend;
  }

  getPendingEmployeeData(transactions) {
    if (transactions[0].key === 'employee.upload') {
      let data = transactions[0];
      return {pending_transaction: data};
    }
    if (transactions[0].key === 'employee.block') {
      let data = transactions[0];
      data.transaction_details = data.transaction_details.filter(object => {
        return object.column_name !== "status_message" && object.column_name !== "active" && object.column_name !== "prev_account_status";
      });
      for (const element of data.transaction_details) {
        if (element.column_name === 'account_status') {
          if (element.old_relation) element.old_relation = this.translationService.getTranslationData(element.old_relation);
          if (element.new_relation) element.new_relation = this.translationService.getTranslationData(element.new_relation);
          element.column_name = this.translate.instant('approval.accountStatus');
          element.old_value = element.old_value ? (element.old_relation?.translations?.[this.currentLanguage]?.title ? element.old_relation.translations[this.currentLanguage].title : element.old_value) : '-';
          element.new_value = element.new_relation?.translations?.[this.currentLanguage]?.title ? element.new_relation.translations[this.currentLanguage].title : element.new_value;
        }
      }
      data.description_transaction = get(data, 'feature_item.profile.fullname') || get(data, 'feature_item.email')
      return {pending_transaction: data};
    }
    if (transactions[0].key === 'employee.profile') {
      let data = transactions[0];
      data.description_transaction = get(data, 'feature_item.profile.fullname') || get(data, 'feature_item.email')
      return {pending_transaction: data};
    }
    if (transactions[0].key === 'employee.cards') {
      return this.getPendingCardsData(transactions);
    }
    if (transactions[0].key === 'devices.active') {
      return this.getPendingDevices(transactions);
    }
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };
    if (transactions[0].description_transaction) {
      dataToSend.pending_transaction.description_transaction = transactions[0].description_transaction;
    }

    for (const element of transactions) {
      if (element.key === 'employee') {
        if (element.callback_params) {
          const callbackProperties = JSON.parse(element.callback_params);
          keys(callbackProperties).forEach(key => {
            let propName = (key === 'first_name') ? 'fname' : key;
            propName = (key === 'last_name') ? 'lname' : propName;
            if (!element.transaction_details.map(i => i.column_name).includes(key)) {
              element.transaction_details.push({
                column_name: key,
                old_value: get(element, `feature_item.${propName}`)
                  || get(element, `feature_item.extra_data.${propName}`)
                  || get(element, `feature_item.user.${propName}`)
                  || get(element, `feature_item.user.profile.${propName}`)
                  || null,
                new_value: callbackProperties[key]
              });
            }
          });
        }
        for (let i = 0; i < element.transaction_details.length; i++) {
          if (element.transaction_details[i].column_name != 'id'
            && element.transaction_details[i].column_name != 'user_type'
            && element.transaction_details[i].column_name != 'documents'
            && element.transaction_details[i].column_name != 'url'
            && isNaN(element.transaction_details[i].column_name)
          ) {
            if (element.transaction_details[i].column_name == 'dob') {
              element.transaction_details[i].column_name = this.translate.instant('customer.dob');
            }
            if (element.transaction_details[i].column_name == 'corporateEmployeesBlocked') {
              element.transaction_details[i].column_name = this.translate.instant('employees.blockedByCompany');
            }
            if (element.transaction_details[i].column_name == 'department_id') {
              element.transaction_details[i].column_name = this.translate.instant('approval.departmentName');
              element.transaction_details[i].old_value = this.parser.translationsParser('name')(transactions[0].transaction_details[i].old_relation);
              element.transaction_details[i].new_value = this.parser.translationsParser('name')(transactions[0].transaction_details[i].new_relation);
            }
            if (element.transaction_details[i].column_name == 'corporate_id') {
              element.transaction_details[i].column_name = this.translate.instant('approval.companyName');
              element.transaction_details[i].old_value = this.parser.translationsParser('name')(transactions[0].transaction_details[i].old_relation);
              element.transaction_details[i].new_value = this.parser.translationsParser('name')(transactions[0].transaction_details[i].new_relation);
            }
            if (element.transaction_details[i].column_name !== 'extra_data') {
              dataToSend.pending_transaction.transaction_details.push({
                column_name: element.transaction_details[i].column_name,
                old_value: element.transaction_details[i].old_value,
                new_value: element.transaction_details[i].new_value
              });
            }

          }

        }
      }
    }

    const newDocuments = transactions.filter(transaction => transaction.key === 'employee.docs')
      .map(transaction => transaction.transaction_details.filter(td => td.column_name === 'document_id'))
      .flat()
      .map(document => document.new_relation)
      .filter(item => item);
    const oldDocuments =transactions.filter(transaction => transaction.key === 'employee')
      .map(transaction => transaction.feature_item)
      .filter(item => item)
      .map(item => item.documents)
      .flat();
    if (newDocuments?.length || oldDocuments?.length) {
      dataToSend.pending_transaction.transaction_details.push({
        column_name: 'documents',
        old_value: oldDocuments,
        new_value: newDocuments
      });
    }
    return dataToSend;
  }

  getPendingCardsData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: transactions[0].description_transaction || '',
        transaction_details: []
      }
    };

    if (transactions && transactions.length) {
      for (const element of transactions[0].transaction_details) {
        if (element.column_name !== 'expiry' && element.column_name !== 'currency' && element.column_name !== 'product_id' && element.column_name !== 'card_id') {
          if (element.column_name === 'user_id') {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: this.translate.instant('approval.username'),
              old_value: element.old_relation?.profile?.fullname ? element.old_relation?.profile?.fullname : '-',
              new_value: element.new_relation?.profile?.fullname ? element.new_relation?.profile?.fullname : '-'
            });
          } else if(element.column_name === 'status') {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: this.translate.instant('approval.status'),
              old_value: element.old_value,
              new_value: (element.new_value).toLowerCase() === 'cancelled' ? this.translate.instant('approval.cancelled') : element.new_value
            });
          } else if(element.column_name === 'display_name') {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: this.translate.instant('customer.cardname'),
              old_value: element.old_value,
              new_value: element.new_value
            });
          } else {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: element.column_name,
              old_value: element.old_value,
              new_value: element.new_value
            });
          }
        }
      }
    }
    return dataToSend;
  }

  getPendingCompaniesData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: transactions[0]?.description_transaction || '',
        transaction_details: []
      }
    };

    if (transactions && transactions.length) {
      for (const element of transactions[0].transaction_details) {
        if (element.column_name !== 'id') {
          if(element.column_name === 'status') {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: element.column_name,
              old_value: element.old_value ? this.translate.instant(`companies.${element.old_value?.toLowerCase()}`) : '',
              new_value: element.new_value ? this.translate.instant(`companies.${element.new_value?.toLowerCase()}`) : ''
            });
          } else if (element.column_name === 'extra_data' && element.new_value && transactions[0].key === 'fintechs.services') {
            let extraData = JSON.parse(element.new_value);
            if (extraData) {
              Object.keys(extraData).forEach(key => {
                if (key === 'default_vcn_product_id') {
                  let productName = this.cardProducts.find(item => item.id === extraData[key])?.name;
                  if (productName) {
                    dataToSend.pending_transaction.transaction_details.push({
                      column_name: this.translate.instant('services.configurations.vcn.cardProduct'),
                      new_value: productName
                    });
                  }
                } else {
                  dataToSend.pending_transaction.transaction_details.push({
                    column_name: key,
                    new_value: extraData[key]
                  });
                }
              });
            }
          } else {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: element.column_name,
              old_value: element.old_value,
              new_value: element.new_value
            });
          }
        }
      }

      if (transactions[0]?.extra?.custom_feature_item?.company && transactions[0]?.feature_item?.feature?.name) {
        let arrayOfObject = [
          {
            key: this.translate.instant('approval.company'),
            value: transactions[0].extra.custom_feature_item.company
          },
          {
            key: this.translate.instant('approval.name'),
            value: transactions[0].feature_item.feature.name
          }
        ];
        dataToSend.pending_transaction.description_transaction = this.transformJsonToString(arrayOfObject);
      }
    }
    return dataToSend;
  }

  getPendingIDVData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };

    if (transactions && transactions.length) {
      for (const element of transactions) {
        for (let i = 0; i < element.transaction_details.length; i++) {
          if (element.transaction_details[i].column_name == 'capture_screen' ||
            element.transaction_details[i].column_name == 'preview_screen' ||
            element.transaction_details[i].column_name == 'tests') {

            if (this.hasJsonStructure(element.transaction_details[i].old_value)) {
              element.transaction_details[i].old_value = JSON.parse(element.transaction_details[i].old_value) ?? "";
              element.transaction_details[i].old_value = this.convertToString(element.transaction_details[i].old_value) ?? "";
              element.transaction_details[i].old_value_display = "JSON";
            }
            if (this.hasJsonStructure(element.transaction_details[i].new_value)) {
              element.transaction_details[i].new_value = JSON.parse(element.transaction_details[i].new_value) ?? "";
              element.transaction_details[i].new_value = this.convertToString(element.transaction_details[i].new_value) ?? "";
              element.transaction_details[i].new_value_display = "JSON";
            }


          }



          dataToSend.pending_transaction.transaction_details.push({
            column_name: element.transaction_details[i].column_name,
            old_value: element.transaction_details[i].old_value,
            new_value: element.transaction_details[i].new_value
          });


        }
      }
    }
    return dataToSend;
  }

  getPricingManagementData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };

    if (transactions && transactions.length) {
      for (const element of transactions) {
        for (let j = 0; j < element.transaction_details.length; j++) {
          if (element.transaction_details[j].column_name !== 'merchant_group_id' &&
            element.transaction_details[j].column_name !== 'id')
          {
            if (element.transaction_details[j].column_name === 'equation') {
              let newEquationData = JSON.parse(element.transaction_details[j].new_value);
              let oldEquationData = JSON.parse(element.transaction_details[j].old_value);
              let equationData = [];
              for (const property in newEquationData) {
                if (property.toLowerCase() !== 'recipient') {
                  equationData.push({
                    column_name: property,
                    old_value: oldEquationData ? oldEquationData[property] : null,
                    new_value: newEquationData ? newEquationData[property] : null
                  });
                }
              }
              equationData.forEach((item: any) => {
                dataToSend.pending_transaction.transaction_details.push({
                  column_name: item.column_name,
                  old_value: item.old_value,
                  new_value: item.new_value
                });
              });
            } else if (element.transaction_details[j].column_name === 'acquirer_id') {
              dataToSend.pending_transaction.transaction_details.push({
                column_name: this.translate.instant('approval.acquirerName'),
                old_value: element.transaction_details[j]?.old_relation?.email,
                new_value: element.transaction_details[j]?.new_relation?.email,
              });
            } else if (element.transaction_details[j].column_name === 'payment_type_id') {
              dataToSend.pending_transaction.transaction_details.push({
                column_name: this.translate.instant('approval.paymentMethod'),
                old_value: element.transaction_details[j]?.old_relation?.name,
                new_value: element.transaction_details[j]?.new_relation?.name,
              });
            } else {
              dataToSend.pending_transaction.transaction_details.push({
                column_name: element.transaction_details[j].column_name,
                old_value: element.transaction_details[j].old_value,
                new_value: element.transaction_details[j].new_value,
                lang: element.transaction_details[j].lang
              });
            }
          }
        }
      }
    }
    return dataToSend;
  }

  getMerchantManagementData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };

    if (transactions[0].description_transaction && !(transactions[0].transaction_details.some(i => i.column_name === 'name'))) {
      dataToSend.pending_transaction.description_transaction = transactions[0].description_transaction;
    }

    if (transactions && transactions.length) {
      for (const element of transactions[0].transaction_details) {
        if (element.column_name !== 'id') {
          if(element.column_name === 'active') {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: this.translate.instant('approval.status'),
              old_value: element.old_value == 1 ? this.translate.instant('approval.active') : this.translate.instant('approval.inactive'),
              new_value: element.new_value == 1 ? this.translate.instant('approval.active') : this.translate.instant('approval.inactive')
            });
          } else {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: element.column_name,
              old_value: element.old_value,
              new_value: element.new_value
            });
          }
        }
      }
    }
    return dataToSend;
  }

  getPendingLocationData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };

    if (transactions && transactions.length) {
      for (const element of transactions[0].transaction_details) {
        if (element.column_name !== 'id' && element.column_name !== 'app_id') {
          if (element.column_name === 'active') {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: element.column_name,
              old_value: element.old_value == '1' ? this.translate.instant('approval.yes') : this.translate.instant('approval.no'),
              new_value: element.new_value == '1' ? this.translate.instant('approval.yes') : this.translate.instant('approval.no')
            });
          } else {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: element.column_name,
              old_value: element.old_value,
              new_value: element.new_value,
              lang: element.lang
            });
          }
        }
      }
    }
    dataToSend.pending_transaction.transaction_details = _.orderBy(dataToSend.pending_transaction.transaction_details, ['lang'],['desc']);
    return dataToSend;
  }

  getPendingFeedbackData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };

    if(transactions && transactions.length && transactions[0]?.feature_item) {
      let arrayOfObject = [
        {
          key: this.translate.instant('approval.title'),
          value: transactions[0]?.feature_item?.feedback
        },
        {
          key: this.translate.instant('approval.phone'),
          value: transactions[0]?.feature_item?.user?.phone
        }
      ];
      dataToSend.pending_transaction.description_transaction = this.transformJsonToString(arrayOfObject);
    }

    if (transactions && transactions.length) {
      for (const element of transactions[0].transaction_details) {
        if (element.column_name !== 'id') {
          if(element.column_name === 'read' || element.column_name === 'action_taken') {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: element.column_name,
              old_value: element.old_value == '1' ? this.translate.instant('approval.yes') : this.translate.instant('approval.no'),
              new_value: element.new_value == '1' ? this.translate.instant('approval.yes') : this.translate.instant('approval.no')
            });
          } else {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: element.column_name,
              old_value: element.old_value,
              new_value: element.new_value
            });
          }
        }
      }
    }
    return dataToSend;
  }

  getPendingOwnerAppData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };

    if (transactions && transactions.length) {
      let arrayOfObject = [];
      for (const element of transactions[0].transaction_details) {
        if(element.column_name === 'countries') {
          dataToSend.pending_transaction.transaction_details.push({
            column_name: element.column_name,
            old_value: element.old_value ? (JSON.parse(element.old_value)).join(', ') : '-',
            new_value: element.new_value ? (JSON.parse(element.new_value)).join(', ') : '-'
          });
        } else if(element.column_name === 'active' || element.column_name === 'enabled') {
          dataToSend.pending_transaction.transaction_details.push({
            column_name: this.translate.instant('approval.status'),
            old_value: element.old_value == 1 ? this.translate.instant('approval.active') : this.translate.instant('approval.inactive'),
            new_value: element.new_value == 1 ? this.translate.instant('approval.active') : this.translate.instant('approval.inactive')
          });
        } else if (element.column_name === 'challenge') {
          arrayOfObject.push({
            key: this.translate.instant('approval.challengeName'),
            value: this.replaceSeparatorAndCapitalizePipe.transform(element.new_value)
          });
        } else if(element.column_name === 'country_name' || element.column_name === 'country') {
          arrayOfObject.push({
            key: this.translate.instant('approval.countryName'),
            value: element.new_value
          });
        } else if(element.column_name === 'document_name' || element.column_name === 'doc_type') {
          arrayOfObject.push({
            key: this.translate.instant('approval.documentName'),
            value: element.new_value
          });
        } else if (element.column_name === 'input_name') {
          arrayOfObject.push({
            key: this.translate.instant('approval.inputName'),
            value: this.removeUnderscoreAndCapitalize(element.new_value)
          });
        } else if (element.column_name === 'app_name') {
          arrayOfObject.push({
            key: this.translate.instant('approval.appName'),
            value: element.new_value
          });
        } else if (element.column_name === 'app_id') {
          arrayOfObject.push({
            key: this.translate.instant('approval.app_id'),
            value: element.new_value
          });
        } else if (element.column_name === 'sub_folder_name') {
          arrayOfObject.push({
            key: this.translate.instant('approval.subFolderName'),
            value: element.new_value
          });
        } else if (element.column_name === 'capture_screen' || element.column_name === 'preview_screen' ||
          element.column_name === 'progress_intro_titles' || element.column_name === 'document_verification' || element.column_name === 'personal_verification' ||
          element.column_name === 'terms_and_conditions' || element.column_name === 'progress_one_section_finished_titles' ||
          element.column_name === 'progress_finished_titles' || element.column_name === 'document_screen') {
          let title = '';
          switch (element.column_name) {
            case 'capture_screen':
              title = "shared-component.countries.popup.firstNgContainerTitle";
              break;
            case 'preview_screen':
              title = "shared-component.countries.popup.secondNgContainerTitle";
              break;
            case 'progress_intro_titles':
              title = "approval.progress_intro_titles";
              break;
            case 'document_verification':
              title = "approval.document_verification";
              break;
            case 'personal_verification':
              title = "approval.personal_verification";
              break;
            case 'terms_and_conditions':
              title = "approval.terms_and_conditions";
              break;
            case 'progress_one_section_finished_titles':
              title = "approval.progress_one_section_finished_titles";
              break;
            case 'progress_finished_titles':
              title = "approval.progress_finished_titles";
              break;
            default:
              title = null;
          }
          let newValue = JSON.parse(element.new_value);
          const showResultText = newValue?.visible ?? undefined;
          for (let key in newValue) {
            if ((key != 'overlay_link') && (key != 'icon_link')
              && !(element.column_name == 'capture_screen'
                && (key == 'text' || key == 'button_title'))
              && !(element.column_name == 'preview_screen' && (key == 'visible'))
              && !(element.column_name == 'preview_screen' && (key == 'title') && !showResultText)
            ) {
              dataToSend.pending_transaction.transaction_details.push({
                column_name: (title ? this.translate.instant(title) + " " : '') + this.removeUnderscoreAndCapitalize(key),
                old_value: element.old_value ? JSON.parse(element.old_value)[key] ? JSON.parse(element.old_value)[key] : '-' : '-',
                new_value: element.new_value ? JSON.parse(element.new_value)[key] ? JSON.parse(element.new_value)[key] : '-' : '-'
              });
            }
          }
        } else if (element.column_name === 'tests') {
          let testsArray = JSON.parse(element.old_value);
          if(Array.isArray(testsArray)) {
            testsArray.forEach((item: any) => {
              dataToSend.pending_transaction.transaction_details.push({
                column_name: item.name,
                old_value: item.threshold + '%',
                new_value: JSON.parse(element.new_value).find(newItemData => newItemData.test_id === item.id).threshold  + '%'
              });
            });
          }
        } else {
          if (element.column_name !== 'challenge_id' && element.column_name !== 'flow_id' && element.column_name !== 'input_id' && element.column_name !== 'document_passport_icon_link'
            && element.column_name !== 'document_civil_id_icon_link' && element.column_name !== 'intro_icon_link') {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: element.column_name,
              old_value: element.old_value,
              new_value: element.new_value
            });
          }
        }
      }
      dataToSend.pending_transaction.description_transaction = this.transformJsonToString(arrayOfObject);
    }
    return dataToSend;
  }

  getPendingIdvOperationResultsdData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };

    if (transactions && transactions.length) {
      let arrayOfObject = [];
      for (const element of transactions[0].transaction_details) {
        if (element.column_name === 'cms_approval_status') {
          const objectsToAdd = [
            {
              key: this.translate.instant('approval.action'),
              value: transactions[0]?.extra?.action || ''
            },
            {
              key: this.translate.instant('approval.idvAccountName'),
              value: transactions[0]?.extra?.custom_item?.user?.fullname || ''
            },
            {
              key: this.translate.instant('approval.section'),
              value: transactions[0]?.extra?.section || ''
            },
            {
              key: this.translate.instant('approval.idvAccountId'),
              value: transactions[0]?.feature_item?.user?.profile?.user_id || ''
            },
            {
              key: this.translate.instant('approval.idvAccountName'),
              value: transactions[0]?.feature_item?.user?.profile?.fullname || ''
            }
          ];

          arrayOfObject.push(...objectsToAdd.filter(obj => obj.value));
          dataToSend.pending_transaction.transaction_details.push({
            column_name: element.column_name,
            old_value: element.old_value == 1 ? this.translate.instant('approval.approved') : this.translate.instant('approval.pending'),
            new_value: element.new_value == 1 ? this.translate.instant('approval.approved') : this.translate.instant('approval.pending'),
          });
        } else if (element.column_name === 'status') {
          dataToSend.pending_transaction.transaction_details.push({
            column_name: element.column_name,
            old_value: element.old_value == 1 ? this.translate.instant('approval.active') : this.translate.instant('approval.pending'),
            new_value: element.new_value == 1 ? this.translate.instant('approval.active') : this.translate.instant('approval.pending'),
          });
        } else {
          if (element.column_name !== 'cms_action_by' && element.column_name !== 'cms_action_at' && element.column_name !== 'id' && element.column_name != 'result_status') {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: element.column_name,
              old_value: element.old_value,
              new_value: element.new_value
            });
          }
        }
      }
      dataToSend.pending_transaction.description_transaction = this.transformJsonToString(arrayOfObject);
    }
    return dataToSend;
  }

  getPendingDashboardData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };

    if (transactions && transactions.length) {
      for (const element of transactions) {
        for (let i = 0; i < element.transaction_details.length; i++) {
          if (element.feature_item?.dashboard_widget) element.feature_item.dashboard_widget = this.translationService.getTranslationData(element.feature_item.dashboard_widget);
          if(element.transaction_details[i].column_name === 'active') {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: element.feature_item?.dashboard_widget?.translations?.[this.currentLanguage]?.title ? element.feature_item?.dashboard_widget?.translations?.[this.currentLanguage]?.title : element?.feature_item?.dashboard_widget?.key,
              old_value: element.feature_item?.active === 1 ? this.translate.instant('approval.inactive') : this.translate.instant('approval.active'),
              new_value: element.feature_item?.active === 1 ? this.translate.instant('approval.active') : this.translate.instant('approval.inactive')
            });
          } else if(element.transaction_details[i].column_name === 'order') {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: (element.feature_item?.dashboard_widget?.translations?.[this.currentLanguage]?.title ? element.feature_item?.dashboard_widget?.translations?.[this.currentLanguage]?.title : element?.feature_item?.dashboard_widget?.key) + " " + element.transaction_details[i].column_name,
              old_value: toInteger(element.transaction_details[i].old_value) + 1,
              new_value: toInteger(element.transaction_details[i].new_value) + 1
            });
          } else {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: element.transaction_details[i].column_name,
              old_value: element.transaction_details[i].old_value,
              new_value: element.transaction_details[i].new_value
            });
          }
        }
      }
    }
    return dataToSend;
  }

  getWorkflowData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };

    if (transactions && transactions.length) {
      for (const element of transactions[0].transaction_details) {
        if(element.column_name === 'status') {
          dataToSend.pending_transaction.transaction_details.push({
            column_name: element.column_name,
            old_value: this.translate.instant(`applicationsWorkflow.${element.old_value?.toLowerCase()}`),
            new_value: this.translate.instant(`applicationsWorkflow.${element.new_value?.toLowerCase()}`)
          });
        } else if (element.column_name !== 'key') {
          dataToSend.pending_transaction.transaction_details.push({
            column_name: element.column_name,
            old_value: element.old_value,
            new_value: element.new_value,
            lang: element.lang
          });
        }
      }
    }
    return dataToSend;
  }

  getPendingMakercheckerdData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };

    if (transactions && transactions.length) {
      for (const element of transactions) {
        for (let i = 0; i < element.transaction_details.length; i++) {
          dataToSend.pending_transaction.transaction_details.push({
            column_name: element.feature_item?.title,
            old_value: element.transaction_details[i].old_value == 1 ? this.translate.instant('approval.active') : this.translate.instant('approval.inactive'),
            new_value: element.transaction_details[i].new_value == 1 ? this.translate.instant('approval.active') : this.translate.instant('approval.inactive')
          });
        }
      }
    }
    return dataToSend;
  }

  getPendingDynamicFormsEkycData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };

    if (transactions[0].feature_item?.name) {
      let arrayOfObject = [
        {
          key: this.translate.instant('approval.name'),
          value: transactions[0].feature_item.name
        }
      ];
      dataToSend.pending_transaction.description_transaction = this.transformJsonToString(arrayOfObject);
    }
    if (transactions && transactions.length) {
      for (const element of transactions) {
        for (let i = 0; i < element.transaction_details.length; i++) {
          const transaction = element.transaction_details[i];
          if(transaction.column_name == 'elements'){
            dataToSend.pending_transaction.transaction_details.push({
              column_name: transaction?.column_name,
              old_value: transaction?.old_value ? JSON.parse(transaction.old_value) : '-',
              new_value: transaction?.new_value ? JSON.parse(transaction.new_value) : '-',
              displayAs: "JSON"
            });
          }
          else if(transaction.column_name == 'can_edit'){
            dataToSend.pending_transaction.transaction_details.push({
              column_name: transaction.column_name,
              old_value: transaction?.old_value == '0' || transaction?.old_value == 0 ||  transaction?.old_value == false ||  transaction?.old_value == 'false' ? this.translate.instant('general.no') : this.translate.instant('general.yes'),
              new_value: transaction?.new_value == '0' || transaction?.new_value == 0 ||  transaction?.new_value == false ||  transaction?.new_value == 'false' ? this.translate.instant('general.no') : this.translate.instant('general.yes')
            });
          }
          else if(transaction.column_name !== 'form_version'
            && transaction.column_name !== 'app_id'
            && transaction.column_name !== 'group_key'
          ) {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: transaction.column_name,
              old_value: transaction?.old_value ?? '-',
              new_value: transaction.new_value ?? '-',
              lang:  transaction.lang,
            });
          }
        }
      }
    }
    return dataToSend;
  }
  getPendingDynamicFormsData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: transactions[0]?.description_transaction || '',
        transaction_details: []
      }
    };

    if (transactions[0]?.key === 'dynamic_form_groups') {
      for (let i = 0; i < transactions[0].transaction_details.length; i++) {
        if(transactions[0].transaction_details[i].column_name !== 'form_version'
          && transactions[0].transaction_details[i].column_name !== 'id'
          && transactions[0].transaction_details[i].column_name !== 'app_id'
          && !(transactions[0].transaction_details[i].column_name == 'name' && !transactions[0].transaction_details[i].lang)
          && !(transactions[0].transaction_details[i].column_name == 'description' && !transactions[0].transaction_details[i].lang)
        ) {
          dataToSend.pending_transaction.transaction_details.push({
            column_name: transactions[0].transaction_details[i].column_name,
            old_value: transactions[0].transaction_details[i].old_value,
            new_value: transactions[0].transaction_details[i].new_value,
            lang:  transactions[0].transaction_details[i].lang,
          });
        }
      }

      // TODO: change "forms" in "transactions[0].feature_item?.forms" to the property that backend returns when done from their end
      const oldforms = transactions[0].feature_item?.forms?.map((t, i) => ({form: t, order: i + 1}));
      const addedForms = transactions.filter(t => t.key === 'form_group_data' && t.action === 'add').map(t =>  ({form:  t.transaction_details.find(td => td.column_name === 'form_id')?.new_relation?.form, order:  t.transaction_details.find(td => td.column_name === 'order')?.new_value}));

      // TODO: change "forms" in "transactions[0].feature_item?.forms" to the property that backend returns when done from their end
      const deletedForms = transactions.filter(t => t.key === 'form_group_data' && t.action === 'delete').map(t =>  ({form:transactions[0].feature_item?.forms?.find(g => g.id == t.feature_item?.form_id), order: t.feature_item?.order}));

      // TODO: change "forms" in "transactions[0].feature_item?.forms" to the property that backend returns when done from their end
      const editedForms = transactions.filter(t => t.key === 'form_group_data' && t.action === 'edit').map(t =>  ({form: transactions[0].feature_item?.forms?.find(g => g.id == t.feature_item?.form_id), order: t.transaction_details.find(td => td.column_name === 'order')?.new_value}));

      dataToSend.pending_transaction.transaction_details.push({
        column_name: this.translate.instant('approval.dynamicForms.forms'),
        old_value: orderBy(oldforms, ['order']).map(f => f.form?.name).join(', '),
        new_value:  orderBy(differenceBy(unionBy(editedForms, addedForms, oldforms, ((item: any) => item.form?.id)), deletedForms, (item => item.form?.id) ) , ['order']).map(f => f.form?.name).join(', '),
      });
    }
    else  if (transactions[0]?.key === 'dynamic_group_sub_groups') {
      for (let i = 0; i < transactions[0].transaction_details.length; i++) {
        if(transactions[0].transaction_details[i].column_name !== 'form_version'
          && transactions[0].transaction_details[i].column_name !== 'id'
          && transactions[0].transaction_details[i].column_name !== 'app_id'
          && transactions[0].transaction_details[i].column_name !== 'reviewable'
          && transactions[0].transaction_details[i].column_name !== 'is_predefined'
          && transactions[0].transaction_details[i].column_name !== 'allow_multiple'
          && transactions[0].transaction_details[i].column_name !== 'type'
          && !(transactions[0].transaction_details[i].column_name == 'name' && !transactions[0].transaction_details[i].lang)
          && !(transactions[0].transaction_details[i].column_name == 'description' && !transactions[0].transaction_details[i].lang)
        ) {
          //TODO: handle showing the workflow name instead of workflow_id
          dataToSend.pending_transaction.transaction_details.push({
            column_name: transactions[0].transaction_details[i].column_name,
            old_value: transactions[0].transaction_details[i].old_value,
            new_value: transactions[0].transaction_details[i].new_value,
            lang:  transactions[0].transaction_details[i].lang,
          });
        }
      }

      const oldSubgroups = transactions[0].feature_item?.sub_groups.map((t, i) => ({group: t, order: i + 1})) || [];
      const addedSubgroups = transactions.filter(t => t.key === 'form_group_category_data' && t.action === 'add').map(t =>  ({group:  t.transaction_details.find(td => td.column_name === 'group_id')?.new_relation?.group ?? t.transaction_details.find(td => td.column_name === 'group_id')?.new_relation, order:  t.transaction_details.find(td => td.column_name === 'order')?.new_value})) || [];
      const deletedSubgroups = transactions.filter(t => t.key === 'form_group_category_data' && t.action === 'delete').map(t =>  ({group: transactions[0].feature_item?.sub_groups.find(g => g.id == t.feature_item?.group_id), order: t.feature_item?.order})) || [];
      const editedSubgroups = transactions.filter(t => t.key === 'form_group_category_data' && t.action === 'edit').map(t =>  ({group: transactions[0].feature_item?.sub_groups.find(g => g.id == t.feature_item?.group_id), order: t.transaction_details.find(td => td.column_name === 'order')?.new_value}));

      // TODO: remove the two occurences of  f.group?.key in the below code. It should be f.group?.name instead of f.group?.name || f.group.key
      dataToSend.pending_transaction.transaction_details.push({
        column_name: this.translate.instant('approval.dynamicForms.subGroups'),
        old_value: oldSubgroups?.map(f => f.group?.name || f.group.key).join(', '),
        new_value:  orderBy(differenceBy(unionBy(editedSubgroups, addedSubgroups, oldSubgroups, ((item: any) => item.group?.id)), deletedSubgroups, (item => item.group?.id) ) , ['order']).map(f => f.group?.name || f.group?.key).join(', '),
      });

    } else if (transactions && transactions.length) {
      for (const element of transactions) {
        for (let i = 0; i < element.transaction_details.length; i++) {
          if(transactions[0].transaction_details[i].column_name !== 'form_version'
            && transactions[0].transaction_details[i].column_name !== 'id'
            && transactions[0].transaction_details[i].column_name !== 'app_id'
            && !(transactions[0].transaction_details[i].column_name == 'name' && !transactions[0].transaction_details[i].lang)
            && !(transactions[0].transaction_details[i].column_name == 'description' && !transactions[0].transaction_details[i].lang)
          ) {
            dataToSend.pending_transaction.transaction_details.push({
              column_name: element.transaction_details[i].column_name,
              old_value: element.transaction_details[i].old_value,
              new_value: element.transaction_details[i].new_value
            });
          }
        }
      }
    }
    return dataToSend;
  }

  getPendingLoanData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };

    if (transactions[0].description_transaction) {
      dataToSend.pending_transaction.description_transaction = transactions[0].description_transaction;
    }

    if (transactions?.length) {
      for (const element of transactions) {
        if ((transactions[0].key === 'loantypes') || (element.key != 'loantypes')) {
          for (let i = 0; i < element.transaction_details.length; i++) {
            if (!(element.transaction_details[i].column_name === 'type') &&
              !(element.transaction_details[i].column_name === 'key') &&
              !(element.transaction_details[i].column_name === 'info_menu') &&
              !(element.transaction_details[i].column_name === 'details_menu') &&
              !(element.transaction_details[i].column_name === 'id') &&
              !(element.transaction_details[i].column_name === 'display_values')
            ) {
              if ((element.transaction_details[i].column_name === 'show_info') || (element.transaction_details[i].column_name === 'active')) {
                element.transaction_details[i].old_value = (element.transaction_details[i].old_value == 1 ? this.translate.instant('approval.yes') : this.translate.instant('approval.no'));
                element.transaction_details[i].new_value = (element.transaction_details[i].new_value == 1 ? this.translate.instant('approval.yes') : this.translate.instant('approval.no'));
              }
              if ((element.transaction_details[i].column_name === 'condition') ||
                (element.transaction_details[i].column_name === 'formula') ||
                ((element.transaction_details[i].column_name === 'description') && transactions[0].key === 'loan_simulator')
              ) {
                element.transaction_details[i].column_name = element?.feature_item?.config?.name ? element.feature_item.config.name : ''
              }

              if (element.transaction_details[i].column_name === 'values') {
                element.transaction_details[i].column_name = element?.feature_item?.config?.name ? element.feature_item.config.name : ''
                element.transaction_details[i].old_value = (((element.transaction_details[i].old_value).substring(1, (element.transaction_details[i].old_value)?.length -1)).split(',')).join(', ');
                element.transaction_details[i].new_value = (((element.transaction_details[i].new_value).substring(1, (element.transaction_details[i].new_value)?.length -1)).split(',')).join(', ');
              }

              dataToSend.pending_transaction.transaction_details.push({
                column_name: element.transaction_details[i].column_name,
                old_value: element.transaction_details[i].old_value,
                new_value: element.transaction_details[i].new_value,
                lang: element.transaction_details[i].lang ? element.transaction_details[i].lang : ''
              });
            }
          }
        }
      }
    }

    let loanTypesData = this.groupLoanOrAccountOrCardData(transactions , 'loantypes');
    if (loanTypesData?.old_values?.length || loanTypesData?.new_values.length) {
      dataToSend.pending_transaction.transaction_details.push({
        column_name: this.translate.instant('approval.loans'),
        old_value: loanTypesData?.old_values?.length ? loanTypesData?.old_values : '',
        new_value: loanTypesData?.new_values?.length ? loanTypesData?.new_values : '',
      });
    }
    return dataToSend;
  }



  getPendingAccountData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };

    if (transactions[0].description_transaction) {
      dataToSend.pending_transaction.description_transaction = transactions[0].description_transaction;
    }

    if (transactions?.length) {
      for (const element of transactions) {
        for (let i = 0; i < element.transaction_details.length; i++) {
          if (!(element.transaction_details[i].column_name === 'type') &&
            !(element.transaction_details[i].column_name === 'key') &&
            !(element.transaction_details[i].column_name === 'info_menu') &&
            !(element.transaction_details[i].column_name === 'details_menu') &&
            !(element.transaction_details[i].column_name === 'category_id') &&
            !(element.transaction_details[i].column_name === 'id')) {
            if (element.transaction_details[i].column_name === 'active') {
              element.transaction_details[i].old_value = (element.transaction_details[i].old_value == 1 ? this.translate.instant('approval.yes') : this.translate.instant('approval.no'));
              element.transaction_details[i].new_value = (element.transaction_details[i].new_value == 1 ? this.translate.instant('approval.yes') : this.translate.instant('approval.no'));
            }

            if ((element.transaction_details[i].column_name === 'transfer_in') ||
              (element.transaction_details[i].column_name === 'transfer_out') ||
              (element.transaction_details[i].column_name === 'checkbook_request'))
            {
              element.transaction_details[i].old_value = (element.transaction_details[i].old_value == 1 ? this.translate.instant('approval.on') : this.translate.instant('approval.off'));
              element.transaction_details[i].new_value = (element.transaction_details[i].new_value == 1 ? this.translate.instant('approval.on') : this.translate.instant('approval.off'));
            }

            dataToSend.pending_transaction.transaction_details.push({
              column_name: element.transaction_details[i].column_name,
              old_value: element.transaction_details[i].old_value,
              new_value: element.transaction_details[i].new_value,
              lang: element.transaction_details[i].lang ? element.transaction_details[i].lang : ''
            });
          }
        }
      }
    }

    let accountTypesData = this.groupLoanOrAccountOrCardData(transactions , 'accounttypes');
    if (accountTypesData?.old_values?.length || accountTypesData?.new_values.length) {
      dataToSend.pending_transaction.transaction_details.push({
        column_name: this.translate.instant('approval.accounts'),
        old_value: accountTypesData?.old_values?.length ? accountTypesData?.old_values : '',
        new_value: accountTypesData?.new_values?.length ? accountTypesData?.new_values : '',
      });
    }
    return dataToSend;
  }

  groupLoanOrAccountOrCardData(transactions, filterKey) {
    let arrayOfOldData = [];
    let arrayOfNewData = [];
    let array = transactions?.length ? _.filter(transactions, ['key', filterKey]) : [];

    array.forEach(item => {
      if ((item?.transaction_details?.length) &&
        ((_.find(item.transaction_details, {column_name: 'category_id'})?.old_value)))
      {
        arrayOfOldData.push(item?.feature_item?.name ? item.feature_item.name : '');
      }
      if ((item?.transaction_details?.length) &&
        ((_.find(item.transaction_details, {column_name: 'category_id'})?.new_value) || (_.find(item.transaction_details, {column_name: 'id'})?.new_value)))
      {
        arrayOfNewData.push(item?.feature_item?.name ? item.feature_item.name : '');
      }
    });

    return {
      old_values: arrayOfOldData?.join(', '),
      new_values: arrayOfNewData?.join(', ')
    };
  }

  getWebViewsData(transactions) {
    const item = transactions[0].feature_item;
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };

    if (item?.title) {
      let arrayOfObject = [
        {
          key: this.translate.instant('approval.title'),
          value: item.title
        }
      ];
      dataToSend.pending_transaction.description_transaction = this.transformJsonToString(arrayOfObject);
    }

    for (const element of transactions[0].transaction_details) {
      if (element.column_name === 'is_url_external') {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: this.translate.instant('webviews.open_link'),
          old_value: element.old_value == '0' || element.old_value == 0 ||  element.old_value == false ||  element.old_value == 'false' ? this.translate.instant('webviews.inapp') : this.translate.instant('webviews.external'),
          new_value: element.new_value == '0' || element.new_value == 0 ||  element.new_value == false ||  element.new_value == 'false' ? this.translate.instant('webviews.inapp') : this.translate.instant('webviews.external')
        });
      } else if (element.column_name === 'requires_login') {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: this.translate.instant('webviews.app_session'),
          old_value: element.old_value == '0' || element.old_value == 0 ||  element.old_value == false ||  element.old_value == 'false' ? this.translate.instant('general.no') : this.translate.instant('general.yes'),
          new_value: element.new_value == '0' || element.new_value == 0 ||  element.new_value == false ||  element.new_value == 'false' ? this.translate.instant('general.no') : this.translate.instant('general.yes')
        });
      } else if (element.column_name === 'show_back_button') {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: element.column_name,
          old_value: element.old_value == '0' || element.old_value == 0 ||  element.old_value == false ||  element.old_value == 'false' ? this.translate.instant('general.no') : this.translate.instant('general.yes'),
          new_value: element.new_value == '0' || element.new_value == 0 ||  element.new_value == false ||  element.new_value == 'false' ? this.translate.instant('general.no') : this.translate.instant('general.yes')
        });
      } else if (element.column_name === 'active') {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: this.translate.instant('approval.status'),
          old_value: element.old_value == '0' || element.old_value == 0 ||  element.old_value == false ||  element.old_value == 'false' ? this.translate.instant('approval.inactive') : this.translate.instant('approval.active'),
          new_value: element.new_value == '0' || element.new_value == 0 ||  element.new_value == false ||  element.new_value == 'false' ? this.translate.instant('approval.inactive') : this.translate.instant('approval.active'),
        });
      } else if (element.column_name === 'closing_url') {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: this.translate.instant('webviews.closingParameter'),
          old_value: element.old_value,
          new_value: element.new_value
        });
      } else {
        if (element.column_name != "app_id" && element.column_name != "key" && element.column_name != "type" && element.column_name != "is_clickable") {
          dataToSend.pending_transaction.transaction_details.push({
            column_name: element.column_name,
            old_value: element.old_value,
            new_value: element.new_value,
            lang: element.lang
          });
        }
      }


    }

    return dataToSend;
  }

  getApiTokenData(transactions) {
    const item = transactions[0].feature_item;
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };

    if (item?.name) {
      let arrayOfObject = [
        {
          key: this.translate.instant('approval.name'),
          value: item.name
        }
      ];
      dataToSend.pending_transaction.description_transaction = this.transformJsonToString(arrayOfObject);
    }

    for (const element of transactions[0].transaction_details) {
      if (element.column_name === 'active') {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: this.translate.instant('approval.status'),
          old_value: element.old_value == '0' || element.old_value == 0 || element.old_value == false || element.old_value == 'false' ? this.translate.instant('approval.revoked') : this.translate.instant('approval.active'),
          new_value: element.new_value == '0' || element.new_value == 0 || element.new_value == false || element.new_value == 'false' ? this.translate.instant('approval.revoked') : this.translate.instant('approval.active'),
        });
      } else if (element.column_name === 'role_id') {
        dataToSend.pending_transaction.transaction_details.push({
          column_name: this.translate.instant('approval.role'),
          old_value: element?.old_relation?.name ?? '-',
          new_value: element?.new_relation?.name ?? '-'
        });
      } else {
        if (element.column_name != "app_id") {
          dataToSend.pending_transaction.transaction_details.push({
            column_name: element.column_name,
            old_value: element.old_value,
            new_value: element.new_value,
            lang: element.lang
          });
        }
      }
    }
    return dataToSend;
  }

  setCategoryKeyName(transactions) {
    const serviceCategoryKeyName = this.checkService('setCategoryKeyName', [transactions]);
    if (serviceCategoryKeyName) {
      return serviceCategoryKeyName;
    }
    var categoryKey = '';
    if (this.checkIfKeyExist(transactions, 'branches,regions,subregions'))
      categoryKey = 'branches';
    else if (this.checkIfKeyExist(transactions, 'app_mode'))
      categoryKey = 'app_mode';
    else if (this.checkIfKeyExist(transactions, 'pages'))
      categoryKey = 'pages';
    else if (this.checkIfKeyExist(transactions, 'templates'))
      categoryKey = 'notification';
    else if (this.checkIfKeyExist(transactions, 'update_rules'))
      categoryKey = 'settings';
    else if (this.checkIfKeyExist(transactions, 'system_configuration'))
      categoryKey = 'system_configuration';
    else if (this.checkIfKeyExist(transactions, 'config,config.messages,config.account_config,config.customer_service_number,currencies,currencies.forex,config.base_currency_config,config.security_config,config.emails_to_send,mailsettings,sms_settings'))
      categoryKey = 'generalConf';
    else if (this.checkIfKeyExist(transactions, 'customer,customer.send,customer.role,customer.reset_initial_password,customer.profile,customer.block,customer.card,devices.active'))
      categoryKey = 'customer';
    else if (this.checkIfKeyExist(transactions, 'cms_users.role,cms_users.profile,cms_users,cms_ad_users'))
      categoryKey = 'cmsUsers';
    else if (this.checkIfKeyExist(transactions, 'roles,permissions'))
      categoryKey = 'roles';
    else if (this.checkIfKeyExist(transactions, 'notification.history'))
      categoryKey = 'notification';
    else if (this.checkIfKeyExist(transactions, 'transfer_history'))
      categoryKey = 'transfer_history';
    else if (this.checkIfKeyExist(transactions, 'faqs,faq_categories'))
      categoryKey = 'content_management';
    else if (this.checkIfKeyExist(transactions, 'feedback'))
      categoryKey = 'feedback';
    else if (this.checkIfKeyExist(transactions, 'location'))
      categoryKey = 'location';
    else if (this.checkIfKeyExist(transactions, 'owner.app,admin_idv_sendtojava'))
      categoryKey = 'owner.app';
    else if (this.checkIfKeyExist(transactions, 'customer.cards'))
      categoryKey = 'cards';
    else if (this.checkIfKeyExist(transactions, 'dashboard.general,dashboard.sva,dashboard.cards'))
      categoryKey = 'dashboard';
    else if (this.checkIfKeyExist(transactions, 'makerchecker'))
      categoryKey = 'makerchecker';
    else if (this.checkIfKeyExist(transactions, 'sva_accounts'))
      categoryKey = 'sva_accounts';
    else if (this.checkIfKeyExist(transactions, 'transfers.transfer_type_currency,transfers.transfer_type_otp'))
      categoryKey = 'transfers';
    else if (this.checkIfKeyExist(transactions, 'loantypes,loangroups,loan_simulator'))
      categoryKey = 'loans';
    else if (this.checkIfKeyExist(transactions, 'accounttypes,accountgroup'))
      categoryKey = 'accounts';
    else if (this.checkIfKeyExist(transactions, 'web_views'))
      categoryKey = 'web_views';
    else if (this.checkIfKeyExist(transactions, 'api_token'))
      categoryKey = 'api_token';
    else if (this.checkIfKeyExist(transactions, 'menu_extensions'))
      categoryKey = 'menu_extensions';
    else if (this.checkIfKeyExist(transactions, 'idv_operation_results'))
      categoryKey = 'idv';
    else
      categoryKey = transactions[0]?.key;
    return categoryKey;
  }

  checkIfKeyExist(transactions, keys) {
    let retVal = false;
    const keysArray = keys.split(',');
    const filteredTransactions = filter(transactions, (transaction) => {
      return filter(keysArray, (key) => {
        return key === transaction.key;
      }).length > 0;
    });
    if (filteredTransactions.length) {
      retVal = true;
    }
    return retVal;
  }

  getMenuExtensionsData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };

    if (transactions[0].description_transaction) {
      dataToSend.pending_transaction.description_transaction = transactions[0].description_transaction;
    }

    if (transactions && transactions.length) {
      for (const element of transactions) {
        for (let i = 0; i < element.transaction_details.length; i++) {
          if(element.transaction_details[i].column_name !== 'id') {
            if ((element.transaction_details[i].column_name === 'require_session') || element.transaction_details[i].column_name === 'open_in_new_tab') {
              dataToSend.pending_transaction.transaction_details.push({
                column_name: element.transaction_details[i].column_name,
                old_value: element.transaction_details[i].old_value == 1 ? this.translate.instant('approval.yes') : this.translate.instant('approval.no'),
                new_value: element.transaction_details[i].new_value == 1 ? this.translate.instant('approval.yes') : this.translate.instant('approval.no')
              });
            } else if (element.transaction_details[i].column_name === 'active') {
              dataToSend.pending_transaction.transaction_details.push({
                column_name: element.transaction_details[i].column_name,
                old_value: element.transaction_details[i].old_value == 1 ? this.translate.instant('approval.active') : this.translate.instant('approval.inactive'),
                new_value: element.transaction_details[i].new_value == 1 ? this.translate.instant('approval.active') : this.translate.instant('approval.inactive')
              });
            } else {
              dataToSend.pending_transaction.transaction_details.push({
                column_name: element.transaction_details[i].column_name,
                old_value: element.transaction_details[i].old_value,
                new_value: element.transaction_details[i].new_value,
                lang: element.transaction_details[i].lang,
              });
            }
          }
        }
        if (element?.extra?.roles?.old_value || element?.extra?.roles?.new_value) {
          dataToSend.pending_transaction.transaction_details.push({
            column_name: this.translate.instant('menuExtension.roles'),
            old_value: element.extra.roles?.old_value,
            new_value: element.extra.roles?.new_value
          });
        }
      }
    }
    return dataToSend;
  }

  getPendingCardControl(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };

    if (transactions && transactions.length) {
      for (const element of transactions) {
        for (let i = 0; i < element.transaction_details.length; i++) {
          if((element.transaction_details[i].column_name !== 'user_id') && (element.transaction_details[i].column_name !== 'card_name') && (element.transaction_details[i].column_name !== 'card_id')) {
            if ((element.transaction_details[i].column_name === 'international_active') || (element.transaction_details[i].column_name === 'domestic_active') || (element.transaction_details[i].column_name === 'atm_limit_active') || (element.transaction_details[i].column_name === 'pos_limit_active')) {
              let column_name;
              switch (element?.key) {
                case 'cardcontrol.ecommerce':
                  column_name = element.transaction_details[i].column_name === 'domestic_active' ? this.translate.instant('approval.localECommerce') : (element.transaction_details[i].column_name === 'international_active' ? this.translate.instant('approval.internationalECommerce') : '');
                  break;
                case 'cardcontrol.atm':
                  column_name = element.transaction_details[i].column_name === 'international_active' ? this.translate.instant('approval.internationalATMWithdrawal') : element.transaction_details[i].column_name === 'atm_limit_active' ? this.translate.instant('approval.atmLimitStatus') : '';
                  break;
                case 'cardcontrol.pos':
                  column_name = element.transaction_details[i].column_name === 'international_active' ? this.translate.instant('approval.internationalPOSTransactions') : element.transaction_details[i].column_name === 'pos_limit_active' ? this.translate.instant('approval.posLimitStatus') : '';
                  break;
                default:
                  column_name = '';
                  break;
              }
              dataToSend.pending_transaction.transaction_details.push({
                column_name: column_name,
                old_value: element.transaction_details[i].old_value == 1 ? this.translate.instant('approval.active') : this.translate.instant('approval.inactive'),
                new_value: element.transaction_details[i].new_value == 1 ? this.translate.instant('approval.active') : this.translate.instant('approval.inactive'),
                lang: element.transaction_details[i].lang,
              });
            } else {
              if ((element.transaction_details[i].column_name === 'atm_limit') || (element.transaction_details[i].column_name === 'pos_limit')) {
                element.transaction_details[i].column_name = (element.transaction_details[i].column_name === 'atm_limit' ? this.translate.instant('approval.atmLimit') : this.translate.instant('approval.posLimit'));
              }
              dataToSend.pending_transaction.transaction_details.push({
                column_name: element.transaction_details[i].column_name,
                old_value: element.transaction_details[i].old_value,
                new_value: element.transaction_details[i].new_value,
                lang: element.transaction_details[i].lang,
              });
            }
          }
        }
      }
    }
    if (transactions[0]?.extra) {
      dataToSend.pending_transaction.description_transaction = this.getCardInfoFromTransactionsExtra(transactions[0].extra);
    }
    return dataToSend;
  }

  getCardInfoFromTransactionsExtra(transactions) {
    let arrayOfObject = [
      {
        key: this.translate.instant('approval.cardName'),
        value: transactions?.card_name
      },
      {
        key: this.translate.instant('approval.maskedPan'),
        value: (transactions?.masked_pan) ? ("•••• " + (transactions?.masked_pan)?.slice(-4)) : ''
      },
      {
        key: this.translate.instant('approval.fullname'),
        value: transactions?.user_full_name ? transactions.user_full_name : (transactions?.username ? transactions.username : '')
      }
    ];
    return this.transformJsonToString(arrayOfObject);
  }

  getPendingCardGroupstData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };

    if (transactions?.length) {
      for (const element of transactions) {
        for (let i = 0; i < element.transaction_details.length; i++) {
          if (!(element.transaction_details[i].column_name === 'type') &&
            !(element.transaction_details[i].column_name === 'info_menu') &&
            !(element.transaction_details[i].column_name === 'details_menu') &&
            !(element.transaction_details[i].column_name === 'transactions') &&
            !(element.transaction_details[i].column_name === 'category_id') &&
            !(element.transaction_details[i].column_name === 'id')) {

            if (element.transaction_details[i].column_name == 'key') {
              element.transaction_details[i].column_name = this.translate.instant('approval.type');
            }

            dataToSend.pending_transaction.transaction_details.push({
              column_name: element.transaction_details[i].column_name,
              old_value: element.transaction_details[i].old_value,
              new_value: element.transaction_details[i].new_value,
              lang: element.transaction_details[i].lang ? element.transaction_details[i].lang : ''
            });
          }
        }
      }
    }

    let cardTypesData = this.groupLoanOrAccountOrCardData(transactions , 'cardtypes');
    if (cardTypesData?.old_values?.length || cardTypesData?.new_values.length) {
      dataToSend.pending_transaction.transaction_details.push({
        column_name: this.translate.instant('approval.cardType'),
        old_value: cardTypesData?.old_values?.length ? cardTypesData?.old_values : '',
        new_value: cardTypesData?.new_values?.length ? cardTypesData?.new_values : '',
      });
    }
    return dataToSend;
  }

  getPendingCardTypesData(transactions) {
    const dataToSend = {
      pending_transaction: {
        description_transaction: '',
        transaction_details: []
      }
    };

    if (transactions?.length) {
      for (const element of transactions) {
        for (let i = 0; i < element.transaction_details.length; i++) {
          if (!(element.transaction_details[i].column_name === 'id') &&
          !(element.transaction_details[i].column_name === 'type')) {

            if (element.transaction_details[i].column_name == 'image_url') {
              element.transaction_details[i].column_name = this.translate.instant('approval.image');
            }

            if (element.transaction_details[i].column_name == 'phone') {
              element.transaction_details[i].column_name = this.translate.instant('approval.serviceNumber');
            }

            if (element.transaction_details[i].column_name == 'active') {
              element.transaction_details[i].column_name = this.translate.instant('approval.cardStatus');
              element.transaction_details[i].old_value = (element.transaction_details[i].old_value == 1 ? this.translate.instant('approval.active') : this.translate.instant('approval.inactive'));
              element.transaction_details[i].new_value = (element.transaction_details[i].new_value == 1 ? this.translate.instant('approval.active') : this.translate.instant('approval.inactive'));
            }

            dataToSend.pending_transaction.transaction_details.push({
              column_name: element.transaction_details[i].column_name,
              old_value: element.transaction_details[i].old_value,
              new_value: element.transaction_details[i].new_value,
              lang: element.transaction_details[i].lang ? element.transaction_details[i].lang : ''
            });
          }
        }
      }
    }
    return dataToSend;
  }
}
