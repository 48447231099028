import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  constructor() { }

  getTranslationData(data: any) {
    let translationData = JSON.parse(JSON.stringify(data, (key, value) => {
      let retVal = value;
      if (key === 'translations') {
        retVal = {};
        for (let i = 0; i < value.length; i++) {
          retVal[value[i].lang] = value[i];
        }
      }
      return retVal;
    }));

    return translationData;
  }
}
