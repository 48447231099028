import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';

@Pipe({
  name: 'replaceSeparatorAndCapitalize'
})
export class ReplaceSeparatorAndCapitalizePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return value ? _.startCase((value).replaceAll('_', ' ').toLowerCase()) : value;
  }

}
