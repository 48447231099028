import {Component, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {UntypedFormGroup, FormGroupDirective, AbstractControl, Validators} from '@angular/forms';
import {TinymceConfigService, TokenStorage} from "foo-framework";
import { MatDialog } from '@angular/material/dialog';
import { TranslationService } from '../services/translation.service';

@Component({
  selector: 'framework-translation-input',
  templateUrl: './translation-input.component.html',
  styleUrls: ['./translation-input.component.scss']
})
export class TranslationInputComponent implements OnInit {

  selector: string;
  languages: Array<any>;
  languagesNoCurrent: Array<any>;

  // only required is used in cases where we have nested form groups and FormBuilder is used instead of RxFormBuilder(eg:Vouchers)
  @Input() requiredOnly;
  @Input() selectorInput;
  @Input() labelInput;
  @Input() labelInputTooltip;
  @Input() labelNoTranslation;
  @Input() valueInput;
  @Input() uploadPath;
  @Input() fileType;
  @Input() formGroupToAccess;
  @Input() placeholderInput;
  @Input() fieldRequired;

  @Input() selectDataToLoop;
  @Input() selectMultiple;
  @Input() selectDisplay;
  @Input() selectValue;
  @Input() maxlength;
  @Input() minlength;

  @Input() pattern;
  @Input() readonly: boolean = false;

  curLang: string;
  static counter = 0;
  currentComponentCount: number;
  editorConfigs: any;
  form: UntypedFormGroup;
  hasOneLanguage = false;
  insidePopup: boolean = false;

  constructor(private rootFormGroup: FormGroupDirective,
              private elRef: ElementRef,
              private tokenStorage: TokenStorage,
              protected tinymceConfigService: TinymceConfigService,
              private dialog: MatDialog,
              private translationService: TranslationService,
              private renderer: Renderer2
  ) {
    this.editorConfigs = this.tinymceConfigService.getTinymceEditorConfig();
  }

  ngOnInit(): void {
    this.insidePopup = this.dialog.openDialogs.length ? true : false;
    this.currentComponentCount = Number(TranslationInputComponent.counter);
    TranslationInputComponent.counter++;

    if(!this.formGroupToAccess){
      this.form =  this.rootFormGroup.control;
    }else{
      this.form = this.formGroupToAccess;
    }

    this.curLang = this.tokenStorage.getCurrentLanguage();
    this.languages = this.translationService.getTranslationData(this.tokenStorage.getLanguages());
    const lang = this.curLang;
    this.languagesNoCurrent = this.languages.filter((obj) => {
      return obj['code'] !== lang;
    });

    this.selector = (this.selectorInput === '') ? null : this.selectorInput;

    if(this.languagesNoCurrent.length === 0) {
      this.hasOneLanguage = true;
    }

  }

  hideAllOtherModals() {
    this.renderer.removeStyle(document.body, 'overflow');
    let currentModal = this.elRef.nativeElement.querySelector('.modal');
    const modals = document.getElementsByClassName('modal');

    for (let i = 0; i < modals.length; i++) {
      const modal = modals[i];
      if (modal instanceof HTMLElement && currentModal != modal && modal.classList.contains('show')) {
        let btn: HTMLElement = modal.getElementsByClassName('foo-btn-outline-secondary')[0] as HTMLElement;
        if (btn) {
          btn.click();
        }
      }else{
        const backdrops = document.querySelectorAll('.modal-backdrop');
        backdrops.forEach((backdrop: HTMLElement) => {
          if (backdrop) {
            backdrop.remove();
          }
        });
      }
    }
  }
  copyTranslation() {
    for (const lang of this.languagesNoCurrent) {
      this.form.get('translations').get(lang["code"]).get(this.valueInput).setValue(this.form.get('translations').get(this.curLang).get(this.valueInput).value);
      this.form.get('translations').markAsDirty();
    }
  }
  isRequiredField(curLang:string,valueInput:string) {
    let form_field = this.form.get('translations').get(curLang).get(valueInput)
    if (!form_field.validator) {
      return false;
    }
    return (form_field.hasValidator(Validators.required));
  }
}
