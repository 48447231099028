import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DomainService {

  constructor() { }

  get prefix(): string {
    if (window['prefix']) {
      return window['prefix'];
    } else {
      return '';
    }
  }

  getPrefixedURL(url): string {
    if (url?.length && url[0] === '/') {
      return this.prefix ?  `/${this.prefix}${url}` : `${url}`;
    } else {
      return this.prefix ?  `/${this.prefix}/${url}` : `/${url}`;
    }
  }
}
