import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

export enum formats {
  date = 'date',
  datetime = 'datetime'
}

@Pipe({
  name: 'date',
})
export class CustomDatePipe extends DatePipe implements PipeTransform {

  transform(
    value: any,
    format?: string
  ): any {
    if (value) {
      const adjustedValue = this.convertToMilliseconds(value);
      const dateFormat = format ? format : this.getFormattedString(this.detectDateTimeType(value));
      return super.transform(adjustedValue, dateFormat);
    }
    return null;
  }

  convertToMilliseconds(value: any): number {
    if (typeof value === 'number') {
      // Check if the value is in seconds (10 digits) or milliseconds (13 digits)
      if (value.toString().length === 10) {
        return value * 1000; // Convert seconds to milliseconds
      } else if (value.toString().length === 13) {
        return value;
      }
    }
    return value;
  }

  detectDateTimeType(value) {
    let parsedDate;

    if (typeof value === 'number') {
      if (value.toString().length === 10) {
        parsedDate = moment.unix(value);
      } else if (value.toString().length === 13) {
        parsedDate = moment(value);
      }
    } else {
      parsedDate = moment(value);
    }

    if (parsedDate.format('HH:mm:ss') === '00:00:00') {
      return formats.date;
    } else {
      return formats.datetime;
    }
  }

  getFormattedString(value) {
    switch (value) {
      case formats.date:
        return 'd MMM yyyy';
      default:
        return 'd MMM yyyy | HH:mm';
    }
  }
}