import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/**
 *  This fulter is used to bypass security and treat URL as trusted
 */

@Pipe({
    name: 'trustedUrl'
})
export class TrustedUrl implements PipeTransform  {

   constructor(private _sanitizer: DomSanitizer){}  

    transform(url: string) {
        return this._sanitizer.bypassSecurityTrustResourceUrl(url); 
    } 
}