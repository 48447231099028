<ng-container *ngIf="sortingOptions?.length">
  <div ngbDropdown #sortingOptionsDropdown container="body"
       dropdownClass="sorting-dropdown"
       autoClose="true"  display="dynamic">
    <button type="button" class="foo-btn-outline-secondary sorting-button" [disabled]="shimmer" ngbDropdownToggle>
      <img src="/assets/img/sorting-icon.svg" alt="sorting">
      <span class="pl-9">{{'general.sort' | translate}}</span>
    </button>
    <div ngbDropdownMenu>
      <ng-container *ngFor="let option of sortingOptions">
        <button ngbDropdownItem class="check-able-dropdown-item" [class.selected]="isOptionSelected(option)" (click)="chooseOption(option)">
          <ng-container *ngIf="option.label">{{option.label | translate}}</ng-container>
          <ng-container>{{predefinedLabels[option.type] ? (predefinedLabels[option.type][option.sort_direction] | translate) : ''}}</ng-container>
          <span *ngIf="isOptionSelected(option)" class="foo-icon foo-check-third check-img"></span>
        </button>
      </ng-container>
    </div>
  </div>
</ng-container>
