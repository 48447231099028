<!-- name of transaction -->
<div class="row w-100"
    *ngIf="transactionDetails && transactionDetails[item[arrayKey]] && transactionDetails[item[arrayKey]]?.description_transaction && !dataShimmer">
    <div class="col-12" [innerHTML]="transactionDetails[item[arrayKey]]?.description_transaction">
    </div>
</div>

<!-- for transactionDetails -->
<ng-container
    *ngIf="transactionDetails && transactionDetails[item[arrayKey]] && ((responsiveDesignMobileBreakPoint && !checkAction(item, 'add')) || (!responsiveDesignMobileBreakPoint))">
    <div class="row w-100" [ngClass]="{'shimmer-animate' : detailsShimmer[item[arrayKey]]}">
        <div class="col-3 col-sm-3 col-md-3 col-lg-3 border-right pb-2"
            [ngClass]="[responsiveDesignMobileBreakPoint ? 'col-6 col-sm-3 col-md-3 col-lg-3 pb-2' : '', item && item.extra && item.extra.custom_column ? 'border-right' : '']">
            <span
                [ngClass]="{'shimmer-even' : detailsShimmer[item[arrayKey]] || dataShimmer, 'shimmer-i-block': detailsShimmer[item[arrayKey]] || dataShimmer}"></span>
        </div>
        <div *ngIf="!checkAction(item, 'add')" class="col-3 col-sm-3 col-md-3 col-lg-3 border-right pb-2"
            [ngClass]="[responsiveDesignMobileBreakPoint ? 'col-6 col-sm-3 col-md-3 col-lg-3 pb-2' : '', item && item.extra && item.extra.custom_column ? 'border-right' : '']">
            <span class="title-header"
                [ngClass]="{'shimmer-even' : detailsShimmer[item[arrayKey]] || dataShimmer, 'shimmer-i-block': detailsShimmer[item[arrayKey]] || dataShimmer}">{{'approval.oldValues'
                | translate}}</span>
        </div>
        <div class="col-3 col-sm-3 col-md-3 col-lg-3 pb-2" *ngIf="!responsiveDesignMobileBreakPoint"
            [class.border-right]="item && item.extra && item.extra.custom_column">
            <span class="title-header"
                [ngClass]="{'shimmer-even' : detailsShimmer[item[arrayKey]] || dataShimmer, 'shimmer-i-block': detailsShimmer[item[arrayKey]] || dataShimmer}">{{
                !checkAction(item, 'add') ?
                ('approval.newValues'|
                translate) : ('approval.values' | translate)}}</span>
        </div>
        <div *ngIf="item && item.extra && item.extra.custom_column && !responsiveDesignMobileBreakPoint"
            class="col-3 col-sm-3 col-md-3 col-lg-3 pb-2 ">
            <div class="row">
                <div class="col-12">
                    <span class="title-header"
                        [ngClass]="{'shimmer-even' : detailsShimmer[item[arrayKey]] || dataShimmer, 'shimmer-i-block': detailsShimmer[item[arrayKey]] || dataShimmer}">{{"approval.reason"
                        | translate}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12 pt-11">
                    <strong class="title-body-new"
                        [ngClass]="{'shimmer-even' : detailsShimmer[item[arrayKey]] || dataShimmer, 'shimmer-i-block': detailsShimmer[item[arrayKey]] || dataShimmer}">{{item.extra.custom_column.value}}</strong>
                </div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="transactionDetails && transactionDetails[item[arrayKey]]">
        <div class="row w-100 shimmer-row"
            [ngClass]="{'shimmer-animate' : detailsShimmer[item[arrayKey]], 'last-child': last}"
            *ngFor="let detail of transactionDetails[item[arrayKey]].transaction_details; let last = last">
            <div class="col-3 col-sm-3 col-md-3 col-lg-3 border-right pb-1"
                [ngClass]="[detail?.colmn_name_class ?? '',responsiveDesignMobileBreakPoint ? 'col-6 col-sm-3 col-md-3 col-lg-3 pb-2' : '']">
                <span
                    [ngClass]="{'shimmer-even' : detailsShimmer[item[arrayKey]] || dataShimmer, 'shimmer-i-block': detailsShimmer[item[arrayKey]] || dataShimmer}"
                    class="title-header">{{getColumnNameForTransaction(detail.column_name)}}
                    {{detail.lang}}</span>
            </div>
            <ng-container
                *ngIf="!columnIsImage(detail.column_name) && detail.column_name !== 'documents' && detail.column_name !== 'file' && !detail.hasOwnProperty('displayAs'); else other_content">
                <div class="col-3 col-sm-3 col-md-3 col-lg-3 pb-1" *ngIf="!checkAction(item, 'add')"
                    [ngClass]="[detail?.colmn_name_class ?? '',detail?.old_value_class ?? '' , textTransformInitialCheck(detail.column_name) ? 'text-transform-initial' : '',responsiveDesignMobileBreakPoint ? 'col-6 col-sm-3 col-md-3 col-lg-3 pb-2' : 'border-right']">
                    <strong
                        [ngClass]="{'shimmer-even' : detailsShimmer[item[arrayKey]] || dataShimmer, 'shimmer-i-block': detailsShimmer[item[arrayKey]] || dataShimmer, 'title-body-old-new' : !checkAction(item, 'add')}">
                        {{getDisplayValue(detail.old_value, detail.column_name)}}</strong>
                </div>
                <div class="col-3 col-sm-3 col-md-3 col-lg-3 pb-1" *ngIf="!responsiveDesignMobileBreakPoint"
                    [ngClass]="[detail?.colmn_name_class ?? '',detail?.new_value_class ?? '',textTransformInitialCheck(detail.column_name) ? 'text-transform-initial' : '']"
                    [class.border-right]="item && item.extra && item.extra.custom_column">
                    <strong
                        [ngClass]="{'shimmer-even' : detailsShimmer[item[arrayKey]] || dataShimmer, 'shimmer-i-block': detailsShimmer[item[arrayKey]] || dataShimmer, 'title-body-new' : checkAction(item, 'add'), 'title-body-old-new' : !checkAction(item, 'add'), 'foo-text-primary': comparison(detail.new_value, detail.old_value, item)}">
                        {{getDisplayValue(detail.new_value, detail.column_name)}}</strong>
                </div>
            </ng-container>

            <ng-template #other_content>
                <div class="col-3 col-sm-3 col-md-3 col-lg-3 pb-1 "
                    [ngClass]="[detail?.colmn_name_class ?? '',responsiveDesignMobileBreakPoint ? 'col-6 col-sm-3 col-md-3 col-lg-3 pb-2' : 'border-right']"
                    *ngIf="!checkAction(item, 'add')" [ngClass]="detail?.old_value_class">
                    <ng-container *ngIf="columnIsImage(detail.column_name)">
                        <ng-container *ngIf="detail.old_value; else EmptyValue">
                            <div class="width-50px">
                                <a [href]="detail.old_value" target="_blank">
                                    <div class="img-value"
                                        [ngClass]="{'shimmer-item' : detailsShimmer[item[arrayKey]] || dataShimmer, 'shimmer-i-block': detailsShimmer[item[arrayKey]] || dataShimmer}"
                                        [style]="(!detailsShimmer[item[arrayKey]] && !dataShimmer) && detail.old_value ? 'background-image: url('+ detail.old_value +')' : ''">
                                    </div>
                                </a>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="detail.column_name === 'documents'">
                        <ng-container *ngIf="detail.old_value?.length; else EmptyValue">
                            <ng-container *ngFor="let document of detail.old_value">
                                <a class="doc-link" [href]="document.link" target="_blank">
                                    <strong
                                        [ngClass]="{'shimmer-item' : detailsShimmer[item[arrayKey]] || dataShimmer, 'shimmer-i-block': detailsShimmer[item[arrayKey]] || dataShimmer,'title-body-old-new' : !checkAction(item, 'add')}">
                                        {{document.name}}</strong>
                                </a>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="detail.column_name === 'file'">
                        <ng-container *ngIf="detail.old_value; else EmptyValue">
                            <a class="doc-link" [href]="detail.old_value" target="_blank">
                                <strong
                                    [ngClass]="{'shimmer-item' : detailsShimmer[item[arrayKey]] || dataShimmer, 'shimmer-i-block': detailsShimmer[item[arrayKey]] || dataShimmer,'title-body-old-new' : !checkAction(item, 'add')}">
                                    {{'approval.viewFile' | translate}}</strong>
                            </a>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="detail.displayAs && detail.displayAs === 'HTML'">
                        <display-as-html [data]="detail.old_value" class="text-transform-initial"></display-as-html>
                    </ng-container>
                    <ng-container *ngIf="detail.displayAs && detail.displayAs === 'InheritStyleHTML'">
                        <div [innerHTML]="detail.old_value | trustedHtml" class="text-transform-initial"></div>
                    </ng-container>
                    <ng-container *ngIf="detail.displayAs && detail.displayAs === 'JSON'">
                      <pre>{{ detail.old_value ? (getTypeOf(detail.old_value) == 'object' ? (detail.old_value | json) : detail.old_value) : '-'}}</pre>
                    </ng-container>
                    <ng-container *ngIf="detail.displayAs && detail.displayAs === 'dialog'">
                        <span class="title-body-old-new foo-text-primary cursor-pointer" (click)="openDialog(detail?.dialogConfigs?.component, detail?.dialogConfigs?.oldValueDialogConfigs)">{{ detail?.old_value }}</span>
                    </ng-container>
                </div>

                <div class="col-3 col-sm-3 col-md-3 col-lg-3 pb-1" *ngIf="!responsiveDesignMobileBreakPoint"
                    [ngClass]="[detail?.colmn_name_class ?? '',detail?.new_value_class ?? '']"
                    [class.border-right]="item && item.extra && item.extra.custom_column">
                    <ng-container *ngIf="columnIsImage(detail.column_name)">
                        <ng-container *ngIf="detail.new_value ; else EmptyValue">
                            <div class="width-50px">
                                <a [href]="detail.new_value" target="_blank">
                                    <div class="img-value"
                                        [ngClass]="{'shimmer-item' : detailsShimmer[item[arrayKey]] || dataShimmer, 'shimmer-i-block': detailsShimmer[item[arrayKey]] || dataShimmer}"
                                        [style]="(!detailsShimmer[item[arrayKey]] && !dataShimmer) && detail.new_value ? 'background-image: url('+ detail.new_value +')' : ''">
                                    </div>
                                </a>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="detail.column_name === 'documents'">
                        <ng-container *ngIf="detail.new_value?.length ; else EmptyValue">
                            <ng-container *ngFor="let document of detail.new_value;let doxIdx=index;">
                                <a class="doc-link" [href]="document.link" target="_blank">
                                    <strong
                                        [ngClass]="{'shimmer-item' : detailsShimmer[item[arrayKey]] || dataShimmer, 'shimmer-i-block': detailsShimmer[item[arrayKey]] || dataShimmer,'title-body-new' : checkAction(item, 'add'), 'title-body-old-new' : !checkAction(item, 'add'), 'foo-text-primary': comparison(document.name, detail.old_value[doxIdx]?.name, item)}">
                                        {{document.name}}</strong>
                                </a>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="detail.column_name === 'file'">
                        <ng-container *ngIf="detail.new_value; else EmptyValue">
                            <a class="doc-link" [href]="detail.new_value" target="_blank">
                                <strong
                                    [ngClass]="{'shimmer-item' : detailsShimmer[item[arrayKey]] || dataShimmer, 'shimmer-i-block': detailsShimmer[item[arrayKey]] || dataShimmer,'title-body-new' : checkAction(item, 'add'), 'title-body-old-new' : !checkAction(item, 'add'), 'foo-text-primary': comparison(detail.new_value, detail.old_value, item)}">
                                    {{'approval.viewFile' | translate}}</strong>
                            </a>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="detail.displayAs && detail.displayAs === 'HTML'">
                        <display-as-html [data]="detail.new_value" class="text-transform-initial"></display-as-html>
                    </ng-container>
                    <ng-container *ngIf="detail.displayAs && detail.displayAs === 'InheritStyleHTML'">
                        <div [innerHTML]="detail.new_value | trustedHtml" class="text-transform-initial"></div>
                    </ng-container>
                    <ng-container *ngIf="detail.displayAs && detail.displayAs === 'JSON'">
                      <pre class="foo-text-primary">{{ detail.new_value ? (getTypeOf(detail.new_value) == 'object' ? (detail.new_value | json) : detail.new_value) : '-'}}</pre>
                    </ng-container>
                    <ng-container *ngIf="detail.displayAs && detail.displayAs === 'dialog'">
                        <span class="title-body-old-new foo-text-primary cursor-pointer" (click)="openDialog(detail?.dialogConfigs?.component, detail?.dialogConfigs?.newValueDialogConfigs)">{{ detail?.new_value }}</span>
                    </ng-container>
                </div>
            </ng-template>
        </div>
    </ng-container>
</ng-container>

<!-- responsive for transactionDetails -->
<ng-container *ngIf="transactionDetails && transactionDetails[item[arrayKey]] && responsiveDesignMobileBreakPoint">
    <div [ngClass]="{'border-top-dashed' : responsiveDesignMobileBreakPoint && !checkAction(item, 'add')}">
        <div class="row w-100" [ngClass]="{'shimmer-animate' : detailsShimmer[item[arrayKey]]}">
            <div class="col-3 col-sm-3 col-md-3 col-lg-3 border-right pb-2"
                [ngClass]="[responsiveDesignMobileBreakPoint ? 'col-6 col-sm-3 col-md-3 col-lg-3 pb-2' : '']">
                <span
                    [ngClass]="{'shimmer-even' : detailsShimmer[item[arrayKey]] || dataShimmer, 'shimmer-i-block': detailsShimmer[item[arrayKey]] || dataShimmer}"></span>
            </div>
            <div class="col-3 col-sm-3 col-md-3 col-lg-3 pb-2"
                [ngClass]="responsiveDesignMobileBreakPoint ? 'col-6 col-sm-3 col-md-3 col-lg-3 pb-2' : ''"
                [ngClass]="[responsiveDesignMobileBreakPoint ? 'col-6 col-sm-3 col-md-3 col-lg-3 pb-2' : '']">
                <span class="title-header">{{ !checkAction(item, 'add') ?
                    ('approval.newValues'|
                    translate) : ('approval.values' | translate)}}</span>
            </div>
        </div>
        <ng-container *ngIf="transactionDetails && transactionDetails[item[arrayKey]]">
            <div class="row w-100 shimmer-row"
                [ngClass]="{'shimmer-animate' : detailsShimmer[item[arrayKey]], 'last-child': last}"
                *ngFor="let detail of transactionDetails[item[arrayKey]].transaction_details; let last = last">
                <div class="col-3 col-sm-3 col-md-3 col-lg-3 border-right pb-1"
                    [ngClass]="[detail?.colmn_name_class ?? '',responsiveDesignMobileBreakPoint ? 'col-6 col-sm-3 col-md-3 col-lg-3 pb-2' : '']">
                    <span
                        [ngClass]="{'shimmer-even' : detailsShimmer[item[arrayKey]] || dataShimmer, 'shimmer-i-block': detailsShimmer[item[arrayKey]] || dataShimmer}"
                        class="title-header">{{getColumnNameForTransaction(detail.column_name)}}
                        {{detail.lang}}</span>
                </div>
                <ng-container
                    *ngIf="!columnIsImage(detail.column_name) && detail.column_name !== 'documents' && detail.column_name !== 'file'  && !detail.hasOwnProperty('displayAs'); else other_content">
                    <div class="col-3 col-sm-3 col-md-3 col-lg-3 pb-1"
                        [ngClass]="[detail?.colmn_name_class ?? '',responsiveDesignMobileBreakPoint ? 'col-6 col-sm-3 col-md-3 col-lg-3 pb-2' : '', textTransformInitialCheck(detail.column_name) ? 'text-transform-initial' : '']">
                        <strong
                            [ngClass]="{'shimmer-even' : detailsShimmer[item[arrayKey]] || dataShimmer, 'shimmer-i-block': detailsShimmer[item[arrayKey]] || dataShimmer, 'title-body-new' : checkAction(item, 'add'), 'title-body-old-new' : !checkAction(item, 'add'), 'foo-text-primary': comparison(detail.new_value, detail.old_value, item)}">
                            {{getDisplayValue(detail.new_value, detail.column_name)}} </strong>
                    </div>
                </ng-container>
                <ng-template #other_content>
                    <div class="col-3 col-sm-3 col-md-3 col-lg-3 pb-1"
                        [ngClass]="[detail?.colmn_name_class ?? '',responsiveDesignMobileBreakPoint ? 'col-6 col-sm-3 col-md-3 col-lg-3 pb-2' : '']">
                        <ng-container *ngIf="columnIsImage(detail.column_name)">
                            <ng-container *ngIf="detail.new_value ; else EmptyValue">
                                <div class="width-50px">
                                    <a [href]="detail.new_value" target="_blank">
                                        <div class="img-value"
                                            [ngClass]="{'shimmer-item' : detailsShimmer[item[arrayKey]] || dataShimmer, 'shimmer-i-block': detailsShimmer[item[arrayKey]] || dataShimmer}"
                                            [style]="(!detailsShimmer[item[arrayKey]] && !dataShimmer) && detail.new_value ? 'background-image: url('+ detail.new_value +')' : ''">
                                        </div>
                                    </a>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="detail.column_name === 'documents'">
                            <ng-container *ngIf="detail.new_value?.length ; else EmptyValue">
                                <ng-container *ngFor="let document of detail.new_value;let docIdx=index;">
                                    <a class="doc-link" [href]="document.link" target="_blank">
                                        <strong
                                            [ngClass]="{'shimmer-item' : detailsShimmer[item[arrayKey]] || dataShimmer, 'shimmer-i-block': detailsShimmer[item[arrayKey]] || dataShimmer,'title-body-new' : checkAction(item, 'add'), 'title-body-old-new' : !checkAction(item, 'add'), 'foo-text-primary': comparison(document.name, detail.old_value[docIdx]?.name, item)}">
                                            {{document.name}}</strong>
                                    </a>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="detail.column_name === 'file'">
                            <ng-container *ngIf="detail.new_value; else EmptyValue">
                                <a class="doc-link" [href]="detail.new_value" target="_blank">
                                    <strong
                                        [ngClass]="{'shimmer-item' : detailsShimmer[item[arrayKey]] || dataShimmer, 'shimmer-i-block': detailsShimmer[item[arrayKey]] || dataShimmer,'title-body-new' : checkAction(item, 'add'), 'title-body-old-new' : !checkAction(item, 'add'), 'foo-text-primary': comparison(detail.new_value, detail.old_value, item)}">
                                        {{'approval.viewFile' | translate}}</strong>
                                </a>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngIf="detail.displayAs && detail.displayAs === 'HTML'">
                            <display-as-html [data]="detail.new_value" class="text-transform-initial"></display-as-html>
                        </ng-container>
                        <ng-container *ngIf="detail.displayAs && detail.displayAs === 'InheritStyleHTML'">
                            <div [innerHTML]="detail.new_value | trustedHtml" class="text-transform-initial"></div>
                        </ng-container>
                        <ng-container *ngIf="detail.displayAs && detail.displayAs === 'JSON'">
                          <pre>{{ detail.old_value ? (getTypeOf(detail.old_value) == 'object' ? (detail.old_value | json) : detail.old_value) : '-'}}</pre>
                        </ng-container>
                        <ng-container *ngIf="detail.displayAs && detail.displayAs === 'dialog'">
                            <span class="title-body-old-new foo-text-primary cursor-pointer" (click)="openDialog(detail?.dialogConfigs?.component, detail?.dialogConfigs?.newValueDialogConfigs)">{{ detail?.new_value }}</span>
                        </ng-container>
                    </div>
                </ng-template>
            </div>
        </ng-container>
    </div>
</ng-container>

<!-- responsive for transactionDetails for extra columns -->
<ng-container *ngIf="transactionDetails && transactionDetails[item[arrayKey]] && responsiveDesignMobileBreakPoint">
    <div [ngClass]="{'border-top-dashed' : responsiveDesignMobileBreakPoint && !checkAction(item, 'add')}">
        <div class="row w-100" [ngClass]="{'shimmer-animate' : detailsShimmer[item[arrayKey]]}"
            *ngIf="item && item.extra && item.extra.custom_column">
            <div class="col-3 col-sm-3 col-md-3 col-lg-3 pb-2"
                [class.border-right]="item && item.extra && item.extra.custom_column"
                [ngClass]="[responsiveDesignMobileBreakPoint ? 'col-6 col-sm-3 col-md-3 col-lg-3 pb-2' : '']">
                <span class="title-header">{{"approval.reason" | translate}}</span>
            </div>
            <div class="col-3 col-sm-3 col-md-3 col-lg-3 pb-2"
                [ngClass]="[responsiveDesignMobileBreakPoint ? 'col-6 col-sm-3 col-md-3 col-lg-3 pb-2' : '']">
                <strong class="title-body-new"
                    [ngClass]="{'shimmer-even' : detailsShimmer[item[arrayKey]] || dataShimmer, 'shimmer-i-block': detailsShimmer[item[arrayKey]] || dataShimmer}">{{item.extra.custom_column.value}}</strong>
            </div>
        </div>
    </div>
</ng-container>

<!-- for roleTransactionDetails -->
<ng-container *ngIf="roleTransactionDetails && roleTransactionDetails[item[arrayKey]] && !checkAction(item, 'delete')">
    <div
        [ngClass]="(responsiveDesignMobileBreakPoint || responsiveDesignTabletBreakPoint) ? 'verticalHorizontalScroll' : 'verticalScroll'">

        <div class="row w-max-content" [ngClass]="{'shimmer-animate' : detailsShimmer[item[arrayKey]]}">
            <div class="width-175 pb-2">
                <span
                    [ngClass]="{'shimmer-even' : detailsShimmer[item[arrayKey]] || dataShimmer, 'shimmer-i-block': detailsShimmer[item[arrayKey]] || dataShimmer}"></span>
            </div>
            <div class="width-485 pb-2 pl-15" *ngIf="!checkAction(item, 'add')">
                <span class="title-header">{{'approval.oldValues' | translate}}</span>
            </div>
            <div class="width-485 pb-2 pl-15"
                *ngIf="!(responsiveDesignMobileBreakPoint || responsiveDesignTabletBreakPoint)">
                <span class="title-header">{{ !checkAction(item, 'add') ?
                    ('approval.newValues'|
                    translate) : ('approval.values' | translate)}}</span>
            </div>
        </div>

        <ng-container
            *ngIf="(responsiveDesignMobileBreakPoint || responsiveDesignTabletBreakPoint) && !checkAction(item, 'add') || !(responsiveDesignMobileBreakPoint || responsiveDesignTabletBreakPoint)">
            <ng-container
                *ngFor="let detail of roleTransactionDetails[item[arrayKey]]?.roleObject?.transaction_details">
                <div class="row w-max-content shimmer-row"
                    *ngIf="detail.column_name != 'app_id' && detail.column_name != 'is_cms' && detail.column_name != 'level'"
                    [ngClass]="{'shimmer-animate' : detailsShimmer[item[arrayKey]]}">
                    <div class="width-175 border-right pb-1">
                        <span
                            [ngClass]="{'shimmer-even' : detailsShimmer[item[arrayKey]] || dataShimmer, 'shimmer-i-block': detailsShimmer[item[arrayKey]] || dataShimmer}"
                            class="title-header">{{getColumnNameForTransaction(detail.column_name)}}
                            {{detail.lang}}</span>
                    </div>
                    <div class="width-485 border-right pb-1 pl-15"
                        *ngIf="!checkAction(item, 'add') && !(responsiveDesignMobileBreakPoint || responsiveDesignTabletBreakPoint)">
                        <strong
                            [ngClass]="{'shimmer-even' : detailsShimmer[item[arrayKey]] || dataShimmer, 'shimmer-i-block': detailsShimmer[item[arrayKey]] || dataShimmer, 'title-body-old-new' : !checkAction(item, 'add')}">{{detail.old_value
                            ? detail.old_value : '-'}}</strong>
                    </div>
                    <div class="width-485 pb-1 pl-15">
                        <strong
                            [ngClass]="{'shimmer-even' : detailsShimmer[item[arrayKey]] || dataShimmer, 'shimmer-i-block': detailsShimmer[item[arrayKey]] || dataShimmer, 'title-body-new' : checkAction(item, 'add'), 'title-body-old-new' : !checkAction(item, 'add'), 'foo-text-primary': comparison(detail.new_value, detail.old_value, item)}">{{detail.new_value
                            ? detail.new_value : '-'}}</strong>
                    </div>
                </div>
            </ng-container>

            <div *ngFor="let featuresGroup of roleTransactionDetails[item[arrayKey]]?.permissionsList">

                <div *ngIf="!(detailsShimmer[item[arrayKey]] || dataShimmer)"
                    [ngClass]="(responsiveDesignMobileBreakPoint || responsiveDesignTabletBreakPoint) ? 'row width-660': 'row'">
                    <div class="width-175 border-right pb-20 pt-16">
                        <span class="role-main-row-title">
                            {{ featuresGroup.key }} </span>
                    </div>

                    <div class="width-485 pb-22"
                        [ngClass]="{'border-right' : !(responsiveDesignMobileBreakPoint || responsiveDesignTabletBreakPoint)}"
                        *ngIf="!checkAction(item, 'add')">

                        <div class="row">
                            <div class="col-9 border-top-dashed m-auto pb-16"></div>
                        </div>

                        <div class="row justify-content-center text-center">
                            <div class="col-2 role-main-row-title">
                                {{ "approval.view" | translate }}</div>
                            <div class="col-2 role-main-row-title">
                                {{ "approval.add" | translate }}</div>
                            <div class="col-2 role-main-row-title">
                                {{ "approval.edit" | translate }}</div>
                            <div class="col-2 role-main-row-title">
                                {{ "approval.delete" | translate }}</div>
                            <div class="col-2 role-main-row-title">
                                {{ "approval.approve" | translate }}</div>
                        </div>
                    </div>
                    <div class="width-485 pb-22"
                        *ngIf="!(responsiveDesignMobileBreakPoint || responsiveDesignTabletBreakPoint)">

                        <div class="row">
                            <div class="col-9 border-top-dashed m-auto pb-16"></div>
                        </div>

                        <div class="row justify-content-center text-center">
                            <div class="col-2 role-main-row-title">
                                {{ "approval.view" | translate }}</div>
                            <div class="col-2 role-main-row-title">
                                {{ "approval.add" | translate }}</div>
                            <div class="col-2 role-main-row-title">
                                {{ "approval.edit" | translate }}</div>
                            <div class="col-2 role-main-row-title">
                                {{ "approval.delete" | translate }}</div>
                            <div class="col-2 role-main-row-title">
                                {{ "approval.approve" | translate }}</div>
                        </div>
                    </div>
                </div>

                <ng-container *ngFor="let transaction of featuresGroup.value">
                    <div [ngClass]="(responsiveDesignMobileBreakPoint || responsiveDesignTabletBreakPoint) ? 'row width-660': 'row'"
                        *ngIf="!(detailsShimmer[item[arrayKey]] || dataShimmer)">
                        <div class="width-175 border-right pb-22">
                            <span class="role-child-row-title">{{ transaction.key }}</span>
                        </div>

                        <div class="width-485 pb-20 text-center"
                            [ngClass]="{'border-right' : !(responsiveDesignMobileBreakPoint || responsiveDesignTabletBreakPoint)}"
                            *ngIf="!checkAction(item, 'add')">
                            <div class="row justify-content-center">
                                <div class="col-2">
                                    <strong [ngClass]="{'title-body-old-new' : !checkAction(item, 'add')}">
                                        {{ !comparison(transaction.result?.can_read?.new_value,
                                        transaction.result?.can_read?.old_value) ?
                                        ((transaction.result?.can_read?.new_value ==
                                        (1 || '1')) && (transaction.result?.can_read?.old_value == (1 || '1'))) ?
                                        ("approval.yes" | translate) : '-' : transaction.result?.can_read?.old_value ===
                                        (0 ||
                                        '0') ? '-' : ("approval.yes" | translate) }}
                                    </strong>
                                </div>
                                <div class="col-2">
                                    <strong [ngClass]="{'title-body-old-new' : !checkAction(item, 'add')}">
                                        {{ !comparison(transaction.result?.can_create?.new_value,
                                        transaction.result?.can_create?.old_value) ?
                                        ((transaction.result?.can_create?.new_value
                                        == (1 || '1')) && (transaction.result?.can_create?.old_value == (1 || '1'))) ?
                                        ("approval.yes" | translate) : '-' : transaction.result?.can_create?.old_value
                                        === (0 ||
                                        '0') ? '-' : ("approval.yes" | translate) }}
                                    </strong>
                                </div>
                                <div class="col-2">
                                    <strong [ngClass]="{'title-body-old-new' : !checkAction(item, 'add')}">
                                        {{ !comparison(transaction.result?.can_update?.new_value,
                                        transaction.result?.can_update?.old_value) ?
                                        ((transaction.result?.can_update?.new_value
                                        == (1 || '1')) && (transaction.result?.can_update?.old_value == (1 || '1'))) ?
                                        ("approval.yes" | translate) : '-' : transaction.result?.can_update?.old_value
                                        === (0 ||
                                        '0') ? '-' : ("approval.yes" | translate) }}
                                    </strong>
                                </div>
                                <div class="col-2">
                                    <strong [ngClass]="{'title-body-old-new' : !checkAction(item, 'add')}">
                                        {{ !comparison(transaction.result?.can_delete?.new_value,
                                        transaction.result?.can_delete?.old_value) ?
                                        ((transaction.result?.can_delete?.new_value
                                        == (1 || '1')) && (transaction.result?.can_delete?.old_value == (1 || '1'))) ?
                                        ("approval.yes" | translate) : '-' : transaction.result?.can_delete?.old_value
                                        === (0 ||
                                        '0') ? '-' : ("approval.yes" | translate) }}
                                    </strong>
                                </div>
                                <div class="col-2">
                                    <strong [ngClass]="{'title-body-old-new' : !checkAction(item, 'add')}">
                                        {{ !comparison(transaction.result?.can_approve?.new_value,
                                        transaction.result?.can_approve?.old_value) ?
                                        ((transaction.result?.can_approve?.new_value == (1 || '1')) &&
                                        (transaction.result?.can_approve?.old_value == (1 || '1'))) ? ("approval.yes" |
                                        translate) : '-' : transaction.result?.can_approve?.old_value === (0 || '0') ?
                                        '-' :
                                        ("approval.yes" | translate) }}
                                    </strong>
                                </div>
                            </div>
                        </div>
                        <div class="width-485 pb-20 text-center"
                            *ngIf="!(responsiveDesignMobileBreakPoint || responsiveDesignTabletBreakPoint)">
                            <div class="row justify-content-center">
                                <div class="col-2">
                                    <strong
                                        [ngClass]="{'title-body-old-new' : !checkAction(item, 'add'), 'foo-text-primary': comparison(transaction.result?.can_read?.new_value, transaction.result?.can_read?.old_value, item), 'title-body-new' : checkAction(item, 'add')}">
                                        {{ !comparison(transaction.result?.can_read?.new_value,
                                        transaction.result?.can_read?.old_value) ?
                                        ((transaction.result?.can_read?.new_value ==
                                        (1 || '1')) && (transaction.result?.can_read?.old_value == (1 || '1'))) ?
                                        ("approval.yes" | translate) : '-' : transaction.result?.can_read?.new_value ===
                                        (0 ||
                                        '0') ? checkAction(item, 'add') ? '-' : ("approval.no"
                                        |
                                        translate) : ("approval.yes" | translate) }}
                                    </strong>
                                </div>
                                <div class="col-2">
                                    <strong
                                        [ngClass]="{'title-body-old-new' : !checkAction(item, 'add'), 'foo-text-primary': comparison(transaction.result?.can_create?.new_value, transaction.result?.can_create?.old_value, item), 'title-body-new' : checkAction(item, 'add')}">
                                        {{ !comparison(transaction.result?.can_create?.new_value,
                                        transaction.result?.can_create?.old_value) ?
                                        ((transaction.result?.can_create?.new_value
                                        == (1 || '1')) && (transaction.result?.can_create?.old_value == (1 || '1'))) ?
                                        ("approval.yes" | translate) : '-' : transaction.result?.can_create?.new_value
                                        === (0 ||
                                        '0') ? checkAction(item, 'add') ? '-' : ("approval.no"
                                        |
                                        translate) : ("approval.yes" | translate) }}
                                    </strong>
                                </div>
                                <div class="col-2">
                                    <strong
                                        [ngClass]="{'title-body-old-new' : !checkAction(item, 'add'), 'foo-text-primary': comparison(transaction.result?.can_update?.new_value, transaction.result?.can_update?.old_value, item), 'title-body-new' : checkAction(item, 'add')}">
                                        {{ !comparison(transaction.result?.can_update?.new_value,
                                        transaction.result?.can_update?.old_value) ?
                                        ((transaction.result?.can_update?.new_value
                                        == (1 || '1')) && (transaction.result?.can_update?.old_value == (1 || '1'))) ?
                                        ("approval.yes" | translate) : '-' : transaction.result?.can_update?.new_value
                                        === (0 ||
                                        '0') ? checkAction(item, 'add') ? '-' : ("approval.no"
                                        |
                                        translate) : ("approval.yes" | translate) }}
                                    </strong>
                                </div>
                                <div class="col-2">
                                    <strong
                                        [ngClass]="{'title-body-old-new' : !checkAction(item, 'add'), 'foo-text-primary': comparison(transaction.result?.can_delete?.new_value, transaction.result?.can_delete?.old_value, item), 'title-body-new' : checkAction(item, 'add')}">
                                        {{ !comparison(transaction.result?.can_delete?.new_value,
                                        transaction.result?.can_delete?.old_value) ?
                                        ((transaction.result?.can_delete?.new_value
                                        == (1 || '1')) && (transaction.result?.can_delete?.old_value == (1 || '1'))) ?
                                        ("approval.yes" | translate) : '-' : transaction.result?.can_delete?.new_value
                                        === (0 ||
                                        '0') ? checkAction(item, 'add') ? '-' : ("approval.no"
                                        |
                                        translate) : ("approval.yes" | translate) }}
                                    </strong>
                                </div>
                                <div class="col-2">
                                    <strong
                                        [ngClass]="{'title-body-old-new' : !checkAction(item, 'add'), 'foo-text-primary': comparison(transaction.result?.can_approve?.new_value, transaction.result?.can_approve?.old_value, item), 'title-body-new' : checkAction(item, 'add')}">
                                        {{ !comparison(transaction.result?.can_approve?.new_value,
                                        transaction.result?.can_approve?.old_value) ?
                                        ((transaction.result?.can_approve?.new_value == (1 || '1')) &&
                                        (transaction.result?.can_approve?.old_value == (1 || '1'))) ? ("approval.yes" |
                                        translate) : '-' : transaction.result?.can_approve?.new_value === (0 || '0') ?
                                        checkAction(item, 'add') ? '-' : ("approval.no" |
                                        translate) : ("approval.yes" | translate) }}
                                    </strong>
                                </div>
                            </div>
                        </div>

                    </div>
                </ng-container>
            </div>
        </ng-container>

        <!-- new responsive for role -->
        <div
            [ngClass]="{'border-top-dashed' : (responsiveDesignMobileBreakPoint || responsiveDesignTabletBreakPoint) && !checkAction(item, 'add')}">
            <ng-container *ngIf="(responsiveDesignMobileBreakPoint || responsiveDesignTabletBreakPoint)">

                <div class="row w-max-content" [ngClass]="{'shimmer-animate' : detailsShimmer[item[arrayKey]]}">
                    <div class="width-175 pb-2">
                        <span
                            [ngClass]="{'shimmer-even' : detailsShimmer[item[arrayKey]] || dataShimmer, 'shimmer-i-block': detailsShimmer[item[arrayKey]] || dataShimmer}"></span>
                    </div>
                    <div class="width-485 pb-2 pl-15">
                        <span class="title-header">{{ !checkAction(item, 'add') ?
                            ('approval.newValues'|
                            translate) : ('approval.values' | translate)}}</span>
                    </div>
                </div>

                <ng-container
                    *ngFor="let detail of roleTransactionDetails[item[arrayKey]]?.roleObject?.transaction_details">
                    <div class="row w-max-content shimmer-row"
                        *ngIf="detail.column_name != 'app_id' && detail.column_name != 'is_cms' && detail.column_name != 'level'"
                        [ngClass]="{'shimmer-animate' : detailsShimmer[item[arrayKey]]}">
                        <div class="width-175 border-right pb-1">
                            <span
                                [ngClass]="{'shimmer-even' : detailsShimmer[item[arrayKey]] || dataShimmer, 'shimmer-i-block': detailsShimmer[item[arrayKey]] || dataShimmer}"
                                class="title-header">{{getColumnNameForTransaction(detail.column_name)}}
                                {{detail.lang}}</span>
                        </div>
                        <div class="width-485 pb-1 pl-15">
                            <strong
                                [ngClass]="{'shimmer-even' : detailsShimmer[item[arrayKey]] || dataShimmer, 'shimmer-i-block': detailsShimmer[item[arrayKey]] || dataShimmer, 'title-body-new' : checkAction(item, 'add'), 'title-body-old-new' : !checkAction(item, 'add'), 'foo-text-primary': comparison(detail.new_value, detail.old_value, item)}">{{detail.new_value
                                ? detail.new_value : '-'}}</strong>
                        </div>
                    </div>
                </ng-container>

                <div *ngFor="let featuresGroup of roleTransactionDetails[item[arrayKey]]?.permissionsList">

                    <div [ngClass]="(responsiveDesignMobileBreakPoint || responsiveDesignTabletBreakPoint) ? 'row width-660': 'row'"
                        *ngIf="!(detailsShimmer[item[arrayKey]] || dataShimmer)">
                        <div class="width-175 border-right pb-20 pt-16">
                            <span class="role-main-row-title">
                                {{ featuresGroup.key }} </span>
                        </div>

                        <div class="width-485 pb-22">

                            <div class="row">
                                <div class="col-9 border-top-dashed m-auto pb-16"></div>
                            </div>

                            <div class="row justify-content-center text-center">
                                <div class="col-2 role-main-row-title">
                                    {{ "approval.view" | translate }}</div>
                                <div class="col-2 role-main-row-title">
                                    {{ "approval.add" | translate }}</div>
                                <div class="col-2 role-main-row-title">
                                    {{ "approval.edit" | translate }}</div>
                                <div class="col-2 role-main-row-title">
                                    {{ "approval.delete" | translate }}</div>
                                <div class="col-2 role-main-row-title">
                                    {{ "approval.approve" | translate }}</div>
                            </div>
                        </div>
                    </div>

                    <ng-container *ngFor="let transaction of featuresGroup.value">
                        <div [ngClass]="(responsiveDesignMobileBreakPoint || responsiveDesignTabletBreakPoint) ? 'row width-660': 'row'"
                            *ngIf="!(detailsShimmer[item[arrayKey]] || dataShimmer)">
                            <div class="width-175 border-right pb-22">
                                <span class="role-child-row-title">{{ transaction.key }}</span>
                            </div>

                            <div class="width-485 pb-20 text-center">
                                <div class="row justify-content-center">
                                    <div class="col-2">
                                        <strong
                                            [ngClass]="{'title-body-old-new' : !checkAction(item, 'add'), 'foo-text-primary': comparison(transaction.result?.can_read?.new_value, transaction.result?.can_read?.old_value, item), 'title-body-new' : checkAction(item, 'add')}">
                                            {{ !comparison(transaction.result?.can_read?.new_value,
                                            transaction.result?.can_read?.old_value) ?
                                            ((transaction.result?.can_read?.new_value ==
                                            (1 || '1')) && (transaction.result?.can_read?.old_value == (1 || '1'))) ?
                                            ("approval.yes" | translate) : '-' : transaction.result?.can_read?.new_value
                                            === (0 ||
                                            '0') ? checkAction(item, 'add') ? '-' : ("approval.no"
                                            |
                                            translate) : ("approval.yes" | translate) }}
                                        </strong>
                                    </div>
                                    <div class="col-2">
                                        <strong
                                            [ngClass]="{'title-body-old-new' : !checkAction(item, 'add'), 'foo-text-primary': comparison(transaction.result?.can_create?.new_value, transaction.result?.can_create?.old_value, item), 'title-body-new' : checkAction(item, 'add')}">
                                            {{ !comparison(transaction.result?.can_create?.new_value,
                                            transaction.result?.can_create?.old_value) ?
                                            ((transaction.result?.can_create?.new_value
                                            == (1 || '1')) && (transaction.result?.can_create?.old_value == (1 || '1')))
                                            ?
                                            ("approval.yes" | translate) : '-' :
                                            transaction.result?.can_create?.new_value === (0 ||
                                            '0') ? checkAction(item, 'add') ? '-' : ("approval.no"
                                            |
                                            translate) : ("approval.yes" | translate) }}
                                        </strong>
                                    </div>
                                    <div class="col-2">
                                        <strong
                                            [ngClass]="{'title-body-old-new' : !checkAction(item, 'add'), 'foo-text-primary': comparison(transaction.result?.can_update?.new_value, transaction.result?.can_update?.old_value, item), 'title-body-new' : checkAction(item, 'add')}">
                                            {{ !comparison(transaction.result?.can_update?.new_value,
                                            transaction.result?.can_update?.old_value) ?
                                            ((transaction.result?.can_update?.new_value
                                            == (1 || '1')) && (transaction.result?.can_update?.old_value == (1 || '1')))
                                            ?
                                            ("approval.yes" | translate) : '-' :
                                            transaction.result?.can_update?.new_value === (0 ||
                                            '0') ? checkAction(item, 'add') ? '-' : ("approval.no"
                                            |
                                            translate) : ("approval.yes" | translate) }}
                                        </strong>
                                    </div>
                                    <div class="col-2">
                                        <strong
                                            [ngClass]="{'title-body-old-new' : !checkAction(item, 'add'), 'foo-text-primary': comparison(transaction.result?.can_delete?.new_value, transaction.result?.can_delete?.old_value, item), 'title-body-new' : checkAction(item, 'add')}">
                                            {{ !comparison(transaction.result?.can_delete?.new_value,
                                            transaction.result?.can_delete?.old_value) ?
                                            ((transaction.result?.can_delete?.new_value
                                            == (1 || '1')) && (transaction.result?.can_delete?.old_value == (1 || '1')))
                                            ?
                                            ("approval.yes" | translate) : '-' :
                                            transaction.result?.can_delete?.new_value === (0 ||
                                            '0') ? checkAction(item, 'add') ? '-' : ("approval.no"
                                            |
                                            translate) : ("approval.yes" | translate) }}
                                        </strong>
                                    </div>
                                    <div class="col-2">
                                        <strong
                                            [ngClass]="{'title-body-old-new' : !checkAction(item, 'add'), 'foo-text-primary': comparison(transaction.result?.can_approve?.new_value, transaction.result?.can_approve?.old_value, item), 'title-body-new' : checkAction(item, 'add')}">
                                            {{ !comparison(transaction.result?.can_approve?.new_value,
                                            transaction.result?.can_approve?.old_value) ?
                                            ((transaction.result?.can_approve?.new_value == (1 || '1')) &&
                                            (transaction.result?.can_approve?.old_value == (1 || '1'))) ?
                                            ("approval.yes" |
                                            translate) : '-' : transaction.result?.can_approve?.new_value === (0 || '0')
                                            ?
                                            checkAction(item, 'add') ? '-' : ("approval.no" |
                                            translate) : ("approval.yes" | translate) }}
                                        </strong>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>

        <!-- end new responsive for role -->
    </div>

</ng-container>

<!-- rejected tab -->
<div class="row w-100"
    [ngClass]="{'shimmer-item' : detailsShimmer[item[arrayKey]], 'shimmer-i-block': detailsShimmer[item[arrayKey]]}"
    *ngIf="selectedStatus === 'REJECTED'">
    <div class="col-4 col-sm-3 col-md-3 col-lg-3">
        {{ "approval.rejectionReason" | translate }}
    </div>
    <div class="col-8 col-sm-9 col-md-9 col-lg-9">
        <strong>{{item.transaction_rejection_reason ? item.transaction_rejection_reason :
            '-'}}</strong>
    </div>
</div>

<!-- all -->
<div
    *ngIf="(item?.extra?.is_role && !checkAction(item, 'delete') && (!roleTransactionDetails || roleTransactionDetails.length === 0)) || (item.key === 'roles' && !checkAction(item, 'delete') && (!roleTransactionDetails || roleTransactionDetails.length === 0)) || ((item.key !== 'roles' || (item.key === 'roles' && checkAction(item, 'delete'))) && (!transactionDetails ||  !transactionDetails[item[arrayKey]] || !transactionDetails[item[arrayKey]].transaction_details || transactionDetails[item[arrayKey]].transaction_details.length === 0))">
    <framework-no-data></framework-no-data>
</div>

<!-- all -->
<ng-template #EmptyValue>
    <div class="col-3 col-sm-3 col-md-3 col-lg-3 pb-1 pl-0">
        <strong [ngClass]="{'shimmer-even' : dataShimmer, 'shimmer-i-block': dataShimmer}"
            class="title-body-old-new">-</strong>
    </div>
</ng-template>
