<button mat-button mat-dialog-close class="close-icon">
  <span class="foo-icon foo-close-dark"></span>
</button>
<div mat-dialog-title>
  <p class="title">
    <ng-container *ngIf="isObservable(title); else TitleTranslation">
      {{ title | async}}
    </ng-container>
    <ng-template #TitleTranslation>
      {{ title | translate}}
    </ng-template>
  </p>
</div>
<mat-dialog-content class="mt-10">
  <p class="info" *ngIf="text">
    <ng-container *ngIf="isObservable(text); else TextTranslation">
      {{ text | async}}
    </ng-container>
    <ng-template #TextTranslation>
      {{ text | translate}}
    </ng-template>
  </p>

  <ng-template #innerComponent></ng-template>

</mat-dialog-content>
<mat-dialog-actions class="pt-28" [ngClass]="{
  'vertical-buttons': buttonsDirection === 'vertical'
}">
  <ng-container *ngFor="let btn of btnList">
    <button (click)="onClick(btn)" class="{{btn.classNames || 'foo-btn-outline-secondary' }}">
      {{ btn?.title | translate}}
    </button>
  </ng-container>
</mat-dialog-actions>
