import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { EditStepsFormComponent } from './edit-steps-form/edit-steps-form.component';

@Component({
  selector: 'app-country-details',
  templateUrl: './country-details.component.html',
  styleUrls: ['./country-details.component.scss']
})
export class CountryDetailsComponent implements OnInit {

  @Input() filtersLoaded: any;
  @Input() countryName: any;
  @Input() countryFlows: any;
  @Input() form: any;
  @Input() permissions: any;
  @Input() selectedCountry: any;
  @Input() api_url_uploadResultIcon = '';
  @Input() api_url_updateinput = '';
  @Input() disableUpdateFlowStatus = false;
  @Input() disableUpdateInputStatus = false;
  @Input() updateOverlaysPermission = true;
  @Input() app_id: any;
  @Input() app_name: any;
  @Input() asSuperAdmin = false;

  @Output() updateFlowStatusEvent = new EventEmitter<any>();
  @Output() updateInputStatusEvent = new EventEmitter<any>();
  @Output() refreshFlowData = new EventEmitter<any>();
  @Output() updateOverlaysEvent = new EventEmitter<any>();

  @ViewChildren('MatExpansionPanel') panels: QueryList<MatExpansionPanel>;

  extensions = 'JPG, PNG';
  input_form;
  selected_flow_id;
  selected_flow;
  settings_title;

  constructor(
    public datepipe: DatePipe,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
  }

  capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  getInputName(name) {
    return name
      .toLowerCase()
      .replace(/_/g, " ")
      .split(" ")
      .map(this.capitalize)
      .join(" ");
  };

  updateFlowStatus(id, status, document_name) {
    this.panels.toArray().forEach(panel => {
      if (status === false && panel.expanded) {
        panel.toggle();
      }
    });

    let dataToEmit = {
      id: id,
      status: status,
      document_name: document_name
    }

    this.updateFlowStatusEvent.emit(dataToEmit);
  }

  updateInputStatus(flow_id, input_id, status, document_name, input_name) {
    let dataToEmit = {
      flow_id: flow_id,
      input_id: input_id,
      status: status ? 1 : 0,
      document_name: document_name,
      input_name: input_name
    };

    this.updateInputStatusEvent.emit(dataToEmit);
  }

  updateOverlays(flow) {
    this.updateOverlaysEvent.emit(flow);
  }

  editStep(input, flow) {
    this.input_form = input;
    this.selected_flow_id = flow.id;
    this.selected_flow = flow;
    this.settings_title = this.getInputName(input.input_alias);

    const dialogRef = this.dialog.open(EditStepsFormComponent, {
      width: '550px',
      panelClass: ['app-custom-dialog-container', 'display-scrollbar'],
      backdropClass: 'app-customer-backdrop',
      data: {
        mode: 'edit',
        input_form: this.input_form,
        selected_flow_id: this.selected_flow_id,
        selected_flow: this.selected_flow,
        settings_title: this.settings_title,
        selected_country: this.selectedCountry,
        api_url_uploadResultIcon: this.api_url_uploadResultIcon,
        api_url_updateinput: this.api_url_updateinput,
        app_id: this.app_id? this.app_id : null,
        app_name: this.app_name? this.app_name : null,
        country_name: this.countryName
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'submit') {
        this.refreshFlowData.emit();
      }
    });
  }
}
