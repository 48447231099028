import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class FooRouteReuseStrategy extends RouteReuseStrategy {
  private storedRoutes = new Map<string, DetachedRouteHandle>();

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
    // to add conditions in case we need some route to not re-construct related component
  }
  store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
    this.storedRoutes.set(route.routeConfig.path, detachedTree);
  }
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return !!route.routeConfig && !!this.storedRoutes.get(route.routeConfig.path);
  }
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle|null {
    return this.storedRoutes.get(route.routeConfig.path);
  }
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
     return future.routeConfig === curr.routeConfig;
  }
}
