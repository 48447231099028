import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
// import {FooFrameworkModule, MaterialModule, PipesModule} from 'foo-framework';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { SwiperModule } from 'swiper/angular';
import {ReactiveFormsModule} from '@angular/forms';
import { DmpTransactionDetailsService } from 'dmp-framework/shared-modules';

const components = [

];

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    CommonModule,
    // FooFrameworkModule,
    TranslateModule,
    SwiperModule,
    // MaterialModule,
    ReactiveFormsModule,
    // PipesModule

  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  exports: [
    ...components
  ],
})
export class FooDmpModule {
  static forRoot(): ModuleWithProviders<FooDmpModule> {
    return {
      ngModule: FooDmpModule,
      providers: [
        {
          provide: 'DmpTransactionDetailsService',
          useExisting: DmpTransactionDetailsService
        },
      ],
    };
  }
}
