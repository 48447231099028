<label *ngIf="templateOptions?.label && !templateOptions.hideLabel"  class="form-label" [class.d-inline-flex]="templateOptions?.infoTooltip">
  <span [innerHTML]="templateOptions?.label | translate | trustedHtml"></span>
  <span *ngIf="templateOptions?.isRepeatedItem && templateOptions?.index"> - {{ templateOptions?.index }}</span>
  <span *ngIf="templateOptions?.isRepeatedItem && !templateOptions?.readonly" class="delete"
        (click)="deleteRepeatItem()">{{ "general.delete" | translate }}
  </span>
  <span *ngIf="control?.hasValidator(requiredValidator) && !templateOptions?.readonly">*</span>
  <ng-container *ngIf="templateOptions?.infoTooltip">
    <foo-icon [icon]="{
          iconClass: 'foo-circle foo-shadow-primary w-100 h-100',
          stackIconClass: 'foo-form-info-primary ',
          containerClass: 'ml-1 foo-w-17 foo-h-17'}"
              [tooltip]="isString(templateOptions?.infoTooltip)?templateOptions?.infoTooltip:{
                position:  templateOptions?.infoTooltip?.['position']||'above',
                message: templateOptions?.infoTooltip?.['message']
                }" >

    </foo-icon>
  </ng-container>
</label>
