<foo-control-label [control]="control" [templateOptions]="templateOptions"></foo-control-label>

<mat-form-field appearance="fill" class="datetimepicker">
  <input [formControl]="control"
         [max]="maxDate"
         [min]="minDate"
         [placeholder]="templateOptions?.placeholder | translate"
         [ngxMatDatetimePicker]="datetimePicker"
         matInput
         placeholder=""
         readonly
         class="input-calendar">

  <ng-container *ngIf="control?.value && clearable">
    <div (click)="clearInput()" class="custom-clear-input" matDatepickerToggleIcon><span class="ng-clear"></span></div>
  </ng-container>

  <mat-datepicker-toggle [for]="$any(datetimePicker)"
                         matSuffix
                         [class.toggleDisabled]="templateOptions?.readonly"
                         [disabled]="templateOptions?.readonly">
    <mat-icon matDatepickerToggleIcon>
      <span class="foo-icon" [class]="icon"></span>
    </mat-icon>
  </mat-datepicker-toggle>
  <ngx-mat-datetime-picker #datetimePicker [enableMeridian]="true" [showSeconds]="false"></ngx-mat-datetime-picker>
</mat-form-field>

<foo-control-errors *ngIf="!templateOptions?.readonly && !templateOptions?.hideErrors"
                    [control]="control"
                    [errorMessages]="errors"></foo-control-errors>
