import {
  Directive,
  HostListener,
  ElementRef,
  Optional
} from "@angular/core";
import { FormGroupDirective } from "@angular/forms";
import {fromEvent, Subject} from "rxjs";
import {debounceTime, take, takeUntil} from "rxjs/operators";

import { FormValidationContainerDirective } from "./form-validation-container.directive";

@Directive({
  selector: "[formValidation]"
})
export class FormValidationDirective {
  private get containerEl(): any {
    return this.scrollContainerDir ? this.scrollContainerDir.containerEl : window;
  }

  constructor(
    private el: ElementRef,
    private formGroupDir: FormGroupDirective,
    @Optional()
    private scrollContainerDir: FormValidationContainerDirective
  ) {}

  @HostListener("ngSubmit") onSubmit() {
    if (this.formGroupDir.control.invalid) {
      this.scrollToFirstInvalidControl();
      if (this.formGroupDir.invalid) {
        this.formGroupDir.control.markAllAsTouched();
        return false;
      }
    }
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      ".ng-invalid:not(form)"
    );

    this.containerEl.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: "smooth"
    });

    fromEvent(this.containerEl, "scroll")
      .pipe(
        debounceTime(100),
        take(1)
      )
      .subscribe(() => firstInvalidControl.focus());
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 50;
    const controlElTop = controlEl.getBoundingClientRect().top;

    if (this.scrollContainerDir) {
      const containerTop = this.containerEl.getBoundingClientRect().top;
      const absoluteControlElTop = controlElTop + this.containerEl.scrollTop;

      return absoluteControlElTop - containerTop - labelOffset;
    } else {
      const absoluteControlElTop = controlElTop + window.scrollY;

      return absoluteControlElTop - labelOffset;
    }
  }


  /**
   * This function is used when a control changes its value after popup load leading it to be set as pristine false hence
   * Save button enabled on page load(eg. when using ngIntl plugin)
   * @param control control that changed after form load and needs to be checked as pristine
   * @checksCount how many times we need to check for change before stopping subscription
   */
  public markControlAsPristineOnLoad(control, checksCount: number = 1){
    let controlInitiated = false;
    let finalise = new Subject();
    let checksCountParam = checksCount;
    control.valueChanges
      .pipe(takeUntil(finalise))
      .subscribe((x) => {
        if(checksCountParam > 1){
          checksCountParam-=1;
        }
        else if (!controlInitiated) {
          control.markAsPristine();
          controlInitiated = true;
          finalise.next(null);
          finalise.complete();
        }
      });
  }
}
