import {Directive, ElementRef, EventEmitter, Inject, Input, Output, Renderer2} from '@angular/core';
import {DOCUMENT} from "@angular/common";

@Directive({
  selector: '[appOnClickedOutside]'
})
export class OnClickedOutsideDirective {

  @Output() onClickedOutside = new EventEmitter();

  @Input() appOnClickedOutside = true;

  document = null;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private dom,
  ) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.appOnClickedOutside) {
            if (this.dom.contains(e.target) && !this.el.nativeElement.contains(e.target)) {
              this.onClickedOutside.emit(e);
            }
      }
    });
  }

}
