import { Component, OnInit ,Input} from '@angular/core';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss']
})
export class StarRatingComponent implements OnInit {
  @Input() actualRating;
  @Input() ratingTotal;
  actualRate = 0;
  emptyStars =0;
  totalReviews: any =0;
  reviews: any  =0;
  showTotalreviews: any  =0;
  constructor() { }

  ngOnInit(): void {
    this.actualRate=this.actualRating;
  }

  drawEmptyStars(){
     this.emptyStars = this.ratingTotal-Math.ceil(this.actualRating);
    return new Array(this.emptyStars);
  }

  drawFullStarsChecked(){
    return new Array(Math.floor(this.actualRating));
  }

  checkIfHalfStarChecked(){
    return this.actualRating && Math.floor(this.actualRating) != this.actualRating;
  }

}
