<div class="login_content">
  <div class="login_body_container">
    <div class="login_body" formValidationContainer>
      <div class="col-12 col-md-12 col-sm-12 col-lg-12 mb-32 centered">
        <img class="login-logo" alt="login-logo" src="{{logo}}"/>
        <p class="title-desc">{{ "auth.enterOtpSent" | translate}}</p>
      </div>
      <form (submit)="onSubmit()" [formGroup]="otpForm" autocomplete="off" formValidation class="col-12 col-md-12 col-sm-12 col-lg-12 p-0">
        <button hidden type="submit"></button>

        <div class="form-group mb-32">
          <label class="form-label">{{"auth.otp" | translate}}</label>
          <input class="form-control" formControlName="otp" type="text" placeholder='{{"auth.enterOtp" | translate}}'/>
          <small *ngIf="otpForm.get('otp').touched && otpForm.get('otp').errors"
                 class="text-danger">
            {{"auth.otpShouldBe" | translate}} {{otpLength}} {{"auth.digits" | translate}}
          </small>
        </div>
        <div class="form-group mb-16">
          <p class="resendMessage"> {{ "general.didntreceive" | translate }} {{ "general.resendin" | translate }}
            <span> {{ counter  | date:'mm:ss' }}</span></p>
          <button [disabled]="counter !=0" type="button" class="foo-btn-outline-secondary btn-big foo-btn-center" (click)="resendCode()"
          >{{ "general.resendcode" | translate }}</button>
        </div>
        <div class="form-group mb-0">
          <button type="submit" class="foo-btn-primary btn-block">{{ "auth.submit" | translate}}</button>
        </div>
      </form>
    </div>
  </div>
  <div class="login-footer" *ngIf="showCopyright || showDesignedDevelopedByFoo">
    <div class="reserved" *ngIf="showCopyright">&copy; Copyright {{currentYear}} {{projectName}}</div>
    <div class="designed" *ngIf="showDesignedDevelopedByFoo">Designed and developed by FOO_</div>
  </div>
</div>


