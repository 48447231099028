import {Component, OnInit, Input, Output, EventEmitter, SimpleChanges} from '@angular/core';
import {isFunction, isNumber} from "lodash";
import {TranslateService} from "@ngx-translate/core";
import { SearchResultService } from '../services/search-result.service';

@Component({
  selector: 'foo-boxes-filter',
  templateUrl: './boxes-filter.component.html',
  styleUrls: ['./boxes-filter.component.scss']
})
export class BoxesFilterComponent {
  @Input() configArr;
  @Input() filter;
  @Input() form;
  @Output() onFilterCleared = new EventEmitter<any>();

  hasFilter = false;
  separate = ': ';
  hideClearAllFilter: boolean = false;

  public constructor(
    private translate: TranslateService,
    private SearchResultService: SearchResultService
  ) {
  }

  ngOnInit(): void {
    this.hasFilterFun();
  }

  clearFilterFields(...fields: string[]): void {
    (fields || []).forEach(field => {
      this.form.controls[field].setValue(null);
    });
    this.onFilterCleared.emit();
  }

  clearAllFilterFields(): void {
    this.form.reset();
    this.onFilterCleared.emit();
  }

  isNumber(object: any): boolean {
    return isNumber(object);
  }

  isFunction(object: any){
    return isFunction(object);
  }

  translationPathFun(obj: any) {
    let value = '';
    let objKey = obj.objectKey ? obj.objectKey : 'key';
    let formProp = obj.type == 'object' ? this.filter[obj.formProp][objKey] : this.filter[obj.formProp];
    let filterValKey = obj.filterValKey ? obj.filterValKey : 'value';

    if (obj.sperate) {
      this.separate = obj.sperate;
    }
    if (obj.title) {
      value += this.translate.instant(obj.title);
    }
    value += this.separate;
    if (!obj['arr'] && ((this.filter[obj.formProp]) || ((this.filter[obj.formProp] == 0) && obj?.isNumber))) {
      value += this.translate.instant((obj?.translationPath ? obj.translationPath : '') + formProp);
    } else {
      const keyvalueType = obj.arrType == 'keyvalue';
      const objArr = keyvalueType ? Object.keys(obj.arr) : obj.arr;
      let objItem;
      let findItem = objArr?.filter(o => {
        if (obj.arrType == 'keyvalue') {
          objItem = o == formProp;
        } else if (obj.arrType == 'object') {
          let arrFilterValKey = filterValKey.split('.');
          if (o[arrFilterValKey[0]] != null) {
            objItem = o[arrFilterValKey[0]][arrFilterValKey[1]] == formProp;
          }
        } else {
          objItem = o[filterValKey] == formProp;
        }
        return objItem;
      });
      let returnedValue;
      if (findItem?.length == 1) {
        if (keyvalueType) {
          returnedValue = obj.returnValKey ? obj.arr[findItem[0]][obj.returnValKey] : obj.arr[findItem[0]];
        } else if (obj.arrType == 'object') {
          let arrReturnValKey = obj.returnValKey.split('.');
          returnedValue = findItem[0][arrReturnValKey[0]][arrReturnValKey[1]];
        } else {
          returnedValue = findItem[0][obj.returnValKey];
        }
      }
      value += obj.translationPath ? this.translate.instant(obj.translationPath + returnedValue) : returnedValue;
    }
    return value;
  }

  hasFilterFun() {
    this.hasFilter = false;
    if (this.filter != null) {
      let self = this;
      let hideClearAllFilterCounter: number = 0;
      this.configArr.forEach(field => {
        if (field.hasOwnProperty('hideClearFilter') && ((isFunction(field.hideClearFilter) && field.hideClearFilter() === true) || field.hideClearFilter === true)) hideClearAllFilterCounter++;
        if (hideClearAllFilterCounter === this.configArr?.length) this.hideClearAllFilter = true;
        if (field.type == 'range') {
          if (self.filter[field.formProp1] || self.filter[field.formProp2]) {
            this.hasFilter = true
            return
          }
        } else {
          if (self.filter[field.formProp] || self.filter[field.formProp] === 0) {
            this.hasFilter = true
            return
          }
        }
      });
    }
    this.SearchResultService.setHasFilter(this.hasFilter);
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      this.hasFilterFun();
    }
  }

  ngOnDestroy(): void {
    this.SearchResultService.setHasFilter(false);
  }
}
