import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MakeCallService } from 'foo-framework';

@Component({
  selector: 'page-buttons',
  templateUrl: './page-buttons.component.html',
  styleUrls: ['./page-buttons.component.scss']
})
export class PageButtonsComponent implements OnInit {
  @Input() hideBtnListOnDesktop = false;
  @Input() hideBtnListOnMobile = false;
  @Input() isBtnListDisabled = false;
  @Input() btnList;
  @Input() dataShimmer;

  @Output() onFunctionCalledEvent = new EventEmitter<{ functionName: string, item: object }>();

  constructor(private makeCall: MakeCallService) { }

  ngOnInit() {
    if (this.dataShimmer === undefined) {
      this.makeCall.isLoading.subscribe((isLoading) => {
        this.dataShimmer = isLoading;
      });
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setPageContentMargin();
    }, 0);
  }

  setPageContentMargin() {
    let responsiveButtons = document.getElementsByClassName("responsive-buttons-container");
    if(responsiveButtons.length){
      for (var i = 0; i < responsiveButtons.length; i++) {
          let responsiveButtonsContainerHeight = document.getElementsByClassName("responsive-buttons-container")[i] ? document.getElementsByClassName("responsive-buttons-container")[i].getBoundingClientRect().height : 0;
          if(document.getElementsByClassName("page-content")[0]) {
            // @ts-ignore
            document.getElementsByClassName("page-content")[0].style.marginBottom = responsiveButtonsContainerHeight + 'px';
          }
      }
    }
  }

  btnFunctionCalled(name, data) {
    this.onFunctionCalledEvent.emit({
      functionName: name,
      item: data
    });
  }

}
