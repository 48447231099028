import { Injectable } from '@angular/core';
import { RouteHandler } from './route-handler';
import { Router } from '@angular/router';
import { isFunction } from 'lodash';
import { AlertService } from 'foo-framework';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ExternalLinkRouteHandlerService implements RouteHandler {

  constructor(
    protected router: Router,
    protected alert: AlertService,
    protected translate: TranslateService
  ) { }

  handle(menuItem: any): void {
    if (menuItem?.open_in_new_tab && menuItem?.new_tab_url) {
      if (!this.isValidUrl(menuItem.new_tab_url)) {
        this.alert.emitAlert({ type: 'danger', text: this.translate.instant('general.invalidURL') });
        return;
      }
      window.open(menuItem.new_tab_url, '_blank');
    }
  }

  protected isValidUrl(url) {
    try {
      if (isFunction(window['URL'])) {
        return Boolean(new window['URL'](url));
      } else {
        return true;
      }
    }
    catch (e) {
      return false;
    }
  }
}