import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {AlertService, LoaderService, MakeCallService} from "foo-framework";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'foo-file-icon',
  templateUrl: './file-icon.component.html',
  styleUrls: ['./file-icon.component.scss']
})
export class FileIconComponent implements OnInit {

  @Input() fileData = null;

  mimeTypes = {
    'audio/aac': 'aac',
    // 'application/x-abiword': 'abw',
    // 'application/x-freearc': 'arc',
    'video/x-msvideo': 'avi',
    // 'application/vnd.amazon.ebook': 'azw',
    'application/octet-stream': 'bin',
    'image/bmp': 'bmp',
    // 'application/x-bzip': 'bz',
    // 'application/x-bzip2': 'bz2',
    // 'application/x-csh': 'csh',
    'text/css': 'css',
    'text/csv': 'xls',
    'application/msword': 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'doc',
    // 'application/vnd.ms-fontobject': 'eot',
    // 'application/epub+zip': 'epub',
    // 'application/gzip': 'gz',
    'image/gif': 'gif',
    'text/html': 'html',
    // 'image/vnd.microsoft.icon': 'ico',
    // 'text/calendar': 'ics',
    // 'application/java-archive': 'jar',
    'image/jpeg': 'jpg',
    'image/jpg': 'jpg',
    // 'text/javascript': 'mjs',
    // 'application/json': 'json',
    // 'application/ld+json': 'jsonld',
    'audio/midi': 'midi',
    'audio/mpeg': 'mp3',
    'video/mpeg': 'mpg',
    // 'application/vnd.apple.installer+xml': 'mpkg',
    // 'application/vnd.oasis.opendocument.presentation': 'odp',
    // 'application/vnd.oasis.opendocument.spreadsheet': 'ods',
    // 'application/vnd.oasis.opendocument.text': 'odt',
    // 'audio/ogg': 'oga',
    // 'video/ogg': 'ogv',
    // 'application/ogg': 'ogx',
    // 'audio/opus': 'opus',
    // 'font/otf': 'otf',
    'image/png': 'png',
    'application/pdf': 'pdf',
    'application/x-httpd-php': 'php',
    'application/vnd.ms-powerpoint': 'ppt',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'ppt',
    'application/vnd.rar': 'zip',
    // 'application/rtf': 'rtf',
    // 'application/x-sh': 'sh',
    'image/svg+xml': 'svg',
    // 'application/x-shockwave-flash': 'swf',
    'application/x-tar': 'zip',
    'image/tiff': 'tif',
    // 'video/mp2t': 'ts',
    // 'font/ttf': 'ttf',
    'text/plain': 'txt',
    // 'application/vnd.visio': 'vsd',
    // 'audio/wav': 'wav',
    // 'audio/webm': 'weba',
    // 'video/webm': 'webm',
    // 'image/webp': 'webp',
    // 'font/woff': 'woff',
    // 'font/woff2': 'woff2',
    'application/xhtml+xml': 'html',
    'application/vnd.ms-excel': 'xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xls',
    'application/xml ': 'xml',
    'application/vnd.mozilla.xul+xml': 'xml',
    'application/zip': 'zip',
    // 'video/3gpp': '3gp',
    // 'video/3gpp2': '3g2',
    'application/x-7z-compressed': 'zip'
  };

  constructor(
    private translate: TranslateService,
    private loaderService: LoaderService,
    private alert: AlertService,
    private makeCall: MakeCallService,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
  }

  openFile(file): void {
    window.open(file.document_link);
  }

}
