import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/**
 *  This fulter is used to bypass security and treat HTML as trusted
 */

@Pipe({
    name: 'trustedHtml'
})
export class TrustedHtml implements PipeTransform  {

   constructor(private _sanitizer: DomSanitizer){}  

    transform(html: string) {
        return this._sanitizer.bypassSecurityTrustHtml(html); 
    } 
} 