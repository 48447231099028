import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import { MakeCallService} from "foo-framework";

@Component({
  selector: 'app-refund-dialog',
  templateUrl: './refund-dialog.component.html',
  styleUrls: ['./refund-dialog.component.scss']
})
export class RefundDialogComponent implements OnInit {
  form: UntypedFormGroup;
  numDecimalRegex = /^\d*[.,]?\d{0,3}$/;
  currency = '';

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<any>,
    private makeCall: MakeCallService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.initializeForms();
  }

  ngOnInit(): void {
    if (this.data?.item?.currency) this.currency = this.data.item.currency;
  }

  initializeForms(): void {
    this.form = this.fb.group({
      amount: [null, [Validators.required, Validators.pattern(this.numDecimalRegex)]],
    });
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.makeCall.postRequest('orderhistory/performaction', {
        id: this.data?.item?.id ? this.data.item.id : null,
        type: 'refund',
        void: 0,
        amount: this.form.get('amount').value,
      }, {
        showLoader: true,
        showSuccessMessage: true
      }).subscribe({
        next: (data) => {
          this.dialogRef.close(true);
        }
      });
    }
  }
}
