<ng-container *ngIf="!templateOptions.readonly">
  <ng-container *ngIf="!templateOptions.items">
    <mat-checkbox [formControl]="control" class="form-control">
      <ng-container
        *ngTemplateOutlet="getTemplate(labelTemplate); context: { label: label}">
      </ng-container>
    </mat-checkbox>
  </ng-container>
  <ng-container *ngIf="templateOptions.items">
    <foo-control-label [templateOptions]="templateOptions" [control]="control"></foo-control-label>
    <div  class="foo-checkbox-option"
          [class.checkbox-button]="templateOptions.items?.length === 2"
          [class.vertical-checkboxes]="templateOptions.items?.length > 2">
      <mat-checkbox *ngFor="let val of templateOptions.items" class="form-control-checkbox"
                     [(ngModel)]="val.selected" (change)="setValues($event)"
                     [checked]="val.selected"
                     name="{{val.value}}"
                     [disabled]="disableOthers == true && val.value != 'none'">
        {{ val.label | translate }}
      </mat-checkbox>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="templateOptions.readonly">
  <input [readonly]="'readonly'"
         class="form-control" [formControl]="control"
         type="text">
</ng-container>

<foo-control-errors *ngIf="!templateOptions?.readonly && !templateOptions?.hideErrors" [control]="control"
                    [errorMessages]="errors"></foo-control-errors>


<ng-template [frameworkTemplate]="'checkboxDefault'" let-label="label">
  {{label | translate}}
</ng-template>
