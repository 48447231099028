import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'link'
})
export class LinkPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let urlRegex = /(https?:\/\/[^\s]+)/g;
    return value?.replace(urlRegex, function (url: string) {
      return `<a href="${url}" target="_blank">${url}</a>`;
    })
  }

}
