
<span class="d-flex checked " *ngFor=" let  x of  drawFullStarsChecked() ; let i=index" >
  <span class="foo-icon foo-fill-star-primary"></span>
</span>
<span class="d-flex" *ngIf="checkIfHalfStarChecked()"  >
  <span class="foo-icon foo-half-star-primary"></span>
</span>
<span  class="checked d-flex" *ngFor=" let  y of  drawEmptyStars() ; let j=index">
  <span class="foo-icon foo-empty-star-primary"></span>
</span>

<span class="opacity_rate d-flex">{{actualRating?actualRating:'0.0'}}</span>
<span class="opacity_rate d-flex" *ngIf="showTotalreviews =='show'">({{totalReviews?totalReviews:0}} reviews)</span>

