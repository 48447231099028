import { Component, Input, OnInit } from '@angular/core';
import {isObservable} from "rxjs";

@Component({
  selector: 'framework-first-load',
  templateUrl: './first-load.component.html',
  styleUrls: ['./first-load.component.scss']
})
export class FirstLoadComponent implements OnInit {

  @Input() showFirstLoad;
  @Input() params: any = {};

  constructor() { }
  isAnimated = true;
  ngOnInit(): void {
    if(this.params?.imageUrl && !this.params?.imageUrl?.endsWith('.json')){
      this.isAnimated = false;
    }
  }

  isObservable(obj: any): boolean {
    return isObservable(obj);
  }

}
