import {Injectable} from '@angular/core';
import { isNil, isEmpty, get, keyBy } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService{
  public permissions = {};
  public menu = window['menu'] && window['menu'] !== 'null' ? JSON.parse(window['menu']):[];

  routesFromApi;
  constructor( ) { }

  public viewPermission(url){
    const permisionMenu = url !== null && url !== undefined ? this.getMenuPermissionById(url) : null;
    if (permisionMenu) {
      if (permisionMenu.length === 1) {
        this.permissions = permisionMenu[0].permissions[0];
        for(const property in this.permissions) {
          this.permissions[property] = Number(this.permissions[property]);
        }
        this.permissions[get(permisionMenu[0], 'key')] = {...this.permissions};
      }
      else {
        this.permissions = keyBy(permisionMenu, 'key');
        for (const property in this.permissions) {
          this.permissions[property] = this.permissions[property].permissions[0];
        }

        for (const item in this.permissions) {
          for (const property in this.permissions[item]) {
            this.permissions[item][property] = Number(this.permissions[item][property]);
          }
        }
      }
    }
    else {
      this.permissions = {};
    }
  }

  private getMenuPermissionById(eventUrl: any) {
    if (isEmpty(this.menu)) {
      this.menu = window['menu'] && window['menu'] !== 'null' ? JSON.parse(window['menu']):[];
    }

    this.menu = this.menu.map( i => i.menu_url === 'manage-beneficiary' ?{...i, menu_url: 'more/manage-beneficiary'} : i)
    const urlParts =  eventUrl.split('/');
    const url = urlParts[urlParts.length - 1];
    const retVal = this.menu.filter(item => {
      const routesParts = (item['menu_url'] || '').split('/');
      return routesParts[routesParts.length - 1] === url;
    });
    if (retVal.length > 0) {
      return retVal[0].permission_features;
    } else {
      return null;
    }
  }

  checkIfStateExist(state_name) {
    if (!isNil(state_name)) {
      this.routesFromApi = JSON.parse(window['menu']);
      var state_found = this.routesFromApi.find(
        (x) => x.state_name === state_name
      );
      return state_found ? true : false;
    } else {
      return false;
    }
  }
}
