import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {UtilsService} from "./utils.service";
import { AlertService } from './alert.service';
import { LoaderService } from './loader/loader.service';
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Observable, ReplaySubject, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { RecaptchaService } from './recaptcha.service';
import { isArray, isObject, keys } from 'lodash';

export interface RequestConfigs extends BaseConfigs {
  options?: any;
  showLoader?: boolean;
  updateIsLoading?: boolean;
  showSuccessMessage?: boolean;
  downloadFileFromPath?: boolean;
  cacheResponse?: boolean | RequestCacheResponseConfigs
 
}

export interface BaseConfigs {
  googleRecaptchaEnabled?: boolean;
}

export interface RequestCacheResponseConfigs {
    keepOnNavigation?: boolean
}

@Injectable()
export class MakeCallService {
  prefix = 'api/';
  protected isLoadingSubject = new BehaviorSubject<boolean>(false);
  isLoading = this.isLoadingSubject.asObservable();

  protected cachedAPICalls: {
    [key: string]: {
      isPending: boolean,
      pendingSubject: ReplaySubject<string>,
      response: any;
      configs: RequestCacheResponseConfigs
    } 
  } = {};


  constructor(
    protected http: HttpClient,
    protected utils: UtilsService,
    protected alert: AlertService,
    protected translate: TranslateService,
    protected loaderService: LoaderService,
    protected recaptchaService: RecaptchaService
  ) { }

  defaultRequestConfigs: RequestConfigs = {
    options: null,
    showLoader: false,
    updateIsLoading: true,
    showSuccessMessage: false,
    downloadFileFromPath: false
  }


  protected handleCachedRequest(type: string, url: string, queryParams: any | null = null, method: string = '', configurations?: RequestConfigs): Observable<any> {
    const requestId = this.getRequestId(url,queryParams);
    const cacheResponseConfig = isObject(configurations.cacheResponse) ? configurations.cacheResponse : null;
    if (this.cachedAPICalls[requestId]) {
      if (this.cachedAPICalls[requestId].isPending) { 
        return this.cachedAPICalls[requestId].pendingSubject.asObservable();
      } else {
        return of(this.cachedAPICalls[requestId].response);         
      }
     } else {
      const subject = new ReplaySubject<string>();
      this.cachedAPICalls[requestId] = {
        isPending: true,
        pendingSubject: subject,
        response: null,
        configs: cacheResponseConfig
      };
      this.handleDefaultRequest(type, url, queryParams, method, configurations)
      .subscribe({
        next: (response) => {
          if (this.cachedAPICalls[requestId]) {
           this.cachedAPICalls[requestId].response = response;
           this.cachedAPICalls[requestId].isPending = false;
           subject.next(this.cachedAPICalls[requestId].response);
          }
          subject.complete();
        },
        error: (error) => {
          subject.error(error);
          subject.complete();
          this.cachedAPICalls[requestId] = null;
        }
      });
      return subject.asObservable();
     }
  }

  protected handleDefaultRequest(type: string, url: string, queryParams: any | null = null, method: string = '', configurations?: RequestConfigs): Observable<any> {
    return new Observable(observer => {
      const configs = { ...this.defaultRequestConfigs, ...configurations };
      configs.options = { ...configs.options, ...{ updateIsLoading: configs.updateIsLoading } }
      if (configs.showLoader) {
        this.loaderService.visibility = true;
      }

      let apiCall: Observable<any>;
      if (queryParams) {
        const baseConfigs: BaseConfigs = {
          ...configs?.googleRecaptchaEnabled !== undefined && {
            googleRecaptchaEnabled: configs.googleRecaptchaEnabled
          }
        }

        apiCall = this[type](url, queryParams, configs.options, baseConfigs);
      } else if (method) {
        apiCall = this[type](method, url, configs.options);
      } else {
        apiCall = this[type](url, configs.options);
      }

      apiCall.subscribe({
        next: (data: any) => {
          if (configs.showLoader) this.loaderService.visibility = false;
          if (data.status?.toLowerCase() === 'ok' ||  data.status?.toLowerCase() === 'success' || data.success || data.items || (configs.downloadFileFromPath && data.path)) {
            if (configs.downloadFileFromPath && data.path) {
              location.href = data.path;
            } else if (configs.showSuccessMessage) {
              this.alert.emitAlert({type: 'success', text: data['message']});
            }
            observer.next(data);
            observer.complete();
          } else {
            if (data.message) {
              this.alert.emitAlert({type: 'danger', text: data.message});
              observer.error(data.message);
            } else {
              this.alert.emitAlert({type: 'danger', text: this.translate.instant('general.somethingWentWrong')});
              observer.error(this.translate.instant('general.somethingWentWrong'));
            }
          }
        },
        error: (err: any) => {
          if (configs.showLoader) this.loaderService.visibility = false;
          this.alert.emitAlert({type: 'danger', text: this.translate.instant('general.somethingWentWrong')});
          observer.error(err);
        }
      });
    });
  }


  protected handleRequests(type: string, url: string, queryParams: any | null = null, method: string = '', configurations?: RequestConfigs): Observable<any> {
    if (configurations?.cacheResponse) {
      return this.handleCachedRequest(type, url, queryParams, method, configurations);
    } else {
      return this.handleDefaultRequest(type, url, queryParams, method, configurations);
    }
  }

  getRequest(url: string, queryParams: any | null = null, configurations?: RequestConfigs): Observable<any> {
    return this.handleRequests("getCall", url, queryParams, '', configurations);
  }

  postRequest(url: string, queryParams: any | null = null, configurations?: RequestConfigs): Observable<any> {
    return this.handleRequests("postCall", url, queryParams, '', configurations);
  }

  deleteRequest(url: string, configurations?: RequestConfigs): Observable<any> {
    return this.handleRequests("deleteCall", url, null, '', configurations);
  }

  requestWithHandling(method: string, url: string, configurations?: RequestConfigs): Observable<any> {
    return this.handleRequests("request", url, null, method, configurations);
  }

  setLoading(isLoading: boolean) {
    this.isLoadingSubject.next(isLoading);
  }

  getCall(url: string, queryParams: any | null = null, options: any | null = null) {
    if (queryParams){
      const queryString = Object.keys(queryParams).map(key => key + '=' + queryParams[key]).join('&');
      url += (url.indexOf('?') !== -1) ? '&' + queryString : '?' + queryString;
    }
    return this.http.get(this.prefix + url, this.appendHeaders(options));
  }

  postCall(url: string, data: any | null, options: any | null = null, configs?: BaseConfigs) {
    return this.recaptchaService.handleRecaptcha(url, configs).pipe(
      switchMap((recaptchaToken: string | null) => {
        // Include the reCAPTCHA token in the request data if available
        if (recaptchaToken) {
          data = { ...data, reCaptchaToken: recaptchaToken };
        }

        return this.http.post(this.prefix + url, data, this.appendHeaders(options));
      })
    );
  }

  deleteCall(url: string, options: any | null = null) {
    return this.http.delete(this.prefix + url, this.appendHeaders(options));
  }

  request(method: string, url: string, options: any | null = null) {
    return this.http.request(method, this.prefix + url, this.appendHeaders(options));
  }

  protected appendHeaders(options: any | null) {
    const timeZone = this.utils.getTimeZone();
    const headers = new HttpHeaders().set('X-USER-TIMEZONE', timeZone)
      .set('update-loading', String(options?.updateIsLoading === false ? options?.updateIsLoading : true));
    if (options && options.headers) {
      options.headers = options.headers.append('X-USER-TIMEZONE', timeZone)
        .append('update-loading', String(options?.updateIsLoading === false ? options?.updateIsLoading : true));
    } else if (options) {
      options.headers = headers;
    } else {
      options = { headers };
    }

    return options;
  }

  protected getRequestId(url: string, queryParams: any) {
    queryParams = queryParams || {};
    const paramsKeys = keys(queryParams);
    paramsKeys.sort();
    return url + JSON.stringify(paramsKeys.reduce((ac, key) =>  ({...ac, [key]: queryParams[key]}),{}));
 }

 emptyCache(urlPrefix: string | string[]): void {
  this.cachedAPICalls = Object.keys(this.cachedAPICalls).reduce((memo, key) => {
    if ((isArray(urlPrefix) ? urlPrefix : [urlPrefix]).every(prefix => !key.startsWith(prefix))) {
        memo[key] = this.cachedAPICalls[key];
    }
    return memo;
  }, {});
 }

 emptyCacheAll(): void {
  this.cachedAPICalls = {};
 }

 emptyCacheOnNavigation(): void {
  this.cachedAPICalls = Object.keys(this.cachedAPICalls).reduce((memo, key) => {
    if (this.cachedAPICalls[key]?.configs?.keepOnNavigation) {
      memo[key] = this.cachedAPICalls[key];
    }
    return memo;
  }, {});
 }

}