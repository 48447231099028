import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AlertService, LoaderService, MakeCallService } from 'foo-framework';
@Component({
  selector: 'app-add-country',
  templateUrl: './add-country.component.html',
  styleUrls: ['./add-country.component.scss']
})
export class AddCountryComponent implements OnInit {

  supportedCountries = [];
  apiUrl: string;
  form: UntypedFormGroup;
  disableButton = true;
  app_id = null;
  app_name = null;

  constructor(private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private alert: AlertService,
    private makecall: MakeCallService,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<any>,
    private loaderService: LoaderService) { }

  ngOnInit(): void {
    this.dialogRef.componentInstance = this.data.parentComponent;
    this.apiUrl = this.data.apiUrl;
    this.supportedCountries = this.data.supportedCountries;
    this.form = this.fb.group({});
    this.supportedCountries.forEach(element => {
      this.form.addControl(element.id, new UntypedFormControl({value: element.enabled, disabled: element.enabled} ));
    });

    if(this.data.app_id && this.data.app_name) {
      this.app_id = this.data.app_id;
      this.app_name = this.data.app_name;
    }

    this.form.valueChanges.subscribe(arrayOfFormsControllers => {
      let hasAtLeastOneSelectedCountry = Object.values(arrayOfFormsControllers).some(val => val === true);
      if (!hasAtLeastOneSelectedCountry) {
        this.disableButton = true;
      } else {
        this.disableButton = false;
      }
    });
  }

  save() {

    this.loaderService.visibility = true;

    let ids = []
    Object.keys(this.form.value).forEach(key => {
      if (this.form.controls[key].value === true) {
        ids.push(parseInt(key))
      }
    });

    let countriesName = [];
    ids.forEach(id => {
      let countryName = this.supportedCountries.find(supportedCountry => supportedCountry.id === id);
      if(countryName) {
        countriesName.push(countryName.name);
      }
    });

    let dataToSend = {
      country_ids: JSON.stringify(ids),
      countries: countriesName
    };

    if(this.app_id && this.app_name) {
      dataToSend['app_id'] = this.app_id;
      dataToSend['app_name'] = this.app_name;
    }

    this.makecall.postCall(this.apiUrl, dataToSend).subscribe((data) => {

      if (data["status"] == "OK" || data["status"] == "success") {
        this.alert.emitAlert({
          type: 'success',
          text: data['message'] && data['message'] != '' ? data['message'] : this.translate.instant('general.addedSuccessfully')
        });
        this.dialogRef.close('submit');
      } else {
        this.alert.emitAlert({ type: 'danger', text: data['message'] });
      }
      this.loaderService.visibility = false;
    }, error => {
      this.alert.emitAlert({ type: 'danger', text: this.translate.instant('general.somethingWentWrong') });
      this.loaderService.visibility = false;
    });
  }

}
