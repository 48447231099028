import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchResultService {
  private hasSearch = new BehaviorSubject<boolean>(false);
  private hasFilter = new BehaviorSubject<boolean>(false);
  constructor() { }

  setHasSearch(value: boolean): void {
    this.hasSearch.next(value);
  }
  
  setHasFilter(value: boolean): void {
    this.hasFilter.next(value);
  }

  getHasSearch(): BehaviorSubject<boolean> {
    return this.hasSearch;
  }

  getHasFilter(): BehaviorSubject<boolean> {
    return this.hasFilter;
  }

}
