import { Injector, APP_INITIALIZER } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LOCATION_INITIALIZED } from '@angular/common';
import {TokenStorage} from './services/token-storage.service';

export function appInitializerFactory(translate: TranslateService, injector: Injector, tokenStorage: TokenStorage) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      let langToSet = 'en';
      if (tokenStorage.getCurrentLanguage()) {
        langToSet = tokenStorage.getCurrentLanguage();
      } else {
        tokenStorage.setCurrentLanguage('en');
        langToSet = 'en';
      }
      translate.setDefaultLang(langToSet);
      translate.use(langToSet).subscribe(() => {
      }, err => {
        console.error(`Problem with '${langToSet}' language initialization.'`);
      }, () => {
        resolve(null);
      });
    });
  });
}
