import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  prefix = 'api/';
  constructor(private http: HttpClient) { }

  upload(file: File, uploadPath:string, url:string, params:any = null): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);
    if (url) formData.append('url', url);
    if(params) {
      let keys = Object.keys(params);

      if(keys.length) {
        keys.forEach(element => {
          if (element === 'params[analytics]') params[element] = JSON.stringify(params[element]);
          formData.append(element, params[element]);
        });
      }
    }

    const req = new HttpRequest('POST', this.prefix + uploadPath, formData, {
      headers: new HttpHeaders().set('update-loading', 'false'),
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }
}
