import { Component, forwardRef, Injector, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { FooControlComponent } from "../foo-control/foo-control.component";
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';

import { has } from 'lodash';
import { CountryService } from "../../services/country.service";
import { HelperService } from 'foo-framework';
const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FooMobileNumberComponent),
  multi: true
};


@Component({
  selector: 'foo-mobile-number',
  templateUrl: './foo-mobile-number.component.html',
  styleUrls: ['./foo-mobile-number.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class FooMobileNumberComponent extends FooControlComponent implements OnInit {

  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;

  predefinedPreferredCountries: CountryISO[] = [];
  currentCountry: any;
  itemSelectedCountry = null;
  numberWithCountryCode;
  numberWithoutCountryCode;
  filteredCountries = [];
  preferredCountries = [];
  selectedCountry: any

  constructor(
    private countryService: CountryService,
    private helperService: HelperService,
    injector: Injector
  ) {
    super(injector);
  }


  ngOnInit(): void {
    this.numberWithCountryCode = this.control.value;
    let currentCountry = this.countryService.getCurrentCountry();
    let predefinedPreferredCountries = []
    predefinedPreferredCountries.push(CountryISO[currentCountry]);
    let preferredCountries = this.templateOptions.preferredCountries || predefinedPreferredCountries;
    let selectedCountry = this.templateOptions.selectedCountry || CountryISO[currentCountry];

    if (!this.templateOptions?.filteredCountries || this.templateOptions?.filteredCountries?.excludeCountries) {
      let countries = this.templateOptions.filteredCountries?.countries || [CountryISO.Israel];
      this.filteredCountries = Object.keys(CountryISO)
        .filter((x) => ![...countries].some(y => y == CountryISO[x]))
        .map((x) => CountryISO[x]);
    } else {
      this.filteredCountries = this.templateOptions.filteredCountries?.countries || [];
    }
    this.preferredCountries = preferredCountries?.filter(item => this.filteredCountries?.includes(item));
    if (!this.filteredCountries?.includes(selectedCountry?.toLowerCase())) {
      this.selectedCountry = this.filteredCountries[0];
    } else {
      this.selectedCountry = selectedCountry;
    }

    if (this.control.value) {
      if (!this.control.value.startsWith("+")) {
        this.control.setValue(`+${this.control.value}`);
      }
      const phone = this.helperService.getNumberWithoutCountryCode(this.control.value);
      this.itemSelectedCountry = phone.selectedCountry;
      this.selectedCountry = this.itemSelectedCountry;
      this.control.setValue(phone.phoneWithoutCountryCode);
      this.numberWithoutCountryCode = phone.phoneWithoutCountryCode.replace(/\s/g, '');
    }
    this.control.markAsPristine();
    this.control.valueChanges.subscribe(newValue => {
      const phoneNumber = newValue?.number.replace(/\s/g, '');
      if (phoneNumber !== this.numberWithoutCountryCode) {
        this.control.markAsTouched();
        this.control.markAsDirty();
      }
      if (newValue === null || phoneNumber === this.numberWithoutCountryCode) {
        this.control.markAsPristine();
      }
    });
  }

  get separateDialCode() {
    if (has(this.templateOptions, 'separateDialCode')) {
      return this.templateOptions.separateDialCode;
    }
    return true;
  }

}
