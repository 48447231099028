<form [formGroup]="form" autocomplete="off" (ngSubmit)="update()" formValidation>
  <div class="row">
    <div class="col-12 col-sm-6 col-md-5 col-lg-5 col-xl-4">
      <div class="app-info-img">
        <img class="w-100" src="/assets/img/app-info-step1.png" alt="">
      </div>
    </div>

    <div class="col-12 col-sm-6 col-md-7 col-lg-6 col-xl-5 mt-1 pl-0">

      <div class="form-group">
        <label class="form-label">{{ "shared-component.appInfo.stepOneDone.stepOneDoneTitle" | translate }}*</label>

        <input class="form-control"
          placeholder="{{'shared-component.appInfo.stepOneDone.stepOneDoneTitle-placeholder' | translate}}"
          formControlName="stepOneDoneTitle" type="text"
          [(ngModel)]="data['progress_one_section_finished_titles']['title']" />

        <small
          *ngIf="(form.get('stepOneDoneTitle').touched || form.get('stepOneDoneTitle').dirty) && form.get('stepOneDoneTitle').hasError('required')"
          class="text-danger">
          {{"validations.required_field" | translate}}
        </small>

        <small
          *ngIf="(form.get('stepOneDoneTitle').touched || form.get('stepOneDoneTitle').dirty) && form.get('stepOneDoneTitle').hasError('maxwords')"
          class="text-danger">
          {{"validations.maxWords" | translate: { maxnumber: 6 } }}
        </small>
      </div>

      <div class="form-group">
        <label class="form-label">{{ "shared-component.appInfo.stepOneDone.stepOneDoneText" | translate }}</label>

        <textarea class="form-control mb-9" formControlName="stepOneDoneText" type="text"
          placeholder="{{'shared-component.appInfo.stepOneDone.stepOneDoneText-placeholder' | translate}}" rows="7"
          [maxlength]="maxChars" [(ngModel)]="data['progress_one_section_finished_titles']['text']"></textarea>

        <span class="form-label">{{ form.controls['stepOneDoneText'].value.length }}/{{ maxChars }} {{'shared-component.appInfo.characters'
          |
          translate}}</span>

        <br>
        <small
          *ngIf="(form.get('stepOneDoneText').touched || form.get('stepOneDoneText').dirty) && form.get('stepOneDoneText').hasError('required')"
          class="text-danger">
          {{"validations.required_field" | translate}}
        </small>

        <small
          *ngIf="(form.get('stepOneDoneText').touched || form.get('stepOneDoneText').dirty) && form.get('stepOneDoneText').hasError('maxwords')"
          class="text-danger">
          {{"validations.maxWords" | translate: { maxnumber: 50 } }}
        </small>
      </div>

      <div class="form-group mt-5">
        <button type="submit" [disabled]="form.pristine" *ngIf="permissions?.can_update" class="foo-btn-primary  btn-main-site min-w170 foo-btn-center">{{
          "shared-component.appInfo.stepOneDone.submit" | translate}}</button>
      </div>

    </div>
  </div>
</form>
