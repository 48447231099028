<foo-control-label [templateOptions]="templateOptions" [control]="control"></foo-control-label>
<mat-form-field appearance="fill" class="datepicker">
  <input matInput
         [matDatepicker]="picker"
         [formControl]="control"
         [max]="templateOptions?.max"
         [min]="templateOptions?.min"
         [placeholder]="(placeholder || templateOptions.placeholder) | translate"
         readonly
         class="input-calendar">

  <ng-container *ngIf="control?.value && clearable">
    <div matDatepickerToggleIcon class="custom-clear-input" (click)="clearInput()"><span class="ng-clear"></span></div>
  </ng-container>

  <mat-datepicker-toggle matSuffix [for]="picker" [class.toggleDisabled]="templateOptions?.readonly" [disabled]="templateOptions?.readonly">
    <mat-icon matDatepickerToggleIcon >
      <span class="foo-icon" [class]="icon"></span>
    </mat-icon>
  </mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>


<foo-control-errors *ngIf="!templateOptions?.readonly && !templateOptions?.hideErrors" [control]="control"
                    [errorMessages]="errors"></foo-control-errors>
