import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UnauthPagesComponent } from './unauth-pages.component';
import {LazyLoadedComponent, PageNotFoundComponent} from 'foo-framework';
import {RoutesMapper as FrameworkRoutesMapper} from 'foo-framework/routes-mapper';
const routes = [
  {
    path: '',
    component: UnauthPagesComponent,
    children: [
      {
        path: 'login-otp',
        component: LazyLoadedComponent,
        data: {
          title: 'Login',
          canonical: '/login-otp',
          routeInfo: {
            module: FrameworkRoutesMapper.modules.VerifyNumberModule,
            componentName: 'VerifyNumberComponent',
          }
        }
      },
      {
        path: 'check-username',
        component: LazyLoadedComponent,
        data: {
          title: 'Forgot Password',
          canonical: '/check-username',
          breadcrumb: { label: 'Forgot Password' },
          routeInfo: {
            module: FrameworkRoutesMapper.modules.UsernameModule,
            componentName: 'UsernameComponent',
          }
        }
      },
      {
        path: '404',
        component: PageNotFoundComponent,
        data: {
          title: 'Page not found',
          canonical: '/404',
          breadcrumb: { label: 'Page not found' }
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UnauthPagesRoutingModule {
}
