<div class="form-group hasrightinfo">
  <foo-control-label [templateOptions]="templateOptions" [control]="control"></foo-control-label>
  <textarea
    [formControl]="control"
    type="textarea"
    class="form-control"
    (beforeinput)="templateOptions?.onBeforeInput && templateOptions?.onBeforeInput($event)"
    (input)="templateOptions?.onInput && templateOptions?.onInput($event)"
    [placeholder]="(templateOptions?.placeholder || '') | translate"
    maxlength="{{templateOptions?.maxLength || null}}"
  ></textarea>
  <ng-container *ngIf="templateOptions?.maxLength">
    <span *ngIf="templateOptions?.maxLength" class="form-text messageCount">
       {{(control?.value?.length || 0) + '/' + templateOptions?.maxLength}}
      </span>
  </ng-container>

  <foo-control-errors [control]="control" [errorMessages]="errors"></foo-control-errors>
</div>
