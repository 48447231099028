<framework-listing
  [customRowStyle]="customRowStyle"
  [dataShimmer]="dataShimmer"
  [listingdata]="listingdata"
  [paginationInput]="paginationInput"
  [dataInput]="dataInput"
  [labelsInput]="labels"
  [issearchInput]="issearchInput"
  [showEditDelete]="showEditDelete"
  [openView]="openView"
  [staticOrdering]="false"
  [apiOrdering]="false"
  [permissions]="permissions"
  [clickableRow]="clickableRow"
  [boxView]="true"
  [btnList]="btnList"
  [noDataFoundParams]="noDataFoundParams"
  (onFunctionCalledEvent)="onFunctionCalledEvent?.emit($event)"
  (onGetDataEvent)="onGetDataEvent?.emit($event)"
  (onSearchEvent)="onSearchEvent?.emit($event)"
  (arrowClicked)="arrowClicked?.emit($event)"
  (onReorderEvent)="onReorderEvent?.emit($event)"
></framework-listing>
