import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { SimpleChanges, SimpleChange } from "@angular/core";
import {TokenStorage} from "foo-framework";

@Component({
  selector: 'framework-listing-bootstrap',
  templateUrl: './listing-bootstrap.component.html',
  styleUrls: ['./listing-bootstrap.component.scss'],
})
export class ListingBootstrapComponent implements OnInit {

  pagination:[];
  data:[];
  issearch: 0;
  searchText="";
  dataPerpage = 20;
  pageNumber = 1;
  totalData = 0;
  labels: any[] = [];
  @Input() dataShimmer;
  @Input() listingdata;
  @Input() paginationInput;
  @Input() dataInput;
  @Input() labelsInput;
  @Input() issearchInput;
  @Output() onOpenPopupEvent = new EventEmitter<{isAdd:boolean, item:object}>();
  @Output() onGetDataEvent = new EventEmitter<number>();
  @Output() onDeleteEvent = new EventEmitter<string>();
  @Output() onSearchEvent = new EventEmitter<string>();
  @Output() onClickEvent = new EventEmitter<any>();

  public constructor(private tokenStorage: TokenStorage) {
  }

  curLang : string;
  items;
  ngOnInit(): void {
    this.curLang = this.tokenStorage.getCurrentLanguage();
    this.pagination = this.paginationInput;
    this.totalData = this.pagination["totalData"];
    this.dataPerpage = this.pagination["dataPerpage"];
    this.pageNumber = this.pagination["pageNumber"];
    this.data = this.dataInput;
    this.issearch = this.issearchInput;
    this.labels = this.labelsInput;
  }

  clearText(){
    this.searchText = "";
  }
  openPopup(isAdd, item){
    this.onOpenPopupEvent.emit({isAdd, item});
  }
  getData(){
    this.onGetDataEvent.emit(this.pageNumber);
  }
  delete(itemId, item){
    this.onDeleteEvent.emit(itemId);
  }
  searchData($event){
    this.onSearchEvent.emit($event);
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentPagination: SimpleChange = changes.paginationInput;
    const currentData: SimpleChange = changes.dataInput;
    const currentIssearch: SimpleChange = changes.issearchInput;
    if(currentPagination){
      this.pagination = currentPagination.currentValue;
      this.totalData = this.pagination["totalData"];
      this.dataPerpage = this.pagination["dataPerpage"];
      this.pageNumber = this.pagination["pageNumber"];
    }
    if(currentData){
      this.data = currentData.currentValue;
    }
    if(currentIssearch){
      this.issearch = currentIssearch.currentValue;
    }

  }
  toggleResponsiveEdit(item){
    item.showActions = !item.showActions;
  }

}
